import { HttpEvent, HttpEventType, HttpProgressEvent } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { ApiErrorResponse } from '@core/models';
import { environment } from '@environments';
import { Store } from '@ngrx/store';
import { CustomerRouterSelectors } from '@portal/customer/state/customer-router.selectors';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable, of, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { FileUploadStatus } from '@portal/customer/components/asset-details/data-room/services';

export interface FileUploadProgress {
  error?: string;
  file: File;
  key: string;
  name: string;
  progress: number | undefined;
  timestamp: string;
}

interface MapValue {
  asset_id: string;
  error?: string;
  key: string;
  progress: number | undefined;
}

export interface UploadListEntity {
  asset_id: string;
  complete: boolean;
  errors: number;
  progress: number;
  timestamp: Date;
  total: number;
  upload_id: string;
}

export interface BatchProgress {
  error: boolean;
  complete: boolean;
  action?: 'finish' | 'cancel';
  cancellationSubject?: Subject<void>;
}

@Injectable({
  providedIn: 'root',
})
export class UploadTrackerService {
  readonly hasUploads$ = new BehaviorSubject<boolean>(false);
  readonly progress$ = new BehaviorSubject<FileUploadProgress[]>([]);
  readonly update$ = new BehaviorSubject<boolean>(false);
  readonly batchUploadActionTrigger$ = new Subject<{
    asset_id: string;
    upload_id: string;
    batchProgress: BatchProgress;
  }>();

  private readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);
  private readonly batchProgress = new Map<string, BatchProgress>();
  private readonly fileAssetMap = new Map<
    File,
    {
      asset_id: string;
      timestamp: string;
      upload_id: string;
    }
  >();
  readonly folderAssetMap = new Map<
    string,
    {
      folderName: string;
    }[]
  >();
  private readonly filesProgress = new Map<File, MapValue>();

  private updateProgress = _.throttle(
    (asset_id: string | null) => {
      this.zone.run(() => {
        this.update$.next(true);
      });

      if (!asset_id) {
        this.zone.run(() => {
          this.progress$.next([]);
        });
        return;
      }

      this.assetId$
        .pipe(
          take(1),
          filter((currentAssetId) => currentAssetId === asset_id)
        )
        .subscribe({
          next: () => {
            const stats: FileUploadProgress[] = this.getFileUploadProgress(asset_id);

            this.zone.run(() => {
              this.progress$.next(stats);
            });
          },
        });
    },
    500,
    { trailing: true }
  );

  constructor(private readonly zone: NgZone, private readonly store: Store) {
    this.assetId$.pipe().subscribe({
      next: (asset_id) => this.updateProgress(asset_id),
    });
  }

  get hasUploads(): boolean {
    return this.fileAssetMap.size > 0;
  }

  clearUploadTrackingData() {
    this.hasUploads$.next(false);
    this.progress$.next([]);
    this.fileAssetMap.clear();
    this.folderAssetMap.clear();
    this.filesProgress.clear();
    this.update$.next(false);
  }

  addFiles(
    fileStatuses: FileUploadStatus[],
    asset_id: string,
    upload_id: string,
    cancellationSubject: Subject<void>,
    folders: string[] = []
  ): void {
    const timestamp = new Date().toISOString();
    fileStatuses.forEach((fileStatus) => {
      if (!fileStatus.rawFile) {
        fileStatus.rawFile = {
          name: fileStatus.name,
          size: fileStatus.size,
        } as unknown as File;
      }
      fileStatus.rawFile['path'] = fileStatus.filePath;
      this.filesProgress.set(fileStatus.rawFile, {
        key: fileStatus.id || this.generateRandomUUID(),
        progress: fileStatus.status === 'completed' ? 100 : undefined,
        asset_id,
      });
      this.fileAssetMap.set(fileStatus.rawFile, {
        asset_id,
        timestamp,
        upload_id,
      });

      if (!this.batchProgress.has(upload_id)) {
        this.batchProgress.set(upload_id, {
          complete: false,
          error: false,
          cancellationSubject,
        });
      }
    });

    folders.forEach((folder) => {
      this.folderAssetMap.set(upload_id, [
        ...(this.folderAssetMap.get(upload_id) || []),
        {
          folderName: folder,
        },
      ]);
    });

    this.updateProgress(asset_id);
    this.hasUploads$.next(this.hasUploads);
  }

  clearFiles(asset_id?: string, timestampDate?: Date): void {
    const timestamp = timestampDate?.toISOString();

    if (asset_id) {
      this.filesProgress.forEach((value: MapValue, file: File) => {
        const fileAsset = this.fileAssetMap.get(file);
        if (fileAsset?.asset_id === asset_id && (timestampDate ? fileAsset?.timestamp === timestamp : true)) {
          this.filesProgress.delete(file);
          this.fileAssetMap.delete(file);
        }
      });
    } else {
      this.filesProgress.clear();
      this.fileAssetMap.clear();
    }

    this.updateProgress(asset_id);
    this.hasUploads$.next(this.hasUploads);
  }

  getBatchProgress(file: File) {
    const initialProgress = { complete: false, error: false, action: undefined };
    const upload_id = this.getFileUploadId(file);
    if (!upload_id) {
      return initialProgress;
    }

    return this.batchProgress.get(upload_id) || initialProgress;
  }

  getFileUploadId(file: File): string | undefined {
    return this.fileAssetMap.get(file)?.upload_id;
  }

  getFileUploadProgress(asset_id: string): FileUploadProgress[] {
    const stats: FileUploadProgress[] = [];

    this.filesProgress.forEach(({ key, progress, error }: MapValue, file: File) => {
      const assetMapValue = this.fileAssetMap.get(file);
      if (assetMapValue?.asset_id === asset_id && file) {
        stats.push({
          key,
          progress,
          error,
          name: file.name,
          file,
          timestamp: assetMapValue.timestamp,
        });
      }
    });

    return stats;
  }

  getUploadsList(): UploadListEntity[] {
    const values = Array.from(this.fileAssetMap.values());
    const uploads = values.reduce((acc, value) => {
      acc[value.asset_id] = acc[value.asset_id] || {};
      acc[value.asset_id][value.timestamp] = true;
      return acc;
    }, {} as Record<string, Record<string, unknown>>);

    let entities: UploadListEntity[] = [];

    for (const asset_id in uploads) {
      // eslint-disable-next-line
      const stats = this.getFileUploadProgress(asset_id);
      const assetEntities: UploadListEntity[] = _(stats)
        .groupBy((d) => d.timestamp)
        .mapValues((grouped) => {
          const total = grouped.length;
          let errors = 0;
          let bytesSize = 0;
          let bytesUploaded = 0;

          grouped.forEach(({ file, error, progress }) => {
            errors += error ? 1 : 0;
            // Do not take into account files with errors
            if (!error) {
              bytesSize += file.size;
              bytesUploaded += (progress ? progress / 100 : 0) * file.size;
            }
          });

          const p = Math.round((100 * bytesUploaded) / bytesSize);

          const upload_id = this.getFileUploadId(grouped[0].file);
          let complete = false;

          if (upload_id) {
            const batchProgress = this.batchProgress.get(upload_id) || { complete: false, error: false };
            complete = batchProgress.complete || p === 100;
            this.batchProgress.set(upload_id, {
              ...batchProgress,
              complete,
            });
          }

          return { total, errors, progress: isNaN(p) ? 0 : p, complete, upload_id: upload_id as string };
        })
        .entries()
        .map(([timestamp, entry]) => ({
          asset_id,
          timestamp: new Date(timestamp),
          ...entry,
        }))
        .value();

      entities = [...entities, ...assetEntities];
    }

    return entities;
  }

  handleUploadError(error: ApiErrorResponse, file: File): void {
    const value = this.filesProgress.get(file);
    if (value) {
      this.filesProgress.set(file, {
        ...value,
        error: error.message || error.statusText,
      });
      const fileAssetValue = this.fileAssetMap.get(file);
      if (fileAssetValue) {
        const { asset_id, upload_id } = fileAssetValue;
        this.updateProgress(asset_id);

        if (upload_id) {
          const batchProgress = this.batchProgress.get(upload_id) || { complete: false, error: false };
          this.batchProgress.set(upload_id, {
            ...batchProgress,
            error: true,
            complete: false,
          });
          this.setBatchUploadStatus(upload_id, batchProgress);
        }
      }
    }
  }

  handleUploadEvent(event: HttpEvent<unknown>, file: File): void {
    switch (event.type) {
      case HttpEventType.Sent:
        this.changeFileProgress(file, 0);
        break;

      case HttpEventType.UploadProgress:
        const percentDone = this.getFileProgress(event);
        this.changeFileProgress(file, percentDone);
        break;

      case HttpEventType.Response:
        this.changeFileProgress(file, 100);
        break;
    }

    if (!environment.production && false) {
      this.logEventMessage(event, file);
    }
  }

  removeUploadedFiles(asset_id: string, type: 'uploaded' | 'failed', timestampDate: Date): void {
    const timestamp = timestampDate?.toISOString();

    this.filesProgress.forEach(({ progress, error }: MapValue, file: File) => {
      const fileAsset = this.fileAssetMap.get(file);

      if (type === 'uploaded' && !error && progress === 100) {
        if (fileAsset?.asset_id === asset_id && fileAsset?.timestamp === timestamp) {
          this.filesProgress.delete(file);
          this.fileAssetMap.delete(file);
        }
      } else if (type === 'failed' && error && fileAsset?.timestamp === timestamp) {
        if (fileAsset?.asset_id === asset_id) {
          this.filesProgress.delete(file);
          this.fileAssetMap.delete(file);
        }
      }
    });
    this.updateProgress(asset_id);
    this.hasUploads$.next(this.hasUploads);
  }

  setBatchUploadStatus(
    upload_id: string,
    status: Partial<{
      error: boolean;
      complete: boolean;
      action: 'finish' | 'cancel';
    }>
  ): void {
    const previousBatchProgressValue = this.batchProgress.get(upload_id) || {
      complete: false,
      error: false,
    };
    const batchProgressUpdate: BatchProgress = {
      error: previousBatchProgressValue.error || status.error,
      complete: previousBatchProgressValue.complete || status.complete,
      action: status.action,
      cancellationSubject: previousBatchProgressValue.cancellationSubject,
    };

    this.batchProgress.set(upload_id, batchProgressUpdate);
    if (batchProgressUpdate.action || (batchProgressUpdate.complete && !batchProgressUpdate.error)) {
      this.assetId$.pipe(take(1)).subscribe((asset_id) => {
        this.batchUploadActionTrigger$.next({ asset_id, upload_id, batchProgress: batchProgressUpdate });
        this.updateProgress(asset_id);
      });
    }
  }

  stopUpload(upload_id: string): Observable<boolean> {
    const batchUpload = this.batchProgress.get(upload_id);
    if (!batchUpload) {
      return EMPTY;
    }

    this.batchProgress.set(upload_id, {
      ...batchUpload,
      complete: true,
      action: 'cancel',
    });
    this.update$.next(true);

    if (!batchUpload.cancellationSubject) {
      return EMPTY;
    }

    batchUpload.cancellationSubject.next();
    batchUpload.cancellationSubject.complete();

    return of(true);
  }

  generateRandomUUID(): string {
    if (crypto) {
      // eslint-disable-next-line
      return (crypto as any).randomUUID();
    }

    return `${Date.now()}-${Math.ceil(Math.random() * 1e4)}-${Math.ceil(Math.random() * 1e4)}`;
  }

  private changeFileProgress(file: File, progress: number): void {
    const value = this.filesProgress.get(file);
    const assetMapValue = this.fileAssetMap.get(file);
    if (value) {
      this.filesProgress.set(file, {
        ...value,
        progress,
      });
    } else {
      this.filesProgress.set(file, {
        key: this.generateRandomUUID(),
        progress,
        asset_id: assetMapValue?.asset_id || '',
      });
    }

    if (assetMapValue?.asset_id) {
      this.updateProgress(assetMapValue.asset_id);
    }
  }

  private getFileProgress(event: HttpProgressEvent): number {
    return Math.round((100 * event.loaded) / (event.total ?? 0));
  }

  private logEventMessage(event: HttpEvent<unknown>, file: File) {
    switch (event.type) {
      case HttpEventType.Sent:
        // eslint-disable-next-line
        console.log(`Uploading file "${file.name}" of size ${file.size}.`);
        return;

      case HttpEventType.UploadProgress:
        const percentDone = this.getFileProgress(event);
        // eslint-disable-next-line
        console.log(`File "${file.name}" is ${percentDone}% uploaded.`);
        return;

      case HttpEventType.Response:
        // eslint-disable-next-line
        console.log(`File "${file.name}" was completely uploaded!`);
        return;

      default:
        // eslint-disable-next-line
        console.log(`File "${file.name}" surprising upload event: ${event.type}.`);
        return;
    }
  }
}
