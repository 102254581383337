<x-dialog
  *transloco="let t; read: 'tickets-system.add-references-to-system-modal'"
  [title]="t('title')"
  [closeOnly]="true"
  (handleClose)="dialogRef.close()"
  class="w-[1024px] max-w-[90vw]"
>
  <p class="mb-2">{{ t('subtitle') }}</p>
  <div class="flex flex-end mb-4">
    <x-button
      color="white"
      class="h-fit"
      [disabled]="selectedRows?.length === 0"
      [matMenuTriggerFor]="onlyMediaFilesSelected() ? actionsMenu : undefined"
      (handleClick)="onSelectLocationClick()"
    >
      {{ 'select-location' | transloco }}
    </x-button>
  </div>

  <mat-menu #actionsMenu>
    <ng-template matMenuContent>
      <div class="divide-y divide-gray-300">
        <div>
          <button (click)="onPhotosDirectorySelected()" mat-menu-item>
            <span>{{ 'photos' | transloco }}</span>
          </button>
          <button (click)="onDataroomDirectorySelected()" mat-menu-item>
            <span>{{ 'data-room' | transloco }}</span>
          </button>
        </div>
      </div>
    </ng-template>
  </mat-menu>
  <ag-grid-angular
    #grid
    class="ag-theme-xportal h-[400px]"
    [gridOptions]="gridOptions"
    [suppressRowClickSelection]="true"
    [modules]="modules"
    [columnDefs]="columnDefs"
    [rowData]="dialogData.documents"
    [getRowNodeId]="getRowNodeId"
    [rowHeight]="80"
    [embedFullWidthRows]="true"
    (selectionChanged)="onSelectionChange($event)"
  ></ag-grid-angular>
  <ng-container additionalActionsRight>
    <x-button
      *ngIf="dialogData.onSkipAndContinueClick"
      color="primary"
      (handleClick)="onSkipAndContinue()"
      [disabled]="isLoading"
    >
      {{ t('skip-and-continue') }}
    </x-button>
    <span
      [matTooltipPosition]="'above'"
      [matTooltip]="
        !haveAllDocumentsTheirLocationsSelected
          ? ('tickets-system.add-references-to-system-modal.add-selected-disabled-tooltip-text' | transloco)
          : ''
      "
    >
      <x-button
        color="primary"
        [disabled]="!selectedRows.length || !haveAllDocumentsTheirLocationsSelected || isLoading"
        (handleClick)="onSubmitSelected()"
      >
        {{ t('add-selected') }}
      </x-button>
    </span>
  </ng-container>
</x-dialog>
