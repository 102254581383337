import { CdkConnectedOverlay, CdkOverlayOrigin, ViewportRuler } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SHORT_DEBOUNCE_TIME } from '@app/utils/rxjs.utils';
import { CallPipeModule } from '@common';
import { DropdownTriggerDirective } from '@common/components/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '@common/components/dropdown/dropdown.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { decodeHtml, DecodeHtmlPipe } from '@common/pipes/decode-html/decode-html.pipe';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _isEqual from 'lodash/isEqual';
import { debounceTime, filter, map } from 'rxjs/operators';

import { ISelectOption } from './select.model';
import { AutofocusDirectiveModule } from '@common/directives';

@UntilDestroy()
@Component({
  selector: 'x-select',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslocoModule,
    DecodeHtmlPipe,
    DecodeHtmlPipe,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    SvgIconModule,
    MatTooltipModule,
    CallPipeModule,
    DropdownComponent,
    DropdownTriggerDirective,
    AutofocusDirectiveModule,
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectComponent<T, K> implements OnInit, OnChanges, AfterContentInit {
  @Input() label = '';
  @Input() id = '';
  @Input() name = '';
  @Input() options: ISelectOption<T, K>[] | string[] | number[] | readonly number[] = [];
  @Input() control?: FormControl;
  @HostBinding('class.pointer-events-none')
  @Input()
  disabled?: boolean;
  @Input() required?: '' | 'required' | boolean;
  @Input() optional = false;
  @Input() withEmptyOption?: boolean;
  @Input() emptyOptionValue: unknown = null;
  @Input() emptyOptionLabel = '-';
  @Input() value: T = undefined;
  @Input() labelPosition: 'above' | 'inline' = 'above';
  @Input() transparent = false;
  @Input() thin = false;
  @Input() decodeHtmlInStringOptions = false;
  @Input() infoTooltip = '';
  @Input() readonly = false;
  @Input() focused = false;

  @ViewChild('dropdownComponent', { static: true }) dropdownElement: DropdownComponent;
  @ViewChild('buttonElement', { static: true }) buttonElement: ElementRef<HTMLElement>;

  @Input() placeholder?: string;
  @Input() classes = '';
  @Output() valueChange = new EventEmitter<T>();

  dropdownOpened = false;
  dropdownTriggerWidth: number;

  readonly cdr = inject(ChangeDetectorRef);
  readonly transloco = inject(TranslocoService);
  readonly viewportRuler = inject(ViewportRuler);
  readonly elementRef = inject(ElementRef);

  private currentFocusedIndex = -1;
  private searchString = '';

  ngOnInit() {
    if (this.control) {
      this.value = this.control.value;
    } else {
      this.control = new FormControl<T>(this.value, { updateOn: 'change' });
    }

    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });

    this.control.valueChanges
      .pipe(
        filter((value) => !_isEqual(this.value, value)),
        debounceTime(SHORT_DEBOUNCE_TIME),
        map((value) => {
          if (value === 'true' || value === 'false') {
            return JSON.parse(value);
          } else {
            return value;
          }
        }),
        untilDestroyed(this)
      )
      .subscribe((value) => {
        if (typeof this.value === 'number' || this.options?.some((val) => typeof val.value === 'number')) {
          value = Number(value);
        }
        this.value = value;
        this.valueChange.emit(value);
        this.cdr.markForCheck();
      });

    this.handleDisabledState();
    this.cdr.markForCheck();
  }

  ngAfterContentInit() {
    this.calculateTriggerWidth();
    this.watchTriggerWidth();
  }

  ngOnChanges({ value, options }: SimpleChanges) {
    if (!!this.control) {
      if (value && !value.firstChange) {
        const newValue = value?.currentValue ?? null;
        if (!_isEqual(newValue, this.control.value)) {
          this.control.setValue(newValue, { emitEvent: false });
        }
      }
      this.handleDisabledState();
    }

    if (options) {
      this.cdr.detectChanges();
    }
  }

  getOptionValue(option: ISelectOption<unknown> | string | number): unknown | string | number {
    return this.getOptionProperty(option);
  }

  getOptionLabel(option: ISelectOption<unknown> | string | number): unknown | string | number {
    return this.getOptionProperty(option, 'label');
  }

  trackByValue(_: number, option: ISelectOption<unknown | string | number>) {
    return option.value;
  }

  handleOptionSelection(value?: unknown) {
    this.control.setValue(value, { emitEvent: true });
    this.value = value as T;
    this.dropdownElement?.triggerButton.close();
    this.resetSearchString();
    this.cdr.detectChanges();
  }

  getSelectedValueLabel(): unknown {
    const emptyOptionLabel = this.withEmptyOption ? this.emptyOptionLabel : ' ';
    if (this.control?.value === this.emptyOptionValue) {
      return emptyOptionLabel;
    }
    const valueIndex = this.options?.findIndex((option) => option?.value === this.control?.value);
    const labelValue = this.options?.[valueIndex];
    if (labelValue?.['label']) {
      return this.decodeHtmlInStringOptions ? decodeHtml(labelValue?.['label']) : labelValue?.['label'];
    }
    let result = labelValue?.['translationKey']
      ? this.transloco.translate(labelValue?.['translationKey'])
      : labelValue?.['value']
      ? labelValue?.['value']
      : this.control?.value || emptyOptionLabel;

    if (result instanceof Array) {
      result = result.join(' ');
    }

    return this.decodeHtmlInStringOptions ? decodeHtml(result) : result;
  }

  getParsedLabel(option: ISelectOption<unknown | string | number>) {
    const decodeHtml = (value) => {
      const txt = document.createElement('textarea');
      txt.innerHTML = value;
      return txt.value;
    };

    const result = option.translationKey
      ? this.transloco.translate(option.translationKey)
      : this.decodeHtmlInStringOptions
      ? decodeHtml(this.getOptionLabel(option))
      : this.getOptionLabel(option);

    if (result instanceof Array) {
      return result.join(' ');
    } else {
      return result;
    }
  }

  getOptions(pinnedOnly?: boolean) {
    if (!(this.options instanceof Array)) {
      return this.options;
    }

    const filterCondition = (option: ISelectOption<unknown>) => (pinnedOnly ? option?.pinned : !option?.pinned);
    return (this.options as ISelectOption<unknown>[]).filter(filterCondition);
  }

  onDropdownToggle(isDropdownOpened: boolean) {
    this.resetSearchString();
    this.dropdownOpened = isDropdownOpened;
  }

  handleKeydown(event: KeyboardEvent) {
    const optionElements = this.getOptionElements();
    if (!optionElements.length) {
      return;
    }

    if (['ArrowDown', 'ArrowUp'].includes(event.key)) {
      event.preventDefault();
      this.resetSearchString();

      if (event.key === 'ArrowDown') {
        this.currentFocusedIndex = (this.currentFocusedIndex + 1) % optionElements.length;
      } else {
        this.currentFocusedIndex = (this.currentFocusedIndex - 1 + optionElements.length) % optionElements.length;
      }

      optionElements[this.currentFocusedIndex].focus();
    } else if (event.key === 'Enter') {
      optionElements[this.currentFocusedIndex].click();
    } else if (event.key === 'Escape') {
      this.resetSearchString();
    } else if (event.key === 'Backspace') {
      this.removeLastCharacterFromSearchString();
    } else if (/^[a-z0-9]$/i.test(event.key)) {
      const newSearchString = this.searchString + event.key.toLowerCase();

      const matchedOptionIndex = optionElements.findIndex((el) =>
        el.textContent.trim().toLowerCase().startsWith(newSearchString)
      );

      if (matchedOptionIndex !== -1 && matchedOptionIndex !== this.currentFocusedIndex) {
        this.currentFocusedIndex = matchedOptionIndex;
        this.searchString = newSearchString;
        optionElements[this.currentFocusedIndex].focus();
      }
    }
  }

  focusNextOption() {
    const options = this.getOptionElements();
    if (options.length === 0) return;

    this.currentFocusedIndex = (this.currentFocusedIndex + 1) % options.length;
    options[this.currentFocusedIndex].focus();
  }

  focusPreviousOption() {
    const options = this.getOptionElements();
    if (options.length === 0) return;

    this.currentFocusedIndex = (this.currentFocusedIndex - 1 + options.length) % options.length;
    options[this.currentFocusedIndex].focus();
  }

  selectFocusedOption() {
    const options = this.getOptionElements();
    if (options.length === 0 || this.currentFocusedIndex < 0) return;

    const focusedOption = options[this.currentFocusedIndex];
    const value = focusedOption.getAttribute('data-value');
    this.handleOptionSelection(value);
  }

  focusOptionStartingWith(char: string) {
    const options = this.getOptionElements();
    if (options.length === 0) return;

    const matchIndex = options.findIndex((option) => option.innerText.toLowerCase().startsWith(char.toLowerCase()));
    if (matchIndex !== -1) {
      this.currentFocusedIndex = matchIndex;
      options[this.currentFocusedIndex].focus();
    }
  }

  getOptionElements(): HTMLElement[] {
    return Array.from(document?.querySelectorAll('.select-options-dropdown'));
  }

  private calculateTriggerWidth() {
    this.dropdownTriggerWidth = this.buttonElement?.nativeElement?.offsetWidth;
    this.dropdownElement.setOverlayWidth(this.dropdownTriggerWidth);
  }

  private watchTriggerWidth() {
    this.viewportRuler
      .change()
      .pipe(debounceTime(SHORT_DEBOUNCE_TIME), untilDestroyed(this))
      .subscribe(() => {
        if (this.dropdownOpened) {
          this.calculateTriggerWidth();
          this.cdr.detectChanges();
        }
      });
  }

  private handleDisabledState() {
    if (this.disabled !== undefined) {
      this.disabled ? this.control?.disable() : this.control?.enable();
      this.cdr.markForCheck();
    }
  }

  private getOptionProperty(
    option: ISelectOption<unknown> | string | number,
    property: 'label' | 'value' = 'value'
  ): unknown {
    return typeof option === 'string' || typeof option === 'number' ? option : option?.[property];
  }

  private resetSearchString() {
    this.searchString = '';
  }

  private removeLastCharacterFromSearchString() {
    this.searchString = this.searchString.slice(0, -1);
  }
}
