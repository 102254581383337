<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="AssetType.Office">{{ 'office' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AssetType.Residential">{{ 'residential' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AssetType.Hotel">{{ 'hotel' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AssetType.Logistics">{{ 'logistics' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AssetType.ShoppingCenter">{{ 'shopping-center' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AssetType.Retail">{{ 'retail' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AssetType.NursingHome">{{ 'nursing-home' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AssetType.ParkingGarage">{{ 'parking-garage' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ type || '-' }}</ng-container>
</ng-container>
