import { AssetSettings } from '@core/models';
import { UserActions } from '@core/state';
import { Action, createReducer, on } from '@ngrx/store';
import { ResolvedDataActions } from './resolver.actions';

export interface ResolvedData {
  settings?: AssetSettings;
  assetId: string;
  projectLogoUrl?: string;
  assetLogoUrl?: string;
  project_terms_conditions_id?: string;
  reportLogoUrl?: string;
}

const resolvedDataInitialState: ResolvedData = <ResolvedData>{};

const resolvedReducer = createReducer<ResolvedData>(
  resolvedDataInitialState,
  on(ResolvedDataActions.setAssetSettings, (state, action) => ({
    ...state,
    settings: action.data?.settings,
    assetId: action.data?.assetId,
    project_terms_conditions_id: action.data?.settings?.project.terms_conditions_id,
  })),
  on(ResolvedDataActions.updateSettingsGroupIfApplicable, (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      group: state.settings.group.id === action.group.id ? action.group : state.settings.group,
    },
  })),
  on(ResolvedDataActions.setAssetName, (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      asset: {
        ...state.settings.asset,
        name: action.name,
      },
    },
  })),
  on(UserActions.clearUser, () => ({
    ...resolvedDataInitialState,
  })),
  on(ResolvedDataActions.clearAssetSettings, () => ({
    ...resolvedDataInitialState,
  })),
  on(ResolvedDataActions.setAssetLogo, (state, action) => ({
    ...state,
    assetLogoUrl: action.url,
  })),
  on(ResolvedDataActions.setProjectLogo, (state, action) => ({
    ...state,
    projectLogoUrl: action.url,
  })),
  on(ResolvedDataActions.setReportLogo, (state, action) => ({
    ...state,
    reportLogoUrl: action.url,
  })),
  on(ResolvedDataActions.setAssetStatus, (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      asset: {
        ...state.settings.asset,
        status: action.assetStatus,
      },
    },
  })),
  on(ResolvedDataActions.setAssetFrozenState, (state, action) => ({
    ...state,
    settings: {
      ...state.settings,
      asset: {
        ...state.settings.asset,
        is_frozen: action.isFrozen,
      },
    },
  })),
  on(ResolvedDataActions.setDisplayAssetIndexPoints, (state, action) => ({
    ...state,
    display_index_points: action.displayIndexPoints,
    settings: {
      ...state.settings,
      asset: {
        ...state.settings.asset,
        display_index_points: action.displayIndexPoints,
      },
    },
  })),
  on(ResolvedDataActions.setProjectTermsConditionsId, (state, action) => ({
    ...state,
    project_terms_conditions_id: action.termsConditionsId,
  }))
);

export namespace ResolvedDataReducer {
  export const name = 'customer-asset-resolved-data';

  export const reducer = (state: ResolvedData, action: Action) => resolvedReducer(state, action);
}
