import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { QA } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CustomerRouterSelectors } from '@portal/customer/state';
import * as _ from 'lodash';
import { QAFilter } from './qa-filter';
import { QAOverviewSearchActions } from './qa-overview-search.actions';

@UntilDestroy()
@Component({
  selector: 'x-qa-overview-search-breadcrumbs',
  templateUrl: './qa-overview-search-breadcrumbs.component.html',
  styleUrls: ['./qa-overview-search-breadcrumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QAOverviewSearchBreadcrumbsComponent {
  readonly statuses = QA.Status.all;
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);

  filter = {} as QAFilter;
  backupSearchFilter: QAFilter;

  private filterSent = false;
  resetFilterAction$ = createEffect(() => this.actions$.pipe(ofType(QAOverviewSearchActions.reset)));

  get filterChanged(): boolean {
    return !_.isEmpty(this.filter?.query);
  }

  get filterApplied(): boolean {
    return this.filterChanged || this.filterSent;
  }

  constructor(
    private readonly store: Store,
    private readonly actions$: Actions,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.resetFilterAction$.pipe(untilDestroyed(this)).subscribe({
      next: () => {
        this.onCancelFilter();
        this.cdr.markForCheck();
      },
    });
  }

  onShowSearch(): void {
    if (this.backupSearchFilter) {
      _.merge(this.filter, this.backupSearchFilter);
      this.onApplyFilter();
    }
  }

  onClickFilter(): void {
    if (this.filterApplied) {
      this.backupSearchFilter = null;
      this.onCancelFilter();
    } else {
      this.onApplyFilter();
    }
  }

  onInputKeydown(event: KeyboardEvent): void {
    if (event.code === 'Escape') {
      if (this.filterChanged) {
        this.filter.query = null;
        this.onApplyFilter();
      } else {
        this.onCancelFilter();
      }
    }
  }

  onApplyFilter(): void {
    this.backupSearchFilter = this.backupSearchFilter || <QAFilter>{};
    _.merge(this.backupSearchFilter, this.filter);

    this.filterSent = true;
    this.store.dispatch(QAOverviewSearchActions.search({ params: { ...this.filter } }));
  }

  onCancelFilter(): void {
    this.filter = {} as QAFilter;

    this.filterSent = false;
    this.store.dispatch(QAOverviewSearchActions.search({}));
  }

  onToggleStatus(status: QA.Status): void {
    this.filter.status = status;
    this.onApplyFilter();
  }
}
