import { animate, style, transition, trigger } from '@angular/animations';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgClass, NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, ContentChild, inject, Input } from '@angular/core';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { DropdownTriggerDirective } from './dropdown-trigger.directive';

@Component({
  selector: 'x-dropdown',
  templateUrl: './dropdown.component.html',
  animations: [
    trigger('AnimationTrigger', [
      transition(':enter', [
        style({ opacity: 0, transform: 'scale(.95)' }),
        animate('100ms ease-out', style({ opacity: 1, transform: 'scale(1)' })),
      ]),
      transition(':leave', [
        style({ opacity: 1, transform: 'scale(1)' }),
        animate('75ms ease-in', style({ opacity: 0, transform: 'scale(.95)' })),
      ]),
    ]),
  ],
  standalone: true,
  imports: [NgIf, NgClickOutsideDirective, NgClass, OverlayModule],
})
export class DropdownComponent {
  @Input() origin: 'TOP_LEFT' | 'TOP_RIGHT' | 'BOTTOM_LEFT' | 'BOTTOM_RIGHT' = 'TOP_LEFT';
  @Input() width: 'sm' | 'md' | 'lg' | 'xl' = 'sm';
  @Input() distance = '50px';
  @Input() isDisabled = false;
  @Input() isSelect = false;

  @ContentChild(DropdownTriggerDirective)
  triggerButton: DropdownTriggerDirective | undefined;
  overlayWidth: number;
  readonly cdr = inject(ChangeDetectorRef);

  setOverlayWidth(width) {
    if (!this.isSelect && !width) {
      return;
    }
    this.overlayWidth = width;
    this.cdr.markForCheck();
  }

  onClickOutside() {
    this.triggerButton?.close();
  }
}
