<ng-container [ngSwitch]="fieldName">
  <ng-container *ngSwitchCase="'description'">{{ 'description' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'is_published'">{{ 'is-published' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'name'">{{ 'name' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'tags'">{{ 'tags' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'types'">{{ 'type' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'upload_status'">{{ 'upload-status' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'user_email'">{{ 'email' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'created_at'">{{ 'created-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'uploaded_at'">{{ 'uploaded-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'published_at'">{{ 'published-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'original_datetime'">{{ 'original-date' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'download_token'">{{ 'download' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'image-preview-col'">{{ 'photo-preview' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'file_size'">{{ 'size' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'messages'">{{ 'messages' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="'date_modified'">{{ 'date-modified' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>
