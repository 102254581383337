import { Directive, Input, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
  selector: '[autofocus]',  // eslint-disable-line
})
export class AutofocusDirective implements AfterViewInit {
  @Input() autofocus: boolean;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    this.setFocus(this.autofocus);
  }

  private setFocus(shouldFocus = true): void {
    if (shouldFocus) {
      this.el.nativeElement.focus();
    }
  }
}
