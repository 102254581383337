import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  AvailableStatisticScopeValue,
  LoginHistory,
  PaginationRequest,
  PaginationResponse,
  QaEntry,
  StatisticEntry,
  StatisticReportType,
  StatisticSearchFilter,
  StatisticType,
  TicketEntry,
  Transform,
} from '@core/models';
import { Activity } from '@core/models/activity';
import { ActivityHeatmap } from '@core/models/activity-heatmap';
import { ActivityReport } from '@core/models/activity-statistic';
import { DataroomUpdatesEntries } from '@core/models/dataroom-updates';
import { LastAssetAccessLog } from '@core/models/login-history';
import { environment } from '@environments';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API_URL } from './constants';

@Injectable({
  providedIn: 'root',
})
export class ActivityStatisticsService {
  readonly apiUrl = API_URL;
  readonly http = inject(HttpClient);

  getQaStatisticReport(
    id: string,
    paginationRequest: PaginationRequest = {},
    filter: StatisticSearchFilter = {}
  ): Observable<PaginationResponse<QaEntry>> {
    filter = { ...filter, from_date: filter.from_date, to_date: this.getNextDate(filter.to_date) };
    const params = this.mapFilterToQueryParams({
      ...(paginationRequest || {}),
      ...(filter || {}),
    });
    return this.http.get<PaginationResponse<QaEntry>>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${id}/statistics/qa`,
      {
        params,
      }
    );
  }

  getTicketStatisticReport(
    id: string,
    paginationRequest: PaginationRequest = {},
    filter: StatisticSearchFilter = {}
  ): Observable<PaginationResponse<TicketEntry>> {
    filter = { ...filter, from_date: filter.from_date, to_date: this.getNextDate(filter.to_date) };
    const params = this.mapFilterToQueryParams({
      ...(paginationRequest || {}),
      ...(filter || {}),
    });
    return this.http.get<PaginationResponse<TicketEntry>>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${id}/statistics/tickets`,
      {
        params,
      }
    );
  }

  getPhotoDocumentsStatistics(
    id: string,
    paginationRequest: PaginationRequest = {},
    filter: StatisticSearchFilter = {}
  ): Observable<PaginationResponse<TicketEntry>> {
    filter = { ...filter, from_date: filter.from_date, to_date: this.getNextDate(filter.to_date) };
    const params = this.mapFilterToQueryParams({
      ...(paginationRequest || {}),
      ...(filter || {}),
    });
    return this.http.get<PaginationResponse<TicketEntry>>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${id}/statistics/document`,
      {
        params,
      }
    );
  }

  trackActivity(assetId: string, data: Activity): Observable<void> {
    return this.http.post<void>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${assetId}/statistics/activity-event`,
      data
    );
  }

  getStatisticReport(
    id: string,
    paginationRequest: PaginationRequest = {},
    filter: StatisticSearchFilter = {},
    scopes: AvailableStatisticScopeValue[] = ['data_room']
  ): Observable<PaginationResponse<ActivityReport>> {
    filter = { ...filter, from_date: filter.from_date, to_date: this.getNextDate(filter.to_date) };
    const params = this.mapFilterToQueryParams({
      ...(paginationRequest || {}),
      ...(filter || {}),
      scopes,
    });
    return this.http.get<PaginationResponse<ActivityReport>>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${id}/statistics/activity`,
      {
        params,
      }
    );
  }

  getGroupStatisticReport(
    id: string,
    paginationRequest: PaginationRequest = {},
    filter: StatisticSearchFilter = {}
  ): Observable<PaginationResponse<StatisticEntry>> {
    filter = {
      ...filter,
      from_date: filter.from_date,
      to_date: this.getNextDate(filter.to_date),
    };
    const params = this.mapFilterToQueryParams({
      ...(paginationRequest || {}),
      ...(filter || {}),
    });
    return this.http.get<PaginationResponse<StatisticEntry>>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${id}/statistics/group`,
      {
        params,
      }
    );
  }

  getStatisticUpdatesAndPermissions(
    id: string,
    paginationRequest: PaginationRequest = {},
    filter: StatisticSearchFilter = {}
  ): Observable<PaginationResponse<DataroomUpdatesEntries>> {
    filter = { ...filter, from_date: filter.from_date, to_date: this.getNextDate(filter.to_date) };
    const queryParams = this.mapFilterToQueryParams({
      ...(paginationRequest || {}),
      ...(filter || {}),
    });

    return this.http.get<PaginationResponse<DataroomUpdatesEntries>>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${id}/statistics/dataroom-updates`,
      {
        params: queryParams,
      }
    );
  }

  getStatisticLoginReport(
    id: string,
    paginationRequest: PaginationRequest,
    filter?: StatisticSearchFilter
  ): Observable<LoginHistory> {
    const queryParams = this.mapFilterToQueryParams({
      ...paginationRequest,
      ...(filter || {}),
    });

    return this.http
      .get<LoginHistory>(`${this.apiUrl}/${environment.portalApiUrl}/assets/${id}/statistics/login`, {
        params: queryParams,
      })
      .pipe(
        map((response: LoginHistory) => ({
          ...response,
          records: _.map(response.records, (record) => this.transform(record)),
        }))
      );
  }

  getStatisticActivityHeatmap(assetId: string): Observable<ActivityHeatmap[]> {
    return this.http.get<ActivityHeatmap[]>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${assetId}/statistics/activity-heatmap`
    );
  }

  exportStatisticReport(
    assetId: string,
    statisticType: StatisticType,
    filter?: StatisticSearchFilter,
    scopes: AvailableStatisticScopeValue[] = ['data_room']
  ) {
    const params = this.mapFilterToQueryParams({ ...filter, scopes });

    return this.http.post<void>(
      `${this.apiUrl}/${environment.portalApiUrl}/assets/${assetId}/statistics/${statisticType}/export`,
      {},
      { params }
    );
  }

  getScopeFromStatisticType(statisticReportType: StatisticReportType): AvailableStatisticScopeValue[] {
    switch (statisticReportType) {
      case 'qaActivity':
        return ['qa'];
      case 'ticketActivity':
        return ['ticket'];
      case 'photoActivity':
        return ['photo'];
      default:
        return ['data_room'];
    }
  }

  private mapFilterToQueryParams(filter?: StatisticSearchFilter) {
    return <HttpParams>_(filter)
      .omitBy(_.isNil)
      .mapValues((value) => (value instanceof Date ? value.toISOString() : value))
      .value();
  }

  private getNextDate(date: Date | undefined) {
    if (!date) {
      return;
    }
    const tomorrow = new Date(date);
    tomorrow.setDate(date.getDate() + 1);
    return tomorrow;
  }

  private transform(value: LastAssetAccessLog): LastAssetAccessLog {
    return {
      ...value,
      created_at: Transform.date(value.created_at),
    };
  }
}
