import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';
import { Message } from '@core/models';

@Pipe({
  name: 'messagesColors',
})
export class MessagesColorsPipe implements PipeTransform {
  transform(messages: { color: Message.Color; category: string }[] | undefined): string[] {
    if (!messages) {
      return [];
    }

    return _(messages)
      .map(({ color, category }) => (category === 'tickets' ? 'orange' : color))
      .uniq()
      .value();
  }
}
