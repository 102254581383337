import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of } from 'rxjs';
import { catchError, finalize, first, map, tap } from 'rxjs/operators';

import { AdminsService, ProjectsService } from '@core/services';
import { ToastService, ToastType } from '@core/toast';

@UntilDestroy()
@Component({
  selector: 'x-admin-selector-dialog',
  templateUrl: './admin-selector-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdminSelectorDialogComponent {
  @ViewChild('addAdminErrorMessageTemplate') addAdminErrorMessageTemplate: ElementRef;

  adminFormGroup: FormGroup;
  requestInProgress = false;

  readonly admins$ = this.adminsService
    .fetchProjectAdmins()
    .pipe(
      map((projectAdmins) =>
        projectAdmins.map((projectAdmin) => ({ value: projectAdmin.user_id, label: projectAdmin.user.email }))
      )
    );

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { projectId: string },
    private readonly adminsService: AdminsService,
    private readonly projectsService: ProjectsService,
    private readonly dialogRef: MatDialogRef<AdminSelectorDialogComponent, boolean>,
    private readonly cdr: ChangeDetectorRef,
    private readonly transloco: TranslocoService,
    private readonly fb: FormBuilder,
    private readonly toastService: ToastService
  ) {
    this.buildAdminFormGroup();
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.requestInProgress = true;
    this.projectsService
      .addProjectAdmin(this.data?.projectId, this.adminFormGroup.getRawValue())
      .pipe(
        first(),
        tap(() => this.dialogRef.close()),
        catchError((error) => {
          this.dialogRef.close();
          this.toastService.showToast(this.transloco.translate('failed-to-add-an-admin'), ToastType.ERROR, [
            error?.error?.ErrKey,
          ]);
          return of(null);
        }),
        finalize(() => {
          this.requestInProgress = false;
          this.cdr.markForCheck();
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  private buildAdminFormGroup(): void {
    this.adminFormGroup = this.fb.group({
      user_id: ['', [Validators.required]],
      is_project_lead: [false, [Validators.required]],
    });
  }
}
