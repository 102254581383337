import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { MEDIUM_DEBOUNCE_TIME } from '@app/utils/rxjs.utils';

import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { HashMap, TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { debounceTime, filter } from 'rxjs/operators';

interface ErrorToDisplay {
  translationKey: string;
  errorDetails: HashMap<unknown>;
}

@UntilDestroy()
@Component({
  selector: 'x-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslocoModule, SvgIconModule, NgClickOutsideDirective],
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class InputComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() label = '';
  @Input() id?: string;
  @Input() name?: string;
  @Input() placeholder = '';
  @Input() required?: '' | 'required' | boolean;
  @Input() autocomplete!: string;
  @Input() control = new FormControl();
  @Input() disabled?: boolean;
  @Input() errorTranslationKeyPrefix = '';
  @Input() errorTranslationKeyMainNodePrefix = '';
  @Input() type: 'text' | 'email' | 'number' | 'password' | 'tel' = 'text';
  @Input() isShowPasswordButtonVisible = true;
  @Input() min?: number;
  @Input() max?: number;
  @Input() nativeInputClasses = '';
  @Input() value?: string | number;
  @Input() maxlength?: string | number;
  @Input() showClearButton = false;
  @Input() forceShowClearButton = false;
  @Input() leftSideIcon?: AvailableIcons;
  @Input() rightSideIcon?: AvailableIcons;
  @Input() errors?: ValidationErrors;
  @Input() labelPosition: 'above' | 'inline' = 'above';
  @Input() prefixInputText: string;
  @Input() allowNumbersOnly: boolean;

  @Output() valueChange = new EventEmitter();
  @Output() handleInput = new EventEmitter();
  @Output() handleKeydown = new EventEmitter();
  @Output() clickOutside = new EventEmitter();
  @Output() inputClick = new EventEmitter();
  @Output() resetClick = new EventEmitter();
  @Output() leftSideIconClick = new EventEmitter();
  @Output() rightSideIconClick = new EventEmitter();

  // it's needed to don't change styles when some @Input() type was added
  @HostBinding('attr.type') typeAttribute = '';

  errorsKeysToDisplay: ErrorToDisplay[] | null = null;
  isPasswordShowed = false;

  ngOnInit(): void {
    this.watchValueChangesToCreateErrorsToDisplay();
  }

  ngOnChanges() {
    if (this.value !== undefined) {
      this.control.setValue(this.value);
    }

    if (this.disabled !== undefined) {
      if (this.disabled) {
        this.control.disable();
      } else {
        this.control.enable();
      }
    }
  }

  ngAfterViewInit() {
    this.control.valueChanges.pipe(debounceTime(MEDIUM_DEBOUNCE_TIME)).subscribe((currentValue) => {
      currentValue !== this.value ? this.onValueChange(currentValue) : null;
    });
  }

  get isTogglePasswordVisibilityButtonShowed() {
    return (this.type === 'password' || this.isPasswordShowed) && this.isShowPasswordButtonVisible;
  }

  getShowActionButtonsContainer() {
    return this.showClearButton || this.isTogglePasswordVisibilityButtonShowed || this.rightSideIcon;
  }

  onValueChange(event: Event | string): void {
    const value = typeof event === 'object' ? (event?.target as HTMLInputElement)?.value : event;
    if (this.type === 'number') {
      const newValue = Number.parseInt(value, 10);
      if (this.min !== undefined && newValue < this.min) {
        this.setAndEmitValue(this.min);
        return;
      }
      if (this.max !== undefined && newValue > this.max) {
        this.setAndEmitValue(this.max);
        return;
      }
    }
    if (this.type === 'text' && value === '') {
      this.createErrorsToDisplay();
    }
    this.valueChange.emit(this.type === 'number' ? Number.parseInt(value, 10) : value);
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    this.handleInput.emit(value);
  }

  onInputBlur(): void {
    this.createErrorsToDisplay();
  }

  onKeydown(keyboardEvent: KeyboardEvent): void {
    if (this.allowNumbersOnly) {
      const key = keyboardEvent.key;
      const isAllowedKey = /^[0-9]$/.test(key) || key === 'Backspace';

      if (!isAllowedKey) {
        keyboardEvent.preventDefault();
        return;
      }
    }
    this.handleKeydown.emit(keyboardEvent);
  }

  onPasswordShowToggle(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.isPasswordShowed = !this.isPasswordShowed;
  }

  trackByErrorTranslationKey(_: number, errorToDisplay: ErrorToDisplay): string {
    return errorToDisplay.translationKey;
  }

  onResetClick() {
    this.setAndEmitValue('');
    this.handleInput.emit('');
    this.resetClick.emit();
  }

  private setAndEmitValue(value: number | string): void {
    this.control.setValue(value);
    this.valueChange.emit(value);
  }

  private watchValueChangesToCreateErrorsToDisplay(): void {
    this.control.statusChanges
      .pipe(
        filter(() => !!this.control.errors),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.createErrorsToDisplay();
      });
  }

  private createErrorsToDisplay(): void {
    if (this.control.errors) {
      this.errorsKeysToDisplay = Object.keys(this.control.errors as ValidationErrors).map((error) => ({
        translationKey: `${this.errorTranslationKeyPrefix}error.${this.errorTranslationKeyMainNodePrefix}${error
          .toLowerCase()
          .replace(/[\-|\s]/, '-')}`,
        errorDetails: this.control.errors[error],
      }));
    } else {
      this.errorsKeysToDisplay = null;
    }
  }
}
