<div class="h-full w-full flex flex-col">
  <div class="flex justify-between items-center mb-6">
    <h2 class="font-bold text-black">
      {{ 'projects-overview' | transloco }}
    </h2>
    <div class="flex flex-end items-center space-x-3">
      <x-admin-portal-search class="pt-[2px]"></x-admin-portal-search>
      <x-button
        *ngIf="rootAndManager | hasRole | async"
        class="mt-[3px]"
        title="{{ 'add-project' | transloco }}"
        (click)="onAddProject()"
      >
        <x-svg-icon svgImageName="plus" svgClass="w-4 h-4 text-white mr-2"></x-svg-icon>
        <span>{{ 'project' | transloco }}</span>
      </x-button>
    </div>
  </div>
  <div class="flex overflow-hidden h-full w-full justify-between">
    <ag-grid-angular
      class="h-full w-full ag-theme-xportal"
      [defaultColDef]="defaultColDef"
      [gridOptions]="gridOptions"
      [rowData]="rowData$ | async"
      [columnDefs]="columnDefs"
      [modules]="modules"
    ></ag-grid-angular>

    <x-columns-panel
      class="h-full"
      [columns]="settingsColumns"
      [fieldTemplate]="projectLabelTemplate"
      (panelOpened)="onPanelOpened($event)"
      (toggleVisibility)="onToggleColumnVisibility($event)"
    ></x-columns-panel>
  </div>
</div>

<ng-template #projectLabelTemplate let-field>
  <x-portal-projects-label [fieldName]="field"></x-portal-projects-label>
</ng-template>

<mat-menu #actionsMenu>
  <ng-template matMenuContent let-data="data">
    <button (click)="onRemoveProject(data)" mat-menu-item>
      <span>{{ 'delete' | transloco }}</span>
    </button>
  </ng-template>
</mat-menu>
