import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SIDE_PANEL_DIALOG_DATA,
  SidePanelDialogData,
} from '@common/components/side-panel-dialog/side-panel-dialog.component';
import { Message } from '@core/models';
import { MessageModule } from '@portal/components/comment/message';

interface ISidePanelMessagesData {
  messages: Message.Short[];
  allMessages: Message.Short[];
}

@Component({
  selector: 'x-side-panel-messages',
  standalone: true,
  imports: [CommonModule, MessageModule],
  templateUrl: './side-panel-messages.component.html',
  styleUrls: ['./side-panel-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelMessagesComponent {
  readonly dialogData: SidePanelDialogData<ISidePanelMessagesData> = inject(SIDE_PANEL_DIALOG_DATA);
}
