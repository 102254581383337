<x-dialog
  [title]="'create-tracker' | transloco"
  [submitDisabled]="!trackers.length"
  (handleClose)="dialogRef.close()"
  (handleSubmit)="onSubmit()"
  classes="w-[75vw]"
>
  <form #form="ngForm" class="container" (submit)="onSubmit()" novalidate autocomplete="off">
    <div>
      <div class="trackers-container-header">
        <div>{{ 'description' | transloco }}</div>
        <div>{{ 'planned-date' | transloco }}</div>
        <button type="button" class="primary icon-button button-add" (click)="onAddTracker()">
          <x-icon class="plus"></x-icon>
          &nbsp;
        </button>
      </div>
      <div class="trackers-container">
        <div class="tracker" *ngFor="let tracker of trackers; index as index">
          <div class="descriptions-container">
            <div class="description-container" *ngFor="let descr of tracker.description; index as descrIndex">
              <div class="description">
                <input type="text" name="description-{{ index }}-{{ descrIndex }}" [(ngModel)]="descr.description" />
                <mat-select
                  #select
                  [multiple]="false"
                  name="lang-{{ index }}-{{ descrIndex }}"
                  [(ngModel)]="descr.lang"
                >
                  <mat-option *ngFor="let language of languages" [value]="language.lang">
                    <x-language-label [language]="language.id"></x-language-label>
                  </mat-option>
                </mat-select>
              </div>
              <x-validation-error-label
                [error]="getErrors(getControlName('lang', index, descrIndex))"
              ></x-validation-error-label>
              <x-validation-error-label
                [error]="getErrors(getControlName('description', index, descrIndex))"
              ></x-validation-error-label>
            </div>
          </div>
          <div class="date-container">
            <input
              type="text"
              name="planned_date-{{ index }}"
              (focus)="picker.open()"
              [matDatepicker]="picker"
              [(ngModel)]="tracker.planned_date"
            />
            <mat-datepicker #picker></mat-datepicker>
            <x-validation-error-label
              [error]="getErrors(getControlName('planned_date', index))"
            ></x-validation-error-label>
          </div>

          <button type="button" class="primary" (click)="onRemoveTracker(tracker)">
            <x-icon iconFont="close"></x-icon>
            &nbsp;
          </button>
        </div>
      </div>
    </div>
  </form>
</x-dialog>

<ng-template #unknownErrorTemplate>
  <span>{{ 'unknown-error-please-try-again' | transloco }}</span>
</ng-template>
