import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Link } from '@core/models';
import { Observable } from 'rxjs';
import { PORTAL_API_URL } from './constants';

export interface DeleteAssetLinkReqBody {
  asset_id: string;
  link: Link;
}

@Injectable({
  providedIn: 'root',
})
export class LinksService {
  readonly apiUrl = PORTAL_API_URL;
  readonly adminApiUrl = `${this.apiUrl}/admin`;
  readonly customerApiUrl = `${this.apiUrl}/assets`;

  constructor(private http: HttpClient) {}

  createAssetLink({ asset_id, link }: { asset_id: string; link: Link }): Observable<Link> {
    const url = `${this.customerApiUrl}/${asset_id}/links`;
    return this.http.post<Link>(url, link);
  }

  deleteAssetLink({ asset_id, link }: DeleteAssetLinkReqBody): Observable<Link> {
    const url = `${this.customerApiUrl}/${asset_id}/links`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: link,
    };
    return this.http.delete<Link>(url, options);
  }

  createLink({ project_id, asset_id, link }: { project_id: string; asset_id: string; link: Link }): Observable<Link> {
    const url = `${this.adminApiUrl}/projects/${project_id}/assets/${asset_id}/links`;
    return this.http.post<Link>(url, link);
  }

  deleteLink({ project_id, asset_id, link }: { project_id: string; asset_id: string; link: Link }): Observable<Link> {
    const url = `${this.adminApiUrl}/projects/${project_id}/assets/${asset_id}/links`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: link,
    };
    return this.http.delete<Link>(url, options);
  }

  getLinks({ project_id, asset_id }: { project_id: string; asset_id: string }): Observable<Link[]> {
    const url = `${this.adminApiUrl}/projects/${project_id}/assets/${asset_id}/links`;
    return this.http.get<Link[]>(url);
  }
}
