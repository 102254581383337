import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AssetOverview, Transform } from 'src/app/core/models';
import { TranslocoService } from '@ngneat/transloco';
import { AdminAssetsService } from 'src/app/portal/admin/services/admin-assets.service';
import { TermsConfirmationDialogComponent } from 'src/app/portal/components/terms-confirmation-dialog/terms-confirmation-dialog.component';
import { TermsConfirmationDialogData } from 'src/app/portal/components/terms-confirmation-dialog';
import _ from 'lodash';
import { EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AssetsActions } from '@portal/state/assets/assets.actions';
import { Store } from '@ngrx/store';
import { ToastService, ToastType } from '@core/toast';

@Injectable({
  providedIn: 'root',
})
export class TermsConditionsValidateService {
  constructor(
    private readonly adminAssetService: AdminAssetsService,
    private readonly dialog: MatDialog,
    private readonly toast: ToastService,
    private readonly transloco: TranslocoService,
    private readonly store: Store
  ) {}

  validateGlobalTermsConditions(observable$: Observable<AssetOverview[]>) {
    return observable$.pipe(
      map((assetOverviews) => this.transformResponse(assetOverviews)),
      catchError((error) => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 403) {
            return this.adminAssetService.getTermsAndConditions().pipe(
              switchMap((response) =>
                this.dialog
                  .open(TermsConfirmationDialogComponent, {
                    data: <TermsConfirmationDialogData>{
                      description: response.description,
                      content: response.content,
                    },
                  })
                  .afterClosed()
              ),
              switchMap((dialogCloseResult: boolean) => {
                if (dialogCloseResult) {
                  return this.adminAssetService.confirmTermsAndConditions().pipe(
                    catchError((dialogError) => {
                      this.toast.showToast(this.transloco.translate('terms-conditions-confirm-error'), ToastType.ERROR);
                      return EMPTY;
                    }),
                    tap(() => {
                      this.store.dispatch(AssetsActions.fetch());
                    })
                  );
                } else {
                  return of([]);
                }
              }),
              switchMap(() => of([] as AssetOverview[]))
            );
          }
        }
        return throwError(error);
      })
    );
  }

  private transformAssetOverview(assetOverview: AssetOverview): AssetOverview {
    let { asset_due_date, project_due_date, asset_photo } = assetOverview;
    asset_due_date = Transform.date(asset_due_date);
    project_due_date = Transform.date(project_due_date);
    asset_photo = asset_photo || './assets/images/header.jpg';

    return {
      ...assetOverview,
      asset_due_date,
      project_due_date,
      asset_photo,
      project_finding_format: +assetOverview.project_finding_format,
      asset_started_at: Transform.date(assetOverview.asset_started_at),
      asset_created_at: Transform.date(assetOverview.asset_created_at),
    };
  }

  private transformResponse(assetOverviews: AssetOverview[]): AssetOverview[] {
    return _(assetOverviews)
      .filter((overview) => !!overview)
      .map(this.transformAssetOverview)
      .value();
  }
}
