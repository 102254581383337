import { Report } from '@core/models';
import { Action, createReducer, on } from '@ngrx/store';
import { ReportTemplateActions } from '@portal/admin/store/report-templates/report-template.actions';

export interface ReportTemplateState {
  reportTemplate: Report | null;
}

const reportsTemplateInitialState = {
  reportTemplate: null,
};

const reportTemplateReducer = createReducer<ReportTemplateState>(
  reportsTemplateInitialState,
  on(ReportTemplateActions.selectReportTemplate, (state, { reportTemplate }) => ({
    ...state,
    reportTemplate,
  }))
);

export namespace ReportTemplateReducer {
  export const name = 'report-template-state';

  export const initialState = reportsTemplateInitialState;

  export const reducer = (state: ReportTemplateState, action: Action) => reportTemplateReducer(state, action);
}
