import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'x-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss'],
})
export class InviteUserDialogComponent {
  model: { email: string } = {
    email: null,
  };

  constructor(private dialogRef: MatDialogRef<InviteUserDialogComponent, string>) {}

  onSubmit(): void {
    this.dialogRef.close(this.model.email);
  }
}
