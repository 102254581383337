import { Injectable } from '@angular/core';
import { Report } from '@core/models';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class FormatReportChapterTitleService {
  format(chapter: Report.Chapter): string {
    const { title, title_prefix, without_prefix } = chapter;
    return without_prefix || _.isEmpty(title_prefix) ? title : `${title_prefix} ${title}`;
  }
}
