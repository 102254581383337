import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BadgeCellRendererData } from '@portal/components/badge-cell-renderer/badge-cell-renderer.component';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { CellRendererComponent } from '@portal/components/grid/cell-renderer';

@Component({
  selector: 'x-multiple-badge-cell-renderer',
  standalone: true,
  imports: [BadgeComponent, NgIf, CommonModule],
  templateUrl: './multiple-badge-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultipleBadgeCellRendererComponent extends CellRendererComponent<BadgeCellRendererData[]> {
  trackByValue(_: number, badge: BadgeCellRendererData) {
    return badge;
  }
}
