import * as _ from 'lodash';
import { DataRoom } from './data-room';
import { Document } from './document';
import { Link } from './link';
import { Risk } from './risk';
import { Trade } from './trade';

export type Finding = FindingTDD | FindingMonitoring;
export type FindingClass = 'tdd' | 'monitoring';

export type FindingField = keyof Finding | keyof FindingTDD | keyof FindingMonitoring;

export type MonitoringFindingStatus = 'open' | 'discussion' | 'closed';

interface FindingGeneric extends Finding.Short {
  src_id: string;
  finding_type: 'finding' | 'documentation';
  buildings: string[];
  rental_areas: string[];
  trade_id: number;
  readonly trade?: Trade;
  component: string;
  finding: string;
  finding_processed: string;
  guidance: string;
  class: 'monitoring' | 'tdd';
  risk_evaluation: Risk.Low | Risk.Medium | Risk.High;
  extra_notes: string;
  photos: Document.Short[];
  documents: Document.Short[];
  overhaul_backlog_now: number;
  overhaul_backlog_now_plan: number;
  overhaul_backlog_next_year: number;
  overhaul_backlog_next_year_plan: number;
  plan_invest_next_years: number;
  plan_invest_next_years_plan: number;
  plan_invest_following_years: number;
  plan_invest_following_years_plan: number;
  risk_indication: string;
  risk_value_eur: number;
  lifetime_age: number;
  lifetime_default: number;
  lifetime_remaining: number;
  failure_probability: NumericRange<1, 5>;
  follow_up_costs: NumericRange<1, 5>;
  failure_effect: NumericRange<1, 5>;
  risk_value_result: NumericRange<1, 125>; // [1 - 125]
  created_at: Date;
  deleted_at: Date;
  published_at: Date;
  references: Link[];
  add_document: string;
  add_photo: string;
}
export interface FindingTDD extends FindingGeneric {
  class: 'tdd';
  nodes: DataRoom.Node[];
}

export type FindingMonitoringStatus = 'open' | 'followup' | 'discussion' | 'done' | 'regulation_necessary' | 'closed';

export interface FindingMonitoring extends FindingGeneric {
  class: 'monitoring';
  on_site_visit_at: Date;
  level: string;
  location: string;
  responsible_user_id: string;
  task: string;
  status: FindingMonitoringStatus;
}

export namespace Finding {
  const maxFindingFormatValue = 8;

  /**
   * 1-6 - TDD formats
   *
   * 8 - Monitoring format
   */
  export type Format = NumericRange<0, typeof maxFindingFormatValue>;
  export interface Short {
    readonly id: string;
    readonly current_no: number;
    readonly project_id: string;
    readonly class: FindingClass;
    readonly asset_id: string;
  }

  export namespace Short {
    export const transform = (short?: Short) => short;
  }

  export const defaultFormat: Format = 6;

  export const formats: readonly number[] = new Array(maxFindingFormatValue).fill(0).map((v, i) => i + 1);
  export const tddFormats: readonly number[] = new Array(6).fill(0).map((v, i) => i + 1);
  export const monitoringFormats: readonly number[] = [8];
  export const monitoringFormat = 8;

  export const investmentCostFields: readonly (keyof Finding)[] = [
    'overhaul_backlog_now',
    'overhaul_backlog_now_plan',
    'overhaul_backlog_next_year',
    'overhaul_backlog_next_year_plan',
  ];

  export const capexCostFields: readonly (keyof Finding)[] = [
    'plan_invest_next_years',
    'plan_invest_next_years_plan',
    'plan_invest_following_years',
    'plan_invest_following_years_plan',
  ];

  const format1: FindingField[] = [
    'finding_type',
    'current_no',
    'trade',
    'finding',
    'guidance',
    'risk_evaluation',
    'overhaul_backlog_now',
    'overhaul_backlog_next_year',
    'plan_invest_next_years',
    'plan_invest_following_years',
    'references',
    'published_at',
  ];

  const format2: FindingField[] = [...format1, 'buildings', 'component'];
  const format3: FindingField[] = [...format2, 'extra_notes'];
  const format4: FindingField[] = [...format3, 'risk_value_eur', 'rental_areas', 'risk_indication'];
  const format5: FindingField[] = [
    ...format4,
    'overhaul_backlog_now_plan',
    'overhaul_backlog_next_year_plan',
    'plan_invest_next_years_plan',
    'plan_invest_following_years_plan',
  ];
  const format6: FindingField[] = _.uniq([
    ...format5,
    'failure_probability',
    'follow_up_costs',
    'failure_effect',
    'risk_value_result',
  ]);

  const format8: FindingField[] = [
    'current_no',
    'trade',
    'location',
    'level',
    'component',
    'finding',
    'finding_processed',
    'task',
    'status',
    'finding_type',
    'references',
    'on_site_visit_at',
    'published_at',
  ];

  export const getFieldsByFormat = (format: Format): FindingField[] => {
    switch (format) {
      case 1:
        return format1;
      case 2:
        return format2;
      case 3:
        return format3;
      case 4:
        return format4;
      case 5:
        return format5;
      case 6:
        return format6;
      case 8:
        return format8;
    }

    return [];
  };

  export const getOverviewFieldsByFormat = (format: Format): FindingField[] => {
    if (format === 8) {
      return format8;
    }

    return [
      'current_no',
      'trade',
      'finding_type',
      'finding_processed',
      'guidance',
      'risk_evaluation',
      'references',
      'overhaul_backlog_now',
      'overhaul_backlog_next_year',
      'plan_invest_next_years',
      'plan_invest_following_years',
    ];
  };
}

export const VALUE_TO_REPLACE_ZERO = '-';
