import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';
import { RouterOutlet } from '@angular/router';

import { HeaderComponent } from '@common/components/header';
import { LanguageSwitcherModule, PageModule } from '@common';

@Component({
  selector: 'x-full-page-with-header-and-footer',
  templateUrl: './full-page-with-header-and-footer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [HeaderComponent, LanguageSwitcherModule, NgOptimizedImage, PageModule, RouterOutlet],
})
export class FullPageWithHeaderAndFooterComponent {}
