import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { Message } from '@core/models';
import { TranslocoService } from '@ngneat/transloco';
import { Dictionary } from 'lodash';
import { ResolvedDataSelectors } from '@portal/customer/state';
import { filter } from 'rxjs/operators';
import { isNotNil } from '@app/utils';
import { Store } from '@ngrx/store';

@Component({
  selector: 'x-messages-container',
  templateUrl: './messages-container.component.html',
  styleUrls: ['./messages-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesContainerComponent implements OnChanges {
  @Input() allEmptyText?: string;
  @Input() allMessages?: Message.Short[];
  @Input() allMessagesLabelTemplate?: TemplateRef<unknown>;
  @Input() canClose = true;
  @Input() currentEmptyText?: string;
  @Input() currentMessages?: Message.Short[];
  @Input() hasAllTab = true;
  @Input() messagesLabelTemplate?: TemplateRef<unknown>;
  @Input() currentTabTitle: string;
  @Input() withFrame = false;
  @Input() headerClasses = '';
  @Input() showToolbar = true;
  @Output() handleOnClose = new EventEmitter<void>();
  @Output() messageClick = new EventEmitter<Message.Short>();
  @Output() messageDeleted = new EventEmitter<Message.Short>();

  showAllMessages = false;
  subMessagesVisibility: Dictionary<boolean> = {};

  readonly isFrozen$ = this.store.select(ResolvedDataSelectors.getIsAssetFrozen).pipe(filter(isNotNil));

  constructor(
    private readonly transloco: TranslocoService,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef
  ) {}

  @Input() set selectedTab(value: 'current' | 'all' | undefined) {
    this.showAllMessages = value === 'all';
  }

  getIconTooltip = (isQuestion: boolean): string => (isQuestion ? this.transloco.translate(`question`) : '');

  isQuestion(message?: Message.Short): boolean {
    return message?.category === 'qa';
  }

  ngOnChanges({ page, messages }: SimpleChanges) {
    if (page || messages) {
      this.subMessagesVisibility = {};
    }
  }

  onMessageHasBeenRemoved = (message: Message.Short) => {
    if (this.allMessages) {
      this.allMessages = this.allMessages.filter((msg) => msg.id !== message.id);
    }
    if (this.currentMessages) {
      this.currentMessages = this.currentMessages.filter((currentMessage) => currentMessage.id !== message.id);
    }
    this.messageDeleted.emit(message);
    this.cdr.detectChanges();
  };

  onShowMessageDetails(message: Message.Short): void {
    window.openTemplateOverlay({
      data: message as Message,
      dialogType: message.category === 'tickets' ? 'ticket' : 'qa',
    });
  }

  onToggleShowSubMessages(messageId: string): void {
    this.subMessagesVisibility[messageId] = !this.subMessagesVisibility[messageId];
  }
}
