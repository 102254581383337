import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { ButtonComponent } from '@common/components/button/button.component';
import { DropdownTriggerDirective } from '@common/components/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '@common/components/dropdown/dropdown.component';
import { FilterGroupComponent } from '@common/components/filter-group/filter-group.component';
import { FiltersDropdownComponent } from '@common/components/filters-dropdown/filters-dropdown.component';
import { InputComponent } from '@common/components/input/input.component';
import { SelectComponent } from '@common/components/select/select.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { ToggleComponent } from '@common/components/toggle/toggle.component';
import { TranslocoModule } from '@ngneat/transloco';
import { CustomDateAdapter } from 'src/app/common/custom-date.adapter';
import { AutofocusDirectiveModule } from 'src/app/common/directives';
import { AssetsOverviewSearchComponent } from './assets-overview-search.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatDividerModule,
    NgxSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslocoModule,
    AutofocusDirectiveModule,
    FilterGroupComponent,
    ButtonComponent,
    DropdownComponent,
    DropdownTriggerDirective,
    SvgIconModule,
    FiltersDropdownComponent,
    SelectComponent,
    InputComponent,
    ToggleComponent,
  ],
  declarations: [AssetsOverviewSearchComponent],
  exports: [AssetsOverviewSearchComponent],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class AssetsOverviewSearchModule {}
