import { Pipe, PipeTransform } from '@angular/core';
import { Trade } from '@core/models';

@Pipe({
  name: 'trade',
})
export class TradePipe implements PipeTransform {
  transform(trade: Trade): string {
    return `${trade.register} ${trade.description}`;
  }
}
