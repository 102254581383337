import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { Constants, IHeaderParams } from '@ag-grid-community/core';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
} from '@angular/core';
import { LabelComponent } from '@common';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface HeaderCellComponentParams extends IHeaderParams {
  displayName: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  getLabelPortal(): ComponentPortal<LabelComponent<unknown>>;
}

@Component({
  selector: 'x-header-cell',
  templateUrl: './header-cell.component.html',
  styleUrls: ['./header-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCellComponent implements IHeaderAngularComp, OnDestroy {
  @HostBinding('class.sortable')
  isSortable = true;
  @HostBinding('class.custom-header-cell')
  @Input()
  isCustomHeaderCell = true;

  activeSort = null;
  params: HeaderCellComponentParams;

  readonly defaultSortingOrder = [Constants.SORT_DESC, Constants.SORT_ASC, null];
  readonly destroy$ = new Subject<void>();
  readonly sortDirection = {
    Asc: Constants.SORT_ASC,
    Desc: Constants.SORT_DESC,
  };

  constructor(private ref: ChangeDetectorRef) {}

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() set sort(value: any) {
    this.activeSort = value;
  }

  @Input() set sortable(value: boolean) {
    this.isSortable = value;
  }

  get displayName(): string {
    return this.params?.displayName;
  }

  get isDraggable(): boolean {
    return this.params && !this.params.column.getColDef().lockPosition;
  }

  get labelPortal(): ComponentPortal<LabelComponent<unknown>> {
    return this.params?.getLabelPortal?.();
  }

  @HostListener('click', ['$event'])
  onSortClick(event: MouseEvent): void {
    if (!this.params || !this.isSortable) {
      return;
    }

    const sortingOrder = this.params.column.getColDef().sortingOrder || this.defaultSortingOrder;
    const nextSortIndex = sortingOrder.indexOf(this.activeSort) + 1;

    this.params.setSort(sortingOrder[nextSortIndex % sortingOrder.length], event.shiftKey);
  }

  agInit(params: HeaderCellComponentParams): void {
    this.params = params;
    this.isSortable = this.params?.column.getColDef().sortable !== false;

    fromEvent(params.column, 'sortChanged')
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.onSortChanged());

    this.onSortChanged();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  refresh(params: HeaderCellComponentParams): boolean {
    this.params = params;
    return true;
  }

  private onSortChanged = () => {
    const sort = this.params.column.getSort();

    if ((sort || this.activeSort) && sort !== this.activeSort) {
      this.activeSort = sort;
      this.ref.detectChanges();
    }
  };
}
