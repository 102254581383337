import { ColDef } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertType } from '@common';
import { User } from '@core/models';
import { UserService } from '@core/services';
import { FormatDateService } from '@core/services/format-date.service';
import { DialogActions } from '@core/state';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BaseGrid, BaseGridComponent, GridHelperService } from '@portal/components/grid';
import * as _ from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import { AddressCellRendererComponent } from '../../grid/address-cell-renderer';
import { PortalUserHeaderCellComponent } from '../portal-user-header-cell/portal-user-header-cell.component';
import {
  PortalUserStatusRendererComponent,
  PortalUserStatusRendererParams,
} from '../portal-user-status-renderer/portal-user-status-renderer.component';
import { InviteUserDialogComponent } from './invite-user-dialog/invite-user-dialog.component';

@Component({
  selector: 'x-portal-users',
  templateUrl: './portal-users.component.html',
  styleUrls: ['./portal-users.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalUsersComponent extends BaseGridComponent<User> {
  @ViewChild('invitationFailedTemplate') invitationFailedTemplate: TemplateRef<unknown>;
  @ViewChild('statusUpdateFailedTemplate') statusUpdateFailedTemplate: TemplateRef<unknown>;

  defaultColDef: ColDef = {
    ...BaseGrid.defaultColDef,
    tooltipValueGetter: ({ value }) => {
      if (value instanceof Date) {
        return this.getDateCellRenderer(value);
      }

      return value;
    },
  };

  readonly rowData$ = new BehaviorSubject<User[]>([]);

  constructor(
    protected componentRef: ElementRef<HTMLElement>,
    protected formatDateService: FormatDateService,
    private userService: UserService,
    private dialog: MatDialog,
    private store: Store,
    gridHelper: GridHelperService,
    transloco: TranslocoService
  ) {
    super(componentRef, formatDateService, gridHelper, transloco);

    this.fetchUsers();
  }

  onInviteUser(): void {
    this.dialog
      .open<InviteUserDialogComponent, void, string>(InviteUserDialogComponent)
      .afterClosed()
      .pipe(
        first(),
        filter((result) => !!result),
        mergeMap((result) => this.userService.inviteUser(result))
      )
      .subscribe({
        next: () => this.fetchUsers(),
        error: () => this.showError(this.invitationFailedTemplate),
      });
  }

  onRowClicked(): void {}

  protected getColDef(field: keyof User, colDef: Partial<ColDef> = {}): ColDef {
    return {
      ...super.getColDef(field, colDef),
      headerComponentFramework: PortalUserHeaderCellComponent,
    };
  }

  protected getColumnDefs(): ColDef[] {
    return [
      this.getColDef('firstname', { cellClass: 'user-select-text' }),
      this.getColDef('lastname', { cellClass: 'user-select-text' }),
      this.getColDef('email', { cellClass: 'user-select-text' }),
      this.getColDef('company'),
      this.getColDef('status', {
        cellRendererFramework: PortalUserStatusRendererComponent,
        cellRendererParams: ({ data }) =>
          <PortalUserStatusRendererParams>{
            onChange: (status) => this.setUserStatus(data, status),
          },
      }),
      this.getColDef('phone'),
      this.getColDef('created_at', {
        cellRenderer: ({ value }) => this.getDateCellRenderer(value),
      }),
      this.getColDef('address', {
        hide: true,
        cellRendererFramework: AddressCellRendererComponent,
      }),
      this.getColDef('title', { hide: true }),
      this.getColDef('best_reachable', { hide: true }),
    ];
  }

  protected setUserStatus(user: User, status: User.Status): void {
    this.userService
      .setUserStatus(user, status)
      .pipe(first())
      .subscribe({
        next: () => this.fetchUsers(),
        error: () => this.showError(this.statusUpdateFailedTemplate),
      });
  }

  private fetchUsers(): void {
    this.userService
      .fetchListOfUsers()
      .pipe(first())
      .subscribe({
        next: (members) => this.rowData$.next(_.map(members, ({ user }) => user)),
        error: () => this.rowData$.next(null),
      });
  }

  private showError(template: TemplateRef<unknown>): void {
    this.store.dispatch(
      DialogActions.showAlert({
        data: {
          type: AlertType.Error,
          template: () => template,
        },
      })
    );
  }
}
