import { ENTER } from '@angular/cdk/keycodes';
import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ISelectOption } from '@common/components/select/select.model';
import { Message, Report } from '@core/models';

export interface AddMessageDialogComponentData {
  color: Message.Color;
  fields?: Report.Field[];
  message: string;
}

@Component({
  selector: 'x-add-message-dialog',
  templateUrl: './add-message-dialog.component.html',
  styleUrls: ['./add-message-dialog.component.scss'],
})
export class AddMessageDialogComponent {
  readonly predefinedColors: Message.Color[] = ['grey', 'red', 'green', 'blue', 'yellow'];
  model: Partial<Message.NewNote> = {
    message: '',
    color: this.predefinedColors[0],
  };
  selectedColor = this.predefinedColors[0];

  constructor(
    public dialogRef: MatDialogRef<AddMessageDialogComponent, Partial<Message.NewNote>>,
    @Inject(MAT_DIALOG_DATA) public data: AddMessageDialogComponentData
  ) {
    if (this.isResponse) {
      this.selectedColor = data.color;
      this.model.color = data.color;
    }
  }

  get isResponse(): boolean {
    return !!this?.data?.color;
  }

  @HostListener('keydown', ['$event'])
  onSubmitByEnter(event: KeyboardEvent): void {
    if (event.keyCode === ENTER && event.ctrlKey) {
      this.onSubmit();
    }
  }

  onSelectColor(color: Message.Color): void {
    this.selectedColor = color;
    this.model.color = color;
  }

  getSelectOptions(fields: Report.Field[]): ISelectOption<string>[] {
    return fields.map((field) => ({
      value: field.title,
      label: field.title,
    }));
  }
  onSubmit(): void {
    this.dialogRef.close(this.model);
  }

  onClose() {
    this.dialogRef.close(null);
  }
}
