import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LoginDialogComponent } from './login-dialog.component';

@NgModule({
  imports: [FormsModule, CommonModule, MatDialogModule, RouterModule, TranslocoModule],
  declarations: [LoginDialogComponent],
  exports: [LoginDialogComponent],
})
export class LoginDialogModule {}
