import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component, ElementRef, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeResourceUrl } from '@angular/platform-browser';
import { api, Document, Message } from '@core/models';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject, combineLatest, forkJoin, Observable, of } from 'rxjs';
import { concatMap, map as rxjsMap, take } from 'rxjs/operators';
import { ToastService, ToastType } from '@core/toast';
import {
  QaResponseData,
  WriteResponseModelOutput,
} from '@portal/customer/components/asset-details/asset-tickets-container/components/write-response-form/write-response-form.component';
import { DeleteAssetLinkReqBody } from '@core/services';
import { ProcessedReference } from '@portal/customer/components/attach-reference-button/reference.model';

export interface QAAnswerDialogComponentData {
  message: Message;
  submit: (response: QaResponseData) => Observable<void | never>;
  submitFiles: (files: File[]) => Observable<void | never>;
  removeFiles: (files: DeleteAssetLinkReqBody[]) => Observable<unknown>;
  saveDraft: (response: QaResponseData) => Observable<void | never>;
}

export type AnswerDialogResponse = boolean | undefined;

export type FileWithImageSource = Extend<File, { imageSrc?: SafeResourceUrl }>;

@Component({
  templateUrl: './qa-answer-dialog.component.html',
  styleUrls: ['./qa-answer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QAAnswerDialogComponent {
  @ViewChild('fileInput') fileInput?: ElementRef<HTMLInputElement>;

  readonly isSaveInProgress$ = new BehaviorSubject<boolean>(false);
  readonly isSaveDraftInProgress$ = new BehaviorSubject<boolean>(false);
  readonly isResponseEmpty$ = new BehaviorSubject<boolean>(true);

  readonly isSubmitDisabled$ = combineLatest([
    this.isSaveInProgress$,
    this.isSaveDraftInProgress$,
    this.isResponseEmpty$,
  ]).pipe(
    rxjsMap(
      ([isSaveInProgress, isSaveDraftInProgress, isResponseEmpty]) =>
        isSaveInProgress || isSaveDraftInProgress || isResponseEmpty
    )
  );

  writeResponseModelOutput: WriteResponseModelOutput;
  message: Message;

  get question(): string {
    return this.data.message.message;
  }

  get documents(): Document.Short[] {
    return this.data.message.documents;
  }

  get photos(): Document.Short[] {
    return this.data.message.photos;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QAAnswerDialogComponentData,
    public dialogRef: MatDialogRef<Message, AnswerDialogResponse>,
    private readonly toast: ToastService,
    private readonly transloco: TranslocoService
  ) {
    const { message } = data;
    this.message = message;
    this.writeResponseModelOutput = {
      response: message?.response || '',
      files: [],
      references: [],
    };
    this.isResponseEmpty$.next(!this.writeResponseModelOutput?.response);
  }

  onChanges(model: WriteResponseModelOutput) {
    this.writeResponseModelOutput = model;
    this.isResponseEmpty$.next(!model.response);
  }

  onSubmit(): void {
    this.isSaveInProgress$.next(true);
    this.dialogRef.disableClose = true;

    forkJoin([this.saveFiles(), this.removeFiles()])
      .pipe(
        concatMap(() =>
          this.data.submit({
            response: this.writeResponseModelOutput?.response,
            references: this.writeResponseModelOutput?.references,
          })
        ),
        take(1)
      )
      .subscribe({
        next: () => {
          this.showSuccess(this.transloco.translate('answer-message-dialog.answer-saved'));
          this.dialogRef.close(true);
        },
        error: (error: HttpErrorResponse) => this.showError(error),
      });
  }

  onSaveDraft(): void {
    this.isSaveDraftInProgress$.next(true);
    this.dialogRef.disableClose = true;

    forkJoin([this.saveFiles(), this.removeFiles()])
      .pipe(
        concatMap(() =>
          this.data.saveDraft({
            response: this.writeResponseModelOutput?.response,
            references: this.writeResponseModelOutput?.references,
          })
        ),
        take(1)
      )
      .subscribe({
        next: () => {
          this.showSuccess(this.transloco.translate('answer-message-dialog.draft-saved'));
          this.dialogRef.close(true);
        },
        error: (error: HttpErrorResponse) => this.showError(error),
      });
  }

  saveFiles(): Observable<void | never> {
    const { files } = this.writeResponseModelOutput;

    if (!files?.length) {
      return of(true) as Observable<never>;
    }

    return this.data.submitFiles(files).pipe(take(1));
  }

  showError(error: api.ErrorResponse<unknown>): void {
    this.isSaveInProgress$.next(false);
    this.isSaveDraftInProgress$.next(false);
    this.dialogRef.disableClose = false;

    const text = error.error?.errors?.map((e) => e.details).join('\n') || error.message;

    this.toast.showToast(text, ToastType.ERROR);
  }

  showSuccess(text: string): void {
    this.toast.showToast(text, ToastType.SUCCESS);
  }

  getResponseReferences(message: Message): ProcessedReference[] | undefined {
    return Message.extractResponseReferences(message);
  }

  private removeFiles() {
    const { filesToRemove } = this.writeResponseModelOutput || {};
    if (!filesToRemove?.length) {
      return of(true) as Observable<never>;
    }
    return this.data.removeFiles(filesToRemove).pipe(take(1));
  }
}
