import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { SnackBarModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { GridModule } from '@portal/components/grid';
import { QaHistoryDialogComponent } from './qa-history-dialog.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslocoModule,
    MatIconModule,
    MatButtonModule,
    GridModule,
    AgGridModule,
    SnackBarModule,
    DialogComponent,
  ],
  declarations: [QaHistoryDialogComponent],
  exports: [QaHistoryDialogComponent, MatDialogModule],
})
export class QaHistoryDialogModule {}
