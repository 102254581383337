<ng-container *ngIf="file$ | async as file">
  <div class="document-viewer-container">
    <div class="document-header">
      <x-breadcrumbs-document-info [document]="document$ | async"></x-breadcrumbs-document-info>
    </div>

    <div class="document-viewer">
      <ng2-pdfjs-viewer
        *ngIf="!isImage(file.type); else imageTemplate"
        #pdfViewer
        [viewerId]="file.id"
        [viewerFolder]="viewerFolder"
        [pdfSrc]="file.url"
        [download]="true"
        [viewBookmark]="true"
        [showSpinner]="true"
        [print]="false"
        [openFile]="false"
        (onDocumentLoad)="onPDFLoaded()"
        (onPageChange)="onPageChange($event)"
      ></ng2-pdfjs-viewer>

      <div #tooltip class="tooltip"></div>
    </div>
  </div>

  <ng-template #imageTemplate>
    <div class="image-container">
      <img [src]="sanitizer.bypassSecurityTrustResourceUrl(file.url)" />
    </div>
  </ng-template>
</ng-container>
