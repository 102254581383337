import { Directive, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Finding } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  EXTERNAL_REPORT_PREVIEW_ACTION,
  ReportPreviewActions,
} from '@portal/customer/components/asset-details/asset-reports-builder/asset-report-chapter-html/report-preview.model';
import { ResolvedDataSelectors } from '@portal/customer/state';
import { FindingService } from '@portal/services/finding.service';
import { Observable, of } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[findingPreview]', // eslint-disable-line
})
export class FindingPreviewDirective implements OnChanges {
  @Input() findingPreview = true;

  @Input() finding?: Finding;
  @Input() format?: Finding.Format | number;
  @Input() findingShort?: Finding.Short;
  @Input() isClosingAllDialogsOnOpen = true;
  @Input() isSmall = true;

  readonly project$ = this.store.select(ResolvedDataSelectors.getProject);

  private finding$: Observable<Finding | undefined> = of(undefined);

  constructor(
    private readonly findingService: FindingService,
    private readonly dialog: MatDialog,
    private readonly store: Store
  ) {}

  @HostListener('click', ['$event'])
  onElementClick(event: MouseEvent) {
    if (this.findingPreview) {
      event.preventDefault();
      event.stopPropagation();
      this.showFindingPreview();
    }
  }

  ngOnChanges({ finding, findingShort }: SimpleChanges): void {
    if (finding) {
      this.finding$ = of(this.finding);
    }

    if (findingShort && this.findingShort) {
      this.finding$ = this.findingService.getFindingFromShort(this.findingShort);
    }
  }

  private showFindingPreview(): void {
    this.finding$
      .pipe(
        filter(Boolean),
        take(1),
        switchMap((finding: Finding) => {
          const findingFormat$ = this.format
            ? of(this.format)
            : this.project$.pipe(
                map((project) =>
                  finding.class === 'tdd' ? project?.tdd_finding_format : project?.monitoring_finding_format
                )
              );
          return findingFormat$.pipe(map((findingFormat) => [findingFormat, finding] as [Finding.Format, Finding]));
        }),
        untilDestroyed(this)
      )
      .subscribe(([findingFormat, finding]) => {
        this.format = findingFormat;
        if (this.isClosingAllDialogsOnOpen) {
          this.dialog.closeAll();
        }
        if (window.frameElement) {
          const findingEvent = new CustomEvent(EXTERNAL_REPORT_PREVIEW_ACTION, {
            detail: { data: finding, type: ReportPreviewActions.OPEN_FINDINGS_DIALOG },
          });
          window.parent.document.dispatchEvent(findingEvent);
        }
      });
  }
}
