import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { FileIconComponent } from './file-icon.component';

@NgModule({
  imports: [CommonModule, SvgIconModule],
  declarations: [FileIconComponent],
  exports: [FileIconComponent],
})
export class FileIconModule {}
