import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api, DataRoom, FetchStatus, Group, Message, QA, Transform } from '@core/models';
import { PredefinedMessage } from '@core/models/predefined-message';
import { Priority } from '@core/models/priority';
import { TimeRange } from '@core/models/time-range';
import { FormatDateService, PORTAL_API_URL } from '@core/services';
import { QAFilter } from '@portal/components/qa-overview/qa-overview-search/qa-filter';
import { Reference } from '@portal/customer/components/attach-reference-button/reference.model';
import * as _ from 'lodash';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import TicketType = Message.TicketType;
import { Document } from '@core/models/document';

export interface QASearchFilter extends api.Search {
  status: QA.Status;
}

export interface MoveAttachmentsPayload {
  nodes: DataRoom.NodeShort[];
  photos: Document.PhotoShort[];
}

export interface DocumentLocation {
  documentId: string;
  location: 'photos' | 'dataroom';
  documentName?: string;
  dirId?: string;
  dirName?: string;
  permission?: DataRoom.PermissionOption;
}

export type StatusType = 'open' | 'answered' | 'closed';
export type ScopeType = 'general' | 'finding' | 'report' | 'report_chapter' | 'photo' | 'document' | 'node';
export type MessageCategory = 'qa' | 'tickets';

export interface GetMessagesFilters {
  only_my_tickets_and_tasks?: boolean;
  type?: TicketType;
  trade_id?: number;
  scope?: ScopeType;
  status?: StatusType;
  priority?: Priority;
  time_frame?: TimeRange;
  due_date_time_frame?: TimeRange;
  date_from?: string;
  due_date_from?: string;
  date_to?: string;
  due_date_to?: string;
  search_term?: string;
  questioner_group?: string;
  adapted?: boolean;
  only_exceeded_final_due_date?: boolean;
  only_not_exceeded_final_due_date?: boolean;
}

export interface GetTicketsFilters {
  only_my_tickets_and_tasks?: boolean;
  type?: TicketType;
  trade_id?: number;
  scope?: ScopeType;
  status?: StatusType[] | null;
  priority?: Priority[] | null;
  time_frame?: TimeRange;
  due_date_time_frame?: TimeRange;
  date_from?: string;
  due_date_from?: string;
  date_to?: string;
  due_date_to?: string;
  search_term?: string;
  questioner_group?: string;
  adapted?: boolean;
  only_exceeded_final_due_date?: boolean;
  only_not_exceeded_final_due_date?: boolean;
}

export interface TicketAnswerPayload {
  referenced_document_ids?: string[];
  referenced_finding_ids?: string[];
  referenced_node_ids?: string[];
  referenced_qa_ids?: string[];
  referenced_report_ids?: string[];
  referenced_ticket_ids?: string[];
  response: string;
}

export interface PutDocIntoDataroomParams {
  assetId: string;
  docId: string;
  parentNodeId: string;
  label: string;
  permissionOption?: DataRoom.PermissionOption;
  indexPoint?: string;
}

export interface TicketGracePeriodsPayload {
  grace_period_first?: string;
  grace_period_second?: string;
  grace_period_third?: string;
  last_extension?: boolean;
  legal_notice?: string;
}

export interface QaLimitsStatistics {
  daily_limit: number;
  daily_use: number;
  total_limit: number;
  total_use: number;
}

export type QASearch = Partial<{
  tags: string[];
}>;
@Injectable({
  providedIn: 'root',
})
export class QAService {
  // eslint-disable-next-line max-len
  protected readonly apiUrl = `${PORTAL_API_URL}/assets`;

  constructor(private http: HttpClient, private formatDateService: FormatDateService) {}

  ask(params: { asset_id: string; question: Message.NewQuestion }): Observable<Message> {
    if (!params.asset_id) {
      return EMPTY;
    }
    if (params.question.reference) {
      params.question = {
        ...params.question,
        ...Message.getMessageReferenceIdField(params.question.reference),
      };
      delete params.question.reference;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/qa`;
    return this.http.post<Message>(url, params.question).pipe(map((message) => this.transformMessage(message)));
  }

  createTicket(assetId: string, ticket: Message.NewTicket) {
    const url = `${this.apiUrl}/${assetId}/messages/tickets`;
    return this.http.post<Message>(url, ticket).pipe(map((message) => this.transformMessage(message)));
  }

  answer(
    params: { asset_id: string; qid: string; answer: string; references?: Reference[] },
    category: MessageCategory = 'qa'
  ): Observable<Message> {
    if (!params.asset_id || !params.qid) {
      return EMPTY;
    }
    const payload = this.getAnswerPayloadData(params.answer, params?.references);
    const url = `${this.apiUrl}/${params.asset_id}/messages/${category}/${params.qid}/response`;
    return this.http.post<Message>(url, payload).pipe(map((message) => this.transformMessage(message)));
  }

  draftAnswer(
    params: { asset_id: string; qid: string; answer: string; references?: Reference[] },
    category: MessageCategory = 'qa'
  ): Observable<Message> {
    if (!params.asset_id || !params.qid) {
      return EMPTY;
    }

    const payload = this.getAnswerPayloadData(params.answer, params?.references);
    const url = `${this.apiUrl}/${params.asset_id}/messages/${category}/${params.qid}/response-draft`;
    return this.http.post<Message>(url, payload).pipe(map((message) => this.transformMessage(message)));
  }

  getHistory(params: { asset_id: string; qid: string }, category: MessageCategory): Observable<Message.History[]> {
    if (!params.asset_id || !params.qid) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/${category}/${params.qid}/history`;
    return this.http.get<Message.History[] | null>(url).pipe(
      map((logs) => logs || []),
      map((logs) => logs.map(Message.MessageHistory.transform))
    );
  }

  releaseMessageToAllGroup(assetId: string, messageId: string, category: MessageCategory): Observable<Message> {
    const url = `${this.apiUrl}/${assetId}/messages/${category}/${messageId}/release`;
    return this.http.post<Message>(url, {});
  }

  approve(params: {
    asset_id: string;
    qid: string;
    type: 'message' | 'answer';
    approval: QA.Approval;
  }): Observable<void> {
    if (!params.asset_id || !params.qid || !params.type) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/qa/${params.qid}/${params.type}/approve`;
    return this.http.post<void>(url, params.approval);
  }

  approveTicket(params: {
    asset_id: string;
    ticketId: string;
    type: 'message' | 'answer';
    approval: QA.Approval;
  }): Observable<void> {
    if (!params.asset_id || !params.ticketId || !params.type) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/tickets/${params.ticketId}/${params.type}/approve`;
    return this.http.post<void>(url, params.approval);
  }

  close(params: { asset_id: string; id: string }, category: MessageCategory = 'qa'): Observable<void> {
    if (!params.asset_id || !params.id) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/${category}/${params.id}/close`;
    return this.http.post<void>(url, null);
  }

  getMessages(
    assetId: string,
    category: MessageCategory,
    filters?: GetMessagesFilters | GetTicketsFilters,
    until?: Date
  ): Observable<Message[]> {
    const url = `${this.apiUrl}/${assetId}/messages/${category}`;
    return this.http
      .get<Message[]>(url, {
        params: this.prepareMessagesFilterParams(filters, until),
      })
      .pipe(map((messages) => _.map(messages, (message) => this.transformMessage(message))));
  }

  prepareMessagesFilterParams(filters?: GetMessagesFilters | GetTicketsFilters, until?: Date): HttpParams {
    if (until) {
      filters = <unknown>{
        ...filters,
        timestamp_until: this.formatDateService.format(until, 'yyyy-MM-dd HH:mm:ss', 'UTC'),
      };
    }
    const params: HttpParams = <HttpParams>(
      Object.keys(filters).reduce((acc, key) => ({ ...acc, ...(filters[key] ? { [key]: filters[key] } : {}) }), {})
    );
    if (params['adapted'] === 'true' || params['adapted'] === true) {
      delete params['adapted'];
      params['filter'] = 'adapted';
    } else {
      delete params['filter'];
      delete params['adapted'];
    }
    return params;
  }

  getPredefinedMessages(asset_id: string, filter: QASearch = {}): Observable<PredefinedMessage[]> {
    if (!asset_id) {
      return EMPTY;
    }

    let params: HttpParams;
    if (filter) {
      params = <HttpParams>(<unknown>{
        ...(filter || {}),
      });
    }

    const url = `${this.apiUrl}/${asset_id}/messages/qa/predefined-messages`;
    return this.http.get<PredefinedMessage[]>(url, { params });
  }

  getCurrentQaLimitStatistics(asset_id: string): Observable<QaLimitsStatistics> {
    const url = `${this.apiUrl}/${asset_id}/messages/qa/limits`;
    return this.http.get<QaLimitsStatistics>(url);
  }

  search({ asset_id, filter }: { asset_id: string; filter: QAFilter }): Observable<FetchStatus<api.SearchResult[]>> {
    if (!asset_id) {
      return EMPTY;
    }

    let httpParams: HttpParams;
    if (filter) {
      httpParams = <HttpParams>(<unknown>_.omitBy(
        <QASearchFilter>{
          status: filter.status,
          query: filter.query,
          category: 'qa',
        },
        _.isNil
      ));
    }

    const url = `${this.apiUrl}/${asset_id}/messages/search`;
    return this.http
      .get<api.PaginationResponse<api.SearchResult>>(url, {
        params: httpParams,
      })
      .pipe(
        map((response) => ({
          entity: _.map(response.data, Transform.searchResult),
        })),
        catchError((error) =>
          of({
            error,
          })
        )
      );
  }

  getReportMessages(params: { asset_id: string; document_id: string }): Observable<Message.Short[]> {
    if (!params.asset_id || !params.document_id) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/documents/${params.document_id}/messages`;
    return this.http
      .get<Message.Short[]>(url)
      .pipe(map((messages) => _.map(messages, (message) => Message.Short.transform(message))));
  }

  getMessage(params: { asset_id: string; qid: string }): Observable<Message> {
    if (!params.asset_id || !params.qid) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/qa/${params.qid}`;
    return this.http.get<Message>(url).pipe(map((message) => this.transformMessage(message)));
  }

  getTicket(params: { asset_id: string; id: string }): Observable<Message> {
    if (!params.asset_id || !params.id) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/tickets/${params.id}`;
    return this.http.get<Message>(url).pipe(map((message) => this.transformMessage(message)));
  }

  getGroups(params: { asset_id: string }, category: MessageCategory = 'qa'): Observable<Group[]> {
    if (!params.asset_id) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/${category}/groups`;
    return this.http.get<Group[]>(url).pipe(map((groups) => _.map(groups, Transform.group)));
  }

  getForwardGroups(
    params: { asset_id: string; message_id: string },
    category: MessageCategory = 'qa'
  ): Observable<Group[]> {
    if (!params?.asset_id || !params?.message_id) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/${category}/${params.message_id}/forward/groups`;
    return this.http.get<Group[]>(url).pipe(map((groups) => _.map(groups, Transform.group)));
  }

  forward(
    params: { asset_id: string; message_id: string; group_id: string; user_id?: string },
    category: MessageCategory = 'qa'
  ): Observable<void> {
    if (!params?.asset_id || !params?.message_id || !params?.group_id) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${params.asset_id}/messages/${category}/${params.message_id}/forward`;
    return this.http.post<void>(url, {
      group_id: params.group_id,
      ...(params?.user_id ? { user_id: params.user_id } : {}),
    });
  }

  extendTicketDueDate(params: { id: string; asset_id: string; due_date?: string }): Observable<void> {
    const url = `${this.apiUrl}/${params.asset_id}/messages/tickets/${params.id}`;
    return this.http.patch<void>(url, {
      due_date: params.due_date,
    });
  }

  setTicketGracePeriodDate(params: {
    id: string;
    asset_id: string;
    payload?: TicketGracePeriodsPayload;
  }): Observable<void> {
    const url = `${this.apiUrl}/${params.asset_id}/messages/tickets/${params.id}`;
    return this.http.patch<void>(url, params.payload);
  }

  getLegalNotices(assetId): Observable<string[]> {
    const url = `${this.apiUrl}/${assetId}/messages/tickets/legal_notices`;
    return this.http
      .get<{ id: string; message: string }[]>(url)
      .pipe(map((list) => list.map(({ message }) => message)));
  }

  export(messages: Message[], category: MessageCategory): Observable<void> {
    if (_.isEmpty(messages)) {
      return EMPTY;
    }

    const message_ids = _.map(messages, ({ id }) => id);
    const { asset_id } = _.head(messages);

    const url = `${this.apiUrl}/${asset_id}/messages/export`;
    return this.http.post<void>(url, {
      category,
      message_ids,
    });
  }

  moveAttachments(assetId: string, documents: DocumentLocation[], category: MessageCategory = 'tickets') {
    const url = `${this.apiUrl}/${assetId}/messages/${category}/move-attachments`;
    const payload: MoveAttachmentsPayload = {
      nodes: documents
        .filter((doc) => doc.location === 'dataroom')
        .map((doc) => ({
          label: doc.documentName,
          document_id: doc.documentId,
          parent_node_id: doc.dirId,
          permission_option: doc.permission,
        })),
      photos: documents
        .filter((doc) => doc.location === 'photos')
        .map((doc) => ({
          type: 'photo',
          document_id: doc.documentId,
        })),
    };
    return this.http.post<void>(url, payload);
  }

  private getAnswerPayloadData(response: string, references?: Reference[]): TicketAnswerPayload {
    const payload: TicketAnswerPayload = { response };

    if (references?.length) {
      const payloadMapping: Record<string, string> = {
        document: 'referenced_node_ids',
        finding: 'referenced_finding_ids',
        qa: 'referenced_qa_ids',
        ticket: 'referenced_ticket_ids',
        photo: 'referenced_document_ids',
        report: 'referenced_report_ids',
      };

      references?.forEach((reference) => {
        const refType = reference.ref_type;
        const payloadProperty = payloadMapping[refType];

        if (payloadProperty) {
          if (!payload[payloadProperty]) {
            payload[payloadProperty] = [];
          }
          payload[payloadProperty].push(reference.ref_id);
        }
      });
    }

    return payload;
  }

  private transformMessage(message: Message): Message {
    return Message.transform(message);
  }
}
