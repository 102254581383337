import { Action, createReducer, on } from '@ngrx/store';

import { User } from '../../models';
import { UserActions } from './users.actions';

export interface UsersState {
  user: User.ActiveUser;
  users: User[];
}

const usersInitialState: UsersState = {
  user: null,
  users: [],
};

const usersReducer = createReducer<UsersState>(
  usersInitialState,
  on(UserActions.fetchUserSuccess, (state, { user }) => ({
    ...state,
    user,
  })),
  on(UserActions.fetchUserFail, UserActions.clearUser, (state) => ({
    ...state,
    user: null,
  }))
);

export namespace UsersReducer {
  export const name = 'users-state';

  export const initialState = usersInitialState;

  export const reducer = (state: UsersState, action: Action) => usersReducer(state, action);
}
