import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userInitials',
  standalone: true,
  pure: true,
})
export class UserInitialsPipe implements PipeTransform {
  transform({ firstname, lastname }: { firstname: string; lastname: string }, ...args: unknown[]): string {
    return `${firstname ? firstname[0] : ''}${lastname ? lastname[0] : ''}`;
  }
}
