import { Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/* eslint-disable */
@Directive({
  selector: '[resizable]', // eslint-disable-line
})
export class ResizableDirective implements OnInit, OnDestroy {
  @Input() resizable?: unknown;

  private isGrabbing = false;
  private previousX = 0;
  private previousY = 0;
  private hostLeft = 0;
  private hostLeftMargin = 0;
  private hostRight = 0;
  private hostRightMargin = 0;
  private hostTop = 0;
  private hostTopMargin = 0;
  private hostBottom = 0;
  private hostBottomMargin = 0;
  private hostMaxWidth = 0;
  private hostMinWidth = 0;
  private hostMaxHeight = 0;
  private hostMinHeight = 0;
  private onDestroy$ = new Subject();
  private resizingStart = new EventEmitter<void>();
  private resizingElement = new EventEmitter<void>();
  private resizingEnd = new EventEmitter<void>();

  private hostCoordinates: DOMRect;
  private mouseOnBorder: string | boolean;

  private hostWidth: number;
  private hostHeight: number;

  private dragDirections = {
    top: false,
    left: false,
    bottom: true,
    right: false,
  };

  constructor(private renderer: Renderer2, private elementRef: ElementRef<HTMLElement>, private zone: NgZone) {}

  mouseMoveOnElement(event) {
    const elRightBorder = this.hostCoordinates.right;
    const elLeftBorder = this.hostCoordinates.left;
    const elTopBorder = this.hostCoordinates.top;
    const elBottomBorder = this.hostCoordinates.bottom;
    if (elRightBorder - event.clientX < 10 && elRightBorder - event.clientX > -10 && !this.isGrabbing) {
      if (
        elTopBorder - event.clientY < 10 &&
        elTopBorder - event.clientY > -10 &&
        this.dragDirections.top &&
        this.dragDirections.right
      ) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'nesw-resize');
        this.mouseOnBorder = 'top_right';
      } else if (
        elBottomBorder - event.clientY < 10 &&
        elBottomBorder - event.clientY > -10 &&
        this.dragDirections.bottom &&
        this.dragDirections.right
      ) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'se-resize');
        this.mouseOnBorder = 'bottom_right';
      } else if (this.dragDirections.right) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'e-resize');
        this.mouseOnBorder = 'right';
      }
    } else if (elBottomBorder - event.clientY < 10 && elBottomBorder - event.clientY > -10 && !this.isGrabbing) {
      if (elLeftBorder - event.clientX < 10 && elLeftBorder - event.clientX > -10) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'nesw-resize');
        this.mouseOnBorder = 'bottom_left';
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 's-resize');
        this.mouseOnBorder = 'bottom';
      }
    } else if (elLeftBorder - event.clientX < 10 && elLeftBorder - event.clientX > -10 && !this.isGrabbing) {
      if (
        elTopBorder - event.clientY < 10 &&
        elTopBorder - event.clientY > -10 &&
        this.dragDirections.top &&
        this.dragDirections.left
      ) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'se-resize');
        this.mouseOnBorder = 'top_left';
      } else if (this.dragDirections.left) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 'e-resize');
        this.mouseOnBorder = 'left';
      }
    } else if (elTopBorder - event.clientY < 10 && elTopBorder - event.clientY > -10 && !this.isGrabbing) {
      if (this.dragDirections.top) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'cursor', 's-resize');
        this.mouseOnBorder = 'top';
      }
    } else if (!this.isGrabbing) {
      this.renderer.removeStyle(this.elementRef.nativeElement, 'cursor');
      this.mouseOnBorder = false;
    }
    this.hostCoordinates = this.elementRef.nativeElement.getBoundingClientRect();
  }

  onMouseDown(event) {
    if (this.mouseOnBorder) {
      this.hostWidth = parseFloat(getComputedStyle(this.elementRef.nativeElement).width);
      this.hostHeight = parseFloat(getComputedStyle(this.elementRef.nativeElement).height);
      this.hostLeft = parseFloat(getComputedStyle(this.elementRef.nativeElement).left);
      this.hostRight = parseFloat(getComputedStyle(this.elementRef.nativeElement).right);
      this.hostLeftMargin = parseFloat(getComputedStyle(this.elementRef.nativeElement).marginLeft);
      this.hostRightMargin = parseFloat(getComputedStyle(this.elementRef.nativeElement).marginRight);
      this.hostTop = parseFloat(getComputedStyle(this.elementRef.nativeElement).top);
      this.hostTopMargin = parseFloat(getComputedStyle(this.elementRef.nativeElement).marginTop);
      this.hostBottom = parseFloat(getComputedStyle(this.elementRef.nativeElement).bottom);
      this.hostBottomMargin = parseFloat(getComputedStyle(this.elementRef.nativeElement).marginBottom);
      this.hostMaxWidth = parseFloat(getComputedStyle(this.elementRef.nativeElement).maxWidth);
      this.hostMinWidth = parseFloat(getComputedStyle(this.elementRef.nativeElement).minWidth);
      this.hostMaxHeight = parseFloat(getComputedStyle(this.elementRef.nativeElement).maxHeight);
      this.hostMinHeight = parseFloat(getComputedStyle(this.elementRef.nativeElement).minHeight);
      this.isGrabbing = true;
      this.previousX = event.clientX;
      this.previousY = event.clientY;
      this.resizingStart.emit(this.outputData(event));
      event.preventDefault();
    }
  }

  onMouseMove(event) {
    if (this.isGrabbing && this.mouseOnBorder) {
      if (this.mouseOnBorder == 'top') {
        this.resizeElementFromTheTop(event);
      } else if (this.mouseOnBorder == 'bottom') {
        this.resizeElementFromTheBottom(event);
      } else if (this.mouseOnBorder == 'left') {
        this.resizeElementFromTheLeft(event);
      } else if (this.mouseOnBorder == 'right') {
        this.resizeElementFromTheRight(event);
      } else if (this.mouseOnBorder == 'top_left') {
        this.resizeElementFromTheLeft(event);
        this.resizeElementFromTheTop(event);
      } else if (this.mouseOnBorder == 'top_right') {
        this.resizeElementFromTheRight(event);
        this.resizeElementFromTheTop(event);
      } else if (this.mouseOnBorder == 'bottom_right') {
        this.resizeElementFromTheRight(event);
        this.resizeElementFromTheBottom(event);
      } else if (this.mouseOnBorder == 'bottom_left') {
        this.resizeElementFromTheLeft(event);
        this.resizeElementFromTheBottom(event);
      }
      this.resizingElement.emit(this.outputData(event));
    }
    this.hostCoordinates = this.elementRef.nativeElement.getBoundingClientRect();
  }

  resizeElementFromTheBottom(event) {
    this.hostHeight += event.clientY - this.previousY;
    this.hostBottom -= event.clientY - this.previousY;
    this.hostBottomMargin -= event.clientY - this.previousY;
    this.previousY = event.clientY;
    if (this.hostHeight < 0 || this.hostHeight < this.hostMinHeight || this.hostHeight > this.hostMaxHeight) return;
    else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${this.hostHeight}px`);
      if (
        getComputedStyle(this.elementRef.nativeElement).position == 'absolute' ||
        getComputedStyle(this.elementRef.nativeElement).position == 'fixed'
      ) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'bottom', `${this.hostBottom}px`);
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, 'margin-bottom', `${this.hostBottomMargin}px`);
        this.renderer.setStyle(this.elementRef.nativeElement, 'margin-bottom', `0`);
      }
    }
  }

  resizeElementFromTheTop(event) {
    this.hostHeight -= event.clientY - this.previousY;
    this.hostTop += event.clientY - this.previousY;
    this.hostTopMargin += event.clientY - this.previousY;
    this.previousY = event.clientY;
    if (this.hostHeight < 0 || this.hostHeight < this.hostMinHeight || this.hostHeight > this.hostMaxHeight) return;
    else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'height', `${this.hostHeight}px`);
      if (
        getComputedStyle(this.elementRef.nativeElement).position == 'absolute' ||
        getComputedStyle(this.elementRef.nativeElement).position == 'fixed'
      ) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'top', `${this.hostTop}px`);
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, 'margin-top', `${this.hostTopMargin}px`);
      }
    }
  }

  resizeElementFromTheRight(event) {
    this.hostWidth += event.clientX - this.previousX;
    this.hostRight -= event.clientX - this.previousX;
    this.hostRightMargin -= event.clientX - this.previousX;
    this.previousX = event.clientX;
    if (this.hostWidth < 0 || this.hostWidth < this.hostMinWidth || this.hostWidth > this.hostMaxWidth) return;
    else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${this.hostWidth}px`);
      if (
        getComputedStyle(this.elementRef.nativeElement).position == 'absolute' ||
        getComputedStyle(this.elementRef.nativeElement).position == 'fixed'
      ) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'right', `${this.hostRight}px`);
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, 'margin-right', `${this.hostRightMargin}px`);
      }
    }
  }

  resizeElementFromTheLeft(event) {
    this.hostWidth -= event.clientX - this.previousX;
    this.hostLeft += event.clientX - this.previousX;
    this.hostLeftMargin += event.clientX - this.previousX;
    this.previousX = event.clientX;
    if (this.hostWidth <= 0 || this.hostWidth <= this.hostMinWidth || this.hostWidth >= this.hostMaxWidth) return;
    else {
      this.renderer.setStyle(this.elementRef.nativeElement, 'width', `${this.hostWidth}px`);
      if (
        getComputedStyle(this.elementRef.nativeElement).position == 'absolute' ||
        getComputedStyle(this.elementRef.nativeElement).position == 'fixed'
      ) {
        this.renderer.setStyle(this.elementRef.nativeElement, 'left', `${this.hostLeft}px`);
      } else {
        this.renderer.setStyle(this.elementRef.nativeElement, 'margin-left', `${this.hostLeftMargin}px`);
      }
    }
  }

  onMouseUp(event: Event): void {
    if (this.isGrabbing) {
      this.resizingEnd.emit(this.outputData(event));
      this.isGrabbing = false;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  outputData(event: Event): any {
    return {
      elementStyles: getComputedStyle(this.elementRef.nativeElement),
      mouseevent: event,
    };
  }

  setSubscriptions() {
    fromEvent(this.elementRef.nativeElement, 'mousemove')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => this.mouseMoveOnElement(event));

    fromEvent(this.elementRef.nativeElement, 'mousedown')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => this.onMouseDown(event));

    fromEvent(document, 'mousemove')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => this.onMouseMove(event));

    fromEvent(document, 'mouseup')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((event) => this.onMouseUp(event));
  }

  ngOnInit() {
    this.hostCoordinates = this.elementRef.nativeElement.getBoundingClientRect();
    this.zone.runOutsideAngular(() => this.setSubscriptions());
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
