<ng-container *ngIf="currentLang$ | async as currentLang">
  <ng-container *ngIf="!useIcons; else dropdownOptionTemplate">
    <div class="flex flex-1 items-center justify-end">
      <ng-container *ngFor="let language of languages; let i = index; trackBy: trackByLanguage">
        <a
          (click)="onLanguageClick(language, currentLang)"
          class="text-sm font-semibold text-gray-700 select-none p-2"
          [ngClass]="{ 'cursor-pointer': language !== currentLang, 'text-primary-600': language === currentLang }"
        >
          {{ 'language-switcher.' + (language | lowercase) | transloco }}
        </a>
        <span *ngIf="i < languages.length - 1" class="h-6 w-px bg-gray-200" aria-hidden="true"></span>
      </ng-container>
    </div>
  </ng-container>
  <ng-template #dropdownOptionTemplate>
    <div
      class="flex w-full h-full space-between items-center hover:bg-gray-50 px-4 py-2 text-sm cursor-pointer"
      [cdkMenuTriggerFor]="menu"
      [cdkMenuTriggerData]="currentLang"
      [cdkMenuPosition]="[{ originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'top' }]"
    >
      <div class="flex flex-row items-center space-between">
        <p class="text-gray-700 text-sm">
          {{ 'language-label' | transloco }}
        </p>
        <div class="border-l border-gray-200 w-[1px] h-[1.5rem] mx-2.5"></div>
        <img class="w-6 h-6" src="{{ 'icons/svg/' + languageIcons[currentLang] | assetUrl }}.svg" alt="" />
      </div>
      <x-svg-icon class="w-4 h-4 text-gray-600 mr-2" svgClass="w-4 h-4" svgImageName="chevron-right"></x-svg-icon>
    </div>
    <ng-template #menu>
      <div
        class="flex flex-col relative left-[100%] bottom-[2rem] min-w-[184px] bg-white border shadow-sm shadow-gray-300 border-gray-200 rounded"
        cdkMenu
      >
        <button
          *ngFor="let language of languages; let i = index; trackBy: trackByLanguage"
          class="flex flex-row h-[44px] items-center hover:bg-gray-100"
          [ngClass]="{ 'bg-gray-100': currentLang === language }"
          (click)="onLanguageClick(language, currentLang)"
          cdkMenuItem
        >
          <img
            class="rounded-[7px] ml-2.5 w-6 h-6"
            src="{{ 'icons/svg/' + languageIcons[language] | assetUrl }}.svg"
            alt=""
          />
          <p class="text-gray-900 text-sm ml-2.5">{{ 'language.' + language | transloco }}</p>
        </button>
      </div>
    </ng-template>
  </ng-template>
</ng-container>
