<div
  class="flex border-2 border-primary-900 bg-tertiary-800 flex-row rounded-xl py-6 px-4 h-full min-h-[190px] relative"
>
  <x-svg-icon
    svgImageName="cross"
    class="flex absolute right-4 top-4 text-white w-6 h-6 items-center justify-center cursor-pointer hover:bg-white/20 rounded-lg"
    svgClass="w-5 h-5"
    (click)="closed.next()"
  ></x-svg-icon>
  <div class="flex flex-col text-white flex-1 space-y-4 justify-center px-2 lg:px-6">
    <p class="text-xl xxl:!text-2xl font-semibold font-inter">{{ 'shop-advertisement-tile.line1' | transloco }}</p>
    <p class="text-l xxl:!text-xl">{{ 'shop-advertisement-tile.line2' | transloco }}</p>
  </div>
  <a
    class="flex items-center justify-center border-2 border-primary-900 bg-secondary-400 text-white font-semibold px-6 mr-2 lg:mr-6 h-12 self-center select-none cursor-pointer hover:bg-secondary-500"
    target="_blank"
    [href]="adShopLink"
  >
    {{ 'shop-advertisement-tile.submit-btn-text' | transloco }}
  </a>
</div>
