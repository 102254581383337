import { Injectable } from '@angular/core';
import { environment } from '@environments';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent, merge } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { isNotNil } from 'src/app/utils';
import { AuthService } from './auth.service';
import { UploadTrackerService } from './upload-tracker.service';
import { UserService } from './user.service';

const IDLE_TIMEOUT = environment.idleTimeout * 60000; // idle in minutes multiplied by number of ms in a minute

@UntilDestroy()
@Injectable()
export class IdleService {
  private timerId: unknown;

  constructor(
    readonly authService: AuthService,
    readonly userService: UserService,
    readonly uploadTrackerService: UploadTrackerService
  ) {
    if (IDLE_TIMEOUT > 0) {
      this.trackIdle();
    }
  }

  private trackIdle(): void {
    this.resetTimer();
    merge(fromEvent(document, 'click'), fromEvent(document, 'keydown'), this.uploadTrackerService.update$)
      .pipe(untilDestroyed(this))
      .subscribe(() => this.resetTimer());
  }

  private resetTimer() {
    clearTimeout(this.timerId as number);
    this.timerId = setTimeout(() => this.signOut(), IDLE_TIMEOUT);
  }

  private signOut() {
    this.userService.user$.pipe(take(1), filter(isNotNil)).subscribe(() => this.authService.signOut());
  }
}
