import { ColDef, GridOptions } from '@ag-grid-community/core';
import { LoadingOverlayComponent } from './templates/loading-overlay.component';
import { NoRowsOverlayComponent } from './templates/no-rows-overlay.component';

export namespace BaseGrid {
  export const defaultColDef: ColDef = {
    pinned: false,
    sortable: true,
    editable: false,
    resizable: true,
    suppressCellFlash: true,
    suppressMovable: true,
    minWidth: 50,
    filterParams: {
      newRowsAction: 'keep',
    },
  };

  export const defaultGridOptions: GridOptions = {
    enableRangeSelection: false,
    enableBrowserTooltips: true,
    suppressCellSelection: true,
    suppressContextMenu: true,
    suppressMultiRangeSelection: true,
    suppressRowDrag: true,
    suppressRowClickSelection: true,
    suppressClickEdit: true,
    stopEditingWhenGridLosesFocus: false,
    noRowsOverlayComponentFramework: NoRowsOverlayComponent,
    loadingOverlayComponentFramework: LoadingOverlayComponent,
    loadingCellRenderer: `<span class="loading-cell-spinner"></span>`,
    headerHeight: 60,
    rowHeight: 100,
    rowSelection: 'multiple',
    maxConcurrentDatasourceRequests: 2,
    infiniteInitialRowCount: 0,
    maxBlocksInCache: 10,
    cacheOverflowSize: 10,
    defaultColDef,
  };

  export const previewHeight = Math.round(BaseGrid.defaultGridOptions.rowHeight * 1.5);
}

export type SortDirection = 'asc' | 'desc';
export const defaultSortingParams: {
  sortColumn: string;
  sortDirection?: 'asc' | 'desc';
} = {
  sortColumn: 'original_datetime',
  sortDirection: 'desc',
};

export const sortDirections: SortDirection[] = ['asc', 'desc'];
export const photoSortableColumns = ['name', 'types', 'created_at', 'file_size', 'original_datetime', 'published_at'];
