import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { catchError, filter, map, shareReplay, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { Activity, Document, Link, SSE } from '@core/models';
import { ActivityStatisticsService, DeleteAssetLinkReqBody, SSEService, StorageService } from '@core/services';
import { CustomerDocumentsService, CustomerRoutingService, MessageCategory } from '@portal/customer/services';
import { LinkHelperService } from '@portal/services/link-helper.service';
import { DocumentReference } from '@portal/components/documents-preview/documents-preview.model';
import {
  dataroomType,
  isPdfTypeRegExp,
  isPhotoTypeRegExp,
  isVideoExtensionsRegExp,
  photoType,
  videoType,
} from '../qa-overview/qa-overview.constants';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of } from 'rxjs';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { Store } from '@ngrx/store';
import { isNotNil } from '@app/utils';
import _isEqual from 'lodash/isEqual';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoService } from '@ngneat/transloco';
import ConversionFinished = SSE.EventData.ConversionFinished;
import { Router } from '@angular/router';
import { IndexPointsService } from '@core/services/index-points.service';

export interface DocumentUploadEvent {
  document: Document;
  allDocuments: Document[];
}

@UntilDestroy()
@Component({
  selector: 'x-documents-preview',
  templateUrl: './documents-preview.component.html',
  styleUrls: ['./documents-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentsPreviewComponent implements OnInit, OnChanges {
  @Input() messageId: string;
  @Input() messageCategory: MessageCategory;
  @Input() documents: Document.Short[] | Document[];
  @Input() isDeleteDocumentPossible = false;
  @Input() isDownloadDocumentPossible = false;
  @Input() showUploadAction = false;
  @Input() groupNotUploadedDocuments = false;
  @Input() showNotUploadedToSystemLabel = false;

  @Output() documentsDelete = new EventEmitter<DeleteAssetLinkReqBody[]>();
  @Output() documentUploadClick = new EventEmitter<DocumentUploadEvent>();

  isOpeningWithinTokenPreview = false;
  isLoadingReferences = false;
  references$ = new BehaviorSubject<DocumentReference[]>(undefined);
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);

  private docsToDelete: DeleteAssetLinkReqBody[] = [];

  constructor(
    private readonly customerRouting: CustomerRoutingService,
    private readonly linkHelperService: LinkHelperService,
    private readonly cdr: ChangeDetectorRef,
    private readonly storageService: StorageService,
    private readonly customerDocumentsService: CustomerDocumentsService,
    private readonly store: Store,
    private readonly sseService: SSEService,
    private readonly toast: ToastService,
    private readonly transloco: TranslocoService,
    private readonly activityService: ActivityStatisticsService,
    private readonly router: Router,
    private readonly indexPointsService: IndexPointsService
  ) {}

  ngOnInit(): void {
    this.sseService.sseEvent$
      .pipe(
        filter(isNotNil),
        filter(
          (event: ConversionFinished) => event.action === 'convert' && event.data.conversion_status === 'finished'
        ),
        map((event: ConversionFinished) => event.data?.id),
        filter(isNotNil),
        withLatestFrom(this.references$),
        take(1),
        untilDestroyed(this)
      )
      .subscribe(([id, refs]: [string, DocumentReference[]]) => {
        const updatedRefs = refs?.map((ref) =>
          ref.doc.id === id
            ? ({ ...ref, doc: { ...ref.doc, conversion_status: 'finished' } } as DocumentReference)
            : ref
        );
        if (!_isEqual(refs, updatedRefs)) {
          this.references$.next(updatedRefs);
        }
      });
  }

  ngOnChanges({ documents }: SimpleChanges) {
    if (documents) {
      this.updateReferences();
    }
  }

  getReference(doc: Document.Short | Document): Observable<DocumentReference> {
    if (this.isFullDocument(doc)) {
      return of(this.createReference(doc));
    } else {
      return this.getDocumentReference(doc as Document.Short);
    }
  }

  onOpenDocument(ref: DocumentReference, event: MouseEvent): void {
    event.stopPropagation();

    if (!this.getIsViewableOnline(ref) || this.isDeleteDocumentPossible) {
      return;
    }

    if (!event?.ctrlKey) {
      event?.preventDefault();
    }

    if (this.getIsMediaDocumentReference(ref.doc)) {
      this.handlePhotoDocument(ref.doc);
    } else {
      this.linkHelperService.open(ref.href, ref.types?.includes(photoType) ? photoType : undefined);
    }
  }

  readonly getIsMediaDocumentReference = (doc: Document) =>
    isPhotoTypeRegExp.test(doc.name) || isVideoExtensionsRegExp.test(doc.name) || doc.types.includes('video');

  readonly getIsViewableOnline = (ref: DocumentReference) =>
    isPhotoTypeRegExp.test(ref.name) ||
    isVideoExtensionsRegExp.test(ref.name) ||
    (isPdfTypeRegExp.test(ref.name) && ref.doc.conversion_status !== 'failed') ||
    ref.doc.types.includes('video') ||
    ref.doc.conversion_status === 'finished';

  trackByReferenceHref(_: number, fileReference: DocumentReference): string {
    return fileReference.href;
  }

  onDeleteReferenceClick(reference: DocumentReference): void {
    const { doc, href } = reference;
    const { asset_id, id } = doc;
    this.references$
      .pipe(
        map((references) => references.filter((ref) => ref.href !== href)),
        take(1),
        untilDestroyed(this)
      )
      .subscribe((updatedRefs) => {
        this.references$.next(updatedRefs);
      });

    this.docsToDelete = [
      ...this.docsToDelete,
      {
        asset_id,
        link: {
          src_id: this.messageId,
          src_type: this.getCurrentActivityMessageCategory(),
          target_id: id,
          target_type: 'document',
        } as Link,
      },
    ];

    this.documentsDelete.emit(this.docsToDelete);
    this.cdr.markForCheck();
  }

  onDownloadReferenceClick({ doc }: DocumentReference): void {
    const toastId = this.toast.showToast(this.transloco.translate('dataroom-documents.starting-download'));
    this.customerDocumentsService
      .getDownloadToken(doc, { action: 'download' })
      .pipe(
        take(1),
        tap((download_token) =>
          this.storageService.downloadDocumentWithProperExtension({ download_token, name: doc?.name })
        ),
        catchError(() => {
          this.toast.hideToast(toastId);
          this.toast.showToast(this.transloco.translate('cant-download-file'), ToastType.ERROR);
          return EMPTY;
        }),
        tap(() => {
          this.toast.hideToast(toastId);
        }),
        switchMap(() => {
          const activity: Activity = {
            scope: this.messageCategory === 'tickets' ? 'ticket' : 'qa',
            action: 'save',
            qa_message_id: this.messageId,
            document_id: doc.id,
          };
          return this.activityService.trackActivity(doc.asset_id, activity);
        })
      )
      .subscribe();
  }

  onUploadIntoSystemClick(reference: DocumentReference, references: DocumentReference[]) {
    this.documentUploadClick.emit({ document: reference.doc, allDocuments: references.map((ref) => ref.doc) });
  }

  filterUploadedDocuments = (documents: DocumentReference[]) =>
    documents.filter((ref) => this.isUploadedIntoSystem(ref.doc));

  filterNotUploadedDocuments = (documents: DocumentReference[]) =>
    documents.filter((ref) => !this.isUploadedIntoSystem(ref.doc));

  asReferencesType = (refs: unknown) => refs as DocumentReference[];

  isUploadedIntoSystem(document: Document) {
    return this.customerDocumentsService.isUploadedIntoSystem(document);
  }

  isUploadedIntoPhotos(document: Document) {
    return this.customerDocumentsService.isUploadedIntoPhotos(document);
  }

  getDataroomPathFromDocument(document: Document): string | undefined {
    if (document?.node) {
      return this.indexPointsService.getFormattedPath(
        [...(document.node.labels_path || []), document.node.label],
        document.node.index_point
      );
    }
  }

  async navigateToDataroomPath(event: MouseEvent, document: Document) {
    event.stopPropagation();
    const url = `${this.customerRouting.getDataRoomLink(document.asset_id)}/${document.node.id}`;
    await this.router.navigateByUrl(url);
  }

  async openInPhotosOverview(event: MouseEvent, reference: DocumentReference) {
    event.stopPropagation();
    const url = this.customerRouting.getPhotoInOverviewLink(reference.doc.asset_id, reference.doc.id);
    await this.router.navigateByUrl(url);
  }

  private getDocumentHref(document: Document) {
    const { id, name, anchor, types } = document;
    let targetType = null;
    if (document.types?.includes(dataroomType)) {
      targetType = dataroomType;
    }
    if (document.types?.includes('photo') || isPhotoTypeRegExp.test(photoType)) {
      targetType = photoType;
    }
    if (document.types?.includes('video') || isVideoExtensionsRegExp.test(videoType)) {
      targetType = videoType;
    }

    const documentLink = Link.toShortDocument(
      {
        target_id: id,
        target_description: name,
        target_anchor: anchor,
        target_type: targetType || types?.[0],
      } as Link,
      document.asset_id
    );
    return this.customerRouting.getDocumentFileLink(
      documentLink,
      undefined,
      this.getCurrentActivityMessageCategory(),
      documentLink.anchor,
      this.messageId
    );
  }

  private handlePhotoDocument(mediaDocument: Document): void {
    window.openMediaDocument(mediaDocument, {
      qa_message_id: this.messageId,
      document_id: mediaDocument.id,
      scope: this.getCurrentActivityMessageCategory(),
    });
  }

  private getCurrentActivityMessageCategory() {
    return this.messageCategory === 'qa' ? 'qa' : 'ticket';
  }

  private createReference(doc: Document): DocumentReference {
    return {
      href: this.getDocumentHref(doc),
      name: doc.name,
      types: doc.types,
      doc,
    };
  }

  private getDocumentReference(shortDoc: Document.Short): Observable<DocumentReference> {
    return this.getDocument(shortDoc).pipe(map((doc: Document) => this.createReference(doc)));
  }

  private getDocumentsReferences(documents: Document.Short[]): Observable<DocumentReference[]> {
    const references: Observable<DocumentReference>[] = documents
      ?.filter((document) => !!document)
      .map((document) => this.getReference(document));

    return references?.length ? forkJoin(references) : of([]);
  }

  private isFullDocument(doc: Document.Short | Document): doc is Document {
    return !!(doc as Document)?.download_token;
  }

  private getDocument(doc: Document.Short): Observable<Document> {
    return this.store.select(CustomerRouterSelectors.getAssetId).pipe(
      switchMap((asset_id) => this.customerDocumentsService.getDocument(asset_id, doc.id, true)),
      take(1)
    );
  }

  private updateReferences(): void {
    this.isLoadingReferences = true;
    this.cdr.markForCheck();
    this.getDocumentsReferences(this.documents)
      .pipe(
        shareReplay(1),
        take(1),
        catchError((error) => {
          console.error(error);
          this.isLoadingReferences = false;
          this.cdr.detectChanges();
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((refs) => {
        this.references$.next(refs);
        this.isLoadingReferences = false;
        this.cdr.detectChanges();
      });
  }
}
