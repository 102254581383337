<ng-container *ngIf="!!value.length">
  <div class="flex flex-wrap gap-1">
    <ng-container *ngFor="let badge of value; trackBy: trackByValue">
      <x-badge
        class="w-full"
        [badgeType]="badge.badgeType"
        [label]="badge.label"
        [text]="badge.text"
        [classes]="badge.classes"
      ></x-badge>
    </ng-container>
  </div>
</ng-container>
