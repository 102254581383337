import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { TranslocoModule } from '@ngneat/transloco';
import { AutofocusDirectiveModule } from 'src/app/common/directives';
import { QAOverviewSearchBreadcrumbsComponent } from './qa-overview-search-breadcrumbs.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    FormsModule,
    TranslocoModule,
    MatButtonModule,
    AutofocusDirectiveModule,
  ],
  declarations: [QAOverviewSearchBreadcrumbsComponent],
  exports: [QAOverviewSearchBreadcrumbsComponent],
})
export class QAOverviewSearchBreadcrumbsModule {}
