import { Pipe, PipeTransform } from '@angular/core';
import { FileSizeService } from '@core/services';

@Pipe({
  name: 'fileSize',
})
export class FileSizePipe implements PipeTransform {
  constructor(private readonly fileSizeService: FileSizeService) {}

  transform(byteSize?: number, type: 'B' | 'kB' | 'MB' | 'GB' = 'GB'): string {
    return this.fileSizeService.transform(byteSize, type);
  }
}
