import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UsersSelectors } from '@core/state';
import { Store } from '@ngrx/store';

@Component({
  selector: 'x-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: ['./side-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidePanelComponent {
  @Input() role: string;

  get isCustomerRole(): boolean {
    return this.role === 'customer';
  }

  readonly isAdmin$ = this.store.select(UsersSelectors.getIsAdmin);

  constructor(private readonly store: Store) {}
}
