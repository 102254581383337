import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { TranslocoModule } from '@ngneat/transloco';
import { SnackBarComponent } from './snack-bar.component';

@NgModule({
  imports: [CommonModule, MatButtonModule, TranslocoModule, MatSnackBarModule],
  declarations: [SnackBarComponent],
  exports: [SnackBarComponent, MatSnackBarModule],
})
export class SnackBarModule {}
