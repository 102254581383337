<div [formGroup]="codeForm">
  <div formArrayName="digits" class="flex gap-4 items-center justify-center w-full" *ngIf="codeForm">
    <input
      *ngFor="let control of digits.controls; let i = index; trackBy: trackByIndex"
      type="text"
      maxlength="1"
      [autofocus]="i === 0"
      [formControlName]="i"
      class="digit-input rounded-md border border-gray-300 px-3 shadow-gray-300 shadow-sm text-gray-600 text-xl w-[42px] h-[42px] text-center"
      (keydown)="handleInputKeyDown(i, $event, control)"
      (focus)="onFocus(i)"
      (blur)="onBlur(i)"
      xEnterKey
      (enterKeyPressed)="codeSubmit.emit($event)"
    />
  </div>
</div>
