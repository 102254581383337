<ng-container *ngIf="messages?.length">
  <div
    class="message-markers"
    title="{{ 'click-to-show-hide-the-messages' | transloco }}"
    (click)="messagesClick.emit(messages)"
  >
    <span
      class="circle"
      *ngFor="let color of messages | messagesColors"
      [style.backgroundColor]="color | lighten"
      [style.borderColor]="color"
    ></span>
  </div>
</ng-container>
