<div class="add-button-container">
  <button class="primary icon-button button-add" title="{{ 'invite-user' | transloco }}" (click)="onInviteUser()">
    <x-icon class="plus"></x-icon>
    <span>{{ 'user' | transloco }}</span>
  </button>
</div>

<div class="grid-container">
  <ag-grid-angular
    #grid
    class="ag-theme-xportal"
    [defaultColDef]="defaultColDef"
    [gridOptions]="gridOptions"
    [rowData]="rowData$ | async"
    [columnDefs]="columnDefs"
    [modules]="modules"
  ></ag-grid-angular>

  <x-columns-panel
    [columns]="settingsColumns"
    [fieldTemplate]="userLabelTemplate"
    (panelOpened)="onPanelOpened($event)"
    (toggleVisibility)="onToggleColumnVisibility($event)"
  ></x-columns-panel>
</div>

<ng-template #userLabelTemplate let-field>
  <x-user-label class="ag-header-cell-text" [fieldName]="field"></x-user-label>
</ng-template>

<ng-template #invitationFailedTemplate>
  <span>{{ 'portal-user-user-invitation-failed' | transloco }}</span>
</ng-template>

<ng-template #statusUpdateFailedTemplate>
  <span>{{ 'portal-user-status-update-failed' | transloco }}</span>
</ng-template>
