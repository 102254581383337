<div *ngIf="finding$ | async as finding; else spinnerTemplate" class="finding-menu-container">
  <h4>
    <ng-container>{{ 'finding-no' | transloco }}</ng-container>
    {{ finding.current_no }}
  </h4>

  <ng-container *ngIf="findingColumns; else spinnerTemplate">
    <ng-container *ngFor="let column of findingColumns">
      <ng-container *ngIf="getFindingValue | call : finding : column as value">
        <section>
          <h5>
            <x-finding-label [fieldName]="$any(column.field)"></x-finding-label>
          </h5>
          <div [class.small]="isSmall">
            <ng-container [ngSwitch]="column.field">
              <ng-container *ngSwitchCase="'documents'">
                <ng-container *ngTemplateOutlet="documentLinkTemplate; context: { $implicit: value }"></ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'photos'">
                <ng-container *ngTemplateOutlet="documentLinkTemplate; context: { $implicit: value }"></ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'references'">
                <ng-container *ngTemplateOutlet="documentLinkTemplate; context: { $implicit: value }"></ng-container>
              </ng-container>

              <ng-container *ngSwitchCase="'trade'">
                {{ value?.['text'] }}
              </ng-container>
              <ng-container *ngSwitchDefault>{{ value }}</ng-container>
            </ng-container>
          </div>
        </section>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #documentLinkTemplate let-docs>
  <div *ngFor="let doc of docs">
    <a [href]="doc.href" target="_blank" rel="noopener" (click)="onOpenDocument(doc, $event)">{{ doc.name }}</a>
  </div>
</ng-template>

<ng-template #spinnerTemplate>
  <x-spinner></x-spinner>
</ng-template>
