import { environmentBase, Environment } from './environment-base';

export const environment: Environment = {
  ...environmentBase,
  production: true,
  apiUrl: '',
  tokens: {
    map: 'pk.eyJ1IjoieHByb2plY3QiLCJhIjoiY2thMmdlZGswMDNxODNtcWJtN3dlczQ5bCJ9.iS1N6iPRHgw3CLn5mabBYA',
  },
  logSSE: false,
};
