import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, filter, finalize, map, shareReplay, switchMap, take, takeUntil } from 'rxjs/operators';
import { environment } from '@environments';
import { User } from '../models';
import { API_URL } from './constants';
import { UserService } from './user.service';
import { DecodedTokenData } from '@core/services/auth-data';
import { SSEService } from '@core/services/sse.service';
import { isNotNil } from '@app/utils';
import { DELAY_TIME } from '@app/utils/rxjs.utils';
import { throttle } from 'lodash';
import { EnterTwoFactorAuthCodeDialogComponent } from '@app/auth/enter-two-factor-auth-code-dialog/enter-two-factor-auth-code-dialog.component';

export const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token';
export const ACCESS_TOKEN_STORAGE_KEY = 'access_token';
export const LAST_LOGGED_USER_ID_KEY = 'last_logged_user_id';

export const TWO_FACTOR_AUTH_CODE_LENGTH = 6;

export interface TokenResponse {
  refresh_token: string;
  token: string;
}

// Will refresh the access token 10 minutes before tokens expiration
// Access Token and Refresh Token have both 25 minutes lifetime
const TIME_BEFORE_EXPIRE_TO_REFRESH = 1000 * 60 * 10;

// After this amount of time of no activity user will be automatically logged out.
const MAX_NO_ACTIVITY_LIMIT_BEFORE_SESSION_EXPIRY = 1000 * 60 * 60;

// activity is an HTTP Request
const LAST_ACTIVITY_TIMESTAMP_KEY = 'last-activity-timestamp';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private readonly token$ = new BehaviorSubject<string | null>(null);
  private readonly logout$ = new Subject();

  private readonly userService = inject(UserService);
  private readonly sseService = inject(SSEService);

  private standingRefreshTokenRequest: Observable<TokenResponse> | null = null;
  private isCurrentSessionExpiredSubject$ = new BehaviorSubject(false);

  private twoFactorAuthorizationApiBaseUrl = `${API_URL}/${environment.portalApiUrl}/users/me/2fa`;
  private recoveryCodesApiBaseUrl = `${API_URL}/${environment.portalApiUrl}/users/me/recovery_codes`;

  get lastActivityTimestamp() {
    const storedActivity = localStorage.getItem(LAST_ACTIVITY_TIMESTAMP_KEY);
    if (!storedActivity) {
      this.lastActivityTimestamp = Date.now();
    }
    return storedActivity ? Number.parseInt(storedActivity) : Date.now();
  }

  set lastActivityTimestamp(timestamp) {
    localStorage.setItem(LAST_ACTIVITY_TIMESTAMP_KEY, `${timestamp}`);
  }

  readonly tokenChanged$ = this.token$.pipe(shareReplay(1));
  readonly isCurrentSessionExpired$ = this.isCurrentSessionExpiredSubject$.asObservable();

  // Needs to be throttled to do not call it again after it was already called (http interceptor calls it dynamically)
  private readonly throttledSignOut = throttle(this.handleSignOut.bind(this), DELAY_TIME);

  constructor(
    private readonly jwtHelper: JwtHelperService,
    private readonly http: HttpClient,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {
    this.emitTokenIfValid();
    this.recreateSSEOnTokenChanges();
    this.watchSSEEvents();
    this.watchCurrentTokenValidity();
  }

  handleSignOut(redirectUrl?: string) {
    this.onActivity();
    const doNotHaveAccessToken = !localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    if (doNotHaveAccessToken) {
      window.location.reload();
      return;
    }
    this.closeSessionAndClearData();

    if (redirectUrl) {
      const routerUrl = this.router.url;
      if (!routerUrl.startsWith('/auth')) {
        this.router.navigate(['/auth'], { queryParams: { path: this.extractPath(redirectUrl) } }).then(() => {
          this.isCurrentSessionExpiredSubject$.next(true);
        });
      } else {
        this.router.navigateByUrl(routerUrl.startsWith('/auth') ? routerUrl : '/').then(() => {
          this.isCurrentSessionExpiredSubject$.next(true);
        });
      }
    } else {
      this.router.navigateByUrl('/auth');
      window.location.reload();
    }
  }

  isUserAccessTokenValid(): boolean {
    try {
      return !this.jwtHelper.isTokenExpired(localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY), 60);
    } catch {
      return false;
    }
  }

  recoverPassword(email: string): Observable<void> {
    return this.http.post<void>(`${API_URL}/${environment.portalApiUrl}/password/forgot`, { email }).pipe(
      switchMap(() => of(null)),
      catchError(this.handleError<void>('recoverPassword'))
    );
  }

  signIn({ username, password }: User.SignInUser): Observable<boolean> {
    return this.http.post<TokenResponse>(`${API_URL}/${environment.portalApiUrl}/sign-in`, { username, password }).pipe(
      switchMap((response) => this.handleSignInSuccess(response)),
      catchError((error) => throwError(error))
    );
  }

  signOut(redirectUrl?: string) {
    if (!redirectUrl) {
      this.http
        .post<void>(`${API_URL}/${environment.portalApiUrl}/logout`, null)
        .pipe(take(1), catchError(this.handleError<void>('signOut')))
        .subscribe();
      this.throttledSignOut();
    } else {
      this.throttledSignOut(redirectUrl);
    }
  }

  fetchNewAccessAndRefreshTokens(): Observable<TokenResponse> {
    if (this.standingRefreshTokenRequest) {
      return this.standingRefreshTokenRequest;
    }

    const refresh_token = localStorage.getItem(REFRESH_TOKEN_STORAGE_KEY);
    const options = { headers: { Authorization: `Bearer ${refresh_token}` } };

    this.standingRefreshTokenRequest = this.http
      .post<TokenResponse>(
        `${API_URL}/${environment.portalApiUrl}/refresh-token`,
        { refresh_token: `Bearer ${refresh_token}` },
        options
      )
      .pipe(
        take(1),
        switchMap((tokens: TokenResponse) => {
          this.setAuthTokens(tokens);
          return of(tokens);
        }),
        catchError((error: HttpErrorResponse) => throwError(error)),
        shareReplay(),
        takeUntil(this.logout$),
        finalize(() => (this.standingRefreshTokenRequest = null))
      );

    return this.standingRefreshTokenRequest;
  }

  getCurrentAuthTokenDecodedData(): DecodedTokenData {
    return this.jwtHelper.decodeToken(localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY));
  }

  onActivity() {
    this.lastActivityTimestamp = Date.now();
  }

  remove2FA(otp: string) {
    return this.http.delete(`${this.twoFactorAuthorizationApiBaseUrl}`, { body: { otp } });
  }

  confirmAndFinalize2FA(otp: string) {
    return this.http.post(`${this.twoFactorAuthorizationApiBaseUrl}/confirm`, { otp });
  }

  initialize2FAAndGetQRCode() {
    return this.http.post(`${this.twoFactorAuthorizationApiBaseUrl}/initialize`, {}, { responseType: 'blob' });
  }

  signInWith2FA(otp: string, tokens?: TokenResponse) {
    return this.http.post(
      `${this.twoFactorAuthorizationApiBaseUrl}/sign-in`,
      { otp, refresh_token: tokens.refresh_token },
      tokens
        ? {
            headers: {
              Authorization: `Bearer ${tokens.token}`,
            },
          }
        : {}
    );
  }

  generateRecoveryCodes(otp: string) {
    return this.http.post<{ code: string }[]>(`${this.recoveryCodesApiBaseUrl}`, { otp });
  }

  signInWith2FARecoveryCode(code: string, tokens?: TokenResponse) {
    return this.http
      .post<TokenResponse>(
        `${this.recoveryCodesApiBaseUrl}/sign-in`,
        {
          code,
          refresh_token: tokens?.refresh_token,
        },
        tokens
          ? {
              headers: {
                Authorization: `Bearer ${tokens.token}`,
              },
            }
          : {}
      )
      .pipe(
        switchMap((tokens: TokenResponse) => {
          const decodedToken = this.jwtHelper.decodeToken(tokens.token);
          return this.userService.fetchUser(decodedToken, tokens.token).pipe(
            take(1),
            map(() => tokens)
          );
        })
      );
  }

  private watchCurrentTokenValidity() {
    setInterval(() => {
      const user = this.userService.user$.value;
      const tokenIsNotSet = !localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
      if (user && !(user.is_seed2fa_confirmed && tokenIsNotSet)) {
        this.logoutIfSessionExpired();
        this.refreshAccessTokensIfNeeded();
      }
    }, 1000);
  }

  private watchSSEEvents() {
    this.sseService.sseEvent$
      .pipe(
        filter(isNotNil),
        filter((event) => event.type === 'logout')
      )
      .subscribe(() => {
        this.signOut();
      });
  }

  private refreshAccessTokensIfNeeded() {
    const token = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
    const tokenExpirationDate = this.jwtHelper.getTokenExpirationDate(token);
    const remainingTimeout = tokenExpirationDate?.valueOf() - Date.now();

    if (remainingTimeout < TIME_BEFORE_EXPIRE_TO_REFRESH) {
      this.fetchNewAccessAndRefreshTokens()?.pipe(take(1), takeUntil(this.logout$)).subscribe();
    }
  }

  private logoutIfSessionExpired() {
    const isTokenExpired = !this.isUserAccessTokenValid();
    const hasExceededNoActivityLimit =
      Date.now() - this.lastActivityTimestamp > MAX_NO_ACTIVITY_LIMIT_BEFORE_SESSION_EXPIRY;
    if (isTokenExpired || hasExceededNoActivityLimit) {
      const redirectUrl = this.router.routerState.snapshot.url;
      this.signOut(redirectUrl);
    }
  }

  private recreateSSEOnTokenChanges() {
    this.tokenChanged$.subscribe((token) => {
      this.sseService.close();
      this.sseService.createSSE(token);
    });
  }

  private emitTokenIfValid(): void {
    if (this.isUserAccessTokenValid()) {
      const storedAccessToken = localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY);
      this.token$.next(storedAccessToken);
    }
  }

  private extractPath(url: string): string {
    const pathIndex = url ? url.indexOf('path=') : -1;
    if (pathIndex !== -1) {
      const encodedPath = url.substring(pathIndex + 5);
      const decodedPath = decodeURIComponent(encodedPath);
      const customerIndex = decodedPath.indexOf('/customer');
      if (customerIndex !== -1) {
        return decodedPath.substring(customerIndex);
      }
    }

    return url;
  }

  private handleSignInSuccess(tokens: TokenResponse): Observable<boolean> {
    this.onActivity();
    this.closeSessionAndClearData();
    const decodedToken = this.jwtHelper.decodeToken(tokens.token);
    return this.userService.fetchUser(decodedToken, tokens.token).pipe(
      switchMap((userData) => {
        if (userData.is_seed2fa_confirmed) {
          return forkJoin([of(userData), this.askFor2FAConfirmationCode(tokens)]);
        } else {
          return forkJoin([of(userData), of(tokens)]);
        }
      }),
      switchMap(([user, tokens]) => {
        const redirectUserId = localStorage.getItem(LAST_LOGGED_USER_ID_KEY);
        localStorage.setItem(LAST_LOGGED_USER_ID_KEY, user.id);
        let obs = this.navigateToPath('/');
        if (redirectUserId === user.id) {
          const queryParamsPath = this.extractPath(this.router.routerState.snapshot.root.queryParams.path);
          obs = this.navigateToPath(queryParamsPath);
        }
        this.setAuthTokens(tokens);
        this.isCurrentSessionExpiredSubject$.next(false);
        return obs;
      }),
      catchError((error) => {
        console.error(error);
        return from(this.router.navigateByUrl('/'));
      })
    );
  }

  private navigateToPath(path: string | null): Observable<boolean> {
    return of(null).pipe(
      switchMap(() =>
        from(
          path
            ? this.router.navigateByUrl(decodeURIComponent(path), { replaceUrl: true })
            : this.router.navigateByUrl('/customer/assets', { replaceUrl: true })
        )
      )
    );
  }

  private setAuthTokens({ token, refresh_token }: TokenResponse): void {
    if (!token || !refresh_token) {
      return;
    }

    this.token$.next(token);
    localStorage.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
    localStorage.setItem(REFRESH_TOKEN_STORAGE_KEY, refresh_token);
  }

  private closeSessionAndClearData() {
    this.dialog.closeAll();
    this.token$.next(null);
    this.logout$.next();
    this.userService.clearUser();
    localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY);
    sessionStorage.clear();
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error): Observable<T> => {
      console.error(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  private askFor2FAConfirmationCode(tokens: TokenResponse) {
    return this.dialog
      .open(EnterTwoFactorAuthCodeDialogComponent, {
        data: tokens,
      })
      .afterClosed()
      .pipe(
        map((result) => {
          if (!result) {
            return throwError('');
          } else {
            return result;
          }
        })
      );
  }
}
