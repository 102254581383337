import { AsyncPipe, NgClass, NgForOf, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ImageUrlPipeModule, SafePipeModule, SpinnerModule } from '@common';
import { CardComponent } from '@common/components/card/card.component';
import { getAssetUrl } from '@common/utils/getAssetUrl';
import { StorageService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DownloadTokenCallbackType } from '@portal/components';
import { CustomerAssetsService } from '@portal/customer/services';
import { Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'x-asset-overview-image-tile',
  templateUrl: './asset-overview-image-tile.component.html',
  styleUrls: ['./asset-overview-image-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CardComponent, NgForOf, AsyncPipe, NgIf, ImageUrlPipeModule, SafePipeModule, NgClass, SpinnerModule],
})
export class AssetOverviewImageTileComponent implements OnInit, OnChanges {
  @Input() newDownloadTokenGetter: DownloadTokenCallbackType;
  @Input() imageDownloadToken: string;
  @Input() imageClasses = '';
  @Input() assetId = '';

  readonly storageService = inject(StorageService);
  readonly assetsService = inject(CustomerAssetsService);
  private readonly cdr = inject(ChangeDetectorRef);
  readonly fallbackImagePath = getAssetUrl('images/asset-placeholder.png');
  imageData$: Observable<string>;
  loading = true;

  ngOnChanges({ imageDownloadToken }: SimpleChanges) {
    if (imageDownloadToken?.previousValue !== imageDownloadToken?.currentValue) {
      this.getImageData();
      this.cdr.markForCheck();
    }
  }

  ngOnInit() {
    this.getImageData();
  }

  private getImageData() {
    if (this.imageDownloadToken) {
      this.imageData$ = this.storageService
        .getImageWithDownloadToken({ download_token: this.imageDownloadToken }, true, this.assetId)
        .pipe(
          catchError((err) => {
            if (err.status === 404) {
              this.storageService.removeItemFromCache(this.assetId);
            }
            if (this.assetId && this.newDownloadTokenGetter) {
              return this.newDownloadTokenGetter(this.assetId).pipe(
                switchMap(
                  (newToken): Observable<Blob> =>
                    this.storageService.getImageWithDownloadToken({ download_token: newToken }, true, this.assetId)
                )
              );
            } else {
              return throwError(err);
            }
          }),
          map((blob) => window.URL.createObjectURL(blob)),
          catchError(() => of(this.fallbackImagePath)),
          finalize(() => {
            this.loading = false;
            this.cdr.markForCheck();
          }),
          untilDestroyed(this)
        );
    } else {
      this.imageData$ = of(this.fallbackImagePath);
      this.loading = false;
      this.cdr.markForCheck();
    }
  }
}
