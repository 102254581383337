import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@core/models';
import { Observable, of } from 'rxjs';

@Pipe({
  name: 'hasRole',
})
export class HasRolePipe implements PipeTransform {
  transform(
    value: string | string[] | User.AdminRole | User.AdminRole[] | User.CustomerRole | User.CustomerRole[]
  ): Observable<boolean> {
    return of(true); // TODO Specify user permissions
  }
}
