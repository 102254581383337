import { AsyncPipe, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject, Input } from '@angular/core';
import { CallPipeModule, LocaleDatePipeModule, NewLinePipeModule, SpinnerModule } from '@common';
import { ButtonComponent } from '@common/components/button/button.component';
import { PriorityIndicatorComponent } from '@common/components/priority-indicator/priority-indicator.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { InfoPanelComponent } from '@common/info-panel/info-panel.component';
import { Message } from '@core/models';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { GridHelperService } from '@portal/components/grid/grid-helper.service';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { DocumentsPreviewModule } from '@portal/components/documents-preview/documents-preview.module';
import { DueDateCellRendererComponent } from '@portal/components/grid/due-date-cell-renderer/due-date-cell-renderer.component';
import { QaDetailsDialogFieldsComponent } from '@portal/components/qa-overview/qa-details-dialog-fields/qa-details-dialog-fields.component';
import { QaOverviewHelperService } from '@portal/components/qa-overview/qa-overview-helper.service';
import { QaReferenceModule } from '@portal/components/qa-overview/qa-reference/qa-reference.module';
import { UsernameCellRendererComponent } from '@portal/components/username-renderer/username-renderer.component';
import { DetailsFieldRowComponent } from '@portal/components/details-field-row/details-field-row.component';
import { FindingDetailsModule } from '@portal/customer/components/asset-details/findings/finding-details';
import { ProcessedReference } from '@portal/customer/components/attach-reference-button/reference.model';
import { ReferencesPreviewComponent } from '@portal/customer/components/references-preview/references-preview.component';
import { TradeService } from '@core/services';
import { Observable } from 'rxjs';

@Component({
  selector: 'x-ticket-details-dialog-fields',
  templateUrl: './ticket-details-dialog-fields.component.html',
  styleUrls: ['./ticket-details-dialog-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NewLinePipeModule,
    TranslocoModule,
    LocaleDatePipeModule,
    CallPipeModule,
    QaReferenceModule,
    NgIf,
    DocumentsPreviewModule,
    PriorityIndicatorComponent,
    SvgIconModule,
    ButtonComponent,
    SpinnerModule,
    AsyncPipe,
    DetailsFieldRowComponent,
    BadgeComponent,
    ReferencesPreviewComponent,
    QaDetailsDialogFieldsComponent,
    FindingDetailsModule,
    UsernameCellRendererComponent,
    DueDateCellRendererComponent,
    InfoPanelComponent,
  ],
})
export class TicketDetailsDialogFieldsComponent {
  @Input() ticket: Message;

  readonly transloco = inject(TranslocoService);
  readonly qaOverviewHelper = inject(QaOverviewHelperService);
  readonly gridHelper = inject(GridHelperService);
  readonly tradeService = inject(TradeService);
  readonly store = inject(Store);

  isDisabled = false;

  onWriteResponseClick() {}

  cancelChanges() {}

  getReferences(message: Message): ProcessedReference[] {
    return Message.extractReference(message).map((ref) =>
      ref.type === 'finding'
        ? {
            ...ref,
            name: `${this.transloco.translate('finding-no')} ${ref.name}`,
          }
        : ref
    );
  }

  getTradeLabel(tradeId: number): Observable<string> {
    return this.tradeService.getTradeLabelObs(tradeId);
  }

  getStatusBadgeData(value) {
    return {
      text: this.transloco.translate(`tickets-system.status.${value}`),
      badgeType: this.gridHelper.getTicketBadgeType(value),
    };
  }

  getLatestGracePeriod(message: Message) {
    return message.grace_period_third || message.grace_period_second || message.grace_period_first;
  }
}
