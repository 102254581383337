import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CustomerRouterSelectors } from '../customer-router.selectors';
import { ResolvedData, ResolvedDataReducer } from './resolver.reducer';

const resolvedDataSelector = createFeatureSelector<ResolvedData>(ResolvedDataReducer.name);

export namespace ResolvedDataSelectors {
  export const getResolvedData = createSelector(
    resolvedDataSelector,
    CustomerRouterSelectors.getAssetId,
    (data, assetId) => (data?.settings?.asset?.id === assetId ? data : undefined)
  );

  export const getAssetSettings = createSelector(getResolvedData, (data) => data?.settings);

  export const getProjectTermsConditionsId = createSelector(
    getResolvedData,
    (data) => data?.project_terms_conditions_id
  );

  export const getAssetDetails = createSelector(getAssetSettings, (settings) => settings?.asset);

  export const getIndexPointsEnabled = createSelector(
    getAssetSettings,
    (settings) => settings?.asset?.display_index_points || false
  );

  export const getIsAssetFrozen = createSelector(getAssetSettings, (settings) => settings?.asset?.is_frozen || false);

  export const getPermission = createSelector(getAssetSettings, (settings) => settings?.permission);

  export const getGroup = createSelector(getAssetSettings, (settings) => settings?.group);

  export const getProject = createSelector(getAssetSettings, (settings) => settings?.asset.project);

  export const getProjectId = createSelector(getAssetSettings, (settings) => settings?.asset.project.id);

  export const getProjectProductType = createSelector(
    getAssetSettings,
    (settings) => settings?.asset.project.product_type
  );

  export const getAssetId = createSelector(getAssetDetails, (details) => details?.id);

  export const getAssetStatus = createSelector(getAssetSettings, (settings) => settings?.asset.status);

  export const getResolve = createSelector(resolvedDataSelector, (data) =>
    data?.assetId && data?.settings ? data : null
  );

  export const getAssetLogoUrl = createSelector(getResolvedData, (data) => data?.assetLogoUrl);

  export const getReportLogoUrl = createSelector(getResolvedData, (data) => data?.reportLogoUrl);

  export const getProjectLogoUrl = createSelector(getResolvedData, (data) => data?.projectLogoUrl);
}
