import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'imageUrl',
})
export class ImageUrlPipe implements PipeTransform {
  transform(file: File | Blob | string): string | null {
    if (_.isString(file)) {
      return `url('${file}')`;
    } else if (file) {
      const uri = window.URL.createObjectURL(file as Blob | MediaSource);
      return `url('${uri}')`;
    }

    return null;
  }
}
