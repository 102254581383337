/* eslint-disable @typescript-eslint/member-ordering */
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Asset, AssetOverview, Transform } from '@core/models';
import { Tenant } from '@core/models/tenant';
import { TermsConditions } from '@core/models/terms-conditions';
import { PORTAL_API_URL, StorageService } from '@core/services';
import { ImageSizeVersion } from '@portal/customer/services';
import { DownloadTokenAction } from '@portal/services/documents.service';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable, of } from 'rxjs';
import { catchError, first, map, mergeMap } from 'rxjs/operators';

interface ServiceAssetParams {
  project_id: string;
  asset_id: string;
}

export enum AssignmentType {
  Assign = 'assign',
  UnAssign = 'unassign',
}

@Injectable({
  providedIn: 'root',
})
export class AdminAssetsService {
  protected readonly apiUrl = PORTAL_API_URL;
  protected readonly adminApiUrl = `${this.apiUrl}/admin`;

  readonly currentAsset$ = new BehaviorSubject<Asset.Details>(null);

  constructor(private http: HttpClient, private storageService: StorageService) {}

  fetchCurrentAsset(params: ServiceAssetParams): void {
    this.getAssetDetails(params)
      .pipe(first())
      .subscribe({
        next: (asset) => this.currentAsset$.next(asset),
      });
  }

  getAssetDetails(params: ServiceAssetParams): Observable<Asset.Details> {
    if (!params?.asset_id || !params?.project_id) {
      return of(null);
    }

    return this.http.get<Asset.Details>(this.getAssetUrl(params)).pipe(
      map((details) => Asset.Details.transform(details)),
      catchError(() => of(null as Asset.Details))
    );
  }

  protected getAssetUrl({ project_id, asset_id }: ServiceAssetParams): string {
    return `${this.adminApiUrl}/projects/${project_id}/assets/${asset_id}`;
  }

  fetchAdminAssets(projectId: string): Observable<AssetOverview[]> {
    if (_.isEmpty(projectId)) {
      return EMPTY;
    }

    return this.fetchAssets(`${this.adminApiUrl}/projects/${projectId}/assets`);
  }

  uploadAssetPhoto({ project_id, asset_id, file }: { project_id: string; asset_id: string; file: File }) {
    return this.requestUploadToken({ project_id, asset_id }).pipe(
      mergeMap((upload_token) => this.storageService.upload(upload_token, file))
    );
  }

  getAdminAssetPhotoDownloadToken(
    {
      asset_id,
      project_id,
    }: {
      asset_id: string;
      project_id: string;
    },
    { version, action }: { version?: ImageSizeVersion; action?: DownloadTokenAction } = {}
  ): Observable<string> {
    let params = new HttpParams();
    if (version) {
      params = params.set('ver', version);
    }
    if (action) {
      params = params.set('action', action);
    }
    const url = `${this.adminApiUrl}/projects/${project_id}/assets/${asset_id}/photo/download_token`;
    return this.http
      .post<{ download_token: string }>(`${url}${params.toString() ? `?${params.toString()}` : ''}`, null)
      .pipe(map(({ download_token }) => download_token));
  }

  removeAsset({ asset_id, project_id }: { asset_id: string; project_id: string }): Observable<void> {
    return this.http.delete<void>(`${this.adminApiUrl}/projects/${project_id}/assets/${asset_id}`);
  }

  changeAssetStatus({ asset_id, project_id }: ServiceAssetParams, status: Asset.Status): Observable<void> {
    return this.http.post<void>(`${this.adminApiUrl}/projects/${project_id}/assets/${asset_id}/status`, {
      status,
    });
  }

  private requestUploadToken({ project_id, asset_id }: { project_id: string; asset_id: string }): Observable<string> {
    const url = `${this.adminApiUrl}/projects/${project_id}/assets/${asset_id}/photo/upload_token`;

    return this.http.post<{ upload_token: string }>(url, null).pipe(map(({ upload_token }) => upload_token));
  }

  private fetchAssets(url: string): Observable<AssetOverview[]> {
    return this.http.get<AssetOverview[]>(url).pipe(map((assetOverviews) => this.transformResponse(assetOverviews)));
  }

  private transformResponse(assetOverviews: AssetOverview[]): AssetOverview[] {
    return _(assetOverviews)
      .filter((overview) => !!overview)
      .map(this.transformAssetOverview)
      .value();
  }

  private transformAssetOverview(assetOverview: AssetOverview): AssetOverview {
    let { asset_due_date, project_due_date, asset_photo } = assetOverview;
    asset_due_date = Transform.date(asset_due_date);
    project_due_date = Transform.date(project_due_date);
    asset_photo = asset_photo || './assets/images/header.jpg';

    return {
      ...assetOverview,
      asset_due_date,
      project_due_date,
      asset_photo,
      project_finding_format: +assetOverview.project_finding_format,
    };
  }

  createTermsAndConditions(data: Partial<TermsConditions>): Observable<TermsConditions> {
    const url = `${this.adminApiUrl}/settings/terms-conditions`;
    return this.http.post<TermsConditions>(url, data);
  }

  confirmTermsAndConditions(): Observable<void> {
    const url = `${this.apiUrl}/confirm-terms-conditions`;
    return this.http.post<void>(url, null);
  }

  getTermsAndConditions(): Observable<TermsConditions> {
    const url = `${this.apiUrl}/terms-conditions`;
    return this.http.get<TermsConditions>(url);
  }

  assignOrUnassignTermsAndConditions(data: Partial<TermsConditions>, assign: AssignmentType): Observable<void> {
    const url = `${this.adminApiUrl}/settings/terms-conditions/${assign}`;
    return this.http.post<void>(url, data);
  }

  deleteTermsAndConditions(): Observable<void> {
    const url = `${this.adminApiUrl}/settings/terms-conditions`;
    return this.http.delete<void>(url);
  }

  getTenant(): Observable<Tenant> {
    const url = `${this.apiUrl}/tenant`;
    return this.http.get<Tenant>(url);
  }
}
