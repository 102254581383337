import { ChangeDetectionStrategy, Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, FormArray, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { AutofocusDirectiveModule } from '@common/directives';
import { EnterKeyDirective } from '@common/directives/enter-key/enter-key.directive';

@Component({
  selector: 'x-digits-code',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AutofocusDirectiveModule, EnterKeyDirective],
  templateUrl: './digits-code.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DigitsCodeComponent implements OnInit {
  @Input() digitsNumber = 6;
  @Output() codeChange = new EventEmitter();
  @Output() codeSubmit = new EventEmitter();

  readonly fb = inject(FormBuilder);
  codeForm: FormGroup;
  previousCodeValues: number[];
  isFocused: boolean[];

  get digits(): FormArray {
    return this.codeForm?.get('digits') as FormArray;
  }

  ngOnInit() {
    this.codeForm = this.fb.group({
      digits: this.fb.array(
        Array.from({ length: this.digitsNumber }, () =>
          this.fb.control('', [Validators.required, Validators.pattern(/[0-9]/)])
        )
      ),
    });
    this.previousCodeValues = Array(this.digitsNumber).fill(null);
    this.isFocused = Array(this.digitsNumber).fill(false);
  }

  handleInputKeyDown(index: number, event: KeyboardEvent, control: AbstractControl) {
    event.preventDefault();
    event.stopPropagation();
    const inputs: NodeList = document.querySelectorAll('.digit-input');
    if (event.key >= '0' && event.key <= '9') {
      control.patchValue(event.key);
      index < this.digitsNumber - 1 && (inputs[index + 1] as HTMLInputElement).focus();
    } else if (event.key === 'Backspace') {
      control.patchValue('');
      !this.previousCodeValues[index] && (inputs[index - 1] as HTMLInputElement)?.focus();
    }
    this.previousCodeValues[index] = control.value;
    this.codeChange.emit(this.digits.value.join(''));
  }

  onFocus(index: number): void {
    this.isFocused[index] = true;
  }

  onBlur(index: number): void {
    this.isFocused[index] = false;
  }

  trackByIndex(index: number): number {
    return index;
  }
}
