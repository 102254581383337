import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { TranslocoModule } from '@ngneat/transloco';

export interface ToggleOptionData<T> {
  id: T;
  text?: string;
  iconName?: AvailableIcons;
  tooltip?: string;
}

@Component({
  selector: 'x-toggle',
  standalone: true,
  imports: [CommonModule, SvgIconModule, TranslocoModule],
  templateUrl: './toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToggleComponent<T> {
  @Input() options: ToggleOptionData<T>[];
  @Input() selectedId: string;
  @Output() optionClick = new EventEmitter<T>();

  trackByFn(index, item) {
    return item.id || index;
  }
}
