<div
  [xHyphenate]="true"
  class="text-ellipsis overflow-hidden break-words text-md font-normal"
  [ngClass]="{
    'blur-sm opacity-40 line-clamp-3': params.blurred,
    'line-clamp-4': !params?.blurred,
  }"
  [innerText]="value || (params.node?.rowPinned ? '' : '-')"
  [title]="!params?.blurred ? value : ''"
></div>
<span class="absolute flex items-center w-full h-full top-0 left-0 text-primary-600" *ngIf="params.blurred">
  {{ params?.blurredPlaceholder }}
</span>
