import { Injectable } from '@angular/core';
import { Norm } from '@core/models';
import { HOST_URL, NormService, StorageService } from '@core/services';
import { environment } from '@environments';
import * as _ from 'lodash';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { fromEvent, of, Subject } from 'rxjs';
import { catchError, filter, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { LinkHelperService } from './link-helper.service';

interface JsPdfViewer {
  container: HTMLElement;
  currentPageNumber: number;
  scroll: {
    down: boolean;
    lastX: number;
    lastY: number;
    right: boolean;
  };
  getPageView(page: number): {
    div: HTMLDivElement;
  };
}

@Injectable({
  providedIn: 'root',
})
export class PDFHelperService {
  static readonly NORM_REGEX = /\/norm\/(.+)/i;

  constructor(
    private readonly storageService: StorageService,
    private readonly normService: NormService,
    private readonly linkHelperService: LinkHelperService
  ) {}

  replaceDownloadButton(pdfViewer: PdfJsViewerComponent): void {
    const contentDocument = this.getContentDocument(pdfViewer);
    if (!contentDocument) {
      return;
    }

    const button = contentDocument.getElementById('download');
    if (button) {
      const buttonClone = button.cloneNode(true);
      button.parentNode?.replaceChild(buttonClone, button);
    }

    const secondaryButton = contentDocument.getElementById('secondaryDownload');
    if (secondaryButton) {
      const secondaryButtonClone = secondaryButton.cloneNode(true);
      secondaryButton.parentNode?.replaceChild(secondaryButtonClone, secondaryButton);
    }
  }

  addClickListener(
    pdfViewer: PdfJsViewerComponent,
    destroy$: Subject<void>,
    callbacks: Partial<{
      onDownload: Function;
      onPrint: Function;
    }> = {}
  ): void {
    const contentDocument = this.getContentDocument(pdfViewer);
    if (!contentDocument) {
      return;
    }

    fromEvent(contentDocument, 'click')
      .pipe(
        takeUntil(destroy$),
        tap((event) => {
          if (this.getIsAnchor(event.target)) {
            let href = (event.target as HTMLAnchorElement).href.toLowerCase();
            if (!environment.production) {
              href = href.replace('https://dev-app.doree.plus', HOST_URL);
            }
            const normId = this.getNormId(event.target);

            if (href?.indexOf('pdfjs/web/viewer.html') === -1) {
              event.preventDefault();
              event.stopImmediatePropagation();

              if (!normId) {
                if (this.isExternalLink(href)) {
                  window.open(href);
                } else {
                  this.linkHelperService.open(href);
                }
              }
            }
          } else if (this.getIsClickedOnDownload(event)) {
            this.storageService.openDownloadDialog(pdfViewer.downloadFileName, pdfViewer.pdfSrc as Blob);
            callbacks.onDownload?.();
          } else if (this.getIsClickedOnPrint(event)) {
            callbacks.onPrint?.();
          }
        })
      )
      .subscribe();
  }

  addNormListeners(pdfViewer: PdfJsViewerComponent, tooltip: HTMLElement, destroy$: Subject<void>): void {
    const contentDocument = this.getContentDocument(pdfViewer);
    if (!contentDocument) {
      return;
    }

    const showTooltip = _.debounce((norm: Norm, rect: DOMRect) => {
      tooltip.innerHTML = `<b>${norm?.name}</b><p>${norm?.description}</p><a href="${norm?.url}" target="_blank">Link</a>`;
      tooltip.classList.add('visible');
      tooltip.setAttribute('style', `top: ${rect.top + rect.height}px; left: ${Math.max(rect.left, 0)}px`);
    }, 100);

    const hideTooltip = _.debounce(() => {
      tooltip.classList.remove('visible');
      tooltip.removeAttribute('style');
      tooltip.innerHTML = '';
    }, 100);

    const hideTooltip$ = new Subject<void>();
    hideTooltip$
      .pipe(
        takeUntil(destroy$),
        tap(() => {
          showTooltip.cancel();
          hideTooltip();
        })
      )
      .subscribe();

    fromEvent(contentDocument, 'mouseover')
      .pipe(
        takeUntil(destroy$),
        map((event) => ({
          event,
          normId: this.getNormId(event.target),
        })),
        filter(({ normId }) => !!normId),
        switchMap(({ event, normId }) => {
          event.preventDefault();
          event.stopPropagation();

          const target = event?.target as HTMLAnchorElement;
          target.removeAttribute('title');

          return this.normService.getNorm(normId).pipe(
            takeUntil(hideTooltip$),
            map((norm) => ({ norm, target })),
            catchError(() => of({ norm: null, target }))
          );
        })
      )
      .subscribe({
        next: ({ norm, target }) => {
          if (!norm) {
            return;
          }
          const rect = target.getBoundingClientRect();
          showTooltip(norm, rect);
        },
      });

    fromEvent(contentDocument, 'mouseout')
      .pipe(
        filter((event) => this.getIsAnchor(event.target)),
        takeUntil(destroy$),
        tap(() => hideTooltip$.next(null))
      )
      .subscribe();

    fromEvent(tooltip, 'mouseenter').pipe(takeUntil(destroy$), tap(hideTooltip.cancel)).subscribe();

    fromEvent(tooltip, 'mouseleave').pipe(takeUntil(destroy$), tap(hideTooltip)).subscribe();
  }

  getIsPageVisible(pdfViewer: PdfJsViewerComponent, page: number): boolean | undefined {
    const jsPdfViewer = pdfViewer?.PDFViewerApplication?.pdfViewer as JsPdfViewer;

    if (!jsPdfViewer) {
      return;
    }

    const containerHeight = jsPdfViewer.container.offsetHeight;
    const containerStart = jsPdfViewer.scroll.lastY;
    const containerEnd = jsPdfViewer.scroll.lastY + containerHeight;

    const pages = jsPdfViewer.container.querySelectorAll('.page');
    let height = 0;
    let isVisible = false;
    pages.forEach((pageElement, index) => {
      const pageHeight = (pageElement as HTMLElement).scrollHeight;
      const pageStart = height;
      height += pageHeight;

      if (index === page - 1) {
        isVisible = height >= containerStart && height <= containerEnd;
        isVisible = isVisible || (pageStart >= containerStart && pageStart <= containerEnd);
        return;
      }
    });

    return isVisible;
  }

  private getContentDocument(pdfViewer: PdfJsViewerComponent): HTMLDocument {
    return pdfViewer.iframe?.nativeElement?.contentDocument;
  }

  private getIsAnchor(element: EventTarget): boolean {
    return (element as HTMLElement).tagName?.toLowerCase() === 'a';
  }

  private getNormId(element: EventTarget): string {
    if (!this.getIsAnchor(element)) {
      return null;
    }
    const { href } = element as HTMLAnchorElement;
    return (href.match(PDFHelperService.NORM_REGEX) || [])[1];
  }

  private isExternalLink(url: string): boolean {
    return url.indexOf(HOST_URL) === -1;
  }

  private getIsClickedOnDownload(event: Event): boolean {
    const d = 'download';
    const sd = 'secondaryDownload';

    return this.getIsButtonClicked(event, d, sd);
  }

  private getIsClickedOnPrint(event: Event): boolean {
    const p = 'print';
    const sp = 'secondaryPrint';

    return this.getIsButtonClicked(event, p, sp);
  }

  /**
   *
   * @param event
   * @param b - button id
   * @param sb - secondary button id
   * @returns boolean
   */
  private getIsButtonClicked(event: Event, b: string, sb: string): boolean {
    const target = event.target as HTMLElement;

    if (!target) {
      return false;
    }

    if (target.id === b || target.id === sb) {
      return true;
    } else if (target.tagName.toLowerCase() !== 'button') {
      if (target.parentElement?.id === b || target.parentElement?.id === sb) {
        return true;
      }
    }

    return false;
  }
}
