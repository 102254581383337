<ng-container [ngSwitch]="fieldName">
  <ng-container *ngSwitchCase="'factsheet'">{{ 'factsheet' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'user'">{{ 'user' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'qa'">{{ 'q-a' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'finding'">{{ 'findings' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'report'">{{ 'reports' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'files'">{{ 'files' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'admin_project'">{{ 'projects' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'admin_user'">{{ 'user' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'admin_statistics'">{{ 'statistics' | transloco }}</ng-container>

  <!-- Data Room permissions -->
  <ng-container *ngSwitchCase="'inherit'">{{ 'same-permission-as-parent-folder' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'pm_and_managers'">
    {{ 'full-permission-for-manager-and-project-manager-group' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="'managers_only'">{{ 'managers-only' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'admins_and_owner'">{{ 'admins-and-owner' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>
