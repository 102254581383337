import { FindingField } from '@core/models';

export type FindingEditorType =
  | 'text'
  | 'string'
  | 'number'
  | 'date'
  | 'references'
  | 'risk'
  | 'trade'
  | 'label'
  | 'tags'
  | 'status'
  | 'finding_type'
  | 'add_document'
  | 'add_photo'
  | 'number_array';

export const getFindingFieldEditorType = (field: FindingField): FindingEditorType => {
  switch (field) {
    case 'finding_processed':
    case 'finding':
    case 'guidance':
      return 'text';

    case 'trade':
    case 'trade_id':
      return 'trade';

    case 'risk_evaluation':
      return 'risk';

    case 'references':
    case 'documents':
    case 'photos':
      return 'references';

    case 'overhaul_backlog_now':
    case 'overhaul_backlog_now_plan':
    case 'overhaul_backlog_next_year':
    case 'overhaul_backlog_next_year_plan':
    case 'plan_invest_next_years':
    case 'plan_invest_next_years_plan':
    case 'plan_invest_following_years':
    case 'plan_invest_following_years_plan':
    case 'risk_value_eur':
    case 'lifetime_age':
    case 'lifetime_default':
    case 'lifetime_remaining':
    case 'risk_value_result':
      return 'number';

    case 'failure_probability':
    case 'follow_up_costs':
    case 'failure_effect':
      return 'number_array';

    case 'current_no':
      return 'label';

    case 'created_at':
    case 'deleted_at':
    case 'published_at':
    case 'on_site_visit_at':
      return 'date';

    case 'buildings':
    case 'rental_areas':
      return 'tags';

    case 'status':
    case 'finding_type':
    case 'add_document':
    case 'add_photo':
      return field;

    default:
      return 'string';
  }
};
