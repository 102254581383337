<ng-container *transloco="let t; read: 'project-details'">
  <ng-container *ngIf="details$ | async as details">
    <ng-container *ngIf="projectId$ | async as projectId">
      <header class="flex space-between items-end w-full min-h-[40px]">
        <h2 class="font-bold text-black">
          {{ details.name }}
        </h2>

        <x-button class="h-[36px]" color="white" [routerLink]="adminRoute.Settings">
          <x-svg-icon svgImageName="cog-2" class="mr-2"></x-svg-icon>
          {{ 'settings' | transloco }}
        </x-button>
      </header>

      <div class="p-0.5 mt-4 space-x-8 w-full flex">
        <x-card class="w-full h-full flex flex-col">
          <div class="grid grid-cols-4 gap-4">
            <span class="flex space-x-4 col-span-1 font-bold min-w-[240px]">{{ 'project' | transloco }}:</span>
            <span class="col-span-3">{{ details.name }}</span>
            <span class="flex space-x-4 col-span-1 font-bold min-w-[240px]">{{ 'token' | transloco }}:</span>
            <span class="col-span-3">{{ details.token }}</span>
            <span class="flex space-x-4 col-span-1 font-bold min-w-[240px]">{{ 'status' | transloco }}:</span>
            <span class="col-span-3">{{ details.status }}</span>
            <span class="flex space-x-4 col-span-1 font-bold min-w-[240px]">{{ 'product' | transloco }}:</span>
            <span class="col-span-3">{{ details.product_type }}</span>
            <span class="flex space-x-4 col-span-1 font-bold min-w-[240px]">{{ 'created' | transloco }}:</span>
            <span class="col-span-3">{{ details.created_at | localeDate }}</span>
          </div>
        </x-card>
        <x-card class="w-full h-full flex flex-col space-y-4">
          <div class="grid grid-cols-4 gap-4 mb-4">
            <span class="col-span-1 font-bold min-w-[240px]">{{ 'admins' | transloco }}:</span>
            <div class="col-span-3">
              <div *ngFor="let admin of admins$ | async" class="flex w-full items-center justify-between mb-2">
                <span class="cursor-pointer" (click)="showAdminDetail(admin)">
                  <span [title]="admin.user?.email">{{ admin.user | userName }}</span>
                  <span *ngIf="admin.is_group_lead">&nbsp;*</span>
                </span>
                <div class="cursor-pointer" [title]="t('remove')" (click)="onRemoveAdmin(projectId, admin, $event)">
                  <x-svg-icon
                    svgImageName="cross"
                    svgClass="w-6 h-6 text-gray-400 rounded-xl hover:bg-gray-200"
                  ></x-svg-icon>
                </div>
              </div>
              <div>
                <x-button
                  class="!p-0 mt-1"
                  [color]="isUserAdmin ? 'white' : 'primary'"
                  size="sm"
                  classes="h-[32px]"
                  [title]="t('add-admin')"
                  (click)="onAddAdminClick(projectId)"
                >
                  <x-svg-icon
                    svgImageName="plus"
                    svgClass="w-3 h-3 mr-1"
                    [ngClass]="{
                      'text-gray-700': isUserAdmin,
                      'text-white': !isUserAdmin
                    }"
                  ></x-svg-icon>
                  <span>{{ t('administrator') }}</span>
                </x-button>
              </div>
            </div>
          </div>

          <div class="grid grid-cols-4 gap-4">
            <span class="col-span-1 font-bold min-w-[240px]">{{ t('owner') }}:</span>
            <span class="col-span-3 cursor-pointer" (click)="showOwnerDetails(details.owner)">
              {{ details.owner | userName }}
            </span>
          </div>
        </x-card>
      </div>

      <ng-container *ngIf="isUserAdmin; else notAdminTemplate">
        <ng-container *ngIf="assetOverview$ | async as assetOverview">
          <ng-container *ngIf="assetOverview; else loading">
            <div *ngIf="selectedAssetOverview; else assetsOverview">
              <div class="flex items-center space-x-3 py-6 mt-1">
                <x-button iconButtonIconName="arrow-left" (click)="resetSelectedAsset()"></x-button>
                <p class="text-3xl font-bold text-black mr-8">{{ t('asset-schedule') }}</p>
              </div>
              <x-asset-overview-grid-view
                [assets]="[selectedAssetOverview]"
                [isClickable]="false"
              ></x-asset-overview-grid-view>

              <x-portal-tracker class="mt-2" [assetId]="selectedAssetOverview.asset_id"></x-portal-tracker>
            </div>
            <ng-template #assetsOverview>
              <x-assets-overview
                [assets]="assetOverview"
                [isAdmin]="true"
                [newDownloadTokenGetter]="newDownloadTokenGetter"
                [title]="t('assets')"
                [canNoResultsBeShowedOnInit]="true"
                [noAssetsCustomPrimaryText]="t('no-assets')"
                [noAssetsCustomSecondaryText]="t('no-assets-details')"
                [isCardEditButtonShowed]="true"
                (uploadAssetPhoto)="onUploadAssetPhoto($event)"
                (assetClick)="onAssetOverviewSelected($event)"
                (editAssetClick)="onAssetEdit(projectId, $event)"
              >
                <x-button class="primary icon-button button-add" [title]="t('asset')" (click)="onAddAsset(projectId)">
                  <x-svg-icon svgImageName="plus" svgClass="w-4 h-4 mr-1 text-white"></x-svg-icon>
                  {{ t('asset') }}
                </x-button>
              </x-assets-overview>
            </ng-template>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-template #notAdminTemplate>
        <ng-container *ngIf="isUserAdmin === false">
          <h3 class="text-gray-400 text-2xl flex mt-6 w-full justify-center items-center">
            <x-svg-icon
              svgImageName="error"
              svgClass="w-10 h-10 text-gray-400 rounded-xl mr-4 hover:bg-gray-200"
            ></x-svg-icon>
            {{ t('no-admin-permission') }}
          </h3>
        </ng-container>
        <ng-container *ngIf="isUserAdmin === null">
          <ng-container *ngTemplateOutlet="loading"></ng-container>
        </ng-container>
      </ng-template>

      <ng-template #loading>
        <div class="flex mt-3 items-center">
          <x-spinner class="mr-2"></x-spinner>
          <span class="text-gray-500 text-xl">{{ 'loading-data' | transloco }}...</span>
        </div>
      </ng-template>
    </ng-container>
  </ng-container>
</ng-container>
