<img #viewer id="viewer" class="image" [src]="src | safeUrl" alt="photoViewer" />
<div class="image-viewer-toolbar absolute bottom-4 w-full flex justify-center z-50">
  <ul class="p-2 flex cursor-auto bg-white opacity-80 gap-4 shadow-[0_7px_24px_-16px_rgba(0,0,0,1)] rounded-[31px]">
    <li class="flex-1" *ngFor="let action of photoActions">
      <div class="p-2 rounded-full hover:bg-gray-400 cursor-pointer" (click)="triggerAction(action.name)">
        <x-svg-icon [svgImageName]="action.iconName"></x-svg-icon>
      </div>
    </li>
  </ul>
</div>
