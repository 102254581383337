import { ColDef, ITooltipParams, ValueGetterParams } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Document, Message } from '@core/models';
import { TranslocoService } from '@ngneat/transloco';
import {
  BadgeCellRendererComponent,
  BadgeCellRendererData,
} from '@portal/components/badge-cell-renderer/badge-cell-renderer.component';
import { WrappedTextCellRendererComponent } from '@portal/components/grid/wrapped-text-cell-renderer/wrapped-text-cell-renderer.component';
import { QaCurrentNoRendererComponent } from '@portal/components/qa-overview/qa-current-no-renderer/qa-current-no-renderer.component';
import { GridHelperColDef, GridHelperService } from '../grid';
import { QAReferenceCellRendererComponent } from './qa-reference-cell-renderer/qa-reference-cell-renderer.component';
import { ReferenceCellRendererData } from './qa-reference-cell-renderer/qa-reference-cell-renderer.model';
import { Priority } from '@core/models/priority';
import { IndexPointsService } from '@core/services/index-points.service';

export type QaColumnDef = Omit<GridHelperColDef, 'colDef'>;

@Injectable({
  providedIn: 'root',
})
export class QaOverviewGridHelperService {
  constructor(
    private readonly gridHelper: GridHelperService,
    private transloco: TranslocoService,
    private indexPointsService: IndexPointsService
  ) {}

  getColumnDefs(qaColDef: QaColumnDef, hasUserViewPermission: boolean): ColDef[] {
    return [
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('current_no', {
          minWidth: 75,
          cellClass: 'py-4',
          cellRendererFramework: QaCurrentNoRendererComponent,
          cellRendererParams: ({ value }) =>
            <{ value }>{
              value: {
                current_no: value.current_no,
              },
            },
        }),
      }),
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('message', {
          flex: 7,
          minWidth: 220,
          wrapText: true,
          cellRendererFramework: WrappedTextCellRendererComponent,
        }),
      }),
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('status', {
          flex: 4,
          minWidth: 105,
          cellRendererFramework: BadgeCellRendererComponent,
          cellRendererParams: ({ value }) =>
            <{ value: BadgeCellRendererData }>{
              value: {
                text: this.transloco.translate(value),
                badgeType: this.gridHelper.getStatusBadgeType(value),
              },
            },
        }),
      }),
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('finding', {
          minWidth: 100,
          flex: 4,
          wrapText: true,
          cellRendererFramework: QAReferenceCellRendererComponent,
          valueGetter: ({ data }: ValueGetterParams) => {
            const message = data as Message;
            const finding = message?.finding;
            const document = message?.node?.document
              ? {
                  ...message.node.document,
                  name: this.indexPointsService.getLabel(message.node.label, message.node.index_point),
                }
              : message?.document;
            const report = message?.report;
            const report_chapter = message?.report_chapter;

            if (finding?.id) {
              return <ReferenceCellRendererData>{
                finding,
                message_id: message.id,
              };
            }

            if (document?.id) {
              return document.types.includes('photo')
                ? <ReferenceCellRendererData>{
                    photos: [<Document.Short>document],
                    message_id: message.id,
                  }
                : <ReferenceCellRendererData>{
                    documents: [<Document.Short>document],
                    pages: [(data as Message).document_page],
                    message_id: message.id,
                  };
            }

            if (report?.id || report_chapter?.id) {
              return <ReferenceCellRendererData>{
                report,
                report_chapter: message?.report_chapter,
                message_id: message.id,
              };
            }

            return null;
          },
        }),
      }),
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('priority', {
          minWidth: 140,
          flex: 3,
          comparator: Priority.compare,
          cellRendererFramework: BadgeCellRendererComponent,
          cellRendererParams: ({ value }) =>
            <{ value: BadgeCellRendererData }>{
              value: {
                text: this.transloco.translate(value),
                badgeType: 'secondary',
              },
            },
        }),
      }),
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('documents', {
          wrapText: true,
          minWidth: 100,
          flex: 4,
          cellRendererFramework: QAReferenceCellRendererComponent,
          valueGetter: ({ data }: ValueGetterParams) => {
            const documents = (data as Message)?.documents;
            if (!documents) {
              return null;
            }

            return <ReferenceCellRendererData>{
              documents,
            };
          },
        }),
      }),
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('message_group', {
          minWidth: 130,
          flex: 3.5,
          valueGetter: ({ data }) => {
            const group = (data as Message)?.message_group;
            return group?.name;
          },
          hide: true,
        }),
      }),
      ...(hasUserViewPermission
        ? [
            this.gridHelper.getColDef({
              ...qaColDef,
              colDef: this.getColDef('to_group', {
                minWidth: 130,
                flex: 3.5,
                valueGetter: ({ data }) => {
                  const group = (data as Message)?.to_group;
                  return group?.name;
                },
                hide: true,
              }),
            }),
          ]
        : []),
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('response', {
          minWidth: 200,
          cellClass: 'py-2',
          flex: 5,
          wrapText: true,
          cellRendererFramework: WrappedTextCellRendererComponent,
          hide: true,
        }),
      }),

      this.gridHelper.getDateColDef(
        {
          ...qaColDef,
          colDef: this.getColDef('released_for_all_at', { hide: true, flex: 3, minWidth: 140 }),
        },
        true
      ),
      ...(hasUserViewPermission
        ? [
            this.gridHelper.getColDef({
              ...qaColDef,
              colDef: this.getColDef('message_user', {
                minWidth: 160,
                valueGetter: this.gridHelper.getUserCellValueGetter(),
                tooltipValueGetter: (params: ITooltipParams) => params.value,
                hide: true,
                wrapText: true,
              }),
            }),
          ]
        : []),
      this.gridHelper.getDateColDef(
        {
          ...qaColDef,
          colDef: this.getColDef('message_approved_at', { hide: true, minWidth: 140 }),
        },
        true
      ),
      this.gridHelper.getColDef({
        ...qaColDef,
        colDef: this.getColDef('response_user', {
          valueGetter: this.gridHelper.getUserCellValueGetter(),
          tooltipValueGetter: (params: ITooltipParams) => params.value,
          hide: true,
          minWidth: 160,
          wrapText: true,
        }),
      }),
      this.gridHelper.getDateColDef(
        {
          ...qaColDef,
          colDef: this.getColDef('response_created_at', { hide: true }),
        },
        true
      ),
      this.gridHelper.getDateColDef(
        {
          ...qaColDef,
          colDef: this.getColDef('response_approved_at', { hide: true }),
        },
        true
      ),
    ];
  }

  private getColDef(field: keyof Message, colDef: Partial<ColDef> = {}): ColDef {
    return {
      ...colDef,
      field,
    };
  }
}
