import { Injectable } from '@angular/core';
import { AvailableLanguages } from '@core/models';
import { LanguageService } from './language-service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  constructor(private readonly languageService: LanguageService) {}

  private get localePath(): string {
    return this.languageService.lang === AvailableLanguages.German ? `/${AvailableLanguages.German}` : '';
  }

  get paths(): string[] {
    return window.location.pathname
      .toLowerCase()
      .split('/')
      .filter((path) => !!path && path !== this.languageService.lang);
  }

  reload(): void {
    window.location.reload();
  }

  navigateAccordingToLocale(path?: string): void {
    if (!path) {
      return this.navigateAccordingToLocale('');
    }

    let delimiter = '';
    if (path.indexOf('/') === -1 && path.length !== 0) {
      delimiter = '/';
    }

    const url = `${this.localePath}${delimiter}${path}`;
    window.location.pathname = url;
  }
}
