import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CellRendererComponent } from '../cell-renderer';
import { LocaleCurrencyPipeModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'x-total-costs-renderer',
  standalone: true,
  imports: [CommonModule, LocaleCurrencyPipeModule, TranslocoModule],
  templateUrl: './total-costs-renderer.component.html',
  styleUrls: ['./total-costs-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalCostsRendererComponent extends CellRendererComponent {}
