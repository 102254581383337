import { ChangeDetectionStrategy, Component, HostBinding, HostListener, Input } from '@angular/core';

import { AvailableLanguages } from '@core/models';
import { LanguageService } from '@core/services';

@Component({
  selector: 'x-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSwitcherComponent {
  @Input() useIcons = false;
  @HostBinding('class.opened')
  opened = false;

  readonly languageIcons = {
    [AvailableLanguages.German]: 'de-flag',
    [AvailableLanguages.English]: 'gb-flag',
    [AvailableLanguages.French]: 'fr-flag',
    [AvailableLanguages.Italian]: 'it-flag',
    [AvailableLanguages.Danish]: 'dk-flag',
  };
  readonly currentLang$ = this.languageService.language$;
  readonly languages = AvailableLanguages.all;
  readonly AvailableLanguages = AvailableLanguages;

  constructor(private languageService: LanguageService) {}

  @HostListener('click')
  onClick(): void {
    this.opened = !this.opened;
  }

  @HostListener('mouseleave')
  onDocumentClick(): void {
    this.opened = false;
  }

  onLanguageClick(lang: AvailableLanguages, prevLang: AvailableLanguages): void {
    if (lang !== prevLang) {
      this.languageService.setLanguage(lang);
    }
  }

  trackByLanguage(index: number, value: AvailableLanguages): string {
    return value;
  }
}
