<div *ngIf="type !== AlertType.Default" class="icon-container">
  <ng-container [ngSwitch]="type">
    <span *ngSwitchCase="AlertType.Success" class="icon-font icon-font-alert-success"></span>
    <span *ngSwitchCase="AlertType.Error" class="icon-font icon-font-error-outline"></span>
    <span *ngSwitchCase="AlertType.Info" class="icon-font icon-font-error-outline info"></span>
    <span *ngSwitchCase="AlertType.Progress" class="icon-progress"></span>
  </ng-container>
</div>
<div class="text-container" *ngIf="text || messageTemplate || data?.error; else missingMessageTemplate">
  <ng-container *ngIf="text">
    <div class="alert-text" [innerHTML]="text"></div>
  </ng-container>

  <ng-container *ngIf="messageTemplate">
    <ng-container *ngTemplateOutlet="messageTemplate; context: { $implicit: data?.context }"></ng-container>
  </ng-container>

  <ng-container *ngIf="data?.error">
    <div *ngFor="let error of data.error?.errors" class="alert-details">
      {{ error.details }}
    </div>
  </ng-container>
</div>

<button mat-button color="accent" (click)="snackBarRef.dismiss()">
  {{ 'notification.ok' | transloco }}
</button>

<ng-template #missingMessageTemplate>
  <div class="text-container">
    <ng-container [ngSwitch]="type">
      <span *ngSwitchCase="AlertType.Error">{{ 'notification.unknown-error' | transloco }}</span>
    </ng-container>
  </div>
</ng-template>
