<div class="logo-container">
  <ng-container *ngIf="headerLogoUrl$ | async as headerLogoUrl; else defaultLogoTemplate">
    <a class="logo" [routerLink]="''" [ngStyle]="{ 'background-image': 'url(' + headerLogoUrl + ')' }"></a>
  </ng-container>
</div>

<ng-content></ng-content>

<ng-container *ngIf="showUser">
  <ng-container *ngTemplateOutlet="userButtonTemplate; context: { $implicit: user$ | async }"></ng-container>
</ng-container>

<x-language-switcher></x-language-switcher>

<ng-template #userButtonTemplate let-user>
  <button #userButton class="primary user" [class.authorized]="user" (click)="openUserDialog(user)">
    <span class="icon-font icon-font-user"></span>
  </button>
</ng-template>

<ng-template #defaultLogoTemplate>
  <a class="logo" [routerLink]="''" [ngStyle]="{ 'background-image': 'url(' + logoPath + '/' + logoUrl + ')' }"></a>
</ng-template>
