<x-dialog
  (handleSubmit)="onConfirm()"
  (handleClose)="onCancel()"
  [submitBtnText]="'agree' | transloco"
  [cancelBtnText]="'refuse' | transloco"
  [isHeaderShowed]="false"
  [hideCloseIcon]="true"
>
  <h2 class="font-bold text-black py-8">{{ description }}</h2>
  <div class="text-container text-gray-600" [innerHTML]="theHtmlString"></div>
</x-dialog>
