import { NoRowsOverlayComponent as BaseNoRowsOverlayComponent } from '@ag-grid-community/core/dist/es6/rendering/overlays/noRowsOverlayComponent';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'x-finding-no-rows-overlay',
  templateUrl: './finding-no-rows-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindingNoRowsOverlayComponent extends BaseNoRowsOverlayComponent {
  @HostBinding('class')
  readonly class = 'ag-overlay-loading-center';

  init(): void {}

  agInit(): void {}
}
