import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '@core/models';
import { PageMessagesService } from '../services';

@Pipe({
  name: 'pageMessages',
})
export class PageMessagesPipe implements PipeTransform {
  constructor(private readonly pageMessagesService: PageMessagesService) {}

  transform(messages: Message.Short[] | undefined, currentPage: number | undefined): Message.Short[] {
    return this.pageMessagesService.transform(messages, currentPage);
  }
}
