import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'x-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInputComponent {
  @Input() set value(value: Date) {
    this.dateValue = value;
  }
  @Input() disabled?: boolean;
  @Input() disallowPastDates?: boolean;
  @Input() reset?: boolean;
  @Input() includeTime?: boolean;
  @Input() label = '';
  @Input() required?: '' | 'required' | boolean;
  @Input() minDate: Date;

  @Output() valueChange = new EventEmitter<Date | undefined>();

  dateValue?: Date;

  get canReset(): boolean {
    return !this.disabled && !!this.reset;
  }

  onDateValueChanged(value: Date | undefined): void {
    this.valueChange.emit(value);

    this.dateValue = value;
  }

  onResetValue(): void {
    if (this.disabled) {
      return;
    }

    this.onDateValueChanged(undefined);
  }

  onKeyPress(event: Event): boolean {
    event.preventDefault();
    return false;
  }

  futureDateFilter = (date: Date | null): boolean => {
    const currentDate = new Date();
    date?.setHours(23, 59, 59, 59);
    return date !== null && date >= currentDate;
  };
}
