import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AuthService } from '@app/core/services';
import { NotificationsComponent } from '@app/user/notification-plan/notifications/notifications.component';
import { environment } from '@environments';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DropdownTriggerDirective } from '../dropdown/dropdown-trigger.directive';
import { SvgIconModule } from '../svg-icon/svg-icon.module';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { HeaderActions } from '@common';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { HeaderNotificationsStore } from '@common/page/header/header-notifications.store';

@UntilDestroy()
@Component({
  selector: 'x-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [CommonModule, RouterModule, SvgIconModule, NotificationsComponent, DropdownTriggerDirective],
  providers: [HeaderNotificationsStore],
})
export class HeaderComponent implements OnInit {
  @Input() classes = '';
  @Input() showNotifications = false;
  @Input() watchAuthTokenChanges = true;
  @Input() logoClasses = '';
  @Effect() headerLogoUrl$ = this.actions$.pipe(
    ofType(HeaderActions.setLogo),
    map((action) => action.url),
    distinctUntilChanged()
  );

  readonly doreeLogoUrl = `${environment.production ? '/static' : ''}/assets/images/doree-logo.svg`;

  constructor(private authService: AuthService, private readonly actions$: Actions) {}

  ngOnInit(): void {
    if (this.watchAuthTokenChanges) {
      this.authService.tokenChanged$.pipe(untilDestroyed(this)).subscribe({
        next: (tokenChanges) => {
          this.showNotifications = tokenChanges !== null;
        },
      });
    }
  }
}
