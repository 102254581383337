<x-dialog
  [title]="'create-project' | transloco"
  classes="w-[70vw] max-w-[680px]"
  (handleSubmit)="onSubmit()"
  (handleClose)="onClose()"
>
  <form class="grid grid-cols-3 gap-4" autocomplete="off">
    <span class="field-label" [class.required]="isRequired(commonFormGroup?.controls['name'] | formControl)">
      {{ 'project-label.project-name' | transloco }}:
    </span>
    <x-input class="col-span-2" [control]="commonFormGroup?.controls['name'] | formControl"></x-input>

    <span class="field-label" [class.required]="isRequired(projectTypeControl)">
      {{ 'project-label.project-type' | transloco }}:
    </span>
    <x-select
      class="col-span-2"
      [options]="projectTypeOptions"
      [control]="projectTypeControl"
      [value]="'tdd'"
    ></x-select>

    <ng-container *ngIf="projectTypeControl.value === 'demo'">
      <span class="field-label" [class.required]="isRequired(demoFormGroup?.controls['template_id'] | formControl)">
        {{ 'project-label.template' | transloco }}:
      </span>
      <x-select
        class="col-span-2"
        [options]="projectTemplates$ | async"
        [control]="demoFormGroup?.controls['template_id'] | formControl"
      ></x-select>
    </ng-container>

    <ng-container *ngIf="projectTypeControl.value === 'tdd'">
      <span class="field-label" [class.required]="isRequired(tddFormGroup.get('product_type') | formControl)">
        {{ 'project-label.product-type' | transloco }}:
      </span>
      <x-select
        class="col-span-2"
        [options]="productTypes$ | async"
        [control]="tddFormGroup.get('product_type') | formControl"
      ></x-select>

      <span class="field-label" [class.required]="isRequired(tddFormGroup.get('modules') | formControl)">
        {{ 'project-label.packages' | transloco }}:
      </span>
      <x-multi-select
        class="col-span-2"
        [control]="tddFormGroup.get('modules') | formControl"
        [options]="packagesOptions"
      ></x-multi-select>

      <span class="field-label" [class.required]="isRequired(tddFormGroup.get('language') | formControl)">
        {{ 'project-label.project-language' | transloco }}:
      </span>
      <x-select
        class="col-span-2"
        [options]="languages"
        [control]="tddFormGroup.get('language') | formControl"
      ></x-select>

      <ng-container
        *ngIf="!tddFormGroup.get('product_type') || (tddFormGroup.get('product_type') | formControl).value === 'tdd'"
      >
        <span class="field-label" [class.required]="isRequired(tddFormGroup.get('tdd_trade_set_id') | formControl)">
          {{ 'project-label.tdd-trade-set' | transloco }}:
        </span>
        <x-select
          class="col-span-2"
          [options]="tradeSets$ | async"
          [control]="tddFormGroup.get('tdd_trade_set_id') | formControl"
        ></x-select>

        <span class="field-label" [class.required]="isRequired(tddFormGroup.get('tdd_finding_format') | formControl)">
          {{ 'project-label.tdd-finding-format' | transloco }}:
        </span>
        <x-select
          class="col-span-2"
          [options]="tddFindingFormats"
          [withEmptyOption]="true"
          [emptyOptionValue]="0"
          [control]="tddFormGroup.get('tdd_finding_format') | formControl"
        ></x-select>
      </ng-container>

      <ng-container *ngIf="(tddFormGroup.get('product_type') | formControl).value === 'monitoring'">
        <span
          class="field-label"
          [class.required]="isRequired(tddFormGroup.get('monitoring_trade_set_id') | formControl)"
        >
          {{ 'project-label.monitoring-trade-set' | transloco }}:
        </span>
        <x-select
          class="col-span-2"
          [options]="tradeSets$ | async"
          [control]="tddFormGroup.get('monitoring_trade_set_id') | formControl"
        ></x-select>

        <span
          class="field-label"
          [class.required]="isRequired(tddFormGroup.get('monitoring_finding_format') | formControl)"
        >
          {{ 'project-label.monitoring-finding-format' | transloco }}:
        </span>
        <x-select
          class="col-span-2"
          [options]="monitoringFindingFormats"
          [control]="tddFormGroup.get('monitoring_finding_format') | formControl"
          [withEmptyOption]="true"
          [emptyOptionValue]="0"
        ></x-select>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="projectTypeControl.value === 'data_room'">
      <span class="field-label" [class.required]="isRequired(dataRoomFormGroup.get('language') | formControl)">
        {{ 'project-label.project-language' | transloco }}:
      </span>
      <x-select
        class="col-span-2"
        [options]="languages"
        [control]="dataRoomFormGroup.get('language') | formControl"
      ></x-select>

      <span class="field-label" [class.required]="isRequired(dataRoomFormGroup.get('add_ons').get('qa') | formControl)">
        {{ 'add-ons' | transloco }}:
      </span>
      <div class="col-span-2">
        <x-checkbox [control]="dataRoomFormGroup.get('add_ons').get('qa') | formControl" name="qa">
          {{ 'q-a' | transloco }}
        </x-checkbox>

        <x-checkbox
          [control]="dataRoomFormGroup.get('add_ons').get('photo_documentation') | formControl"
          name="photo_documentation"
        >
          {{ 'photo-documentation' | transloco }}
        </x-checkbox>

        <x-checkbox [control]="dataRoomFormGroup.get('add_ons').get('ticket') | formControl" name="ticket">
          {{ 'tickets' | transloco }}
        </x-checkbox>
      </div>

      <span class="field-label" [class.required]="isRequired(dataRoomFormGroup.get('user_quota') | formControl)">
        {{ 'project-label.user-quota' | transloco }}:
      </span>
      <x-input
        class="col-span-2"
        type="number"
        [min]="0"
        [control]="dataRoomFormGroup.get('user_quota') | formControl"
      ></x-input>
    </ng-container>

    <hr class="mt-4 col-span-3" />

    <label class="col-span-3 text-gray-900 text-xl">{{ 'owner' | transloco }}</label>

    <span class="field-label" [class.required]="isRequired(commonFormGroup.get('owner').get('email') | formControl)">
      {{ 'email' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      type="email"
      name="owner.email"
      [control]="commonFormGroup.get('owner').get('email') | formControl"
    ></x-input>

    <span
      class="field-label"
      [class.required]="isRequired(commonFormGroup.get('owner').get('firstname') | formControl)"
    >
      {{ 'first-name' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      name="owner.firstname"
      [control]="commonFormGroup.get('owner').get('firstname') | formControl"
    ></x-input>

    <span class="field-label" [class.required]="isRequired(commonFormGroup.get('owner').get('lastname') | formControl)">
      {{ 'last-name' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      name="owner.lastname"
      [control]="commonFormGroup.get('owner').get('lastname') | formControl"
    ></x-input>

    <span class="field-label" [class.required]="isRequired(commonFormGroup.get('owner').get('title') | formControl)">
      {{ 'title' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      name="owner.title"
      [control]="commonFormGroup.get('owner').get('title') | formControl"
    ></x-input>

    <span class="field-label" [class.required]="isRequired(commonFormGroup.get('owner').get('company') | formControl)">
      {{ 'company' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      name="owner.company"
      [control]="commonFormGroup.get('owner').get('company') | formControl"
    ></x-input>

    <span class="field-label" [class.required]="isRequired(commonFormGroup.get('owner').get('phone') | formControl)">
      {{ 'phone' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      type="tel"
      name="owner.phone"
      [control]="commonFormGroup.get('owner').get('phone') | formControl"
    ></x-input>

    <span
      class="field-label"
      [class.required]="isRequired(commonFormGroup.get('owner').get('best_reachable') | formControl)"
    >
      {{ 'best-reachable' | transloco }}:
    </span>
    <x-select
      class="col-span-2"
      [options]="BestReachable.all"
      [control]="commonFormGroup.get('owner').get('best_reachable') | formControl"
    ></x-select>

    <span
      class="field-label"
      [class.required]="isRequired(commonFormGroup.get('owner').get('address').get('country') | formControl)"
    >
      {{ 'country' | transloco }}:
    </span>
    <x-select
      class="col-span-2"
      [options]="sortedCountries"
      [control]="commonFormGroup.get('owner').get('address').get('country') | formControl"
    ></x-select>

    <ng-container
      *ngIf="(commonFormGroup.get('owner').get('address').get('country') | formControl).value === countries.Germany"
    >
      <span class="field-label">{{ 'state' | transloco }}:</span>
      <x-select
        class="col-span-2"
        [options]="states"
        [control]="commonFormGroup.get('owner').get('address').get('state') | formControl"
        [required]="false"
      ></x-select>
    </ng-container>

    <span
      class="field-label"
      [class.required]="isRequired(commonFormGroup.get('owner').get('address').get('city') | formControl)"
    >
      {{ 'city' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      name="owner.address.city"
      [control]="commonFormGroup.get('owner').get('address').get('city') | formControl"
    ></x-input>

    <span
      class="field-label"
      [class.required]="isRequired(commonFormGroup.get('owner').get('address').get('line1') | formControl)"
    >
      {{ 'address' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      name="owner.address.line1"
      [control]="commonFormGroup.get('owner').get('address').get('line1') | formControl"
    ></x-input>

    <span class="flex items-center space-x-4 col-span-1 min-w-[240px] max-h-[38px]"></span>
    <x-input
      class="col-span-2"
      name="owner.address.line2"
      [control]="commonFormGroup.get('owner').get('address').get('line2') | formControl"
    ></x-input>

    <span
      class="field-label"
      [class.required]="isRequired(commonFormGroup.get('owner').get('address').get('zip') | formControl)"
    >
      {{ 'zip' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      name="owner.address.zip"
      [control]="commonFormGroup.get('owner').get('address').get('zip') | formControl"
    ></x-input>
  </form>
</x-dialog>
