import { HttpErrorResponse } from '@angular/common/http';

export enum ApiErrorType {
  Internal = 'Internal_server_error',
  GreaterThan = 'greater_than',
  Inclusion = 'inclusion',
  Invalid = 'invalid',
  Confirmation = 'confirmation',
  Required = 'required',
  Past = 'past',
  Future = 'future',
  Associated = 'associated',
}

export interface ApiError<T = ApiErrorType> {
  details: string;
  error: T;
  source: string;
}

export interface ApiErrorWithKey {
  Data: string;
  ErrKey: string;
  ErrMsg: string;
}

export interface ApiErrorResponse<T = ApiErrorType> extends HttpErrorResponse {
  error: {
    errors: ApiError<T>[];
  };
}
