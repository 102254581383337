import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NotifierModule } from 'angular-notifier';

import { ToastService } from '@app/core/toast/service/toast.service';
import { ToastTemplateComponent } from '@app/core/toast/components/toast-template/toast-template.component';
import { ToastContainerComponent } from '@app/core/toast/components/toast-container/toast-container.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

@NgModule({
  imports: [
    CommonModule,
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle',
        },
        vertical: {
          position: 'top',
          gap: 16,
        },
      },
      behaviour: {
        autoHide: 5000,
        onClick: false,
        onMouseover: 'pauseAutoHide',
        stacking: 4,
        showDismissButton: true,
      },
    }),
    SvgIconModule,
  ],
  providers: [ToastService],
  declarations: [ToastTemplateComponent, ToastContainerComponent],
  exports: [ToastContainerComponent, ToastTemplateComponent],
})
export class ToastModule {}
