import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'x-tab-pill',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './tab-pill.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabPillComponent {
  @Input() isActive = false;
  @Input() disabled = false;
  @Output() handleClick = new EventEmitter();
}
