import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NetworkIdleService {
  private idleSubject$ = new BehaviorSubject<boolean>(false);
  idle$: Observable<boolean> = this.idleSubject$.pipe(distinctUntilChanged());

  setIdleStatus(isNetworkIdle: boolean) {
    this.idleSubject$.next(isNetworkIdle);
  }
}
