import { ColDef, GridReadyEvent, RowNode, SelectionChangedEvent } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ButtonComponent } from '@common/components/button/button.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { DataRoom, Document } from '@core/models';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { BaseGridComponent, GridModule } from '@portal/components';
import {
  FileRendererComponent,
  FileRendererComponentData,
} from '@portal/components/grid/file-renderer/file-renderer.component';
import { isPhotoTypeRegExp, isVideoExtensionsRegExp } from '@portal/components/qa-overview/qa-overview.constants';
import {
  SelectLocationRendererComponent,
  SelectLocationRendererComponentData,
} from '@portal/customer/components/asset-details/asset-tickets-container/components/add-references-to-system-dialog/select-location-renderer/select-location-renderer.component';
import { getFileExtension } from '@portal/customer/components/asset-details/data-room/file-icon/file-icon.helper';
import { CustomerRoute, CustomerRoutingService, DocumentLocation } from '@portal/customer/services';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { EMPTY, Observable } from 'rxjs';
import { catchError, take } from 'rxjs/operators';
import { AgGridModule } from '@ag-grid-community/angular';

export interface AddReferencesToSystemDialogComponentData {
  documents: Document.Short[];
  onSkipAndContinueClick?: () => Observable<unknown>;
  onAddSelected: (documentLocations: DocumentLocation[]) => Observable<unknown>;
  preselectedDocumentId?: string;
}

@UntilDestroy()
@Component({
  selector: 'x-add-references-to-system-dialog',
  standalone: true,
  imports: [
    CommonModule,
    DialogComponent,
    TranslocoModule,
    ButtonComponent,
    MatTooltipModule,
    MatMenuModule,
    AgGridModule,
    GridModule,
  ],
  templateUrl: './add-references-to-system-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddReferencesToSystemDialogComponent
  extends BaseGridComponent<Document.Short>
  implements AfterViewInit, OnDestroy
{
  selectedRows: RowNode[] = [];
  readonly dialogData = inject<AddReferencesToSystemDialogComponentData>(MAT_DIALOG_DATA);
  readonly dialogRef = inject(MatDialogRef);
  readonly cdr = inject(ChangeDetectorRef);
  readonly toast = inject(ToastService);
  readonly store = inject(Store);
  readonly routingService = inject(CustomerRoutingService);
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);

  locations: DocumentLocation[] = [];

  haveAllDocumentsTheirLocationsSelected = false;
  isLoading = false;

  private onDestroyActions: (() => void)[] = [];

  ngOnDestroy() {
    this.onDestroyActions.forEach((action) => action());
  }

  onSkipAndContinue() {
    this.executeSubmitAction(this.dialogData.onSkipAndContinueClick());
  }

  onSubmitSelected() {
    const selectedLocations = this.locations.filter(
      (loc) => this.selectedRows.findIndex((row) => row.data.id === loc.documentId) !== -1
    );
    this.executeSubmitAction(this.dialogData.onAddSelected(selectedLocations), selectedLocations);
  }

  onSelectionChange(selectionChanged: SelectionChangedEvent) {
    this.selectedRows = selectionChanged.api.getSelectedNodes();
    this.haveAllDocumentsTheirLocationsSelected = this.getHaveAllDocumentsTheirLocationsSelected();
    this.cdr.markForCheck();
  }

  onlyMediaFilesSelected(): boolean {
    return this.selectedRows.every(
      (row) => isPhotoTypeRegExp.test(row.data.name) || isVideoExtensionsRegExp.test(row.data.name)
    );
  }

  onSelectLocationClick() {
    if (!this.onlyMediaFilesSelected()) {
      this.onDataroomDirectorySelected();
    }
  }

  onPhotosDirectorySelected() {
    this.setLocations();
  }

  onDataroomDirectorySelected() {
    this.assetId$.pipe(take(1)).subscribe((assetId) => {
      const url = this.routingService.getDataRoomDirectorySelectorLink(assetId);

      const key = `${assetId}|${CustomerRoute.DataRoom}`;
      localStorage.removeItem(key);

      const height = Math.round(screen?.height * 0.6);
      const width = Math.round(screen?.width * 0.6);
      const documentWindow = window.open(
        url,
        undefined,
        `width=${width},height=${height},menubar=0,toolbar=0,status=0`
      );

      documentWindow.addEventListener(
        'load',
        () => {
          const onStorageEventCallback = () => {
            const selectedValue = localStorage.getItem(key);
            if (selectedValue) {
              this.setLocations(selectedValue);
              localStorage.removeItem(key);
              window.removeEventListener('storage', onStorageEventCallback);
            }
          };
          window.addEventListener('storage', onStorageEventCallback);
          this.onDestroyActions.push(() => {
            window.removeEventListener('storage', onStorageEventCallback);
          });
        },
        { once: true }
      );
    });
  }

  getRowNodeId(data) {
    return data?.id;
  }

  protected onGridReady(event: GridReadyEvent) {
    super.onGridReady(event);
    const row = event.api.getRowNode(this.dialogData.preselectedDocumentId);
    row?.setSelected(true);
    this.gridApi.sizeColumnsToFit();
  }

  protected getColumnDefs(): ColDef[] {
    return [
      this.getCheckboxColDef(true),
      {
        maxWidth: 50,
        resizable: false,
        headerName: this.transloco.translate('current_no'),
        valueGetter: ({ node }) => node.rowIndex + 1,
      },
      {
        headerName: this.transloco.translate(
          'tickets-system.add-references-to-system-modal.document-name-column-label'
        ),
        minWidth: 100,
        maxWidth: 350,
        flex: 1,
        resizable: false,
        comparator: (valueA, valueB) => {
          if (valueA?.label == valueB?.label) return 0;
          return valueA?.label > valueB?.label ? 1 : -1;
        },
        cellRendererFramework: FileRendererComponent,
        wrapText: true,
        valueGetter: (params): FileRendererComponentData => {
          const data = params.data as Document.Short;
          return {
            label: data.name,
            extension: getFileExtension(data.name),
          };
        },
      },
      {
        headerName: this.transloco.translate(
          'tickets-system.add-references-to-system-modal.location-action-column-label'
        ),
        flex: 1,
        resizable: false,
        sortable: false,
        cellRendererFramework: SelectLocationRendererComponent,
        valueGetter: (params): SelectLocationRendererComponentData => {
          const document = params.data as Document.Short;
          return {
            document,
            onLocationChanged: this.onLocationChanged.bind(this),
          };
        },
      },
    ];
  }

  private onLocationChanged = (docLocation: DocumentLocation, rowNode: RowNode) => {
    this.locations = [...this.locations.filter((loc) => loc.documentId !== docLocation.documentId), docLocation];
    if (!rowNode.isSelected()) {
      rowNode.setSelected(true);
    } else {
      this.haveAllDocumentsTheirLocationsSelected = this.getHaveAllDocumentsTheirLocationsSelected();
      this.cdr.markForCheck();
    }
  };

  private getHaveAllDocumentsTheirLocationsSelected() {
    return this.selectedRows.every((row) => this.locations.findIndex((loc) => loc.documentId === row.data.id) !== -1);
  }

  private setLocations(selectedValue?) {
    this.selectedRows.forEach((row) => {
      let location: DocumentLocation;
      if (selectedValue) {
        const [id, folderNodeName, permission] = selectedValue.split('|') as [
          string,
          string,
          DataRoom.PermissionOption
        ];
        location = {
          documentId: row.data.document?.id || row.data.id,
          documentName: row.data.document?.name || row.data.name,
          location: selectedValue ? 'dataroom' : 'photos',
          permission,
          dirName: folderNodeName,
          dirId: id,
        };
      } else {
        location = {
          documentId: row.data?.id,
          documentName: row.data?.name,
          location: 'photos',
        };
      }
      this.onLocationChanged(location, row);
      const rowNode = this.gridApi.getRowNode(row.id);
      rowNode.updateData({ ...row.data, location });
      this.cdr.markForCheck();
    });
  }

  private executeSubmitAction(action$: Observable<unknown>, documentLocations: DocumentLocation[] = []) {
    this.isLoading = true;
    action$
      .pipe(
        take(1),
        catchError((error) => {
          if (error.status === 403) {
            this.toast.showToast(this.transloco.translate('notification.upload-permission-error'), ToastType.ERROR);
          } else {
            this.toast.showToast(this.transloco.translate('notification.unknown-error'), ToastType.ERROR);
          }
          this.isLoading = false;
          this.cdr.markForCheck();
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.dialogRef.close(documentLocations);
      });
  }
}
