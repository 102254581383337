<div class="flex xs:justify-center xs:w-full min-h-screen bg-gray-100">
  <div class="flex flex-column w-full z-10 absolute min-h-screen">
    <div class="w-full flex justify-between items-center px-12 bg-white">
      <x-header [watchAuthTokenChanges]="false" class="w-full h-full" logoClasses="!h-[40px]"></x-header>
      <x-language-switcher class="my-6"></x-language-switcher>
    </div>

    <!--  84px is header height; 116px is footer height  -->
    <div class="flex justify-center items-center w-full h-[calc(100vh-84px-116px)] px-12">
      <ng-content></ng-content>
    </div>

    <x-footer class="mt-auto mb-10 px-12" [isExternalHorizontalView]="true"></x-footer>
  </div>
</div>
