<router-outlet style="height: 100%"></router-outlet>

<x-toast-container></x-toast-container>

<x-spinner-overlay *ngIf="modalDetailsLoading" [isLoading]="modalDetailsLoading"></x-spinner-overlay>

<ng-template #templatePortalContent>
  <div class="flex fixed w-full h-full items-center justify-center left-0 top-0" *ngIf="modalDetailsData">
    <x-dialog
      [title]="getModalTitle | call : modalDetailsData : transloco"
      class="bg-white w-[720px]"
      [closeOnly]="true"
      (handleClose)="closeOverlayModalDetails()"
    >
      <ng-container [ngSwitch]="modalDetailsData.dialogType">
        <ng-container *ngSwitchCase="'qa'">
          <ng-container
            *ngTemplateOutlet="qaDetailsDialogTemplate; context: { $implicit: modalDetailsData?.data }"
          ></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'ticket'">
          <ng-container
            *ngTemplateOutlet="ticketDetailsDialogTemplate; context: { $implicit: modalDetailsData?.data }"
          ></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'finding'">
          <ng-container
            *ngTemplateOutlet="findingDetailsDialogTemplate; context: { $implicit: modalDetailsData?.data }"
          ></ng-container>
        </ng-container>
      </ng-container>
    </x-dialog>
  </div>
</ng-template>

<ng-template #ticketDetailsDialogTemplate let-ticket>
  <x-ticket-details-dialog-fields [ticket]="ticket"></x-ticket-details-dialog-fields>
</ng-template>

<ng-template #qaDetailsDialogTemplate let-qa>
  <x-qa-details-dialog-fields [message]="qa" [isQuestion]="qa.category !== 'notes'"></x-qa-details-dialog-fields>
</ng-template>

<ng-template #findingDetailsDialogTemplate let-finding>
  <x-finding-details [finding]="finding" [disabled]="true"></x-finding-details>
</ng-template>
