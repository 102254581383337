import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Address } from '@core/models';
import { CellRendererComponent } from '../cell-renderer';

@Component({
  templateUrl: './address-cell-renderer.component.html',
  styleUrls: ['./address-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddressCellRendererComponent extends CellRendererComponent<Address> {}
