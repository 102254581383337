import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CellRendererComponent } from '@portal/components';
import { FileIconModule } from '@portal/customer/components/asset-details/data-room/file-icon/file-icon.module';
import { SpinnerModule } from '@common';

export interface FileRendererComponentData {
  label: string;
  extension: string;
}

@Component({
  selector: 'x-file-renderer',
  standalone: true,
  imports: [CommonModule, FileIconModule, SpinnerModule],
  templateUrl: './file-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileRendererComponent extends CellRendererComponent<FileRendererComponentData> {}
