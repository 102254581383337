<ng-container [ngSwitch]="risk" *ngIf="!hasTooltip; else templateWithTooltip">
  <ng-container *ngSwitchCase="Risk.VeryLow">{{ 'very-low' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Risk.Low">{{ 'low' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Risk.Medium">{{ 'medium' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Risk.AboveAverage">{{ 'above-average' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Risk.High">{{ 'high' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Risk.Critical">{{ 'critical' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="RiskScore.VeryLow">{{ 'very-low' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="RiskScore.Low">{{ 'low' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="RiskScore.Medium">{{ 'medium' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="RiskScore.AboveAverage">{{ 'above-average' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="RiskScore.High">{{ 'high' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="RiskScore.Critical">{{ 'critical' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ risk }}</ng-container>
</ng-container>

<ng-template #templateWithTooltip>
  <ng-container [ngSwitch]="risk">
    <span *ngSwitchCase="Risk.VeryLow">{{ 'very-low' | transloco }}</span>
    <span *ngSwitchCase="Risk.Low">{{ 'low' | transloco }}</span>
    <span *ngSwitchCase="Risk.Medium">{{ 'medium' | transloco }}</span>
    <span *ngSwitchCase="Risk.AboveAverage">{{ 'above-average' | transloco }}</span>
    <span *ngSwitchCase="Risk.High">{{ 'high' | transloco }}</span>
    <span *ngSwitchCase="Risk.Critical">{{ 'critical' | transloco }}</span>

    <span *ngSwitchCase="RiskScore.VeryLow">{{ 'very-low' | transloco }}</span>
    <span *ngSwitchCase="RiskScore.Low">{{ 'low' | transloco }}</span>
    <span *ngSwitchCase="RiskScore.Medium">{{ 'medium' | transloco }}</span>
    <span *ngSwitchCase="RiskScore.AboveAverage">{{ 'above-average' | transloco }}</span>
    <span *ngSwitchCase="RiskScore.High">{{ 'high' | transloco }}</span>
    <span *ngSwitchCase="RiskScore.Critical">{{ 'critical' | transloco }}</span>

    <span *ngSwitchDefault>{{ risk }}</span>
  </ng-container>
</ng-template>
