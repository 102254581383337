import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PortalProjectsEffects } from './portal-projects.effects';
import { PortalProjectsReducer } from './portal-projects.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([PortalProjectsEffects]),
    StoreModule.forFeature(PortalProjectsReducer.name, PortalProjectsReducer.portalProjectsReducer),
  ],
})
export class PortalProjectsStateModule {}
