<ng-container *transloco="let t; read: 'answer-message-dialog'">
  <x-dialog
    title="{{ t('title', { value: message.current_no }) }}"
    submitBtnText="{{ t('submit') }}"
    cancelBtnText="{{ t('cancel') }}"
    [submitDisabled]="isSubmitDisabled$ | async"
    [closeDisabled]="isSaveInProgress$ | async"
    (handleClose)="dialogRef.close()"
    (handleSubmit)="onSubmit()"
  >
    <form novalidate (submit)="onSubmit()">
      <x-qa-details-dialog-fields [message]="message"></x-qa-details-dialog-fields>
      <x-write-response-form
        [messageId]="message.id"
        [messageCategory]="'qa'"
        [allowReferenceSelect]="true"
        [multipleReferences]="true"
        [initialReferences]="getResponseReferences(message)"
        [initialResponseText]="writeResponseModelOutput.response"
        [documents]="message.documents"
        (modelChange)="onChanges($event)"
        [isSaving]="(isSaveInProgress$ | async) || (isSaveDraftInProgress$ | async)"
      ></x-write-response-form>
    </form>
    <ng-container additionalActionsRight>
      <x-button
        color="primary"
        [disabled]="isSubmitDisabled$ | async"
        [class.progress]="isSaveDraftInProgress$ | async"
        (handleClick)="onSaveDraft()"
      >
        {{ t('submit-draft') }}
      </x-button>
    </ng-container>
  </x-dialog>
</ng-container>
