import { Injectable } from '@angular/core';
import { SimpleBreadCrumbItem } from '@common/breadcrumb/breadcrumb.component';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  extraBreadcrumbItems$: BehaviorSubject<SimpleBreadCrumbItem[]> = new BehaviorSubject<SimpleBreadCrumbItem[]>([]);
}
