import { Injectable } from '@angular/core';
import { AppRoute } from '@app/app-routing.service';
import { Document, Finding, FindingClass } from '@core/models';
import { RoutingService } from '@portal/services/routing.service';
import * as _ from 'lodash';
import { RefType } from '@portal/customer/components/attach-reference-button/reference.model';

export enum CustomerRoute {
  Assets = 'assets',
  Factsheet = 'factsheet',
  Schedule = 'schedule',
  Users = 'users',
  Reports = 'reports',
  ReportsSimple = 'reports-simple',
  Statistic = 'statistic',
  ReportTemplate = 'report-template',
  ReportTemplateChapter = 'chapter',
  Photos = 'photos',
  Tickets = 'tickets',
  Photo = 'photo',
  Location = 'location',
  Documents = 'documents',
  Disclaimer = 'disclaimer',
  Findings = 'findings',
  QA = 'qa',
  DataRoom = 'data-room',
  DataRoomVersioning = 'versioning',
  Settings = 'settings',
  User = 'user',
}

export const PAGE_ANCHOR = `#page=`;

@Injectable({
  providedIn: 'root',
})
export class CustomerRoutingService extends RoutingService {
  constructor() {
    super();
  }

  readonly pathMap = (assetId: string, statistic?: string): Map<string, string> =>
    new Map<string, string>([
      [CustomerRoute.Assets, this.getAssetsLink()],
      [CustomerRoute.Factsheet, this.getFactsheetLink(assetId)],
      [CustomerRoute.Users, this.getUsersLink(assetId)],
      [CustomerRoute.Reports, this.getReportsLink(assetId)],
      [CustomerRoute.Photos, this.getMediaLink(assetId)],
      [CustomerRoute.Findings, this.getFindingsLink(assetId)],
      [CustomerRoute.QA, this.getQALink(assetId)],
      [CustomerRoute.DataRoom, this.getDataRoomLink(assetId)],
      [CustomerRoute.Settings, this.getAssetSettingsLink(assetId)],
      [CustomerRoute.Statistic, this.getStatisticsLink(assetId, statistic)],
      [CustomerRoute.User, this.getAssetsLink()],
    ]);

  getLinkForRoutes(path: string, assetId: string, statistic?: string): string {
    return this.pathMap(assetId, statistic).get(path);
  }

  getHomeLink(): string {
    return `/${AppRoute.Customer}`;
  }

  getAssetsLink(): string {
    return `${this.getHomeLink()}/${CustomerRoute.Assets}`;
  }

  getAssetDetailsLink(assetId: string): string {
    return `${this.getAssetsLink()}/${assetId}`;
  }

  getFactsheetLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Factsheet}`;
  }

  getScheduleLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Schedule}`;
  }

  getUsersLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Users}`;
  }

  getReportsLink(assetId: string, simpleView = false): string {
    return `${this.getAssetDetailsLink(assetId)}/${simpleView ? CustomerRoute.ReportsSimple : CustomerRoute.Reports}`;
  }

  getStatisticsLink(assetId: string | null, statistic?: string): string {
    return `${this.getAssetDetailsLink(assetId ?? '')}/${CustomerRoute.Statistic}${statistic ? '/' + statistic : ''}`;
  }

  getMediaLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Photos}`;
  }

  getPhotoInOverviewLink(assetId: string, photoId: string): string {
    return `${this.getMediaLink(assetId)}/photo/${photoId}`;
  }

  /**
   * @param assetId
   * @param search should have properties with names from PhotoFilter
   */
  getMediaSearchLink(assetId: string, search: Partial<{ tags: string }>): string | null {
    const query = _(search)
      .omitBy(_.isNil)
      .toPairs()
      .map((pair) => pair.join('='))
      .join('&');
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Photos}?${query}`;
  }

  getMediaTypeLink(assetId: string, mediaType: string): string {
    return `${this.getMediaLink(assetId)}/${mediaType}`;
  }

  getMediaTypeDocumentLink(assetId: string, mediaType: string, documentId: string): string {
    return `${this.getMediaTypeLink(assetId, mediaType)}/${documentId}`;
  }

  getLocationLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Location}`;
  }

  getAssetDocumentViewerLink(assetId: string, documentId: string, simpleView = false): string {
    return `${this.getReportsLink(assetId, simpleView)}/${documentId}`;
  }

  getAssetReportTemplateViewerLink(assetId: string, reportId: string, simpleView = false): string {
    return `${this.getReportsLink(assetId, simpleView)}/${CustomerRoute.ReportTemplate}/${reportId}`;
  }

  getAssetReportTemplateChapterViewerLink(
    assetId: string,
    reportId: string,
    chapterId: string,
    simpleView = false
  ): string {
    return `${this.getReportsLink(assetId, simpleView)}/${CustomerRoute.ReportTemplate}/${reportId}/${
      CustomerRoute.ReportTemplateChapter
    }/${chapterId}`;
  }

  getFindingsLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Findings}`;
  }

  getFindingDetailsLink(assetId: string, findingId: string, findingClass?: FindingClass): string {
    return `${this.getFindingsLink(assetId)}/${findingId}?class=${findingClass || 'tdd'}`;
  }

  getDocumentViewerLink(doc: Document.ListItem | Document): string {
    return this.getMediaTypeDocumentLink(doc.asset_id, this.getMediaType(doc.types), doc.id);
  }

  getQALink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.QA}`;
  }

  getTicketsLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Tickets}`;
  }

  getTicketDetailsLink(assetId: string, ticketId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Tickets}/${ticketId}`;
  }

  getQADetailsLink(assetId: string, qaId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.QA}/${qaId}`;
  }

  getFindingLink(finding: Finding.Short): string {
    return this.getFindingsLink(finding.asset_id);
  }

  getDocumentFileLink(
    doc: Document.Short,
    page?: number,
    trackedActivityScope?: RefType,
    anchor?: string,
    messageId?: string
  ): string {
    const trackedActivityScopeParam = trackedActivityScope ? `?trackedActivityScope=${trackedActivityScope}` : '';
    const messageIdParam = trackedActivityScope && messageId ? `&messageId=${messageId}` : '';
    const pageNumberAnchor = _.isNumber(page) ? `${PAGE_ANCHOR}${page}` : '';
    const anchorParam = _.isEmpty(pageNumberAnchor) ? (anchor ? `#${anchor}` : '') : '';
    // NOTE: Anchors need to be last here - after query params - external pdfViewer library uses them to navigate to proper pages.
    return `${this.getAssetDetailsLink(doc.asset_id)}/${CustomerRoute.Documents}/${
      doc.id
    }${trackedActivityScopeParam}${pageNumberAnchor}${messageIdParam}${anchorParam}`;
  }

  getDataRoomLink(assetId: string, simpleView = false): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.DataRoom}${simpleView ? '/simpleView' : ''}`;
  }

  getDataRoomVersioningNodeLink(assetId: string, versioningId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.DataRoom}/${
      CustomerRoute.DataRoomVersioning
    }/${versioningId}`;
  }

  getDataRoomDirectorySelectorLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.DataRoom}/directory-selection`;
  }

  getReportChapterLink(assetId: string, reportId: string, chapterId: string): string {
    const reportLink = this.getAssetReportTemplateViewerLink(assetId, reportId);
    return `${reportLink}/chapter/${chapterId}`;
  }

  getChapterPreviewLink(assetId: string, reportId: string, chapterId: string): string {
    const reportLink = this.getAssetReportTemplateViewerLink(assetId, reportId);
    return `.${reportLink}/${chapterId}/preview?p`;
  }

  getDataRoomLinkForTheReport(assetId: string, reportId: string): string {
    return `${this.getAssetReportTemplateViewerLink(assetId, reportId)}/${CustomerRoute.DataRoom}/view`;
  }

  getDataRoomSourceLinkForTheReport(assetId: string, reportId: string): string {
    return `${this.getAssetReportTemplateViewerLink(assetId, reportId)}/${CustomerRoute.DataRoom}/source`;
  }

  getAssetSettingsLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Settings}`;
  }

  getPhotoSelectionWindowLink(assetId: string): string {
    return `${this.getAssetDetailsLink(assetId)}/${CustomerRoute.Photos}`;
  }

  getDataRoomSourceWindowLink(assetId: string): string {
    return `${this.getDataRoomLink(assetId)}`;
  }

  getDataroomSourceLink(source: string, assetId: string, sourcePage?: number): string {
    const path = this.getDataRoomLink(assetId as string, true);
    const page = sourcePage ? `?page=${sourcePage}` : ``;
    return `${path}/${source}${page}`;
  }
}
