import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Countries } from '@core/models';

@Component({
  selector: 'x-country-label',
  templateUrl: './country-label.component.html',
  styleUrls: ['./country-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountryLabelComponent {
  @Input()
  country: Countries | string;

  readonly Countries = Countries;
}
