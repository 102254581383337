import { getAllEnumValues } from './enum';

export enum DepthSurvey {
  DesktopAnalysis = 'desktop_analysis',
  PreDD = 'pre_dd',
  FullDD = 'full_dd',
  ConfirmatoryDD = 'confirmatory_dd',
}

export namespace DepthSurvey {
  export const all = getAllEnumValues<DepthSurvey>(DepthSurvey);
}
