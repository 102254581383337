import { getAllEnumValues } from './enum';

export enum AssetType {
  Residential = 'residential',
  Office = 'office',
  Retail = 'retail',
  Hotel = 'hotel',
  Logistics = 'logistics',
  ShoppingCenter = 'shopping_center',
  NursingHome = 'nursing_home',
  ParkingGarage = 'parking_garage',
}

export namespace AssetType {
  export const all = getAllEnumValues<AssetType>(AssetType);
}
