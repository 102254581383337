import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsConfig, MatTabsModule, MAT_TABS_CONFIG } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

const modules = [
  MatSelectModule,
  MatCheckboxModule,
  MatRadioModule,
  MatInputModule,
  MatIconModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatChipsModule,
  MatAutocompleteModule,
  MatTabsModule,
  MatMenuModule,
  MatDialogModule,
  MatStepperModule,
  MatExpansionModule,
  MatDividerModule,
  MatListModule,
  OverlayModule,
  PortalModule,
  MatTreeModule,
  MatProgressBarModule,
  MatButtonToggleModule,
  MatTooltipModule,
  ObserversModule,
  MatCardModule,
];

const matTabsConfig: MatTabsConfig = {
  animationDuration: '0',
  disablePagination: true,
};

const rippleOptions: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
};

/**
 * Import only required modules instead of this module
 *
 * @deprecated
 */
@NgModule({
  imports: modules,
  exports: modules,
  providers: [
    { provide: MAT_TABS_CONFIG, useValue: matTabsConfig },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: rippleOptions },
  ],
})
export class MaterialModule {}
