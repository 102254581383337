import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AssetType } from '@core/models';

@Component({
  selector: 'x-asset-type-label',
  templateUrl: './asset-type-label.component.html',
  styleUrls: ['./asset-type-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetTypeLabelComponent {
  @Input() type?: AssetType;

  readonly AssetType = AssetType;
}
