import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StoredTemplatesService {
  private qaFieldsTemplate?: TemplateRef<HTMLElement>;
  private ticketFieldsTemplate?: TemplateRef<HTMLElement>;

  setQaFieldsTemplate(template: TemplateRef<HTMLElement>): void {
    this.qaFieldsTemplate = template;
  }

  getQaFieldsTemplate(): TemplateRef<HTMLElement> | undefined {
    return this.qaFieldsTemplate;
  }

  setTicketFieldsTemplate(template: TemplateRef<HTMLElement>): void {
    this.ticketFieldsTemplate = template;
  }

  getTicketFieldsTemplate(): TemplateRef<HTMLElement> | undefined {
    return this.ticketFieldsTemplate;
  }
}
