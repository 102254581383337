import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm, ValidationErrors } from '@angular/forms';
import { MatMenuTrigger } from '@angular/material/menu';
import { ISelectOption } from '@common/components/select/select.model';
import {
  api,
  Document,
  Finding,
  FindingField,
  FindingMonitoring,
  Link,
  Norm,
  Risk,
  VALUE_TO_REPLACE_ZERO,
} from '@core/models';
import { LinksService, NormService, TradeService } from '@core/services';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CustomerRoute, CustomerRoutingService, FindingsService, PAGE_ANCHOR } from '@portal/customer/services';
import { ResolvedDataSelectors } from '@portal/customer/state';
import { FindingHelperService } from '@portal/services/finding-helper.service';
import { LinkHelperService } from '@portal/services/link-helper.service';
import { SimpleChanges } from '@root/src/custom-typings/angular';
import { isFinite, kebabCase, uniq } from 'lodash';
import { BehaviorSubject, EMPTY, forkJoin, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { FindingEditorType, getFindingFieldEditorType } from './finding-field-editor-type-helper';
import { isPhotoTypeRegExp, isVideoExtensionsRegExp } from '@portal/components/qa-overview/qa-overview.constants';
import { NodeLinkSelectionData } from '@portal/customer/components/asset-details/data-room';

interface Reference {
  readonly href: string;
  readonly name: string;
  readonly page: number;
  readonly types: Document.Short['types'];
  readonly doc: Document.Short;
}

interface InitialFormData {
  references: Link[];
}

const readonlyDateFields: FindingField[] = ['created_at', 'deleted_at', 'published_at'];
const monitoring_fields: (keyof FindingMonitoring)[] = [
  'on_site_visit_at',
  'location',
  'level',
  'task',
  'responsible_user_id',
  'status',
];

@UntilDestroy()
@Component({
  selector: 'x-finding-details',
  templateUrl: './finding-details.component.html',
  styleUrls: ['./finding-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [FindingHelperService, FindingsService],
})
export class FindingDetailsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() assetId?: string;
  @Input() finding?: Finding;
  @Input() format?: Finding.Format;
  @Input() classForNewFinding: Finding['class'];
  @Input() createNewFindingMode?: boolean;
  @Input() error?: api.ErrorResponse<Finding>;
  @Input() disabled?: boolean;
  @Input() canAddDocument: boolean;
  @Input() canAddPhoto: boolean;
  @Input() isSaving$: BehaviorSubject<boolean>;
  @Input() canEdit: boolean;
  @Input() initialData: InitialFormData;

  @Output() findingChange = new EventEmitter<Finding>();
  @Output() requiredFieldMissing = new EventEmitter<boolean>();
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  readonly normId$ = new BehaviorSubject<string>(null);
  readonly norm$ = this.normId$.pipe(
    switchMap((normId) => (!normId ? of(null as Norm) : this.normService.getNorm(normId))),
    catchError(() => {
      this.normId$.next(null);
      return of(null as Norm);
    }),
    tap((norm) => (norm ? this.menuTrigger?.openMenu() : this.menuTrigger?.closeMenu()))
  );

  readonly requiredFields: FindingField[] = [
    'finding_type',
    'trade',
    'trade_id',
    'finding_processed',
    'finding',
    'task',
    'guidance',
    'risk_evaluation',
    'status',
  ];

  readonly disabledFields: FindingField[] = ['published_at', 'risk_value_result'];

  readonly types = [1, 2, 3, 4, 5];

  /**
   * t(risk-label.low)
   * t(risk-label.medium)
   * t(risk-label.high)
   */
  readonly riskLevels = [Risk.Low, Risk.Medium, Risk.High].map((risk: Risk.Low | Risk.Medium | Risk.High) => ({
    value: risk,
    label: this.transloco.translate(`risk-label.${kebabCase(risk)}`),
  }));

  /**
   * t(finding)
   * t(documentation)
   */
  readonly findingTypes = ['finding', 'documentation'].map((type: 'finding' | 'documentation') => ({
    value: type,
    label: this.transloco.translate(type),
  }));

  _model: Finding = {} as Finding;
  set model(findingModel: Finding) {
    this._model = findingModel;
    const valuesKeys = Object.keys(findingModel);
    const isSomeRequiredFieldMissingValue = this.requiredFields.some(
      (requiredField) => valuesKeys.includes(requiredField) && !findingModel[requiredField]
    );
    this.requiredFieldMissing.emit(isSomeRequiredFieldMissingValue);
  }

  get model() {
    return this._model;
  }

  addedLinks: Link[] = [];
  deletedLinks: Link[] = [];
  linkCounter$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  isMonitoringClass = false;

  get isNotPublished(): boolean {
    return !this.finding?.published_at || true;
  }

  fields: FindingField[] = [];
  tradeSelectOptions$: Observable<ISelectOption<number>[]>;
  readonly statusSelectOptions: ISelectOption<FindingMonitoring['status']>[] = ['open', 'discussion', 'closed'].map(
    (status: FindingMonitoring['status']) => ({
      value: status,
      translationKey: `finding-monitoring-status.${status}`,
      tooltip: status,
    })
  );
  private onDestroyActions: (() => void)[] = [];

  constructor(
    private readonly transloco: TranslocoService,
    private readonly customerRouting: CustomerRoutingService,
    private readonly linkHelperService: LinkHelperService,
    private readonly linksService: LinksService,
    readonly findingsService: FindingsService,
    private readonly toast: ToastService,
    readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
    private readonly normService: NormService,
    private readonly store: Store,
    private readonly tradeService: TradeService
  ) {
    this.canAddDocument = false;
    this.canAddPhoto = false;

    this.linkCounter$.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value === this.addedLinks.length + this.deletedLinks.length) {
        this.isSaving$?.next(false);
      }
    });

    this.tradeSelectOptions$ = this.tradeService.trades$.pipe(
      map((trades) =>
        trades?.map((trade) => ({
          value: trade.id,
          label: `${trade.register} ${trade.description}`,
          tooltip: trade.tooltip,
        }))
      ),
      untilDestroyed(this)
    );
  }

  ngOnInit() {
    this.model = this.createNewFindingMode ? this.getNewFinding() : (this.finding as Finding);
    this.isMonitoringClass = (this.finding?.class || this.classForNewFinding) === 'monitoring';
    this.store
      .select(ResolvedDataSelectors.getProject)
      .pipe(
        map((project) => (this.isMonitoringClass ? project?.monitoring_finding_format : project?.tdd_finding_format)),
        take(1),
        untilDestroyed(this)
      )
      .subscribe((format) => {
        this.format = format;
        this.fields = this.getFindingFields();
        this.cdr.markForCheck();
      });
    if (this.initialData) {
      this.setInitialData();
    }
  }

  ngOnChanges({
    format,
    classForNewFinding,
    createNewFindingMode,
    finding,
    disabled,
  }: SimpleChanges<FindingDetailsComponent>): void {
    if (finding && !finding.firstChange) {
      this.model = {
        ...finding.currentValue,
      };
    }

    if (disabled) {
      this.cdr.markForCheck();
    }
  }

  ngOnDestroy() {
    this.onDestroyActions.forEach((action) => action());
  }

  readonly getIsMediaReference = (ref: Reference) =>
    !!ref.types?.includes('photo') ||
    !!ref.types?.includes('video') ||
    isPhotoTypeRegExp.test(ref.doc.name) ||
    isVideoExtensionsRegExp.test(ref.doc.name);

  getTradeLabel(tradeId: number) {
    return this.tradeService.getTradeLabelObs(tradeId);
  }

  /**
   * Finding fields and their visibility should only be defined with this method
   */
  getFindingFields(): FindingField[] {
    const fieldsToExclude = ['current_no', ...readonlyDateFields];
    const referenceFields = ['references', 'add_document', 'add_photo'];

    const fields: FindingField[] = Finding.getFieldsByFormat(this.format || Finding.defaultFormat)
      .filter((field) => !fieldsToExclude.includes(field))
      .map((field) => {
        if (field === 'finding_processed') {
          return 'finding';
        }
        if (field === 'photos' || field === 'documents') {
          return 'references';
        }

        return field;
      })
      .filter((field) => {
        if (this.createNewFindingMode) {
          return field !== 'risk_value_result';
        }

        return true;
      })
      .filter((field) => {
        const monitoringFieldName = field as keyof FindingMonitoring;

        if (this.isMonitoringClass) {
          if (this.requiredFields.includes(monitoringFieldName) || referenceFields.includes(monitoringFieldName)) {
            return true;
          }
          if (monitoring_fields.includes(monitoringFieldName)) {
            return true;
          }
        } else {
          if (monitoring_fields.includes(monitoringFieldName)) {
            return false;
          }
        }

        return true;
      });

    const addedFields: FindingField[] = [];
    if (this.canAddDocument || this.createNewFindingMode) {
      addedFields.push('add_document');
    }
    if (this.canAddPhoto || this.createNewFindingMode) {
      addedFields.push('add_photo');
    }
    const index = fields.findIndex((field) => field === 'references');
    if (index !== -1) {
      fields.splice(index + 1, 0, ...addedFields);
    }

    if (!this.createNewFindingMode) {
      fields.push('published_at');
    }

    return uniq(fields);
  }

  getNewFinding(): Finding {
    return <Finding>{
      finding_type: 'finding',
      class: this.classForNewFinding,
    };
  }

  getFieldValue(field: FindingField, finding: Finding, shouldZeroBeReplaced = false): string | number {
    const fieldValue = finding?.[field] || '';
    return +fieldValue === 0 && shouldZeroBeReplaced ? VALUE_TO_REPLACE_ZERO : fieldValue;
  }

  getDateFieldValue(finding: Finding, field: keyof Finding): Date {
    let value = finding?.[field] as Date | string;
    if (typeof value === 'string') {
      value = new Date(value);
    }

    return value;
  }

  getFieldArrayValue(field: keyof Finding, finding: Finding): string[] {
    return finding?.[field] as string[];
  }

  getFieldAvailableOptions(
    field: keyof Finding,
    assetId: string | undefined,
    findingsService: FindingsService
  ): Observable<string[]> {
    let availableOptions$ = of([]);

    if (field) {
      if (assetId) {
        const optionsFetchFunctionsMap: Partial<
          Record<keyof Finding, ({ asset_id }: { asset_id: string }) => Observable<string[]>>
        > = {
          buildings: findingsService.getBuildings.bind(findingsService),
          rental_areas: findingsService.getRentalAreas.bind(findingsService),
        };

        const fetchOptionsFn = optionsFetchFunctionsMap?.[field];

        if (fetchOptionsFn) {
          availableOptions$ = fetchOptionsFn({ asset_id: assetId }).pipe(
            catchError((error) => {
              console.error(`Couldn't get finding's options (${field}) list:`, error);
              this.toast.showToast(this.transloco.translate('password-successfully-changed'), ToastType.ERROR);
              return throwError(error);
            })
          );
        }
      } else {
        console.error(`Selected finding has no asset id, so options (${field}) list couldn't be fetched`);
      }
    }

    return availableOptions$;
  }

  canEditField(fieldName: FindingField): boolean {
    return fieldName !== 'risk_value_result';
  }

  getIsDateFieldDisabled(fieldName: FindingField): boolean {
    return readonlyDateFields.includes(fieldName);
  }

  existRestriction(fieldName: FindingField): boolean {
    return fieldName === 'failure_probability' || fieldName === 'follow_up_costs' || fieldName === 'failure_effect';
  }

  getFieldType(field: FindingField): FindingEditorType {
    return getFindingFieldEditorType(field);
  }

  getErrors(fieldName: string, form: NgForm): ValidationErrors {
    let controlName: FindingField = fieldName as FindingField;
    switch (fieldName as FindingField) {
      case 'trade':
        controlName = 'trade_id';
        break;
    }

    const errors = form && form.controls?.[controlName] && form.controls?.[controlName]?.errors;
    return errors && errors.serverError;
  }

  setInitialData() {
    if (this.initialData.references?.length) {
      this.initialData.references.forEach((link) => {
        this.prepareFindingModelLinks(link);
      });
    }
  }

  onSelectValueChanged(value: unknown, field: FindingField, model: Finding): void {
    this.findingChange.emit(model);
  }

  onFailureValueChanged(value: unknown, field: FindingField, model: Finding): void {
    const key = field as keyof Finding;
    model[key as string] = parseFloat(value as string);
    this.findingChange.emit(model);
  }

  onInputValueChanged(value: unknown, field: FindingField, model: Finding): void {
    const key = field as keyof Finding;
    model[key as string] = value;
    this.findingChange.emit(model);
  }

  onNumericInputValueChanged(event: Event, field: FindingField, model: Finding): void {
    const key = field as keyof Finding;
    const value = (event.target as HTMLInputElement).value;
    if (value) {
      model[key as string] = parseFloat(value);
    } else {
      delete model[key as string];
    }
    this.findingChange.emit(model);
  }

  onDateValueChanged(value: Date | undefined, field: FindingField, model: Finding): void {
    const key = field as keyof Finding;
    this.model[key as string] = value ? this.getCorrectDate(value) : value;
    this.findingChange.emit(model);
    this.cdr.detectChanges();
  }

  getCorrectDate(date: Date): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
  }

  onTagsChanged(tags: string[], field: FindingField, model: Finding): void {
    model[field as string] = tags;
    this.findingChange.emit(model);
  }

  getReferences = (model: Finding): Reference[] => {
    const references: Link[] = model.references || [];
    return references.map((reference) => {
      const linkDocument = Link.toShortDocument(reference, model.asset_id || this.assetId);

      if (reference.target_type === 'photo') {
        return this.getReference(
          this.customerRouting.getDocumentFileLink(linkDocument, undefined, 'finding'),
          linkDocument
        );
      }
      return this.getReference(
        this.customerRouting.getDocumentFileLink(linkDocument, undefined, 'finding', linkDocument.anchor),
        linkDocument
      );
    });
  };

  onOpenDocument(ref: Reference, event: MouseEvent): void {
    const withCtrlBtnPressed = !event?.ctrlKey && !event?.metaKey;
    if (withCtrlBtnPressed) {
      event?.preventDefault();
    }
    this.linkHelperService.open(ref.href, ref.types?.includes('photo') ? 'photo' : undefined);
  }

  onOpenPhoto(document: Document) {
    window.openMediaDocument(document, {
      scope: 'finding',
      finding_id: this.finding?.id,
    });
  }

  onClickAddDocument(): void {
    this.onAddDocumentLink(this.model);
  }

  onClickAddPhoto(): void {
    this.onAddPhotoLink(this.model);
  }

  onDeleteLink(event: MouseEvent, { doc }: Reference): void {
    event.stopPropagation();

    const finding = this.finding as Finding;
    const link = <Link>{
      src_id: finding.id,
      src_type: 'finding',
      target_id: doc.id,
      target_type: isVideoExtensionsRegExp.test(doc.name)
        ? 'video'
        : isPhotoTypeRegExp.test(doc.name)
        ? 'photo'
        : 'document',
    };

    this.model = {
      ...this.model,
      references: this.model.references.filter(
        (ref) => !(ref.target_id === doc.id && (doc.anchor === ref.target_anchor || doc.anchor === ref.anchor))
      ),
    };
    this.findingChange.emit(this.model);

    const aLink = this.addedLinks.find(
      ({ src_id, target_id }) => src_id === link.src_id && target_id === link.target_id
    );
    if (aLink) {
      this.addedLinks = this.addedLinks.filter((l) => l !== aLink);
    } else if (
      !this.deletedLinks.find(({ src_id, target_id }) => src_id === link.src_id && target_id === link.target_id)
    ) {
      this.deletedLinks.push(link);
    }
  }

  trackByReference(index: number, ref: Reference): string {
    return ref.doc.id;
  }

  getIsFieldRequired(requiredFields: FindingField[], field: FindingField): boolean {
    return requiredFields.includes(field);
  }

  getProcessedFindingValue(): string {
    return this.getFieldValue('finding_processed', this.model) as string;
  }

  removeLocalStorageKey(key) {
    localStorage.removeItem(key);
    if (localStorage.getItem(`${key}|name`)) {
      localStorage.removeItem(`${key}|name`);
    }
  }

  onAddPhotoLink(finding: Finding): void {
    this.openWindow(CustomerRoute.Photos, 'PhotoSelection', this.assetId || finding.asset_id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (selectedValue) => {
          const [mediaId, mediaName, type] = (selectedValue || '').split('|');

          this.removeLocalStorageKey(`${this.assetId}|${CustomerRoute.Photos}`);
          const link: Link = <Link>{
            src_type: 'finding',
            src_id: finding.id as string,
            target_type: type,
            target_id: mediaId,
            target_description: mediaName || mediaId,
          };

          if (!this.isLinkAlreadyAdded(link)) {
            this.prepareFindingModelLinks(link);
          }
        },
      });
  }

  onAddDocumentLink(finding: Finding): void {
    this.openWindow(CustomerRoute.DataRoom, 'DataRoomFileSelection', this.assetId || finding.asset_id)
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (selectedValue) => {
          const selectedSources = JSON.parse(selectedValue) as NodeLinkSelectionData[];
          this.removeLocalStorageKey(`${this.assetId}|${CustomerRoute.DataRoom}`);
          selectedSources.forEach((source) => {
            let source_page = source.page;
            source_page = isFinite(source_page) ? source_page : undefined;

            const documentId = source.documentId;
            const name = source.name;

            const link: Link = <Link>{
              src_type: 'finding',
              src_id: finding.id as string,
              target_type: 'document',
              target_id: documentId,
              target_anchor: source_page ? `Page=${source_page}` : undefined,
              target_description: name,
            };

            if (!this.isLinkAlreadyAdded(link)) {
              this.prepareFindingModelLinks(link);
            }
          });
        },
      });
  }

  addLink(link: Link, findingId?: string): Observable<Link> {
    return this.linksService
      .createAssetLink({
        asset_id: this.assetId || this.finding.asset_id,
        link: {
          ...link,
          src_id: findingId || link.src_id,
        },
      })
      .pipe(take(1));
  }

  deleteLink(link: Link, findingId?: string): Observable<Link> {
    return this.linksService
      .deleteAssetLink({
        asset_id: this.assetId || this.finding.asset_id,
        link: {
          ...link,
          src_id: findingId || link.src_id,
        },
      })
      .pipe(take(1));
  }

  editReferencesSubmit(): Observable<unknown> {
    return this.handleSaveReferences(
      this.addedLinks,
      this.deletedLinks,
      this.addLink.bind(this),
      this.deleteLink.bind(this),
      this.finding.id
    );
  }

  submitReferencesForNewFinding(findingId: string): Observable<unknown> {
    return this.handleSaveReferences(
      this.addedLinks,
      this.deletedLinks,
      this.addLink.bind(this),
      this.deleteLink.bind(this),
      findingId
    );
  }

  getIsFieldDisabled(disabledFields: FindingField[], field: FindingField): boolean {
    return disabledFields.includes(field);
  }

  trackFn(index: number): string | number {
    return index;
  }

  resetAttachments() {
    this.addedLinks = [];
    this.deletedLinks = [];
    this.linkCounter$.next(0);
  }

  onFindingProcessedClick(event: Event): void {
    const target = event.target as HTMLAnchorElement;

    if (target?.tagName?.toLowerCase() === 'a') {
      const normId = (target.href.match(/\/norms\/(.+)/i) || [])[1];
      if (normId) {
        event.preventDefault();
        event.stopPropagation();
        this.normId$.next(normId);
      }
    }
  }

  getEmptyValue(): string {
    return this.disabled ? '-' : '';
  }

  private showSaveToast() {
    this.toast.showToast(this.transloco.translate('finding-details.save-success'), ToastType.SUCCESS);
    this.linkCounter$.next(this.linkCounter$.value + this.addedLinks.length + this.deletedLinks.length);
  }

  private showSaveErrorToast() {
    this.toast.showToast(this.transloco.translate('finding-details.save-error'), ToastType.ERROR);
    this.linkCounter$.next(this.linkCounter$.value + this.addedLinks.length + this.deletedLinks.length);
  }

  private handleSaveReferences(
    addedLinks: unknown[],
    deletedLinks: unknown[],
    addLinkFunc: Function,
    deleteLinkFunc: Function,
    findingId?: string
  ): Observable<void> {
    if (!addedLinks.length && !deletedLinks.length) {
      this.isSaving$?.next(false);
      return of(undefined);
    }

    const addLinkObservables = addedLinks.map((link) => addLinkFunc(link, findingId));
    const deleteLinkObservables = deletedLinks.map((link) => deleteLinkFunc(link, findingId));

    return forkJoin([...addLinkObservables, ...deleteLinkObservables]).pipe(
      take(1),
      map(() => {
        this.isSaving$?.next(false);
        this.showSaveToast();
      }),
      catchError(() => {
        this.isSaving$?.next(false);
        this.showSaveErrorToast();
        return EMPTY;
      })
    );
  }

  private getReference(href: string, doc: Document.Short): Reference {
    const page = Number.parseInt(href.toLowerCase().split(PAGE_ANCHOR).pop() || '', 10);
    return {
      href,
      get name() {
        return doc.name;
      },
      get types() {
        return doc.types;
      },
      doc,
      page,
    };
  }

  private isLinkAlreadyAdded(link: Link): boolean {
    return !!this.model.references?.find(
      (ref) => ref.target_id === link.target_id && link.target_anchor === ref.target_anchor
    );
  }

  private prepareFindingModelLinks(link: Link): void {
    this.updateModelByAddingLinkToReferences(link);
    this.findingChange.emit(this.model);
    this.cdr.detectChanges();

    if (this.deletedLinks.find((_link) => _link.src_id === link.src_id && _link.target_id === link.target_id)) {
      this.deletedLinks = this.deletedLinks.filter(
        (_link) => _link.src_id !== link.src_id || _link.target_id !== link.target_id
      );
    } else if (!this.addedLinks.find((_link) => _link.src_id === link.src_id && _link.target_id === link.target_id)) {
      this.addedLinks.push(link);
    }
  }

  private updateModelByAddingLinkToReferences(link: Link): void {
    this.model = {
      ...this.model,
      references: this.model.references ? [...this.model.references, link] : [link],
    };
  }

  private openWindow(route: CustomerRoute, name: string, assetId: string): Observable<string | null> {
    const windowClosed$ = new Subject<string | null>();

    const key = `${assetId}|${route}`; // undefined instead of report-id
    localStorage.removeItem(key);

    const url =
      route === CustomerRoute.DataRoom
        ? `${this.customerRouting.getDataRoomSourceWindowLink(assetId)}/source`
        : `${this.customerRouting.getPhotoSelectionWindowLink(assetId)}/source`;
    const height = Math.round(screen?.height * 0.6);
    const width = Math.round(screen?.width * 0.6);
    const documentWindow = window.open(url, name, `width=${width},height=${height},menubar=0,toolbar=0,status=0`);

    documentWindow.addEventListener(
      'load',
      () => {
        const onStorageEventCallback = () => {
          let selectedValue = localStorage.getItem(key);
          const selectedValueName = localStorage.getItem(`${key}|name`);
          if (selectedValueName) {
            // for data with '|' separated data only
            selectedValue += `|${selectedValueName}`;
          }
          if (selectedValue) {
            windowClosed$.next(selectedValue);
            window.removeEventListener('storage', onStorageEventCallback);
          }
        };
        window.addEventListener('storage', onStorageEventCallback);
        this.onDestroyActions.push(() => {
          window.removeEventListener('storage', onStorageEventCallback);
        });
      },
      { once: true }
    );

    return windowClosed$;
  }
}
