<x-input
  [label]="label"
  class="large"
  type="text"
  autocomplete="off"
  rightSideIcon="calendar"
  [class.can-reset]="canReset"
  [disabled]="disabled"
  [required]="required"
  [value]="dateValue | localeDate : includeTime"
  (rightSideIconClick)="datePicker.open()"
  (inputClick)="datePicker.open()"
  (handleKeydown)="onKeyPress($event)"
></x-input>

<input
  class="opacity-0 h-[0px] p-[0px]"
  [matDatepicker]="datePicker"
  [value]="dateValue"
  [min]="minDate"
  (dateChange)="onDateValueChanged($event.value)"
  [matDatepickerFilter]="disallowPastDates ? futureDateFilter : undefined"
/>

<mat-datepicker #datePicker></mat-datepicker>

<mat-icon *ngIf="canReset" (click)="onResetValue()">close</mat-icon>
