import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiError, Document } from '@core/models';
import { UploadOutput } from '@portal/components/upload';
import { ISelectOption } from '@common/components/select/select.model';
import { TranslocoService } from '@ngneat/transloco';

export interface UploadPreviewDialogData {
  dataTransfer?: DataTransfer;
  files?: File[];
  status?: { file?: File; errors?: ApiError[] }[];
  deleteEnabled?: boolean;
  selectTypes?: boolean;
  getDocumentType?: (file: File) => Document.Type;
  allowedTypes?: Document.Type[];
  allowedExtensions?: string[];
  selectTags?: boolean;
  predefinedTags?: string[];
}

export interface UploadPreviewOutput extends UploadOutput {
  tags?: string[];
}

@Component({
  selector: 'x-upload-preview',
  templateUrl: './upload-preview.component.html',
  styleUrls: ['./upload-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadPreviewComponent {
  tags: string[] = [];
  allowedTypes: Document.Type[] = [];
  allowedTypesOptions: ISelectOption<Document.Type>[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: UploadPreviewDialogData,
    private readonly dialogRef: MatDialogRef<UploadPreviewComponent, UploadPreviewOutput>,
    private readonly transloco: TranslocoService
  ) {
    if (data?.allowedTypes?.[0]) {
      this.allowedTypes = [data?.allowedTypes?.[0]];
    }

    this.allowedTypesOptions = data?.allowedTypes?.map((type) => ({
      value: type,
      label: this.transloco.translate(type),
    }));
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  onUpload(output: UploadPreviewOutput): void {
    this.dialogRef.close({
      ...output,
      tags: this.tags,
    });
  }

  onTagsChanged(tags: string[]): void {
    this.tags = [...(tags || [])];
  }

  onTypeChanged(type: Document.Type): void {
    this.allowedTypes = [type];
  }
}
