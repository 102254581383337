import { ColDef } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { isNotNil } from '@app/utils';
import { AlertDialogComponent, AlertDialogData, AlertType } from '@common';
import { BreadcrumbService } from '@common/breadcrumb/breadcrumb.service';
import { Project, ProjectListItem, Tracker } from '@core/models';
import { SSEService } from '@core/services';
import { FormatDateService } from '@core/services/format-date.service';
import { UsersSelectors } from '@core/state';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CreateTrackerDialogComponent, CreateTrackerDialogData } from '@portal/admin/components/create-tracker-dialog';
import { TrackersService } from '@portal/admin/services';
import { AdminRouterSelectors } from '@portal/admin/store';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, first, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { BaseGridComponent, DatePickerCellRendererComponent, GridHelperService } from '../grid';
import { ProjectsSelectors } from '../portal-projects';
import { PortalTrackerHeaderCellComponent } from './portal-tracker-header-cell/portal-tracker-header-cell.component';

@UntilDestroy()
@Component({
  selector: 'x-portal-tracker',
  templateUrl: './portal-tracker.component.html',
  styleUrls: ['./portal-tracker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalTrackerComponent extends BaseGridComponent<Tracker> implements OnInit, OnDestroy {
  @Input() assetId?: string;

  @ViewChild('trackerDeleteFailedTemplate') trackerDeleteFailedTemplate: TemplateRef<unknown>;
  @ViewChild('trackerUpdateFailedTemplate') trackerUpdateFailedTemplate: TemplateRef<unknown>;

  readonly isAllowedToCreateTracker$ = this.store.select(UsersSelectors.getIsAdmin); // TODO Specify user permissions
  readonly projectId$ = this.store.select(AdminRouterSelectors.getProjectId);
  readonly rowData$ = new BehaviorSubject<Tracker[]>(null);
  readonly project$ = combineLatest([this.projectId$, this.store.select(ProjectsSelectors.getProjects)]).pipe(
    map(([projectId, projectsDict]) => projectsDict?.[projectId])
  );

  assetId$ = this.store.select(AdminRouterSelectors.getProjectAssetId);

  readonly relatedSseEvents$ = this.sseService.sseEvent$.pipe(
    filter(isNotNil),
    filter((event) => event.type === 'tracker_milestone'),
    startWith([null]),
    untilDestroyed(this)
  );

  constructor(
    protected readonly componentRef: ElementRef<HTMLElement>,
    protected readonly formatDateService: FormatDateService,
    private readonly store: Store,
    private readonly trackersService: TrackersService,
    private readonly dialog: MatDialog,
    readonly gridHelper: GridHelperService,
    readonly transloco: TranslocoService,
    private readonly sseService: SSEService,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    super(componentRef, formatDateService, gridHelper, transloco);
  }

  ngOnInit(): void {
    if (this.assetId) {
      this.assetId$ = of(this.assetId);
    }
    this.fetchTrackers();
    this.store
      .select(UsersSelectors.getIsAdmin)
      .pipe(
        untilDestroyed(this),
        // TODO Specify user permissions
        // map(userRole => _.some([User.AdminRole.Root, User.AdminRole.Manager, User.CustomerRole.ProjectLead], role => role === userRole)),
        tap((isAbleToPublish) => {
          this.columnDefs = this.getColumnDefs(isAbleToPublish);
          this.settingsColumns = this.getSettingsColumns();

          setTimeout(() => {
            this.resizeGrid();
          }, 500);
        })
      )
      .subscribe();
    this.relatedSseEvents$.subscribe(() => {
      this.fetchTrackers();
    });
    super.ngOnInit();
  }

  onAddTracker(project: Project | ProjectListItem, asset_id: string): void {
    this.dialog
      .open<CreateTrackerDialogComponent, CreateTrackerDialogData>(CreateTrackerDialogComponent, {
        data: { project_id: project.id, asset_id },
      })
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: (result) => {
          if (result) {
            this.fetchTrackers();
          }
        },
      });
  }

  onRowClicked(data: Tracker): void {}

  ngOnDestroy() {
    this.breadcrumbService.extraBreadcrumbItems$.next([]);
  }

  protected getColDef(field: keyof Tracker, colDef: Partial<ColDef> = {}): ColDef {
    return {
      ...super.getColDef(field, colDef),
      tooltipField: null,
      headerComponentFramework: PortalTrackerHeaderCellComponent,
    };
  }

  protected removeTracker({ id, is_done }: Tracker): void {
    if (is_done) {
      return;
    }

    this.assetId$
      .pipe(
        untilDestroyed(this),
        first(),
        withLatestFrom(this.projectId$),
        switchMap(([assetId, projectId]) => this.trackersService.delete(projectId, assetId, id))
      )
      .subscribe({
        next: () => this.fetchTrackers(),
        error: () => this.showError(this.trackerDeleteFailedTemplate),
      });
  }

  protected getColumnDefs(isAbleToPublish = true): ColDef[] {
    const columns = [
      this.getColDef('description'),
      this.getColDef('planned_date', {
        cellClass: ({ data: { is_done } }) => (is_done === true ? '' : 'clickable-cell'),
        valueSetter: ({ newValue, data }) => this.savePlannedDate(newValue, data),
        cellRendererFramework: DatePickerCellRendererComponent,
      }),
      this.getColDef('is_done', {
        minWidth: 70,
        maxWidth: 100,
        cellClass: ({ value }) => `${value === true ? '' : 'clickable-cell'} icon-cell`,
        cellRenderer: ({ value }) => `<span class="icon-font icon-font-${value === true ? 'check' : 'cancel'}"></span>`,
        onCellClicked: ({ data }) => this.markTrackerAsDone(data),
      }),
      this.getColDef('invoice_rate', { hide: true }),
      this.getColDef('invoice_relevant', { hide: true }),
      this.getColDef('numeration', {
        hide: true,
        sort: 'asc',
      }),
      this.getColDef('id', {
        minWidth: 40,
        width: 40,
        sortable: false,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        cellClass: ({ data: { is_done } }) => (is_done === true ? 'icon-cell disabled' : 'clickable-cell icon-cell'),
        cellRenderer: () => `<span class="icon-font icon-font-alert-fail"></span>`,
        onCellClicked: ({ data }) => this.removeTracker(data),
        headerComponentParams: {
          displayName: '',
        },
      }),
    ];

    return _.filter(columns, ({ field }) => {
      if (field === 'id' || field === 'is_done') {
        return isAbleToPublish;
      } else {
        return true;
      }
    });
  }

  private fetchTrackers() {
    this.showLoadingOverlay();
    return combineLatest([this.projectId$, this.assetId$])
      .pipe(
        untilDestroyed(this),
        first(),
        switchMap(([projectId, assetId]) => this.trackersService.fetch(projectId, assetId)),
        tap((trackers) => {
          this.hideLoadingOverlay();
          this.rowData$.next(trackers);
        })
      )
      .subscribe();
  }

  private showError(template: TemplateRef<unknown>): void {
    this.dialog.open(AlertDialogComponent, {
      data: <AlertDialogData>{
        type: AlertType.Error,
        template: () => template,
      },
    });
  }

  private savePlannedDate(date, tracker: Tracker): boolean {
    if (tracker.is_done) {
      return false;
    }

    this.assetId$
      .pipe(
        untilDestroyed(this),
        first(),
        withLatestFrom(this.projectId$),
        switchMap(([assetId, projectId]) =>
          this.trackersService.update(projectId, assetId, {
            ...tracker,
            planned_date: date,
          })
        )
      )
      .subscribe({
        next: () => this.fetchTrackers(),
        error: () => this.showError(this.trackerUpdateFailedTemplate),
      });

    return true;
  }

  private markTrackerAsDone(tracker: Tracker): void {
    if (tracker.is_done) {
      return;
    }

    combineLatest([this.projectId$, this.assetId$])
      .pipe(
        untilDestroyed(this),
        first(),
        switchMap(([projectId, assetId]) =>
          this.trackersService.update(projectId, assetId, {
            ...tracker,
            is_done: true,
          })
        )
      )
      .subscribe({
        next: () => this.fetchTrackers(),
        error: () => this.showError(this.trackerUpdateFailedTemplate),
      });
  }
}
