import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { isNotNil } from '@app/utils';
import { DEBOUNCE_TIME } from '@app/utils/rxjs.utils';
import { ISelectOption } from '@common/components/select/select.model';
import { ToggleOptionData } from '@common/components/toggle/toggle.component';
import { Asset, AssetOverview, ProjectProductType, SearchInItem } from '@core/models';
import { TimeRange } from '@core/models/time-range';
import { FormatDateService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AssetViewType } from '@portal/components';
import { CustomerAssetsService } from '@portal/customer/services';
import { AssetsOverviewSearchService } from '@portal/services/assets-overview-search.service';
import { DateHelperService } from '@portal/services/date-helper.service';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { catchError, debounceTime, filter, switchMap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'x-assets-overview-search',
  templateUrl: './assets-overview-search.component.html',
  styleUrls: ['./assets-overview-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetsOverviewSearchComponent implements OnChanges, OnInit {
  @Input() viewTypes: ToggleOptionData<AssetViewType>[] = [];
  @Input() availableAssets?: AssetOverview[];
  @Input() isDisabled = false;
  @Output() viewChange: EventEmitter<string> = new EventEmitter<string>();

  customDateShow = false;
  endProgress = 100;
  from_date?: string;
  isSelectedProgressType = false;
  searchTerm = '';
  selectedRange?: TimeRange;
  selectedSearchType?: SearchInItem;
  startProgress = 0;
  to_date?: string;
  selectedViewType: AssetViewType = 'grid';

  readonly typeItems: ISelectOption<ProjectProductType>[] = [
    { value: 'tdd', translationKey: 'project-product-types.tdd' },
    { value: 'monitoring', translationKey: 'project-product-types.monitoring' },
    { value: 'data_room_professional', translationKey: 'project-product-types.vdr-professional' },
    { value: 'data_room_premium', translationKey: 'project-product-types.vdr-premium' },
    { value: 'data_room_free_trial', translationKey: 'project-product-types.vdr-free-trial' },
  ];

  readonly statuses: ISelectOption<Asset.Status>[] = [
    { value: 'active', translationKey: 'asset-statuses.active' },
    { value: 'under_construction', translationKey: 'asset-statuses.under_construction' },
    { value: 'project_managers_only', translationKey: 'asset-statuses.project_managers_only' },
    { value: 'blocked', translationKey: 'asset-statuses.blocked' },
  ];

  readonly filters = this.fb.group({
    createdAtFormControl: [null],
    typeFormControl: [null],
    statusFormControl: [null],
  });

  private filters$ = new BehaviorSubject<Asset.Filter | undefined>(undefined);
  private availableAssets$: BehaviorSubject<AssetOverview[]> = new BehaviorSubject<AssetOverview[]>([]);

  constructor(
    readonly dateHelperService: DateHelperService,
    private readonly assetsOverviewSearchService: AssetsOverviewSearchService,
    private readonly customerAssetsService: CustomerAssetsService,
    private readonly formatDateService: FormatDateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly fb: FormBuilder
  ) {}

  ngOnChanges({ availableAssets }: SimpleChanges): void {
    if (availableAssets && availableAssets.currentValue) {
      this.availableAssets$.next(availableAssets.currentValue);
    }
  }

  ngOnInit(): void {
    combineLatest([this.filters$, this.availableAssets$])
      .pipe(
        filter(([, assets]) => isNotNil(assets)),
        debounceTime(DEBOUNCE_TIME),
        switchMap(([filters, assets]) =>
          (assets && !filters) || (filters && Object.values(filters).every((filterValue) => !filterValue))
            ? of(this.availableAssets)
            : this.customerAssetsService.fetchAllCustomerAssets(filters).pipe(
                catchError(() => {
                  this.onCancelFilter();
                  this.cdr.detectChanges();
                  return of([]);
                })
              )
        ),
        untilDestroyed(this)
      )
      .subscribe((assets) => {
        this.assetsOverviewSearchService.setSearchFilter(assets);
      });

    this.filters.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.onApplyFilter();
    });

    this.filters.controls.createdAtFormControl.valueChanges.pipe(untilDestroyed(this)).subscribe((range) => {
      this.onApplyRange(range);
    });
    this.onApplyFilter();
  }

  getCorrectDate(date: Date): string {
    return this.formatDateService.format(date);
  }

  onViewTypeChange(event): void {
    this.selectedViewType = event;
    this.viewChange.emit(event);
  }

  onApplyFilter(): void {
    const params: Asset.Filter = {
      search_term: this.searchTerm,
    };

    if (this.filters.value.createdAtFormControl) {
      params.time_frame = this.filters.value.createdAtFormControl;
    }

    if (this.filters.value.typeFormControl) {
      params.type = this.filters.value.typeFormControl;
    }

    if (this.filters.value.statusFormControl) {
      params.status = this.filters.value.statusFormControl;
    }

    if (this.customDateShow) {
      if (this.from_date) {
        params.date_from = this.from_date;
      }

      if (this.to_date) {
        params.date_to = this.to_date;
      }
    }

    this.filters$.next(params);
  }

  onApplyRange(range: TimeRange): void {
    this.selectedRange = this.selectedRange === range ? null : range;

    if (this.selectedRange === 'custom') {
      this.onSelectCustomDate();
    } else {
      this.customDateShow = false;
      this.to_date = null;
      this.from_date = null;
      this.onApplyFilter();
    }
  }

  onCancelFilter(): void {
    this.clearFilter();
    this.filters$.next(undefined);
  }

  onEndDateChange(date: Date): void {
    this.to_date = date ? this.getCorrectDate(date) : '';
    this.onApplyFilter();
  }

  onSearchInputChange(searchText: string): void {
    this.searchTerm = searchText;
    this.onApplyFilter();
  }

  onSelectCustomDate() {
    this.customDateShow = !this.customDateShow;
  }

  onStartDateChange(date: Date): void {
    this.from_date = date ? this.getCorrectDate(date) : '';
    this.onApplyFilter();
  }

  getActiveFiltersCount(): number {
    return Object.values(this.filters.value).reduce((acc, val) => acc + (val ? 1 : 0), 0);
  }

  private clearFilter(): void {
    this.searchTerm = '';
    this.selectedRange = null;
    this.selectedSearchType = null;
    this.isSelectedProgressType = false;
    this.customDateShow = false;
    this.startProgress = 0;
    this.endProgress = 100;
    this.from_date = null;
    this.to_date = null;
  }
}
