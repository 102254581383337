<x-dialog
  (handleClose)="onClose()"
  (handleSubmit)="onSubmit()"
  [submitBtnText]="'forward' | transloco"
  [title]="'forward-question' | transloco"
>
  <x-select
    [label]="'select-a-group-to-forward-a-question' | transloco"
    [(value)]="selectedGroup"
    [options]="groupOptions"
  ></x-select>
</x-dialog>
