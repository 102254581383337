import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { CallPipeModule, IconComponentModule } from '@common';
import { ButtonComponent } from '@common/components/button/button.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { FilterGroupComponent } from '@common/components/filter-group/filter-group.component';
import { FiltersDropdownComponent } from '@common/components/filters-dropdown/filters-dropdown.component';
import { InputComponent } from '@common/components/input/input.component';
import { SearchInputComponent } from '@common/components/search-input/search-input.component';
import { SelectComponent } from '@common/components/select/select.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { AutofocusDirectiveModule } from '@common/directives';
import { TranslocoModule } from '@ngneat/transloco';
import { TagsInputComponent } from '@portal/components/tags-input/tags-input.component';
import { TextareaComponent } from '@portal/components/textarea/textarea.component';
import { AttachReferenceButtonComponent } from '@portal/customer/components/attach-reference-button/attach-reference-button.component';
import { CustomerGridModule } from '@portal/customer/components/customer-grid/customer-grid.module';
import { QALabelModule } from 'src/app/common/components/labels/qa-label';
import { GridModule } from '../grid/grid.module';
import { QaAnswerDialogModule } from './qa-answer-dialog';
import { QaAskDialogModule } from './qa-ask-dialog';
import { QAForwardDialogComponent } from './qa-forward-dialog/qa-forward-dialog.component';
import { QaHistoryDialogModule } from './qa-history-dialog';
import { QAOverviewComponent } from './qa-overview.component';
import { SpinnerOverlayComponent } from '@common/spinner-overlay/spinner-overlay.component';
import { QaDetailsDialogFieldsComponent } from '@portal/components/qa-overview/qa-details-dialog-fields/qa-details-dialog-fields.component';
import { MessageRejectDialogComponent } from '@portal/customer/components/asset-details/asset-tickets-container/components/ticket-reject-dialog/message-reject-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    MatOptionModule,
    MatSelectModule,
    GridModule,
    CustomerGridModule,
    IconComponentModule,
    TagsInputComponent,
    IconComponentModule,
    MatCheckboxModule,
    MatTooltipModule,
    QALabelModule,
    QaAskDialogModule,
    TranslocoModule,
    QaAnswerDialogModule,
    QaHistoryDialogModule,
    ButtonComponent,
    SvgIconModule,
    SelectComponent,
    FiltersDropdownComponent,
    FilterGroupComponent,
    DialogComponent,
    TextareaComponent,
    InputComponent,
    AutofocusDirectiveModule,
    MatDatepickerModule,
    MatInputModule,
    SearchInputComponent,
    AttachReferenceButtonComponent,
    CallPipeModule,
    MatMenuModule,
    SpinnerOverlayComponent,
    QaDetailsDialogFieldsComponent,
    MessageRejectDialogComponent,
  ],
  declarations: [QAOverviewComponent, QAForwardDialogComponent],
  exports: [QAOverviewComponent],
})
export class QAOverviewModule {}
