import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { LanguageSwitcherModule, UserInfoDialogModule } from '../components';
import { LoginDialogModule } from '../components/login-dialog';
import { CallPipeModule } from '../pipes';
import { FooterComponent } from './footer';
import { LegacyHeaderComponent } from './header';
import { PageComponent } from './page.component';
import { SidePanelButtonComponent, SidePanelComponent } from './side-panel';

const components = [
  PageComponent,
  LegacyHeaderComponent,
  FooterComponent,
  SidePanelComponent,
  SidePanelButtonComponent,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatBadgeModule,
    MatMenuModule,
    CallPipeModule,
    LoginDialogModule,
    UserInfoDialogModule,
    LanguageSwitcherModule,
    TranslocoModule,
  ],
  declarations: components,
  exports: components,
})
export class PageModule {}
