import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';

import { PortalAdminsComponent } from './portal-admin/portal-admin.component';
import { PortalUsersComponent } from './portal-user/portal-users.component';

@Component({
  selector: 'x-portal-users-container',
  templateUrl: './portal-users-container.component.html',
  styleUrls: ['./portal-users-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalUsersContainerComponent {
  @ViewChild('usersGrid') usersGrid: PortalUsersComponent;
  @ViewChild('adminsGrid') adminsGrid: PortalAdminsComponent;

  onSelectedTabChanged(index: number): void {
    setTimeout(() => {
      if (index === 0) {
        this.usersGrid.onWindowResize();
      } else {
        this.adminsGrid.onWindowResize();
      }
    });
  }
}
