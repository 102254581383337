import { inject, Injectable } from '@angular/core';
import { ResolvedDataSelectors } from '@portal/customer/state';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class IndexPointsService {
  private readonly store = inject(Store);
  private indexPointsEnabled: boolean;

  constructor() {
    this.store.select(ResolvedDataSelectors.getIndexPointsEnabled).subscribe((indexPointsEnabled) => {
      this.indexPointsEnabled = indexPointsEnabled;
    });
  }

  getFormattedPath(pathSegments: string[], pathsIndexPoint?: string): string {
    const indexPoints: string[] = `${pathsIndexPoint || ''}`?.split('.') || [];
    if (!pathSegments) {
      return;
    }
    return pathSegments
      .map((segment, index) => {
        if (pathsIndexPoint !== undefined && this.indexPointsEnabled && index > 0) {
          return `${indexPoints.slice(0, index).join('.')} ${segment}`;
        } else {
          return segment;
        }
      })
      .join('/');
  }

  getLabel(label: string, indexPoint: string) {
    return this.indexPointsEnabled ? `${indexPoint} ${label}` : label;
  }
}
