<div class="host-container" [ngClass]="role">
  <div class="menu-container side-panel-container" [class.with-padding]="isAdmin$ | async">
    <ng-content></ng-content>
  </div>

  <div #backContainer class="back-container container" [class.hidden]="(isAdmin$ | async) !== true && isCustomerRole">
    <div
      *ngIf="backContainer.children.length"
      class="panels-divider"
      [class.hidden]="(isAdmin$ | async) !== true"
    ></div>
    <ng-content select="[side-panel-back]"></ng-content>
  </div>
</div>
