<ng-container *ngFor="let photo of value">
  <div #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu" (click)="selectImage(photo)">
    {{ photo.name }}
  </div>

  <mat-menu #menu="matMenu" [hasBackdrop]="true" (close)="selectImage(null)" class="finding-photo-preview">
    <ng-container *ngIf="imageData$ | async as imageData; else spinner">
      <a
        class="preview"
        [href]="imageLink$ | async"
        target="_blank"
        [style.backgroundImage]="imageData | imageUrl | safeStyle"
      >
        <mat-icon (click)="menuTrigger.closeMenu(); $event.stopPropagation(); $event.preventDefault()">close</mat-icon>
      </a>
    </ng-container>
  </mat-menu>
</ng-container>

<ng-template #spinner>
  <div class="relative">
    <span class="icon-progress"></span>
  </div>
</ng-template>
