import { Pipe, PipeTransform } from '@angular/core';
import { api } from '@core/models';
import { Observable, of } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';

type ReturnType<T> = Partial<{ loading: boolean; value: T; error: unknown }>;

@Pipe({
  name: 'withLoading',
})
export class WithLoadingPipe implements PipeTransform {
  transform<T>(observable: Observable<T>): Observable<ReturnType<T>> {
    return observable.pipe(
      map((value: T) => ({
        loading: typeof value === 'string' ? value === api.start : false,
        value,
      })),
      startWith({ loading: true }),
      catchError((error) => of({ loading: false, error }))
    );
  }
}
