import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SafePipeModule } from '@common';
import { HyphenateDirective } from '@common/directives/hyphenate/hyphenate.directive';
import { CellRendererComponent } from '../cell-renderer';

export interface WrappedTextCellRendererComponentOptions {
  blurred: boolean;
  blurredPlaceholder: string;
}

@Component({
  selector: 'x-wrapped-text-cell-renderer',
  standalone: true,
  imports: [CommonModule, HyphenateDirective, SafePipeModule],
  templateUrl: './wrapped-text-cell-renderer.component.html',
  styleUrls: ['./wrapped-text-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WrappedTextCellRendererComponent
  extends CellRendererComponent<string, WrappedTextCellRendererComponentOptions>
  implements ICellRendererAngularComp {}
