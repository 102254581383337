import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataRoom, Permission } from '@core/models';

@Component({
  selector: 'x-permission-label',
  templateUrl: './permission-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PermissionLabelComponent {
  @Input() fieldName: keyof Permission | DataRoom.PermissionOption;
}
