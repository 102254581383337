import { Component, Input } from '@angular/core';
import { Document } from '@core/models';

@Component({
  selector: 'x-breadcrumbs-document-info',
  templateUrl: './breadcrumbs-document-info.component.html',
  styleUrls: ['./breadcrumbs-document-info.component.scss'],
})
export class BreadcrumbsDocumentInfoComponent {
  @Input() document?: Document.ListItem;
}
