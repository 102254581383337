import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AreaType } from '../../../../core/models';

@Component({
  selector: 'x-area-type-label',
  templateUrl: './area-type-label.component.html',
  styleUrls: ['./area-type-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaTypeLabelComponent {
  @Input()
  type: AreaType;

  readonly AreaType = AreaType;
}
