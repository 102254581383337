<div class="relative flex flex-row text-left">
  <div cdkOverlayOrigin #trigger="cdkOverlayOrigin">
    <ng-content select="[xDropdownTrigger]"></ng-content>
  </div>
  <ng-template
    (backdropClick)="onClickOutside()"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="triggerButton?.isOpen"
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayMinWidth]="overlayWidth"
    cdkConnectedOverlay
    cdkConnectedOverlayBackdropClass="opacity-0"
  >
    <div
      @AnimationTrigger
      class="z-50 left-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none max-w-4xl"
      [ngClass]="{
        'w-56': width === 'sm',
        'w-72': width === 'md',
        'w-96': width === 'lg',
        'w-[500px]': width === 'xl',
        'w-full': !!overlayWidth,
        'bottom-0': origin === 'BOTTOM_LEFT' || origin === 'BOTTOM_RIGHT',
        'top-0': origin === 'TOP_LEFT' || origin === 'TOP_RIGHT',
        'left-0': origin === 'BOTTOM_LEFT' || origin === 'TOP_LEFT',
        'right-0': origin === 'BOTTOM_RIGHT' || origin === 'TOP_RIGHT',
        'origin-top-right': origin === 'TOP_RIGHT',
        'origin-top-left': origin === 'TOP_LEFT',
        'origin-bottom-right': origin === 'BOTTOM_RIGHT',
        'origin-bottom-left': origin === 'BOTTOM_LEFT'
      }"
    >
      <div role="none">
        <ng-content></ng-content>
      </div>
    </div>
  </ng-template>
</div>
