import { CommonModule } from '@angular/common';

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonColor, ButtonComponent } from '@common/components/button/button.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { SpinnerModule } from '@common/components/spinner/spinner.module';

export interface DialogData {
  title: string;
}

@Component({
  selector: 'x-dialog',
  standalone: true,
  imports: [CommonModule, ButtonComponent, TranslocoModule, SvgIconModule, SpinnerModule],
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DialogComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() hideCloseIcon = false;
  @Input() submitDisabled = false;
  @Input() submitLoading = false;
  @Input() closeDisabled = false;
  @Input() submitBtnText? = '';
  @Input() submitBtnIcon?: AvailableIcons;
  @Input() cancelBtnText? = '';
  @Input() cancelBtnIcon?: AvailableIcons;
  @Input() withFooter? = true;
  @Input() closeOnly? = false;
  @Input() classes?: string;
  @Input() isHeaderShowed = true;
  @Input() submitColor: ButtonColor = 'primary';
  @Input() titleClasses = '';

  @Output() handleSubmit = new EventEmitter<void>();
  @Output() handleClose = new EventEmitter<void>();
}
