<div class="pt-2">
  <div class="flex space-x-3 text-gray-600 items-center cursor-pointer select-none" (click)="toggle()">
    <x-svg-icon
      svgImageName="chevron-arrow-down"
      svgClass="w-4 h-4"
      class="transition duration-300 ease-in-out"
      [ngClass]="{ '-rotate-90': !expanded }"
    ></x-svg-icon>
    <span class="font-medium text">{{ title }}</span>
  </div>
  <div *ngIf="expanded" class="pl-[28px] pt-1 pb-2">
    <ng-content></ng-content>
  </div>
</div>
