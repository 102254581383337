import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Timeframe } from '../../../../core/models';

@Component({
  selector: 'x-timeframe-label',
  templateUrl: './timeframe-label.component.html',
  styleUrls: ['./timeframe-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeframeLabelComponent {
  @Input()
  timeframe: Timeframe;

  readonly Timeframe = Timeframe;
}
