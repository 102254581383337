import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';

@Component({
  selector: 'x-info-panel',
  standalone: true,
  imports: [CommonModule, SvgIconModule],
  templateUrl: './info-panel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoPanelComponent {
  @Input() title: string;
  @Input() variant: 'primary' | 'gray' | 'warning' = 'primary';
  @Input() icon: AvailableIcons = 'exclamation-triangle';
}
