<label [for]="id" class="block text-sm font-medium text-gray-700" *ngIf="label && labelPosition === 'above'">
  <ng-container *ngTemplateOutlet="labelWithAdditions"></ng-container>
</label>
<div class="flex items-center w-full">
  <label
    *ngIf="label && labelPosition === 'inline'"
    class="control-inline-label text-left text-black text-sm font-medium mr-2.5"
  >
    <ng-container *ngTemplateOutlet="labelWithAdditions"></ng-container>
  </label>
  <x-dropdown [isSelect]="true" #dropdownComponent origin="TOP_RIGHT" width="lg" class="flex-1 w-full">
    <button
      xDropdownTrigger
      (toggleOverlay)="onDropdownToggle($event)"
      (keydown)="handleKeydown($event)"
      class="w-full"
      [disabled]="control?.disabled"
      [isTriggerDisabled]="control?.disabled || readonly"
      [ngClass]="{ 'flex items-center': label && labelPosition === 'inline' }"
    >
      <div
        #buttonElement
        class="w-full flex rounded-md border border-gray-300 text-sm sm:text-sm truncate min-h-[20px] py-[8px] px-3"
        [ngClass]="{
          '!border-danger-400 !text-danger-400': control?.hasError('required') && control?.touched,
          'text-gray-500 cursor-not-allowed bg-gray-100': control?.disabled,
          'bg-transparent [&:not(:hover)]:border-transparent hover:border-gray-300 focus:border-gray-300 hover:bg-white focus:bg-white':
            transparent,
          'border-gray-300': !transparent,
          'cursor-pointer bg-white': !control?.disabled,
          'text-gray-700 font-normal': thin,
          'border-primary-500 shadow-primary-1':
            (dropdownComponent && dropdownComponent?.triggerButton?.isOpen) || focused
        }"
      >
        <div class="flex flex-1 justify-between items-center w-full relative">
          <span
            class="inline ellipsis w-[calc(100%-40px)] overflow-ellipsis line-clamp-1 min-h-[20px] absolute text-left"
          >
            {{ placeholder && !value ? placeholder : getSelectedValueLabel() }}
          </span>
          <x-svg-icon [svgImageName]="'chevron-arrow-down'" svgClass="w-4 h-4" class="ml-auto pl-1"></x-svg-icon>
        </div>
      </div>
    </button>
    <div class="options max-h-[350px] overflow-y-auto py-2 shadow-sm">
      <div
        *ngIf="withEmptyOption"
        class="select-options-dropdown"
        (click)="handleOptionSelection(emptyOptionValue)"
        [tabIndex]="0"
        [attr.data-value]="emptyOptionValue"
      >
        <span
          [ngClass]="{
                'selected': control?.value === emptyOptionValue,
              }"
        >
          {{ emptyOptionLabel }}
        </span>
      </div>
      <div
        *ngFor="let option of $any(options); let index"
        (click)="handleOptionSelection(getOptionValue(option))"
        class="select-options-dropdown"
        [tabIndex]="withEmptyOption ? index + 1 : index"
        [attr.data-value]="getOptionValue(option)"
        (keydown)="handleKeydown($event)"
      >
        <span
          [ngClass]="{
                'selected': control?.value === getOptionValue(option),
              }"
        >
          {{ getParsedLabel(option) }}
        </span>
      </div>
      <ng-container *ngIf="getOptions(true); let pinnedOptions">
        <div class="options-group" *ngIf="pinnedOptions?.length">
          <ng-container *ngTemplateOutlet="optionsTemplate; context: { $implicit: pinnedOptions }"></ng-container>
        </div>
      </ng-container>
      <ng-template #optionsTemplate let-options>
        <div
          *ngFor="let option of $any(options); trackBy: trackByValue; let i = index"
          class="select-options-dropdown"
          [ngClass]="{
                'selected': control?.value === getOptionValue(option),
              }"
          (click)="handleOptionSelection(getOptionValue(option))"
          [title]="option?.tooltip || ''"
          [tabIndex]="withEmptyOption ? i + 1 : i"
          [attr.data-value]="getOptionValue(option)"
          (keydown)="handleKeydown($event)"
        >
          <span>
            {{ getParsedLabel(option) }}
          </span>
        </div>
      </ng-template>
    </div>
  </x-dropdown>
</div>

<ng-template #labelWithAdditions>
  <span class="inline-flex items-center">
    {{ label }}
    <span *ngIf="required" class="text-danger-500 inline-block">*</span>
    <span *ngIf="optional" class="text-gray-500 text-sm">&nbsp;({{ 'optional' | transloco }})</span>
    <x-svg-icon
      *ngIf="infoTooltip"
      class="inline-flex text-gray-600"
      svgClass="w-5 h-5 ml-1"
      svgImageName="info"
      matTooltipPosition="above"
      [matTooltip]="infoTooltip"
    ></x-svg-icon>
  </span>
</ng-template>
