import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
  private readonly timezone: string;

  constructor() {
    this.timezone = new Date().getTimezoneOffset().toString();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headers = request.headers.set('x-project-tz', this.timezone);

    return next.handle(request.clone({ headers }));
  }
}
