import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DocumentsPreviewComponent } from '@portal/components/documents-preview/documents-preview.component';
import { CallPipeModule, DocumentDetailsPipeModule, DocumentPhotoPipeModule, SpinnerModule } from '@common';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { FileIconModule } from '@portal/customer/components/asset-details/data-room/file-icon/file-icon.module';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { TranslocoModule } from '@ngneat/transloco';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AngularSvgIconModule } from 'angular-svg-icon';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    CallPipeModule,
    DocumentDetailsPipeModule,
    DocumentPhotoPipeModule,
    SpinnerModule,
    BadgeComponent,
    FileIconModule,
    SvgIconModule,
    TranslocoModule,
    MatTooltipModule,
    AngularSvgIconModule,
  ],
  declarations: [DocumentsPreviewComponent],
  exports: [DocumentsPreviewComponent],
})
export class DocumentsPreviewModule {}
