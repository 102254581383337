<div class="flex flex-col flex-wrap" *ngIf="parsedReferences?.length > 0">
  <div *ngFor="let ref of parsedReferences; trackBy: trackByFn" class="flex mt-1.5 mr-2.5 overflow-hidden">
    <ng-container *ngIf="ref.type === 'photo'; else simpleReferencePreview">
      <span class="inline-flex">
        <div *ngIf="ref.document?.id | documentDetails | async as documentDetails">
          <p class="text-sm text-primary-700 pb-0.5" *ngIf="showLabels">{{ ref.name }}</p>
          <section
            *ngIf="documentDetails.download_token | documentPhoto : false : documentDetails | async as imageData"
            class="relative w-fit"
          >
            <img
              (click)="onOpenPhoto(documentDetails)"
              class="bg-cover bg-center bg-no-repeat image-preview border border-gray-200 rounded-lg cursor-pointer"
              [ngClass]="{ 'h-[100px]': !smallThumbnail, 'h-[50px]': smallThumbnail }"
              [src]="imageData"
              [alt]="ref.name"
            />
            <x-svg-icon
              *ngIf="canRemoveReference"
              class="flex absolute top-2 right-2 text-gray-400 cursor-pointer hover:text-danger-600"
              svgImageName="trash"
              svgClass="w-6 h-6"
              (click)="onRemoveReference(ref, $event)"
            ></x-svg-icon>
          </section>
        </div>
      </span>
    </ng-container>
    <ng-template #simpleReferencePreview>
      <div
        class="flex items-center justify-center cursor-pointer select-none rounded-lg"
        [ngClass]="{ 'border border-gray-200': showLabels && ref.name }"
        (click)="openPreview(ref)"
      >
        <span class="flex flex-row p-2">
          <x-svg-icon
            class="w-6 h-6"
            svgClass="text-gray-700 w-6 h-6"
            [svgImageName]="ref.icon"
            [title]="ref.type === 'document' ? ref.name : ''"
          ></x-svg-icon>
          <span class="flex-1 px-1.5 line-clamp-2 text-ellipsis" *ngIf="showLabels && ref.name">
            <span class="font-semibold text-gray-900" *ngIf="ref.current_no">
              <ng-container *ngIf="ref.type">{{ ref.type | transloco }}&nbsp;</ng-container>
              <ng-container>{{ 'number-no' | transloco }}</ng-container>
              <ng-container>&nbsp;{{ ref.current_no }}&nbsp;</ng-container>
            </span>
            <span>{{ ref.name }}</span>
          </span>
          <span class="border-l border-gray-200 whitespace-nowrap px-2" *ngIf="ref.page">
            {{ 'page-no' | transloco }} {{ ref.page }}
          </span>
          <x-svg-icon
            *ngIf="canRemoveReference"
            class="text-black hover:text-danger-800 cursor-pointer w-5 h-5"
            svgImageName="trash-can"
            (click)="onRemoveReference(ref, $event)"
          ></x-svg-icon>
        </span>
      </div>
    </ng-template>
  </div>
</div>
