<label [for]="id" class="block text-sm font-medium text-gray-700">
  {{ label }}
  <span *ngIf="required" class="text-danger-500 inline-block">*</span>
</label>
<div class="mt-1">
  <textarea
    class="p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-300 focus:ring-primary-500 focus:border-primary-500 shadow-sm"
    [autofocus]="focus"
    [required]="required"
    [placeholder]="placeholder || ''"
    [rows]="rows"
    [maxlength]="maxlength"
    [attr.id]="id"
    [attr.name]="name"
    [formControl]="control"
    [ngClass]="{
      'border-danger-400 text-danger-400':
        (control.invalid || (control.errors || [] | keyvalue).length > 0) && control.touched,
      'text-gray-400 cursor-not-allowed bg-gray-100': control.disabled || disabled
    }"
  ></textarea>
</div>
