import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { ActivatedRouteData } from '@core/models';
import _ from 'lodash';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { DestroyComponent } from '../components';

export enum PageType {
  Home = 'home',
  Portal = 'portal',
}

const scrollThreshold = 70; // difference in height of full-sized and condensed headers

@Component({
  selector: 'x-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageComponent extends DestroyComponent implements OnChanges {
  @HostBinding('class.scrolled')
  scrolled = false;
  @Input() condensedHeaderImage = false;
  @Input() hasSidePanel = false;
  @Input() pageType: PageType = PageType.Home;
  @Input() showHeaderImage = false;
  @Input() showSearch = false;
  @Input() userRole: 'admin' | 'customer' = 'customer';

  routeData?: ActivatedRouteData;
  showUser = false;

  constructor(router: Router, readonly activatedRoute: ActivatedRoute) {
    super();

    router.events
      .pipe(
        startWith(router),
        filter((event) => event instanceof NavigationEnd || event instanceof Router),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => this.onDocumentScroll(),
      });

    activatedRoute.url
      .pipe(
        tap(() => {
          this.routeData = this.getSnapshotData(activatedRoute.snapshot);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  get showBreadCrumbs(): boolean {
    return _.isNil(this.routeData?.showBreadCrumbs) ? true : this.routeData?.showBreadCrumbs;
  }

  get showFooter(): boolean {
    return _.isNil(this.routeData?.showFooter) ? true : this.routeData?.showFooter;
  }

  get showHeader(): boolean {
    return _.isNil(this.routeData?.showHeader) ? true : this.routeData?.showHeader;
  }

  get showSideMenu(): boolean {
    return _.isNil(this.routeData?.showSideMenu) ? this.hasSidePanel : this.routeData?.showSideMenu;
  }

  @HostListener('window:scroll')
  @HostListener('window:resize')
  onDocumentScroll(): void {
    this.scrolled = document.body.scrollTop > scrollThreshold || document.documentElement.scrollTop > scrollThreshold;
  }

  ngOnChanges({ pageType }: SimpleChanges) {
    if (pageType) {
      this.showUser = pageType.currentValue === PageType.Portal;
    }
  }

  private getSnapshotData(snapshot: ActivatedRouteSnapshot): ActivatedRouteData {
    if (snapshot?.firstChild) {
      return this.getSnapshotData(snapshot?.firstChild);
    }

    return snapshot?.data;
  }
}
