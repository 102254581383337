<ng-container [ngSwitch]="fieldName">
  <ng-container *ngSwitchCase="getKey('address')">{{ 'address' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('title')">{{ 'title' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('lastname')">{{ 'last-name' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('firstname')">{{ 'first-name' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('status')">{{ 'status' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('email')">{{ 'email' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('phone')">{{ 'phone' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('best_reachable')">{{ 'best-reachable' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('company')">{{ 'company' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('created_at')">{{ 'created-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getKey('role')">{{ 'role' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'group'">{{ 'group' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>
