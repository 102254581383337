import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { DocumentLabelModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { GridModule } from '@portal/components/grid/grid.module';
import { HeaderCellComponent } from '@portal/components/grid/header-cell/header-cell.component';
import { PanoramaViewerModule } from '@portal/components/panorama-viewer';
import { PortalDocumentHeaderCellComponent } from './portal-document-header-cell/portal-document-header-cell.component';
import { PortalDocumentTagModule } from './portal-document-tag';
import { PortalDocumentComponent } from './portal-document.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,

    GridModule,
    AgGridModule.withComponents([PortalDocumentHeaderCellComponent, HeaderCellComponent]),

    DocumentLabelModule,

    PortalDocumentTagModule,
    PanoramaViewerModule,
    TranslocoModule,
  ],
  declarations: [PortalDocumentComponent, PortalDocumentHeaderCellComponent],
  exports: [PortalDocumentComponent],
})
export class PortalDocumentModule {}
