import { Permission } from './permission';
import { Transform } from './transform';
import { User } from './user';

export interface Group {
  id: string;
  name: string;
  permission: Permission;
  tags: string[];
  is_locked: boolean;
  admin_type: 'admin' | User.AdminRole.Root | User.AdminRole.Manager | User.CustomerRole.ProjectManager;
  project_id: string;
  tenant_id: string;
  created_at: Date;
  deleted_at: Date;
  view_without_watermark: boolean;
  qa_limit_total: number;
  qa_limit_daily: number;
  tickets_limit_total: number;
  tickets_limit_daily: number;
  status: 'active' | 'blocked';
  type: Group.Type;
  limited_asset_ids?: string[];
  save_pdf_without_watermark: boolean;
  save_pdf_instead_these_file_formats: string[];
}

export interface ShortGroup {
  id: string;
  name: string;
}

export namespace Group {
  export type Type = 'custom' | 'manager' | 'project_manager';
  export const transform = (group: Group): Group =>
    group && {
      ...group,
      created_at: Transform.date(group.created_at),
      deleted_at: Transform.date(group.deleted_at),
    };
}
