import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}

document.body.classList.add('theme-default');

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
