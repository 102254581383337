import { Component, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

@Component({
  selector: 'x-filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, SvgIconModule],
})
export class FilterGroupComponent {
  @Input() title = '';
  @Input() expanded = false;

  toggle() {
    this.expanded = !this.expanded;
  }
}
