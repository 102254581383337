import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'x-shop-advertisement-tile',
  standalone: true,
  imports: [CommonModule, SvgIconModule, TranslocoModule],
  templateUrl: './shop-advertisement-tile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShopAdvertisementTileComponent {
  @Output() closed = new EventEmitter();
  readonly adShopLink = 'https://www.doree.plus';
}
