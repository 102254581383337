import { getAllEnumValues } from './enum';

export enum NumberOfObjects {
  N1 = 'n1',
  N24 = 'n2_4',
  More = 'n_more_5',
}

export namespace NumberOfObjects {
  export const all = getAllEnumValues<NumberOfObjects>(NumberOfObjects);
}
