import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import * as _ from 'lodash';

const kbSize = 1024;
const mbSize = kbSize * 1024;
const gbSize = mbSize * 1024;

@Injectable({
  providedIn: 'root',
})
export class FileSizeService {
  constructor(private readonly transloco: TranslocoService) {}

  /**
   * @returns size in specified type
   */
  transform(byteSize?: number, type: 'B' | 'kB' | 'MB' | 'GB' = 'GB'): string {
    if (_.isNil(byteSize) || isNaN(+byteSize) || !isFinite(+byteSize)) {
      return '';
    }

    /**
     * t(bytes.b)
     * t(bytes.kb)
     * t(bytes.mb)
     * t(bytes.gb)
     */

    switch (type) {
      case 'kB':
        const kb = Math.round((byteSize / kbSize) * 100) / 100;
        return kb === 0 ? this.transform(byteSize, 'B') : `${kb} ${this.transloco.translate('bytes.kb')}`;
      case 'MB':
        const mb = Math.round((byteSize / mbSize) * 100) / 100;
        return mb === 0 ? this.transform(byteSize, 'kB') : `${mb} ${this.transloco.translate('bytes.mb')}`;
      case 'GB':
        const gb = Math.round((byteSize / gbSize) * 100) / 100;
        return gb < 1 ? this.transform(byteSize, 'MB') : `${gb} ${this.transloco.translate('bytes.gb')}`;
      default:
        return `${byteSize}` + this.transloco.translate('bytes.b');
    }
  }
}
