<x-dialog [title]="'user-detail' | transloco" (handleClose)="ref.close()" [closeOnly]="true">
  <div class="divide-y divide-gray-200">
    <div class="row">
      <span class="label">{{ 'full-name' | transloco }}</span>
      <span>{{ data | userName }}</span>
    </div>

    <div class="row">
      <span class="label">{{ 'email' | transloco }}</span>
      <span>{{ data.email }}</span>
    </div>

    <div class="row">
      <span class="label">{{ 'created-at' | transloco }}</span>
      <span>{{ data.created_at | localeDate }}</span>
    </div>

    <div class="row">
      <span class="label">{{ 'status' | transloco }}</span>
      <span>{{ data.status | titlecase }}</span>
    </div>

    <div class="row" *ngIf="data.first_access_at">
      <span class="label">{{ 'first-login' | transloco }}</span>
      <span>{{ data.first_access_at | localeDate }}</span>
    </div>

    <div class="row" *ngIf="data.last_access_at">
      <span class="label">{{ 'last-login' | transloco }}</span>
      <span>{{ data.last_access_at | localeDate }}</span>
    </div>
  </div>
</x-dialog>
