<x-dropdown origin="TOP_RIGHT" width="sm">
  <x-button xDropdownTrigger color="white" size="sm" [disabled]="disabled">
    <x-svg-icon svgImageName="plus" svgClass="w-4 h-4 mr-2 text-black"></x-svg-icon>
    <span>{{ label | transloco }}</span>
    <x-svg-icon class="ml-2" svgImageName="chevron-arrow-down"></x-svg-icon>
  </x-button>

  <div class="max-h-[560px] overflow-y-auto relative">
    <div class="flex flex-col">
      <ng-container *ngFor="let option of options; trackBy: trackBy">
        <div
          (click)="openAttachmentSelection(option.type)"
          class="flex gap-4 align-center px-4 py-2.5 cursor-pointer hover:bg-gray-50"
          *ngIf="canView(permissions$ | async, option.permission) && isOptionVisible(option.type)"
        >
          <x-svg-icon svgClass="text-gray-700 h-6 w-6" [svgImageName]="option.iconName"></x-svg-icon>
          <span class="text-gray-700 text-base font-medium">
            {{ option.labelTranslationKey || option.type | transloco }}
          </span>
        </div>
      </ng-container>
      <ng-content></ng-content>
    </div>
  </div>
</x-dropdown>
