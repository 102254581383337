import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { BreadcrumbsDocumentInfoModule } from '../breadcrumbs-document-info';
import { PortalDocumentViewerComponent } from './portal-document-viewer.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: ``,
        component: PortalDocumentViewerComponent,
      },
    ]),
    CommonModule,
    PdfJsViewerModule,
    BreadcrumbsDocumentInfoModule,
    TranslocoModule,
  ],
  declarations: [PortalDocumentViewerComponent],
  exports: [PortalDocumentViewerComponent],
})
export class PortalDocumentViewerModule {}
