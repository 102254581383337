import { ColDef, ICellRendererParams, RowClickedEvent, ValueGetterParams } from '@ag-grid-community/core';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { AssetCellRendererComponent } from '@app/user/notification-plan/asset-cell-renderer/asset-cell-renderer.component';
import { AssetOverview } from '@core/models';
import { AssetOverviewHeaderCellComponent, DownloadTokenCallbackType, GridHelperService } from '@portal/components';
import { WrappedTextCellRendererComponent } from '@portal/components/grid/wrapped-text-cell-renderer/wrapped-text-cell-renderer.component';
import { MultipleBadgeCellRendererComponent } from '@portal/components/multiple-badge-cell-renderer/multiple-badge-cell-renderer.component';
import _uniq from 'lodash/uniq';
import { BaseGridComponent } from '../../grid/base-grid.component';

@Component({
  selector: 'x-asset-overview-list-view',
  templateUrl: './asset-overview-list-view.component.html',
  styleUrls: ['./asset-overview-list-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetOverviewListViewComponent extends BaseGridComponent<AssetOverview> implements AfterViewInit {
  @Input() assetData: AssetOverview[] = [];
  @Input() newDownloadTokenGetter: DownloadTokenCallbackType;
  @Output() assetClick: EventEmitter<AssetOverview> = new EventEmitter<AssetOverview>();
  @Output() lastAssetShown = new EventEmitter<void>();

  readonly gridHelper = inject(GridHelperService);
  readonly cdr = inject(ChangeDetectorRef);

  ngAfterViewInit() {
    this.cdr.markForCheck();
    this.gridApi?.getLastRenderedRow();
  }

  onRowClicked(data: AssetOverview, event?: RowClickedEvent) {
    this.assetClick.emit(data);
  }

  protected getColDef(field: keyof AssetOverview, colDef: Partial<ColDef> = {}): ColDef {
    return {
      ...super.getColDef(field, colDef),
      tooltipField: null,
      headerComponentFramework: AssetOverviewHeaderCellComponent,
    };
  }

  protected getColumnDefs(): ColDef[] {
    return [
      this.getColDef('asset_name', {
        colId: 'asset_name',
        minWidth: 300,
        tooltipField: null,
        wrapText: true,
        cellRendererFramework: AssetCellRendererComponent,
        cellRendererParams: () => ({
          new_download_token_getter: this.newDownloadTokenGetter,
        }),
      }),
      this.getColDef('asset_street1', {
        minWidth: 150,
        wrapText: true,
        cellRendererFramework: WrappedTextCellRendererComponent,
        cellRendererParams: this.gridHelper.getAddressCellValueGetter(),
      }),
      this.getColDef('project_product_type', {
        width: 80,
        minWidth: 60,
        valueGetter: (params: ValueGetterParams) =>
          this.transloco.translate(`asset-type.${params.data['project_product_type']}`),
      }),
      this.getColDef('project_due_date', {
        width: 100,
        hide: true,
        tooltipField: null,
        cellRenderer: ({ value }) => this.getDateCellRenderer(value, false),
      }),
      this.getColDef('asset_status', {
        cellRendererFramework: MultipleBadgeCellRendererComponent,
        width: 150,
        cellRendererParams: ({ data }) => {
          const badges = [
            {
              text: this.transloco.translate(`asset-statuses.${data.asset_status}`),
              badgeType: this.gridHelper.getBadgeType(data.asset_status),
            },
          ];
          if (data.asset_is_frozen) {
            badges.push({ badgeType: 'primary_light', text: this.transloco.translate('is-frozen') });
          }
          return {
            value: badges,
          };
        },
      }),
      this.getColDef('project_name', {
        width: 120,
        hide: this.getAssetsProjectAmount(this.assetData) <= 1,
        wrapText: true,
        cellRendererFramework: WrappedTextCellRendererComponent,
      }),
      this.getColDef('tracker_done', {
        minWidth: 50,
        width: 80,
        hide: true,
        wrapText: true,
        cellRenderer: ({ data }: ICellRendererParams) =>
          this.gridHelper.getProgressValue(data.tracker_done, data.tracker_all),
      }),
      this.getColDef('asset_is_initial_setup_done', {
        width: 80,
        minWidth: 50,
        hide: true,
        wrapText: true,
        cellRenderer: ({ data }: ICellRendererParams) =>
          this.transloco.translate(data.asset_is_initial_setup_done ? 'yes' : 'no'),
      }),
      this.getColDef('asset_created_at', {
        width: 100,
        hide: true,
        tooltipField: null,
        cellRenderer: ({ value }) => this.getDateCellRenderer(value, false),
      }),
      this.getColDef('asset_started_at', {
        width: 100,
        minWidth: 40,
        tooltipField: null,
        cellRenderer: ({ value }) => this.getDateCellRenderer(value, false),
      }),
      this.getColDef('asset_termination_notice_at', {
        tooltipField: null,
        minWidth: 40,
        cellRenderer: ({ value }) => this.getDateCellRenderer(value, false),
      }),
    ];
  }

  private getAssetsProjectAmount(assets: AssetOverview[]): number {
    const projectIds = assets.map((asset) => asset.project_id);
    const uniqueProjectIds = _uniq(projectIds);
    return uniqueProjectIds.length;
  }
}
