import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { MonitoringLabelComponent } from './monitoring-label.component';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [MonitoringLabelComponent],
  exports: [MonitoringLabelComponent],
})
export class MonitoringLabelModule {}
