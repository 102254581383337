<section class="settings-section" (transitionend)="isSettingsDisplayed = isPanelOpened">
  <ng-container *ngIf="isPanelOpened">
    <header class="flex space-between p-5 text-gray-900">
      <p class="text-sm font-semibold">{{ 'table-visible-columns' | transloco }}</p>
      <x-svg-icon
        (click)="isPanelOpened = !isPanelOpened"
        svgImageName="cross"
        class="cursor-pointer"
        svgClass="w-6 h-6 text-gray-400 rounded-xl hover:bg-gray-200"
      ></x-svg-icon>
    </header>
    <section class="flex flex-column px-2 overflow-auto h-full">
      <ng-container *ngFor="let column of columns">
        <div class="text-gray-900 text-sm ml-2">
          <mat-checkbox [disableRipple]="true" [checked]="!column.hide" (change)="onToggleVisibility(column)">
            <ng-container *ngIf="fieldTemplate">
              <ng-container
                *ngTemplateOutlet="fieldTemplate; context: { $implicit: column.displayName }"
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="labelComponent">
              <ng-container [cdkPortalOutlet]="getComponentPortal | call : labelComponent : column"></ng-container>
            </ng-container>
            <ng-container *ngIf="!fieldTemplate && !labelComponent">
              <ng-container *ngIf="translationPrefix; else idLabelTemplate">
                {{ translationPrefix + '.' + $any(column.columnId) | transloco }}
              </ng-container>
              <ng-template #idLabelTemplate>
                {{ column.columnId }}
              </ng-template>
            </ng-container>
          </mat-checkbox>
        </div>
      </ng-container>
    </section>
  </ng-container>
</section>

<section class="title-section" [class.opened]="isPanelOpened" (click)="isPanelOpened = !isPanelOpened">
  <div class="panel-title-container">
    <span class="panel-title">{{ 'settings' | transloco }}</span>
  </div>
</section>
