import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { NotificationPlan } from '@core/models';
import { TranslocoModule } from '@ngneat/transloco';
import { AssetOverviewImageTileComponent } from '@portal/components/assets-overview/asset-overview-image-tile/asset-overview-image-tile.component';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { CellRendererComponent } from '../../../portal/components/grid/cell-renderer';

@Component({
  selector: 'x-asset-cell-renderer',
  standalone: true,
  imports: [CommonModule, TranslocoModule, BadgeComponent, AssetOverviewImageTileComponent, SvgIconModule],
  templateUrl: './asset-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetCellRendererComponent extends CellRendererComponent<NotificationPlan> {}
