import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { CallPipeModule, LocaleDatePipeModule, NewLinePipeModule, SpinnerModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { DocumentsPreviewModule } from '@portal/components/documents-preview/documents-preview.module';
import { QaDetailsDialogFieldsComponent } from '@portal/components/qa-overview/qa-details-dialog-fields/qa-details-dialog-fields.component';
import { MessageDetailsDialogComponent } from './message-details-dialog.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { DocumentsService } from '@portal/services/documents.service';
import { CustomerDocumentsService, CustomerRoutingService, FindingsService } from '@portal/customer/services';
import { RoutingService } from '@portal/services/routing.service';
import { FindingService } from '@portal/services/finding.service';
import { TicketDetailsDialogFieldsComponent } from '@portal/customer/components/asset-details/asset-tickets-container/components/ticket-details-dialog-fields/ticket-details-dialog-fields.component';
import { ButtonComponent } from '@common/components/button/button.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { RequiredInformationComponent } from '@common/required-information/required-information.component';
import { WriteResponseFormComponent } from '@portal/customer/components/asset-details/asset-tickets-container/components/write-response-form/write-response-form.component';
import { ReferencesPreviewComponent } from '@portal/customer/components/references-preview/references-preview.component';
import { DetailsFieldRowComponent } from '@portal/components/details-field-row/details-field-row.component';
import { FormattedMemberNamePipe } from '@common/pipes/format-name/format-name.component';
import { UsernameCellRendererComponent } from '@portal/components/username-renderer/username-renderer.component';
import { FindingDetailsModule } from '@portal/customer/components/asset-details/findings/finding-details';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    LocaleDatePipeModule,
    NewLinePipeModule,
    CallPipeModule,
    DocumentsPreviewModule,
    QaDetailsDialogFieldsComponent,
    DialogComponent,
    TicketDetailsDialogFieldsComponent,
    ButtonComponent,
    SvgIconModule,
    RequiredInformationComponent,
    SpinnerModule,
    WriteResponseFormComponent,
    ReferencesPreviewComponent,
    DetailsFieldRowComponent,
    FormattedMemberNamePipe,
    UsernameCellRendererComponent,
    FindingDetailsModule,
  ],
  declarations: [MessageDetailsDialogComponent],
  exports: [MessageDetailsDialogComponent, MatDialogModule],
  providers: [
    CustomerDocumentsService,
    { provide: DocumentsService, useClass: CustomerDocumentsService },
    { provide: RoutingService, useClass: CustomerRoutingService },
    { provide: FindingService, useClass: FindingsService },
  ],
})
export class MessageDetailsDialogModule {}
