import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyOptionModule as MatOptionModule } from '@angular/material/legacy-core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { RouterModule } from '@angular/router';
import { IconComponentModule, RoleLabelModule, UserLabelModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { GridModule } from '../grid';
import { AddAdminDialogComponent, PortalAdminsComponent } from './portal-admin';
import { InviteUserDialogComponent, PortalUsersComponent } from './portal-user';
import { PortalUserHeaderCellComponent } from './portal-user-header-cell';
import { PortalUserStatusRendererComponent } from './portal-user-status-renderer';
import { PortalUsersContainerComponent } from './portal-users-container.component';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: PortalUsersContainerComponent,
      },
    ]),
    CommonModule,
    MatDialogModule,
    TranslocoModule,
    MatTabsModule,
    IconComponentModule,
    UserLabelModule,
    GridModule,
    FormsModule,
    MatMenuModule,
    RoleLabelModule,
    MatOptionModule,
    MatSelectModule,
    MatAutocompleteModule,
  ],
  declarations: [
    PortalUsersContainerComponent,
    PortalUserStatusRendererComponent,
    PortalUsersComponent,
    InviteUserDialogComponent,
    PortalAdminsComponent,
    AddAdminDialogComponent,
    PortalUserHeaderCellComponent,
  ],
  exports: [PortalUsersContainerComponent],
})
export class PortalUserModule {}
