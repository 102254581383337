import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Message } from '@core/models';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'x-message-circles',
  templateUrl: './message-circles.component.html',
  styleUrls: ['./message-circles.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageCirclesComponent {
  @Input() messages?: Message.Short[];
  @Output() messagesClick = new EventEmitter<Message.Short[]>();

  circlesTitle = this.transloco.translate('no-messages-for-the-page-click-to-show-hide-the-messages');

  readonly emptyCircles = [1, 2, 3];
  readonly emptyCirclesColor = '#b9bbaf';

  constructor(private readonly transloco: TranslocoService) {}

  @Input() set noMessagesTitle(title: string | undefined) {
    this.circlesTitle = title || this.transloco.translate('no-messages-for-the-page-click-to-show-hide-the-messages');
  }
}
