<span class="icon-font icon-font-close" mat-dialog-close></span>

<form #form="ngForm" class="container" (submit)="onSubmit()" novalidate autocomplete="off">
  <div class="row">
    <div class="col-6 col-md-12">
      <label>{{ 'user' | transloco }}</label>
    </div>
    <div class="col-6 col-md-12">
      <span class="material-select-container input-container">
        <input type="text" name="user" [(ngModel)]="model.user" [matAutocomplete]="userAutocomplete" />
        <mat-autocomplete #userAutocomplete="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let user of filteredOptions$ | async" [value]="user">
            <ng-container *ngIf="user.firstname || user.lastname">
              {{ user.firstname }} {{ user.lastname }}
            </ng-container>
            <ng-container *ngIf="!user.firstname && !user.lastname">
              {{ user.email }}
            </ng-container>
          </mat-option>
        </mat-autocomplete>
      </span>
    </div>
  </div>

  <div class="row">
    <div class="col-6 col-md-12">
      <label>{{ 'role' | transloco }}</label>
    </div>
    <div class="col-6 col-md-12">
      <span class="material-select-container input-container">
        <mat-select (selectionChange)="model.role_id = $event.value" name="role_id">
          <mat-option *ngFor="let role of roles$ | async" [value]="role">
            <x-role-label [role]="role"></x-role-label>
          </mat-option>
        </mat-select>
      </span>
    </div>
  </div>

  <button type="submit" class="submit-admin accent" [disabled]="!isModelFilled">
    <span>{{ 'submit' | transloco }}</span>
  </button>
</form>
