import { UserActions } from '@core/state';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { Document } from '../../../../core/models';
import { AssetDetailsActions } from './asset-details.actions';

const documentsAdapter: EntityAdapter<Document.ListItem> = createEntityAdapter<Document.ListItem>({
  selectId: ({ id }) => id,
});

const documentsInitialState = documentsAdapter.getInitialState({
  entities: {},
  ids: [],
});

const commentsReducer = createReducer<EntityState<Document.ListItem>>(
  documentsInitialState,
  on(AssetDetailsActions.fetchDocumentSuccess, (state, action) => documentsAdapter.upsertOne(action.data, state)),
  on(UserActions.clearUser, (state) => documentsAdapter.removeAll(state))
);

export namespace AssetDetailsReducer {
  export namespace DocumentsReducer {
    export const name = 'asset-details-documents';

    export const adapter = documentsAdapter;

    export const reducer = (state: EntityState<Document.ListItem>, action: Action) => commentsReducer(state, action);
  }
}
