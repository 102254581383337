<div class="h-full flex">
  <ag-grid-angular
    #grid
    class="ag-theme-xportal flex-1"
    [defaultColDef]="defaultColDef"
    [columnDefs]="columnDefs"
    [gridOptions]="gridOptions"
    [modules]="modules"
    [rowClass]="'clickable-row'"
    [rowData]="assetData"
    (bodyScrollEnd)="lastAssetShown.emit()"
  ></ag-grid-angular>

  <x-columns-panel
    [columns]="settingsColumns"
    [fieldTemplate]="documentLabelTemplate"
    (panelOpened)="onPanelOpened($event)"
    (toggleVisibility)="onToggleColumnVisibility($event)"
  ></x-columns-panel>
</div>

<ng-template #documentLabelTemplate let-field>
  <x-asset-overview-label [fieldName]="field"></x-asset-overview-label>
</ng-template>
