import { FormatDateService } from '@core/services/format-date.service';
import _cloneDeep from 'lodash/cloneDeep';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localeDate',
  pure: true,
})
export class LocaleDatePipe implements PipeTransform {
  constructor(private readonly formatDateService: FormatDateService) {}

  transform(date: Date | string | undefined, includeTime = false): string {
    let newDate = _cloneDeep(date);

    if (!date) {
      return '';
    }

    if (typeof date === 'string') {
      newDate = new Date(date);
    }

    return this.formatDateService.toLocaleString(newDate as Date, includeTime);
  }
}
