import { NgModule } from '@angular/core';
import { CallPipeModule } from './call';
import { ColorPipesModule } from './color/color-pipe.module';
import { FileSizePipeModule } from './file-size';
import { HasRolePipeModule } from './has-role';
import { HasValuePipeModule } from './has-value';
import { ImageUrlPipeModule } from './image-url';
import { IsPositiveNumModule } from './is-positive-num/is-positive-num.module';
import { ISODatePipeModule } from './iso-date';
import { LocaleCurrencyPipeModule } from './locale-currency';
import { LocaleDatePipeModule } from './locale-date';
import { LocaleNumberPipeModule } from './locale-number';
import { NewLinePipeModule } from './new-line';
import { NotPipeModule } from './not';
import { RomanPipeModule } from './roman';
import { SafePipeModule } from './safe';
import { UserNamePipeModule } from './user-name';
import { WithLoadingPipeModule } from './with-loading';

const pipes = [
  ColorPipesModule,
  CallPipeModule,
  NotPipeModule,
  UserNamePipeModule,
  LocaleDatePipeModule,
  LocaleNumberPipeModule,
  SafePipeModule,
  RomanPipeModule,
  ImageUrlPipeModule,
  HasValuePipeModule,
  FileSizePipeModule,
  HasRolePipeModule,
  NewLinePipeModule,
  WithLoadingPipeModule,
  ISODatePipeModule,
  LocaleCurrencyPipeModule,
  IsPositiveNumModule,
];

/**
 * @deprecated
 * Import only required pipes instead of this module
 */
@NgModule({
  imports: pipes,
  exports: pipes,
})
export class PipesModule {}
