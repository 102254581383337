<div class="flex-1 min-w-[25%] {{ labelClass }}">
  <span class="flex flex-row text-sm font-medium text-gray-500 float-left">
    {{ label }}
    <x-svg-icon
      *ngIf="tooltipText"
      class="ml-1.5"
      [matTooltip]="tooltipText"
      matTooltipPosition="above"
      svgImageName="info"
    ></x-svg-icon>
  </span>
</div>
<div class="flex items-center flex-[2.5] pl-2.5 text-base text-gray-900 {{ contentClasses }}">
  <ng-content></ng-content>
</div>
