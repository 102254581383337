import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AdditionalModules } from '../../../../core/models';

@Component({
  selector: 'x-additional-module-label',
  templateUrl: './additional-module-label.component.html',
  styleUrls: ['./additional-module-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalModuleLabelComponent {
  @Input()
  module: AdditionalModules;

  readonly AdditionalModules = AdditionalModules;
}
