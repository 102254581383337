import { AfterViewChecked, AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { fromEvent, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[preserveFocus]', // eslint-disable-line
})
export class PreserveFocusDirective implements AfterViewInit, OnDestroy, AfterViewChecked {
  @Input() preserveFocus?: unknown;

  readonly destroy$ = new Subject<void>();

  private debounceFocus = _.debounce(() => this.setFocus(), 100, { trailing: true });
  private focusedElementSelector: string;

  constructor(private readonly element: ElementRef<HTMLInputElement>) {}

  ngAfterViewChecked(): void {
    this.debounceFocus();
  }

  ngAfterViewInit(): void {
    fromEvent(this.element.nativeElement, 'focusin')
      .pipe(
        takeUntil(this.destroy$),
        map((event) => event.target as HTMLElement)
      )
      .subscribe({
        next: (target) => this.saveFocus(target),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getFieldId(target: HTMLElement): string {
    let fieldId = target?.getAttribute('field-id');
    if (!fieldId && target?.parentElement) {
      fieldId = this.getFieldId(target.parentElement);
    }

    return fieldId;
  }

  private saveFocus(element: HTMLElement): void {
    const id = this.getFieldId(element);
    this.focusedElementSelector = `[field-id="${id}"]`;
  }

  private setFocus(): void {
    const elementToFocus = this.element?.nativeElement?.querySelector(this.focusedElementSelector) as HTMLElement;
    if (document.activeElement !== elementToFocus && elementToFocus) {
      elementToFocus.focus();
    }
  }
}
