<ng-container [ngSwitch]="fieldName" *ngIf="!hasTooltip; else templateWithTooltip">
  <ng-container *ngSwitchCase="'scope'">{{ 'scope' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'description'">{{ 'description' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'story_id'">{{ 'story' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'user'">{{ 'user' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'created_at'">{{ 'created-at' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>

<ng-template #templateWithTooltip>
  <ng-container [ngSwitch]="fieldName">
    <span *ngSwitchCase="'scope'" title="{{ 'scope' | transloco }}">{{ 'scope' | transloco }}</span>
    <span *ngSwitchCase="'description'" title="{{ 'description' | transloco }}">{{ 'description' | transloco }}</span>
    <span *ngSwitchCase="'story_id'" title="{{ 'story' | transloco }}">{{ 'story' | transloco }}</span>
    <span *ngSwitchCase="'user'" title="{{ 'user' | transloco }}">{{ 'user' | transloco }}</span>
    <span *ngSwitchCase="'created_at'" title="{{ 'created-at' | transloco }}">{{ 'created-at' | transloco }}</span>

    <span *ngSwitchDefault [title]="fieldName">{{ 'field-name' | transloco }}</span>
  </ng-container>
</ng-template>
