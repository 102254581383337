import { createAction, props } from '@ngrx/store';

import { User } from '../../models';

export namespace UserActions {
  export const fetchUser = createAction('[Auth] Fetch User');
  export const fetchUserSuccess = createAction('[Auth] Fetch User Success', props<{ user: User.ActiveUser }>());
  export const fetchUserFail = createAction('[Auth] Fetch User Fail');

  export const signOut = createAction('[Auth] Sign Out');

  export const clearUser = createAction('[Auth] Clear User');

  export const showLoginDialog = createAction('[Auth] Show Login Dialog', props<{ nextAction: () => any }>()); // eslint-disable-line @typescript-eslint/no-explicit-any
  export const showChangePasswordDialog = createAction('[Auth] Show Change Password', props<{ token: string }>());

  export const showEmailConfirmationResult = createAction('[Auth] Show Confirmations', props<{ confirmed: boolean }>());
}
