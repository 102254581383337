import { FlatTreeControl } from '@angular/cdk/tree';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { Document } from '@core/models';
import { FileUploadProgress } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { map, startWith, take } from 'rxjs/operators';
import { SimpleChanges } from 'src/custom-typings/angular';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';

interface Node {
  name?: string;
  file?: File;
  children?: Node[];
  types?: Document.Type[];
}

interface FlatNode {
  name: string;
  file?: File;
  level: number;
  expandable: boolean;
  node: Node;
}

type ExtendedFile = Extend<
  File,
  {
    path: string;
    readonly webkitRelativePath: string;
  }
>;

interface DataRecordEntity {
  file?: ExtendedFile;
  children?: Node[];
}
type DataRecord = Record<string, Record<string, DataRecordEntity> | DataRecordEntity>;

export interface UploadOutput {
  files: File[];
  folders: string[];
  fileTypeMap?: Map<File, Document.Type>;
}

@UntilDestroy()
@Component({
  selector: 'x-files-tree',
  templateUrl: './files-tree.component.html',
  styleUrls: ['./files-tree.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesTreeComponent implements OnChanges {
  @Input('files') originalFiles?: File[];
  @Input('folders') originalFolders?: string[];
  @Input() types?: Document.Type[];
  @Input() maxFolderDepth?: number;
  @Input() progress?: FileUploadProgress[];
  @Input() readonly?: boolean;
  @Input() rebuildOnChange?: boolean;
  @Input() showTypes = true;
  @Input() maxFileSize?: number;

  @Output() filesChanged = new EventEmitter<Map<File, Document.Type[] | undefined>>();
  @Output() foldersChanged = new EventEmitter<string[]>();
  @Output() maxDepthLevelExceeded = new EventEmitter<boolean>();
  @Output() maxFileSizeExceeded = new EventEmitter<boolean>();
  @Output() emptyFileDetected = new EventEmitter<boolean>();
  @Output() tooLongPathFileDetected = new EventEmitter<boolean>();
  @Output() filesCount = new EventEmitter<number>();

  @ViewChild(CdkVirtualScrollViewport) viewport!: CdkVirtualScrollViewport;

  private currentFiles?: File[];
  private currentFolders?: string[];

  nestedNodeMap = new Map<Node, FlatNode>();
  treeControl = new FlatTreeControl<FlatNode>(this.getLevel.bind(this), this.isExpandable.bind(this));
  treeFlattener = new MatTreeFlattener<Node, FlatNode>(
    this.transformer.bind(this),
    this.getLevel.bind(this),
    this.isExpandable.bind(this),
    this.getChildren.bind(this)
  );
  dataSource = new MatTreeFlatDataSource<Node, FlatNode>(this.treeControl, this.treeFlattener);

  nodeHeight = 40;
  firstErrorNodeIndex = undefined;

  constructor() {
    this.treeControl.expansionModel.changed
      .pipe(
        map((_nodes) => this.treeControl.dataNodes),
        map((flatNodes) => _.filter(flatNodes, (flatNode) => !!flatNode?.file).length),
        startWith(0),
        untilDestroyed(this)
      )
      .subscribe({
        next: (filesCount) => this.filesCount.emit(filesCount),
      });

    this.treeControl.expansionModel.changed.pipe(untilDestroyed(this)).subscribe({
      next: (_flatNodes) => this.onChanges(),
    });
  }

  ngOnChanges({ types, originalFiles, originalFolders }: SimpleChanges<FilesTreeComponent>): void {
    let filesChanged = false;

    if (originalFiles) {
      this.currentFiles = originalFiles.currentValue;

      if (originalFiles.firstChange || this.rebuildOnChange) {
        filesChanged = true;
      } else {
        const currentFiles: File[] = originalFiles.currentValue || [];
        const prevFiles: File[] = originalFiles.previousValue || [];

        const uniq = _.uniq([...currentFiles, ...prevFiles]);
        const diff = _.difference(uniq, prevFiles);
        filesChanged = filesChanged || diff.length > 0;
      }
    }

    if (originalFolders) {
      this.currentFolders = originalFolders.currentValue;

      if (originalFolders.firstChange || this.rebuildOnChange) {
        filesChanged = true;
      } else {
        const currentFolders: string[] = originalFolders.currentValue || [];
        const prevFolders: string[] = originalFolders.previousValue || [];

        const diff = _.difference(currentFolders, prevFolders);
        filesChanged = filesChanged || diff.length > 0;
      }
    }

    if (filesChanged || types) {
      this.setDatasource(this.currentFiles || [], this.rebuildOnChange, this.currentFolders || []);
      this.onChanges();
    }
  }

  getLevel(node: FlatNode): number {
    return node.level;
  }

  isExpandable(node: FlatNode): boolean {
    return node.expandable;
  }

  getChildren(node: Node): Node[] | undefined {
    return node.children;
  }

  transformer(node: Node, level: number): FlatNode {
    const existingNode = this.nestedNodeMap.get(node);
    const flatNode = existingNode && existingNode.file === node.file ? existingNode : <FlatNode>{};
    flatNode.name = node.name || '';
    flatNode.file = node.file;
    flatNode.level = level;
    flatNode.expandable = !!node.children?.length;
    flatNode.node = node;
    this.nestedNodeMap.set(node, flatNode);
    return flatNode;
  }

  getNodePadding(node: FlatNode): number {
    return node.level * 20 + (node.file ? 24 : 0);
  }

  onRemoveNode(flatNode: FlatNode): void {
    const node = flatNode.node;
    const children = _.map(this.getAllNodeChildren(node), (n) => n.file);
    const filesToRemove = _.filter([flatNode.file, ...children]) as File[];

    const files = _.without((this.currentFiles || []) as File[], ...filesToRemove);

    const folders = this.getDatasourceFolders(node);

    this.currentFiles = files;
    this.currentFolders = folders;

    this.setDatasource(files, true, folders);
    this.onChanges();
  }

  getAllNodeChildren(node: Node): Node[] {
    const children = [...(node?.children || [])];
    const nestedChildren = _(children)
      .map((childNode) => this.getAllNodeChildren(childNode))
      .flatMap()
      .value();
    return [...children, ...nestedChildren];
  }

  getFileExtension(file: File): string | undefined {
    return file?.name.split('.').pop();
  }

  sortNodes(nodes: Node[]): Node[] {
    const collator = new Intl.Collator('de', {
      sensitivity: 'base',
      ignorePunctuation: true,
      numeric: false,
      caseFirst: 'upper',
    });
    const foldersFirst = 1;
    nodes.sort((a, b) => {
      if (a.file && b.file) {
        return collator.compare(a.file.name, b.file.name);
      } else if (a.file) {
        return foldersFirst;
      } else if (b.file) {
        return -foldersFirst;
      }

      return collator.compare(a.name || '', b.name || '');
    });
    nodes.forEach((node) => {
      node.children = node.children ? this.sortNodes(node.children) : node.children;
    });
    return nodes;
  }

  castToNode(node: FlatNode): FlatNode {
    return node;
  }

  onFileTypeChanged(): void {
    this.treeControl.expansionModel.changed.pipe(take(1), untilDestroyed(this)).subscribe((flatNodes) => {
      this.emitFileChanges([...flatNodes.added]);
    });
  }

  getFileProgress(progress: FileUploadProgress[], file: File): FileUploadProgress | undefined {
    return _.find(progress, (p) => p.file === file);
  }

  getFileProgressClass(progress: FileUploadProgress): 'error' | 'success' | 'progress' | undefined {
    if (progress.error) {
      return 'error';
    } else if (progress.progress === 100) {
      return 'success';
    } else if (progress.progress && progress.progress > 0) {
      return 'progress';
    }

    return;
  }

  getFileProgressValue(progress: FileUploadProgress): number {
    return progress.progress || 0;
  }

  getErrorTooltip(progress: FileUploadProgress): string {
    return progress.error?.toString() || '';
  }

  inverseOfTranslation(): string {
    if (!this.viewport) {
      return '-0px';
    }
    const offset = this.viewport.getOffsetToRenderedContentStart();

    return `-${offset}px`;
  }

  scrollToErrorNode() {
    this.viewport?.scrollToIndex(this.firstErrorNodeIndex);
  }

  private getDatasourceFolders(nodeToOmit?: Node): string[] {
    const getFolders = (nodes: Node[]): string[] => {
      const folderNodes = nodes.filter((n) => !n.file);

      return folderNodes.flatMap((node) => {
        if (node === nodeToOmit) {
          return [];
        }

        const name = node.name as string;

        if (node.children) {
          const childNames = getFolders(node.children);
          return childNames.length ? childNames.map((chName) => `${name}/${chName}`) : [name];
        }

        return [name];
      });
    };

    return getFolders(this.dataSource.data);
  }

  private getFoldersFromFiles(filesCollection: File[]): string[] {
    const files: ExtendedFile[] = _.map(filesCollection, (file) => {
      const exFile = file as ExtendedFile;
      exFile.path = exFile.path || exFile.webkitRelativePath;
      return exFile;
    });
    const directories: string[] = [];

    _.each(files, (file) => {
      const folders = (file.path?.split('/') as string[]) || [];
      if (folders.length <= 1) {
        return;
      }
      folders.pop();
      directories.push(folders.join('/'));
    });

    return _.uniq(directories);
  }

  private setDatasource(files: File[], reset = false, folders?: string[]): void {
    if (!files.length && !folders?.length && !reset) {
      this.dataSource.data = [];
      return;
    }

    folders = folders
      ? Array.from(new Set([...folders, ...this.getFoldersFromFiles(files)]))
      : this.getFoldersFromFiles(files);

    const flattenedNodes = _.flatMap(this.dataSource.data, (node) => this.getAllNodeChildren(node));
    const data: Record<string, DataRecordEntity> = {};

    _.forEach(files, (file) => {
      const exFile = file as ExtendedFile;
      const paths = exFile.path?.split('/').filter((key) => !_.isEmpty(key)) || [];
      if (paths.length) {
        paths.pop();
      }

      const node: Node = this.getRecursiveNode(data, paths);
      node.children = node.children || [];
      node.children.push({
        name: file.name,
        file,
        types: this.getFileType(file, _.find(flattenedNodes, (n) => n.file === file)?.types),
      });
    });

    _.forEach(folders, (folder) => {
      const paths = folder.split('/').filter((key) => !_.isEmpty(key)) || [];
      this.getRecursiveNode(data, paths);
    });

    const getNodes = (d: DataRecordEntity, name: string) => {
      if (_.isEmpty(name)) {
        return d.children;
      }

      const childFolders = _(d)
        .omit('file', 'children')
        .mapValues((child, key) => getNodes(child, key))
        .values()
        .value();

      const node: Node = {
        name,
        children: [...childFolders, ...(d?.children || [])],
      };
      return node;
    };
    const nodes = _(data).mapValues(getNodes).values().flatMap().value();

    if (nodes.length) {
      this.dataSource.data = this.sortNodes(nodes as Node[]);
      this.treeControl.expandAll();
    } else {
      this.dataSource.data = [];
      this.filesCount.emit(0);
    }
  }

  private getRecursiveNode(data: DataRecord, paths: string[]): DataRecordEntity {
    if (_.isEmpty(paths) || _.isNil(paths)) {
      return data[''] || (data[''] = {});
    }

    const path = paths.shift() as string;
    const d = data[path] ? data[path] : (data[path] = {} as DataRecord);

    return paths.length === 0 ? d : this.getRecursiveNode(d as DataRecord, paths);
  }

  private getFileType(file: File, types?: Document.Type[]): Document.Type[] | undefined {
    if (!this.types) {
      return undefined;
    }

    if (types) {
      return types;
    }

    const fileType = file.type.split('/')[0];

    if (fileType === 'image' && this.types.includes('photo')) {
      return ['photo'];
    } else if (fileType === 'video' && this.types.includes('video')) {
      return ['video'];
    } else if (fileType === 'application' && this.types.includes('report')) {
      return ['report'];
    }

    return this.types.includes('document') ? ['document'] : this.types;
  }

  private onChanges() {
    const flatNodes = this.treeControl.dataNodes.filter((node) => !node?.file?.['hasGetFileError']);
    const tooLongPathNodes = this.treeControl.dataNodes.filter((node) => node?.file?.['hasGetFileError']);
    const maxLevelDepthNode = _.maxBy(flatNodes, (flatNode) => flatNode?.level);
    const maxFileSizeNode = _.maxBy(flatNodes, (flatNode) => flatNode?.file?.size);
    const minFileSizeNode = _.minBy(flatNodes, (flatNode) => flatNode?.file?.size);
    this.maxDepthLevelExceeded.emit(maxLevelDepthNode?.level > this.maxFolderDepth);
    const maxSizeExceeded = maxFileSizeNode?.file?.size > this.maxFileSize;
    const isZeroBytes = minFileSizeNode?.file?.size === 0;
    this.maxFileSizeExceeded.emit(maxSizeExceeded);
    this.emptyFileDetected.emit(isZeroBytes);
    const tooLongPathFileDetected = tooLongPathNodes.length > 0;
    this.tooLongPathFileDetected.emit(tooLongPathFileDetected);
    if (tooLongPathFileDetected) {
      this.firstErrorNodeIndex = this.treeControl.dataNodes.indexOf(tooLongPathNodes[0]);
      this.scrollToErrorNode();
    } else if (maxSizeExceeded || isZeroBytes) {
      this.firstErrorNodeIndex = flatNodes.indexOf(maxSizeExceeded ? maxFileSizeNode : minFileSizeNode);
      this.scrollToErrorNode();
    } else {
      this.firstErrorNodeIndex = undefined;
    }
    this.emitFileChanges(this.treeControl.dataNodes as FlatNode[]);
  }

  private emitFileChanges(flatNodes: FlatNode[]): void {
    const nodes = (flatNodes || []).filter((fn) => !!fn.file).map((fn) => fn.node);
    const fileTypeMap = new Map<File, Document.Type[] | undefined>();
    nodes.forEach((node) => {
      if (node.file) {
        fileTypeMap.set(node.file, node.types);
      }
    });

    this.filesChanged.emit(fileTypeMap);

    const folders = this.getDatasourceFolders();
    this.foldersChanged.emit(folders);
  }
}
