import { getAllEnumValues } from './enum';

export const ENGLISH_LOCALE = 'en-EN';
export const GERMAN_LOCALE = 'de-DE';
export const FRENCH_LOCALE = 'fr-FR';
export const ITALIAN_LOCALE = 'it-IT';
export const DANISH_LOCALE = 'da-DK';

export enum AvailableLanguages {
  German = 'de',
  English = 'en',
  Italian = 'it',
  French = 'fr',
  Danish = 'dk',
}

export namespace AvailableLanguages {
  export const all = getAllEnumValues<AvailableLanguages>(AvailableLanguages);
}
