import { Injectable } from '@angular/core';
import { AdminDocumentsService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AdminRouterSelectors } from '../admin-router.selector';
import { AdminDocumentsActions } from './documents.actions';

@Injectable()
export class AdminDocumentsEffects {
  readonly loadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AdminDocumentsActions.fetchDocument),
      withLatestFrom(
        this.store.select(AdminRouterSelectors.getProjectId),
        this.store.select(AdminRouterSelectors.getProjectAssetId),
        this.store.select(AdminRouterSelectors.getProjectAssetDocumentId)
      ),
      switchMap(([, project_id, asset_id, document_id]) =>
        this.documentsService.getDocument(project_id, asset_id, document_id)
      ),
      map((document) => AdminDocumentsActions.fetchDocumentSuccess({ data: document }))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<never>,
    private documentsService: AdminDocumentsService
  ) {}
}
