import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'x-not-found-alert',
  standalone: true,
  imports: [CommonModule, SvgIconModule, TranslocoModule],
  templateUrl: './not-found-alert.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./not-found-alert.component.scss'],
})
export class NotFoundAlertComponent {
  @Input() withBorder = true;
  @Input() customPrimaryText?: string;
  @Input() customSecondaryText?: string;
}
