import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@common/components/svg-icon/svg-icon.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AngularSvgIconPreloaderModule } from 'angular-svg-icon-preloader';

@NgModule({
  declarations: [SvgIconComponent],
  imports: [
    CommonModule,
    AngularSvgIconModule.forRoot(),
    AngularSvgIconPreloaderModule.forRoot({
      configUrl: `/static/assets/icons/svg/preload.json`,
    }),
  ],
  exports: [SvgIconComponent],
})
export class SvgIconModule {}
