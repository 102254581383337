import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssetOverviewLabelModule, DocumentLabelModule } from '@common';
import { AssetOverviewListViewComponent } from '@portal/components/assets-overview/asset-overview-list-view/asset-overview-list-view.component';
import { GridModule } from '../../grid/grid.module';

@NgModule({
  imports: [CommonModule, AgGridModule, GridModule, DocumentLabelModule, AssetOverviewLabelModule],
  declarations: [AssetOverviewListViewComponent],
  exports: [AssetOverviewListViewComponent],
})
export class AssetOverviewListViewModule {}
