import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { Injector } from '@angular/core';
import { isNotNil } from 'src/app/utils';
import { LabelComponentInjectors } from './label-component';

export interface LabelPortalFactoryProps {
  field: string;
  displayName?: string;
  tooltip?: boolean;
}

export class LabelPortalFactory {
  static createPortal<T>(
    labelComponent: ComponentType<T>,
    { field, displayName, tooltip }: LabelPortalFactoryProps
  ): ComponentPortal<T> {
    const injector = Injector.create({
      providers: [
        {
          provide: LabelComponentInjectors.Field,
          useValue: isNotNil(displayName) ? displayName : field,
        },
        {
          provide: LabelComponentInjectors.Tooltip,
          useValue: !!tooltip,
        },
      ],
    });

    return new ComponentPortal(labelComponent, null, injector);
  }
}
