import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Message } from '@core/models';

@Component({
  selector: 'x-qa-label',
  templateUrl: './qa-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QALabelComponent {
  @Input() fieldName?: keyof Message;
  @Input() hasTooltip = false;

  getField(field: keyof Message): keyof Message {
    return field;
  }
}
