<ng-container [ngSwitch]="fieldName">
  <ng-container *ngSwitchCase="'id'">{{ 'id' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'description'">{{ 'description' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'planned_date'">{{ 'planned-date' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'done_at'">{{ 'done-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'is_done'">{{ 'done' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'invoice_relevant'">{{ 'invoice-relevant' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'invoice_rate'">{{ 'invoice-rate' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'numeration'">{{ 'numeration' | transloco }}</ng-container>
  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>
