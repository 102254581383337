import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISelectOption } from '@common/components/select/select.model';

import {
  Asset,
  AssetType,
  Countries,
  GermanLands,
  getCountryTranslationKeyName,
  getKnownDecimalSeparators,
} from '@core/models';
import { FormatDateService, LanguageService, ProjectsService } from '@core/services';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { Observable, throwError } from 'rxjs';
import { catchError, take } from 'rxjs/operators';

export interface CreateAssetDialogData {
  asset?: Asset.Details;
  projectId: string;
}

@UntilDestroy()
@Component({
  selector: 'x-create-asset-dialog',
  templateUrl: './create-asset-dialog.component.html',
  styleUrls: ['./create-asset-dialog.component.scss'],
})
export class CreateAssetDialogComponent {
  inProgress = false;
  assetFormGroup: FormGroup;

  readonly AssetType = AssetType;
  readonly Countries = Countries;
  readonly sortedCountries = this.sortCountries(Countries.all).map((country) => ({
    value: country,
    label: this.transloco.translate(getCountryTranslationKeyName(country)),
  }));
  readonly states: ISelectOption<string>[] = GermanLands.map((land) => ({ value: land, label: land }));
  readonly minDate = new Date();
  readonly separators = getKnownDecimalSeparators.map((separator) => ({
    value: separator,
    label: this.transloco.translate(`decimal-separators.${separator}`),
  }));

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateAssetDialogData,
    private readonly dialogRef: MatDialogRef<CreateAssetDialogComponent, boolean>,
    private readonly projectsService: ProjectsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly transloco: TranslocoService,
    private readonly formatDateService: FormatDateService,
    private readonly fb: FormBuilder,
    private readonly toastService: ToastService,
    private readonly languageService: LanguageService
  ) {
    this.buildCreateAssetFormGroup();
    this.watchStreetChange();
  }

  get isEdit(): boolean {
    const formValue = this.assetFormGroup.value;
    return 'id' in formValue && !_.isEmpty(formValue.id);
  }

  onSubmit(): void {
    this.inProgress = true;
    let request$: Observable<void>;
    let terminationNoticeDate: Date;
    const terminationNoticeAtValue = this.assetFormGroup.controls.termination_notice_at.value;
    if (terminationNoticeAtValue) {
      terminationNoticeDate = new Date(this.formatDateService.format(terminationNoticeAtValue));
      terminationNoticeDate.setUTCHours(12);
    }

    this.assetFormGroup.controls.termination_notice_at.patchValue(terminationNoticeDate);

    let formValue = this.assetFormGroup.value;
    formValue.asset_types = Object.keys(formValue.asset_types).filter((assetType) => formValue.asset_types[assetType]);
    if (formValue.address.country !== Countries.Germany) {
      delete formValue.address.state;
    }

    const { gross_area, parking_garage_spaces, plot_size, year_of_construction } = formValue;

    formValue = {
      ...formValue,
      gross_area: Number.parseInt(gross_area, 10),
      parking_garage_spaces: Number.parseInt(parking_garage_spaces, 10),
      plot_size: Number.parseInt(plot_size, 10),
      year_of_construction: Number.parseInt(year_of_construction, 10),
    };

    if ('id' in this.assetFormGroup.value) {
      request$ = this.updateAsset(this.data?.projectId, formValue);
    } else {
      request$ = this.createAsset(this.data?.projectId, formValue);
    }

    request$
      .pipe(
        take(1),
        catchError(({ status, error }) => {
          this.inProgress = false;

          if (status === 422) {
            _.forOwn(this.assetFormGroup.controls, (control) => {
              if (!control) {
                return;
              }

              control.setErrors(null);
            });

            _.forEach(error.errors, (serverError) => {
              const { source } = serverError;
              const control = this.assetFormGroup.controls[source];
              if (!control) {
                return;
              }

              control.setErrors({ [serverError.error]: true });
              control.markAsDirty();
              control.markAsTouched();
            });
          }

          this.cdr.markForCheck();
          this.toastService.showToast(
            this.transloco.translate('create-asset-dialog.notification.create-asset-failed'),
            ToastType.ERROR,
            error.errors,
            undefined,
            undefined,
            undefined,
            true
          );
          return throwError(error);
        })
      )
      .subscribe({
        next: () => {
          this.inProgress = false;
          this.cdr.markForCheck();
          this.dialogRef.close(true);
        },
      });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  trackByFn(index) {
    return index;
  }

  private createAsset(projectId: string, model: Asset): Observable<void> {
    return this.projectsService.createAsset(projectId, model);
  }

  private updateAsset(projectId: string, model: Asset.Details): Observable<void> {
    return this.projectsService.updateAsset(projectId, model);
  }

  private hasAssetType(assetType: AssetType): boolean {
    return (this.data?.asset?.asset_types || []).indexOf(assetType) !== -1;
  }

  private buildCreateAssetFormGroup(): void {
    this.assetFormGroup = this.fb.group({
      ...(this.data?.asset?.id ? { id: [this.data?.asset?.id] } : {}),
      name: ['', [Validators.required]],
      asset_types: this.fb.group({
        [AssetType.Residential]: [this.hasAssetType(AssetType.Residential)],
        [AssetType.Office]: [this.hasAssetType(AssetType.Office)],
        [AssetType.Retail]: [this.hasAssetType(AssetType.Retail)],
        [AssetType.Hotel]: [this.hasAssetType(AssetType.Hotel)],
        [AssetType.Logistics]: [this.hasAssetType(AssetType.Logistics)],
        [AssetType.ShoppingCenter]: [this.hasAssetType(AssetType.ShoppingCenter)],
        [AssetType.NursingHome]: [this.hasAssetType(AssetType.NursingHome)],
        [AssetType.ParkingGarage]: [this.hasAssetType(AssetType.ParkingGarage)],
      }),
      year_of_construction: [''],
      plot_size: [''],
      gross_area: [''],
      parking_garage_spaces: [''],
      address: this.fb.group({
        country: ['', [Validators.required]],
        state: [''],
        city: ['', [Validators.required]],
        line1: ['', { validators: [Validators.required], updateOn: 'blur' }],
        line2: [''],
        zip: ['', [Validators.required]],
      }),
      termination_notice_at: [''],
      decimal_separator: ['', [Validators.required]],
    });
    this.assetFormGroup
      .get('address.state')
      .addValidators(this.requiredStateValidator(this.assetFormGroup.get('address.country')));
    if (this.data.asset) {
      this.assetFormGroup.patchValue(this.data.asset);
    }
  }

  private sortCountries(countries: Countries[]): Countries[] {
    return this.languageService.getCountriesSortedByCurrentLanguage(countries);
  }

  private watchStreetChange() {
    this.assetFormGroup
      .get('address')
      .get('line1')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value) => {
        const line2Control = this.assetFormGroup.get('address').get('line2') as FormControl;
        if (!line2Control.value) {
          line2Control.patchValue(value);
        }
      });
  }

  private requiredStateValidator(countryControl: AbstractControl): ValidatorFn {
    return (stateControl: AbstractControl): { [key: string]: boolean } | null => {
      const countryValue = countryControl.value;
      const stateValue = stateControl.value;

      if (countryValue === Countries.Germany && !stateValue) {
        return { requiredState: true };
      }

      return null;
    };
  }
}
