import { Asset, AssetSettings, Group, Permission } from '@core/models';
import { createAction, props } from '@ngrx/store';

export namespace ResolvedDataActions {
  export const setAssetSettings = createAction(
    '[Resolved Data] Set Asset Settings',
    props<{
      data: {
        settings?: AssetSettings;
        permission?: Permission;
        assetId: string;
      };
    }>()
  );

  export const updateSettingsGroupIfApplicable = createAction(
    '[Resolved Data] Update Settings Group',
    props<{
      group: Group;
    }>()
  );

  export const setAssetName = createAction(
    '[Resolved Data] Set asset name',
    props<{
      name: string;
    }>()
  );

  export const clearAssetSettings = createAction('[Resolved Data] Clear Permission');

  export const setAssetLogo = createAction(
    '[Resolved Data] Set Asset Logo',
    props<{
      url: string | undefined;
    }>()
  );

  export const setAssetStatus = createAction(
    '[Resolved Data] Set Asset Status',
    props<{
      assetStatus: Asset.Status;
    }>()
  );

  export const setAssetFrozenState = createAction(
    '[Resolved Data] Set Asset Frozen Status',
    props<{
      isFrozen: boolean;
    }>()
  );

  export const setDisplayAssetIndexPoints = createAction(
    '[Resolved Data] Set Asset Index Points',
    props<{
      displayIndexPoints: boolean;
    }>()
  );

  export const setProjectLogo = createAction(
    '[Resolved Data] Set Project Logo',
    props<{
      url: string | undefined;
    }>()
  );

  export const setReportLogo = createAction(
    '[Resolved Data] Set Report Logo',
    props<{
      url: string | undefined;
    }>()
  );

  export const setProjectTermsConditionsId = createAction(
    '[Resolved Data] Set Project Terms & Conditions Id',
    props<{
      termsConditionsId: string | undefined;
    }>()
  );
}
