import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { franc } from 'franc';
import createHyphen, { HyphenationFunctionAsync } from 'hyphen';
import de from 'hyphen/patterns/de-1996';
import en from 'hyphen/patterns/en-us';
import { from, Subject } from 'rxjs';
import { switchMap, take, takeUntil } from 'rxjs/operators';

interface HyphenOptions {
  html: boolean;
  async: boolean;
  hyphenChar: '&shy;' | '\u200B';
  minWordLength: number;
}

const CREATE_HYPHEN_OPTIONS: HyphenOptions = {
  html: true,
  async: true,
  hyphenChar: '&shy;',
  minWordLength: 2,
};

@UntilDestroy()
@Directive({
  selector: '[xHyphenate]',
  standalone: true,
})
export class HyphenateDirective implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() xHyphenate = true;
  @Input() hyphenatedText: string | null = null;
  @Input() isHyphenInvisible = false;
  @Input() additionalHyphenChars?: string[];

  private readonly createHyphenOptions: HyphenOptions = { ...CREATE_HYPHEN_OPTIONS };
  private hyphenateEnglish: HyphenationFunctionAsync;
  private hyphenateGerman: HyphenationFunctionAsync;
  private markForTransform = true;
  private isInProgress = false;
  private previousWidth = 0;
  private resizeObserver: ResizeObserver;
  private stopOldProcesses$ = new Subject<string>();

  constructor(
    private readonly el: ElementRef,
    private readonly ngZone: NgZone,
    private readonly transloco: TranslocoService,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.createHyphenateByLangaugeFunctions();
  }

  ngOnInit(): void {
    if (this.isHyphenInvisible) {
      this.createHyphenOptions.hyphenChar = '\u200B';
    }
    this.createHyphenateByLangaugeFunctions();
  }

  ngOnChanges({ hyphenatedText, isHyphenInvisible, additionalHyphenChars }: SimpleChanges): void {
    if (hyphenatedText?.currentValue) {
      this.markForTransform = true;
      this.hyphenate(hyphenatedText.currentValue);
    }

    if (isHyphenInvisible?.currentValue !== undefined) {
      this.createHyphenOptions.hyphenChar = isHyphenInvisible.currentValue === true ? '\u200B' : '&shy;';
      this.createHyphenateByLangaugeFunctions();
    }

    if (additionalHyphenChars?.currentValue) {
      this.additionalHyphenChars = additionalHyphenChars.currentValue;
      this.hyphenate();
    }
  }

  ngAfterViewInit(): void {
    if (this.xHyphenate) {
      this.hyphenate();
      this.observeWidthChanges();
      this.observeLanguageChanges();
    }
  }

  ngOnDestroy(): void {
    if (this.resizeObserver && Object.keys(this.resizeObserver).length) {
      this.resizeObserver.unobserve(this.el.nativeElement);
    }
  }

  private hyphenate(text: string = this.hyphenatedText): void {
    if (!this.xHyphenate || !this.markForTransform) return;

    if (this.isInProgress) {
      this.stopOldProcesses$.next();
    }

    this.isInProgress = true;
    let value = text || this.el.nativeElement.innerText;

    if (value && this.additionalHyphenChars?.length > 0) {
      this.additionalHyphenChars.forEach((char) => {
        value = value.replace(new RegExp(`${char}`, 'g'), `${char}${this.createHyphenOptions.hyphenChar}`);
      });
    }

    if (value && value !== '-') {
      const language = franc(value);
      const hyphenateFn = language === 'deu' ? this.hyphenateGerman.bind(this) : this.hyphenateEnglish.bind(this);
      const transformedHtmlPromise = hyphenateFn(value) as Promise<string>;

      from(transformedHtmlPromise)
        .pipe(
          take(1),
          switchMap((transformedHtmlValue) => {
            const isHTML = RegExp(/(<([^>]+)>)/i);
            if (isHTML.test(transformedHtmlValue)) {
              this.el.nativeElement.innerText = transformedHtmlValue;
            } else {
              this.el.nativeElement.innerHTML = transformedHtmlValue;
            }
            this.el.nativeElement.style.hyphens = 'manual';
            this.el.nativeElement.style.wordBreak = 'break-word';
            this.isInProgress = false;
            this.cdr.markForCheck();
            return transformedHtmlPromise;
          }),
          takeUntil(this.stopOldProcesses$)
        )
        .subscribe();

      this.markForTransform = false;
    } else {
      this.isInProgress = false;
    }
  }

  private observeLanguageChanges(): void {
    this.transloco.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.markForTransform = true;
      this.hyphenate();
    });
  }

  private observeWidthChanges(): void {
    this.ngZone.runOutsideAngular(() => {
      this.resizeObserver = new ResizeObserver(() => {
        const currentWidth = this.el.nativeElement.offsetWidth;
        if (this.previousWidth !== currentWidth) {
          this.previousWidth = currentWidth;
          this.ngZone.run(() => {
            this.markForTransform = true;
            this.hyphenate();
          });
        }
      });
      if (this.resizeObserver && Object.keys(this.resizeObserver).length) {
        this.resizeObserver.observe(this.el.nativeElement);
      }
    });
  }

  private createHyphenateByLangaugeFunctions(): void {
    this.hyphenateEnglish = createHyphen(en, this.createHyphenOptions) as HyphenationFunctionAsync;
    this.hyphenateGerman = createHyphen(de, this.createHyphenOptions) as HyphenationFunctionAsync;
  }
}
