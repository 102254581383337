import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateAdapter, MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarConfig, MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CallPipeModule, PageModule, SpinnerModule, ValidationErrorLabelModule } from '@common';
import { CustomDateAdapter } from '@common/custom-date.adapter';
import { CoreModule } from '@core/core.module';
import { LocaleInterceptor, TimezoneInterceptor } from '@core/interceptors';
import { StopRequestsAfterLogoutInterceptor } from '@core/interceptors/stop-requests-after-logout.interceptor';
import { ACCESS_TOKEN_STORAGE_KEY, LanguageService, TitleService, UserService } from '@core/services';
import { UsersEffects, UsersReducer } from '@core/state';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { DisclaimerInterceptor } from '@portal/customer/interceptors';
import { IdleInterceptor } from '@portal/customer/interceptors/idle.interceptor';
import { TermsConditionsInterceptor } from '@portal/customer/interceptors/terms-conditions.interceptor';
import { CustomerDocumentsService } from '@portal/customer/services';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslocoRootModule } from './transloco-root.module';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ErrorInterceptor } from '@core/interceptors/http-error.interceptor';
import { FullPageWithHeaderAndFooterComponent } from '@common/components/full-page-with-header-and-footer/full-page-with-header-and-footer.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { QaDetailsDialogFieldsComponent } from '@portal/components/qa-overview/qa-details-dialog-fields/qa-details-dialog-fields.component';
import { TicketDetailsDialogFieldsComponent } from '@portal/customer/components/asset-details/asset-tickets-container/components/ticket-details-dialog-fields/ticket-details-dialog-fields.component';
import { FindingDetailsModule } from '@portal/customer/components/asset-details/findings/finding-details';
import { SpinnerOverlayComponent } from '@common/spinner-overlay/spinner-overlay.component';
import { DBConfig, NgxIndexedDBModule } from 'ngx-indexed-db';
import { JwtModule } from '@auth0/angular-jwt';
import { AirbrakeErrorHandler } from '@app/airbrake-error.handler';

export const UPLOAD_FILES_STORE_NAME = 'file-uploads';

const dbConfig: DBConfig = {
  name: 'doree-plus',
  version: 1,
  objectStoresMeta: [
    {
      store: UPLOAD_FILES_STORE_NAME,
      storeConfig: { keyPath: null, autoIncrement: false },
      storeSchema: [
        { name: 'file', keypath: 'file', options: { unique: true } },
        { name: 'id', keypath: 'id', options: { unique: true } },
      ],
    },
  ],
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: () => localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY),
        allowedDomains: [window.location.host],
        disallowedRoutes: [/refresh-token$/],
      },
    }),
    NgCircleProgressModule.forRoot({ lazy: false }),
    EffectsModule.forRoot([]),
    EffectsModule.forFeature([UsersEffects]),
    StoreModule.forFeature(UsersReducer.name, UsersReducer.reducer),
    PageModule,
    FormsModule,
    ValidationErrorLabelModule,
    MatDialogModule,
    TranslocoRootModule,
    SvgIconModule,
    FullPageWithHeaderAndFooterComponent,
    SpinnerModule,
    DialogComponent,
    QaDetailsDialogFieldsComponent,
    TicketDetailsDialogFieldsComponent,
    FindingDetailsModule,
    CallPipeModule,
    SpinnerOverlayComponent,
    NgxIndexedDBModule.forRoot(dbConfig),
  ],
  declarations: [AppComponent],
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: { hasBackdrop: true, disableClose: true },
    },
    {
      provide: MAT_RIPPLE_GLOBAL_OPTIONS,
      useValue: {
        disabled: true,
        animation: {
          enterDuration: 0,
          exitDuration: 0,
        },
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: <MatSnackBarConfig>{
        panelClass: 'success-snackbar',
        horizontalPosition: 'center',
        verticalPosition: 'top',
      },
    },
    UserService,
    TitleService,
    { provide: HTTP_INTERCEPTORS, useClass: LocaleInterceptor, multi: true, deps: [LanguageService] },
    { provide: HTTP_INTERCEPTORS, useClass: TimezoneInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: StopRequestsAfterLogoutInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: IdleInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: CustomerDocumentsService, useClass: CustomerDocumentsService },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DisclaimerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TermsConditionsInterceptor,
      multi: true,
    },
    { provide: DateAdapter, useClass: CustomDateAdapter },
    { provide: ErrorHandler, useClass: AirbrakeErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
