import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  NgIterable,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatIcon, MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _isEqual from 'lodash/isEqual';
import { distinctUntilChanged } from 'rxjs/operators';

import { environment } from '@environments';
import { IMultiSelectOption } from './multi-select.model';
import { LocaleDatePipeModule } from '@common';
import { FormatDateService } from '@core/services';

@UntilDestroy()
@Component({
  selector: 'x-multi-select',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LocaleDatePipeModule,
    MatOptionModule,
    MatSelectModule,
    TranslocoModule,
    MatIconModule,
  ],
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent<T> implements OnInit, OnChanges, AfterViewInit {
  @Input() label = '';
  @Input() id = '';
  @Input() options: IMultiSelectOption<T>[] | string[] | number[] | readonly number[] | Date[] = [];
  @Input() control = new FormControl();
  @HostBinding('class.pointer-events-none')
  @Input()
  disabled?: boolean;
  @Input() value?;
  @Input() areStringOptionsAlsoTranslationKeys = false;
  @Input() isSelectAllAvailable = false;
  @Input() disabledValues: string[] | number[] = [];

  @Output() valueChange = new EventEmitter();
  @Output() openedChange = new EventEmitter<boolean>();

  @ViewChild('select', { static: false, read: ElementRef }) select: ElementRef;
  @ViewChild('chevronArrowDown', { static: false }) chevronArrowDownTemplate: TemplateRef<MatIcon>;

  selectAllControl = new FormControl(false);
  disabledValuesMap: Record<number | string, boolean> = {};

  private readonly assetsPath = environment.production ? '/static/assets' : 'assets';

  constructor(
    private readonly transloco: TranslocoService,
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private readonly formatDateService: FormatDateService
  ) {
    this.registerSelectIcon();
  }

  ngOnInit(): void {
    this.watchControlValueChanges();
    this.watchSelectAllChanges();
  }

  ngAfterViewInit(): void {
    this.renderProperMultiSelectStyles();
  }

  ngOnChanges({ value, options, disabledValues }: SimpleChanges): void {
    if (this.disabled !== undefined) {
      this.disabled ? this.control.disable() : this.control.enable();
    }

    if (value) {
      const newValue = value.currentValue || null;
      if (!_isEqual(newValue, this.control.value)) {
        this.control.patchValue(newValue, { emitEvent: false, onlySelf: true });
        this.setSelectAllControlValue();
        this.cdr.markForCheck();
      }
    }

    if (options && options.currentValue && !_isEqual(options.previousValue, options.currentValue)) {
      if (this.value) {
        this.control.patchValue(this.value, { emitEvent: false, onlySelf: true });
        this.setSelectAllControlValue();
        this.cdr.markForCheck();
      }
    }

    if (disabledValues?.currentValue?.length) {
      this.disabledValuesMap = disabledValues.currentValue.reduce(
        (obj: Record<number | string, boolean>, value: number | string) => {
          obj[value] = true;
          return obj;
        },
        {}
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getOptions(): NgIterable<any> {
    return this.options;
  }

  getOptionValue(option: IMultiSelectOption<unknown> | string | number): unknown | string | number {
    return this.getOptionProperty(option);
  }

  getOptionLabel(option: IMultiSelectOption<unknown> | string | number | Date): unknown | string | number {
    if (option instanceof Date) {
      return this.formatDateService.toLocaleString(option);
    } else {
      const translationKeyForLabel = option?.['translationKey'];
      return translationKeyForLabel
        ? this.transloco.translate(translationKeyForLabel)
        : typeof option === 'string' && this.areStringOptionsAlsoTranslationKeys
        ? this.transloco.translate(option)
        : this.getOptionProperty(option, 'label');
    }
  }

  setCheckboxCheckedClassInSelectAll(): void {
    const selectAllPseudoCheckboxEl = document.querySelector('.select-all .mat-pseudo-checkbox');
    if (selectAllPseudoCheckboxEl) {
      if (this.selectAllControl.value) {
        selectAllPseudoCheckboxEl.classList.add('mat-pseudo-checkbox-checked');
      } else {
        selectAllPseudoCheckboxEl.classList.remove('mat-pseudo-checkbox-checked');
      }
    }
  }

  trackByValue(_: number, option: IMultiSelectOption<unknown | string | number | Date>) {
    return option.value;
  }

  compareWithFn = (obj1, obj2) => {
    const toSimpleValue = (val) => (val instanceof Date ? val.toISOString() : val);
    return _isEqual(toSimpleValue(obj1), toSimpleValue(obj2));
  };

  private renderProperMultiSelectStyles(): void {
    const selectElement: HTMLElement = this.select.nativeElement;
    const arrowElement: HTMLElement = selectElement.querySelector('.mat-mdc-select-arrow')?.firstChild.parentElement;
    if (arrowElement) {
      const parentElement: HTMLElement = arrowElement.parentElement;
      const chevronArrowElement: HTMLElement = this.chevronArrowDownTemplate.createEmbeddedView(null).rootNodes[0];
      this.renderer.insertBefore(parentElement, chevronArrowElement, arrowElement);
      this.renderer.removeChild(parentElement, arrowElement);
      this.setSelectAllControlValue();
    }
  }

  private watchSelectAllChanges() {
    this.selectAllControl.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      if (value) {
        this.control.patchValue(
          typeof this.options[0] === 'object' ? [...this.options.map((item) => item.value), 0] : this.options,
          { emitEvent: false, onlySelf: true }
        );
      } else {
        this.control.patchValue([], { emitEvent: false, onlySelf: true });
      }
      this.setCheckboxCheckedClassInSelectAll();
    });
  }

  private getOptionProperty(
    option: IMultiSelectOption<unknown> | string | number | Date,
    property: 'value' | 'label' | 'translationKey' = 'value'
  ): unknown {
    if (option instanceof Date) {
      return option.toISOString();
    }

    return typeof option === 'string' || typeof option === 'number' ? option : option[property];
  }

  private registerSelectIcon(): void {
    this.matIconRegistry.addSvgIcon(
      'chevron-arrow-down',
      this.domSanitizer.bypassSecurityTrustResourceUrl(`${this.assetsPath}/icons/svg/chevron-arrow-down.svg`)
    );
  }

  private setSelectAllControlValue(): void {
    if (this.isSelectAllAvailable && this.control.value && this.options) {
      this.selectAllControl.patchValue(this.control.value?.length === this.options.length, {
        emitEvent: false,
        onlySelf: true,
      });
      this.setCheckboxCheckedClassInSelectAll();
    }
  }

  private watchControlValueChanges(): void {
    this.control.valueChanges.pipe(distinctUntilChanged(), untilDestroyed(this)).subscribe((value) => {
      if (!_isEqual(value, this.value)) {
        this.valueChange.emit(value);
        this.setSelectAllControlValue();
      }
    });
  }
}
