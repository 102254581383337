import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { environment } from '@environments';

@Component({
  selector: 'x-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent {
  @Input() type: string;
  @Input() inlineLogo: boolean;
  @Input() isExternalHorizontalView = false;
  @Input() itemsAlign: 'center' | 'end' = 'center';

  get links() {
    return environment?.links;
  }

  onAnchorClick(event: Event, link: string): void {
    event.stopPropagation();
    event.preventDefault();
    window.open(link, 'newwindow', 'width=600,height=800');
  }
}
