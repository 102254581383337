import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  inject,
  Input,
  Output,
} from '@angular/core';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

export type ButtonColor = 'primary' | 'secondary' | 'white' | 'danger' | 'transparent' | 'success';

@Component({
  selector: 'x-button',
  standalone: true,
  imports: [CommonModule, SvgIconModule],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ButtonComponent {
  @Input() color: ButtonColor = 'primary';
  @Input() muted = false;
  @Input() size: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full' = 'md';
  @Input() shapeType: 'circle' | 'square' = 'square';
  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @HostBinding('class.pointer-events-none')
  @Input()
  disabled = false;
  @Input() block = false;
  @Input() classes = '';
  @Input() active = false;
  @Input() title = '';
  @Input() iconButtonIconName: AvailableIcons | null = null;

  @Output() handleClick = new EventEmitter();
  readonly cdr = inject(ChangeDetectorRef);

  @HostBinding('title')
  get hostTitle(): string {
    return this.title || '';
  }
}
