import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import _map from 'lodash/map';
import saveAs from 'save-as';

import { Injectable } from '@angular/core';
import { Document, ExternalEntity, Report, ReportData } from '@core/models';
import { PORTAL_API_URL } from '@core/services';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  readonly reportSettingsUrl = `${PORTAL_API_URL}/admin/settings/reports`;
  readonly projectReportUrl = `${PORTAL_API_URL}/admin/projects`;

  constructor(private readonly http: HttpClient) {}

  exportReport(reportId: string, reportName?: string) {
    this.http
      .get(`${this.reportSettingsUrl}/${reportId}/template`)
      .pipe(take(1))
      .subscribe((reportTemplateObject) => {
        const jsonString = JSON.stringify(reportTemplateObject);
        const blob = new Blob([jsonString], { type: 'application/json' });
        saveAs(blob, `${reportName || reportId}-template`);
      });
  }

  importReport(templateName: string, report: Report) {
    return this.http.post(`${this.reportSettingsUrl}/import`, {
      name: templateName,
      trade_set_id: report.trade_set_id,
      type: report.type,
      template: report,
    });
  }

  getReports({ active }: Partial<{ active: boolean }> = {}): Observable<Report[]> {
    let params: HttpParams;
    if (active) {
      params = new HttpParams({
        fromObject: <never>(<Partial<Report>>{
          status: 'active',
        }),
      });
    }

    return this.http
      .get<Report[]>(this.reportSettingsUrl, {
        params,
      })
      .pipe(map((reports) => _map(reports, Report.transform)));
  }

  getProjectReports({ active }: Partial<{ active: boolean }> = {}): Observable<Report[]> {
    let params: HttpParams;
    if (active) {
      params = new HttpParams({
        fromObject: <never>(<Partial<Report>>{
          status: 'active',
        }),
      });
    }

    return this.http
      .get<Report[]>(`${PORTAL_API_URL}/admin/reports`, {
        params,
      })
      .pipe(map((reports) => _map(reports, Report.transform)));
  }

  getReport(id: string): Observable<Report> {
    if (!id) {
      return EMPTY;
    }

    return this.http.get<Report>(`${this.reportSettingsUrl}/${id}`).pipe(map(Report.transform));
  }

  createReport(report: Partial<Report>): Observable<Report> {
    return this.http.post<Report>(this.reportSettingsUrl, report).pipe(map(Report.transform));
  }

  copyReport(copy: { name: string; template_id: string }): Observable<Report> {
    const url = `${this.reportSettingsUrl}/copy`;
    return this.http.post<Report>(url, copy).pipe(map(Report.transform));
  }

  setReportStatus({ report_id, status }: { report_id: string; status: 'active' | 'inactive' }): Observable<void> {
    return this.http.post<void>(`${this.reportSettingsUrl}/${report_id}/status/${status}`, null);
  }

  deleteReport(report_id: string): Observable<void> {
    return this.http.delete<void>(`${this.reportSettingsUrl}/${report_id}`);
  }

  // PROJECT REPORTS
  assignReportToProject({ report_id, project_id }: { report_id: string; project_id: string }) {
    const url = `${this.projectReportUrl}/${project_id}/reports/${report_id}`;
    return this.http.post<void>(url, null);
  }

  removeReportFromProject({ report_id, project_id }: { report_id: string; project_id: string }) {
    const url = `${this.projectReportUrl}/${project_id}/reports/${report_id}`;
    return this.http.delete<void>(url);
  }

  getReportsForProject(project_id: string): Observable<Report[]> {
    if (!project_id) {
      return EMPTY;
    }

    return this.http
      .get<Report[]>(`${this.projectReportUrl}/${project_id}/reports`)
      .pipe(map((reports) => _map(reports, Report.transform)));
  }

  // REPORT CONFIGURATION
  getReportChapters(report_id: string): Observable<Report.Chapter[]> {
    if (!report_id) {
      return EMPTY;
    }
    return this.http
      .get<Report.Chapter[]>(`${this.reportSettingsUrl}/${report_id}/chapters`)
      .pipe(map((chapters) => _map(chapters, (chapter) => Report.Chapter.transform(chapter))));
  }

  createReportChapter(report_id: string, chapter: Report.Chapter): Observable<Report.Chapter> {
    return this.http
      .post<Report.Chapter>(`${this.reportSettingsUrl}/${report_id}/chapters`, <Report.Chapter>{
        ...chapter,
      })
      .pipe(map(Report.Chapter.transform));
  }

  updateReportChapter(report_id: string, chapter: Report.Chapter): Observable<Report.Chapter> {
    return this.http
      .put<Report.Chapter>(`${this.reportSettingsUrl}/${report_id}/chapters/${chapter.id}`, <Report.Chapter>{
        ...chapter,
      })
      .pipe(map(Report.Chapter.transform));
  }

  deleteReportChapter(report_id: string, chapter: Report.Chapter): Observable<void> {
    return this.http.delete<void>(`${this.reportSettingsUrl}/${report_id}/chapters/${chapter.id}`);
  }

  getChapterTypes(): Observable<string[]> {
    const url = `${this.reportSettingsUrl}/chapter-types`;
    return this.http.get<string[]>(url);
  }

  getReportTypes(): Observable<Document.Type[]> {
    const url = `${PORTAL_API_URL}/documents/types`;
    const params: HttpParams = <HttpParams>(<unknown>{
      filter: 'reports',
    });
    return this.http.get<Document.Type[]>(url, { params });
  }

  getReportExternalEntities(): Observable<ExternalEntity[]> {
    return this.http.get<ExternalEntity[]>(`${this.reportSettingsUrl}/external-entities`);
  }

  getReportNumFields(): Observable<ReportData[]> {
    return this.http.get<ReportData[]>(`${this.reportSettingsUrl}/num-fields`);
  }
}
