export * from './additional-module-label';
export * from './area-standard-label';
export * from './area-type-label';
export * from './asset-overview-label';
export * from './asset-type-label';
export * from './country-label';
export * from './deployment-label';
export * from './depth-survey-label';
export * from './document-label';
export * from './file-label';
export * from './finding-label';
export * from './frequency-label';
export * from './label-component';
export * from './label-portal-factory';
export * from './labels.module';
export * from './language-label';
export * from './monitoring-label';
export * from './number-of-objects-label';
export * from './permission-label';
export * from './portal-projects-label';
export * from './portal-tracker-label';
export * from './quantity-label';
export * from './report-label';
export * from './role-label';
export * from './timeframe-label';
export * from './user-label';
export * from './validation-error-label';
