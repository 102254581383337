import { inject, Injectable } from '@angular/core';
import { Group, Member, SSE } from '@core/models';
import { ResolvedDataSelectors } from '@portal/customer/state';
import { Store } from '@ngrx/store';
import { filter, withLatestFrom } from 'rxjs/operators';
import { isNotNil } from '@app/utils';
import { SSEService } from '@core/services';
import DeleteUser = SSE.EventData.DeleteUser;
import MoveUser = SSE.EventData.MoveUser;
import NewUser = SSE.EventData.NewUser;

export interface MembersCache {
  [projectId: string]: Member[];
}
export interface GroupsCache {
  [projectId: string]: Group[];
}

@Injectable({
  providedIn: 'root',
  deps: [Store],
})
export class UsersAndGroupsCacheService {
  private groupsCache: GroupsCache = {};
  private membersCache: MembersCache = {};
  private readonly store = inject(Store);
  private readonly sseService = inject(SSEService);

  constructor() {
    this.sseService.sseEvent$
      .pipe(
        withLatestFrom(this.store.select(ResolvedDataSelectors.getProjectId)),
        filter(isNotNil),
        filter(([event]: [SSE.EventData.GroupUpdated, string]) => event?.module === 'group')
      )
      .subscribe(([event, projectId]) => {
        delete this.groupsCache[projectId];
        if (event.action === 'delete') {
          delete this.membersCache[event.data.project_id];
        }
      });

    this.sseService.sseEvent$
      .pipe(
        filter(isNotNil),
        filter((event) => ['new_user', 'move_user', 'delete_user'].includes(event.type))
      )
      .subscribe((event: DeleteUser | MoveUser | NewUser) => {
        const relatedCacheKey = Object.values(this.groupsCache)
          .flat()
          .find((group) => group.id === event.data?.group_id)?.project_id;
        if (relatedCacheKey) {
          delete this.membersCache[relatedCacheKey];
        }
      });
  }

  setMembersCache(projectId: string, members: Member[]) {
    this.membersCache[projectId] = members;
  }

  getMembersFromCache(projectId: string): Member[] {
    return this.membersCache[projectId];
  }

  discardMembersCache(projectId: string) {
    delete this.membersCache[projectId];
  }

  setGroupsCache(projectId: string, groups: Group[]) {
    this.groupsCache[projectId] = groups;
  }

  getGroupsCache(projectId: string): Group[] {
    return this.groupsCache[projectId];
  }

  discardGroupsCache(projectId: string) {
    delete this.groupsCache[projectId];
  }
}
