export enum AppRoute {
  Portal = 'portal',
  Customer = 'customer',
  Admin = 'admin',
  Auth = 'auth',
  Reset = 'reset',
  EmailConfirm = 'email-confirm',
  ConfirmInvitation = 'confirm-invitation',
  Imprint = 'imprint',
  DataProtection = 'data-protection',
  Terms = 'terms',
  User = 'user',
  External = 'external',
}
