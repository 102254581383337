<ng-container [ngSwitch]="role">
  <span *ngSwitchCase="Role.Lead">{{ 'lead' | transloco }}</span>
  <span *ngSwitchCase="Role.Team">{{ 'team' | transloco }}</span>

  <span *ngSwitchCase="UserRole.External">{{ 'external' | transloco }}</span>
  <span *ngSwitchCase="UserRole.Internal">{{ 'internal' | transloco }}</span>
  <span *ngSwitchCase="UserRole.Manager">{{ 'manager' | transloco }}</span>
  <span *ngSwitchCase="UserRole.Root">{{ 'root' | transloco }}</span>
  <span *ngSwitchCase="'admin'">{{ 'admin' | transloco }}</span>
  <span *ngSwitchCase="'pm_pool'">{{ 'pm-pool' | transloco }}</span>

  <span *ngSwitchDefault>{{ role }}</span>
</ng-container>
