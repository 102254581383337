import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BadgeComponent, LabelType } from '@portal/components/badge/badge.component';
import { CellRendererComponent } from '@portal/components/grid/cell-renderer';

export interface BadgeCellRendererData {
  text: string | undefined;
  badgeType: LabelType;
  label?: string;
  classes?: string;
}

@Component({
  selector: 'x-badge-cell-renderer',
  standalone: true,
  imports: [BadgeComponent, NgIf],
  templateUrl: './badge-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeCellRendererComponent extends CellRendererComponent<BadgeCellRendererData> {}
