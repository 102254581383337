<ng-container [ngSwitch]="language">
  <ng-container *ngSwitchCase="Countries.Denmark">{{ 'danish' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.France">{{ 'french' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Germany">{{ 'german' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Italy">{{ 'italian' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Spain">{{ 'spanish' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.UnitedKingdom">{{ 'english' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.UnitedStates">{{ 'english' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ language }}</ng-container>
</ng-container>
