import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';

export type PhotoActionType = 'zoomIn' | 'zoomOut' | 'rotateLeft' | 'rotateRight' | 'reset' | 'fullscreen';

export interface PhotoAction {
  iconName: AvailableIcons;
  name: PhotoActionType;
}

export const PHOTO_ACTIONS: PhotoAction[] = [
  {
    name: 'zoomIn',
    iconName: 'plus',
  },
  {
    name: 'zoomOut',
    iconName: 'minus',
  },
  {
    name: 'rotateLeft',
    iconName: 'arrow-left',
  },
  {
    name: 'rotateRight',
    iconName: 'arrow-right',
  },
  {
    name: 'reset',
    iconName: 'reset',
  },
  {
    name: 'fullscreen',
    iconName: 'fullscreen',
  },
];
