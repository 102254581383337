import { getAllEnumValues } from './enum';

export enum AreaType {
  LettableArea = 'lettable_area',
  BuildingArea = 'building_area',
}

export namespace AreaType {
  export const all = getAllEnumValues<AreaType>(AreaType);
}
