import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

export type PriorityValue = 'high' | 'medium' | 'low';

@Component({
  selector: 'x-priority-indicator',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './priority-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriorityIndicatorComponent {
  @Input() priorityValue: PriorityValue;

  getCircleClass(colorClass: string): string {
    return `${!!colorClass ? `${colorClass} ${this.priorityValue}` : 'bg-gray-200 none'}`;
  }
}
