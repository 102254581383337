import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { API_URL, AuthService, TokenResponse } from '@core/services';
import { environment } from '@environments';
import { Router } from '@angular/router';
import { notNeededAuthEndpoints } from '@core/interceptors/stop-requests-after-logout.interceptor';

const refreshTokenRegex = new RegExp(`${API_URL}/${environment.portalApiUrl}/refresh-token`);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  private readonly authService: AuthService = inject(AuthService);
  private readonly router: Router = inject(Router);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const redirectUrl = window.location.pathname + window.location.search;
    const isConsidered =
      !refreshTokenRegex.test(request.url) && !notNeededAuthEndpoints.some((regex) => regex.test(request.url));

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const shouldHandleError =
          err.status === 401 && isConsidered && err.error?.errors?.[0]?.source !== 'terms_conditions';
        if (shouldHandleError && this.authService?.isUserAccessTokenValid()) {
          return this.authService.fetchNewAccessAndRefreshTokens().pipe(
            switchMap(({ token }: TokenResponse) => {
              const headers = request.headers.set('Authorization', `Bearer ${token}`);
              return next.handle(request.clone({ headers }));
            }),
            catchError((tokenError) => {
              if (decodeURIComponent(redirectUrl) !== '/customer/assets' && this.authService.isUserAccessTokenValid()) {
                this.router.navigateByUrl('/customer/assets');
                return of(null);
              } else {
                this.handleLogout(redirectUrl);
                return throwError(tokenError);
              }
            })
          );
        } else if (shouldHandleError && !this.authService?.isUserAccessTokenValid()) {
          this.handleLogout(redirectUrl);
        }
        return throwError(err);
      }),
      tap(() => {
        if (isConsidered) {
          this.authService.onActivity();
        }
      })
    );
  }

  private handleLogout(redirectUrl: string): void {
    this.authService.signOut(redirectUrl);
  }
}
