<div class="inline-flex rounded-md shadow-sm border border-gray-300 divide-x divide-gray-300">
  <span
    *ngFor="let option of options; let i = index; trackBy: trackByFn"
    (click)="optionClick.emit(option.id)"
    [title]="option.tooltip | transloco"
    [ngClass]="{
      'text-primary-600': selectedId === option.id,
      'rounded-l-lg': i === 0,
      'rounded-r-lg': i === options.length - 1
    }"
    class="flex items-center justify-center h-[40px] min-w-[45px] px-4 py-2 cursor-pointer text-sm font-medium text-primary-600 bg-white border-gray-300 rounded-l-lg hover:bg-gray-100 focus:ring-2 focus:ring-primary-600 focus:text-primary-600"
  >
    <x-svg-icon *ngIf="option.iconName" [svgImageName]="option.iconName" svgClass="w-4 h-4"></x-svg-icon>
    <span *ngIf="option.text">{{ option.text | transloco }}</span>
  </span>
</div>
