<ng-container *ngIf="imageData$ | async as imageData; else placeholder">
  <div
    [style.backgroundImage]="imageData | imageUrl | safeStyle"
    class="rounded-lg bg-cover bg-center bg-no-repeat w-full aspect-[16/10]"
    [ngClass]="imageClasses"
  ></div>
</ng-container>
<ng-template #placeholder>
  <div
    class="bg-gray-100 rounded-lg bg-cover bg-center bg-no-repeat w-full aspect-[16/10]"
    [ngClass]="imageClasses"
  ></div>
  <x-spinner class="position-center"></x-spinner>
</ng-template>
