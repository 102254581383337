import { Component, Input } from '@angular/core';
import { Project, User } from '@core/models';

@Component({
  selector: 'x-role-label',
  templateUrl: './role-label.component.html',
})
export class RoleLabelComponent {
  // eslint-disable-line
  @Input()
  role: Project.Admin.Role | User.AdminRole;

  readonly Role = Project.Admin.Role;
  readonly UserRole = User.AdminRole;
}
