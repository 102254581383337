import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { RouterModule } from '@angular/router';
import { IconComponentModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { GridModule, HeaderCellComponent } from '../grid';
import { PortalDocumentWrapperComponent } from './portal-document-wrapper.component';
import { PortalDocumentTagModule } from './portal-document/portal-document-tag';
import { PortalDocumentModule } from './portal-document/portal-document.module';
import { UploadPreviewModule } from './upload-preview/upload-preview.module';

@NgModule({
  imports: [
    RouterModule.forChild([
      {
        path: '',
        component: PortalDocumentWrapperComponent,
      },
    ]),
    CommonModule,
    GridModule,
    PortalDocumentModule,
    AgGridModule.withComponents([HeaderCellComponent]),
    PortalDocumentTagModule,
    TranslocoModule,
    MatButtonModule,
    IconComponentModule,
    MatIconModule,
    MatSelectModule,
    UploadPreviewModule,
  ],
  declarations: [PortalDocumentWrapperComponent],
  exports: [PortalDocumentModule, PortalDocumentWrapperComponent],
})
export class PortalDocumentWrapperModule {}
