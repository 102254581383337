import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ISelectOption } from '@common/components/select/select.model';
import { Group } from '@core/models';

export interface QAForwardDialogParams {
  groups: Group[];
}

@Component({
  templateUrl: './qa-forward-dialog.component.html',
  styleUrls: ['./qa-forward-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QAForwardDialogComponent implements AfterViewInit {
  selectedGroup: string;
  groupOptions: ISelectOption<string>[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QAForwardDialogParams,
    public dialogRef: MatDialogRef<QAForwardDialogParams, string>,
    private cdr: ChangeDetectorRef
  ) {
    this.groupOptions = this.getGroupOptions(data.groups);
    if (data?.groups?.length === 1) {
      this.selectedGroup = this.groupOptions[0].value;
    }
    this.cdr.markForCheck();
  }

  ngAfterViewInit() {
    this.groupOptions = this.getGroupOptions(this.data.groups);
  }

  getGroupOptions(groups: Group[]): ISelectOption<string>[] {
    return groups.map((group) => ({ value: group.id, label: group.name }));
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit(): void {
    this.dialogRef.close(this.selectedGroup);
  }
}
