<div class="controls-container" *transloco="let t; read: 'qa-overview'">
  <div class="flex flex-row items-center">
    <p class="text-3xl font-bold text-black mr-8">{{ 'q-a' | transloco }}</p>
  </div>

  <div class="actions-container align-center space-x-3">
    <x-search-input [control]="filters.controls['search_term']"></x-search-input>
    <ng-container *ngIf="filterEnabled">
      <x-filters-dropdown
        [activeFiltersCount]="activeFiltersCount$ | async"
        (resetFilters)="filters.reset(initialFiltersValue)"
        customTitle="{{ 'filters' | transloco }}"
      >
        <x-filter-group [title]="'view' | transloco" [expanded]="true">
          <x-select
            [options]="(filterOptions$ | async)?.adapted"
            [control]="filters.controls['adapted']"
            [withEmptyOption]="false"
          ></x-select>
        </x-filter-group>
        <x-filter-group [title]="'scope' | transloco">
          <x-select
            [options]="(filterOptions$ | async)?.scope"
            [control]="filters.controls['scope']"
            [withEmptyOption]="true"
          ></x-select>
        </x-filter-group>
        <x-filter-group [title]="'status' | transloco">
          <x-select
            [options]="(filterOptions$ | async)?.status"
            [control]="filters.controls['status']"
            [withEmptyOption]="true"
          ></x-select>
        </x-filter-group>
        <x-filter-group [title]="'priority' | transloco">
          <x-select
            [options]="(filterOptions$ | async)?.priority"
            [control]="filters.controls['priority']"
            [withEmptyOption]="true"
          ></x-select>
        </x-filter-group>
        <x-filter-group [title]="'questioner' | transloco" *ngIf="hasUserViewPermission">
          <x-select
            [options]="(filterOptions$ | async)?.questioner_group"
            [control]="filters.controls['questioner_group']"
            [withEmptyOption]="true"
          ></x-select>
        </x-filter-group>
        <x-filter-group [title]="'created-at' | transloco">
          <x-select
            [options]="(filterOptions$ | async)?.time_frame"
            [control]="filters.controls['time_frame']"
            [withEmptyOption]="true"
          ></x-select>
          <ng-container *ngIf="filters.controls['time_frame'].value === 'custom'">
            <ng-container *ngTemplateOutlet="dateRangeTemplate"></ng-container>
          </ng-container>
        </x-filter-group>
      </x-filters-dropdown>
    </ng-container>
    <ng-container *ngIf="exportEnabled">
      <x-button
        class="self-baseline"
        [disabled]="isSingleQuestionSelected && !exportEnabled"
        [matMenuTriggerFor]="actionsMenu"
        [matMenuTriggerData]="{ data: selectedNodes }"
        color="white"
        [block]="true"
      >
        <div class="flex justify-between w-full gap-4 align-center">
          <span class="ml-1">{{ 'actions' | transloco }}</span>
          <x-svg-icon svgImageName="chevron-arrow-down"></x-svg-icon>
        </div>
      </x-button>

      <mat-menu #actionsMenu>
        <ng-template matMenuContent let-data="data">
          <div class="divide-y divide-gray-300">
            <div>
              <button
                mat-menu-item
                (click)="onExportQuestions()"
                [title]="!hasSelectedNodes ? ('actions-button-disabled-info' | transloco) : ''"
              >
                {{ t('export') | titlecase }}
              </button>
            </div>
            <div *ngIf="!frozen && (canWriteTicket$ | async)">
              <button [disabled]="frozen || isSingleQuestionSelected" (click)="onAddTicketToQa(data)" mat-menu-item>
                <span>{{ 'asset_menu.add_ticket' | transloco }}</span>
              </button>
            </div>
          </div>
        </ng-template>
      </mat-menu>
    </ng-container>
    <x-button
      *ngIf="addQuestionEnabled && canWrite"
      color="primary"
      [block]="true"
      (handleClick)="onAddQuestion()"
      [disabled]="frozen"
      [matTooltip]="frozen ? ('asset-frozen-state-info' | transloco) : ''"
    >
      <x-svg-icon svgImageName="plus" svgClass="w-4 h-4 text-white"></x-svg-icon>
      <span class="pl-2 capitalize">{{ 'question' | transloco }}</span>
    </x-button>
  </div>
</div>
<div class="grid-container">
  <ag-grid-angular
    #grid
    class="ag-theme-xportal"
    [gridOptions]="gridOptions"
    [defaultColDef]="defaultColDef"
    [modules]="modules"
    [columnDefs]="columnDefs"
    [rowData]="questions"
    [suppressScrollOnNewData]="true"
  ></ag-grid-angular>

  <x-columns-panel
    [columns]="settingsColumns"
    [fieldTemplate]="qaLabelTemplate"
    (panelOpened)="onPanelOpened()"
    (toggleVisibility)="onToggleColumnVisibility($event)"
  ></x-columns-panel>

  <x-spinner-overlay [isLoading]="isLoading$ | async"></x-spinner-overlay>
</div>

<ng-template #qaLabelTemplate let-field>
  <x-qa-label [fieldName]="field"></x-qa-label>
</ng-template>

<ng-template #dateRangeTemplate>
  <div class="date-range">
    <mat-date-range-input [rangePicker]="picker">
      <input
        matInput
        matStartDate
        [(ngModel)]="startDate"
        placeholder="{{ 'start-date' | transloco }}"
        (dateChange)="onDateChange({ date_from: formatDateService.format($event.value) })"
        (click)="picker.open()"
      />
      <input
        matInput
        matEndDate
        [(ngModel)]="endDate"
        placeholder="{{ 'end-date' | transloco }}"
        (dateChange)="onDateChange({ date_to: formatDateService.format($event.value) })"
        (click)="picker.open()"
      />
    </mat-date-range-input>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </div>
</ng-template>

<ng-template let-message #qaFieldsTemplate>
  <x-qa-details-dialog-fields [message]="message"></x-qa-details-dialog-fields>
</ng-template>
