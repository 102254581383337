import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input } from '@angular/core';
import { LocaleDatePipeModule } from '@common';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { Message } from '@core/models';
import { TranslocoModule } from '@ngneat/transloco';
import { CellRendererComponent } from '@portal/components';

@Component({
  selector: 'x-due-date-cell-renderer',
  standalone: true,
  imports: [CommonModule, SvgIconModule, LocaleDatePipeModule, TranslocoModule],
  templateUrl: './due-date-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DueDateCellRendererComponent extends CellRendererComponent<Date> {
  @Input() message: Message;
  @Input() hideIcons: boolean;

  private _date: Date;
  @Input() set date(date: string | Date) {
    this._date = typeof date === 'string' ? new Date(date) : date;
  }
  get date(): Date {
    return this._date;
  }

  get dueDate() {
    const ticket = (this.params?.message as Message) || this.message;
    return (
      this.value ||
      this.date ||
      ticket?.grace_period_third ||
      ticket?.grace_period_second ||
      ticket?.grace_period_first ||
      ticket?.due_date
    );
  }

  readonly cdr = inject(ChangeDetectorRef);

  getGracePeriodNumber(ticket: Message) {
    return ticket?.grace_period_third || ticket?.last_extension_at
      ? 3
      : ticket?.grace_period_second
      ? 2
      : ticket?.grace_period_first
      ? 1
      : 0;
  }

  isDueDateMissed() {
    const ticket = (this.params?.data as Message) || this.message;
    const isOpen = !ticket?.is_final_step;
    const dueDate = new Date(this.dueDate);
    dueDate?.setHours(23, 59, 59, 59);

    const isAfterCurrentDate = new Date() > dueDate;
    const isAfterResponseApproveTicketDate = new Date(ticket?.response_approved_at) > dueDate;

    return (isOpen && isAfterCurrentDate) || (!isOpen && isAfterResponseApproveTicketDate);
  }
}
