import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipeModule } from '@common';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { PanoramaViewerModule } from '../panorama-viewer';
import { ImageViewerComponent } from './image-viewer.component';
import { VideoViewerComponent } from '@portal/components/video-viewer/video-viewer.component';

@NgModule({
  imports: [CommonModule, SafePipeModule, PanoramaViewerModule, VideoViewerComponent, SvgIconModule],
  declarations: [ImageViewerComponent],
  exports: [ImageViewerComponent],
})
export class ImageViewerModule {}
