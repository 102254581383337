import { ITooltipAngularComp } from '@ag-grid-community/angular';
import { ITooltipParams } from '@ag-grid-community/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { AlertType } from '@common';
import { StorageService } from '@core/services';
import { DialogActions } from '@core/state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { isNotNil } from 'src/app/utils';

export interface ImagePreviewTooltipParams extends ITooltipParams {
  newDownloadTokenGetter: () => Observable<string>;
}

@Component({
  selector: 'x-image-preview-tooltip',
  templateUrl: './image-preview-tooltip.component.html',
  styleUrls: ['./image-preview-tooltip.component.scss'],
})
export class ImagePreviewTooltipComponent implements ITooltipAngularComp {
  readonly downloadToken$ = new BehaviorSubject<string | undefined>(undefined);
  readonly imageData$ = this.downloadToken$.pipe(
    filter(isNotNil),
    switchMap((download_token) =>
      this.storageService.getImageWithDownloadToken({ download_token }).pipe(
        map((blob) => window.URL.createObjectURL(blob)),
        tap(() =>
          setTimeout(() => {
            this.cdr.detectChanges();
          }, 10)
        ),
        catchError((httpError: HttpErrorResponse) => {
          this.store.dispatch(
            DialogActions.showAlert({
              data: {
                type: AlertType.Error,
                error: httpError.error,
              },
            })
          );

          return of(null);
        })
      )
    )
  );

  params?: ImagePreviewTooltipParams;

  constructor(private storageService: StorageService, private cdr: ChangeDetectorRef, private store: Store<unknown>) {}

  refresh(params: ImagePreviewTooltipParams): boolean {
    this.downloadImage();
    return true;
  }

  agInit(params: ImagePreviewTooltipParams): void {
    this.params = params;
    this.downloadImage();
  }

  private downloadImage() {
    if (!this.params?.newDownloadTokenGetter) {
      return;
    }

    this.params
      .newDownloadTokenGetter()
      .pipe(
        take(1),
        map((token) => this.downloadToken$.next(token)),
        catchError((httpError: HttpErrorResponse) => {
          this.store.dispatch(
            DialogActions.showAlert({
              data: {
                type: AlertType.Error,
                error: httpError.error,
              },
            })
          );

          return throwError(httpError);
        })
      )
      .subscribe();
  }
}
