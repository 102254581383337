import { Directive, ElementRef, Renderer2, HostListener, AfterViewInit, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[xResizableColumn]',
  standalone: true,
})
export class ResizableColumnDirective implements AfterViewInit {
  @Output() columnResizeStart = new EventEmitter();
  @Output() columnResizeEnd = new EventEmitter();

  private startX: number;
  private startWidth: number;
  private header: HTMLElement;
  private handle: HTMLElement;
  private table: HTMLElement;
  private styleElement: HTMLStyleElement;
  private uniqueClassName: string;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    this.header = this.el.nativeElement;
    this.table = this.header.closest('.flat-tree-container');
    this.handle = this.renderer.createElement('span');
    this.renderer.addClass(this.handle, 'resize-handle');
    this.renderer.appendChild(this.header, this.handle);
    this.uniqueClassName = this.generateUniqueClassName();
    this.renderer.addClass(this.table, this.uniqueClassName);
    this.styleElement = this.renderer.createElement('style');
    this.renderer.appendChild(this.table, this.styleElement);
  }

  @HostListener('mousedown', ['$event'])
  onMouseDown(event: MouseEvent): void {
    if (event.target === this.handle) {
      this.columnResizeStart.emit();
      this.startX = event.pageX;
      this.startWidth = this.header.offsetWidth;
      document.addEventListener('mousemove', this.onMouseMove);
      document.addEventListener('mouseup', this.onMouseUp);
    }
  }

  private onMouseMove = (event: MouseEvent): void => {
    const dx = event.pageX - this.startX;
    const newWidth = this.startWidth + dx;
    const columnWidth = this.header.clientWidth;
    const columnClass = this.header.classList[1];
    this.setColumnWidth(newWidth);
    this.setLocalColumnWidth(columnClass, columnWidth);
  };

  private onMouseUp = (): void => {
    document.removeEventListener('mousemove', this.onMouseMove);
    document.removeEventListener('mouseup', this.onMouseUp);
    const columnWidth = this.header.clientWidth;
    this.columnResizeEnd.emit(columnWidth);
  };

  private setColumnWidth(width: number) {
    this.renderer.setStyle(this.header, 'width', `${width}px`);
    this.renderer.setStyle(this.header, 'flex-basis', `${width}px`);
  }

  private setLocalColumnWidth(columnClass: string, width: number) {
    // Use the unique class name to scope the styles
    this.styleElement.innerHTML = `
      .${this.uniqueClassName} .tree-node-container .${columnClass} {
        width: ${width}px !important;
        flex-basis: ${width}px !important;
      }
    `;
  }

  private generateUniqueClassName(): string {
    return 'resizable-column-container-' + Math.random().toString(36).slice(2, 11);
  }
}
