import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import {
  CallPipeModule,
  DocumentDetailsPipeModule,
  DocumentPhotoPipeModule,
  ImageUrlPipeModule,
  SafePipeModule,
} from '@common';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { TranslocoModule } from '@ngneat/transloco';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { DocumentsPreviewModule } from '@portal/components/documents-preview/documents-preview.module';
import { FindingPreviewModule } from '@portal/customer/components/report-preview/directives/finding-preview';
import { SpinnerModule } from 'src/app/common/components/spinner';
import { QaReferenceComponent } from './qa-reference.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    TranslocoModule,
    SafePipeModule,
    ImageUrlPipeModule,
    SpinnerModule,
    FindingPreviewModule,
    DocumentDetailsPipeModule,
    DocumentPhotoPipeModule,
    DocumentsPreviewModule,
    SvgIconModule,
    BadgeComponent,
    CallPipeModule,
    MatTooltipModule,
  ],
  declarations: [QaReferenceComponent],
  exports: [QaReferenceComponent],
})
export class QaReferenceModule {}
