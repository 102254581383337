import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { PermissionLabelComponent } from './permission-label.component';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [PermissionLabelComponent],
  exports: [PermissionLabelComponent],
})
export class PermissionLabelModule {}
