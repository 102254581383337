import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Assessment, Transform } from '@core/models';
import { PORTAL_API_URL } from '@core/services';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface Params {
  asset_id: string;
  node_id: string;
  assessment_id?: string;
  new_assessment?: Pick<
    Assessment,
    'completeness' | 'up_to_date' | 'risk' | 'completeness_comment' | 'up_to_date_comment' | 'risk_comment'
  >;
}

@Injectable()
export class AssessmentService {
  constructor(private http: HttpClient) {}

  deleteAssessment({ asset_id, node_id, assessment_id }: Params) {
    return this.http.delete<void>(`${PORTAL_API_URL}/assets/${asset_id}/nodes/${node_id}/assessments/${assessment_id}`);
  }

  createAssessment({ asset_id, node_id, new_assessment }: Params): Observable<Assessment> {
    return this.http
      .post<Assessment>(`${PORTAL_API_URL}/assets/${asset_id}/nodes/${node_id}/assessments`, new_assessment)
      .pipe(
        map((assessment) => ({
          ...assessment,
          created_at: Transform.date(assessment.created_at),
          updated_at: Transform.date(assessment.updated_at),
        }))
      );
  }
}
