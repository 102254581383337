import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BadgeComponent, LabelType } from '@portal/components/badge/badge.component';
import { Risk } from '@core/models';
import { RiskLabelModule } from '@common/components/labels/risk-label';

@Component({
  selector: 'x-risk-pill',
  standalone: true,
  imports: [CommonModule, BadgeComponent, RiskLabelModule],
  templateUrl: './risk-pill.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      :host {
        font-weight: 600;
      }
    `,
  ],
})
export class RiskPillComponent {
  @Input() value: Risk;

  getLabelType(risk: Risk): LabelType {
    switch (risk) {
      case Risk.Critical:
        return 'danger_dark';
      case Risk.High:
        return 'danger';
      case Risk.AboveAverage:
        return 'warning_dark';
      case Risk.Medium:
        return 'warning';
      case Risk.Low:
        return 'success_dark';
      case Risk.VeryLow:
        return 'success';
    }
  }
}
