import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DestroyComponent } from '@common';
import { Document } from '@core/models';
import { ActivityStatisticsService, StorageService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';
import { Viewer } from 'photo-sphere-viewer';
import { of, timer } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { SimpleChanges } from 'src/custom-typings/angular';
import { TokenGetter } from '@portal/customer/components/asset-details/asset-photo-viewer/asset-photo-viewer-container/asset-photo-viewer-container.model';

@Component({
  selector: 'x-panorama-viewer',
  templateUrl: './panorama-viewer.component.html',
  styleUrls: ['./panorama-viewer.component.scss'],
})
export class PanoramaViewerComponent extends DestroyComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() photos?: Document.ListItem[];
  @Input() selectedDocument?: Document.ListItem;
  @Input() active = false;
  @Input() newDownloadTokenGetter?: TokenGetter;

  @Output() selectedDocumentChange = new EventEmitter<Document.ListItem>();

  @ViewChild('panoramaViewer') panoramaViewerElement?: ElementRef<HTMLElement>;

  panoramaImagesDict: Dictionary<string> = {};
  panoramaViewer?: PhotoSphere;
  tooltip?: PhotoSphere.Tooltip;
  currentPanorama?: string;

  constructor(
    private storageService: StorageService,
    private readonly transloco: TranslocoService,
    private readonly activityService: ActivityStatisticsService
  ) {
    super();
  }

  ngOnChanges({ photos, selectedDocument, active }: SimpleChanges<PanoramaViewerComponent>): void {
    if (photos) {
      const panoramaPhotos = _(photos.currentValue as Document.ListItem[])
        .groupBy(({ id }) => id)
        .mapValues(() => null)
        .value();
      const oldPhotos = _.pick(this.panoramaImagesDict, Object.keys(panoramaPhotos));
      this.panoramaImagesDict = { ...oldPhotos, ...panoramaPhotos };

      this.panoramaViewer?.navbar?.setButtons(this.getNavbar());
      this.panoramaViewer?.navbar?.refreshUi();
    }

    if (selectedDocument && selectedDocument.currentValue) {
      this.downloadImage(selectedDocument.currentValue);
    }

    if (active && active.currentValue) {
      this.createPanorama();
    }
  }

  ngAfterViewInit() {
    this.createPanorama();
  }

  downloadImage(document: Document.ListItem): void {
    this.getPhoto(document);
  }

  getPhoto(photo: Document.ListItem | undefined): void {
    if (!photo) {
      return;
    }

    this.panoramaViewer?.loader?.show();
    this.newDownloadTokenGetter?.(photo.id, photo.asset_id)
      .pipe(
        mergeMap((download_token) =>
          this.storageService.downloadDocument({ download_token }).pipe(map((blob) => window.URL.createObjectURL(blob)))
        ),
        switchMap((objectUrl: string) =>
          this.activityService
            .trackActivity(photo.asset_id, { scope: 'photo', document_id: photo.id, action: 'view' })
            .pipe(
              map(() => objectUrl),
              catchError(() => of(objectUrl))
            )
        )
      )
      .subscribe({
        next: (objectUrl) => {
          this.panoramaImagesDict[photo.id] = objectUrl;

          this.panoramaViewer?.loader?.hide();
          this.setPanorama(objectUrl);
        },
        error: (error) => {
          console.error(`Couldn't load an image: `, error);
          this.panoramaViewer?.loader?.hide();
        },
      });
  }

  setPanorama(url?: string): void {
    if (!this.panoramaViewer) {
      return;
    }

    const images = _.values(this.panoramaImagesDict).filter((image) => !!image);
    url = url || images[0];
    if (!url || _.isEmpty(url)) {
      return;
    }

    this.currentPanorama = url;
    this.panoramaViewer.setPanorama(url as PhotoSphere.Type);
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();

    this.panoramaViewer?.navbar?.destroy();
    this.panoramaViewer?.destroy();
    this.panoramaViewer = null;
  }

  onNext(): void {
    const images = _.values(this.panoramaImagesDict);
    const index = Math.min(images.indexOf(this.currentPanorama) + 1, images.length - 1);
    const url = images[index];

    this.selectedDocumentChange.emit(this.photos?.[index]);

    if (!url) {
      this.getPhoto(this.photos?.[index]);
      return;
    } else if (url === this.currentPanorama) {
      return;
    }
    this.setPanorama(url);
  }

  onPrevious(): void {
    const images = _.values(this.panoramaImagesDict).filter((image) => !!image);
    const index = Math.max(images.indexOf(this.currentPanorama) - 1, 0);
    const url = images[index];

    this.selectedDocumentChange.emit(this.photos?.[index]);

    if (!url) {
      this.getPhoto(this.photos?.[index]);
      return;
    } else if (url === this.currentPanorama) {
      return;
    }
    this.setPanorama(url);
  }

  createPanorama(): void {
    if (!this.panoramaViewerElement || !this.active || this.panoramaViewer) {
      return;
    }

    timer(300)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        if (!this.panoramaViewerElement || !this.active || this.panoramaViewer) {
          return;
        }

        const images = _.values(this.panoramaImagesDict).filter((image) => !!image);
        const panorama = _.head(images);
        this.currentPanorama = panorama;
        this.panoramaViewer = new Viewer({
          container: this.panoramaViewerElement.nativeElement,
          panorama,
          navbar: this.getNavbar(),
          // FIXME : remove as unknown as PhotoSphere
        }) as unknown as PhotoSphere;
        this.createTooltip();
        this.panoramaViewer?.loader?.show();
      });
  }

  getNavbar() {
    /**
     * t(previous)
     * t(next)
     * t(close)
     */
    return [
      'autorotate',
      'zoom',
      ...(this.photos?.length < 2
        ? []
        : [
            {
              id: 'previous-button',
              content: '←',
              title: this.transloco.translate(`previous`),
              className: 'custom-button prev',
              onClick: () => this.onPrevious(),
            },
          ]),
      ...(this.photos?.length < 2
        ? []
        : [
            {
              id: 'next-button',
              content: '→',
              title: this.transloco.translate(`next`),
              className: 'custom-button next',
              onClick: () => this.onNext(),
            },
          ]),
      'fullscreen',
      {
        id: 'close-button',
        content: 'x',
        title: this.transloco.translate(`close`),
        className: 'custom-button close',
        onClick: () => this.close(),
      },
    ];
  }

  createTooltip(): void {
    if (!this.panoramaViewerElement) {
      return;
    }

    /**
     * t(panorama-tooltip)
     */
    const tooltipContent = this.transloco.translate(`panorama-tooltip`);
    const panoramaRect = this.panoramaViewerElement?.nativeElement.getBoundingClientRect();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.tooltip = (<any>this.panoramaViewer).tooltip.create({
      content: tooltipContent,
      top: Math.floor(panoramaRect.height / 2),
      left: Math.floor(panoramaRect.width / 2),
      position: 'bottom center',
    });

    timer(5000)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.tooltip?.destroy();
      });
  }

  private close(): void {
    this.panoramaViewer?.exitFullscreen();
    this.selectedDocumentChange.emit(null);
  }
}
