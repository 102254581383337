export type FileType =
  | 'archive'
  | 'code'
  | 'excel'
  | 'folder'
  | 'heic'
  | 'image'
  | 'pdf'
  | 'powerpoint'
  | 'sql'
  | 'text'
  | 'tiff'
  | 'unknown'
  | 'video'
  | 'word'
  | 'placeholder'
  | 'visio';

export const getFileTypeFromExtension = (extension?: string): FileType => {
  switch (extension?.toLowerCase()) {
    case 'txt':
      return 'text';

    case 'doc':
    case 'docx':
      return 'word';

    case 'xls':
    case 'xlsx':
    case 'csv':
      return 'excel';

    case 'png':
    case 'jpeg':
    case 'jpg':
    case 'gif':
      return 'image';

    case 'mov':
    case 'mp4':
    case 'avi':
      return 'video';

    case 'zip':
    case '7zip':
    case 'tar':
    case 'tgz':
      return 'archive';

    case 'pdf':
      return 'pdf';

    case 'sql':
      return 'sql';

    case 'js':
      return 'code';

    case 'tif':
    case 'tiff':
      return 'tiff';

    case 'ppt':
    case 'odp':
    case 'pptx':
    case 'ppsx':
      return 'powerpoint';

    case 'heic':
      return 'heic';

    case 'vdx':
    case 'vsd':
    case 'vsdm':
    case 'vsdx':
      return 'visio';

    case 'folder':
      return 'folder';

    case 'placeholders':
      return 'placeholder';
  }

  return 'unknown';
};

const typeIconRecord: Record<FileType, string> = {
  archive: 'zip',
  excel: 'excel',
  image: 'png',
  pdf: 'pdf',
  text: 'txt',
  unknown: 'unknown',
  video: 'mp4',
  word: 'word',
  sql: 'sql',
  code: 'code',
  tiff: 'tiff',
  powerpoint: 'powerpoint',
  heic: 'heic',
  folder: 'folder',
  placeholder: 'placeholder',
  visio: 'visio',
};

const availableExtensions: string[] = [
  'csv',
  'dwg',
  'gif',
  'jpg',
  'mp4',
  'png',
  'ppt',
  'txt',
  'zip',
  'sql',
  'code',
  'tiff',
  'folder',
  'heic',
  'visio',
];

const extensionIconRecord: Record<string, string> = availableExtensions.reduce((acc, ext) => {
  acc[ext] = ext;
  return acc;
}, {} as Record<string, string>);

export const getFileIconFromType = (fileType: FileType, extension?: string, multiple?: boolean): string => {
  const icon = (extension && extensionIconRecord[extension]) || typeIconRecord[fileType];
  const fileIcon = icon || 'unknown';
  return multiple ? `${fileIcon}-multiple` : fileIcon;
};

export const getFileExtension = (filename: string): string | null => {
  const dotIndex = filename.lastIndexOf('.');
  if (dotIndex !== -1) {
    return filename.substring(dotIndex + 1).toLowerCase();
  }
  return null;
};
