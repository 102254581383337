<nav class="flex relative">
  <div class="flex flex-1 items-center align-center justify-between gap-2 flex-shrink-0" [ngClass]="classes">
    <a
      *ngIf="headerLogoUrl$ | async as logo; else defaultLogo"
      style="max-width: 12rem"
      class="logo flex-1 h-14 w-14"
      [routerLink]="''"
      [ngStyle]="{ 'background-image': 'url(' + logo + ')' }"
      [ngClass]="logoClasses"
    ></a>
    <ng-template #defaultLogo>
      <a
        style="max-width: 10rem"
        [class]="'logo flex-1 h-12 w-12' + logoClasses"
        [ngClass]="{ '!h-14 !w-14': (headerLogoUrl$ | async) }"
        [routerLink]="''"
        [ngStyle]="{ 'background-image': 'url(' + doreeLogoUrl + ')' }"
      ></a>
    </ng-template>
    <x-notifications *ngIf="showNotifications"></x-notifications>
  </div>
</nav>
