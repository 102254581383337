import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Asset, AssetOverview, AssetSettings, Disclaimer, Group, Permission, Transform } from '@core/models';
import { TermsConditions } from '@core/models/terms-conditions';
import { PORTAL_API_URL, StorageService } from '@core/services';
import { AssignmentType } from '@portal/admin/services';
import { ImageSizeVersion } from '@portal/customer/services/customer-documents.service';
import { DownloadTokenAction } from '@portal/services/documents.service';
import * as _ from 'lodash';
import _mapValues from 'lodash/mapValues';
import _sortBy from 'lodash/sortBy';
import _uniq from 'lodash/uniq';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { BasicSettings, LogoType } from '../components';
import { TermsConditionsValidateService } from './terms-conditions-validate.service';

@Injectable({
  providedIn: 'root',
})
export class CustomerAssetsService {
  protected readonly customerApiUrl = `${PORTAL_API_URL}/assets`;
  protected readonly disclaimerUrl = '/disclaimer';

  assetsOverview$: BehaviorSubject<AssetOverview[]> = new BehaviorSubject<AssetOverview[]>([]);

  constructor(
    private http: HttpClient,
    private termsConditions: TermsConditionsValidateService,
    private storageService: StorageService
  ) {}

  getAssetSettings(assetId: string): Observable<AssetSettings> {
    return this.http.get<AssetSettings>(`${this.assetsApiUrl(assetId)}/settings`).pipe(
      map((settings) => {
        settings = {
          ...settings,
          asset: Asset.Details.transform(settings.asset),
          group: Group.transform(settings.group),
          permission: _mapValues(settings.permission, (keys) => _uniq(keys)) as Permission,
        };

        return settings;
      })
    );
  }

  clearAssetList() {
    this.assetsOverview$.next([]);
    this.storageService.imagesCache.clear();
  }

  updateAssetOverview(asset_id: string, asset_name: string, assetStatus?: Asset.Status) {
    const assetIndexToUpdate = this.assetsOverview$.value?.findIndex(
      (assetOverview) => assetOverview.asset_id === asset_id
    );
    const updatedOverviewAssets = this.assetsOverview$.getValue();
    if (assetIndexToUpdate !== -1 && assetStatus) {
      updatedOverviewAssets[assetIndexToUpdate].asset_status = assetStatus;
      this.assetsOverview$.next(updatedOverviewAssets);
      return;
    }
    this.fetchAllCustomerAssets({ search_term: asset_name })
      .pipe(take(1))
      .subscribe((assetsOverviews: AssetOverview[]) => {
        if (assetIndexToUpdate !== -1 && !!assetsOverviews.length) {
          updatedOverviewAssets[assetIndexToUpdate] = assetsOverviews[0];
          this.assetsOverview$.next(updatedOverviewAssets);
          this.storageService.removeItemFromCache(asset_id);
        } else {
          updatedOverviewAssets.push(assetsOverviews[0]);
          this.assetsOverview$.next(_sortBy(updatedOverviewAssets, (assetOverview) => assetOverview.asset_name));
        }
      });
  }

  getAssetDetails(assetId: string): Observable<Asset.Details> {
    return this.http
      .get<Asset.Details>(`${this.assetsApiUrl(assetId)}`)
      .pipe(map((details) => Asset.Details.transform(details)));
  }

  fetchCustomerAssets(params: Asset.OverviewParams): Observable<AssetOverview[]> {
    return this.http
      .get<AssetOverview[]>(`${this.customerApiUrl}`, {
        params: <HttpParams>_(params).omitBy(_.isNil).value(),
      })
      .pipe(this.termsConditions.validateGlobalTermsConditions.bind(this.termsConditions));
  }

  fetchAllCustomerAssets(filter?: Asset.Filter): Observable<AssetOverview[]> {
    return this.http
      .get<AssetOverview[]>(`${this.customerApiUrl}`, {
        params: <HttpParams>filter || {},
      })
      .pipe(this.termsConditions.validateGlobalTermsConditions.bind(this.termsConditions));
  }

  getAssetPhotoDownloadToken(
    { asset_id }: { asset_id: string },
    { version, action }: { version?: ImageSizeVersion; action?: DownloadTokenAction } = {}
  ): Observable<string> {
    const url = `${this.assetsApiUrl(asset_id)}/photo/download_token`;
    let params = new HttpParams();
    if (version) {
      params = params.set('ver', version);
    }
    if (action) {
      params = params.set('action', action);
    }
    return this.http
      .post<{ download_token: string }>(`${url}${params.toString() ? `?${params.toString()}` : ''}`, null)
      .pipe(map(({ download_token }) => download_token));
  }

  getDisclaimer({
    asset_id,
    reportId,
    chapterId,
  }: {
    asset_id: string;
    reportId?: string;
    chapterId?: string;
  }): Observable<{ disclaimer: Disclaimer; asset_id: string }> {
    if (!asset_id) {
      return throwError(`Can't request a disclaimer. Asset id is null`);
    }
    let url = `${this.assetsApiUrl(asset_id)}`;
    url += reportId && chapterId ? this.publishedReportChapterApiUrl(reportId, chapterId) : this.disclaimerUrl;
    return this.http.get<Disclaimer>(url).pipe(
      map((response) => ({
        disclaimer: Transform.disclaimer(response),
        asset_id,
      }))
    );
  }

  confirmDisclaimer({ asset_id }: { asset_id: string }): Observable<void> {
    const url = `${this.assetsApiUrl(asset_id)}${this.disclaimerUrl}`;
    return this.http.post<void>(url, null);
  }

  getTermsAndConditions(assetId?: string): Observable<TermsConditions> {
    let url = `${this.customerApiUrl}`;
    const termsUrl = '/terms-conditions';
    url = assetId ? url + `/${assetId}${termsUrl}` : termsUrl;
    return this.http.get<TermsConditions>(url);
  }

  getAssetTermsAndConditions({ asset_id }: { asset_id: string }): Observable<TermsConditions> {
    const url = `${this.assetsApiUrl(asset_id)}/terms-conditions`;
    return this.http.get<TermsConditions>(url);
  }

  getAssetSetupTermsAndConditions({ asset_id }: { asset_id: string }): Observable<TermsConditions> {
    const url = `${this.assetsApiUrl(asset_id)}/setup/terms-conditions`;
    return this.http.get<TermsConditions>(url);
  }

  confirmTermsAndConditions(asset_id: string): Observable<void> {
    const url = `${this.assetsApiUrl(asset_id)}/confirm-terms-conditions`;
    return this.http.post<void>(url, null);
  }

  createTermsAndConditions(data: Partial<TermsConditions>): Observable<TermsConditions> {
    const url = `${this.customerApiUrl}/${data.id}/setup/terms-conditions`;
    return this.http.post<TermsConditions>(url, data);
  }

  deleteTermsAndConditions({ asset_id }: { asset_id: string }): Observable<void> {
    const url = `${this.assetsApiUrl(asset_id)}/setup/terms-conditions`;
    return this.http.delete<void>(url);
  }

  assignOrUnassignTermsAndConditions(data: Partial<TermsConditions>, type: AssignmentType): Observable<void> {
    const url = `${this.customerApiUrl}/${data.id}/setup/terms-conditions/${type}`;
    return this.http.post<void>(url, data);
  }

  setAssetSettings(data: Partial<TermsConditions>, type: AssignmentType): Observable<void> {
    const url = `${this.customerApiUrl}/${data.id}/setup/terms-conditions/${type}`;
    return this.http.post<void>(url, data);
  }

  getLogoUploadToken({ asset_id }: { asset_id: string }, logoType: LogoType = 'projectLogo'): Observable<string> {
    const url =
      logoType === 'reportLogo'
        ? `${this.assetsApiUrl(asset_id)}/setup/report-logo/upload_token`
        : `${this.assetsApiUrl(asset_id)}/setup/logo/upload_token`;
    return this.http.post<{ upload_token: string }>(url, null).pipe(map(({ upload_token }) => upload_token));
  }

  deleteLogo({ asset_id }: { asset_id: string }, logoType: LogoType): Observable<void> {
    let url;
    switch (logoType) {
      case 'reportLogo':
        url = `${this.assetsApiUrl(asset_id)}/setup/report-logo`;
        break;
      case 'projectLogo':
        url = `${this.assetsApiUrl(asset_id)}/setup/logo`;
        break;
      default:
        url = `${this.assetsApiUrl(asset_id)}/setup/photo`;
        break;
    }
    return this.http.delete<void>(url);
  }

  getAssetPhotoUploadToken({ asset_id }: { asset_id: string }): Observable<string> {
    const url = `${this.assetsApiUrl(asset_id)}/setup/photo/upload_token`;
    return this.http.post<{ upload_token: string }>(url, null).pipe(map(({ upload_token }) => upload_token));
  }

  updateSettings(assetId: string, reqPayload: BasicSettings): Observable<Asset> {
    return this.http.post<Asset>(`${this.assetsApiUrl(assetId)}/setup/settings`, reqPayload);
  }

  protected readonly assetsApiUrl = (asset_id) => `${this.customerApiUrl}/${asset_id}`;

  protected readonly publishedReportChapterApiUrl = (reportId, chapterId) =>
    `/reports/${reportId}/published/chapters/${chapterId}`;
}
