import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { User } from '@core/models';
import { UserService } from '@core/services';
import { UserActions } from '@core/state';
import { environment } from '@environments';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CustomerRoutingService } from '@portal/customer/services';
import { map } from 'rxjs/operators';
import { HeaderActions, UserInfoDialogComponent, UserInfoDialogComponentParams } from '@app/common';
import { LoginDialogComponent } from '../../components/login-dialog';
import { HeaderNotificationsStore } from './header-notifications.store';

const DEFAULT_LOGO = 'logo.jpg';

@UntilDestroy()
@Component({
  selector: 'x-legacy-header',
  templateUrl: './legacy-header.component.html',
  styleUrls: ['./legacy-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [HeaderNotificationsStore],
})
export class LegacyHeaderComponent {
  @Effect() headerLogoUrl$ = this.actions$.pipe(
    ofType(HeaderActions.setLogo),
    map((action) => action.url)
  );
  @Input() showUser = false;
  @ViewChild('userButton') userButton?: ElementRef;

  readonly logoPath = `${environment.production ? '/static' : ''}/assets/images`;
  readonly logoUrl = DEFAULT_LOGO;

  readonly user$ = this.userService.user$;

  constructor(
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private readonly store: Store<unknown>,
    private readonly actions$: Actions,
    readonly customerRouting: CustomerRoutingService
  ) {}

  openUserDialog(user: User): void {
    const userButtonElement: HTMLElement = this.userButton?.nativeElement;
    if (!userButtonElement) {
      return;
    }
    const { top, right, height } = userButtonElement.getBoundingClientRect();

    if (!user) {
      this.dialog.open(LoginDialogComponent, {
        data: {
          top: top + height,
          right,
        },
      });
      return;
    }

    this.dialog.open(UserInfoDialogComponent, {
      data: <UserInfoDialogComponentParams>{
        user,
        top: top + height,
        right,
        onLogOut: () => this.store.dispatch(UserActions.signOut()),
      },
      backdropClass: 'backdrop-transparent',
      panelClass: 'user-info-container',
    });
  }
}
