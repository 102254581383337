import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';

import { ProjectAssetsReducer } from './portal-project-assets.reducer';

const projectsState = createFeatureSelector<EntityState<ProjectAssetsReducer.AdapterEntity>>(ProjectAssetsReducer.name);
const { selectEntities } = ProjectAssetsReducer.assetsAdapter.getSelectors(projectsState);

export namespace ProjectAssetsSelectors {
  // export const getAll = selectAll;

  export const getEntities = createSelector(selectEntities, (entities) =>
    _(entities)
      .mapValues(({ assets }) => assets)
      .value()
  );
}
