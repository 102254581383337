import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { api, Disclaimer } from '@core/models';
import { PORTAL_API_URL } from '@core/services';
import { Store } from '@ngrx/store';
import * as _ from 'lodash';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { isNotNil } from 'src/app/utils';
import { AppRoute } from '../../../app-routing.service';
import { AlertType } from '../../../common';
import { DialogActions } from '../../../core/state';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '../../components';
import { CustomerAssetsService, CustomerRoutingService } from '../services';
import { CustomerRouterSelectors } from '../state';

@Injectable()
export class DisclaimerInterceptor implements HttpInterceptor {
  constructor(
    private readonly store: Store,
    private readonly assetsService: CustomerAssetsService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly routingService: CustomerRoutingService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: api.ErrorResponse<unknown>) => {
        if (error && error.status === 403) {
          if (error.error?.errors?.[0]?.source !== 'disclaimer') {
            // not a disclaimer restriction
            return throwError(error);
          }

          const disclaimerDialog = _.find(this.dialog.openDialogs, ({ id }) => id === 'disclaimer-dialog');

          if (disclaimerDialog) {
            return throwError(error);
          }

          combineLatest([
            this.store.select(CustomerRouterSelectors.getUrl),
            this.store.select(CustomerRouterSelectors.getAssetId),
          ])
            .pipe(
              take(1),
              filter(([url, asset_id]) => isNotNil(asset_id)),
              filter(
                ([url, asset_id]) => !!error?.url?.toLowerCase().startsWith(`${PORTAL_API_URL}/assets/${asset_id}`)
              ),
              switchMap(([url, asset_id]) =>
                this.assetsService.getDisclaimer({ asset_id }).pipe(map((result) => ({ result, url })))
              )
            )
            .subscribe({
              next: ({ result: { disclaimer, asset_id }, url }) => this.showDisclaimerDialog(asset_id, disclaimer, url),
            });
        }

        return throwError(error);
      })
    );
  }

  private showDisclaimerDialog(asset_id: string, disclaimer: Disclaimer, url: string | null): void {
    try {
      const dialog$ = this.dialog.open(ConfirmationDialogComponent, {
        id: `disclaimer-dialog`,
        closeOnNavigation: true,
        disableClose: true,
        data: <ConfirmationDialogData>{
          text: disclaimer.content,
          cancelButton: 'reject',
          acceptButton: 'accept',
          disclaimer: true,
          onCancel: () => this.rejectDisclaimer(),
          onConfirm: () => this.acceptDisclaimer(asset_id, url),
        },
      });

      dialog$
        .afterOpened()
        .pipe(
          take(1),
          tap(() => {
            _.forEach(this.dialog.openDialogs, (openedDialog) => {
              if (openedDialog.id !== 'disclaimer-dialog') {
                openedDialog.close();
              }
            });
          })
        )
        .subscribe();
    } catch (error) {
      /*
        The exception occurs in case of several dialog open requests with the same id
        in a short time and since it is async, it is not possible to filter some of the
        additional open requests just by checking the listr of opened dialogs.
      */
    }
  }

  private rejectDisclaimer(): void {
    this.router.navigateByUrl(`/${AppRoute.Customer}`);
    this.dialog.closeAll();
  }

  private acceptDisclaimer(asset_id: string, url: string | null): void {
    this.assetsService
      .confirmDisclaimer({ asset_id })
      .pipe(
        take(1),
        map(() => this.router.navigateByUrl(url || this.routingService.getAssetDetailsLink(asset_id))),
        catchError((disclaimerError: HttpErrorResponse) => {
          this.store.dispatch(
            DialogActions.showAlert({
              data: {
                type: AlertType.Error,
                text: disclaimerError.error || '',
              },
            })
          );

          return throwError(disclaimerError);
        })
      )
      .subscribe();
  }
}
