import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatLegacyAutocomplete as MatAutocomplete } from '@angular/material/legacy-autocomplete';
import { DestroyComponent } from '@common';
import { DocumentsService } from '@portal/services/documents.service';
import _difference from 'lodash/difference';
import _isUndefined from 'lodash/isUndefined';
import _filter from 'lodash/filter';
import _clone from 'lodash/clone';
import { BehaviorSubject, combineLatest, ReplaySubject } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, takeUntil } from 'rxjs/operators';
import { TagsDialogData, TagsDialogEditOption, TagsDialogResponse } from './portal-document-tag.model';

@Component({
  selector: 'x-portal-document-tag',
  templateUrl: './portal-document-tag.component.html',
})
export class PortalDocumentTagComponent extends DestroyComponent implements AfterViewInit, OnInit {
  @ViewChild('tagsInput') tagsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  readonly tags$ = new ReplaySubject<string[]>();
  readonly allTags$ = this.documentsService.getListOfTags(this.data?.options);
  readonly inputValue$ = new BehaviorSubject<string>('');
  readonly excludedTags$ = combineLatest([this.tags$, this.allTags$]).pipe(
    map(([tags, allTags]) => _difference(allTags, tags))
  );
  readonly filteredTags$ = combineLatest([this.excludedTags$, this.inputValue$]).pipe(
    map(([excludedTags, inputValue]) => _filter(excludedTags, (tag) => tag.toLowerCase().startsWith(inputValue)))
  );

  tags: string[] = [];
  notRemovableTags: string[] = [];

  tagsCtrl = new UntypedFormControl();
  checkCtrl = new UntypedFormControl();

  showAllPhotos = true;
  customTitle = this.data?.customTitle;
  editOption: TagsDialogEditOption = this.data?.editOption || 'ALL';
  errorMessage = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: TagsDialogData,
    public dialog: MatDialogRef<PortalDocumentTagComponent, TagsDialogResponse>,
    private documentsService: DocumentsService
  ) {
    super();
    const tags = _clone([...new Set(data?.tags)]?.filter(Boolean)) || [];
    if (this.editOption === 'ADD_ONLY') {
      this.notRemovableTags = tags;
    } else {
      this.tags = tags;
    }
    this.tags$.next([]);
    this.showAllPhotos = !data?.disableAllPhotos;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.tagsInput?.nativeElement.focus();
      this.updateTags();
    });
  }

  ngOnInit(): void {
    this.tagsCtrl.valueChanges
      .pipe(
        startWith(''),
        map((value: string) => value?.toLowerCase()),
        filter((value) => !_isUndefined(value)),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: (value) => {
          this.inputValue$.next(value);
        },
      });
  }

  onAdd(): void {
    this.addTag(this.tagsCtrl.value || '');
  }

  onSubmit(): void {
    if (!this.tags.length && this.editOption === 'ADD_ONLY') {
      this.errorMessage = 'select-or-type-new-tags-to-add-to-photo';
      return;
    }

    this.onAdd();
    this.dialog.close({
      tags: [...new Set(this.tags || [])],
      tagAllPhotos: !!this.checkCtrl.value,
    });
  }

  handleTagsChanged(tags): void {
    this.errorMessage = '';
    this.tags = tags;
  }

  trackByIndex(index: number, _: string) {
    return index;
  }

  private updateTags(): void {
    this.tags$.next([...this.notRemovableTags, ...this.tags]);
  }

  private addTag(tag: string): void {
    if (tag.trim()) {
      this.tags.push(tag.trim());
    }

    const input = this.tagsInput?.nativeElement;
    if (input) {
      input.value = '';
    }

    this.tagsCtrl.setValue(null);
    this.updateTags();
  }
}
