import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Countries } from '../../../../core/models';

@Component({
  selector: 'x-language-label',
  templateUrl: './language-label.component.html',
  styleUrls: ['./language-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageLabelComponent {
  @Input()
  language!: Countries;

  readonly Countries = Countries;
}
