import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Quantity } from '@core/models';

@Component({
  selector: 'x-quantity-label',
  templateUrl: './quantity-label.component.html',
  styleUrls: ['./quantity-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuantityLabelComponent {
  @Input()
  quantity: Quantity;

  readonly Quantity = Quantity;
}
