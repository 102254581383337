import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ToggleOptionData } from '@common/components/toggle/toggle.component';

import { AssetOverview } from '@core/models';
import { FormatDateService } from '@core/services/format-date.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ImageSizeVersion } from '@portal/customer/services';
import { AssetsOverviewSearchService } from '@portal/services/assets-overview-search.service';
import { DownloadTokenAction } from '@portal/services/documents.service';
import _isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs';

export type AssetViewType = 'list' | 'grid';

export type DownloadTokenCallbackType = (
  asset_id: string,
  { version, action }?: { version?: ImageSizeVersion; action?: DownloadTokenAction }
) => Observable<string>;

@UntilDestroy()
@Component({
  selector: 'x-assets-overview',
  templateUrl: './assets-overview.component.html',
  styleUrls: ['./assets-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssetsOverviewComponent implements OnInit, OnChanges {
  @Input() assets: AssetOverview[];
  @Input() isAdmin = false;
  @Input() isLoading: boolean;
  @Input() newDownloadTokenGetter: DownloadTokenCallbackType;
  @Input() title?: string;
  @Input() canNoResultsBeShowedOnInit = false;
  @Input() noAssetsCustomPrimaryText?: string;
  @Input() noAssetsCustomSecondaryText?: string;
  @Input() isCardEditButtonShowed = false;
  @Input() isAllFetched = false;

  @Output() assetClick = new EventEmitter<AssetOverview>();
  @Output() editAssetClick = new EventEmitter<AssetOverview>();
  @Output() uploadAssetPhoto = new EventEmitter<{
    asset: AssetOverview;
    file: File;
  }>();
  @Output() lastAssetShown = new EventEmitter<void>();

  filteredAssets?: AssetOverview[];
  readonly viewTypeToggleData: ToggleOptionData<AssetViewType>[] = [
    {
      id: 'grid',
      iconName: 'grid',
      tooltip: 'view-type.tile-view',
    },
    {
      id: 'list',
      iconName: 'list',
      tooltip: 'view-type.list-view',
    },
  ];
  selectedViewType = 'grid';

  constructor(
    protected componentRef: ElementRef<HTMLElement>,
    protected formatDateService: FormatDateService,
    private readonly assetsOverviewSearchService: AssetsOverviewSearchService,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.assetsOverviewSearchService
      .getSearchFilter()
      .pipe(untilDestroyed(this))
      .subscribe((filteredAssets) => {
        this.filteredAssets = filteredAssets;
        this.cdr.detectChanges();
      });
  }
  ngOnChanges({ assets }: SimpleChanges) {
    if (assets?.previousValue && assets?.currentValue && !_isEqual(assets.previousValue, assets.currentValue)) {
      this.cdr.markForCheck();
    }
  }

  get isNoResultsBeShowedOnInit(): boolean {
    return !this.assets?.length && this.canNoResultsBeShowedOnInit;
  }
}
