import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tracker } from '@core/models';

@Component({
  selector: 'x-document-label',
  templateUrl: './document-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocumentLabelComponent {
  @Input() fieldName: keyof Tracker;
}
