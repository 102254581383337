<ng-container *transloco="let t; read: 'tickets-system.ticket-details'">
  <x-details-field-row [label]="'tickets-system.columns.trade_id' | transloco" *ngIf="ticket?.trade_id">
    {{ getTradeLabel(ticket?.trade_id) | async }}
  </x-details-field-row>
  <x-details-field-row [label]="t('task')">
    {{ ticket.message }}
  </x-details-field-row>
  <x-details-field-row [label]="t('status')" *ngIf="getStatusBadgeData(ticket.status) as badgeData">
    <x-badge [text]="badgeData.text" [badgeType]="badgeData.badgeType"></x-badge>
  </x-details-field-row>
  <x-details-field-row [label]="t('type')" *ngIf="ticket.type">{{ t(ticket.type) }}</x-details-field-row>
  <x-details-field-row [label]="t('assigned-to')" *ngIf="ticket?.to_user || ticket?.to_user">
    <x-username-renderer
      [value]="{ user: ticket.to_user, fallbackName: ticket.to_group?.name || '-' }"
    ></x-username-renderer>
  </x-details-field-row>
  <ng-container *ngIf="getReferences(ticket); let references">
    <x-details-field-row [label]="t('reference')" *ngIf="references.length">
      <x-references-preview [assetId]="ticket.asset_id" [attachedReferencesList]="references"></x-references-preview>
    </x-details-field-row>
  </ng-container>
  <x-details-field-row [label]="t('priority')">
    <x-priority-indicator [priorityValue]="ticket.priority"></x-priority-indicator>
  </x-details-field-row>
  <x-details-field-row
    [label]="!!getLatestGracePeriod(ticket) ? t('initial-due-date') : t('due-date')"
    *ngIf="ticket?.due_date"
  >
    <x-due-date-cell-renderer [message]="ticket" [date]="ticket.due_date"></x-due-date-cell-renderer>
  </x-details-field-row>
  <x-details-field-row
    *ngIf="ticket.grace_period_first"
    [label]="'tickets-system.grace-periods.grace-period-1' | transloco"
  >
    <x-due-date-cell-renderer [message]="ticket" [date]="ticket.grace_period_first"></x-due-date-cell-renderer>
  </x-details-field-row>
  <x-details-field-row
    *ngIf="ticket.grace_period_second"
    [label]="'tickets-system.grace-periods.grace-period-2' | transloco"
  >
    <x-due-date-cell-renderer [message]="ticket" [date]="ticket.grace_period_second"></x-due-date-cell-renderer>
  </x-details-field-row>
  <x-details-field-row
    *ngIf="ticket.last_extension_at"
    contentClasses="flex-col !items-start"
    [label]="'tickets-system.grace-periods.last-extension' | transloco"
  >
    <x-due-date-cell-renderer
      [message]="ticket"
      [date]="getLatestGracePeriod(ticket) | localeDate"
    ></x-due-date-cell-renderer>
    <x-info-panel class="flex mt-1.5" variant="warning">
      {{ ticket.legal_notice || ('tickets-system.grace-periods.final-extension-consequences-warning' | transloco) }}
    </x-info-panel>
  </x-details-field-row>
  <x-details-field-row [label]="t('created-by')">
    <x-username-renderer [value]="{ user: ticket.message_user, fallbackName: '-' }"></x-username-renderer>
  </x-details-field-row>
  <x-details-field-row [label]="t('created-at')">
    {{ ticket.created_at | localeDate : true }}
  </x-details-field-row>
</ng-container>
