import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { TranslocoModule } from '@ngneat/transloco';
import { UploadComponentModule } from '@portal/components/upload';
import { UploadPreviewComponent } from './upload-preview.component';
import { TagsInputComponent } from '@portal/components/tags-input/tags-input.component';
import { SelectComponent } from '@common/components/select/select.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    UploadComponentModule,
    TagsInputComponent,
    MatSelectModule,
    TranslocoModule,
    SelectComponent,
  ],
  declarations: [UploadPreviewComponent],
  exports: [UploadPreviewComponent, MatDialogModule],
})
export class UploadPreviewModule {}
