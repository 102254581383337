<span [title]="document?.name">
  {{ document?.name }}
</span>
<span title="{{ 'size' | transloco }}">
  <ng-container *ngIf="document?.file_size | fileSize as bytesSize">&nbsp;|&nbsp;{{ bytesSize }}</ng-container>
</span>
<ng-container *ngIf="document?.original_datetime; else publishedAtTemplate">
  <span title="{{ 'original-date' | transloco }}">&nbsp;|&nbsp;{{ document?.original_datetime | localeDate }}</span>
</ng-container>

<ng-template #publishedAtTemplate>
  <span title="{{ 'published-at' | transloco }}" *ngIf="document?.published_at">
    &nbsp;|&nbsp;{{ document?.published_at | localeDate }}
  </span>
</ng-template>
