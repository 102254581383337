<x-dialog
  [title]="'add-admin' | transloco"
  classes="w-[70vw] max-w-[528px]"
  (handleSubmit)="onSubmit()"
  (handleClose)="onClose()"
>
  <form class="grid grid-cols-3 gap-4" autocomplete="off">
    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px]">
      {{ 'admin' | transloco }}:
    </span>
    <x-select
      class="col-span-2"
      [options]="admins$ | async"
      [control]="adminFormGroup.get('user_id') | formControl"
    ></x-select>

    <x-checkbox
      class="flex items-center space-x-4 col-span-2 min-w-[240px]"
      [control]="adminFormGroup.get('is_project_lead') | formControl"
      name="is_project_lead"
    >
      <ng-container>{{ 'is-project-lead' | transloco }}</ng-container>
    </x-checkbox>
    <div class="col-span-1"></div>
  </form>
</x-dialog>

<ng-template #addAdminErrorMessageTemplate>
  <span>{{ 'failed-to-add-an-admin' | transloco }}</span>
</ng-template>
