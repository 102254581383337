<ng-container [ngSwitch]="fieldName">
  <ng-container *ngSwitchCase="'id'">{{ 'id' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'name'">{{ 'name' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'token'">{{ 'token' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'status'">{{ 'status' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'owner.company'">{{ 'company' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'product_type'">{{ 'product-types' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'additional_modules'">{{ 'additional-modules' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'report_format_id'">{{ 'report-format' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'team_leads'">{{ 'team-leads' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'owner.name'">{{ 'owner' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_types'">{{ 'asset-types' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'started_at'">{{ 'started-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'created_at'">{{ 'created-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'trade_set.name'">{{ 'trade-set' | transloco }}</ng-container>
  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>
