import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { ENGLISH_LOCALE } from '@core/models';
import * as _ from 'lodash';
import { LanguageService } from './language-service';

export const dateOptions: Intl.DateTimeFormatOptions = {
  month: 'short',
  day: '2-digit',
  year: 'numeric',
};
export const dateTimeOptions: Intl.DateTimeFormatOptions = {
  ...dateOptions,
  hour: '2-digit',
  minute: '2-digit',
  second: '2-digit',
  hour12: false,
};
export const timeOptions: Intl.DateTimeFormatOptions = { hour: '2-digit', minute: '2-digit' };
export const DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_TIME_FORMAT = 'yyyy-MM-dd HH:mm:ss';
export const NULL_DATE_VALUE = '0001-01-01T00:00:00Z';

@Injectable({
  providedIn: 'root',
})
export class FormatDateService {
  constructor(private readonly languageService: LanguageService) {}

  toLocaleString(date: Date, includeTime = false): string {
    if (!_.isFinite(+date)) {
      return null;
    }

    return date?.toLocaleString(this.languageService.locale, includeTime ? dateTimeOptions : dateOptions);
  }

  toLocaleTimeString(date: Date): string | undefined {
    if (!_.isFinite(+date)) {
      return undefined;
    }

    return date?.toLocaleString(this.languageService.locale, timeOptions);
  }

  /**
   * Date will be formatted according to provided format
   * rather than current locale
   */
  format(date: Date, format = DATE_FORMAT, timezone?: string): string {
    return formatDate(date, format, ENGLISH_LOCALE, timezone);
  }
}
