import { Injectable } from '@angular/core';
import { ProjectListItem } from '@core/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminPortalSearchService {
  private rowData$ = new Subject<ProjectListItem[]>();

  getSearchFilter(): Observable<ProjectListItem[]> {
    return this.rowData$.asObservable();
  }

  setSearchFilter(rowData: ProjectListItem[]): void {
    this.rowData$.next(rowData);
  }
}
