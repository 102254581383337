<x-input
  (inputClick)="searchFieldFocused = true"
  (clickOutside)="searchFieldFocused = false"
  (resetClick)="reset()"
  class="w-[150px] inline-flex"
  [ngClass]="{ 'search-input-wide': searchFieldFocused }"
  [autofocus]="true"
  [showClearButton]="true"
  [forceShowClearButton]="searchFieldFocused"
  [control]="control"
  nativeInputClasses="h-[42px]"
  placeholder="{{ 'search' | transloco }}"
  autocomplete="off"
  leftSideIcon="magnifying-glass"
  [value]="value"
  (handleKeydown)="handleKeydown.emit($event)"
  (valueChange)="valueChange.emit($event)"
></x-input>
