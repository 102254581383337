import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable } from 'rxjs';

import { ACCESS_TOKEN_STORAGE_KEY, API_URL } from '@core/services';
import { environment } from '@environments';

export const notNeededAuthEndpoints = [
  new RegExp(`${API_URL}/${environment.portalApiUrl}/sign-in`),
  new RegExp(`${API_URL}/${environment.portalApiUrl}/password/forgot`),
  new RegExp(`${API_URL}/${environment.portalApiUrl}/password/reset`),
  new RegExp(`${API_URL}/${environment.portalApiUrl}/sign-up`),
  new RegExp(`${API_URL}/${environment.portalApiUrl}/logout`),
  new RegExp(`${API_URL}/${environment.portalApiUrl}/register/email-confirmation`),
  new RegExp(`${API_URL}/${environment.portalApiUrl}/register/invitation`),
  new RegExp(`${API_URL}/${environment.portalApiUrl}/users/me`),
];

const requestsUrlsToSkipInStopCheck = [...notNeededAuthEndpoints, /assets\//];

const requestsUrlsToNotSkipInStopCheck = [new RegExp(`${API_URL}/${environment.portalApiUrl}/assets/\\w+`)];

@Injectable()
export class StopRequestsAfterLogoutInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const shouldStopRequest =
      !localStorage.getItem(ACCESS_TOKEN_STORAGE_KEY) &&
      (!requestsUrlsToSkipInStopCheck.some((regex) => regex.test(request.url)) ||
        !requestsUrlsToNotSkipInStopCheck.some((regex) => !regex.test(request.url)));

    if (shouldStopRequest) {
      console.warn('! WARNING ! - Request stopped by interceptor:', request.url);
      return EMPTY;
    }

    return next.handle(request);
  }
}
