import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[xEnterKey]',
  standalone: true,
})
export class EnterKeyDirective {
  @Output() enterKeyPressed: EventEmitter<KeyboardEvent> = new EventEmitter();

  @HostListener('keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent): void {
    event.preventDefault();
    this.enterKeyPressed.emit(event);
  }
}
