import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _isEqual from 'lodash/isEqual';
import { AutofocusDirectiveModule } from '@common/directives';

@UntilDestroy()
@Component({
  selector: 'x-textarea',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, AutofocusDirectiveModule],
  templateUrl: './textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaComponent<T> implements OnInit, OnChanges {
  @Input() label = '';
  @Input() control = new FormControl();
  @Input() id?: string;
  @Input() value: T = undefined;
  @Input() name: string;
  @HostBinding('class.pointer-events-none')
  @Input()
  disabled = false;
  @Input() required?: '' | 'required' | boolean;
  @Input() rows = 3;
  @Input() maxlength: string | number;
  @Input() focus = false;
  @Input() placeholder?: string;

  @Output() valueChange = new EventEmitter<T>();

  ngOnInit() {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
      this.valueChange.emit(value);
    });
  }

  ngOnChanges({ value, disabled }: SimpleChanges) {
    if (value && !_isEqual(value.currentValue, this.control.value)) {
      this.control.setValue(value.currentValue);
    }
    if (disabled) {
      disabled.currentValue ? this.control.disable() : this.control.enable();
    }
  }
}
