<div class="flex flex-row space-x-1.5" [title]="priorityValue | transloco">
  <ng-container
    *ngTemplateOutlet="circle; context: { $implicit: priorityValue === 'low' && 'bg-success-600' }"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="circle; context: { $implicit: priorityValue === 'medium' && 'bg-warning-500' }"
  ></ng-container>
  <ng-container
    *ngTemplateOutlet="circle; context: { $implicit: priorityValue === 'high' && 'bg-danger-600' }"
  ></ng-container>
</div>

<ng-template #circle let-colorClass>
  <span [class]="'rounded-full w-[10px] h-[10px]'" [ngClass]="getCircleClass(colorClass)"></span>
</ng-template>
