<x-legacy-header *ngIf="showHeader" [showUser]="showUser">
  <ng-content select="[header]"></ng-content>
</x-legacy-header>

<div class="body-container" [class.has-side-panel]="showSideMenu">
  <x-side-panel *ngIf="showSideMenu" [role]="userRole">
    <ng-content select="[side-panel-links]"></ng-content>
    <ng-content side-panel-back select="[side-panel-back]"></ng-content>
  </x-side-panel>

  <div class="body-container-content">
    <ng-container *ngIf="showHeaderImage">
      <div class="body-header" [class.condensed]="condensedHeaderImage">
        <h1 class="portal-title">
          <ng-content select="[header-title]"></ng-content>
        </h1>
        <h3 class="portal-description">
          <ng-content select="[header-description]"></ng-content>
        </h3>
      </div>
    </ng-container>
    <ng-content *ngIf="showSearch" select="[search]"></ng-content>
    <ng-content *ngIf="showBreadCrumbs" select="[breadcrumbs]"></ng-content>
    <ng-content select="[content]"></ng-content>
  </div>
</div>

<x-footer *ngIf="showFooter" [type]="pageType"></x-footer>
