import { ISelectOption } from '@common/components/select/select.model';
import { Document } from './document';
import { Transform } from './transform';
import { ShortGroup } from '@core/models/group';
import { ShortUser, User } from '@core/models/user';

export interface DataRoomUpdatesStatisticEntry {
  action: string;
  affected_group_id: string;
  affected_group_name: string;
  asset_id: string;
  dest_node: string;
  dest_path: string;
  file_extension: string;
  file_size: string;
  group_id: string;
  group_name: string;
  id: string;
  original_filename: string;
  permissions: string[];
  project_id: string;
  src_node: string;
  src_path: string;
  timestamp: string;
  user_email: string;
  user_id: string;
}

export namespace DataRoom {
  export type PermissionVariant = 'default' | 'add' | 'remove';

  export interface Completeness {
    asset_id: string;
    created_at: string;
    deleted_at: string;
    group_id: string;
    src_id: string;
    id: string;
    level: 0 | 1 | 2 | 3; // 0: not set, 1: green, 2: yellow, 3: red
    missing_items: string[];
    project_id: string;
    updated_at: string;
    user: User;
    user_id: string;
  }

  export interface NodeShort {
    document_id: string;
    label: string;
    parent_node_id: string;
    permission_option: PermissionOption;
    index_point?: string;
  }

  export interface Node {
    // general
    asset_id: string;
    created_at: Date;
    creator_group: ShortGroup;
    creator_group_id: string;
    deleted_at: Date;
    document: Partial<Document> | null;
    document_id: string;
    id: string;
    ids_path: string[];
    index_point?: string;
    is_root_node: boolean;
    label: string;
    labels_path: string[];
    level: number;
    parent_id: string;
    root_type: 'data_room' | 'downloads' | 'favorites' | 'qa' | 'trash';
    size?: number;
    total_doc_count: number;
    type?: 'documents' | 'folders' | 'placeholders';
    updated_at: Date;
    upload_id: string;
    uploaded_at?: Date;

    // permission related
    permission_groups_approve: string[];
    permission_groups_del: string[];
    permission_groups_download: string[];
    permission_groups_need_approve: string[];
    permission_groups_print: string[];
    permission_groups_view: string[];
    permission_groups_write: string[];
    permission_set: PermissionSet;
    permission_set_group1?: PermissionSet;

    // versions related
    contains_pending_version?: boolean;
    is_locked?: boolean;
    locked_by?: string; // id
    locked_at?: string;
    locked_by_group_id?: string;
    locked_by_user?: ShortUser;
    total_versions_count?: number;
    version: number;
    version_approved_at: string;
    version_approved_by: string;
    version_approved_by_group_id: string;
    version_comment: string;
    version_created_at: string;
    version_created_by: string;
    version_created_by_group: ShortGroup;
    version_created_by_group_id: string;
    version_created_by_user?: ShortUser;
    version_rejected_at: string;
    version_rejected_by: ShortGroup;
    version_rejected_by_group_id: string;
    version_rejection_comment: string;
    version_status?: VersionStatus;
    versioning_id: string;
  }

  export namespace Node {
    export const transform = (data: DataRoom.Node): DataRoom.Node => ({
      ...data,
      created_at: Transform.date(data.created_at),
      deleted_at: Transform.date(data.deleted_at),
      updated_at: Transform.date(data.updated_at),
      document: data.document ? Document.transform(data.document as Document) : null,
    });
  }

  export enum Layout {
    Tree = 'tree',
    List = 'list',
    Search = 'search',
    Versions = 'versions',
  }

  export type VersionStatus = 'pending' | 'draft' | 'approved' | 'rejected';

  export type PermissionOption = 'inherit' | 'pm_and_managers'; // | 'admins_and_owner' | 'managers_only';

  export const permissionTranslationKeyMap = new Map([
    ['qa', 'q-a'],
    ['finding', 'findings'],
    ['report', 'reports'],
    ['admin_project', 'projects'],
    ['admin_user', 'user'],
    ['admin_statistics', 'statistics'],
    ['inherit', 'same-permission-as-parent-folder'],
    ['pm_and_managers', 'full-permission-for-manager-and-project-manager-group'],
  ]);

  export namespace PermissionOption {
    export const list: PermissionOption[] = ['inherit', 'pm_and_managers'];
    export const uiSelectListMap: ISelectOption<DataRoom.PermissionOption>[] = DataRoom.PermissionOption.list.map(
      (option) => ({
        value: option,
        translationKey: DataRoom.permissionTranslationKeyMap.get(option) || option,
      })
    );
  }

  export interface NodeGroupPermission {
    name: string;
    permission_set: PermissionSet;
  }

  export namespace Permission {
    export type Type =
      | 'view'
      | 'edit'
      | 'write'
      | 'del'
      | 'download'
      | 'print'
      | 'need_approve'
      | 'view_versions'
      | 'approve';
  }

  export type PermissionSet = [boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean, boolean];
  // view, edit, write, del, download, print, approve, need_approve, view_versions

  export namespace PermissionSet {
    export enum Permission {
      'view',
      'edit',
      'write',
      'del',
      'download',
      'print',
      'approve',
      'need_approve',
      'view_versions',
    }
    export const canView = (set: PermissionSet | undefined): boolean => set?.[Permission.view] || false;

    export const canEdit = (set: PermissionSet | undefined): boolean => set?.[Permission.edit] || false;

    export const canWrite = (set: PermissionSet | undefined): boolean => set?.[Permission.write] || false;

    export const canDel = (set: PermissionSet | undefined): boolean => set?.[Permission.del] || false;

    export const canDownload = (set: PermissionSet | undefined): boolean => set?.[Permission.download] || false;

    export const canPrint = (set: PermissionSet | undefined): boolean => set?.[Permission.print] || false;

    // check if user can approve/reject/switch versions
    export const canApprove = (set: PermissionSet | undefined): boolean => set?.[Permission.approve] || false;

    // check if user can update node versions
    export const needApprove = (set: PermissionSet | undefined): boolean => set?.[Permission.need_approve] || false;

    export const canViewVersions = (set: PermissionSet | undefined): boolean =>
      set?.[Permission.view_versions] || false;
  }

  export interface PermissionGroupUpdate {
    add_remove_group_id: string;
    add_remove_permission_set: Partial<PermissionGroupUpdate.AddRemovePermissionSet>;
    recursive: boolean;
  }

  export interface PermissionGroupCopyPermissionsUpdate {
    source_group_id: string;
    target_group_id: string;
  }

  export namespace PermissionGroupUpdate {
    export type Value = 'add' | 'remove';

    export interface AddRemovePermissionSet {
      print: Value;
      edit: Value;
      view: Value;
      write: Value;
      del: Value;
      approve: Value;
      download: Value;
    }
  }

  export type VersionFilter = 'pending' | 'approved';

  export namespace Search {
    export interface Params {
      last_days?: number;
      only_docs?: boolean;
      only_folders?: boolean;
      only_placeholders?: boolean;
      query?: string;
      version_filter?: VersionFilter;
      version_approved_by_user_id?: string;
      version_approved_by_group_id?: string;
    }

    export interface Result {
      id: string;
      abstract: string;
      created_at: Date;
      document_id: string;
      file_extension?: string;
      file_size?: string;
      labels_path?: string[];
      title: string;
      type: 'node' | 'finding' | 'qa';
      updated_at: Date;
      node: DataRoom.Node;
    }
  }
}
