import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { LocaleDatePipeModule, MaterialModule } from '@common';
import { CustomDateAdapter } from 'src/app/common/custom-date.adapter';
import { DateInputComponent } from './date-input.component';
import { InputComponent } from '@common/components/input/input.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

@NgModule({
  imports: [CommonModule, LocaleDatePipeModule, MatIconModule, InputComponent, MaterialModule, SvgIconModule],
  declarations: [DateInputComponent],
  exports: [DateInputComponent],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class DateInputModule {}
