import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TooltipComponent } from './tooltip.component';

@NgModule({
  imports: [CommonModule, MatIconModule, MatTooltipModule, OverlayModule],
  declarations: [TooltipComponent],
  exports: [TooltipComponent],
})
export class TooltipModule {}
