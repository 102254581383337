import { Pipe, PipeTransform } from '@angular/core';
import { getAssetUrl } from '@common/utils/getAssetUrl';

@Pipe({
  name: 'assetUrl',
  standalone: true,
  pure: true,
})
export class AssetUrlPipe implements PipeTransform {
  transform(assetName: string): string {
    return getAssetUrl(assetName);
  }
}
