import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UsersReducer, UsersState } from './users.reducer';

const featureSelector = createFeatureSelector<UsersState>(UsersReducer.name);

export namespace UsersSelectors {
  export const getUser = createSelector(featureSelector, (state) => state && state.user);

  export const getIsLoggedIn = createSelector(getUser, (user) => !!user);

  export const getWithAdminAccess = createSelector(getUser, (user) => user?.withAdminAccess);

  export const getIsAdmin = createSelector(getWithAdminAccess, (withAdminAccess) => withAdminAccess);

  export const getIsRoot = createSelector(getWithAdminAccess, (withAdminAccess) => withAdminAccess);
}
