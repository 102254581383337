<x-icon *ngIf="type !== AlertType.Progress" iconFont="close" (click)="dialogRef.close()"></x-icon>

<div class="icon-container">
  <ng-container [ngSwitch]="type">
    <span *ngSwitchCase="AlertType.Success" class="icon-font icon-font-alert-success"></span>
    <span *ngSwitchCase="AlertType.Error" class="icon-font icon-font-error-outline"></span>
    <x-spinner *ngSwitchCase="AlertType.Progress"></x-spinner>
  </ng-container>
</div>
<ng-container *ngIf="text || (messageTemplate && data?.context) || data?.error; else missingMessageTemplate">
  <div class="text-container">
    <ng-container *ngIf="text">
      <div class="alert-title" *ngIf="type">
        <ng-container [ngSwitch]="type">
          <span *ngSwitchCase="AlertType.Info">{{ 'info' | transloco }}</span>
          <span *ngSwitchCase="AlertType.Success">{{ 'thanks-a-lot' | transloco }}</span>
          <span *ngSwitchCase="AlertType.Error">{{ 'generic-error' | transloco }}</span>
        </ng-container>
      </div>

      <div class="alert-text">{{ text }}</div>
    </ng-container>

    <ng-container *ngIf="messageTemplate">
      <ng-container *ngTemplateOutlet="messageTemplate; context: { $implicit: data?.context }"></ng-container>
    </ng-container>

    <ng-container *ngIf="data?.error">
      <div *ngFor="let error of data.error.errors" class="alert-details">
        {{ error.details }}
      </div>
    </ng-container>
  </div>
</ng-container>

<ng-template #missingMessageTemplate>
  <div class="text-container" *ngIf="type && type !== 'progress'">
    <ng-container [ngSwitch]="type">
      <span *ngSwitchCase="AlertType.Error">{{ 'unknown-error-please-try-again' | transloco }}</span>
    </ng-container>
  </div>
</ng-template>
