import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import _isEqual from 'lodash/isEqual';

@UntilDestroy()
@Component({
  selector: 'x-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  styleUrls: ['checkbox.component.scss'],
  templateUrl: './checkbox.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent<T> implements OnInit, OnChanges {
  @Input() id?: string;
  @Input() classes = 'flex items-start';
  @Input() name?: string;
  @Input() control = new FormControl();
  @Input() value: T = undefined;
  @Input() disabled = false;
  @Input() variant: 'normal' | 'toggle' = 'normal';
  @Input() usePropsStateOnly = false;
  @Output() valueChange = new EventEmitter<T>();

  @ViewChild('checkbox', { static: true }) checkbox!: ElementRef;

  constructor(private cdr: ChangeDetectorRef) {}

  onLabelClicked(): void {
    if (this.usePropsStateOnly) {
      this.valueChange.emit();
    } else {
      this.checkbox.nativeElement.click();
    }
  }

  ngOnInit(): void {
    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });

    if (!this.usePropsStateOnly) {
      this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((value) => {
        this.valueChange.emit(value);
        this.cdr.markForCheck();
      });
    }
  }

  ngOnChanges({ value, disabled }: SimpleChanges): void {
    if (value && !_isEqual(value?.currentValue, this.control.value)) {
      this.control.setValue(!!value?.currentValue);
    }
    if (disabled) {
      if (disabled.currentValue) {
        this.control.disable();
      } else if (disabled.currentValue === false) {
        this.control.enable();
      }
    }
  }
}
