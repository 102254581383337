<ng-template #customToastTemplate>
  <div
    class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 min-w-[320px]"
    [ngClass]="{
      'border-2 border-danger-400': type === toastType.ERROR
    }"
  >
    <div class="p-4">
      <div class="flex items-start relative">
        <div class="flex-shrink-0" [ngSwitch]="type">
          <x-svg-icon
            *ngSwitchCase="toastType.SUCCESS"
            svgImageName="success"
            svgClass="h-6 w-6 text-green-400"
          ></x-svg-icon>
          <x-svg-icon *ngSwitchCase="toastType.INFO" svgImageName="info" svgClass="w-6 h-6 text-blue-400"></x-svg-icon>
          <x-svg-icon *ngSwitchCase="toastType.ERROR" svgImageName="error" svgClass="w-6 h-6 text-red-400"></x-svg-icon>
          <ng-container *ngSwitchDefault></ng-container>
        </div>
        <div class="ml-3 mr-6 w-0 flex-1 pt-0.5">
          <p *ngIf="message" class="text-sm font-medium text-gray-900" [innerHTML]="message"></p>
          <ng-container
            *ngIf="messageTemplate"
            [ngTemplateOutlet]="messageTemplate"
            [ngTemplateOutletContext]="{ $implicit: messageTemplateContext }"
          ></ng-container>
          <div *ngIf="additionalTexts?.length" class="mt-1">
            <p
              *ngFor="let text of additionalTexts; trackBy: trackBy"
              class="text-xs font-medium"
              [ngClass]="{ 'text-danger-400': type === toastType.ERROR, 'text-gray-900': type !== toastType.ERROR }"
              [innerHTML]="text"
            ></p>
          </div>
          <button class="absolute top-0 right-0" (click)="onHideToast()">
            <x-svg-icon svgImageName="cross" svgClass="w-6 h-6 text-gray-600"></x-svg-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
