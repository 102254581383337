import { HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorResponse, DataRoom, Document } from '@core/models';
import { PORTAL_API_URL, StorageService, UploadTrackerService, UserService } from '@core/services';
import { environment } from '@environments';
import { ImportStructureType, UploadType } from '@portal/customer/components/asset-details/data-room';
import { forkJoin, from, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, shareReplay, switchMap, takeUntil, tap } from 'rxjs/operators';
import { DataRoomUploadDialogResult } from '@portal/customer/components/asset-details/data-room/data-room-tree/data-room-upload-dialog';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { isNotEmpty } from '@app/utils';
import { Store } from '@ngrx/store';
import {
  BatchUpload,
  BatchUploadFile,
  BatchUploadForm,
  UploadMetadata,
  UploadResumingService,
} from '@portal/customer/services/upload-resuming.service';

export interface UploadResult {
  file: File;
  uploadFile: BatchUploadFile | null;
  errorStatus: ApiErrorResponse | null;
  batch_id: string;
}

interface CreateFileForHiddenPlaceholderRequest {
  filename: string;
  target_node_id: string;
}

export type DataroomUploadMetadata = UploadMetadata<{
  uploadType: UploadType;
  versioningId?: string;
  isPlaceholder?: boolean;
}>;

export interface DataroomSavedUploadMetadata extends DataroomUploadMetadata {
  id: string;
}

export interface FileUploadStatus {
  id: string;
  name: string;
  size: number;
  filePath: string;
  status: 'pending' | 'completed';
  rawFile?: File;
  types?: Document.Type[];
  document?: Document;
}

@Injectable({
  providedIn: 'root',
})
export class DataRoomUploadService {
  private readonly apiUrl = `${PORTAL_API_URL}/assets`;

  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId).pipe(filter(isNotEmpty), shareReplay());

  constructor(
    private readonly http: HttpClient,
    private readonly storageService: StorageService,
    private readonly uploadTrackerService: UploadTrackerService,
    private readonly userService: UserService,
    private readonly store: Store,
    private readonly uploadResumingService: UploadResumingService
  ) {}

  resumeDataroomUploadsIfExists() {
    this.uploadResumingService.resumeUploadsIfExists('dataroom', this.startFilesUploading.bind(this));
  }

  upload(
    { rawFiles, folders, option, comment }: DataRoomUploadDialogResult,
    assetId: string,
    nodeId: string,
    cancellationSubject: Subject<void>,
    isPlaceholder?: boolean,
    parentNodeId?: string,
    uploadType: UploadType = 'upload'
  ) {
    const filesPaths = rawFiles.map((file) => this.uploadResumingService.getFilePath(file));
    const uploadFormParams: BatchUploadForm = {
      files_count: rawFiles.length,
      folders,
      files: filesPaths,
      permission_option: option,
      target_node_id: nodeId,
    };

    const createUpload = (uploadFormParams: BatchUploadForm) => this.createUpload(assetId, uploadFormParams);
    const createUploadFn$ =
      uploadType === 'upload-node-version'
        ? this.createNewNodeVersion(assetId, nodeId, comment).pipe(
            switchMap(({ versioning_id, id }) =>
              forkJoin([
                createUpload({ ...uploadFormParams, target_node_id: nodeId, parent_node_id: parentNodeId }).pipe(
                  map((batchUpload) => ({ ...batchUpload, target_node_id: id }))
                ),
                of(versioning_id),
              ])
            )
          )
        : forkJoin([createUpload(uploadFormParams), of(undefined)]);

    return createUploadFn$.pipe(
      concatMap(async ([batchUpload, versioningId]: [BatchUpload, string]) => {
        const fileStatuses: FileUploadStatus[] = rawFiles.map((rawFile) => ({
          id: this.uploadTrackerService.generateRandomUUID(),
          rawFile,
          filePath: this.uploadResumingService.getFilePath(rawFile),
          name: rawFile.name,
          size: rawFile.size,
          status: 'pending',
        }));
        const uploadMetadata: DataroomUploadMetadata = {
          uploadSection: 'dataroom',
          assetId,
          userId: this.userService.user$.value.id,
          fileStatuses,
          folders,
          batchUpload,
          data: {
            versioningId,
            uploadType,
            isPlaceholder,
          },
        };
        const savedUploadMetadata = await this.uploadResumingService.addUploadData(uploadMetadata);
        return this.startFilesUploading(savedUploadMetadata, cancellationSubject);
      }),
      concatMap((resolvePromise) => resolvePromise)
    );
  }

  importFileStructure(
    file: File,
    permission_option: DataRoom.PermissionOption,
    asset_id: string,
    nodeID: string,
    importType?: ImportStructureType
  ): Observable<unknown> {
    const formData = new FormData();
    formData.append('file', file);
    if (permission_option) {
      formData.append('permission_option', permission_option);
    }
    const url = `${this.apiUrl}/${asset_id}/nodes/${nodeID}/${importType === 'excel-import' ? 'import' : 'sync'}`;
    return this.http.post(url, formData);
  }

  private startFilesUploading(
    savedUpload: DataroomSavedUploadMetadata,
    cancellationSubject: Subject<void> = new Subject<void>()
  ) {
    const { fileStatuses, assetId, folders, batchUpload } = savedUpload;
    const { isPlaceholder, uploadType, versioningId } = savedUpload.data;
    this.uploadTrackerService.addFiles(fileStatuses, assetId, batchUpload.id, cancellationSubject, folders);
    return from(fileStatuses.filter((file) => file.status === 'pending')).pipe(
      mergeMap(
        (fileStatus) =>
          this.createBatchUploadFile(
            assetId,
            batchUpload,
            fileStatus,
            cancellationSubject,
            isPlaceholder,
            uploadType,
            versioningId,
            savedUpload.id
          ),
        environment.document.maxConcurrentUploads
      ),
      takeUntil(cancellationSubject),
      catchError((error) => throwError(error))
    );
  }

  private createFileForHiddenPlaceholder(
    assetId: string,
    uploadId: string,
    payload: CreateFileForHiddenPlaceholderRequest
  ): Observable<BatchUploadFile> {
    const url = `${this.apiUrl}/${assetId}/batch_uploads/${uploadId}/files/replace_hidden`;
    return this.http.post<BatchUploadFile>(url, payload);
  }

  private createNewNodeVersion(assetId: string, nodeId: string, comment: string): Observable<DataRoom.Node> {
    const url = `${this.apiUrl}/${assetId}/nodes/${nodeId}/versions`;
    return this.http.post<DataRoom.Node>(url, { comment });
  }

  private createUpload(asset_id: string, form: BatchUploadForm): Observable<BatchUpload> {
    const url = `${this.apiUrl}/${asset_id}/batch_uploads`;

    return this.http.post<BatchUpload>(url, form);
  }

  private createBatchUploadFile(
    asset_id: string,
    batchUpload: BatchUpload,
    fileStatus: FileUploadStatus,
    cancellationSubject: Subject<void>,
    isPlaceholder?: boolean,
    uploadType: UploadType = 'upload',
    versioningId = '',
    uploadId?: string
  ) {
    const batch_upload_id = batchUpload.id;
    let request$: Observable<BatchUploadFile>;

    if (uploadType === 'upload') {
      const filePath = fileStatus.filePath;
      request$ = this.createFileForHiddenPlaceholder(asset_id, batch_upload_id, {
        filename: filePath,
        target_node_id: batchUpload.files_map[`/${filePath}`],
      }).pipe(
        catchError((error) => {
          if (error.status === 409) {
            this.uploadTrackerService.handleUploadEvent(
              { type: HttpEventType.Response } as HttpEvent<string>,
              fileStatus.rawFile
            ); // setting as Completed because 409 conflict means it was already uploaded
            if (uploadId) {
              this.uploadResumingService.markFileAsCompleted(uploadId, fileStatus);
            }
            return throwError(error);
          } else {
            if (uploadId) {
              this.uploadResumingService.clearUploadMetadata('dataroom');
            }
          }
        })
      );
    } else {
      const url = `${this.apiUrl}/${asset_id}/batch_uploads/${batch_upload_id}/files${
        uploadType === 'fill-in-placeholders' ? '/replace' : uploadType === 'upload-node-version' ? '/versioning' : ''
      }`;
      const uploadFile: Partial<BatchUploadFile> = {
        filename: fileStatus.rawFile.name,
        target_node_id: isPlaceholder || uploadType === 'upload-node-version' ? batchUpload.target_node_id : undefined,
        target_versioning_id: versioningId || undefined,
      };
      request$ = this.http.post<BatchUploadFile>(url, uploadFile);
    }

    return request$.pipe(
      mergeMap((batchUploadFile: BatchUploadFile | undefined) => {
        if (batchUploadFile) {
          return this.uploadFile(fileStatus.rawFile, batchUploadFile, batchUpload.id, cancellationSubject);
        }
        return of(undefined);
      }),
      takeUntil(cancellationSubject),
      catchError((error: ApiErrorResponse) => {
        if (error.status !== 409) {
          this.uploadTrackerService.handleUploadError(error, fileStatus.rawFile);
        }
        return of(<UploadResult>{
          file: fileStatus.rawFile,
          uploadFile: null,
          errorStatus: error,
          batch_id: batchUpload.id,
        });
      }),
      tap(() => {
        this.uploadResumingService.markFileAsCompleted(uploadId, fileStatus);
      })
    );
  }

  private uploadFile(
    file: File,
    uploadFile: BatchUploadFile,
    batch_id: string,
    cancellationSubject: Subject<void>
  ): Observable<UploadResult> {
    const { upload_token } = uploadFile;

    return this.storageService.upload(upload_token, file).pipe(
      map(() => <UploadResult>{ file, uploadFile, errorStatus: null, batch_id }),
      takeUntil(cancellationSubject),
      catchError((errorStatus: ApiErrorResponse) => {
        this.uploadTrackerService.handleUploadError(errorStatus, file);
        return of(<UploadResult>{ file, uploadFile, errorStatus, batch_id });
      })
    );
  }
}
