import { Injectable } from '@angular/core';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { LanguageService } from './language-service';

@Injectable({
  providedIn: 'root',
})
export class FormatNumberService {
  constructor(
    private readonly languageService: LanguageService,
    private readonly translocoLocale: TranslocoLocaleService
  ) {}

  format(value: number, digitsInfo?: string, replaceValueIfFalsy?: string): string {
    const parsedDigits = digitsInfo ? this.getDigitsInfo(digitsInfo) : undefined;

    if (replaceValueIfFalsy && !value) {
      return replaceValueIfFalsy;
    }

    if (parsedDigits) {
      return this.translocoLocale.localizeNumber(value, 'decimal', this.languageService.locale, {
        minimumIntegerDigits: parsedDigits.minimumIntegerDigits,
        minimumFractionDigits: parsedDigits.minimumFractionDigits,
        maximumFractionDigits: parsedDigits.maximumFractionDigits,
      });
    }

    return this.translocoLocale.localizeNumber(value, 'decimal');
  }

  formatCurrency(value: number | string, currency = '€', minimumFractionDigits = 0): string {
    if (typeof 'string') {
      value = Number.parseFloat(value as string);
    }
    return this.translocoLocale.localizeNumber(value, 'currency', this.languageService.locale, {
      currency: this.currencySignToCode(currency),
      minimumIntegerDigits: 1,
      minimumFractionDigits,
      maximumFractionDigits: 3,
    });
  }

  currencySignToCode(currency = '€'): string {
    switch (currency) {
      case '$':
        return 'USD';

      case '€':
      default:
        return 'EUR';
    }
  }

  getDigitsInfo(digitsInfo: string) {
    const match = digitsInfo.match(/(\d+)\.(\d+)-(\d+)/);
    if (!match) {
      return undefined;
    }

    return {
      minimumIntegerDigits: +match[1],
      minimumFractionDigits: +match[2],
      maximumFractionDigits: +match[3],
    };
  }
}
