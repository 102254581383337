import { Pipe, PipeTransform } from '@angular/core';
import { Member, ShortUser } from '@core/models';

@Pipe({
  name: 'formattedMemberName',
  standalone: true,
})
export class FormattedMemberNamePipe implements PipeTransform {
  transform(member: Member | ShortUser, showEmail = true): string {
    if (!member) {
      return '-';
    }

    return Member.getFormattedMemberName(member, showEmail);
  }
}
