<div class="inline-flex items-center justify-center rounded-full bg-gray-500 overflow-hidden" [ngClass]="classes">
  <img *ngIf="!!src; else userInitials" [src]="src" alt="" class="h-full w-full" />
</div>

<ng-template #userInitials>
  <span class="inline-flex items-center justify-center bg-gray-500 overflow-hidden">
    <span class="font-medium leading-none text-white uppercase text-md">
      {{ initials }}
    </span>
  </span>
</ng-template>
