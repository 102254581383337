<ng-container *transloco="let t; read: 'upload-dialog'">
  <x-dialog
    class="h-full"
    [title]="title"
    [subtitle]="subtitle"
    (handleClose)="onClose()"
    (handleSubmit)="onSubmit()"
    [submitBtnText]="action || t('upload')"
    [cancelBtnText]="t('cancel')"
    [submitDisabled]="!selected || maxDepthLevelExceeded || disabled || hasTooLongPathFileDetected"
  >
    <section
      class="h-full flex flex-col"
      (dragenter)="onDrag($event)"
      (dragover)="onDrag($event)"
      (drop)="onDropFile($event)"
    >
      <div
        class="flex flex-column h-full content-center p-4 py-12 items-center rounded-xl border-dashed border-2 border-primary-600 justify-center"
        *ngIf="!selected"
      >
        <div class="py-2">
          <x-svg-icon
            class="block mx-auto w-16 h-16"
            svgImageName="cloud-upload"
            svgClass="w-full h-full text-primary-600"
          ></x-svg-icon>
          <p class="text-base font-semibold text-black" [ngSwitch]="multiple">
            <ng-container *ngIf="customDropHereText; else defaultDropHereText">
              {{ customDropHereText }}
            </ng-container>
            <ng-template #defaultDropHereText>
              <ng-container *ngSwitchCase="false">
                {{ t('drop-file-here') }}
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngIf="acceptFolders; else filesOnly">
                  {{ t('upload-files-and-folders') }}
                </ng-container>
                <ng-template #filesOnly>
                  {{ 'upload-files' | transloco }}
                </ng-template>
              </ng-container>
            </ng-template>
          </p>
        </div>
        <ng-container [ngSwitch]="multiple">
          <ng-container *ngSwitchCase="false">
            <label class="interactive-text" (click)="fileInput.click()">{{ t('select-file') }}</label>
          </ng-container>
          <ng-container *ngSwitchDefault [ngTemplateOutlet]="multipleFilesSelectTemplate"></ng-container>
        </ng-container>

        <div class="mt-2 text-sm text-gray-400" *ngIf="multiple && showEmptyFoldersInfo">
          <sup>*</sup>
          <ng-container>{{ t('empty-folders-will-be-uploaded-only-when-dropped-to-the-dialog') }}</ng-container>
        </div>

        <div class="mt-2 text-sm text-gray-400" *ngIf="text">
          {{ text }}
        </div>
      </div>

      <div *ngIf="selected" class="flex flex-col rounded-xl border border-gray-300 overflow-hidden h-full">
        <x-files-tree
          #filesTreeComponent
          class="rounded-xl h-full"
          [files]="selected.files"
          [folders]="selected.folders"
          [types]="types"
          [showTypes]="showTypes"
          [maxFolderDepth]="maxFolderDepth"
          [maxFileSize]="maxFileSize"
          (filesChanged)="onFilesChanged($event)"
          (foldersChanged)="onFoldersChanged($event)"
          (maxDepthLevelExceeded)="onMaxDepthLevelExceededChanged($event)"
          (maxFileSizeExceeded)="onMaxFileSizeExceededChanged($event)"
          (emptyFileDetected)="onEmptyFileSizeDetectedChanged($event)"
          (tooLongPathFileDetected)="onTooLongPathFileDetected($event)"
          (filesCount)="onFilesCountChanged($event)"
        ></x-files-tree>

        <div class="flex flex-row space-between border border-dashed m-3 rounded px-2.5 py-1.5" *ngIf="multiple">
          <div class="flex flex-row flex-nowrap">
            <x-svg-icon svgImageName="cloud-upload" svgClass="w-5 h-5 text-primary-600"></x-svg-icon>
            <span class="ml-2 text-sm text-black font-semibold">{{ t('drop-files-and-folders-here') }}</span>
          </div>
          <ng-container [ngTemplateOutlet]="multipleFilesSelectTemplate"></ng-container>
        </div>
      </div>

      <div class="mt-2 text-sm font-normal text-gray-900">
        <p *ngIf="filesCount">{{ t('files-count', { value: filesCount }) }}, {{ filesSize | fileSize }}</p>
      </div>

      <div *ngIf="maxDepthLevelExceeded" class="mt-2">
        <p
          class="text-sm text-danger-600"
          [innerHtml]="t('maximum-nested-folder-depth-exceeded', { value: maxFolderDepth }) | safeHtml"
        ></p>
      </div>

      <div *ngIf="maxFileSizeExceeded" class="mt-2">
        <p
          class="text-sm text-danger-600"
          [innerHtml]="t('maximum-file-size-exceeded-info', { maxSize: maxFileSize | fileSize }) | safeHtml"
        ></p>
      </div>

      <div *ngIf="emptyFileSizeDetected" class="mt-2">
        <p
          class="text-sm text-danger-600"
          [innerHtml]="t('empty-file-size-info', { maxSize: maxFileSize | fileSize }) | safeHtml"
        ></p>
      </div>

      <div *ngIf="hasTooLongPathFileDetected" class="mt-2">
        <p class="text-sm text-danger-600" [innerHtml]="t('file-path-too-long')"></p>
      </div>

      <div class="mt-2">
        <ng-content></ng-content>
      </div>
    </section>

    <input
      class="hidden"
      type="file"
      #fileInput
      (change)="onFileChanged(fileInput)"
      [multiple]="multiple"
      [accept]="getAcceptedTypes()"
    />
    <input
      class="hidden"
      type="file"
      #folderInput
      (change)="onFileChanged(folderInput)"
      multiple
      webkitdirectory
      mozdirectory
    />
    <ng-template #multipleFilesSelectTemplate>
      <div class="flex flex-row items-center justify-center">
        <label class="interactive-text" (click)="fileInput.click()">{{ t('select-files') }}</label>
        <ng-container *ngIf="acceptFolders">
          <span class="text-sm px-2" *ngIf="acceptFolders">{{ t('or') }}</span>
          <label class="interactive-text" (click)="folderInput.click()">
            <ng-container>{{ t('select-folder') }}</ng-container>
            <sup>*</sup>
          </label>
        </ng-container>
      </div>
    </ng-template>
  </x-dialog>
</ng-container>
