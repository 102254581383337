import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[xDropdownTrigger]',
  standalone: true,
})
export class DropdownTriggerDirective {
  @Input() isTriggerDisabled = false;
  @Output() toggleOverlay: EventEmitter<boolean> = new EventEmitter<boolean>();

  isOpen = false;

  @HostListener('click')
  onClick() {
    if (!this.isTriggerDisabled) {
      this.isOpen = !this.isOpen;
      this.toggleOverlay.emit(this.isOpen);
    }
  }

  close() {
    this.isOpen = false;
  }
}
