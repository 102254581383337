import { Directive, Inject, Input } from '@angular/core';

export enum LabelComponentInjectors {
  Field = 'fieldName',
  Tooltip = 'hasTooltip',
}

@Directive()
export abstract class LabelComponent<T> {
  // eslint-disable-line @angular-eslint/directive-class-suffix
  @Input() fieldName: keyof T;
  @Input() hasTooltip = false;

  constructor(
    @Inject(LabelComponentInjectors.Field) fieldName: keyof T,
    @Inject(LabelComponentInjectors.Tooltip) hasTooltip: boolean
  ) {
    this.fieldName = fieldName;
    this.hasTooltip = !!hasTooltip;
  }

  getField(field: keyof T): keyof T {
    return field;
  }
}
