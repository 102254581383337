import { getAllEnumValues } from './enum';

export enum Timeframe {
  Week1 = 'week_1',
  Week2 = 'week_2',
  Week3 = 'week_3',
  Week6 = 'week_6',
  Month2 = 'month_2',
  More = 'more_2_month',
}

export namespace Timeframe {
  export const all = getAllEnumValues<Timeframe>(Timeframe);
}
