<div class="flex flex-1 flex-row items-center my-1.5">
  <span class="flex flex-1 flex-row items-center" *ngIf="documentLocation">
    <x-svg-icon
      svgImageName="folder-closed"
      svgClass="text-yellow-600 w-5 h-5 mr-1.5"
      *ngIf="documentLocation.location === 'dataroom'"
    ></x-svg-icon>
    <span class="block max-w-[12rem] line-clamp-1 text-ellipsis" [title]="documentLocation?.dirName ?? ''">
      {{ documentLocation.location === 'photos' ? ('photos' | transloco) : documentLocation.dirName }}
    </span>
  </span>
  <x-button
    color="white"
    class="h-fit"
    [ngClass]="{ 'ml-auto': documentLocation }"
    [matMenuTriggerFor]="isMediaFile(value.document) ? actionsMenu : undefined"
    (handleClick)="onSelectLocationClick(value.document)"
    [size]="documentLocation ? 'xs' : 'md'"
  >
    {{ (documentLocation ? 'change' : 'select-location') | transloco }}
  </x-button>
  <div class="cursor-pointer" (click)="clearLocation()" *ngIf="documentLocation">
    <x-svg-icon svgImageName="cross" svgClass="w-6 h-6 ml-1 text-gray-400 rounded-xl hover:bg-gray-200"></x-svg-icon>
  </div>
</div>

<mat-menu #actionsMenu>
  <ng-template matMenuContent>
    <div class="divide-y divide-gray-300">
      <div>
        <button (click)="onPhotosDirectorySelected()" mat-menu-item>
          <span>{{ 'photos' | transloco }}</span>
        </button>
        <button (click)="onDataroomDirectorySelected()" mat-menu-item>
          <span>{{ 'data-room' | transloco }}</span>
        </button>
      </div>
    </div>
  </ng-template>
</mat-menu>
