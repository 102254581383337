import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { QAAskDialogComponent } from './qa-ask-dialog.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SelectComponent } from '@common/components/select/select.component';
import { CheckboxComponent } from '@common/components/checkbox/checkbox.component';
import { TextareaComponent } from '@portal/components/textarea/textarea.component';
import { ButtonComponent } from '@common/components/button/button.component';
import { TagsInputComponent } from '@portal/components/tags-input/tags-input.component';
import { PortalModule } from '@angular/cdk/portal';
import { CdkConnectedOverlay, CdkOverlayOrigin } from '@angular/cdk/overlay';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { AttachReferenceButtonComponent } from '@portal/customer/components/attach-reference-button/attach-reference-button.component';
import { ReferencesPreviewComponent } from '@portal/customer/components/references-preview/references-preview.component';
import { SpinnerOverlayComponent } from '@common/spinner-overlay/spinner-overlay.component';

@NgModule({
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    MatTooltipModule,
    MatDialogModule,
    TagsInputComponent,
    TranslocoModule,
    DialogComponent,
    MatCheckboxModule,
    SelectComponent,
    CheckboxComponent,
    TextareaComponent,
    ButtonComponent,
    PortalModule,
    CdkOverlayOrigin,
    CdkConnectedOverlay,
    SvgIconModule,
    AttachReferenceButtonComponent,
    ReferencesPreviewComponent,
    SpinnerOverlayComponent,
  ],
  declarations: [QAAskDialogComponent],
  exports: [QAAskDialogComponent, MatDialogModule],
})
export class QaAskDialogModule {}
