<ng-container *ngIf="value; else dash">
  <x-qa-reference
    [messageId]="value.message_id"
    [finding]="value.finding"
    [photos]="value.photos"
    [documents]="value.documents"
    [report]="value.report"
    [reportChapter]="value.report_chapter"
    [pages]="value.pages"
    [targetBlank]="value.targetBlank"
    [useIconsOnly]="true"
    [maxPreviewThumbnails]="2"
    [scope]="value?.scope"
  ></x-qa-reference>
</ng-container>
<ng-template #dash>-</ng-template>
