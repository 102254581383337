<div class="flat-tree-container" *transloco="let t; read: 'upload-dialog'">
  <cdk-virtual-scroll-viewport #scrollViewport itemSize="35" class="relative overflow-auto">
    <div class="headers !sticky z-100" [style.top]="inverseOfTranslation()">
      <div class="column info" xResizableColumn>{{ t('name') }}</div>
      <div class="column size">{{ t('size') }}</div>
      <div *ngIf="progress" class="column progress">{{ t('progress') }}</div>
      <div *ngIf="types && showTypes" class="column type">{{ t('type') }}</div>
      <div *ngIf="!readonly" class="column remove">&nbsp;</div>
    </div>
    <ng-container *cdkVirtualFor="let dataSourceNode of dataSource">
      <div *ngIf="castToNode | call : dataSourceNode as node" class="tree-node-container" [style.height]="nodeHeight">
        <div
          class="tree-node"
          [ngClass]="{
            'size-exceeded bg-danger-600/20': (maxFileSize && node?.file?.size > maxFileSize) || node?.file?.size === 0
          }"
        >
          <div
            class="info column"
            [style.padding-left.px]="getNodePadding(node)"
            [title]="node.name"
            (click)="treeControl.toggle(node)"
          >
            <button class="flex justify-center items-center">
              <ng-container *ngTemplateOutlet="iconTemplate; context: { $implicit: node }"></ng-container>
            </button>

            <span class="flex items-center ml-1.5 label">
              {{ node.name }}

              <x-svg-icon
                *ngIf="maxFileSize && node?.file?.size > maxFileSize"
                class="ml-2 text-danger-800"
                [matTooltip]="t('maximum-file-size', { maxSize: maxFileSize | fileSize })"
                svgImageName="error"
              ></x-svg-icon>

              <x-svg-icon
                *ngIf="node?.file?.size === 0"
                class="ml-2 text-danger-800"
                [matTooltip]="t('minimum-file-size', { minSize: 0 })"
                svgImageName="error"
              ></x-svg-icon>

              <x-svg-icon
                *ngIf="maxFolderDepth && node.level > maxFolderDepth"
                class="ml-2 text-danger-800"
                [matTooltip]="t('maximum-folder-depth')"
                svgImageName="error"
              ></x-svg-icon>
            </span>
          </div>

          <span class="column size">
            <ng-container *ngIf="node.file && !node.file['hasGetFileError'] && node.file.size > 0">
              {{ node.file.size | fileSize }}
            </ng-container>
            <ng-container *ngIf="node?.file?.['hasGetFileError']">?</ng-container>
            <ng-container *ngIf="!node.file">-</ng-container>
          </span>

          <span *ngIf="progress" class="column progress">
            <ng-container *ngIf="node.file; else notFileProgressTemplate">
              <ng-container
                *ngIf="getFileProgress | call : progress : node.file as fileProgress; else notFileProgressTemplate"
              >
                <ng-container *ngIf="fileProgress.error; else fileProgressTemplate">
                  <x-svg-icon
                    svgImageName="exclamation-triangle"
                    svgClass="font-bold text-danger-500 w-4 h-4"
                  ></x-svg-icon>
                </ng-container>
                <ng-template #fileProgressTemplate>
                  <span [class]="getFileProgressClass | call : fileProgress">
                    {{ getFileProgressValue | call : fileProgress | localeNumber }}%
                  </span>
                </ng-template>
              </ng-container>
            </ng-container>
            <ng-template #notFileProgressTemplate>&nbsp;</ng-template>
          </span>

          <span *ngIf="types && showTypes" class="column type">
            <x-multi-select
              *ngIf="node.file; else noTypeTemplate"
              name="type"
              [(value)]="node.node.types"
              (valueChange)="onFileTypeChanged()"
              [options]="types"
            ></x-multi-select>
            <ng-template #noTypeTemplate>&nbsp;</ng-template>
          </span>

          <span *ngIf="!readonly" class="column remove">
            <x-svg-icon
              (click)="onRemoveNode(node)"
              class="cursor-pointer"
              svgImageName="trash-can"
              svgClass="h-4 w-4"
            ></x-svg-icon>
          </span>
        </div>
      </div>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template #iconTemplate let-node>
  <ng-container
    *ngTemplateOutlet="node?.file ? iconDocumentTemplate : iconFolderTemplate; context: { $implicit: node }"
  ></ng-container>
</ng-template>

<ng-template #iconFolderTemplate let-node>
  <x-svg-icon
    class="text-primary-600 mx-2"
    svgImageName="chevron-arrow-down"
    [ngClass]="{ '-rotate-90': !treeControl?.isExpanded(node) }"
  ></x-svg-icon>
  <ng-container *ngIf="treeControl?.isExpanded(node) && treeControl?.isExpandable(node); else folderClosed">
    <x-svg-icon class="folder text-yellow-500/90" svgImageName="folder-opened"></x-svg-icon>
  </ng-container>
  <ng-template #folderClosed>
    <x-svg-icon class="folder text-yellow-600" svgImageName="folder-closed"></x-svg-icon>
  </ng-template>
</ng-template>

<ng-template #iconDocumentTemplate let-node>
  <x-file-icon [extension]="getFileExtension | call : node.file"></x-file-icon>
</ng-template>
