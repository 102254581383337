<div
  *ngIf="withBorder; else notFoundAlert"
  class="flex flex-1 items-center justify-center py-4 w-full mx-auto border-dashed border-4 border-gray-300 rounded-xl"
>
  <ng-container [ngTemplateOutlet]="notFoundAlert"></ng-container>
</div>

<ng-template #notFoundAlert>
  <div class="text-center">
    <x-svg-icon svgImageName="no-results" svgClass="text-gray-900 w-20 h-20"></x-svg-icon>
    <h2 class="text-black mt-4">{{ customPrimaryText || ('results-not-found' | transloco) }}</h2>
    <p class="text-gray-400 mt-2">{{ customSecondaryText || ('results-not-found-description' | transloco) }}</p>
  </div>
</ng-template>
