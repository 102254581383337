<ng-container *ngIf="isDueDateMissed() as dueDateMissed; else dateTemplate">
  <ng-container
    [ngTemplateOutlet]="dateTemplate"
    [ngTemplateOutletContext]="{ $implicit: dueDateMissed }"
  ></ng-container>
</ng-container>
<ng-template #dateTemplate let-dueDateMissed>
  <div class="flex flex-col">
    <span
      class="inline-flex items-center whitespace-nowrap"
      [ngClass]="{
        'text-danger-700': dueDateMissed,
        'text-gray-800': !dueDateMissed
      }"
    >
      <x-svg-icon
        class="w-5 h-5 mr-0.5"
        svgClass="w-5 h-5"
        svgImageName="calendar-cross"
        *ngIf="dueDateMissed && !params?.data?.hideIcons"
      ></x-svg-icon>
      {{ dueDate | localeDate }}
    </span>
    <span
      class="text-sm mt-1 whitespace-normal"
      *ngIf="getGracePeriodNumber(params?.data); let gracePeriodNumber"
      [ngClass]="{
        'text-danger-700': dueDateMissed,
        'text-gray-400': !dueDateMissed
      }"
    >
      <ng-container [ngSwitch]="gracePeriodNumber">
        <ng-container *ngSwitchCase="1">{{ 'tickets-system.grace-periods.grace-period-1' | transloco }}</ng-container>
        <ng-container *ngSwitchCase="2">{{ 'tickets-system.grace-periods.grace-period-2' | transloco }}</ng-container>
        <ng-container *ngSwitchCase="3">
          <ng-container *ngIf="dueDateMissed; else lastExtensionTemplate">
            {{ 'tickets-system.grace-periods.last-extension-exceeded' | transloco }}
          </ng-container>
          <ng-template #lastExtensionTemplate>
            {{ 'tickets-system.grace-periods.last-extension' | transloco }}
          </ng-template>
        </ng-container>
      </ng-container>
    </span>
  </div>
</ng-template>
