import { DataRoom } from '@core/models/data-room';
import { Finding } from './finding';
import { Message } from './message';
import { Transform } from './transform';

export type ConversionStatus = 'pending' | 'processing' | 'failed' | 'ignored' | 'finished';

export interface Document {
  id: string;
  project_id: string;
  asset_id: string;
  name: string;
  description: string;
  types: Document.Type[];
  tags: string[];
  upload_token: string;
  user_id: string;
  is_published: boolean;
  created_at: Date;
  uploaded_at: Date;
  published_at?: Date;
  original_datetime?: Date;
  file_size?: number;
  findings: Finding.Short[];
  download_token: string;
  file_extension?: string;
  is_known: boolean;
  messages: Message.Short[];
  conversion_status: ConversionStatus;
  node?: DataRoom.Node;
  anchor?: string;
  storage_file_id?: string;
}

export namespace Document {
  export type Type =
    | 'expose'
    | 'offer'
    | 'contract'
    | 'report'
    | 'capex'
    | 'document'
    | 'qa'
    | 'ticket'
    // | 'tdd' | 'redflag' | 'bqü' | 'cqs' | 'monitoring' | 'audit' | 'assessment' | 'technicalreport' | 'tedd' | 'esg'
    | 'photo'
    | 'panorama'
    | 'video'
    | 'dataroom';

  export namespace Type {
    export const all: Type[] = [
      'expose',
      'offer',
      'contract',
      'report',
      'capex',
      'document',
      'qa',
      // 'tdd', 'redflag', 'bqü', 'cqs', 'monitoring', 'audit', 'assessment', 'technicalreport', 'tedd', 'esg',
      'photo',
      'panorama',
      'video',
      'dataroom',
    ];

    export const isPhoto = (type: Type): boolean => type === 'photo';

    export const isPanorama = (type: Type): boolean => type === 'panorama';

    export const isVideo = (type: Type): boolean => type === 'video';
  }

  export interface NewDocument {
    name: string;
    description: string;
    types: Type[];
    tags: string[];
  }

  export interface ListItem extends Document {
    user_id: string;
    user_email: string;
    upload_status: string;
  }

  export interface Short {
    id: string;
    project_id?: string;
    asset_id: string;
    name: string;
    anchor?: string;
    types?: Document.Type[];
    target_type?: Document.Type;
    conversion_status: ConversionStatus;
  }

  export interface PhotoShort {
    document_id: string;
    type: 'photo' | 'video';
  }

  export interface QA extends Short {
    types: ['report' | 'photo'];
    tags: string[];
  }

  export const transform = <T extends Document | Document.ListItem>(doc: T): T =>
    doc && {
      ...doc,
      created_at: Transform.date(doc.created_at),
      uploaded_at: Transform.date(doc.uploaded_at),
      published_at: Transform.date(doc.published_at),
      original_datetime: Transform.date(doc.original_datetime),
      file_size: doc.file_size || 0,
    };
}
