import { Component, Input, OnDestroy } from '@angular/core';
import { DestroyComponent } from '@common';
import { NgIf } from '@angular/common';

@Component({
  selector: 'x-video-viewer',
  templateUrl: './video-viewer.component.html',
  styleUrls: ['./video-viewer.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class VideoViewerComponent extends DestroyComponent implements OnDestroy {
  @Input() source?: {
    src: string;
    type: string;
  };
}
