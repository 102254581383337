<div [class]="classes" [ngClass]="{ 'cursor-pointer': control.enabled }">
  <input
    #checkbox
    [attr.id]="id"
    [attr.name]="name"
    [formControl]="control"
    [ngClass]="{
      'cursor-pointer': control.enabled,
      'cursor-not-allowed bg-gray-100': control.disabled,
      'ring-2 ring-offset-2 ring-danger-400': control.enabled && control.invalid && control.dirty,
      hidden: variant === 'toggle'
    }"
    [value]="value"
    [style.margin-top.px]="2"
    type="checkbox"
    class="h-4 w-4 rounded border-gray-300 text-primary-600 focus:ring-primary-500"
  />
  <div
    *ngIf="variant === 'toggle'"
    class="relative toggle-bg bg-gray-200 border-2 border-gray-200 h-6 w-12 rounded-full"
    (click)="onLabelClicked()"
  ></div>
  <label
    [attr.for]="id"
    [ngClass]="{
      'cursor-pointer text-gray-900': control.enabled,
      'text-gray-400': control.disabled,
    }"
    (click)="onLabelClicked()"
    class="ml-2 block text-sm"
  >
    <ng-content></ng-content>
  </label>
</div>
