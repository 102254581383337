import { ColDef, ValueFormatterParams, ValueGetterParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Document, Finding, Link } from '@core/models';
import { ReferenceCellRendererData } from '@portal/components/qa-overview/qa-reference-cell-renderer/qa-reference-cell-renderer.model';
import { CustomerRoutingService } from '@portal/customer/services';
import { LinkHelperService } from '@portal/services/link-helper.service';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { isNotEmpty } from 'src/app/utils';

export interface FindingPreviewComponentData {
  finding$: Observable<Finding>;
  findingColumns: ColDef[];
  isSmall?: boolean;
}

@Component({
  selector: 'x-finding-preview',
  templateUrl: './finding-preview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindingPreviewComponent {
  finding$: Observable<Finding>;
  findingColumns?: ColDef[];
  isSmall = true;

  constructor(
    private readonly customerRouting: CustomerRoutingService,
    private readonly linkHelperService: LinkHelperService,
    @Inject(MAT_DIALOG_DATA) data: FindingPreviewComponentData
  ) {
    this.finding$ = data?.finding$;
    this.findingColumns = data?.findingColumns;
    this.isSmall = data?.isSmall;
  }

  getFindingValue = (finding: Finding, column: ColDef): unknown => {
    const field = column.field as keyof Finding;
    const value: ReferenceCellRendererData =
      typeof column.valueGetter === 'function'
        ? column.valueGetter({
            data: finding,
          } as ValueGetterParams)
        : finding[field];

    if (typeof column.valueFormatter === 'function') {
      return column.valueFormatter({
        value,
        colDef: column,
        data: finding,
      } as ValueFormatterParams);
    }

    if (field === 'documents' && 'documents' in value) {
      const documents = value.documents as Document.Short[];
      return _.map(documents, (doc) => ({
        href: this.customerRouting.getDocumentFileLink(doc, undefined, 'finding', doc.anchor),
        name: doc.name,
        types: doc.types,
      }));
    } else if (field === 'photos' && 'photos' in value) {
      const photos = value.photos as Document.Short[];
      return _.map(photos, (doc) => ({
        href: this.customerRouting.getDocumentFileLink(doc, undefined, 'finding'),
        name: doc.name,
        types: doc.types,
      }));
    } else if (field === 'references' && 'links' in value) {
      const links = value.links as Link[];
      return (links || [])
        .map((link) => Link.toShortDocument(link, value.asset_id as string))
        .map((doc) => ({
          href: this.customerRouting.getDocumentFileLink(doc, undefined, 'finding'),
          name: doc.name,
          types: doc.types,
        }));
    }

    if (value instanceof Array) {
      return value.join(', ');
    }

    if (field === 'finding_processed') {
      return finding.finding;
    }

    return value;
  };

  isNotEmpty = (value: unknown): boolean => isNotEmpty(value);

  onOpenDocument(doc: { href: string; type: string }, event: MouseEvent): void {
    if (!event?.ctrlKey) {
      event?.preventDefault();
    }
    this.linkHelperService.open(doc.href, doc.type === 'photo' ? 'photo' : undefined);
  }

  stringify(val: unknown) {
    return JSON.stringify(val);
  }
}
