import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import {
  AlertDialogComponent,
  AlertDialogData,
  AlertType,
} from '../../../common/components/alert-dialog/alert-dialog.component';
import { ConfirmationDialogComponent } from '../../../portal/components/confirmation-dialog/confirmation-dialog.component';
import { DialogActions } from './dialog.actions';
import { ToastService, ToastType } from '@core/toast';

@Injectable()
export class DialogEffects {
  readonly closeAll$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DialogActions.closeAll),
        tap(() => this.dialog.closeAll())
      ),
    { dispatch: false }
  );

  readonly showProgress$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DialogActions.showProgress),
        tap(() =>
          this.dialog.open(AlertDialogComponent, {
            closeOnNavigation: true,
            disableClose: true,
            panelClass: 'transparent',
            data: <AlertDialogData>{
              type: AlertType.Progress,
            },
          })
        )
      ),
    { dispatch: false }
  );

  readonly showAlert$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DialogActions.showAlert),
        tap(({ data }) => {
          if (!data.template && data.type === 'error') {
            this.toast.showToast(data.message, ToastType.ERROR);
          } else {
            this.dialog.open(AlertDialogComponent, { data });
          }
        })
      ),
    { dispatch: false }
  );

  readonly showConfirmation$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DialogActions.showConfirmation),
        tap(({ data }) => {
          this.dialog.closeAll();
          this.dialog.open(ConfirmationDialogComponent, { data });
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private dialog: MatDialog, private toast: ToastService) {}
}
