import { UserActions } from '@core/state';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { AssetOverview } from '../../../../core/models';
import { PortalProjectAssetsActions } from './portal-project-assets.actions';

const adapter: EntityAdapter<ProjectAssetsReducer.AdapterEntity> =
  createEntityAdapter<ProjectAssetsReducer.AdapterEntity>({
    selectId: (entity) => _.head(entity?.assets)?.project_id || '',
  });

const projectAssetsInitialState = adapter.getInitialState({
  entities: {},
  ids: [],
});

const reducer = createReducer<EntityState<ProjectAssetsReducer.AdapterEntity>>(
  projectAssetsInitialState,
  on(PortalProjectAssetsActions.fetchSuccess, (state, { assets }) => adapter.upsertOne({ assets }, state)),
  on(PortalProjectAssetsActions.removeAssetForProject, (state, action) => {
    const entities = state.entities[action.project_id];
    const assets = _.filter(entities?.assets, (entity) => entity?.asset_id !== action.asset_id);

    return assets.length ? adapter.upsertOne({ assets }, state) : adapter.removeOne(action.project_id, state);
  }),
  on(UserActions.clearUser, (state) => adapter.removeAll(state))
);

export namespace ProjectAssetsReducer {
  export interface AdapterEntity {
    assets: AssetOverview[];
  }

  export const name = 'portal-project-assets';

  export const initialState = projectAssetsInitialState;

  export const assetsAdapter = adapter;

  export const portalProjectAssetsReducer = (state: EntityState<AdapterEntity> | undefined, action: Action) =>
    reducer(state, action);
}
