import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { UserLabelComponent } from './user-label.component';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [UserLabelComponent],
  exports: [UserLabelComponent],
})
export class UserLabelModule {}
