import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '@common/breadcrumb/breadcrumb.service';
import { AssetOverview, Project, Transform, User } from '@core/models';
import { ProjectsService, UserService } from '@core/services';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AdminAssetsService, AdminRoute } from '@portal/admin/services';
import { AdminRouterSelectors } from '@portal/admin/store';
import {
  AdminSelectorDialogComponent,
  ConfirmationDialogComponent,
  ConfirmationDialogData,
  CreateAssetDialogComponent,
  CreateAssetDialogData,
  PortalProjectAssetsActions,
  ProjectAssetsSelectors,
  UserDetailPopupComponent,
} from '@portal/components';
import { ImageSizeVersion } from '@portal/customer/services';
import { DownloadTokenAction } from '@portal/services/documents.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, first, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { isNotNil } from 'src/app/utils';
import { ProjectActions, ProjectsSelectors } from '../portal-projects';

@UntilDestroy()
@Component({
  selector: 'x-portal-project-details',
  templateUrl: './portal-project-details.component.html',
  styleUrls: ['./portal-project-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalProjectDetailsComponent {
  readonly admins$ = new BehaviorSubject<Project.Admin[]>([]);
  readonly projectId$ = this.store.select(AdminRouterSelectors.getProjectId);

  readonly assetOverview$ = combineLatest([
    this.projectId$,
    this.store.select(ProjectAssetsSelectors.getEntities),
    this.activatedRoute.queryParams,
  ]).pipe(
    map(([projectId, projectAssets]) => projectAssets[projectId] || []),
    tap((projectAssets) => {
      const queryParamsAssetId = this.activatedRoute.snapshot.queryParams?.assetId;
      if (queryParamsAssetId && projectAssets.length) {
        const selectedAsset = projectAssets?.find(
          (projectAssets: AssetOverview) => projectAssets?.asset_id === queryParamsAssetId
        );
        this.selectedAssetOverview = selectedAsset ? selectedAsset : null;
        this.breadcrumbService.extraBreadcrumbItems$.next([{ label: selectedAsset?.asset_name, path: '' }]);
        this.cdr.markForCheck();
      } else {
        this.selectedAssetOverview = null;
      }
    })
  );

  readonly details$ = combineLatest([
    this.projectId$.pipe(filter(isNotNil)),
    this.store.select(ProjectsSelectors.getProjects),
  ]).pipe(map(([projectId, projectsDict]) => projectsDict?.[projectId] as Project));

  readonly user$ = this.userService.user$;
  readonly adminRoute: typeof AdminRoute = AdminRoute;

  isUserAdmin: boolean | null = null;
  selectedAssetOverview: AssetOverview | null = null;

  constructor(
    private readonly store: Store,
    private readonly projectsService: ProjectsService,
    private readonly dialog: MatDialog,
    private readonly assetsService: AdminAssetsService,
    private readonly userService: UserService,
    private readonly transloco: TranslocoService,
    private readonly toastService: ToastService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.fetchAdmins();
    this.store.dispatch(ProjectActions.fetch());
    this.store.dispatch(PortalProjectAssetsActions.fetchForCurrentProject());
  }

  readonly newDownloadTokenGetter = (
    asset_id: string,
    { version, action }: { version?: ImageSizeVersion; action?: DownloadTokenAction }
  ) =>
    this.projectId$.pipe(
      switchMap((project_id) =>
        this.assetsService.getAdminAssetPhotoDownloadToken(
          {
            asset_id,
            project_id,
          },
          { version, action }
        )
      )
    );

  onAddAdminClick(projectId: string): void {
    this.dialog
      .open(AdminSelectorDialogComponent, {
        data: {
          projectId,
        },
      })
      .afterClosed()
      .pipe(first())
      .subscribe({
        next: () => {
          this.fetchAdmins();
          this.store.dispatch(PortalProjectAssetsActions.fetchForCurrentProject());
        },
      });
  }

  onAddAsset(projectId: string): void {
    const dialogRef = this.dialog.open<CreateAssetDialogComponent, CreateAssetDialogData, boolean>(
      CreateAssetDialogComponent,
      {
        data: {
          projectId,
        },
      }
    );

    dialogRef
      .afterClosed()
      .pipe(filter(Boolean), first())
      .subscribe({
        next: () => this.store.dispatch(PortalProjectAssetsActions.fetchForCurrentProject()),
      });
  }

  onAssetEdit(projectId: string, assetOverview: AssetOverview): void {
    this.assetsService
      .getAssetDetails({
        asset_id: assetOverview.asset_id,
        project_id: projectId,
      })
      .pipe(
        take(1),
        mergeMap((asset) =>
          this.dialog
            .open<CreateAssetDialogComponent, CreateAssetDialogData, boolean>(CreateAssetDialogComponent, {
              data: {
                projectId,
                asset,
              },
            })
            .afterClosed()
        )
      )
      .subscribe({
        next: () => this.store.dispatch(PortalProjectAssetsActions.fetchForCurrentProject()),
      });
  }

  onRemoveAdmin(projectId: string, admin: Project.Admin, event: MouseEvent): void {
    event.stopPropagation();

    this.dialog.open(ConfirmationDialogComponent, {
      data: <ConfirmationDialogData>{
        width: 300,
        variant: 'danger',
        acceptButtonText: this.transloco.translate('delete'),
        text: this.transloco.translate('project-details.remove-admin-confirm', {
          value: admin.user.email,
        }),
        onConfirm: () =>
          this.projectsService
            .removeProjectAdmin(projectId, admin)
            .pipe(first())
            .subscribe({
              next: () => {
                this.fetchAdmins();
                this.store.dispatch(PortalProjectAssetsActions.fetchForCurrentProject());
              },
              error: () =>
                this.toastService.showToast(
                  this.transloco.translate('project-details.failed-to-remove-an-admin'),
                  ToastType.ERROR
                ),
            }),
      },
    });
  }

  onUploadAssetPhoto({ asset: { project_id, asset_id }, file }: { asset: AssetOverview; file: File }): void {
    this.assetsService
      .uploadAssetPhoto({ asset_id, project_id, file })
      .pipe(first())
      .subscribe({
        next: () => this.store.dispatch(PortalProjectAssetsActions.fetchForCurrentProject()),
      });
  }

  showAdminDetail(admin: Project.Admin) {
    this.dialog.open(UserDetailPopupComponent, {
      data: {
        ...admin.user,
        first_access_at: Transform.date(admin.first_access_at),
        last_access_at: Transform.date(admin.last_access_at),
      },
    });
  }

  showOwnerDetails(owner: User) {
    this.dialog.open(UserDetailPopupComponent, {
      data: owner,
    });
  }

  onAssetOverviewSelected(asset: AssetOverview): void {
    this.selectedAssetOverview = asset;
    this.cdr.markForCheck();
    this.router
      .navigate([], {
        queryParams: { assetId: asset.asset_id },
      })
      .then(() => {
        this.breadcrumbService.extraBreadcrumbItems$.next([
          { label: this.selectedAssetOverview?.asset_name, path: '' },
        ]);
      });
  }

  resetSelectedAsset() {
    this.selectedAssetOverview = null;
    this.router.navigateByUrl(window.location.pathname);
  }

  private fetchAdmins(): void {
    this.projectId$
      .pipe(
        filter(isNotNil),
        take(1),
        switchMap((projectId) => this.projectsService.fetchProjectsAdmins(projectId)),
        tap((admins) => this.admins$.next(admins)),
        switchMap(() => combineLatest([this.user$, this.admins$])),
        map(([user, admins]) => !!admins.find((admin) => admin.user_id === user.id)),
        untilDestroyed(this)
      )
      .subscribe((isUserAdmin) => (this.isUserAdmin = isUserAdmin));
  }

  // TODO : restore it when asset remove is needed again
  // onAssetRemove({ asset_id, project_id, asset_name }: AssetOverview): void {
  //   this.dialog
  //     .open(CodeConfirmationDialogComponent, {
  //       data: <{ message: string }>{
  //         message: this.transloco.translate('project-details.remove-asset-confirm', { value: asset_name }),
  //       },
  //     })
  //     .afterClosed()
  //     .pipe(
  //       take(1),
  //       filter(Boolean),
  //       switchMap(() =>
  //         this.assetsService
  //           .removeAsset({
  //             asset_id,
  //             project_id,
  //           })
  //           .pipe(first())
  //       )
  //     )
  //     .subscribe({
  //       next: () => {
  //         this.store.dispatch(
  //           PortalProjectAssetsActions.removeAssetForProject({
  //             asset_id,
  //             project_id,
  //           })
  //         );
  //       },
  //       error: () =>
  //         this.toastService.showToast(
  //           this.transloco.translate('project-details.failed-to-remove-an-asset'),
  //           ToastType.ERROR
  //         ),
  //     });
  // }
}
