import { RefType } from '@portal/customer/components/attach-reference-button/reference.model';
import { Document as XDocument } from './document';

export interface Link {
  id: string;
  src_id: string;
  src_type: RefType;
  target_id: string;
  target_type: RefType;
  target_description: string;
  target_anchor?: string; // for anchors added in new findings form
  target_url?: string;
  anchor?: string; // for anchors already added
}

export namespace Link {
  export interface Document {
    id: string;
    project_id: string;
    asset_id: string;
    name: string;
    types: string[];
    anchor: string;
  }

  export const toShortDocument = (link: Link, asset_id: string): XDocument.Short =>
    <XDocument.Short>{
      id: link.target_id,
      name: link.target_description,
      target_type: link.target_type,
      types: [link.target_type],
      anchor: link.anchor || link.target_anchor,
      asset_id,
    };
}
