<mat-tab-group animationDuration="0" (selectedIndexChange)="onSelectedTabChanged($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'users' | transloco }}</span>
    </ng-template>
    <x-portal-users #usersGrid></x-portal-users>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span>{{ 'admins' | transloco }}</span>
    </ng-template>
    <x-portal-admin #adminsGrid></x-portal-admin>
  </mat-tab>
</mat-tab-group>
