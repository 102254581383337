<label [for]="id" class="block text-sm font-medium text-gray-700">
  {{ label }}
</label>
<mat-select
  #select
  class="mt-1"
  [ngClass]="{
    'border-danger-400 text-danger-400': control.invalid && control.touched,
    '!text-gray-500 !not-allowed !bg-gray-100': control.disabled || disabled
  }"
  panelClass="multi-select-dropdown rounded border border-gray-200 shadow"
  multiple
  [value]="value"
  [id]="id"
  [formControl]="control"
  [disableRipple]="true"
  [compareWith]="compareWithFn"
  (opened)="setCheckboxCheckedClassInSelectAll()"
  (openedChange)="openedChange.emit($event)"
>
  <ng-container *ngIf="isSelectAllAvailable">
    <mat-option
      [value]="selectAllControl.value"
      [class.is-checked]="selectAllControl.value"
      (click)="selectAllControl.patchValue(!selectAllControl.value)"
      dir="rtl"
      class="select-all"
    >
      {{ 'select-all' | transloco }}
    </mat-option>
    <hr class="h-px my-0 bg-gray-200 border-0" />
  </ng-container>

  <mat-option
    *ngFor="let option of getOptions(); trackBy: trackByValue"
    [value]="getOptionValue(option)"
    [title]="option.tooltip ?? ''"
    [disabled]="disabledValuesMap[$any(getOptionValue(option))]"
  >
    {{ getOptionLabel(option) }}
  </mat-option>
</mat-select>

<ng-template #chevronArrowDown>
  <mat-icon svgIcon="chevron-arrow-down"></mat-icon>
</ng-template>
