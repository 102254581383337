import { Injectable } from '@angular/core';
import { AvailableLanguages, Countries } from '@core/models';
import { TranslocoService } from '@ngneat/transloco';
import { TranslocoLocaleService } from '@ngneat/transloco-locale';
import { Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import setHtmlLang from 'src/app/utils/html-lang';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  readonly language$ = this.transloco.langChanges$.pipe(
    map((lang) => lang as AvailableLanguages),
    mergeMap((lang) => this.transloco.selectTranslation(lang).pipe(map(() => lang)))
  );

  get locale(): string {
    return this.translocoLocale.getLocale();
  }

  get lang(): AvailableLanguages {
    return this.transloco.getActiveLang() as AvailableLanguages;
  }

  constructor(private readonly transloco: TranslocoService, private readonly translocoLocale: TranslocoLocaleService) {
    this.transloco._handleMissingKey = (key) => key;
  }

  setLanguage(lang: AvailableLanguages): void {
    this.transloco.setActiveLang(lang);
    setHtmlLang(lang);
  }

  /**
   * Translate text and change translation when selected language changes
   */
  translateAsync(key: string): Observable<string> {
    return this.language$.pipe(map(() => this.transloco.translate(key)));
  }

  /**
   * Translate text according to current lang
   */
  translate(key: string): string {
    return this.transloco.translate(key);
  }

  getCountriesSortedByCurrentLanguage(countries: Countries[]): Countries[] {
    const countriesSortedByName = _(Countries)
      .map((code, name) => ({ code, name }))
      .sort((a, b) =>
        this.transloco.translate(a.name.toLowerCase()).localeCompare(this.transloco.translate(b.name.toLowerCase()))
      )
      .map(({ code }) => code)
      .value();

    return _.orderBy(countries, (country) => countriesSortedByName.indexOf(country));
  }
}
