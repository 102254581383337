import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'x-details-field-row',
  standalone: true,
  imports: [CommonModule, SvgIconModule, MatTooltipModule],
  templateUrl: './details-field-row.component.html',
  styleUrls: ['./details-field-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailsFieldRowComponent {
  @Input() tooltipText = '';
  @Input() label: string;
  @Input() labelClass = '';
  @Input() contentClasses = '';
}
