<ng-container *ngIf="translationMapping[fieldName | lowercase] || fieldName; let translationKey">
  <ng-container *ngIf="!hasTooltip; else templateWithTooltip">
    {{ translationKey | transloco }}
  </ng-container>
  <ng-template #templateWithTooltip>
    <span [title]="translationKey | transloco">
      {{ translationKey | transloco }}
    </span>
  </ng-template>
</ng-container>
