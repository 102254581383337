import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FindingField } from '@core/models';
import { LanguageService } from '@core/services';
import { Observable, ReplaySubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'x-finding-label',
  templateUrl: './finding-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FindingLabelComponent {
  @Input() hasTooltip = false;

  readonly fieldName$ = new ReplaySubject<FindingField>(1);
  readonly label$ = this.fieldName$.pipe(mergeMap((key) => this.getTranslation(key)));

  constructor(private readonly languageService: LanguageService) {}

  @Input() set fieldName(key: FindingField) {
    this.fieldName$.next(key);
  }

  getField(field: FindingField): FindingField {
    return field;
  }

  getTranslation(key: FindingField): Observable<string> {
    /**
     * t(finding-label.finding_type)
     * t(finding-label.buildings)
     * t(finding-label.rental_areas)
     * t(finding-label.trade)
     * t(finding-label.component)
     * t(finding-label.finding)
     * t(finding-label.finding_processed)
     * t(finding-label.guidance)
     * t(finding-label.risk_evaluation)
     * t(finding-label.extra_notes)
     * t(finding-label.photos)
     * t(finding-label.documents)
     * t(finding-label.overhaul_backlog_now)
     * t(finding-label.overhaul_backlog_now_plan)
     * t(finding-label.overhaul_backlog_next_year)
     * t(finding-label.overhaul_backlog_next_year_plan)
     * t(finding-label.plan_invest_next_years)
     * t(finding-label.plan_invest_next_years_plan)
     * t(finding-label.plan_invest_following_years)
     * t(finding-label.plan_invest_following_years_plan)
     * t(finding-label.risk_indication)
     * t(finding-label.risk_value_eur)
     * t(finding-label.lifetime_age)
     * t(finding-label.lifetime_default)
     * t(finding-label.lifetime_remaining)
     * t(finding-label.failure_probability)
     * t(finding-label.follow_up_costs)
     * t(finding-label.failure_effect)
     * t(finding-label.risk_value_result)
     * t(finding-label.created_at)
     * t(finding-label.deleted_at)
     * t(finding-label.current_no)
     * t(finding-label.messages)
     * t(finding-label.references)
     * t(finding-label.on_site_visit_at)
     * t(finding-label.location)
     * t(finding-label.level)
     * t(finding-label.status)
     * t(finding-label.task)
     */

    const i18nKey = `finding-label.${key}`;
    return this.languageService
      .translateAsync(i18nKey)
      .pipe(map((translated) => (translated === i18nKey ? translated : translated)));
  }
}
