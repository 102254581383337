import { getAllEnumValues } from './enum';

export enum DueDiligenceReason {
  Purchase = 'purchase',
  Reason = 'reason',
}

export namespace DueDiligenceReason {
  export const all = getAllEnumValues<DueDiligenceReason>(DueDiligenceReason);
}
