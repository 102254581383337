import { Pipe, PipeTransform } from '@angular/core';
import { FormatDateService } from '@core/services/format-date.service';

@Pipe({
  name: 'localeTime',
})
export class LocaleTimePipe implements PipeTransform {
  constructor(private readonly formatDateService: FormatDateService) {}

  transform(date: Date | undefined): string {
    if (!date) {
      return '';
    }
    return this.formatDateService.toLocaleTimeString(date) || '';
  }
}
