<button
  [type]="type"
  [class.block]="block"
  [ngClass]="{
    '!bg-transparent !border-0 hover:!bg-gray-50': !!iconButtonIconName,
    'color-primary': color === 'primary' && !muted && !iconButtonIconName,
    'color-secondary': color === 'secondary' && !muted,
    'color-success': color === 'success' && !muted,
    'color-white': (color === 'white' && !muted) || !!iconButtonIconName,
    'color-danger': color === 'danger' && !muted,
    'color-transparent': color === 'transparent',
    'color-primary-muted': color === 'primary' && muted,
    'color-secondary-muted': color === 'secondary' && muted,
    'color-white-muted': color === 'white' && muted,
    'focus:ring-2 focus:ring-offset-2': !active && !iconButtonIconName,
    'size-xs': size === 'xs' || !!iconButtonIconName,
    'size-sm': size === 'sm',
    'size-md': size === 'md' && !iconButtonIconName,
    'size-lg': size === 'lg',
    'size-xl': size === 'xl',
    'ring-2 ring-offset-2 active': active,
    '!rounded-full': shapeType === 'circle',
    'w-full': size === 'full'
  }"
  [disabled]="disabled"
  (click)="handleClick.emit($event)"
  class="inline-flex items-center border font-medium focus:outline-none cursor-pointer disabled:opacity-60 disabled:pointer-events-none truncate select-none {{
    classes || ''
  }}"
>
  <ng-container *ngIf="iconButtonIconName; else noIconButtonContent">
    <x-svg-icon [svgImageName]="iconButtonIconName" svgClass="h-4 w-4"></x-svg-icon>
  </ng-container>
  <ng-template #noIconButtonContent>
    <ng-content></ng-content>
  </ng-template>
</button>
