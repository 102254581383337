import { Injectable } from '@angular/core';
import { SSE, User } from '@core/models';
import { SSEService, UserService } from '@core/services';
import { ComponentStore, tapResponse } from '@ngrx/component-store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

export type State = {
  read: boolean;
  timestamp: Date;
  event: SSE.EventData;
}[];
@Injectable({
  providedIn: 'root',
})
export class HeaderNotificationsStore extends ComponentStore<State> {
  readonly notifications$ = this.select((state) => state).pipe(shareReplay());

  private listenEvents = this.effect((event$: Observable<SSE.EventData>) =>
    event$.pipe(
      tapResponse(
        (event: SSE.EventData) => this.addEvent(event),
        () => void 0
      )
    )
  );

  private addEvent = this.updater((state, event: SSE.EventData) => [
    {
      read: false,
      timestamp: new Date(),
      event,
    },
    ...state,
  ]);

  private removeEvent = this.updater((state, event: SSE.EventData) => _.filter(state, (s) => s.event !== event));

  private markEventAsRead = this.updater((state, event: SSE.EventData) => {
    const index = _.findIndex(state, (s) => s.event === event);

    if (index === -1) {
      return state;
    }

    const left = state.slice(0, index);
    const right = state.slice(index + 1);

    return [
      ...left,
      {
        read: true,
        timestamp: state[index].timestamp,
        event,
      },
      ...right,
    ];
  });

  private clearEvents = this.updater((state, user: User.ActiveUser | undefined) => []);

  constructor(private readonly sseService: SSEService, private readonly userService: UserService) {
    super([]);

    this.listenEvents(this.sseService.sseEvent$);
    this.clearEvents(this.userService.user$);
  }

  remove(notification: SSE.EventData): void {
    if (!notification) {
      return;
    }

    this.removeEvent(notification);
  }

  markRead(notification: SSE.EventData): void {
    if (!notification) {
      return;
    }

    this.markEventAsRead(notification);
  }
}
