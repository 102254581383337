interface Links {
  imprint: string;
  dataProtection: string;
  terms: string;
}

export interface Environment {
  documentTitle: string;
  production: boolean;
  apiUrl: string;
  portalApiUrl: string;
  links?: Links;
  document: {
    maxConcurrentUploads: number;
  };
  tokens: {
    map: string;
  };
  reportTemplateBuilderEnabled?: boolean;
  notificationPlanEnabled?: boolean;
  logSSE?: boolean;
  idleTimeout: number;
}

export const environmentBase: Environment = {
  documentTitle: 'DOREE+',
  production: false,
  apiUrl: '/api',
  portalApiUrl: 'portal/v1',
  links: {
    imprint: './imprint/',
    dataProtection: './data-protection/',
    terms: './terms/',
  },
  document: {
    maxConcurrentUploads: 5,
  },
  tokens: {
    map: '',
  },
  reportTemplateBuilderEnabled: true,
  notificationPlanEnabled: true,
  logSSE: true,
  idleTimeout: 120,
};
