<span class="icon-font icon-font-close" mat-dialog-close (click)="close()"></span>

<div class="container">
  <div class="user-container">
    <span>
      {{ 'logged-in-as' | transloco }}&nbsp;
      <span class="user">{{ data.user | userName }}</span>
    </span>
  </div>

  <a *ngIf="notificationPlanEnabled" mat-dialog-close (click)="close()" [routerLink]="'/user/notification-plan'">
    <ng-container>{{ 'notification-plan.title' | transloco }}</ng-container>
    ›
  </a>

  <a (click)="onLogOut()" mat-dialog-close>
    <ng-container>{{ 'log-out' | transloco }}</ng-container>
    ›
  </a>
</div>
