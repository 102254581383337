import { ColDef, GridReadyEvent, RowClickedEvent } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DEBOUNCE_TIME } from '@app/utils/rxjs.utils';
import { ISelectOption } from '@common/components/select/select.model';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { Group, Message, QA, User } from '@core/models';
import { FormatDateService } from '@core/services/format-date.service';
import { ToastType } from '@core/toast';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { GridIconActionRendererComponent } from '@portal/components/grid-icon-action-renderer/grid-icon-action-renderer.component';
import { CheckboxCellRendererData } from '@portal/components/grid/cell-checkbox-renderer/cell-checkbox-renderer.component';
import { TicketsService } from '@portal/customer/components/asset-details/asset-tickets-container/tickets.service';
import { Reference } from '@portal/customer/components/attach-reference-button/reference.model';
import { DocumentLocation, GetMessagesFilters, QAService } from '@portal/customer/services';
import { DateHelperService } from '@portal/services/date-helper.service';
import * as _ from 'lodash';
import _isEqual from 'lodash/isEqual';
import { EMPTY, forkJoin, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs/operators';
import { isNotEmpty } from 'src/app/utils';
import { SimpleChanges } from 'src/custom-typings/angular';
import { BaseGrid, GridHelperService } from '../grid';
import { QAAskDialogComponent, QAAskDialogParams } from './qa-ask-dialog';
import { QAForwardDialogComponent, QAForwardDialogParams } from './qa-forward-dialog';
import { QAHeaderCellComponent } from './qa-header-cell';
import { QaHistoryDialogComponent, QaHistoryDialogData } from './qa-history-dialog';
import { QaOverviewGridHelperService } from './qa-overview-grid-helper.service';
import { DeleteAssetLinkReqBody, LinksService } from '@core/services';
import {
  AddReferencesToSystemDialogComponent,
  AddReferencesToSystemDialogComponentData,
} from '@portal/customer/components/asset-details/asset-tickets-container/components/add-references-to-system-dialog/add-references-to-system-dialog.component';
import { QaResponseData } from '@portal/customer/components/asset-details/asset-tickets-container/components/write-response-form/write-response-form.component';
import { CollaborationComponent } from '@portal/customer/components/asset-details/asset-collaboration/collaboration/collaboration.component';
import { SidePanelDialogRef } from '@portal/customer/services/side-panel-dialog.service';
import {
  MessageDetailsDialogComponent,
  MessageDetailsDialogComponentData,
} from '@portal/customer/components/asset-details/asset-collaboration/collaboration/message-details-dialog';

export const pinnedColumnDef: Partial<ColDef> = {
  pinned: 'right',
  suppressAutoSize: true,
  headerClass: 'condensed',
  headerComponentParams: {
    displayName: null,
  },
  sortable: false,
  resizable: false,
  minWidth: 40,
  width: 40,
  suppressColumnsToolPanel: true,
  suppressSizeToFit: true,
};

@UntilDestroy()
@Component({
  selector: 'x-qa-overview',
  templateUrl: './qa-overview.component.html',
  styleUrls: ['./qa-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QAOverviewComponent extends CollaborationComponent implements OnInit, OnChanges {
  @Input() user?: User;
  @Input() assetId?: string;
  @Input() questions?: Message[];
  @Input() groups?: Group[];
  @Input() filterEnabled = false;
  @Input() exportEnabled = false;
  @Input() addQuestionEnabled = true;
  @Input() actions?: ('approve' | 'reject' | 'add_to_report' | Group.Type)[] = ['approve', 'reject'];
  @Input() onAskQuestion?: (question: Message.NewQuestion) => Observable<unknown>;
  @Input() onApproveQuestion?: (
    approval: QA.Approval,
    type: 'answer' | 'message',
    message: Message
  ) => Observable<unknown>;
  @Input() onAnswerQuestion?: (message: Message, response: QaResponseData) => Observable<unknown>;
  @Input() getQaDetails?: (messageId: string) => Observable<Message>;
  @Input() onSaveDraftReponse?: (message: Message, response: QaResponseData) => Observable<unknown>;
  @Input() onSubmitResponseFiles?: (message: Message, files: File[]) => Observable<unknown>;
  @Input() onForwardQuestion?: (message: Message, group: string) => Observable<unknown>;
  @Input() getFindingLink?: (message: Message) => string;
  @Input() getForwardGroups?: (message: Message) => Observable<Group[]>;
  @Input() canWrite = false;
  @Input() historyEnabled = false;
  @Input() frozen = false;
  @Input() hasUserViewPermission = false;

  @Output() releaseMessage = new EventEmitter<Message>();
  @Output() export = new EventEmitter<Message[]>();
  @Output() addToReport = new EventEmitter<Message>();
  @Output() filtersChanged = new EventEmitter<GetMessagesFilters>();

  @ViewChild('tagInput') tagInput?: ElementRef<HTMLInputElement>;
  @ViewChild('qaFieldsTemplate', { static: false })
  qaFieldsTemplate?: TemplateRef<HTMLElement>;

  readonly defaultColDef: ColDef = {
    ..._.omit(BaseGrid.defaultColDef, ['minWidth']),
    flex: 1,
    headerComponentFramework: QAHeaderCellComponent,
  };

  get hasSelectedNodes(): boolean {
    return this.gridApi?.getSelectedNodes()?.length > 0;
  }

  get isSingleQuestionSelected(): boolean {
    return this.gridApi?.getSelectedNodes()?.length !== 1;
  }

  get selectedNodes(): unknown {
    return this.gridApi?.getSelectedNodes();
  }

  searchFieldFocused = false;
  startDate = '';
  endDate = '';

  readonly resize: 'fit' | 'auto' | 'none' = 'auto';

  readonly initialFiltersValue: GetMessagesFilters = {
    search_term: '',
    time_frame: null,
    date_to: null,
    date_from: null,
    status: null,
    scope: null,
    priority: null,
    questioner_group: null,
    adapted: true,
  };
  readonly canWriteTicket$ = this.ticketsService.canCreateTicket$;
  readonly groupChanges$ = new Subject();
  readonly filters = this.fb.group(<GetMessagesFilters>{ ...this.initialFiltersValue });
  readonly filtersValueChanges$ = this.filters.valueChanges.pipe(
    startWith(this.initialFiltersValue),
    map((filters) => {
      const { time_frame, date_from, date_to } = filters;
      if (time_frame === 'custom' && (!date_from || !date_to)) {
        return {
          ...filters,
          time_frame: null,
          date_to: null,
          date_from: null,
        };
      }
      return filters;
    }),
    pairwise(),
    filter(([prevFilters, filters]) => !_isEqual(prevFilters, filters)),
    map(([, filters]) => filters),
    debounceTime(DEBOUNCE_TIME),
    shareReplay(1),
    untilDestroyed(this)
  );

  readonly ignoredCountKeys = ['search_term', 'date_from', 'date_to', 'adapted'];
  readonly activeFiltersCount$ = this.filtersValueChanges$.pipe(
    map((filters) =>
      Object.keys(filters).reduce((acc, key) => acc + +(!this.ignoredCountKeys.includes(key) && !!filters[key]), 0)
    )
  );

  readonly filterOptions$: Observable<{ [key: string]: ISelectOption<boolean | string>[] }> = this.groupChanges$.pipe(
    map(() => ({
      adapted: [
        {
          translationKey: 'qa-overview.questions-and-todos',
          value: false,
        },
        {
          translationKey: 'qa-overview.all-questions',
          value: true,
        },
      ],
      scope: [
        {
          translationKey: 'qa-overview.scope.general',
          value: 'general',
        },
        {
          translationKey: 'qa-overview.scope.finding',
          value: 'finding',
        },
        {
          translationKey: 'qa-overview.scope.report',
          value: 'report',
        },
        {
          translationKey: 'qa-overview.scope.report_chapter',
          value: 'report_chapter',
        },
        {
          translationKey: 'qa-overview.scope.photo',
          value: 'photo',
        },
        {
          translationKey: 'qa-overview.scope.document',
          value: 'document',
        },
        {
          translationKey: 'qa-overview.scope.node',
          value: 'node',
        },
      ],
      status: [
        {
          translationKey: 'qa-overview.status.open',
          value: 'open',
        },
        {
          translationKey: 'qa-overview.status.answered',
          value: 'answered',
        },
      ],
      priority: [
        {
          translationKey: 'qa-overview.priority.high',
          value: 'high',
        },
        {
          translationKey: 'qa-overview.priority.medium',
          value: 'medium',
        },
        {
          translationKey: 'qa-overview.priority.low',
          value: 'low',
        },
      ],
      questioner_group: this.groups.map((group) => ({
        label: group.name,
        value: group.id,
      })),
      time_frame: this.dateHelperService.timeRangeOptions$.getValue(),
    })),
    untilDestroyed(this)
  );

  readonly messageUtils = Message;

  constructor(
    componentRef: ElementRef<HTMLElement>,
    localeDateService: FormatDateService,
    private readonly qaGridHelper: QaOverviewGridHelperService,
    baseGridHelper: GridHelperService,
    transloco: TranslocoService,
    private fb: FormBuilder,
    private dateHelperService: DateHelperService,
    private readonly ticketsService: TicketsService,
    private readonly linksService: LinksService,
    private readonly qaService: QAService
  ) {
    super(componentRef, localeDateService, baseGridHelper, transloco);
  }

  ngOnInit() {
    super.ngOnInit();

    this.filtersValueChanges$.subscribe((filters) => {
      this.filtersChanged.emit(filters);
    });
  }

  ngOnChanges({ exportEnabled, questions, actions, historyEnabled, groups }: SimpleChanges<QAOverviewComponent>): void {
    if (actions || historyEnabled) {
      this.columnDefs = this.getColumnDefs();
      setTimeout(this.resizeGrid.bind(this));
    }

    if (exportEnabled) {
      this.setColumnVisible('checkbox', this.exportEnabled);
    }

    if (questions) {
      setTimeout(this.resizeGrid.bind(this));
    }

    if (groups) {
      this.groupChanges$.next();
    }
  }

  getQaCheckboxColDef(): ColDef {
    return {
      ...this.gridHelper.getCheckboxColDef(),
      cellRendererParams: (params) =>
        <CheckboxCellRendererData>{
          ...params,
          setRowDataId: true,
        },
      hide: !this.exportEnabled,
    };
  }

  getColumnDefs = (): ColDef[] => [
    this.getQaCheckboxColDef(),
    ...this.qaGridHelper.getColumnDefs(
      {
        defaultColDef: this.defaultColDef,
        componentPortal: null,
      },
      this.hasUserViewPermission
    ),
    ...(this.actions?.includes('manager') ? [this.getReleaseMessageColumn()] : []),
    ...(this.actions?.includes('approve') ? [this.getApproveColumn()] : []),
    ...(this.actions?.includes('reject') ? [this.getRejectColumn()] : []),
    ...(this.actions?.includes('add_to_report') ? [this.getAddToReportColumn()] : []),
    ...(this.historyEnabled ? [this.getHistoryColumn()] : []),
  ];

  getColDef(field: keyof Message | null, colDef: Partial<ColDef> = {}): ColDef {
    return {
      ...super.getColDef(field, colDef),
      headerComponentFramework: QAHeaderCellComponent,
    };
  }

  onAddQuestion(): void {
    this.dialog
      .open<QAAskDialogComponent, QAAskDialogParams, Message.NewQuestion>(QAAskDialogComponent, {
        data: <QAAskDialogParams>{
          groups: this.groups,
          showReferenceSelector: true,
        },
      })
      .afterClosed()
      .pipe(
        take(1),
        filter(isNotEmpty),
        mergeMap((question) => this.onAskQuestion?.(question) || EMPTY)
      )
      .subscribe({
        next: () => this.fetchMessages.emit(),
        error: () => this.showError(),
      });
  }

  resizeGrid() {
    this.columnApi?.autoSizeColumn('current_no');
  }

  onGridReady(event: GridReadyEvent): void {
    super.onGridReady(event);
    this.gridApi.setGetRowNodeId((data: Message) => data.id);
  }

  onModelUpdated(): void {
    super.onModelUpdated();
    this.gridApi?.redrawRows();
  }

  onExportQuestions(): void {
    if (!this.exportEnabled) {
      return;
    }
    const qaMessages: Message[] = _.map(this.gridApi?.getSelectedNodes(), (node) => node.data);
    this.export.emit(qaMessages);
  }

  getQaDetailsDialogData(message: Message): MessageDetailsDialogComponentData {
    return {
      message,
      category: 'qa',
      submit: (response: QaResponseData) => this.onAnswerQuestion(message, response),
      submitFiles: (files: File[]) => this.onSubmitResponseFiles(message, files),
      saveDraft: (response: QaResponseData) => this.onSaveDraftReponse(message, response),
      removeFiles: (files: DeleteAssetLinkReqBody[]) => this.removeFiles(files),
      canWriteResponse: this.getCanAnswer(message),
      getDetails: this.getQaDetails.bind(this),
      fieldsTemplate: this.qaFieldsTemplate,
      approveMessage: this.approveQa.bind(this),
      rejectMessage: this.rejectQa.bind(this),
      forwardMessage: this.forwardQa.bind(this),
    };
  }

  onRowClicked(message: Message, event?: RowClickedEvent, writeResponseInitiallyEnabled?: boolean): void {
    this.openDetails(
      () => this.getQaDetails(message.id),
      (message) => this.openQaDetailsDialog(message, writeResponseInitiallyEnabled).ref.afterClosed()
    );
  }

  onDateChange(date: { date_from?: string; date_to?: string }) {
    this.filters.patchValue(date, { emitEvent: true });
  }

  onAddTicketToQa([rowData]) {
    const selectedMessage: Message = rowData.data;
    const reference: Reference = {
      src_id: selectedMessage.id,
      ref_type: 'qa',
      ref_id: selectedMessage.id,
      ref_data: {
        message_id: selectedMessage.id,
        current_no: selectedMessage.current_no,
        message: selectedMessage.message,
      },
    };
    this.ticketsService
      .openCreateTicketDialog(reference, 'qa')
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.toast.showToast(this.transloco.translate('ticket-create-success'), ToastType.SUCCESS);
        },
        error: () => {
          this.toast.showToast(this.transloco.translate('ticket-create-error'), ToastType.ERROR);
        },
      });
  }

  openAddAttachmentsToSystemDialog({ document, allDocuments }): Observable<DocumentLocation[]> {
    return this.dialog
      .open(AddReferencesToSystemDialogComponent, {
        data: <AddReferencesToSystemDialogComponentData>{
          documents: allDocuments,
          preselectedDocumentId: document.id,
          onSkipAndContinueClick: undefined,
          onAddSelected: (documentLocations) => this.handleAddSelectedLocationsToModules(documentLocations),
        },
      })
      .afterClosed();
  }

  private openQaDetailsDialog(message: Message, writeResponseInitiallyEnabled = false): SidePanelDialogRef {
    return this.sidePanel.open({
      title: this.transloco.translate('message-details.title', { value: message.current_no }),
      data: {
        ...this.getQaDetailsDialogData(message),
        writeResponseInitiallyEnabled,
      },
      component: MessageDetailsDialogComponent,
      hideSubmit: true,
      showFooter: Message.getCanApprove(message),
    });
  }

  private getApproveColumn(): ColDef {
    return this.getColDef('id', {
      ...pinnedColumnDef,
      tooltipValueGetter: ({ data }) =>
        this.messageUtils.getCanApprove(data)
          ? this.transloco.translate(`qa-overview.tooltip.approve`)
          : this.messageUtils.getCanAnswer(data)
          ? this.transloco.translate(`qa-overview.tooltip.answer`)
          : '',

      cellClass: ({ data }) =>
        this.messageUtils.getCanApprove(data) || this.messageUtils.getCanAnswer(data)
          ? 'icon-cell clickable-cell'
          : 'icon-cell',

      cellRendererFramework: GridIconActionRendererComponent,
      cellRendererParams: ({ data }) => {
        const iconName: AvailableIcons = this.messageUtils.getCanApprove(data)
          ? `success-check`
          : this.messageUtils.getCanAnswer(data)
          ? `answer`
          : null;
        return {
          ...data,
          iconName,
          svgClass: this.messageUtils.getCanApprove(data) && 'text-success-700',
        };
      },
      onCellClicked: (event) => this.onApproveCellClicked(event.data),
    });
  }

  private getReleaseMessageColumn(): ColDef {
    /**
     * t(qa-overview.tooltip.make-available)
     */
    return this.getColDef('id', {
      ...pinnedColumnDef,
      tooltipValueGetter: ({ data }) =>
        this.messageUtils.getCanReleaseMessageToAllGroups(data)
          ? this.transloco.translate(`qa-overview.tooltip.make-available`)
          : '',
      cellClass: 'icon-cell clickable-cell',
      cellRendererFramework: GridIconActionRendererComponent,
      cellRendererParams: ({ data }) =>
        this.messageUtils.getCanReleaseMessageToAllGroups(data)
          ? {
              ...data,
              iconName: 'campaign',
            }
          : data,
      onCellClicked: (event) =>
        this.messageUtils.getCanReleaseMessageToAllGroups(event.data) ? this.releaseMessage.emit(event.data) : void 0,
    });
  }

  private handleAddSelectedLocationsToModules(documentLocations: DocumentLocation[]) {
    return this.qaService.moveAttachments(this.assetId, documentLocations, 'qa');
  }

  private getRejectColumn(): ColDef {
    /**
     * t(qa-overview.tooltip.reject)
     * t(qa-overview.tooltip.forward)
     */
    return this.getColDef('id', {
      ...pinnedColumnDef,
      tooltipValueGetter: ({ data }) =>
        this.messageUtils.getCanApprove(data)
          ? this.transloco.translate(`qa-overview.tooltip.reject`)
          : this.messageUtils.getCanForward(data)
          ? this.transloco.translate(`qa-overview.tooltip.forward`)
          : ``,
      cellClass: ({ data }) =>
        this.messageUtils.getCanApprove(data)
          ? 'icon-cell clickable-cell'
          : this.messageUtils.getCanForward(data)
          ? `icon-cell clickable-cell`
          : `icon-cell`,
      cellRendererFramework: GridIconActionRendererComponent,

      cellRendererParams: ({ data }) => {
        const iconName: AvailableIcons = this.messageUtils.getCanApprove(data)
          ? `reject-cross`
          : this.messageUtils.getCanForward(data)
          ? `forward`
          : null;
        return {
          ...data,
          iconName,
          svgClass: this.messageUtils.getCanApprove(data) && 'text-danger-700',
        };
      },
      onCellClicked: (event) =>
        this.onRejectCellClicked(event.data, this.rejectQa.bind(this), (message) =>
          this.forwardQa(message).pipe(take(1), untilDestroyed(this)).subscribe()
        ),
    });
  }

  private getAddToReportColumn(): ColDef {
    /**
     * t(qa-overview.tooltip.add-to-the-report)
     */
    return this.getColDef('id', {
      ...pinnedColumnDef,
      tooltipValueGetter: () => this.transloco.translate(`link-reference`),
      cellClass: () => `icon-cell clickable-cell`,
      cellRenderer: () => `<span class="material-icons">add_link</span>`,
      onCellClicked: (event) => {
        this.addToReport.emit(event.data);
      },
    });
  }

  private getHistoryColumn(): ColDef {
    /**
     * t(qa-overview.tooltip.history)
     */
    return this.getColDef(null, {
      ...pinnedColumnDef,
      colId: 'history',
      tooltipValueGetter: () => this.transloco.translate('qa-overview.tooltip.history'),
      cellClass: 'icon-cell clickable-cell',
      cellRendererFramework: GridIconActionRendererComponent,
      cellRendererParams: ({ data }) => ({
        ...data,
        iconName: 'history',
      }),
      onCellClicked: (event) => {
        const message = event.data as Message;
        this.dialog.open<QaHistoryDialogComponent, QaHistoryDialogData>(QaHistoryDialogComponent, {
          data: {
            message,
            hasUserViewPermission: this.hasUserViewPermission,
          },
        });
      },
    });
  }

  private rejectQa(message: Message) {
    return this.rejectMessage(this.transloco.translate('reject-question'), message, this.onApproveQuestion.bind(this));
  }

  private answerMessage(message: Message): void {
    this.onRowClicked(message, undefined, true);
  }

  private removeFiles(files: DeleteAssetLinkReqBody[]) {
    return forkJoin(files.map((file) => this.linksService.deleteAssetLink(file)));
  }

  private forwardQa(message: Message): Observable<unknown> {
    this.isLoading$.next(true);
    const forwardGroups$ = this.getForwardGroups?.(message) || of([]);
    const forwardDialog$ = forwardGroups$.pipe(
      take(1),
      tap(() => {
        this.isLoading$.next(false);
      }),
      catchError(() => {
        this.showError();
        this.isLoading$.next(false);
        return EMPTY;
      }),
      switchMap((groups) =>
        this.dialog
          .open<QAForwardDialogComponent, QAForwardDialogParams, string>(QAForwardDialogComponent, {
            data: <QAForwardDialogParams>{
              groups,
            },
          })
          .afterClosed()
      )
    );

    return forwardDialog$.pipe(
      take(1),
      filter(isNotEmpty),
      switchMap((response) => this.onForwardQuestion?.(message, response) || EMPTY),
      tap(() => {
        this.fetchMessages.emit();
      }),
      catchError(() => {
        this.showError();
        return EMPTY;
      })
    );
  }

  private onApproveCellClicked(message: Message): void {
    if (!message?.with_to_do) {
      return;
    }

    if (this.messageUtils.getCanApprove(message)) {
      this.approveQa(message).pipe(take(1), untilDestroyed(this)).subscribe();
    }

    if (this.messageUtils.getCanAnswer(message)) {
      this.answerMessage(message);
    }
  }

  private approveQa(message: Message) {
    return this.approveMessage(message, this.onApproveQuestion.bind(this));
  }
}
