<div class="report-top">
  <button mat-icon-button [color]="'accent'" (click)="goBack()">
    <mat-icon>arrow_circle_left</mat-icon>
  </button>
  <div class="title">{{ 'photos' | transloco }}</div>
</div>

<div class="add-button-container">
  <div>{{ 'drop-file-s-here-to-upload' | transloco }}</div>
  <button type="button" class="primary button-add" (click)="openUpload()">
    <x-icon class="plus"></x-icon>
    <span class="capitalize" *ngTemplateOutlet="documentTypeTemplate"></span>
  </button>

  <ng-container [ngSwitch]="type$ | async">
    <ng-container *ngSwitchCase="'photo'">
      <button *ngIf="hasSelectedRows" type="button" class="primary button-add" (click)="onTagSelectedPhotos()">
        <ng-container>{{ 'add-tag' | transloco }}</ng-container>
      </button>

      <button
        *ngIf="!hasSelectedRows"
        type="button"
        class="primary button-add"
        disabled="true"
        title="{{ 'select-photos-to-add-tags' | transloco }}"
      >
        <ng-container>{{ 'add-tag' | transloco }}</ng-container>
      </button>
    </ng-container>
  </ng-container>
</div>

<div class="grid-container">
  <x-portal-document
    #portalDocument
    [type]="documentType$ | async"
    [types]="documentTypes"
    [documentTypeTemplate]="documentTypeTemplate"
  ></x-portal-document>
</div>

<div class="upload-container drop-files" (dragleave)="onUnhighlight($event)" (drop)="onDropFile($event)">
  <span>{{ 'drop-file-s-here-to-upload' | transloco }}</span>
</div>

<ng-template #documentTypeTemplate>
  <span [ngSwitch]="type$ | async">
    <ng-container *ngSwitchCase="'capex'">{{ 'cost-report' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="'photo'">{{ 'photo' | transloco }}</ng-container>
    <ng-container *ngSwitchCase="'report'">{{ 'report' | transloco }}</ng-container>
    <ng-container *ngSwitchDefault>{{ 'unknown' | transloco }}</ng-container>
  </span>
</ng-template>

<ng-template #uploadingTemplate let-range>
  <span *ngIf="typeLabel$ | async as typeLabel">
    {{ 'portal-document-uploading' | transloco : { value: typeLabel } }}
  </span>
  <ng-container *ngIf="range">&nbsp;{{ range.current }} of {{ range.total }}</ng-container>
</ng-template>

<ng-template #uploadFailTemplate let-statuses>
  <div>
    <span *ngIf="typeLabel$ | async as typeLabel">
      {{ 'portal-document-uploading-failed' | transloco : { value: typeLabel } }}
    </span>

    <div *ngIf="statuses">
      <br />
      <div *ngFor="let status of statuses">
        <span>{{ status.file?.name }}</span>
        <span *ngIf="status.errors">
          &nbsp;-&nbsp;
          <span>{{ status.errors[0].details }}</span>
        </span>
      </div>
    </div>
  </div>
</ng-template>
