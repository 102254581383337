import { AgGridModule } from '@ag-grid-community/angular';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { CallPipeModule, IconComponentModule, ImageUrlPipeModule, LocaleDatePipeModule, SafePipeModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { CountryLabelModule } from 'src/app/common/components/labels/country-label/country-label.module';
import { RiskLabelModule } from 'src/app/common/components/labels/risk-label';
import { CustomDateAdapter } from 'src/app/common/custom-date.adapter';
import { AddressCellRendererComponent } from './address-cell-renderer';
import { BaseGridComponent } from './base-grid.component';
import { CellRendererComponent } from './cell-renderer/cell-renderer.component';
import { ColumnsPanelComponent } from './columns-panel/columns-panel.component';
import { ContextMenuCellRendererComponent } from './context-menu-cell-renderer';
import { DatePickerCellRendererComponent } from './date-picker-cell-renderer/date-picker-cell-renderer.component';
import { EmptyHeaderCellComponent } from './header-cell/empty-header-cell';
import { HeaderCellComponent } from './header-cell/header-cell.component';
import { HeaderCheckboxRendererComponent } from './header-checkbox-renderer';
import { ImagePreviewCellRendererComponent } from './image-preview-cell-renderer/image-preview-cell-renderer.component';
import { ImagePreviewTooltipComponent } from './image-preview-tooltip/image-preview-tooltip.component';
import { RiskCellRendererComponent } from './risk-cell-renderer';
import { FindingNoRowsOverlayComponent } from './templates/finding-no-rows-overlay.component';
import { LoadingOverlayComponent } from './templates/loading-overlay.component';
import { NoRowsOverlayComponent } from './templates/no-rows-overlay.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { RiskPillComponent } from '@common/components/risk-pill/risk-pill.component';

const component = [
  BaseGridComponent,
  HeaderCellComponent,
  ColumnsPanelComponent,
  NoRowsOverlayComponent,
  FindingNoRowsOverlayComponent,
  LoadingOverlayComponent,
  DatePickerCellRendererComponent,
  EmptyHeaderCellComponent,
  ImagePreviewCellRendererComponent,
  ImagePreviewTooltipComponent,
  CellRendererComponent,
  AddressCellRendererComponent,
  RiskCellRendererComponent,
  HeaderCheckboxRendererComponent,
  ContextMenuCellRendererComponent,
];

@NgModule({
  imports: [
    AgGridModule,
    CommonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatMenuModule,
    MatIconModule,
    PortalModule,
    MatFormFieldModule,
    MatInputModule,
    RiskLabelModule,
    CountryLabelModule,
    IconComponentModule,

    SafePipeModule,
    ImageUrlPipeModule,
    LocaleDatePipeModule,
    CallPipeModule,
    TranslocoModule,
    SvgIconModule,
    BadgeComponent,
    RiskPillComponent,
  ],
  declarations: [...component],
  exports: [...component, AgGridModule],
  providers: [{ provide: DateAdapter, useClass: CustomDateAdapter }],
})
export class GridModule {}
