import { ChangeDetectionStrategy, Component, ElementRef, HostBinding, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@core/models';
import { environment } from '@environments';
import { Observable } from 'rxjs';

export interface UserInfoDialogComponentParams {
  isAdmin$?: Observable<boolean>;
  right: number;
  top: number;
  user: User.ActiveUser;

  onLogOut(): void;
  onNavigateTo?(destination: 'customer' | 'admin'): void;
}

@Component({
  selector: 'x-user-info-dialog',
  templateUrl: './user-info-dialog.component.html',
  styleUrls: ['./user-info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserInfoDialogComponent implements OnInit {
  @HostBinding('style.left.px')
  right = 0;

  // readonly isAdmin$ = this.store.select(UsersSelectors.getIsAdmin);
  readonly notificationPlanEnabled = environment.notificationPlanEnabled;

  constructor(
    private elementRef: ElementRef,
    public dialogRef: MatDialogRef<UserInfoDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: UserInfoDialogComponentParams
  ) {}

  @HostBinding('style.top.px')
  get top(): number {
    return this.data && this.data.top + 15;
  }

  close(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    const componentWidth =
      this.elementRef && (<HTMLElement>this.elementRef.nativeElement).getBoundingClientRect().width;
    this.right = this.data.right - componentWidth + 20;
  }

  onLogOut(): void {
    this.close();
    this.data?.onLogOut();
  }
}
