import { ComponentFactoryResolver, Injectable, Injector, TemplateRef, Type } from '@angular/core';
import { NotifierService } from 'angular-notifier';
import { TranslocoService } from '@ngneat/transloco';

import { ToastTemplateComponent } from '@app/core/toast/components/toast-template/toast-template.component';
import { ToastType } from '@app/core/toast';

interface IAdditionalErrorText {
  details: string;
  error: string;
  source: string;
}

@Injectable()
export class ToastService {
  constructor(
    private readonly notifierService: NotifierService,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly transloco: TranslocoService
  ) {}

  showToast(
    message: string,
    type: ToastType = ToastType.SUCCESS,
    additionalTexts?: string[] | IAdditionalErrorText[],
    additionalTextsPrefix = '',
    messageTemplate?: TemplateRef<unknown>,
    messageTemplateContext?: unknown,
    showAdditionalTextDetails = false
  ): string {
    const formattedAdditionalTexts = (
      additionalTexts?.length
        ? typeof additionalTexts[0] === 'string'
          ? additionalTexts.map((additionalText: string | IAdditionalErrorText) =>
              this.transloco.translate(`${additionalTextsPrefix}${additionalText}`)
            )
          : (additionalTexts as IAdditionalErrorText[]).map((additionalText) =>
              showAdditionalTextDetails
                ? `${additionalText?.source} - ${additionalText?.details}`
                : this.transloco.translate(
                    `${additionalTextsPrefix}${
                      additionalText?.source && additionalText?.error
                        ? `${additionalText?.source}-${additionalText.error}`
                        : additionalText?.details
                    }`
                  )
            )
        : []
    ) as string[];
    this.notifierService.getConfig().behaviour.autoHide = type === ToastType.ERROR ? false : 5000;
    const toastId = new Date().valueOf().toString(10);
    this.notifierService.show({
      type,
      message,
      id: toastId,
      template: this.createToastTemplateRef(
        ToastTemplateComponent,
        type,
        message,
        toastId,
        formattedAdditionalTexts,
        messageTemplate,
        messageTemplateContext
      ),
    });
    return toastId;
  }

  hideToast(toastId: string): void {
    this.notifierService.hide(toastId);
  }

  private createToastTemplateRef(
    componentType: Type<ToastTemplateComponent>,
    toastType: string,
    toastMessage: string,
    id: string,
    additionalTexts?: string[],
    messageTemplate?: TemplateRef<unknown>,
    messageTemplateContext?: unknown
  ): TemplateRef<ToastTemplateComponent> {
    const factory = this.componentFactoryResolver.resolveComponentFactory(componentType);
    const componentRef = factory.create(factory.create(Injector.create({ providers: [] })).injector);
    componentRef.setInput('type', toastType);
    componentRef.setInput('message', toastMessage);
    componentRef.setInput('additionalTexts', additionalTexts);
    componentRef.setInput('id', id);
    componentRef.setInput('messageTemplate', messageTemplate);
    componentRef.setInput('messageTemplateContext', messageTemplateContext);
    componentRef.changeDetectorRef.detectChanges();
    return componentRef.instance.templateRef;
  }
}
