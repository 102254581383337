<div
  class="flex flex-row p-4 rounded-xl"
  [ngClass]="{
    '!text-gray-600 bg-gray-50': variant === 'gray',
    '!text-primary-600 bg-primary-50': variant === 'primary',
    '!text-gray-600 bg-amber-100': variant === 'warning'
  }"
>
  <x-svg-icon
    class="mr-2.5"
    svgClass="w-6 h-6"
    [svgImageName]="icon"
    [ngClass]="{ 'text-warning-600': variant === 'warning' }"
  ></x-svg-icon>
  <div class="flex flex-col">
    <span class="font-semibold mb-2" *ngIf="title">
      {{ title }}
    </span>
    <ng-content></ng-content>
  </div>
</div>
