import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import { AdminAssetsService } from '../../../admin/services';
import { AdminRouterSelectors } from '../../../admin/store';
import { PortalProjectAssetsActions } from './portal-project-assets.actions';

@Injectable()
export class PortalProjectAssetsEffects {
  readonly loadAssets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortalProjectAssetsActions.fetch),
      mergeMap(({ project_id }) =>
        this.assetsService
          .fetchAdminAssets(project_id)
          .pipe(map((assets) => PortalProjectAssetsActions.fetchSuccess({ assets })))
      ),
      catchError(() => of(PortalProjectAssetsActions.fetchFail()))
    )
  );

  readonly loadAssetsForCurrentProject$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PortalProjectAssetsActions.fetchForCurrentProject),
      withLatestFrom(this.store.select(AdminRouterSelectors.getProjectId)),
      mergeMap(([, project_id]) =>
        this.assetsService
          .fetchAdminAssets(project_id)
          .pipe(map((assets) => PortalProjectAssetsActions.fetchSuccess({ assets })))
      ),
      catchError(() => of(PortalProjectAssetsActions.fetchFail()))
    )
  );

  constructor(private actions$: Actions, private assetsService: AdminAssetsService, private store: Store<unknown>) {}
}
