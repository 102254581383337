import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';

@Component({
  selector: 'x-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @Input() tooltip: string;

  get isOpen(): boolean {
    return this.isClicked || this.isHovered;
  }

  isClicked = false;
  isHovered = false;

  @HostListener('mouseenter')
  onMouseEnter(): void {
    this.isHovered = true;
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.isHovered = false;
  }

  onOpen(): void {
    this.isClicked = true;
  }

  onClose(): void {
    this.isClicked = false;
  }
}
