import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  Injector,
  Input,
  Output,
  TemplateRef,
} from '@angular/core';
import { LabelComponentInjectors } from '@common';
import * as _ from 'lodash';

export interface ColumnsPanelSetting<T> {
  columnId: keyof T;
  displayName: keyof T;
  hide: boolean;
}

@Component({
  selector: 'x-columns-panel',
  templateUrl: './columns-panel.component.html',
  styleUrls: ['./columns-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColumnsPanelComponent<T> {
  @Input() columns: ColumnsPanelSetting<T>[] = [];
  @Input() fieldTemplate?: TemplateRef<unknown>;
  @Input() labelComponent?: ComponentType<unknown>;
  @Input() translationPrefix?: string = '';

  @Output() toggleVisibility = new EventEmitter<ColumnsPanelSetting<T>>();
  @Output() panelOpened = new EventEmitter<boolean>();

  @HostBinding('class.opened')
  get isPanelOpened(): boolean {
    return this.isPanelOpenedValue;
  }
  set isPanelOpened(value: boolean) {
    this.isPanelOpenedValue = value;
    this.emitPanelOpened();
  }

  emitPanelOpened = _.debounce(() => {
    this.panelOpened.emit(this.isPanelOpened);
  }, 251);

  isSettingsDisplayed = false;

  private isPanelOpenedValue = false;

  onToggleVisibility(column: ColumnsPanelSetting<T>): void {
    this.toggleVisibility.emit({
      ...column,
      hide: !column.hide,
    });
  }

  getComponentPortal(labelComponent: ComponentType<T>, column: ColumnsPanelSetting<T>): ComponentPortal<T> {
    const { displayName, columnId } = column;
    const injector = Injector.create({
      providers: [
        {
          provide: LabelComponentInjectors.Field,
          useValue: _.isUndefined(displayName) ? columnId : displayName,
        },
        {
          provide: LabelComponentInjectors.Tooltip,
          useValue: false,
        },
      ],
    });

    return new ComponentPortal(labelComponent, null, injector);
  }
}
