import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isPositiveNum',
})
export class IsPositiveNumPipe implements PipeTransform {
  transform(value: number | unknown): boolean {
    if (!value) {
      return false;
    }
    if (typeof value === 'number') {
      return value > 0;
    }
    return false;
  }
}
