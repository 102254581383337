<div
  *transloco="let t; read: 'footer'"
  class="text-sm flex text-gray-500 space-y-3"
  [ngClass]="{
    'items-center': itemsAlign === 'center',
    'items-end': itemsAlign === 'end',
    'justify-between flex-nowrap items-center flex-row-reverse': isExternalHorizontalView,
    'flex-col': !isExternalHorizontalView
  }"
>
  <div class="flex justify-center flex-row space-x-4 xl:space-x-8 whitespace-nowrap flex-wrap">
    <p *ngIf="inlineLogo && !isExternalHorizontalView">{{ t('name') }}</p>
    <a [href]="links.imprint" (click)="onAnchorClick($event, links.imprint)">{{ t('imprint') }}</a>
    <a [href]="links.dataProtection" (click)="onAnchorClick($event, links.dataProtection)">{{ t('privacy') }}</a>
    <a [href]="links.terms" (click)="onAnchorClick($event, links.terms)">{{ t('terms') }}</a>
  </div>
  <p class="!m-0" *ngIf="isExternalHorizontalView; else internalFooterName">
    {{ t('external-rights') }}
  </p>
  <ng-template #internalFooterName>
    <p *ngIf="!inlineLogo">{{ t('name') }}</p>
  </ng-template>
</div>
