<ng-container *transloco="let t; read: 'message-details'">
  <ng-container *ngIf="isQuestion; else basicMessage">
    <x-details-field-row [label]="t('question')">
      <p class="break-words" [innerHtml]="message.message | newLine"></p>
    </x-details-field-row>
    <ng-container *ngIf="message.status !== 'open'">
      <x-details-field-row *ngIf="message.response" [label]="t('answer')">
        <div [innerHtml]="message.response | newLine"></div>
      </x-details-field-row>
      <ng-container *ngIf="getResponseReferences(message); let references">
        <ng-container *ngIf="references.length">
          <x-details-field-row label="{{ t('answer-references') }}">
            <x-references-preview
              [showLabels]="true"
              [canRemoveReference]="true"
              [assetId]="message.asset_id"
              [attachedReferencesList]="references"
            ></x-references-preview>
          </x-details-field-row>
        </ng-container>
      </ng-container>
    </ng-container>
    <x-details-field-row [label]="t('created-at')">
      <div>{{ message.message_created_at | localeDate : true }}</div>
    </x-details-field-row>
    <x-details-field-row [label]="t('priority')">
      <x-priority-indicator [priorityValue]="message.priority"></x-priority-indicator>
    </x-details-field-row>
    <x-details-field-row [label]="t('questioner')" *ngIf="message.message_user">
      <x-username-renderer
        [value]="{ user: message.message_user, fallbackName: message.message_user?.email || '-' }"
      ></x-username-renderer>
    </x-details-field-row>
    <x-details-field-row [label]="t('from')" *ngIf="message.message_group">
      <div>{{ message.message_group?.name || '-' }}</div>
    </x-details-field-row>
    <x-details-field-row [label]="t('recipient')" *ngIf="message.to_group">
      <div>{{ message.to_group?.name || '-' }}</div>
    </x-details-field-row>
    <x-details-field-row [label]="t('status')" *ngIf="getStatusBadgeData(message.status) as badgeData">
      <x-badge [text]="badgeData.text" [badgeType]="badgeData.badgeType"></x-badge>
    </x-details-field-row>
    <x-details-field-row *ngIf="rejected_reason" [label]="t('rejected')">
      <p [innerHtml]="rejected_reason | newLine"></p>
    </x-details-field-row>
  </ng-container>

  <ng-template #basicMessage>
    <x-details-field-row [label]="t('note')">
      <div [innerHtml]="message.message | newLine" class="break-words"></div>
    </x-details-field-row>
    <x-details-field-row [label]="t('created-at')">
      <div>{{ message.message_created_at | localeDate : true }}</div>
    </x-details-field-row>
    <x-details-field-row *ngIf="message.response && message.status !== 'open'" [label]="t('answer')">
      <div [innerHtml]="message.response | newLine"></div>
    </x-details-field-row>
    <x-details-field-row *ngIf="message.rejected_reason" [label]="t('rejected')">
      <div [innerHtml]="message.rejected_reason | newLine"></div>
    </x-details-field-row>
  </ng-template>

  <ng-container *ngIf="getReferences(message); let references">
    <x-details-field-row [label]="t('reference')">
      <x-references-preview
        *ngIf="references?.processedReferences?.length"
        [assetId]="message.asset_id"
        [attachedReferencesList]="references.processedReferences"
      ></x-references-preview>
      <x-qa-reference
        *ngIf="references?.chapterReference"
        [messageId]="message.id"
        [reportChapter]=""
        [isReferenceThumbnailShowed]="true"
        [isExtendedFindingMode]="true"
      ></x-qa-reference>
      <ng-container *ngIf="!references?.chapterReference && !references?.processedReferences?.length">-</ng-container>
    </x-details-field-row>
  </ng-container>
</ng-container>
