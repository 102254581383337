<x-dialog
  title="{{ customTitle || ('tags' | transloco) }}"
  [submitBtnText]="'save' | transloco"
  [cancelBtnText]="'cancel' | transloco"
  (handleClose)="dialog.close(undefined)"
  (handleSubmit)="onSubmit()"
>
  <x-tags-input
    (tagsChanged)="handleTagsChanged($event)"
    [tags]="tags"
    [allTags]="filteredTags$ | async"
    [disabled]="editOption === 'DELETE_ONLY'"
    [placeholder]="editOption === 'ADD_ONLY' ? customTitle : editOption === 'DELETE_ONLY' ? '-' : ('tags' | transloco)"
    [errorMessage]="errorMessage"
  ></x-tags-input>

  <div class="flex flex-row flex-wrap mt-4 space-x-2" *ngIf="notRemovableTags.length > 0">
    <x-badge
      *ngFor="let tag of notRemovableTags; trackBy: trackByIndex"
      text="{{ tag }}"
      badgeType="secondary_light"
    ></x-badge>
  </div>

  <mat-checkbox *ngIf="showAllPhotos" [formControl]="checkCtrl">
    <span>{{ 'apply-this-tags-to-all-photos' | transloco }}</span>
  </mat-checkbox>
</x-dialog>
