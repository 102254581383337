import { Injectable } from '@angular/core';
import { StorageService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HeaderActions } from 'src/app/common/page/header/header.actions';
import { ResolvedDataActions } from './resolver.actions';
import { ResolvedDataSelectors } from './resolver.selector';

@Injectable()
export class ResolverEffects {
  readonly fetchAssetLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResolvedDataActions.setAssetSettings),
      map((settings) => settings.data.settings?.asset.photo_download_token),
      switchMap((download_token) => {
        if (!download_token) {
          return of(undefined);
        }

        return this.storageService
          .getImageWithDownloadToken({ download_token })
          .pipe(map((blob) => window.URL.createObjectURL(blob)));
      }),
      map((url) => ResolvedDataActions.setAssetLogo({ url }))
    )
  );

  readonly fetchProjectLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ResolvedDataActions.setAssetSettings),
      map((settings) => settings.data.settings?.project?.logo_download_token),
      switchMap((download_token) => {
        if (!download_token) {
          return of(undefined);
        }

        return this.storageService
          .downloadDocument({ download_token })
          .pipe(map((blob) => window.URL.createObjectURL(blob)));
      }),
      map((url) => ResolvedDataActions.setProjectLogo({ url }))
    )
  );

  readonly setHeaderLogo$ = createEffect(() =>
    this.store.select(ResolvedDataSelectors.getProjectLogoUrl).pipe(map((url) => HeaderActions.setLogo({ url })))
  );

  constructor(private actions$: Actions, private store: Store, private storageService: StorageService) {}
}
