<span class="icon-font icon-font-close" mat-dialog-close></span>

<form #form="ngForm" class="container" (submit)="onSubmit()" novalidate autocomplete="off">
  <div class="row">
    <div class="col-5 col-md-12">
      <label>{{ 'email' | transloco }}</label>
    </div>
    <div class="col-7 col-md-12">
      <input type="email" [(ngModel)]="model.email" name="email" />
    </div>
  </div>

  <div class="row">
    <div class="col-1"></div>
    <div class="col-11 flex-end">
      <button type="submit" class="submit-user accent" [disabled]="!model.email">
        <span>{{ 'submit' | transloco }}</span>
      </button>
    </div>
  </div>
</form>
