import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Document } from '@core/models';
import _map from 'lodash/map';
import _each from 'lodash/each';
import _take from 'lodash/take';
import _uniq from 'lodash/uniq';
import { from, Subject } from 'rxjs';
import MimeUtility from 'mime';
import { map, mergeMap, take, tap } from 'rxjs/operators';
import { FolderOrFiles } from '@portal/customer/components/asset-details/data-room/data-room-tree/data-room-upload-dialog';
import { FilesTreeComponent } from '@portal/components/files-tree/files-tree.component';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoService } from '@ngneat/transloco';

export interface UploadOutput {
  fileTypeMap?: Map<File, Document.Type[]>;
  files: File[];
  folders: string[];
}

const IGNORED_UPLOAD_FILES = [
  'Thumbs.db', // Windows image thumbnail cache
  '.DS_Store', // macOS folder metadata
  '.Spotlight-V100', // macOS indexing service
  '.Trashes', // macOS folder for files that have been put into the trash
  'desktop.ini', // Windows folder settings
  '._*', // macOS resource fork files on non-HFS volumes
  '.AppleDouble', // macOS files for storing resource forks and Finder info
  '.LSOverride', // macOS file indicating the opening of a file in a non-default app
  '.VolumeIcon.icns', // macOS custom volume icon
  '.com.apple.timemachine.donotpresent', // macOS Time Machine backup exclusion
  '.auto', // Auto directory on some UNIX systems
  '.arch-ids', // Used by some version control systems
  '.lck', // Lock files used by various applications
  '.lock', // Lock files used by various applications
];

@Component({
  selector: 'x-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadComponent implements AfterViewInit, OnChanges {
  @Input() action?: string;
  @Input() dataTransfer?: DataTransfer;
  @Input() disabled?: boolean;
  @Input() multiple = true;
  @Input() acceptFolders = true;
  @Input() openOnInit: FolderOrFiles;
  @Input() showEmptyFoldersInfo = true;
  @Input() showTypes = true;
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() text?: string;
  @Input() types?: Document.Type[];
  @Input() customDropHereText?: string;
  @Input() allowedExtensions?: string[] = [];
  @Input() customAccept?: string;
  @Output() handleClose = new EventEmitter<void>();
  @Output() upload = new EventEmitter<UploadOutput>();
  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
  @ViewChild('folderInput') folderInput: ElementRef<HTMLInputElement>;
  @ViewChild('filesTreeComponent') filesTreeComponent: FilesTreeComponent;

  filesCount?: number;
  filesSize?: number;
  maxDepthLevelExceeded?: boolean;
  maxFileSizeExceeded?: boolean;
  emptyFileSizeDetected?: boolean;
  hasTooLongPathFileDetected?: boolean;
  selected?: UploadOutput;
  hasErrorsOnLoadingFiles = false;

  readonly maxFolderDepth = 15;
  readonly maxFileSize = 524288000; // 500 MB;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly toast: ToastService,
    private readonly transloco: TranslocoService
  ) {}

  handleDataTransfer(dataTransfer: DataTransfer | null): void {
    if (!dataTransfer) {
      return;
    }

    const items = Array.from(dataTransfer.items).map((item) => item.webkitGetAsEntry());
    const hasFolders = items.some((entry) => entry.isDirectory);

    if (!this.acceptFolders && hasFolders) {
      return;
    }

    if (!this.multiple && items.length > 1) {
      return;
    }

    from(dataTransfer.items)
      .pipe(
        take(dataTransfer.items.length),
        map((transferItem) => transferItem.webkitGetAsEntry()),
        mergeMap((entry) => this.traverseFileTree(entry as FileEntry)),
        tap((selected) => {
          if (this.hasErrorsOnLoadingFiles) {
            this.toast.showToast(
              this.transloco.translate(
                selected.files.length === 0
                  ? 'upload-dialog.couldnt-load-files'
                  : 'upload-dialog.couldnt-load-some-files'
              ),
              ToastType.ERROR
            );
          } else if (selected.files.length === 0) {
            this.toast.showToast(
              this.transloco.translate('upload-dialog.provided-directory-is-empty'),
              ToastType.ERROR
            );
          }
          if (selected.files.length > 0) {
            this.selected = this.getUpdatedSelectedFiles(selected);
          }
        })
      )
      .subscribe({
        complete: () => this.cdr.markForCheck(),
      });
  }

  ngOnChanges({ dataTransfer }: SimpleChanges): void {
    if (dataTransfer) {
      this.handleDataTransfer(this.dataTransfer);
    }
  }

  ngAfterViewInit() {
    if (this.openOnInit === 'files') {
      this.fileInput?.nativeElement?.click();
    }
    if (this.openOnInit === 'folder') {
      this.folderInput?.nativeElement?.click();
    }
  }

  onClose(): void {
    this.handleClose.emit();
  }

  onDrag(event?: MouseEvent): void {
    event?.preventDefault();
    event?.stopPropagation();
  }

  onDropFile(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();
    this.handleDataTransfer(event.dataTransfer);
  }

  onFileChanged(input: HTMLInputElement): void {
    const fileArray: File[] = Array.from(input.files);
    const filteredFiles = fileArray.filter((file) => !this.shouldIgnoreFile(file));
    console.log(filteredFiles);
    filteredFiles.forEach(async (file) => {
      try {
        // Attempt to read a blob from the file to check for file system errors
        const blob = file.slice(0, 1);
        const text = await blob.text(); // or any other operation to trigger an error if the file is inaccessible
        console.log(text);
      } catch (err) {
        file['hasGetFileError'] = true;
      }
    });
    const selected = this.getDirectoriesFromFileList(filteredFiles);
    if (selected.files.length) {
      this.selected = this.getUpdatedSelectedFiles(selected);
    } else {
      this.toast.showToast(this.transloco.translate('upload-dialog.couldnt-load-files'), ToastType.ERROR);
    }
    this.fileInput.nativeElement.value = '';
  }

  onFilesChanged(fileTypeMap: Map<File, Document.Type[] | undefined>): void {
    const filesIterator = fileTypeMap.keys();
    const files = Array.from(filesIterator);
    const selected = this.getDirectoriesFromFiles(files);

    this.selected = {
      ...this.getUpdatedSelectedFiles(selected, true),
      fileTypeMap: fileTypeMap as Map<File, Document.Type[]>,
    };
    this.filesSize = files.reduce((acc, file) => acc + file.size, 0);

    if (files.length === 0) {
      this.selected = undefined;
    }
  }

  onFilesCountChanged(count: number): void {
    this.filesCount = count;
  }

  onFoldersChanged(folders: string[]): void {
    if (this.selected) {
      this.selected = {
        ...this.selected,
        folders,
      };
    }
  }

  onMaxDepthLevelExceededChanged(exceeded: boolean): void {
    this.maxDepthLevelExceeded = exceeded;
    this.cdr.markForCheck();
  }

  onMaxFileSizeExceededChanged(exceeded: boolean): void {
    this.maxFileSizeExceeded = exceeded;
    this.cdr.markForCheck();
  }

  onEmptyFileSizeDetectedChanged(exceeded: boolean): void {
    this.emptyFileSizeDetected = exceeded;
    this.cdr.markForCheck();
  }

  onTooLongPathFileDetected(exceeded: boolean): void {
    this.hasTooLongPathFileDetected = exceeded;
    this.cdr.markForCheck();
  }

  onSubmit(): void {
    if (this.maxFileSizeExceeded || this.emptyFileSizeDetected) {
      this.filesTreeComponent.scrollToErrorNode();
    } else {
      const selected = this.selected ? this.selected : ({ files: [], folders: [] } as UploadOutput);
      this.upload.emit({
        files: selected.files,
        folders: selected.folders.map((folderName) => folderName.trim()),
        fileTypeMap: this.types ? selected.fileTypeMap : undefined,
      });
    }
  }

  getAcceptedTypes() {
    const mimeTypes: string[] = this.allowedExtensions.map((ext) => MimeUtility.getType(ext) || ext);
    const acceptedTypes = _uniq([...mimeTypes, ...this.allowedExtensions.map((ext) => `.${ext}`)]).join(', ');
    return this.customAccept ? this.customAccept : acceptedTypes;
  }

  private renameDuplicateFiles(files: File[]): File[] {
    const pathsCountMap = new Map<string, number>();
    const renamedFiles: File[] = [];

    const getNewFileName = (name: string, count: number): string => {
      const [baseName, extension] = splitFileName(name);
      return `${baseName} (${count})${extension}`;
    };

    const splitFileName = (name: string): [string, string] => {
      const lastDotIndex = name.lastIndexOf('.');
      if (lastDotIndex === -1) return [name, ''];
      const baseName = name.substring(0, lastDotIndex);
      const extension = name.substring(lastDotIndex);
      return [baseName, extension];
    };

    const getDirectoryPath = (relativePath: string): string => {
      const lastSlashIndex = relativePath.lastIndexOf('/');
      return lastSlashIndex === -1 ? '' : relativePath.substring(0, lastSlashIndex);
    };

    const getPathProperty = (file: File) => file.webkitRelativePath || file['path'] || file.name || '';

    files.forEach((file) => {
      const path = getPathProperty(file);
      const pathCount = pathsCountMap.get(path) || 0;

      let newName = undefined;
      if (pathCount > 0) {
        newName = getNewFileName(file.name, pathCount + 1);
      } else if (files.filter((file) => getPathProperty(file) === path).length > 1) {
        newName = getNewFileName(file.name, 1);
      }

      if (newName) {
        const renamedFile = new File([file], newName, { type: file.type });
        const dirPath = getDirectoryPath(getPathProperty(file));
        renamedFile['path'] = `${dirPath}/${newName}`;
        renamedFiles.push(renamedFile);
      } else {
        renamedFiles.push(file);
      }

      pathsCountMap.set(path, pathCount + 1);
    });

    return renamedFiles;
  }

  private shouldIgnoreFile(file: File): boolean {
    const fileName = file.name;
    return IGNORED_UPLOAD_FILES.some((ignoredFileName) => {
      if (ignoredFileName.includes('*')) {
        const regex = new RegExp(`^${ignoredFileName.toLowerCase().replace('.', '\\.').replace('*', '.*')}$`);
        return regex.test(fileName.toLowerCase());
      }
      return fileName.toLowerCase() === ignoredFileName.toLowerCase();
    });
  }

  private isAcceptedFile(fileName: string): boolean {
    const fileExtension = fileName.split('.').pop().toLowerCase();
    return this.allowedExtensions.length ? this.allowedExtensions.includes(fileExtension) : true;
  }

  private getDirectoriesFromFileList(files: File[]): { folders: string[]; files: File[] } {
    const newFiles = _map(new Array(files.length), (_, index) => files[index]);
    return this.getDirectoriesFromFiles(newFiles);
  }

  private getDirectoriesFromFiles(filesCollection: File[]): { folders: string[]; files: File[] } {
    const files = _map(filesCollection, (file) => {
      file['path'] = file['path'] || file['webkitRelativePath'];
      return file;
    });
    const directories = [];

    _each(files, (file) => {
      const folders = (file['path']?.split('/') as string[]) || [];
      if (folders.length <= 1) {
        return;
      }
      folders.pop();
      directories.push(folders.join('/'));
    });

    return {
      folders: _uniq(directories),
      files,
    };
  }

  private async getFile(fileEntry: FileEntry): Promise<File> {
    try {
      return await new Promise((resolve, reject) => fileEntry.file(resolve, reject));
    } catch (err) {
      const file = new File([], fileEntry.name);
      file['hasGetFileError'] = true;
      this.cdr.markForCheck();
      return file;
    }
  }

  private getUpdatedSelectedFiles(selected: { files: File[]; folders: string[] }, reset = false): UploadOutput {
    const initialState = { files: [], folders: [] } as UploadOutput;

    if (!selected?.files?.length && !selected?.folders?.length && !reset) {
      return this.selected || initialState;
    }

    let updatedSelected: UploadOutput = this.selected
      ? {
          ...this.selected,
          files: [...this.selected.files],
          folders: [...this.selected.folders],
        }
      : initialState;

    updatedSelected = reset ? initialState : updatedSelected;

    updatedSelected = {
      ...updatedSelected,
      files: this.renameDuplicateFiles([...updatedSelected.files, ...selected.files]),
      folders: [...updatedSelected.folders, ...selected.folders],
    };

    if (!this.multiple) {
      updatedSelected = {
        ...updatedSelected,
        files: _take(updatedSelected.files, 1),
        folders: [],
      };
    }

    return updatedSelected;
  }

  private async readDirectoryAsync(directory: DirectoryEntry) {
    const dirReader = directory.createReader();
    let entries: Entry[] = [];
    const entriesSubj = new Subject<Entry[]>();
    entriesSubj.next([]);
    const getEntries = () => {
      dirReader.readEntries(
        function (results) {
          if (results.length) {
            entries = entries.concat(Array.from(results));
            getEntries();
          } else {
            entriesSubj.next(entries);
            entriesSubj.complete();
          }
        },
        (error) => {
          this.hasErrorsOnLoadingFiles = true;
          entriesSubj.error(error);
        }
      );
    };

    getEntries();
    return entriesSubj.toPromise();
  }

  private async traverseFileTree(
    entry: FileEntry | DirectoryEntry,
    path: string | null = null,
    folders: string[] = [],
    files: File[] = []
  ) {
    if (entry.isFile && this.isAcceptedFile(entry.name)) {
      const file = await this.getFile(entry as FileEntry);
      if (file?.name?.[0] !== '.') {
        file['path'] = entry.fullPath;
        files.push(file);
      }
    } else if (entry.isDirectory) {
      folders.push(entry.fullPath);

      try {
        const entries = await this.readDirectoryAsync(entry as DirectoryEntry);

        if (entries) {
          for (const i of entries.keys()) {
            const nextPath = (path || '') + entry.name + '/';
            await this.traverseFileTree(entries[i] as FileEntry | DirectoryEntry, nextPath, folders, files);
          }
        }
      } catch (e) {}
    }

    return {
      folders,
      files,
    };
  }
}
