<x-dialog
  [title]="data?.title"
  [submitBtnText]="data?.acceptButtonText ? data.acceptButtonText : undefined"
  [cancelBtnText]="data?.cancelButtonText ? data.cancelButtonText : undefined"
  (handleClose)="onCancel()"
  (handleSubmit)="onConfirm()"
  [submitDisabled]="data?.disabled | async"
  [submitColor]="data?.variant"
  [submitLoading]="isSubmitting"
  [isHeaderShowed]="data?.isHeaderShowed !== false"
  [hideCloseIcon]="data?.hideCloseIcon"
  [closeOnly]="data?.closeOnly"
  [ngClass]="{ danger: data?.variant === 'danger' }"
>
  <div class="flex flex-row" [ngClass]="minHeight" *ngIf="template; else defaultTemplate">
    <ng-container [ngTemplateOutlet]="dangerTriangle" *ngIf="data?.variant === 'danger'"></ng-container>
    <span class="flex flex-col flex-1">
      <ng-container *ngTemplateOutlet="template; context: { $implicit: data?.context }"></ng-container>
    </span>
  </div>
</x-dialog>

<ng-template #defaultTemplate>
  <div class="flex flex-row" [ngClass]="data?.textContainerClasses">
    <ng-container [ngTemplateOutlet]="dangerTriangle" *ngIf="data?.variant === 'danger'"></ng-container>
    <span [innerHTML]="data?.text | newLine | safeHtml" [ngClass]="data?.textClasses"></span>
  </div>
</ng-template>

<ng-template #dangerTriangle>
  <span
    class="flex items-center shrink-0 justify-center bg-danger-100 w-[80px] h-[80px] rounded-[80px] self-center mr-2.5"
  >
    <x-svg-icon svgImageName="exclamation-triangle" svgClass="font-bold text-danger-500 w-[60px] h-[60px]"></x-svg-icon>
  </span>
</ng-template>
