<x-dialog
  (handleSubmit)="onSubmit()"
  title="{{ 'message-question' | transloco }}"
  (handleClose)="onClose()"
  [submitDisabled]="!model.message"
>
  <form novalidate autocomplete="off">
    <x-textarea
      label="{{ 'message-dialog.input-label' | transloco }}"
      [autofocus]="true"
      [(value)]="model.message"
      name="message"
    ></x-textarea>
    <p class="mt-4 font-medium text-sm text-gray-700">{{ 'message-dialog.color-label' | transloco }}</p>
    <div class="mt-1 predefined-colors" *ngIf="!isResponse">
      <span
        class="color color-margin"
        *ngFor="let color of predefinedColors"
        [style.border-color]="color"
        [style.color]="color"
        [style.background]="color | lighten"
        (click)="onSelectColor(color)"
      >
        <span class="icon-font icon-font-check" *ngIf="model.color === color"></span>
      </span>
    </div>

    <ng-container *ngIf="data?.fields">
      <section>
        <label>
          <ng-container>{{ 'field' | transloco }}</ng-container>
        </label>
        <x-select [(value)]="model.report_chapter_field" [options]="getSelectOptions(data?.fields)"></x-select>
      </section>
    </ng-container>
  </form>
</x-dialog>
