import { Document } from '@core/models';
import { createAction, props } from '@ngrx/store';

export namespace AssetDetailsActions {
  export const fetchDocument = createAction('[Asset Details] Fetch Document');
  export const fetchDocumentSuccess = createAction(
    '[Asset Details] Fetch Document Success',
    props<{ data: Document.ListItem }>()
  );
  export const fetchDocumentError = createAction('[Asset Details] Fetch Document Error');
}
