<div
  [ngClass]="topContainerClass"
  [class]="'flex flex-col w-full h-full overflow-hidden ' + variantClasses[variant || 'default']"
>
  <div [ngClass]="classes" [class]="'w-full h-full ' + variantPaddings[variant || 'default']">
    <div class="flex flex-row items-center">
      <p *ngIf="title" class="font-semibold {{ titleClasses }}">{{ title }}</p>
      <ng-content select="[title-side]"></ng-content>
    </div>
    <ng-content></ng-content>
  </div>
  <ng-content select="[cardBottomArea]"></ng-content>
</div>
