import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { CallPipeModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { ImageViewerModule } from '../image-viewer/image-viewer.module';
import { PanoramaViewerModule } from '../panorama-viewer';
import { PhotoViewerComponent } from './photo-viewer.component';
import { VideoViewerComponent } from '@portal/components/video-viewer/video-viewer.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    ImageViewerModule,
    TranslocoModule,
    PanoramaViewerModule,
    VideoViewerComponent,
    CallPipeModule,
  ],
  declarations: [PhotoViewerComponent],
  exports: [PhotoViewerComponent],
})
export class PhotoViewerModule {}
