import { HttpErrorResponse } from '@angular/common/http';

export namespace api {
  interface Pagination<T = unknown> {
    data: T[];
    page: number;
    per_page: number;
  }

  export interface PaginationRequest extends Partial<Pagination> {
    sort_column?: string;
    sort_direction?: 'asc' | 'desc';
    search_str?: string;
  }

  export interface PaginationResponse<T> extends Pagination<T> {
    total_count: number;
  }

  export type Search = Partial<{
    query: string;
    scope: 'general' | 'finding' | 'qa' | 'report' | 'photo';
    page: number;
    per_page: number;
  }>;

  export interface SearchResult {
    abstract: string; // html
    document_id: string;
    id: string;
    rank: number;
    title: string;
    type: string;
    updated_at: Date;
    created_at: Date;
  }

  export const start = 'start-api-request';

  export interface Error<T> {
    error: string;
    details: string;
    source: keyof T;
  }

  export interface ErrorResponse<T> extends HttpErrorResponse {
    error: {
      errors?: Error<T>[];
    };
  }
}
