import { ColDef, IHeaderParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Project, ProjectListItem, User } from '@core/models';
import { ProjectsService, SSEService } from '@core/services';
import { FormatDateService } from '@core/services/format-date.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { AdminPortalSearchService } from '@portal/admin/services/admin-portal-search.service';
import { AdminRoutingService } from '@portal/admin/services/admin-routing.service';
import * as _ from 'lodash';
import { filter, take } from 'rxjs/operators';
import { CodeConfirmationDialogComponent } from '@portal/components/code-confirmation-dialog';
import { BaseGrid, BaseGridComponent, GridHelperService } from '../grid';
import {
  CreateProjectDialogComponent,
  PortalProjectsHeaderCellComponent,
  ProjectActions,
  ProjectsSelectors,
} from '@portal/components';
import {
  BadgeCellRendererComponent,
  BadgeCellRendererData,
} from '@portal/components/badge-cell-renderer/badge-cell-renderer.component';
import { CollapsedListCellRendererComponent } from '@portal/components/collapsed-list-cell-renderer/collapsed-list-cell-renderer.component';
import { ToastService, ToastType } from '@core/toast';
import { MatMenu } from '@angular/material/menu';
import { isNotNil } from '@app/utils';

@UntilDestroy()
@Component({
  selector: 'x-portal-projects',
  templateUrl: './portal-projects.component.html',
  styleUrls: ['./portal-projects.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalProjectsComponent extends BaseGridComponent<ProjectListItem> {
  @ViewChild('actionsMenu') actionsMenu: MatMenu;

  defaultColDef: ColDef = {
    ...BaseGrid.defaultColDef,
    initialFlex: 1,
    initialWidth: 100,
    tooltipValueGetter: ({ value }) => {
      if (value instanceof Date) {
        return this.getDateCellRenderer(value);
      }

      return value;
    },
  };

  readonly rootAndManager = [User.AdminRole.Root, User.AdminRole.Manager];
  readonly rowData$ = this.store.select(ProjectsSelectors.getAll);
  readonly relatedSseEvents$ = this.sseService.sseEvent$.pipe(
    filter(isNotNil),
    filter((event) => event.type === 'new_project'),
    untilDestroyed(this)
  );

  constructor(
    componentRef: ElementRef<HTMLElement>,
    localeDateService: FormatDateService,
    gridHelper: GridHelperService,
    transloco: TranslocoService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly adminRoutingService: AdminRoutingService,
    private readonly dialog: MatDialog,
    private readonly projectsService: ProjectsService,
    private readonly adminPortalSearchService: AdminPortalSearchService,
    private readonly toastService: ToastService,
    private readonly sseService: SSEService
  ) {
    super(componentRef, localeDateService, gridHelper, transloco);
    store.dispatch(ProjectActions.fetch());

    this.adminPortalSearchService
      .getSearchFilter()
      .pipe(untilDestroyed(this))
      .subscribe((rowData) => {
        this.gridOptions.api?.setRowData(rowData);
      });

    this.relatedSseEvents$.subscribe(() => {
      store.dispatch(ProjectActions.fetch());
    });
  }

  onAddProject(): void {
    this.dialog
      .open<CreateProjectDialogComponent, unknown, boolean>(CreateProjectDialogComponent, {
        disableClose: true,
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (isProjectAdded) => {
          if (isProjectAdded) {
            this.store.dispatch(ProjectActions.fetch());
          }
        },
      });
  }

  onRowClicked({ id }: ProjectListItem): void {
    const route = this.adminRoutingService.getProjectDetailsLink(id);
    this.router.navigate([route]);
  }

  resizeGrid(): void {}

  protected getColDef(field: keyof ProjectListItem, colDef: Partial<ColDef> = {}): ColDef {
    return {
      ...super.getColDef(field, colDef),
      headerComponentFramework: PortalProjectsHeaderCellComponent,
    };
  }

  protected getColumnDefs(): ColDef[] {
    return [
      this.getColDef('id', { hide: true }),
      this.getColDef('name', {
        minWidth: 150,
        flex: 2,
      }),
      this.getColDef('token', { hide: true }),
      this.getColDef('status', {
        hide: true,
        cellRendererFramework: BadgeCellRendererComponent,
        cellRendererParams: ({ value }: { value: Project['status'] }) =>
          <{ value: BadgeCellRendererData }>{
            value: {
              text: this.transloco.translate(`project-status.${value}`),
              badgeType: this.gridHelper.getBadgeType(value),
            },
          },
      }),
      this.getColDef('owner', {
        colId: 'owner.company',
        headerComponentParams: <IHeaderParams>{
          displayName: 'owner.company',
        },
        valueGetter: ({ data }) => {
          const { owner } = data as ProjectListItem;
          return owner?.company;
        },
      }),
      this.getColDef('product_type'),
      this.getColDef('team_leads', {
        hide: true,
        cellClass: 'team-leads-cell',
        minWidth: 200,
        cellRendererFramework: CollapsedListCellRendererComponent,
        cellRendererParams: ({ data }) => {
          const { team_leads } = data as ProjectListItem;
          return {
            value: team_leads.filter(Boolean).map((lead) => this.getFormattedUsername(lead)),
          };
        },
        tooltipValueGetter: ({ data }) => {
          const { team_leads } = data as ProjectListItem;
          return team_leads
            .filter(Boolean)
            .map((lead) => this.getFormattedUsername(lead))
            .join('\n');
        },
      }),
      this.getColDef('owner', {
        hide: true,
        colId: 'owner.name',
        headerComponentParams: <IHeaderParams>{
          displayName: 'owner.name',
        },
        valueGetter: ({ data }) => {
          const { owner } = data as ProjectListItem;
          return this.getFormattedUsername(owner);
        },
      }),
      this.getColDef('created_at', {
        cellRenderer: ({ value }) => this.getDateCellRenderer(value),
      }),
      // TODO : restore it, when project actions menu will be needed again
      // this.getColDef(null, {
      //   cellClass: 'no-padding flex-centered',
      //   colId: 'menu',
      //   minWidth: 50,
      //   maxWidth: 50,
      //   width: 50,
      //   resizable: false,
      //   suppressMovable: true,
      //   suppressColumnsToolPanel: true,
      //   lockPinned: true,
      //   pinned: 'right',
      //   cellRendererFramework: ContextMenuCellRendererComponent,
      //   cellRendererParams: ({ data }) =>
      //     <ContextMenuCellRendererParams>{
      //       menu: this.actionsMenu,
      //       data,
      //     },
      //   onCellClicked: (event) => event.event?.stopPropagation(),
      // }),
    ];
  }

  protected getFormattedUsername(user: User): string {
    return _.filter([user?.firstname, user?.lastname]).join(' ');
  }

  protected onRemoveProject([{ id, name }]: ProjectListItem[]): void {
    /**
     * t(portal-project.action.delete)
     */
    this.dialog
      .open(CodeConfirmationDialogComponent, {
        data: {
          message: this.transloco.translate(`portal-project.action.delete`, { value: name }),
        },
      })
      .afterClosed()
      .pipe(take(1))
      .subscribe((confirmed) => {
        if (confirmed) {
          this.projectsService
            .removeProject({ project_id: id })
            .pipe(take(1), untilDestroyed(this))
            .subscribe({
              next: () => this.store.dispatch(ProjectActions.fetch()),
              error: () =>
                this.toastService.showToast(this.transloco.translate('failed-to-remove-a-project'), ToastType.ERROR),
            });
        }
      });
  }
}
