import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Frequency } from '@core/models';

@Component({
  selector: 'x-frequency-label',
  templateUrl: './frequency-label.component.html',
  styleUrls: ['./frequency-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FrequencyLabelComponent {
  @Input() frequency: Frequency;

  readonly Frequency = Frequency;
}
