<span
  class="inline-block px-2.5 py-2 text-black rounded-lg cursor-pointer whitespace-nowrap"
  (click)="handleClick.emit($event)"
  [ngClass]="{
    'bg-gray-200': isActive,
    'hover:bg-gray-100': !isActive,
    'pointer-events-none text-gray-400': !isActive && disabled
  }"
>
  <ng-content></ng-content>
</span>
