import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'x-validation-error-label',
  templateUrl: './validation-error-label.component.html',
  styleUrls: ['./validation-error-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidationErrorLabelComponent {
  @Input() error;

  @HostBinding('class.has-error')
  get hasError() {
    return !!this.error;
  }
}
