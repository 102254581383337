<ng-container [ngSwitch]="value">
  <ng-container *ngSwitchCase="Status.Active">
    <ng-container *ngTemplateOutlet="statusChangeTemplate"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="Status.Blocked">
    <ng-container *ngTemplateOutlet="statusChangeTemplate"></ng-container>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <span class="status disabled">{{ value }}</span>
  </ng-container>
</ng-container>

<ng-template #statusChangeTemplate>
  <div class="status" mat-button [matMenuTriggerFor]="menu">{{ value }}</div>
  <mat-menu #menu="matMenu">
    <button
      *ngFor="let status of availableStatuses"
      mat-menu-item
      [disabled]="value === status"
      (click)="onStatusSelected(status)"
    >
      {{ status }}
    </button>
  </mat-menu>
</ng-template>
