import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { Tracker } from '../../../../core/models';

@Component({
  selector: 'x-portal-tracker-label',
  templateUrl: './portal-tracker-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalTrackerLabelComponent {
  @Input() fieldName: keyof Tracker;
}
