import * as _ from 'lodash';
import { getAllEnumValues } from './enum';

export enum Countries {
  Germany = 'de',
  Belgium = 'be',
  Denmark = 'dk',
  Finland = 'fi',
  Greece = 'gr',
  UnitedKingdom = 'gb',
  Ireland = 'ie',
  Italy = 'it',
  Luxembourg = 'lu',
  Liechtenstein = 'li',
  Netherlands = 'nl',
  Norway = 'no',
  Austria = 'at',
  Poland = 'pl',
  Sweden = 'se',
  Switzerland = 'ch',
  Spain = 'es',
  France = 'fr',
  UnitedStates = 'us',
}

export namespace Countries {
  export const all = sortCountries(getAllEnumValues<Countries>(Countries));

  export const reportLanguages = sortCountries([
    Countries.Germany,
    Countries.France,
    Countries.Spain,
    Countries.Italy,
    Countries.Denmark,
    Countries.UnitedStates,
  ]);
}

function sortCountries(countries: Countries[]): Countries[] {
  const countriesSortedByName = _(Countries)
    .map((code, name) => ({ code, name }))
    .sortBy(({ name }) => name)
    .map(({ code }) => code)
    .value();

  return _.orderBy(countries, (country) => countriesSortedByName.indexOf(country));
}

/* eslint-disable @typescript-eslint/naming-convention, no-underscore-dangle, id-denylist, id-match */
/* eslint-disable */
export const GermanLands: string[] = _.orderBy([
  'Baden-Württemberg',
  'Bayern',
  'Berlin',
  'Brandenburg',
  'Bremen',
  'Hamburg',
  'Hessen',
  'Mecklenburg-Vorpommern',
  'Niedersachsen',
  'Nordrhein-Westfalen',
  'Rheinland-Pfalz',
  'Saarland',
  'Sachsen-Anhalt',
  'Sachsen',
  'Schleswig-Holstein',
  'Thüringen',
]);

export const getCountryTranslationKeyName = (countryCode: Countries): string => {
  switch (countryCode) {
    case Countries.Germany:
      return 'germany';
    case Countries.Belgium:
      return 'belgium';
    case Countries.Denmark:
      return 'denmark';
    case Countries.Finland:
      return 'finland';
    case Countries.Greece:
      return 'greece';
    case Countries.UnitedKingdom:
      return 'united-kingdom';
    case Countries.Ireland:
      return 'ireland';
    case Countries.Italy:
      return 'italy';
    case Countries.Luxembourg:
      return 'luxembourg';
    case Countries.Liechtenstein:
      return 'liechtenstein';
    case Countries.Netherlands:
      return 'netherlands';
    case Countries.Norway:
      return 'norway';
    case Countries.Austria:
      return 'austria';
    case Countries.Poland:
      return 'poland';
    case Countries.Sweden:
      return 'sweden';
    case Countries.Switzerland:
      return 'switzerland';
    case Countries.Spain:
      return 'spain';
    case Countries.France:
      return 'france';
    case Countries.UnitedStates:
      return 'united-states';
    default:
      return countryCode;
  }
};
