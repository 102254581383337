import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToastModule } from '@app/core/toast/toast.module';
import { AdminGuard, AuthGuard, RoutingPortalGuard } from '@core/guards';

@NgModule({
  imports: [CommonModule, ToastModule],
  providers: [AdminGuard, AuthGuard, RoutingPortalGuard],
  exports: [ToastModule],
})
export class CoreModule {}
