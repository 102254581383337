import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'x-spinner',
  template: '',
  styleUrls: ['./spinner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerComponent {
  @Input() size: 'small' | 'medium' | 'big' | 'large';
  @Input() color: 'primary' | 'accent';

  @HostBinding('class')
  get cssClass(): string {
    return `${this.size || ''} ${this.color || ''}`;
  }
}
