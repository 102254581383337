import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Translation, TranslocoService } from '@ngneat/transloco';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslocoResolver implements Resolve<Translation> {
  constructor(private transloco: TranslocoService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Translation> {
    const lang = this.transloco.getActiveLang();
    return this.transloco.selectTranslation(lang);
  }
}
