<section class="flex flex-col space-y-1.5">
  <x-textarea
    [focus]="true"
    class="mt-2.5"
    name="response"
    [rows]="5"
    [label]="'response' | transloco"
    [required]="true"
    [disabled]="isSaving"
    [value]="model.response"
    (valueChange)="onResponseChanged($event)"
  ></x-textarea>
</section>
<section class="flex flex-col py-2">
  <div class="flex flex-row space-x-2.5">
    <x-button color="white" (handleClick)="fileInput.click()" [disabled]="isSaving">
      <x-svg-icon svgImageName="upload" svgClass="w-4 h-4 text-black"></x-svg-icon>
      <span class="pl-2">{{ 'upload-files' | transloco }}</span>
    </x-button>
    <x-attach-reference-button
      *ngIf="allowReferenceSelect"
      [disabled]="!multipleReferences && !!model?.references?.length"
      (referenceSelected)="onReferenceAdded($event)"
    ></x-attach-reference-button>
  </div>
  <input class="visibility-hidden" type="file" #fileInput (change)="onFileChanged(fileInput)" multiple />
  <strong *ngIf="model.files?.length > 0 || documents?.length > 0" class="mt-1 py-1.5">
    {{ 'attachments' | transloco }}
  </strong>
  <x-documents-preview
    [documents]="documents"
    [messageId]="messageId"
    [messageCategory]="messageCategory"
    [isDeleteDocumentPossible]="isDeleteDocumentPossible"
    (documentsDelete)="handleDocumentsDelete($event)"
  ></x-documents-preview>
  <ol class="flex flex-col space-y-2.5 mt-2.5">
    <li
      class="inline-flex flex-row items-center p-2 rounded-lg border border-gray-200 w-[320px] break-all line-clamp-2 text-ellipsis"
      *ngFor="let file of model.files; trackBy: trackByFileIndex"
    >
      <img
        *ngIf="file.imageSrc; else nameOnly"
        class="rounded-lg min-w-[2.5rem] max-w-[300px] max-h-[200px]"
        [alt]="file.name"
        [src]="file.imageSrc"
      />
      <ng-template #nameOnly>
        <x-file-icon
          *ngIf="file.name | fileExtension; let extension"
          [hasDotIndicator]="false"
          [extension]="extension"
        ></x-file-icon>
        <span class="ml-1.5 text-sm text-primary-600">{{ file.name }}</span>
      </ng-template>
      <x-svg-icon
        class="ml-1.5 hover:bg-gray-200 rounded-full cursor-pointer ml-auto"
        svgClass="w-5 h-5 text-gray-500"
        svgImageName="cross"
        (click)="onDeleteFileClick(file)"
      ></x-svg-icon>
    </li>
  </ol>
  <ng-container *ngIf="!!model?.references?.length">
    <strong class="pt-2.5">
      {{ 'reference' | transloco }}
    </strong>
    <x-references-preview
      [showLabels]="true"
      [canRemoveReference]="true"
      [assetId]="assetId$ | async"
      [attachedReferencesList]="model.references"
      (referenceRemoved)="onReferenceRemoved($event)"
    ></x-references-preview>
  </ng-container>
</section>
