import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContextMenuCellRendererComponent, EmptyHeaderCellComponent } from '@portal/components';
import { GridModule } from '@portal/components/grid/grid.module';
import { MessagesCellRendererComponent } from './messages-cell-renderer';

@NgModule({
  imports: [CommonModule, GridModule],
  exports: [
    GridModule,
    AgGridModule.withComponents([
      EmptyHeaderCellComponent,
      ContextMenuCellRendererComponent,
      MessagesCellRendererComponent,
    ]) as any, // eslint-disable-line @typescript-eslint/no-explicit-any
  ],
})
export class CustomerGridModule {}
