import {
  ProcessedReference,
  RefDocumentData,
  Reference,
} from '@portal/customer/components/attach-reference-button/reference.model';
import { MessageCategory } from '@portal/customer/services';
import { DataRoom } from './data-room';
import { Document } from './document';
import { Finding } from './finding';
import { Group } from './group';
import { QA } from './qa';
import { Report } from './report';
import { Transform } from './transform';
import { User } from './user';

export interface Message extends Message.Short {
  id: string;
  category: Message.Category;
  parent_id: string;
  asset_id: string;
  current_no: number;
  priority: Message.Priority;
  scope: Message.Scope;
  status: string;
  to_user_id: string;
  to_user?: User;
  to_group_id: string;
  to_group: Group;
  qa: Message.MessageRef;
  ticket: Message.MessageRef;
  qa_workflow_id: string;
  qa_workflow: QA.Workflow;
  qa_workflow_step_id: string;
  qa_workflow_step: QA.WorkflowStep;
  last_qa_workflow_step_id: string;
  finding_id: string;
  finding: Finding;
  document_id: string;
  document: Document;
  document_page: number;
  document_coordinates: string;
  node_id: string;
  node: DataRoom.Node;
  report_id: string;
  report: Report;
  report_chapter_id: string;
  report_chapter: Report.Chapter;
  report_chapter_field: string;
  message: string; // text
  message_created_by: string;
  message_user: User;
  message_created_at: Date;
  message_created_group_id: string;
  message_group: Group;
  message_approved_by: string;
  message_approved_at: Date;
  message_approved_group_id: string;
  message_approved_reason: string; // text
  released_for_all_at: Date;
  response?: string; // text;
  response_created_by: string;
  response_user: User;
  reponse_created_group_id: string;
  response_group: Group;
  response_created_at: Date;
  response_approved_by: string;
  response_approved_at: Date;
  response_approved_group_id: string;
  color: Message.Color;
  is_private: boolean;
  is_final_step: boolean;
  photos: Document.Short[];
  documents: Document.Short[];
  created_at: Date;
  with_to_do: boolean;
  response_referenced_document_ids: string[];
  response_referenced_finding_ids: string[];
  response_referenced_node_ids: string[];
  response_referenced_qa_ids: string[];
  response_referenced_report_ids: string[];
  response_referenced_documents: ReferencedDocument[];
  response_referenced_findings: ReferencedFinding[];
  response_referenced_nodes: ReferencedNode[];
  response_referenced_qa: ReferencedMessage[];
  response_referenced_reports: ReferencedReport[];
  response_referenced_tickets: ReferencedMessage[];
  last_extension_at: Date;
  trade_id?: number;
}

export interface ReferencedMessage {
  id: string;
  current_no: number;
  message: string;
}

export interface ReferencedReport {
  id: string;
  name: string;
}

export interface ReferencedDocument {
  id: string;
  asset_id: string;
  name: string;
  file_extension: string;
  types: string[];
  conversion_status: string;
  conversion_error: string;
  conversion_started_at: string;
}

export interface ReferencedNode {
  id: string;
  asset_id: string;
  label: string;
  ids_path: string[];
  labels_path: string[];
  document_id: string;
  document: ReferencedDocument;
  branch: string;
  level: number;
  index_point: string;
  type: string;
}

export interface ReferencedFinding {
  id: string;
  current_no: number;
  finding: string;
}

export namespace Message {
  export type Color = 'red' | 'yellow' | 'blue' | 'green' | 'grey' | 'orange';
  export type Scope =
    | 'general'
    | 'finding'
    | 'photo'
    | 'report'
    | 'report_chapter'
    | 'node'
    | 'document'
    | 'ticket'
    | 'qa';
  export type Category = 'ticket' | 'tickets' | 'qa' | 'note' | 'notes';
  export type Priority = 'high' | 'low' | 'medium';
  export type TicketType = 'task' | 'defect' | 'complain_of_defect';
  export interface Short {
    id: string;
    category: Category;
    parent_id: string;
    asset_id: string;
    current_no: number;
    priority: Priority;
    scope: Scope;
    status: string;
    finding_id: string;
    finding: Finding;
    document_id: string;
    document: Document;
    document_page: number;
    document_coordinates: string;
    node_id: string;
    node: DataRoom.Node;
    report_id: string;
    report: Report;
    report_chapter_id: string;
    report_chapter: Report.Chapter;
    report_chapter_field: string;
    message: string; // text
    message_created_by: string;
    message_user: User;
    message_created_at: Date;
    message_created_group_id: string;
    message_group: Group;
    color: Color;
    is_private: boolean;
    created_at: Date;
    rejected_reason?: string | null;
    type?: TicketType;
    due_date?: Date;
    grace_period_first?: string;
    grace_period_second?: string;
    grace_period_third?: string;
    legal_notice?: string;
    message_hidden_from_recipient?: boolean;
  }

  export interface NewNote {
    /** Required */
    message: string;
    finding_id?: string;
    node_id?: string;
    report_id?: string;
    report_chapter_id?: string;
    /** Required */
    scope: Message.Scope;
    document_id?: string;
    document_page?: number;
    report_chapter_field?: string;
    color?: Message.Color;
    is_private?: boolean;
  }

  export interface MessageRef {
    category: MessageCategory;
    current_no: string;
    id: string;
    priority: Message.Priority;
    scope: Message.Scope;
  }

  export interface NewQuestion {
    /** Required */
    message: string;
    /** Required */
    priority: Message.Priority;
    /** Required */
    to_group_id: string;
    /** Required */
    qa_workflow_id: string;
    finding_id?: string;
    node_id?: string;
    report_id?: string;
    report_chapter_id?: string;
    bcc?: boolean;
    /**
     * message_id, in case it’s a follow up question, else empty
     */
    parent_id?: string;
    /** Required */
    scope: Message.Scope;
    document_id?: string;
    document_page?: number;
    report_chapter_field?: string;
    reference?: Reference;
  }

  export interface NewTicket {
    bcc?: boolean;
    coordinates?: string;
    document_id?: string;
    document_page?: number;
    due_date: string;
    finding_id?: string;
    message: string;
    node_id?: string;
    priority: Message.Priority;
    qa_id?: string;
    report_chapter_field?: string;
    report_chapter_id?: string;
    report_id?: string;
    scope: Message.Scope;
    ticket_id?: string;
    to_group_id: string;
    to_user_id?: string;
    trade_id: number;
    type: Message.TicketType;
  }

  export const getMessageReferenceIdField = (ref: Reference) => {
    const id = ref?.ref_id;
    switch (ref?.ref_type) {
      case 'photo':
        return { document_id: id };
      case 'finding':
        return { finding_id: id };
      case 'ticket':
      case 'tickets':
        return { ticket_id: id };
      case 'qa':
        return { qa_id: id };
      case 'document':
        return { node_id: id, document_page: (ref?.ref_data as RefDocumentData)?.source_page };
      case 'report':
        return { report_id: id };
      default:
        return {};
    }
  };

  export const transform = (message: Message): Message => ({
    ...message,
    message_user: Transform.user(message.message_user),
    response_user: Transform.user(message.response_user),
    created_at: Transform.date(message.created_at),
    message_created_at: Transform.date(message.message_created_at),
    message_approved_at: Transform.date(message.message_approved_at),
    response_created_at: Transform.date(message.response_created_at),
    response_approved_at: Transform.date(message.response_approved_at),
    released_for_all_at: Transform.date(message.released_for_all_at),
    message_group: Group.transform(message.message_group),
    response_group: Group.transform(message.response_group),
    node: message.node,
    document: message.document,
    documents: message.documents,
    photos: message.photos,
    finding: message.finding,
    report: message.report,
    report_chapter: message.report_chapter,
  });

  export const extractResponseReferences = (message: Message): ProcessedReference[] => {
    const processedRefs: ProcessedReference[] = [];

    if (message?.response_referenced_documents?.length) {
      message?.response_referenced_documents.forEach(({ id, name }) => {
        processedRefs.push({
          id,
          name,
          type: 'photo',
        });
      });
    }

    if (message?.response_referenced_findings?.length) {
      message?.response_referenced_findings.forEach(({ finding, current_no, id }) => {
        processedRefs.push({
          id,
          name: finding,
          current_no,
          type: 'finding',
        });
      });
    }

    if (message?.response_referenced_nodes?.length) {
      message?.response_referenced_nodes.forEach(({ id, label, index_point }) => {
        processedRefs.push({
          id,
          type: 'document',
          name: index_point?.length ? `${index_point} ${label}` : label,
        });
      });
    }

    if (message?.response_referenced_qa?.length) {
      message?.response_referenced_qa.forEach(({ id, message, current_no }) => {
        processedRefs.push({
          id,
          name: message,
          current_no,
          type: 'qa',
        });
      });
    }

    if (message?.response_referenced_tickets?.length) {
      message?.response_referenced_tickets.forEach(({ id, current_no, message }) => {
        processedRefs.push({
          id,
          current_no,
          type: 'ticket',
          name: message,
        });
      });
    }

    if (message?.response_referenced_reports?.length) {
      message?.response_referenced_reports.forEach(({ id, name }) => {
        processedRefs.push({
          id,
          name,
          type: 'report',
        });
      });
    }
    return processedRefs?.length ? processedRefs : undefined;
  };

  export const extractReference = (message: Message): ProcessedReference[] => {
    const processedReferences: ProcessedReference[] = [];
    const { finding, document, qa, ticket, node_id, report, node } = message || {};

    if (finding?.id) {
      processedReferences.push({
        id: finding.id,
        type: 'finding',
        name: String(finding.current_no),
        class: finding.class,
      });
    }

    if (document?.id && node?.document?.id !== document?.id) {
      processedReferences.push({
        id: document.id,
        type: document.types.includes('photo') || document.types.includes('video') ? 'photo' : 'document',
        name: document.name,
      });
    }

    if (qa?.id) {
      processedReferences.push({
        id: qa.id,
        type: 'qa',
        name: qa.current_no,
      });
    }

    if (ticket?.id) {
      processedReferences.push({
        id: ticket.id,
        type: 'ticket',
        name: '',
      });
    }

    if (node_id && node) {
      processedReferences.push({
        id: node_id,
        type: 'document',
        page: message.document_page,
        name: node.index_point !== undefined ? `${node.index_point} ${node.label || ''}` : node.label || '',
      });
    }

    if (report?.id) {
      processedReferences.push({
        id: report.id,
        type: 'report',
        name: report.name,
      });
    }

    return processedReferences;
  };

  export const getCanApprove = (message: Message): boolean => {
    const action = message?.qa_workflow_step?.action;
    return !!(message?.with_to_do && (action === 'approve_answer' || action === 'approve_question'));
  };

  export const getCanAnswer = (message: Message): boolean => {
    const action = message?.qa_workflow_step?.action;
    return !!(message?.with_to_do && action === 'answer');
  };

  export const getCanExtendDueDate = (message: Message, userId: string): boolean =>
    !message?.is_final_step && message.message_user?.id === userId && message.type !== 'complain_of_defect';

  export const getCanSetGracePeriod = (message: Message, userId: string): boolean =>
    !message?.is_final_step &&
    message?.message_user?.id === userId &&
    message.type === 'complain_of_defect' &&
    !message.last_extension_at &&
    new Date(message.due_date) < new Date();

  export const getTicketDueDate = (message: Message, option: 'lowest' | 'highest' = 'highest') => {
    const messageDueDate = new Date(message.due_date);
    const gracePeriodFirst = message.grace_period_first ? new Date(message.grace_period_first) : undefined;
    const gracePeriodSecond = message.grace_period_second ? new Date(message.grace_period_second) : undefined;
    const gracePeriodThird = message.grace_period_third ? new Date(message.grace_period_third) : undefined;

    const datesToConsider = [messageDueDate, gracePeriodFirst, gracePeriodSecond, gracePeriodThird].filter(
      (date) => date
    );

    if (datesToConsider.length === 1) {
      return messageDueDate;
    }

    return new Date(
      option === 'lowest'
        ? Math.min(...datesToConsider.map((date) => date.getTime()))
        : Math.max(...datesToConsider.map((date) => date.getTime()))
    );
  };

  export const getCanForward = (message: Message): boolean =>
    !!(message?.with_to_do && message?.qa_workflow_step?.forwardable && !message?.to_user_id);

  export const getCanReleaseMessageToAllGroups = (message: Message): boolean =>
    !!message?.is_final_step && !message?.released_for_all_at;

  export namespace Short {
    export const transform = (message: Message.Short): Message.Short => ({
      ...message,
      message_user: Transform.user(message.message_user),
      created_at: Transform.date(message.created_at),
      message_created_at: Transform.date(message.message_created_at),
      message_group: Group.transform(message.message_group),
    });
  }

  export interface History {
    id: string;
    qa_message_id: string;
    qa_message?: Message;
    user_id: string;
    user?: User;
    group_id: string;
    group?: Group;
    asset_id: string;
    qa_workflow_step_id: string;
    action: string;
    notes: string;
    created_at: Date;
    value: string;
  }

  export namespace MessageHistory {
    // eslint-disable-next-line
    export const transform = (history: History): History => {
      return {
        ...history,
        qa_message: history.qa_message ? Message.transform(history.qa_message) : undefined,
        group: history.group ? Group.transform(history.group) : undefined,
        user: history.user ? Transform.user(history.user) : undefined,
        created_at: Transform.date(history.created_at),
      };
    };
  }
}
