<ng-container [ngSwitch]="country">
  <ng-container *ngSwitchCase="Countries.Austria">{{ 'austria' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Belgium">{{ 'belgium' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Denmark">{{ 'denmark' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Finland">{{ 'finland' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.France">{{ 'france' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Germany">{{ 'germany' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Greece">{{ 'greece' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Ireland">{{ 'ireland' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Italy">{{ 'italy' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Liechtenstein">{{ 'liechtenstein' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Luxembourg">{{ 'luxembourg' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Netherlands">{{ 'netherlands' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Norway">{{ 'norway' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Poland">{{ 'poland' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Spain">{{ 'spain' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Sweden">{{ 'sweden' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.Switzerland">{{ 'switzerland' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.UnitedKingdom">{{ 'united-kingdom' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Countries.UnitedStates">{{ 'united-states' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ country }}</ng-container>
</ng-container>
