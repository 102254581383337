import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { DeploymentLabelComponent } from './deployment-label.component';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [DeploymentLabelComponent],
  exports: [DeploymentLabelComponent],
})
export class DeploymentLabelModule {}
