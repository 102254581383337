import { ColDef, ValueGetterParams } from '@ag-grid-community/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { LabelComponent } from '@common';
import { Asset, AssetOverview, Countries, getCountryTranslationKeyName, Project, User } from '@core/models';
import { FormatDateService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { LabelType } from '@portal/components/badge/badge.component';
import { CellCheckboxRendererComponent } from '@portal/components/grid/cell-checkbox-renderer/cell-checkbox-renderer.component';
import * as _ from 'lodash';
import { HeaderCellComponent, HeaderCellComponentParams } from './header-cell';
import { HeaderCheckboxRendererComponent } from './header-checkbox-renderer';

export interface GridHelperColDef {
  colDef: Partial<ColDef>;
  defaultColDef: ColDef;
  componentPortal: ComponentPortal<LabelComponent<unknown>> | null;
}

@Injectable({
  providedIn: 'root',
})
export class GridHelperService {
  constructor(protected formatDateService: FormatDateService, private transloco: TranslocoService) {}

  getColDef({ colDef, defaultColDef, componentPortal }: GridHelperColDef): ColDef {
    const field = colDef.field;

    const headerComponentParams = <HeaderCellComponentParams>{
      getLabelPortal: () => componentPortal,
      displayName: (field as string)?.indexOf('.') === -1 ? field : _.last((field as string)?.split('.')) || field,
    };

    const headerComponentFramework =
      colDef.headerComponentFramework || defaultColDef?.headerComponentFramework || HeaderCellComponent;

    return <ColDef>{
      field: field as string,
      tooltipField: null,
      ...colDef,
      ...(colDef.headerComponentFramework === null ? {} : { headerComponentFramework }),
      headerComponentParams: {
        ...headerComponentParams,
        ...(colDef.headerComponentParams || {}),
      },
    };
  }

  getCheckboxColDef(showMasterCheckbox = true, colDef?: ColDef): ColDef {
    return {
      colId: 'checkbox',
      pinned: 'left',
      cellClass: 'no-padding flex-centered',
      headerClass: 'no-padding flex-centered',
      checkboxSelection: false,
      minWidth: 60,
      maxWidth: 60,
      width: 60,
      resizable: false,
      sortable: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
      headerComponentFramework: showMasterCheckbox ? HeaderCheckboxRendererComponent : undefined,
      cellRendererFramework: CellCheckboxRendererComponent,
      ...(colDef ? colDef : {}),
    };
  }

  getDateCellRenderer(date: Date | string, includeTime = false): string {
    if (typeof date === 'string') {
      date = new Date(date);
    }

    return this.formatDateService.toLocaleString(date, includeTime);
  }

  getUserCellValueGetter(): (params: ValueGetterParams) => string {
    return (params: ValueGetterParams): string => {
      const field = params.colDef.field as string;
      const user = params?.data[field] as User;
      return user?.firstname && user?.lastname ? `${user?.firstname} ${user?.lastname}` : user?.email || '';
    };
  }

  getProgressValue(done: number, sum: number): string {
    if (!done || !sum) {
      return '';
    }
    return Math.round(Number((done / sum) * 100)).toLocaleString() + '%';
  }

  getAddressCellValueGetter(): (params: ValueGetterParams) => { value: string } {
    return (params: ValueGetterParams): { value: string } => {
      const {
        asset_street1,
        asset_street2,
        asset_zip = '',
        asset_city = '',
        asset_country,
      } = params.data as AssetOverview;
      const street = asset_street2 || asset_street1 || '';
      const city = asset_zip + asset_city;
      return {
        value: `${street ? street + '\n' : street}${city ? city + '\n' : ''}${this.transloco.translate(
          getCountryTranslationKeyName(asset_country as Countries)
        )}`,
      };
    };
  }

  getDateColDef(def: GridHelperColDef, includeTime = false): ColDef {
    return this.getColDef({
      ...def,
      colDef: {
        minWidth: includeTime ? 150 : 110,
        ...(def.colDef ? def.colDef : {}),
        ..._.defaultsDeep(def.colDef, <ColDef>{
          cellRenderer: ({ value }) => this.getDateCellRenderer(value, includeTime),
          tooltipValueGetter: ({ value }) => this.getDateCellRenderer(value, includeTime),
        }),
      },
    });
  }

  getStatusBadgeType(status): LabelType {
    switch (status) {
      case 'answered':
        return 'success';
      case 'closed':
        return 'secondary';
      default:
        return 'danger';
    }
  }

  getTicketBadgeType(status): LabelType {
    switch (status) {
      case 'open':
        return 'danger';
      case 'closed':
        return 'success';
      default:
        return 'warning';
    }
  }

  getBadgeType(assetStatus: Asset.Status | Project['status']): LabelType {
    switch (assetStatus) {
      case 'active':
        return 'success';
      case 'project_managers_only':
      case 'under_construction':
      case 'pending':
        return 'warning';
      default:
        return 'danger';
    }
  }
}
