<ng-container [ngSwitch]="fieldName" *ngIf="!hasTooltip; else templateWithTooltip">
  <ng-container *ngSwitchCase="getField('current_no')">{{ 'number-no' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('priority')">{{ 'priority' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('status')">{{ 'status' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('created_at')">{{ 'created-at' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="getField('message')">{{ 'question' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('message_user')">{{ 'questioner' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('message_approved_at')">{{ 'question-approved-at' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="getField('response')">{{ 'answer' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('response_user')">{{ 'answerer' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('response_created_at')">{{ 'answered-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('response_approved_at')">{{ 'answer-approved-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('released_for_all_at')">{{ 'released-at' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="getField('qa_workflow_id')">{{ 'workflow' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('qa_workflow')">{{ 'workflow' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="getField('qa_workflow_step_id')">{{ 'workflow-step' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('qa_workflow_step')">{{ 'workflow-step' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="getField('to_group_id')">{{ 'group' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('photos')">{{ 'photos' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('documents')">{{ 'attachments' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="getField('message_group')">{{ 'from' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('to_group')">{{ 'recipient' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="getField('finding')">{{ 'reference' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>

<ng-template #templateWithTooltip>
  <ng-container [ngSwitch]="fieldName">
    <span *ngSwitchCase="getField('current_no')" title="{{ 'number-no' | transloco }}">
      {{ 'number-no' | transloco }}
    </span>
    <span *ngSwitchCase="getField('priority')" title="{{ 'priority' | transloco }}">{{ 'priority' | transloco }}</span>
    <span *ngSwitchCase="getField('status')" title="{{ 'status' | transloco }}">{{ 'status' | transloco }}</span>
    <span *ngSwitchCase="getField('created_at')" title="{{ 'created-at' | transloco }}">
      {{ 'created-at' | transloco }}
    </span>

    <span *ngSwitchCase="getField('message')" title="{{ 'question' | transloco }}">{{ 'question' | transloco }}</span>
    <span *ngSwitchCase="getField('message_user')" title="{{ 'questioner' | transloco }}">
      {{ 'questioner' | transloco }}
    </span>
    <span *ngSwitchCase="getField('message_approved_at')" title="{{ 'question-approved-at' | transloco }}">
      {{ 'question-approved-at' | transloco }}
    </span>

    <span *ngSwitchCase="getField('response')" title="{{ 'answer' | transloco }}">{{ 'answer' | transloco }}</span>
    <span *ngSwitchCase="getField('response_user')" title="{{ 'answerer' | transloco }}">
      {{ 'answerer' | transloco }}
    </span>
    <span *ngSwitchCase="getField('response_created_at')" title="{{ 'answered-at' | transloco }}">
      {{ 'answered-at' | transloco }}
    </span>
    <span *ngSwitchCase="getField('response_approved_at')" title="{{ 'answer-approved-at' | transloco }}">
      {{ 'answer-approved-at' | transloco }}
    </span>
    <span *ngSwitchCase="getField('released_for_all_at')" title="{{ 'released-at' | transloco }}">
      {{ 'released-at' | transloco }}
    </span>

    <span *ngSwitchCase="getField('qa_workflow_id')" title="{{ 'workflow' | transloco }}">
      {{ 'workflow' | transloco }}
    </span>
    <span *ngSwitchCase="getField('qa_workflow')" title="{{ 'workflow' | transloco }}">
      {{ 'workflow' | transloco }}
    </span>

    <span *ngSwitchCase="getField('qa_workflow_step_id')" title="{{ 'workflow-step' | transloco }}">
      {{ 'workflow-step' | transloco }}
    </span>
    <span *ngSwitchCase="getField('qa_workflow_step')" title="{{ 'workflow-step' | transloco }}">
      {{ 'workflow-step' | transloco }}
    </span>

    <span *ngSwitchCase="getField('to_group_id')" title="{{ 'group' | transloco }}">{{ 'group' | transloco }}</span>
    <span *ngSwitchCase="getField('photos')" title="{{ 'photos' | transloco }}">{{ 'photos' | transloco }}</span>
    <span *ngSwitchCase="getField('documents')" title="{{ 'attachments' | transloco }}">
      {{ 'attachments' | transloco }}
    </span>

    <span *ngSwitchCase="getField('message_group')" title="{{ 'from' | transloco }}">{{ 'from' | transloco }}</span>
    <span *ngSwitchCase="getField('finding')" title="{{ 'reference' | transloco }}">{{ 'reference' | transloco }}</span>
    <span *ngSwitchCase="getField('to_group')" title="{{ 'recipient' | transloco }}">
      {{ 'recipient' | transloco }}
    </span>

    <span *ngSwitchDefault>{{ fieldName }}</span>
  </ng-container>
</ng-template>
