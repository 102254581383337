import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { AuthRoutingService } from '../../../auth/auth-routing.service';
import { UsersEffects } from './users.effects';
import { UsersReducer } from './users.reducer';

@NgModule({
  imports: [EffectsModule.forFeature([UsersEffects]), StoreModule.forFeature(UsersReducer.name, UsersReducer.reducer)],
  providers: [AuthRoutingService],
})
export class UsersStateModule {}
