import { Permission } from '@core/models';
import { createAction, props } from '@ngrx/store';

export namespace ResolvedDataActions {
  export const setPermission = createAction(
    '[Resolved Admin Data] Set Permission',
    props<{
      data: {
        permission: Permission;
      };
    }>()
  );

  export const clearPermission = createAction('[Resolved Admin Data] Clear Permission');

  export const setGlobalTermsConditionsId = createAction(
    '[Resolved Admin Data] Set Global Terms & Conditions Id',
    props<{
      termsConditionsId: string;
    }>()
  );
}
