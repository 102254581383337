import { Injectable } from '@angular/core';
import { Document, Finding } from '@core/models';
import { Store } from '@ngrx/store';
import { ResolvedDataSelectors } from '@portal/customer/state';
import _isNumber from 'lodash/isNumber';

import { AppRoute } from '@app/app-routing.service';
import { CustomerRoute } from '../../customer/services';
import { RoutingService } from '../../services/routing.service';

export enum AdminRoute {
  Admin = 'admin',
  User = 'user',
  Statistic = 'statistic',
  Settings = 'settings',
  Projects = 'projects',
  Schedule = 'schedule',
  Report = 'report',
  ReportTemplates = 'report-templates',
  Disclaimer = 'disclaimer',
  Photo = 'photo',
  Asset = 'asset',
  Findings = 'findings',
  CreateFinding = 'create',
  EditFinding = 'edit',
  Deployment = 'deployment',
  Monitoring = 'monitoring',
}

@Injectable({
  providedIn: 'root',
})
export class AdminRoutingService extends RoutingService {
  readonly projectId$ = this.store.select(ResolvedDataSelectors.getProjectId);
  readonly assetId$ = this.store.select(ResolvedDataSelectors.getAssetId);

  constructor(private store: Store<unknown>) {
    super();
  }

  readonly pathMap = (projectId?: string, assetId?: string, findingId?: string): Map<string, string> =>
    new Map<string, string>([
      [AdminRoute.Admin, this.getHomeLink()],
      [AdminRoute.User, this.getUsersLink()],
      [AdminRoute.Statistic, this.getStatisticsLink()],
      [AdminRoute.Disclaimer, this.getEditDisclaimerLink()],
      [AdminRoute.Projects, this.getProjectDetailsLink(projectId)],
      [AdminRoute.Schedule, this.getProjectAssetLink(projectId, assetId)],
      [AdminRoute.Report, this.getProjectReportLink(projectId, assetId)],
      [AdminRoute.ReportTemplates, this.getReportTemplatesLink()],
      [AdminRoute.Photo, this.getProjectPhotoLink(projectId, assetId)],
      [AdminRoute.Asset, this.getAssetDetailsPartialLink(projectId, assetId)],
      [AdminRoute.Findings, this.getAssetFindingsLink(projectId, assetId)],
      [AdminRoute.CreateFinding, this.getAssetCreateFindingLink(projectId, assetId)],
      [AdminRoute.EditFinding, this.getAssetEditFindingLink(projectId, assetId, findingId)],
      [AdminRoute.Deployment, this.getDeploymentsLink()],
      [AdminRoute.Monitoring, this.getMonitoringLink()],
    ]);

  getLinkForRoutes(path: string, projectId?: string, assetId?: string, findingId?: string): string {
    return this.pathMap(projectId, assetId, findingId).get(path);
  }

  getHomeLink(): string {
    return `/${AppRoute.Admin}`;
  }

  getCustomersLink(): string {
    return `/${AppRoute.Customer}`;
  }

  getUsersLink(): string {
    return `${this.getHomeLink()}/${AdminRoute.User}`;
  }

  getStatisticsLink(): string {
    return `${this.getHomeLink()}/${AdminRoute.Statistic}`;
  }

  getProjectsLink(): string {
    return `${this.getHomeLink()}/${AdminRoute.Projects}`;
  }

  getReportTemplatesLink(): string {
    return `${AdminRoute.ReportTemplates}`;
  }

  getProjectDetailsLink(projectId: string): string {
    if (!projectId) {
      return this.getProjectsLink();
    }
    return projectId && `${this.getHomeLink()}/${AdminRoute.Projects}/${projectId}`;
  }

  getDeploymentsLink(): string {
    return `${this.getHomeLink()}/${AdminRoute.Deployment}`;
  }

  getMonitoringLink(): string {
    return `${this.getHomeLink()}/${AdminRoute.Monitoring}`;
  }

  getEditDisclaimerLink(disclaimer_id?: string): string {
    let url = `${AdminRoute.Disclaimer}`;
    if (disclaimer_id) {
      url += `/${disclaimer_id}`;
    }
    return url;
  }

  getProjectAssetLink(projectId: string, assetId: string): string {
    if (!projectId || !assetId) {
      return null;
    }
    return `${this.getAssetDetailsPartialLink(projectId, assetId)}/${AdminRoute.Schedule}`;
  }

  getProjectReportLink(projectId: string, assetId: string): string {
    if (!projectId || !assetId) {
      return null;
    }
    return `${this.getAssetDetailsPartialLink(projectId, assetId)}/${AdminRoute.Report}`;
  }

  getProjectPhotoLink(projectId: string, assetId: string): string {
    if (!projectId || !assetId) {
      return null;
    }
    return `${this.getAssetDetailsPartialLink(projectId, assetId)}/${AdminRoute.Photo}`;
  }

  getAssetDocumentViewerLink(projectId: string, assetId: string, type: Document.Type, documentId: string): string {
    if (!projectId || !assetId || !documentId) {
      return null;
    }
    if (type === 'report') {
      return `${this.getAssetDetailsPartialLink(projectId, assetId)}/report/report/${documentId}`;
    }
    return `${this.getAssetDetailsPartialLink(projectId, assetId)}/${type}/${documentId}`;
  }

  getAssetFindingsLink(projectId: string, assetId: string): string {
    if (!projectId || !assetId) {
      return null;
    }
    return `${this.getAssetDetailsPartialLink(projectId, assetId)}/${AdminRoute.Findings}`;
  }

  getAssetCreateFindingLink(projectId: string, assetId: string): string {
    if (!projectId || !assetId) {
      return null;
    }
    return `${this.getAssetDetailsPartialLink(projectId, assetId)}/${AdminRoute.Findings}/${AdminRoute.CreateFinding}`;
  }

  getAssetEditFindingLink(projectId: string, assetId: string, findingId: string): string {
    if (!projectId || !assetId) {
      return null;
    }
    return `${this.getAssetDetailsPartialLink(projectId, assetId)}/${AdminRoute.Findings}/${
      AdminRoute.EditFinding
    }/${findingId}`;
  }

  getDocumentViewerLink(doc: Document.ListItem): string {
    const type = this.getMediaType(doc.types);
    return this.getAssetDocumentViewerLink(doc.project_id, doc.asset_id, type, doc.id);
  }

  getFindingLink(finding: Finding.Short): string {
    return this.getAssetEditFindingLink(finding.project_id, finding.asset_id, finding.id);
  }

  getDocumentFileLink(doc: Document.Short, page?: number): string {
    const pageNumber = _isNumber(page) ? `#page=${page}` : '';
    return `/${AppRoute.Customer}/${doc.asset_id}/${CustomerRoute.Documents}/${doc.name}${pageNumber}`;
  }

  getProjectSettingsLink(project_id: string): string {
    if (!project_id) {
      return null;
    }
    return `${this.getProjectDetailsLink(project_id)}/${AdminRoute.Settings}`;
  }

  private getAssetDetailsPartialLink(projectId: string, assetId: string): string {
    if (!projectId || !assetId) {
      return null;
    }
    return assetId && `${this.getProjectDetailsLink(projectId)}/${AdminRoute.Asset}/${assetId}`;
  }
}
