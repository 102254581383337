import { GridApi, ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { findLastIndex } from '@app/utils/array';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { fromEvent } from 'rxjs';
import { CellRendererComponent } from '../cell-renderer/cell-renderer.component';

export interface CheckboxCellRendererData extends ICellRendererParams {
  setRowDataId?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'x-cell-checkbox-renderer',
  standalone: true,
  imports: [CommonModule, MatCheckboxModule],
  templateUrl: './cell-checkbox-renderer.component.html',
  styleUrls: ['./cell-checkbox-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CellCheckboxRendererComponent extends CellRendererComponent<CheckboxCellRendererData> {
  isChecked = false;
  node: RowNode;
  api: GridApi;

  readonly cdr = inject(ChangeDetectorRef);

  onChange() {
    const newValue = !this.isChecked;
    this.node.setSelected(newValue);
    this.isChecked = newValue;
  }

  agInit(params: ICellRendererParams): void {
    this.node = params.node;
    this.value = params;
    if (this.value.setRowDataId) {
      this.node.id = params?.data?.id || this.node.id;
    }
    this.api = params.api;
    this.isChecked = this.node.isSelected();

    fromEvent(params.api, 'rowSelected')
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.isChecked = this.node.isSelected();
        this.cdr.markForCheck();
      });
  }

  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  onClick(event: Event) {
    event.stopPropagation();
    const withShiftKey = event['shiftKey'];
    if (withShiftKey) {
      const nodes = [];
      this.api.getModel().forEachNode((node) => nodes.push(node));
      const firstSelectedNode = nodes.findIndex((node) => node.isSelected());
      const firstSelectedFallbackedToZero = firstSelectedNode !== -1 ? firstSelectedNode : 0;
      const currentNodeIndex = nodes.findIndex((node) => node.id === this.node.id);
      const lastSelected = findLastIndex(nodes, (node) => node.isSelected());
      const lastSelectedFallbackedToZero = lastSelected !== -1 ? lastSelected : 0;

      const lesserIndex = Math.min(firstSelectedFallbackedToZero, currentNodeIndex);
      const higherIndex = Math.max(
        firstSelectedFallbackedToZero,
        currentNodeIndex,
        currentNodeIndex <= lesserIndex ? lastSelectedFallbackedToZero : 0
      );

      this.api.deselectAll();
      nodes.slice(lesserIndex, higherIndex + 1).forEach((node) => {
        node.setSelected(true);
      });
    }
  }
}
