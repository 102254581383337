import { Injectable } from '@angular/core';
import { AppRoute } from '../app-routing.service';

@Injectable({
  providedIn: 'root',
})
export class AuthRoutingService {
  getHomeLink(): string {
    return `/${AppRoute.Auth}`;
  }

  getSigninLink(): string {
    return this.getHomeLink();
  }

  // getSignupLink(): string {
  //   return `${this.getHomeLink()}/signup`;
  // }

  getRecoveryLink(): string {
    return `${this.getHomeLink()}/recovery`;
  }
}
