import { ColDef } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertType } from '@common';
import { Member, User } from '@core/models';
import { UserService } from '@core/services';
import { FormatDateService } from '@core/services/format-date.service';
import { DialogActions } from '@core/state';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BaseGrid, BaseGridComponent, GridHelperService } from '@portal/components/grid';
import { BehaviorSubject } from 'rxjs';
import { filter, first, mergeMap } from 'rxjs/operators';
import { AddressCellRendererComponent } from '../../grid/address-cell-renderer';
import { PortalUserHeaderCellComponent } from '../portal-user-header-cell/portal-user-header-cell.component';
import { AddAdminDialogComponent, CreateAdminData } from './add-admin-dialog/add-admin-dialog.component';

@Component({
  selector: 'x-portal-admin',
  templateUrl: './portal-admin.component.html',
  styleUrls: ['./portal-admin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalAdminsComponent extends BaseGridComponent<Member> {
  @ViewChild('adminAddFailedTemplate') adminAddFailedTemplate: TemplateRef<unknown>;
  @ViewChild('adminDeleteFailedTemplate') adminDeleteFailedTemplate: TemplateRef<unknown>;

  defaultColDef: ColDef = {
    ...BaseGrid.defaultColDef,
    tooltipValueGetter: ({ value }) => {
      if (value instanceof Date) {
        return this.getDateCellRenderer(value);
      }

      return value;
    },
  };

  readonly rowData$ = new BehaviorSubject<Member[]>([]);

  constructor(
    protected componentRef: ElementRef<HTMLElement>,
    protected formatDateService: FormatDateService,
    private userService: UserService,
    private dialog: MatDialog,
    private store: Store,
    gridHelper: GridHelperService,
    transloco: TranslocoService
  ) {
    super(componentRef, formatDateService, gridHelper, transloco);

    this.fetchUsers();
  }

  onAddAdmin(): void {
    this.dialog
      .open<AddAdminDialogComponent, null, CreateAdminData>(AddAdminDialogComponent)
      .afterClosed()
      .pipe(
        first(),
        filter((result) => !!result),
        mergeMap(({ role_id, user: { id } }) => this.userService.createAdmin({ role_id, user_id: id }))
      )
      .subscribe({
        next: () => this.fetchUsers(),
        error: () => this.showError(this.adminAddFailedTemplate),
      });
  }

  onRowClicked(): void {}

  protected getColDef(field: keyof Member | string, colDef: Partial<ColDef> = {}): ColDef {
    return {
      ...super.getColDef(field as any, colDef), // eslint-disable-line @typescript-eslint/no-explicit-any
      headerComponentFramework: PortalUserHeaderCellComponent,
    };
  }

  protected getColumnDefs(): ColDef[] {
    return [
      this.getColDef('user.firstname'),
      this.getColDef('user.lastname'),
      this.getColDef('user.email'),
      this.getColDef('group.name', {
        headerComponentParams: {
          displayName: 'group',
        },
      }),
      this.getDateColDef('created_at'),
      this.getColDef('user.address', {
        hide: true,
        cellRendererFramework: AddressCellRendererComponent,
      }),
      this.getColDef('user.company', { hide: true }),
      this.getColDef('user.title', { hide: true }),
      this.getColDef('user.phone', { hide: true }),
      this.getColDef('status', { hide: true }),
      this.getColDef('user.best_reachable', { hide: true }),
      this.getColDef('id', {
        minWidth: 40,
        width: 40,
        sortable: false,
        suppressSizeToFit: true,
        suppressColumnsToolPanel: true,
        cellClass: 'clickable-cell icon-cell',
        cellRenderer: () => `<span class="icon-font icon-font-alert-fail"></span>`,
        onCellClicked: (params) => {
          const member = params.data as Member;
          this.deleteUser(member.user as User.Admin);
        },
        headerComponentParams: {
          displayName: '',
        },
        tooltipValueGetter: undefined,
      }),
    ];
  }

  private deleteUser(user: User.Admin): void {
    this.userService
      .deleteAdmin(user)
      .pipe(first())
      .subscribe({
        next: () => this.fetchUsers(),
        error: () => this.showError(this.adminDeleteFailedTemplate),
      });
  }

  private fetchUsers(): void {
    this.userService
      .fetchListOfAdmins()
      .pipe(first())
      .subscribe({
        next: (members) => this.rowData$.next(members),
        error: () => this.rowData$.next(null),
      });
  }

  private showError(template: TemplateRef<unknown>): void {
    this.store.dispatch(
      DialogActions.showAlert({
        data: {
          type: AlertType.Error,
          template: () => template,
        },
      })
    );
  }
}
