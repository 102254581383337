import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NgClass, NgForOf, NgIf } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';

import { DialogComponent } from '@common/components/dialog/dialog.component';
import { InputComponent } from '@common/components/input/input.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { FormArrayPipe } from '@common/pipes/form-array/form-array.pipe';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';

export interface CodeConfirmationDialogData {
  title: string;
  subtitle: string;
  submitBtnTranslationKey: string;
  submitBtnIcon?: AvailableIcons;
}

@Component({
  selector: 'x-code-confirmation-dialog',
  templateUrl: './code-confirmation-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    TranslocoModule,
    MatDialogModule,
    DialogComponent,
    InputComponent,
    SvgIconModule,
    NgForOf,
    NgIf,
    ReactiveFormsModule,
    FormArrayPipe,
    NgClass,
  ],
})
export class CodeConfirmationDialogComponent implements OnInit {
  readonly code = `${Math.floor(1000 + Math.random() * 9000)}`;

  codeForm: FormGroup;
  previousCodeValues = [null, null, null, null];
  isFocused: boolean[] = [false, false, false, false];

  constructor(
    @Inject(MAT_DIALOG_DATA) readonly data: CodeConfirmationDialogData,
    private readonly matDialogRef: MatDialogRef<CodeConfirmationDialogComponent>,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.codeForm = this.fb.group({
      digits: this.fb.array(
        [
          this.fb.control('', [Validators.required, Validators.pattern(/[0-9]/)]),
          this.fb.control('', [Validators.required, Validators.pattern(/[0-9]/)]),
          this.fb.control('', [Validators.required, Validators.pattern(/[0-9]/)]),
          this.fb.control('', [Validators.required, Validators.pattern(/[0-9]/)]),
        ],
        this.codeValidator(this.code)
      ),
    });
  }

  get digits(): FormArray {
    return this.codeForm.get('digits') as FormArray;
  }

  onConfirm() {
    const enteredCode = this.digits.value.join('');
    if (enteredCode === this.code) {
      this.matDialogRef.close(true);
    }
  }

  isAnyDigitEmpty(): boolean {
    return (this.codeForm.get('digits') as FormArray).controls.some((control) => !control.value);
  }

  isAnyDigitTouched(): boolean {
    return (this.codeForm.get('digits') as FormArray).controls.some((control) => control.touched);
  }

  onCancel() {
    this.matDialogRef.close(false);
  }

  handleInputKeyDown(index: number, event: KeyboardEvent, control: AbstractControl) {
    event.preventDefault();
    event.stopPropagation();
    const inputs: NodeList = document.querySelectorAll('.digit-input');
    if (event.key >= '0' && event.key <= '9') {
      control.patchValue(event.key);
      index < 3 && (inputs[index + 1] as HTMLInputElement).focus();
    } else if (event.key === 'Backspace') {
      control.patchValue('');
      !this.previousCodeValues[index] && (inputs[index - 1] as HTMLInputElement)?.focus();
    }
    this.previousCodeValues[index] = control.value;
  }

  onFocus(index: number): void {
    this.isFocused[index] = true;
  }

  onBlur(index: number): void {
    this.isFocused[index] = false;
  }

  isAnyDigitFocused(): boolean {
    return this.isFocused.some((state) => state);
  }

  trackByIndex(index: number): number {
    return index;
  }

  private codeValidator(expectedCode: string) {
    return (formArray: FormArray) => {
      const enteredCode = formArray.value.join('');
      return enteredCode === expectedCode ? null : { codeMismatch: true };
    };
  }
}
