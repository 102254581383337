import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { getFileIconFromType, getFileTypeFromExtension } from './file-icon.helper';

@Component({
  selector: 'x-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileIconComponent implements OnChanges {
  @Input() set extension(value: string | undefined) {
    this._extension = value;
    const fileType = getFileTypeFromExtension(value);
    this.icon = getFileIconFromType(fileType, value, this.multipleIcon);
  }
  get extension() {
    return this._extension;
  }

  @Input() multipleIcon = false;
  @Input() hasDotIndicator = false;
  @Input() statusIcon: AvailableIcons;

  icon = 'unknown';
  private _extension?: string;

  readonly cdr = inject(ChangeDetectorRef);

  ngOnChanges({ multipleIcon }: SimpleChanges) {
    if (!multipleIcon?.firstChange) {
      const fileType = getFileTypeFromExtension(this.extension);
      this.icon = getFileIconFromType(fileType, this.extension, this.multipleIcon);
      this.cdr.markForCheck();
    }
  }
}
