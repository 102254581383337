import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';

export type CellRendererParams<D> = ICellRendererParams & D;

@Component({
  template: `
    {{ params?.value }}
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class CellRendererComponent<T = any, D = any> implements ICellRendererAngularComp {
  params?: CellRendererParams<D>;
  value?: T;

  protected readonly cdr = inject(ChangeDetectorRef);

  refresh(params: CellRendererParams<D>): boolean {
    this.params = params;
    this.value = params?.value;
    this.cdr.markForCheck();

    return true;
  }

  agInit(params: CellRendererParams<D>): void {
    this.params = params;
    this.value = params?.value;
  }
}
