import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, HostListener, ViewChild } from '@angular/core';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { Tracker } from '@core/models';
import * as _ from 'lodash';

@Component({
  selector: 'x-date-picker-cell-renderer',
  templateUrl: './date-picker-cell-renderer.component.html',
  styleUrls: ['./date-picker-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerCellRendererComponent implements ICellRendererAngularComp {
  @ViewChild('picker') picker: MatDatepicker<Date>;

  params: ICellRendererParams;

  readonly minDate = new Date();

  get isTrackerDone(): boolean {
    return !!(this.params?.data as Tracker)?.is_done;
  }

  @HostListener('click')
  onComponentClick(): void {
    if (this.isTrackerDone) {
      return;
    }

    this.picker.open();
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
  }

  onValueSelected(event: MatDatepickerInputEvent<Date>, picker: MatDatepicker<Date>): void {
    picker.close();
    const date = event.value;
    const newDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
    this.params.setValue(newDate);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh(params: ICellRendererParams): boolean {
    let valueChanged = false;

    valueChanged = valueChanged || _.isEmpty(this.params) !== _.isEmpty(params);
    valueChanged = valueChanged || +this.params?.value === +params?.value;

    return valueChanged;
  }
}
