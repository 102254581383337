import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Message } from '@core/models';

@Component({
  selector: 'x-message-button',
  templateUrl: './message-button.component.html',
  styleUrls: ['./message-button.component.scss'],
})
export class MessageButtonComponent {
  @Input() messages?: Message.Short[];

  @Output() addMessage = new EventEmitter<void>();
  @Output() toggleMessages = new EventEmitter<void>();

  onAddMessage(): void {
    this.addMessage.emit();
  }

  onToggleMessages(): void {
    this.toggleMessages.emit();
  }
}
