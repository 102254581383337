import { AgGridModule } from '@ag-grid-community/angular';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AssetOverviewLabelModule, SpinnerModule } from '@common';
import { NotFoundAlertComponent } from '@common/components/not-found-alert/not-found-alert.component';
import { TranslocoModule } from '@ngneat/transloco';
import { AssetOverviewGridViewComponent } from '@portal/components/assets-overview/asset-overview-grid-view/asset-overview-grid-view.component';
import { AssetOverviewListViewModule } from '@portal/components/assets-overview/asset-overview-list-view/asset-overview-list-view.module';
import { AssetsOverviewSearchModule } from '@portal/components/assets-overview/assets-overview-search';
import { GridModule, LoadingOverlayComponent, NoRowsOverlayComponent } from '../grid';
import { AddressCellRendererComponent } from '../grid/address-cell-renderer';
import { AssetOverviewHeaderCellComponent } from './asset-overview-header-cell/asset-overview-header-cell.component';
import { AssetsOverviewComponent } from './assets-overview.component';

@NgModule({
  imports: [
    CommonModule,
    AgGridModule.withComponents([
      AssetOverviewHeaderCellComponent,
      LoadingOverlayComponent,
      NoRowsOverlayComponent,
      AddressCellRendererComponent,
    ]),
    GridModule,
    AssetOverviewLabelModule,
    AssetOverviewGridViewComponent,
    AssetOverviewListViewModule,
    AssetsOverviewSearchModule,
    TranslocoModule,
    NotFoundAlertComponent,
    SpinnerModule,
  ],
  declarations: [AssetsOverviewComponent, AssetOverviewHeaderCellComponent],
  exports: [AssetsOverviewComponent],
})
export class AssetsOverviewModule {}
