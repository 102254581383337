import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ReportTemplateReducer,
  ReportTemplateState,
} from '@portal/admin/store/report-templates/report-template.reducer';

const selectReportSelector = createFeatureSelector<ReportTemplateState>(ReportTemplateReducer.name);

export namespace ReportTemplateSelector {
  export const getReportTemplate = createSelector(selectReportSelector, (state) => state?.reportTemplate);
}
