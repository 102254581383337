import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import {
  AvailableLanguages,
  DANISH_LOCALE,
  ENGLISH_LOCALE,
  FRENCH_LOCALE,
  GERMAN_LOCALE,
  ITALIAN_LOCALE,
} from '@core/models';
import { environment } from '@environments';
import {
  Translation,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
} from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { TranslocoMessageFormatModule } from '@ngneat/transloco-messageformat';
import { TRANSLOCO_PERSIST_LANG_STORAGE, TranslocoPersistLangModule } from '@ngneat/transloco-persist-lang';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import setHtmlLang from './utils/html-lang';

export const TRANSLOCO_STORAGE_KEY = 'user_language';

export function getLangFn({ cachedLang, browserLang, defaultLang }: never) {
  const availableLanguages = AvailableLanguages.all;

  let currentLang: AvailableLanguages = defaultLang;

  if (cachedLang && availableLanguages.includes(cachedLang)) {
    currentLang = cachedLang;
  } else if (availableLanguages.includes(browserLang)) {
    currentLang = browserLang;
  }

  setHtmlLang(currentLang);

  return currentLang;
}

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private translations = {} as Record<string, Translation>;

  constructor(private http: HttpClient) {}

  getTranslation(lang: string): Observable<Translation> {
    if (this.translations[lang]) {
      const translation = this.translations[lang];
      return of(translation);
    }

    const assets = './static/assets/i18n';
    return this.http
      .get<Translation>(`${assets}/${lang}.json`, {
        headers: {
          'Cache-Control': 'no-cache, no-store',
        },
      })
      .pipe(
        tap((translation) => {
          this.translations[lang] = translation;
        })
      );
  }
}

@NgModule({
  imports: [
    TranslocoMessageFormatModule.forRoot(),
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        [AvailableLanguages.English]: ENGLISH_LOCALE,
        [AvailableLanguages.German]: GERMAN_LOCALE,
        [AvailableLanguages.French]: FRENCH_LOCALE,
        [AvailableLanguages.Italian]: ITALIAN_LOCALE,
        [AvailableLanguages.Danish]: DANISH_LOCALE,
      },
    }),
    TranslocoPersistLangModule.forRoot({
      getLangFn,
      storageKey: TRANSLOCO_STORAGE_KEY,
      storage: {
        provide: TRANSLOCO_PERSIST_LANG_STORAGE,
        useValue: localStorage,
      },
    }),
  ],
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useValue: translocoConfig({
        availableLangs: [
          AvailableLanguages.English,
          AvailableLanguages.German,
          AvailableLanguages.Italian,
          AvailableLanguages.French,
          AvailableLanguages.Danish,
        ],
        defaultLang: AvailableLanguages.German,
        // Remove this option if your application
        // doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: environment.production,
        fallbackLang: 'de',
      }),
    },
    { provide: TRANSLOCO_LOADER, useExisting: TranslocoHttpLoader },
  ],
})
export class TranslocoRootModule {}
