import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Asset,
  Countries,
  DemoProjectForm,
  ProductType,
  Project,
  ProjectListItem,
  ProjectPackage,
  TDD,
  Transform,
  User,
} from '@core/models';
import { DataRoomProjectForm, ProjectForm } from '@core/models/project';
import * as _ from 'lodash';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { PORTAL_API_URL } from './constants';
import { ISelectOption } from '@common/components/select/select.model';

interface AddAdminResponse {
  id: string;
  user_id: string;
  user: User;
  status: string;
  project_id: string;
  role: Project.Admin.Role;
  asset_id: string;
  created_at: Date;
  invited_at: Date;
}

@Injectable({
  providedIn: 'root',
})
export class ProjectsService {
  readonly apiUrl = `${PORTAL_API_URL}/admin/projects`;

  readonly currentProject$ = new BehaviorSubject<Project>(null);

  constructor(private http: HttpClient) {}

  fetchCurrentProject(id: string): void {
    this.fetchDetails(id)
      .pipe(first())
      .subscribe({
        next: (project) => this.currentProject$.next(project),
        error: () => this.currentProject$.next(null),
      });
  }

  fetch(filter: Partial<Project.ProjectFilter> = {}): Observable<ProjectListItem[]> {
    const params = _.omitBy(filter, _.isNil) as unknown as HttpParams;
    return this.http.get<ProjectListItem[]>(this.apiUrl, { params }).pipe(
      map((projects) => _.map(projects, Project.transform)),
      catchError((error) => throwError(error))
    );
  }

  fetchDetails(id: string): Observable<Project> {
    if (_.isEmpty(id)) {
      return EMPTY;
    }

    return this.http.get<Project>(`${this.apiUrl}/${id}`).pipe(map((project) => Project.transform(project)));
  }

  fetchProjectsAdmins(id: string): Observable<Project.Admin[]> {
    if (_.isEmpty(id)) {
      return EMPTY;
    }

    return this.http.get<Project.Admin[]>(`${this.apiUrl}/${id}/admins`).pipe(
      map((admins) =>
        _.map(admins, (admin) => ({
          ...admin,
          created_at: Transform.date(admin.created_at),
          user: Transform.user(admin.user),
        }))
      )
    );
  }

  addProjectAdmin(id: string, data: { user_id: string; is_project_lead: boolean }): Observable<AddAdminResponse> {
    // if (_.isEmpty(data?.user_id) || _.isEmpty(data?.is_project_lead) || _.isEmpty(id)) {
    //   return EMPTY;
    // }

    return this.http.post<AddAdminResponse>(`${this.apiUrl}/${id}/admins`, data).pipe(
      map((response) => ({
        ...response,
        created_at: Transform.date(response.created_at),
        invited_at: Transform.date(response.invited_at),
        user: Transform.user(response.user),
      }))
    );
  }

  removeProjectAdmin(id: string, admin: Project.Admin): Observable<void> {
    if (_.isEmpty(admin) || _.isEmpty(id)) {
      return EMPTY;
    }

    return this.http.delete<void>(`${this.apiUrl}/${id}/admins/${admin.user_id}`);
  }

  createAsset(projectId: string, asset: Asset): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${projectId}/assets`, asset);
  }

  createProject(project: ProjectForm): Observable<Project> {
    const url = `${this.apiUrl}`;
    return this.http.post<Project>(url, project);
  }

  createDataRoom({ name, owner, language, add_ons, user_quota }: Partial<DataRoomProjectForm>): Observable<Project> {
    const url = `${this.apiUrl}/data-room`;
    return this.http.post<Project>(url, { name, owner, language, add_ons, user_quota });
  }

  createDemoProject(project: DemoProjectForm): Observable<Project> {
    const url = `${this.apiUrl}/demo`;
    return this.http.post<Project>(url, project);
  }

  removeProject({ project_id }: { project_id: string }): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${project_id}`);
  }

  getTDDReportFormats(): Observable<TDD.ReportFormat[]> {
    const url = `${PORTAL_API_URL}/admin/tdd-report-formats`;
    return this.http.get<TDD.ReportFormat[]>(url);
  }

  getProductTypes(): Observable<ISelectOption<string>[]> {
    const url = `${PORTAL_API_URL}/admin/projects/product-types`;
    return this.http.get<string[]>(url).pipe(
      map((productTypes) =>
        productTypes.map((productType) => ({
          value: productType,
          translationKey: this.getProductTypeFullName(productType),
        }))
      )
    );
  }

  setDisclaimer({ project_id, disclaimer_id }: { project_id: string; disclaimer_id: string }): Observable<void> {
    const url = `${this.apiUrl}/${project_id}/disclaimer/${disclaimer_id}`;
    return this.http.post<void>(url, null);
  }

  removeDisclaimer({ project_id }: { project_id: string }): Observable<void> {
    const url = `${this.apiUrl}/${project_id}/disclaimer`;
    return this.http.delete<void>(url);
  }

  goLive({ project_id }: { project_id: string }): Observable<void> {
    const url = `${this.apiUrl}/${project_id}/golive`;
    return this.http.post<void>(url, null);
  }

  getLogoUploadToken({ project_id }: { project_id: string }): Observable<string> {
    const url = `${this.apiUrl}/${project_id}/logo/upload_token`;
    return this.http.post<{ upload_token: string }>(url, null).pipe(map((result) => result?.upload_token));
  }

  getLogoDownloadToken({ project_id }: { project_id: string }): Observable<string> {
    const url = `${this.apiUrl}/${project_id}/logo/download_token`;
    return this.http.post<{ download_token: string }>(url, null).pipe(map((result) => result?.download_token));
  }

  deleteLogo({ project_id }: { project_id: string }): Observable<void> {
    const url = `${this.apiUrl}/${project_id}/logo`;
    return this.http.delete<void>(url);
  }

  updateProjectDetails(project: Project, projectId?: string): Observable<Project> {
    const url = `${this.apiUrl}/${projectId || project.id}`;
    return this.http
      .put<Partial<Project>>(url, project)
      .pipe(map((partialProject) => ({ ...project, ...partialProject })));
  }

  updateAsset(projectId: string, asset: Asset.Details): Observable<void> {
    return this.http.put<void>(`${this.apiUrl}/${projectId}/assets/${asset.id}`, asset);
  }

  updateAssetDetails(projectId: string, asset: Asset.Details): Observable<Asset> {
    return this.http.put<Asset>(`${this.apiUrl}/${projectId}/assets/${asset.id}`, asset);
  }

  getProjectPackages(): Observable<ProjectPackage> {
    return this.http.get<ProjectPackage>(`${this.apiUrl}/packages`);
  }

  getProductTypeFullName(type: ProductType | string): string {
    switch (type) {
      case ProductType.Calculation:
        return 'space-calculations';
      case ProductType.Monitoring:
        return 'monitoring';
      case ProductType.TDD:
        return 'tdd';
      case ProductType.DataRoom:
        return 'data-room';
      case 'factsheet':
        return 'factsheet';
      case 'finding':
        return 'finding';
      case 'photo':
        return 'photo';
      case 'qa':
        return 'q-a';
      case 'report':
        return 'report';
      case 'setting':
        return 'setting';
      case 'statistic':
        return 'statistic';
      case 'user':
        return 'user';
      default:
        return type;
    }
  }

  getLanguageTranslationKey(language: Countries): string {
    let translationKey: string;
    switch (language) {
      case Countries.Denmark:
        translationKey = 'danish';
        break;
      case Countries.France:
        translationKey = 'french';
        break;
      case Countries.Germany:
        translationKey = 'german';
        break;
      case Countries.Italy:
        translationKey = 'italian';
        break;
      case Countries.Spain:
        translationKey = 'spanish';
        break;
      case Countries.UnitedKingdom:
      case Countries.UnitedStates:
        translationKey = 'english';
        break;
      default:
        translationKey = language;
        break;
    }
    return translationKey;
  }
}
