import { ProjectProductType, SearchInItem } from '@core/models';
import * as _ from 'lodash';
import { Address } from './address';
import { AreaStandard, AssetType, Countries, getAllEnumValues } from './enums';
import { Project } from './project';
import { Transform } from './transform';

export class Asset {
  country: Countries; // 2 letters iso code, required
  address?: Address;
  name?: string;
  state: string;
  zip: string;
  city: string; // required
  asset_types: AssetType[]; // required
  year_of_construction: number; // int, required
  plot_size: number; // int, required
  gross_area: number; // int, required
  height: number; // int
  parking_garage_spaces: number; // int
  is_frozen: boolean;
  decimal_separator: DecimalSeparator;
  termination_notice_at: Date;
  status: Asset.Status;

  constructor(asset?: Partial<Asset>) {
    if (asset) {
      _.assign(this, asset);
    }
  }
}
export enum DecimalSeparator {
  Comma = 'comma',
  Point = 'point',
}
export const getKnownDecimalSeparators = getAllEnumValues<DecimalSeparator>(DecimalSeparator);

export namespace Asset {
  export type Status = 'under_construction' | 'project_managers_only' | 'active' | 'blocked' | 'closed' | 'archived';

  export interface Details extends Asset {
    id: string;
    photo_download_token: string;
    building_permit_from: Date;
    year_of_first_usage: number;
    plot_size_acc_to_cadastre: boolean;
    ltbl_area_standard: AreaStandard;
    ltbl_area_acc_to_standard: number;
    ltbl_area_acc_to_standard_from: Date;
    ltbl_area_acc_to_lease_contract: number;
    ltbl_area_acc_to_sales_contract: number;
    floors_above_ground: number;
    floors_below_ground: number;
    parking_spaces_required: number;
    parking_spaces_built: number;
    parking_spaces_counted: number;
    parking_garage: boolean;
    parking_garage_size: number;
    parking_garage_floors_below_ground: number;
    parking_garage_with_sprinkler: boolean;
    due_date: Date;
    tracker_summary: {
      next_description: string;
      next_id: string;
      next_planned_date: Date;
      tracker_count_all: number;
      tracker_count_done: number;
    };
    project_started_at: Date;
    project_modules: string[];
    link_dataroom_image_to_photos: boolean;
    started_at: Date;
    status: Status;
    disclaimer_enabled: boolean;
    project: Project;
    is_initial_setup_done: boolean;
    type: string;
    display_index_points: boolean;
    report_logo_download_token?: string;
  }

  export namespace Details {
    export const transform = (value: Asset.Details): Asset.Details =>
      value && {
        ...value,
        building_permit_from: Transform.date(value.building_permit_from),
        ltbl_area_acc_to_standard_from: Transform.date(value.ltbl_area_acc_to_standard_from),
        due_date: Transform.date(value.due_date),
        tracker_summary: value.tracker_summary && {
          ...value.tracker_summary,
          next_planned_date: Transform.date(value.tracker_summary.next_planned_date),
        },
        project_started_at: Transform.date(value.project_started_at),
        project: Project.transform(value.project),
      };
  }

  export type Filter = Partial<{
    search_term: string;
    search_in: SearchInItem;
    time_frame: string;
    date_from: string;
    date_to: string;
    sort_column: string;
    sort_direction: string;
    with_progress: boolean;
    progress_from: number;
    progress_to: number;
    status: Asset.Status;
    type: ProjectProductType;
  }>;

  export interface OverviewParams {
    page: number;
    per_page: number;
  }
}
