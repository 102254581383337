import { Injectable } from '@angular/core';
import { Document, Finding, Message, Report } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class QaOverviewHelperService {
  constructor() {}

  getIsReferenceExisting({ finding, document, report, report_chapter, node }: Message): boolean {
    return !!finding?.id || !!document?.id || !!report?.id || !!report_chapter?.id || !!node?.document?.id;
  }

  getReferenceFinding({ finding }: Message): Finding | undefined {
    return finding?.id ? finding : undefined;
  }

  getReferenceReport({ report }: Message): Report | undefined {
    return report?.id ? report : undefined;
  }

  getReferenceReportChapter({ report_chapter }: Message): Report.Chapter | undefined {
    return report_chapter?.id ? report_chapter : undefined;
  }

  getReferenceDocument({ finding, document, node }: Message) {
    if (finding?.id) {
      return [];
    }

    const refDoc = document || <Document.Short>node?.document;
    return refDoc?.id && refDoc?.types.includes('photo') ? [] : [refDoc];
  }

  getReferencePages({ finding, document, document_page }: Message): number[] {
    if (finding?.id) {
      return [];
    }

    return document?.id && document?.types.includes('photo') ? [] : [document_page];
  }

  getReferencePhotos({ finding, document }: Message): Document.Short[] {
    if (finding?.id) {
      return [];
    }

    return document?.id && document?.types.includes('photo') ? [<Document.Short>document] : [];
  }
}
