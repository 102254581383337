import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Risk } from '@core/models';
import { CellRendererComponent } from '../cell-renderer';

@Component({
  selector: 'x-risk-renderer',
  templateUrl: './risk-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskCellRendererComponent extends CellRendererComponent<Risk> {}
