import { GridApi, IHeaderParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import * as _ from 'lodash';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EmptyHeaderCellComponent } from '../header-cell';

export interface CheckboxRendererParams extends IHeaderParams {
  onSelectAll?: () => void;
  onDeselectAll?: () => void;
  allSelected?: () => boolean;
  someSelected?: () => boolean;
}

@Component({
  templateUrl: './header-checkbox-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderCheckboxRendererComponent extends EmptyHeaderCellComponent {
  params: CheckboxRendererParams;
  isChecked = false;

  get api(): GridApi {
    return this.params?.api;
  }

  get someSelected(): boolean {
    if (typeof this.params?.someSelected === 'function') {
      return this.params.someSelected();
    }

    return this.getSelectedRows().length > 0 && this.getSelectedRows().length !== this.api?.getDisplayedRowCount();
  }

  get allSelected(): boolean {
    if (typeof this.params?.allSelected === 'function') {
      return this.params.allSelected();
    }
    const selectedRows = this.getSelectedRows();
    return selectedRows.length > 0 && selectedRows.length === this.api?.getDisplayedRowCount();
  }

  constructor(private readonly cdr: ChangeDetectorRef) {
    super();
  }

  agInit(params: IHeaderParams): void {
    super.agInit(params);

    fromEvent(this.api, 'rowSelected')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => this.cdr.markForCheck(),
      });

    fromEvent(this.api, 'paginationChanged')
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          if (this.isChecked && this.api?.getDisplayedRowCount() !== this.api?.getSelectedNodes().length) {
            this.onCheck();
          }
          this.cdr.markForCheck();
        },
      });
  }

  indeterminateChange(indeterminate: boolean) {
    this.isChecked = !this.isChecked && !indeterminate && this.getSelectedRows().length > 0;
  }

  onCheck(): void {
    if (!this.allSelected) {
      this.isChecked = true;
      if (_.isFunction(this.params?.onSelectAll)) {
        return this.params.onSelectAll();
      }

      const rowCount = this.api?.getDisplayedRowCount();

      for (let i = 0; i < rowCount; i++) {
        const node = this.api.getDisplayedRowAtIndex(i);
        node.setSelected(true, false);
      }
    } else {
      this.isChecked = false;
      if (_.isFunction(this.params?.onDeselectAll)) {
        return this.params.onDeselectAll();
      }

      const selectedRows = this.getSelectedRows();
      selectedRows.forEach((row) => row.setSelected(false, true));
    }
  }

  private getSelectedRows() {
    const selectedRows = [];
    const rowCount = this.api?.getDisplayedRowCount();

    for (let i = 0; i < rowCount; i++) {
      const node = this.api.getDisplayedRowAtIndex(i);
      node.isSelected() && selectedRows.push(node);
    }
    return selectedRows;
  }
}
