import { ChangeDetectionStrategy, Component, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '@core/models';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { LocaleDatePipeModule, UserNamePipeModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { NgIf, TitleCasePipe } from '@angular/common';

interface UserDetailPopupComponentData extends User {
  first_access_at?: Date;
  last_access_at?: Date;
}

@Component({
  selector: 'x-user-detail-popup',
  templateUrl: './user-detail-popup.component.html',
  styleUrls: ['./user-detail-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogComponent, UserNamePipeModule, TranslocoModule, LocaleDatePipeModule, TitleCasePipe, NgIf],
})
export class UserDetailPopupComponent {
  readonly ref = inject(MatDialogRef);

  constructor(@Inject(MAT_DIALOG_DATA) public data: UserDetailPopupComponentData) {}
}
