<div class="flex flex-col w-full mt-2.5 space-y-3.5">
  <x-select
    name="trade_id"
    [required]="false"
    [control]="form.controls.trade_id"
    [options]="tradesOptions"
    [withEmptyOption]="true"
    [label]="'tickets-system.columns.trade_id' | transloco"
  ></x-select>
  <x-textarea [label]="'task' | transloco" [required]="true" [control]="form.controls.message"></x-textarea>
  <x-select
    [label]="'type' | transloco"
    [infoTooltip]="'tickets-system.ticket-type-tooltip-info' | transloco"
    [required]="true"
    [options]="typesOptions$ | async"
    [control]="form.controls.type"
  ></x-select>
  <x-select
    *ngIf="form.controls.type.value && form.controls.type.value !== 'task'"
    [label]="'assign-to' | transloco"
    [required]="true"
    [options]="groupOptions$ | async"
    [control]="form.controls.to_group_id"
  ></x-select>
  <ng-container *ngIf="userOptions$ | async; let userOptions">
    <x-select
      *ngIf="userOptions.length > 0 && form.controls.type.value === 'task'"
      [label]="'assign-user' | transloco"
      [required]="true"
      [options]="userOptions"
      [control]="form.controls.to_user_id"
      [withEmptyOption]="true"
    ></x-select>
  </ng-container>

  <div class="flex flex-col" *ngIf="form.controls.to_group_id.value === 'external'">
    <p class="text-sm text-gray-900">
      {{ 'tickets-system.external-email-address' | transloco }}
      <span class="text-danger-500">*</span>
    </p>
    <x-add-member-field
      [(members)]="externalMembers"
      (errors)="handleExternalMemberErrorsChange($event)"
      (membersChange)="onExternalMembersChange()"
    ></x-add-member-field>
    <x-info-panel icon="info" variant="gray" class="mt-4 mb-0.5">
      {{ 'tickets-system.external-user-references-note-text' | transloco }}
    </x-info-panel>
  </div>
  <div>
    <span class="text-sm text-gray-900">{{ 'tickets-system.add-ticket-modal-reference' | transloco }}</span>
    <x-attach-reference-button
      *ngIf="!attachedReferences?.length; else preview"
      [singleOnly]="true"
      (referenceSelected)="attachReference($event)"
    ></x-attach-reference-button>
    <ng-template #preview>
      <x-references-preview
        (referenceRemoved)="removeReference($event)"
        [attachedReferencesList]="attachedReferences"
        [assetId]="assetId$ | async"
        [canRemoveReference]="true"
      ></x-references-preview>
    </ng-template>
  </div>
  <x-select
    [label]="'priority' | transloco"
    [required]="true"
    [options]="priorityOptions"
    [control]="form.controls.priority"
  ></x-select>
  <x-date-input
    [label]="'due-date' | transloco"
    [required]="true"
    [disallowPastDates]="true"
    [value]="form.controls.due_date.value | callFunction : getDateFieldValue"
    (valueChange)="onDueDateChange($event)"
  ></x-date-input>
  <x-info-panel icon="info" variant="gray" *ngIf="form.controls.to_group_id.value === 'external'">
    {{ 'tickets-system.external-user-due-date-info-text' | transloco }}
  </x-info-panel>
</div>
