import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { UsersSelectors } from '../state/users/users.selectors';

@Injectable()
export class AdminGuard implements CanLoad, CanActivateChild, CanActivate {
  readonly isAdmin$ = this.store.select(UsersSelectors.getIsAdmin);

  constructor(private store: Store<unknown>) {}

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    return this.isAdmin$;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAdmin$;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.isAdmin$;
  }
}
