import { IHeaderAngularComp } from '@ag-grid-community/angular';
import { IHeaderParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { DestroyComponent } from '@common';

@Component({
  selector: 'x-empty-header-cell',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EmptyHeaderCellComponent extends DestroyComponent implements IHeaderAngularComp {
  params: IHeaderParams;

  constructor() {
    super();
  }

  agInit(params: IHeaderParams): void {
    this.params = params;
  }

  refresh(params: IHeaderParams): boolean {
    this.params = params;
    return true;
  }
}
