<mat-form-field>
  <input
    #searchInput
    matInput
    autocomplete="off"
    [autofocus]
    [(ngModel)]="filter.query"
    (keydown)="onInputKeydown($event)"
    (change)="onApplyFilter()"
  />
</mat-form-field>

<button mat-icon-button (click)="onClickFilter()">
  <mat-icon *ngIf="filterApplied">search_off</mat-icon>
  <mat-icon *ngIf="!filterApplied">search</mat-icon>
</button>

<button mat-icon-button [class.filter-applied]="filter.status" [matMenuTriggerFor]="filterMenu">
  <mat-icon class="material-icons-outlined">filter_alt</mat-icon>
</button>

<mat-menu #filterMenu="matMenu">
  <button mat-menu-item *ngFor="let status of statuses" (click)="onToggleStatus(status)">
    <mat-icon class="done-icon" [class.hidden]="filter.status !== status">done</mat-icon>

    <span [ngSwitch]="status">
      <ng-container *ngSwitchCase="'open'">{{ 'open' | transloco }}</ng-container>
      <ng-container *ngSwitchCase="'prending_approval'">{{ 'pending-approval' | transloco }}</ng-container>
      <ng-container *ngSwitchCase="'answered'">{{ 'answered' | transloco }}</ng-container>
      <ng-container *ngSwitchCase="'closed'">{{ 'closed' | transloco }}</ng-container>

      <ng-container *ngSwitchDefault>{{ status }}</ng-container>
    </span>
  </button>
</mat-menu>
