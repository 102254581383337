import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiErrorResponse, Document, Message } from '@core/models';
import { FormatDateService, PORTAL_API_URL, StorageService, UploadTrackerService, UserService } from '@core/services';
import { environment } from '@environments';
import { DocumentsService, DownloadTokenAction } from '@portal/services/documents.service';
import * as _ from 'lodash';
import { EMPTY, from, Observable, of, Subject, throwError, zip } from 'rxjs';
import { catchError, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import {
  BatchUpload,
  BatchUploadFile,
  BatchUploadForm,
  MediaFileType,
  UploadMetadata,
  UploadResumingService,
} from '@portal/customer/services/upload-resuming.service';
import { FileUploadStatus } from '@portal/customer/components/asset-details/data-room/services';

export type ImageSizeVersion = 1600 | 360 | 120 | undefined;

export interface DocumentQueryParams {
  published?: boolean;
  search_str?: string;
  types?: Document.Type[];
  tags?: string[];
  dates?: Date[];
  page?: number;
  per_page?: number;
  sort_column?: string;
  sort_direction?: 'asc' | 'desc';
  uploaded_at_from?: Date;
  uploaded_at_to?: Date;
  original_datetime_from?: Date;
  original_datetime_to?: Date;
  ver?: ImageSizeVersion; // specifies version of images from download_token in response
}

export interface DocumentsQueryResult {
  documents: Document.ListItem[];
  page: number;
  per_page: number;
  total_count: number;
}

export type DocumentsUploadMetadata = UploadMetadata<{
  tags: string[];
}>;

export type DocumentsUploadSavedUploadMetadata = DocumentsUploadMetadata & { id: string };

@Injectable({ providedIn: 'root' })
export class CustomerDocumentsService extends DocumentsService {
  readonly apiUrl = `${PORTAL_API_URL}/assets`;

  constructor(
    private readonly http: HttpClient,
    private readonly formatDateService: FormatDateService,
    private readonly storageService: StorageService,
    private readonly uploadTrackerService: UploadTrackerService,
    private readonly uploadResuming: UploadResumingService,
    private readonly userService: UserService
  ) {
    super();
  }

  createAssetDocument(
    { files, asset_id }: { files: File[]; asset_id: string },
    typesOrTypeGetter: Document.Type[] | ((file: File) => Document.Type[]) = ['qa'],
    tags: string[] = [],
    cancellationSubject: Subject<void> = new Subject(),
    resumableUpload?: boolean
  ) {
    const fileStatuses: FileUploadStatus[] = files.map((file) => ({
      id: this.uploadTrackerService.generateRandomUUID(),
      rawFile: file,
      name: file.name,
      size: file.size,
      filePath: this.uploadResuming.getFilePath(file),
      status: 'pending',
      types: typeof typesOrTypeGetter === 'function' ? typesOrTypeGetter(file) : typesOrTypeGetter,
    }));
    const documentsUploadMetadata: DocumentsUploadMetadata = {
      uploadSection: 'photos',
      userId: this.userService.user$.value.id,
      assetId: asset_id,
      fileStatuses,
      data: {
        tags,
      },
    };
    const filesPaths = documentsUploadMetadata.fileStatuses.map((file) => file.filePath);
    const uploadFormParams: BatchUploadForm = {
      files_count: filesPaths.length,
      folders: documentsUploadMetadata.folders,
      files: filesPaths,
      files_type: (fileStatuses?.[0]?.types[0] as MediaFileType) || 'photo',
    };
    return this.createPhotoBatchUpload(documentsUploadMetadata.assetId, uploadFormParams).pipe(
      switchMap((batchUpload) =>
        (resumableUpload
          ? from(this.uploadResuming.addUploadData({ ...documentsUploadMetadata, batchUpload }))
          : of(documentsUploadMetadata)
        ).pipe(switchMap((documentsSavedUpload) => this.startUpload(documentsSavedUpload, cancellationSubject)))
      )
    );
  }

  startUpload(
    documentsSavedUpload: DocumentsUploadMetadata | DocumentsUploadSavedUploadMetadata,
    cancellationSubject: Subject<void> = new Subject()
  ) {
    const { assetId, fileStatuses, batchUpload } = documentsSavedUpload;
    const url = `${this.apiUrl}/${assetId}/documents`;
    this.uploadTrackerService.addFiles(fileStatuses, assetId, batchUpload.id, cancellationSubject);
    return from(documentsSavedUpload.fileStatuses.filter((filestatus) => filestatus.status === 'pending')).pipe(
      mergeMap(
        (fileStatus) =>
          (fileStatus.document || documentsSavedUpload.batchUpload
            ? of({ doc: fileStatus.document })
            : this.requestUploadToken(fileStatus.rawFile, url, fileStatus.types, documentsSavedUpload.data.tags)
          ).pipe(
            takeUntil(cancellationSubject),
            mergeMap(async ({ doc }) => {
              let uploadToken = doc?.upload_token;
              if (documentsSavedUpload.batchUpload && !uploadToken) {
                const documentIdEntries = Object.entries(documentsSavedUpload?.batchUpload?.files_map || []).find(
                  ([, name]) => name === fileStatus.filePath
                );
                const batchUploadFile = await this.createPlaceholderPhotoFile(
                  assetId,
                  documentsSavedUpload.batchUpload.id,
                  documentIdEntries?.length ? documentIdEntries[0] : null
                ).toPromise();
                uploadToken = batchUploadFile.upload_token;
                if ('id' in documentsSavedUpload) {
                  await this.uploadResuming.setUploadTokenForSavedUpload(
                    documentsSavedUpload.id,
                    fileStatus.id,
                    batchUploadFile.upload_token
                  );
                }
              }
              return this.uploadFile(fileStatus.rawFile, doc, !!fileStatus.document, uploadToken, async () => {
                if ('id' in documentsSavedUpload) {
                  await this.uploadResuming.markFileAsCompleted(documentsSavedUpload.id, fileStatus);
                }
              }).toPromise();
            }),
            catchError((status: ApiErrorResponse) => {
              console.error(status);
              this.uploadTrackerService.handleUploadError(status, fileStatus.rawFile);
              return of({ file: fileStatus.rawFile, doc: null as Document, status });
            }),
            takeUntil(cancellationSubject)
          ),
        environment.document.maxConcurrentUploads
      ),
      takeUntil(cancellationSubject)
    );
  }

  fetchCustomerDocumentsByIds(assetId: string, ids: string[]): Observable<Document.ListItem[]> {
    const requests = ids.map((id) => {
      const url = `${this.apiUrl}/${assetId}/documents/${id}`;
      return this.http.get<Document.ListItem>(url);
    });

    return zip(...requests);
  }

  fetchCustomerDocumentsForTheAsset<T = Document.ListItem[] | DocumentsQueryResult>(
    assetId: string,
    query: DocumentQueryParams,
    params: Partial<{
      returnQueryResult: boolean;
    }> = {}
  ): Observable<T> {
    if (!assetId) {
      if (!params?.returnQueryResult) {
        return of(<T>(<unknown>[]));
      } else {
        return of(<T>(<unknown>(<DocumentsQueryResult>{
          documents: [],
          page: 0,
          per_page: 0,
          total_count: 0,
        })));
      }
    }

    const parameters: string[] = this.resolveDocumentQuery(query);

    const url = `${this.apiUrl}/${assetId}/documents?${parameters.join('&')}`;

    return this.http.get<DocumentsQueryResult>(url).pipe(
      map((result) => {
        const documents = _.map(result.documents, (document) => ({
          ...Document.transform(document),
        }));

        if (!!params?.returnQueryResult) {
          return <T>(<unknown>(<DocumentsQueryResult>{
            ...result,
            documents,
          }));
        }

        return <T>(<unknown>documents);
      })
    );
  }

  /**
   * Gets download_token
   *
   * @param asset_id
   * @param id
   * @param version - It allows us to specify the "ver" query parameter to download the thumbnail version of a photo uploaded to the photos section.
   * Currently, we support 1600px, 360px and 120px thumbnails, if "ver" is not specified then the original version of the file is returned
     @param action
   */
  getNewDownloadTokenForAssetDocument(
    { asset_id, id }: Partial<Document.ListItem>,
    { version, action }: { version?: ImageSizeVersion; action?: DownloadTokenAction } = {}
  ): Observable<string | undefined> {
    const url = `${this.apiUrl}/${asset_id}/documents/${id}/download_token`;
    let params = new HttpParams();
    if (version) {
      params = params.set('ver', version);
    }
    if (action) {
      params = params.set('action', action);
    }
    return this.http
      .post<Partial<Document.ListItem>>(`${url}${params.toString() ? `?${params.toString()}` : ''}`, null)
      .pipe(
        map(({ download_token }) => download_token),
        catchError(() => EMPTY)
      );
  }

  getListOfTags({ asset_id }: { asset_id: string }): Observable<string[]> {
    if (!asset_id) {
      return of([]);
    }

    const url = `${this.apiUrl}/${asset_id}/documents/tags`;
    return this.http.get<string[]>(url);
  }

  getListOfAssetPhotoDates(asset_id: string): Observable<Date[]> {
    if (!asset_id) {
      return of([]);
    }

    const url = `${this.apiUrl}/${asset_id}/documents/photo_dates`;
    return this.http.get<Date[]>(url).pipe(map((dates) => _.map(dates, (date) => new Date(date))));
  }

  getDocument(asset_id: string, document_id: string, withNodeDetails = false): Observable<Document.ListItem> {
    if (!asset_id || !document_id) {
      return EMPTY;
    }

    const url = `${this.apiUrl}/${asset_id}/documents/${document_id}`;
    return this.http
      .get<Document.ListItem>(url, { params: { node_details: withNodeDetails } })
      .pipe(map((doc) => Document.transform(doc)));
  }

  getDownloadToken(
    doc: Document.ListItem | Document,
    { version, action }: { version?: ImageSizeVersion; action?: DownloadTokenAction } = {}
  ): Observable<string> {
    return this.getNewDownloadTokenForAssetDocument(doc, { version, action });
  }

  getDocumentTypes(filter?: 'reports' | 'media'): Observable<Document.Type[]> {
    const url = `${PORTAL_API_URL}/documents/types`;
    let params: HttpParams;
    if (filter) {
      params = <HttpParams>(<unknown>{
        filter,
      });
    }
    return this.http.get<Document.Type[]>(url, { params });
  }

  getMessages({ asset_id, document_id }: { asset_id: string; document_id: string }): Observable<Message.Short[]> {
    const url = `${this.apiUrl}/${asset_id}/documents/${document_id}/messages`;
    return this.http.get<Message.Short[]>(url).pipe(
      map((messages) => _.filter(messages, (message) => message.document_id === document_id)),
      map((messages) => _.map(messages, (message) => Message.Short.transform(message)))
    );
  }

  getMediaMessages({ asset_id }: { asset_id: string }): Observable<Message.Short[]> {
    const url = `${this.apiUrl}/${asset_id}/documents/media/messages`;
    return this.http
      .get<Message.Short[]>(url)
      .pipe(map((messages) => _.map(messages, (message) => Message.Short.transform(message))));
  }

  appendTagsByDocumentName(params: { asset_id: string; names: string[]; tags: string[] }): Observable<void> {
    const { asset_id, names, tags } = params;
    const url = `${this.apiUrl}/${asset_id}/documents/append_tags_by_name`;

    return this.http.put<void>(url, { tags, names });
  }

  /**
   * remove one or several tags from all photos in scope of an asset
   */
  deleteTags({ asset_id, tags }: { asset_id: string; tags: string[] }): Observable<string[]> {
    const url = `${this.apiUrl}/${asset_id}/documents/tags`;

    return this.http.request<string[]>('delete', url, {
      body: { tags },
      responseType: 'json',
    });
  }

  tagPhoto(document: Document.ListItem, tagAllPhotos = false): Observable<void> {
    const { id, asset_id, tags } = document;
    const urlPart = tagAllPhotos ? `tags` : `${id}/tags`;
    const url = `${this.apiUrl}/${asset_id}/documents/${urlPart}`;

    return this.http.patch<void>(url, { tags });
  }

  publishDocument(document: Document.ListItem) {
    const { id, asset_id } = document;
    const url = `${this.apiUrl}}/${asset_id}/documents/${id}/publish`;

    return this.http.patch<void>(url, null);
  }

  publishDocumentsBatch(documents: Document.ListItem[]) {
    if (_.isEmpty(documents)) {
      return EMPTY;
    }

    const { asset_id } = _.head(documents);
    const ids = _.map(documents, ({ id }) => id);
    const url = `${this.apiUrl}/${asset_id}/documents/publish`;

    return this.http.post<void>(url, { ids });
  }

  publishDocumentsThatMatchFilters(
    assetId: string,
    docs: Document.ListItem[],
    query?: DocumentQueryParams,
    excluded_ids: string[] = []
  ) {
    const params: string[] = this.resolveDocumentQuery(query);
    const url = `${this.apiUrl}/${assetId}/documents/photos/publish`;

    return this.http.post<void>(url + (params?.length ? `?${params.join('&')}` : ''), {
      ids: docs ? _.map(docs, ({ id }) => id) : [],
      excluded_ids,
    });
  }

  deleteDocuments(documents: Document.ListItem[]) {
    if (_.isEmpty(documents)) {
      return EMPTY;
    }
    const { asset_id } = _.head(documents);
    const ids = _.map(documents, ({ id }) => id);
    const url = `${this.apiUrl}/${asset_id}/documents`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: ids,
    };
    return this.http.delete(url, options);
  }

  deletePhotos(
    assetId: string,
    photos?: Document.ListItem[],
    query?: DocumentQueryParams,
    excluded_ids: string[] = []
  ) {
    const params: string[] = this.resolveDocumentQuery(query);
    const url = `${this.apiUrl}/${assetId}/documents/photos?${params.join('&')}`;

    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ids: photos ? _.map(photos, ({ id }) => id) : [],
        excluded_ids,
      },
    };
    return this.http.delete(url, { ...options });
  }

  batchDownloadPhotos(
    assetId: string,
    ids: string[],
    query: DocumentQueryParams,
    excluded_ids: string[]
  ): Observable<string> {
    const parameters: string[] = this.resolveDocumentQuery(query);
    const url = `${PORTAL_API_URL}/assets/${assetId}/documents/photos/download?${parameters.join('&')}`;
    return this.http.post<string>(url, { ids, excluded_ids });
  }

  isUploadedIntoSystem(document: Document) {
    return document.types.some((type) => ['photo', 'panorama', 'video', 'dataroom'].includes(type));
  }

  isUploadedIntoPhotos(document: Document) {
    return document.types.some((type) => ['photo', 'panorama', 'video'].includes(type));
  }

  createPhotoBatchUpload(assetId: string, batchUploadForm: BatchUploadForm) {
    const url = `${this.apiUrl}/${assetId}/batch_uploads/photo`;
    return this.http.post<BatchUpload>(url, batchUploadForm);
  }

  createPlaceholderPhotoFile(assetId: string, batchUploadId: string, documentId: string) {
    const url = `${this.apiUrl}/${assetId}/batch_uploads/${batchUploadId}/files/replace_photo`;
    return this.http.post<BatchUploadFile>(url, { document_id: documentId });
  }

  private formatParameter(key: string, value: unknown) {
    if (key === 'dates' && _.isDate(value)) {
      return `${key}=${this.formatDateService.format(value as Date)}`;
    }

    return `${key}=${_.isDate(value) ? (<Date>value).toISOString() : value}`;
  }

  private resolveDocumentQuery(query: DocumentQueryParams) {
    const parameters: string[] = [];
    _.forIn(query, (value, key) => {
      if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) || !key) {
      } else if (_.isArray(value)) {
        _.forEach(value, (v) => {
          parameters.push(this.formatParameter(key, v));
        });
      } else {
        parameters.push(this.formatParameter(key, value));
      }
    });
    return parameters;
  }

  private requestUploadToken(
    file: File,
    url: string,
    types: Document.Type[],
    tags: string[] = []
  ): Observable<{ doc: Document; file: File }> {
    if (!file) {
      return throwError(422);
    }

    const newDoc: Document.NewDocument = {
      description: '',
      name: file.name,
      tags,
      types,
    };
    return this.http.post<Document>(url, newDoc).pipe(
      map((doc) => ({
        doc,
        file,
      }))
    );
  }

  private uploadFile(
    file: File,
    doc: Document,
    suppress404: boolean,
    uploadToken: string,
    completeCallback: () => Promise<unknown>
  ): Observable<{ file: File; doc: Document; status: ApiErrorResponse }> {
    return this.storageService.upload(uploadToken, file, suppress404, completeCallback).pipe(
      map(() => ({ file, doc, status: null })),
      catchError((status: ApiErrorResponse) => {
        console.error('uploadFile failed: ', status);
        return of({ file, doc, status });
      })
    );
  }
}
