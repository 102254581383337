import { Injectable } from '@angular/core';
import { Message } from '@core/models';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class PageMessagesService {
  transform(messages: Message.Short[] | undefined, currentPage: number | undefined): Message.Short[] {
    if (!messages || _.isUndefined(currentPage)) {
      return [];
    }

    return _(messages)
      .filter(({ document_page }) => document_page === currentPage)
      .value();
  }
}
