import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { isNotNil } from '@app/utils';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { Document } from '@core/models';
import { ActivityStatisticsService, StorageService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CustomerDocumentsService } from '@portal/customer/services';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

export interface ImageDialogData {
  document: Document.Short;
}
@UntilDestroy()
@Component({
  selector: 'x-image-viewer-dialog',
  templateUrl: './image-viewer-dialog.component.html',
  styleUrls: ['./image-viewer-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageViewerDialogComponent implements OnInit {
  assetId$ = this.store.select(CustomerRouterSelectors.getAssetId).pipe(filter(isNotNil));
  photo: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ImageDialogData,
    private readonly dialogRef: MatDialogRef<DialogComponent, boolean>,
    private readonly store: Store,
    private readonly cdr: ChangeDetectorRef,
    private readonly customerDocumentsService: CustomerDocumentsService,
    private readonly storageService: StorageService,
    private readonly activityService: ActivityStatisticsService
  ) {}

  ngOnInit() {
    this.customerDocumentsService
      .getNewDownloadTokenForAssetDocument(this.data.document, { version: 1600 })
      .pipe(
        switchMap((downloadToken) => this.storageService.getImageWithDownloadToken({ download_token: downloadToken })),
        switchMap((blob: Blob) =>
          this.activityService
            .trackActivity(this.data.document.asset_id, {
              document_id: this.data.document.id,
              scope: 'photo',
              action: 'view',
            })
            .pipe(
              map(() => blob),
              catchError(() => of(blob))
            )
        ),
        untilDestroyed(this)
      )
      .subscribe({
        next: (photoFile: Blob) => {
          this.photo = window.URL.createObjectURL(photoFile);
        },
        complete: () => {
          if (this.photo === undefined) {
            this.dialogRef.close();
          } else {
            this.cdr.markForCheck();
          }
        },
      });
  }

  onClose() {
    this.dialogRef.close();
  }
}
