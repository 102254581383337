<mat-form-field class="w-full">
  <mat-chip-grid
    #chipGrid
    class="border-gray-300 mt-1 border-2 block w-full appearance-none rounded-md px-3 py-2 placeholder-gray-400 shadow-sm focus-within:border-primary-500 focus-within:outline-none focus-within:ring-primary-500 text-sm"
    [ngClass]="{ '!border-danger-500': errorMessage }"
  >
    <ng-template
      *ngFor="let member of members; trackBy: trackByFn"
      [ngTemplateOutlet]="chipRowTemplate"
      [ngTemplateOutletContext]="{
        member: member,
        isMemberOfAsset: isAlreadyAMemberOfTheAsset(member.email),
        isEmailValid: isEmailValid(member.email)
      }"
    ></ng-template>
    <input
      class="'min-h-2'"
      [placeholder]="!members.length ? placeholder : ''"
      [matChipInputFor]="chipGrid"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      [matChipInputAddOnBlur]="true"
      (matChipInputTokenEnd)="add($event)"
      (focus)="errorMessage = ''"
    />
  </mat-chip-grid>
</mat-form-field>
<span class="text-sm text-danger-500 mt-1.5">{{ errorMessage | transloco }}</span>

<ng-template #chipRowTemplate let-member="member" let-isMemberOfAsset="isMemberOfAsset" let-isEmailValid="isEmailValid">
  <mat-chip-row
    (removed)="remove(member)"
    [editable]="true"
    (edited)="edit(member, $event)"
    [matTooltip]="
      isMemberOfAsset
        ? ('member-is-already-member-of-asset' | transloco)
        : !isEmailValid
        ? ('email-incorrect' | transloco)
        : undefined
    "
    [ngClass]="{
      '!bg-secondary-100': isEmailValid && isMemberOfAsset,
      '!bg-gray-200': isEmailValid && !isMemberOfAsset,
      '!bg-white border border-danger-500': !isEmailValid
    }"
  >
    <span
      class="ellipsis"
      [title]="member.email"
      [ngClass]="{
        'text-danger-500': !isEmailValid
      }"
    >
      {{ member.email }}
    </span>
    <div class="cursor-pointer" matChipRemove>
      <x-svg-icon svgImageName="cross" svgClass="w-4 h-4 text-gray-900 rounded-xl hover:bg-gray-300"></x-svg-icon>
    </div>
  </mat-chip-row>
</ng-template>
