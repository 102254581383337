import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments';
import * as _ from 'lodash';
import { EMPTY, from, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { ImageSizeVersion } from '@portal/customer/services';
import { DocumentsService, DownloadTokenAction } from '../../portal/services/documents.service';
import { ApiErrorResponse, Document } from '../models';
import { PORTAL_API_URL } from './constants';
import { StorageService } from './storage.service';

interface DocumentQueryParams {
  only_published?: boolean;
  search_str?: string;
  types?: Document.Type[];
  tags?: string[];
  page?: number;
  per_page?: number;
  sort_column?: string;
  sort_direction?: 'asc' | 'desc';
}

interface DocumentsQueryResult {
  documents: Document.ListItem[];
  page: number;
  per_page: number;
  total_count: number;
}

@Injectable({
  providedIn: 'root',
})
export class AdminDocumentsService extends DocumentsService {
  readonly apiUrl = `${PORTAL_API_URL}/admin/projects`;

  constructor(private http: HttpClient, private storageService: StorageService) {
    super();
  }

  createProjectDocument(
    files: File[],
    project_id: string,
    asset_id: string,
    types: Document.Type[] | ((file: File) => Document.Type[])
  ) {
    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents`;

    return from(files).pipe(
      mergeMap(
        (file) =>
          this.requestUploadToken(file, url, _.isArray(types) ? types : (types as any)(file)) // eslint-disable-line @typescript-eslint/no-explicit-any
            .pipe(
              mergeMap(({ doc }) => this.uploadFile(file, doc)),
              catchError((status: ApiErrorResponse) => of({ file, doc: null as Document, status }))
            ),
        environment.document.maxConcurrentUploads
      )
    );
  }

  fetchDocumentsForTheAsset(
    projectId: string,
    assetId: string,
    type: Document.Type | Document.Type[],
    query?: DocumentQueryParams
  ): Observable<DocumentsQueryResult> {
    if (!projectId || !assetId) {
      return of({
        page: 0,
        per_page: 0,
        total_count: 0,
        documents: [],
      });
    }

    let url = `${this.apiUrl}/${projectId}/assets/${assetId}/documents`;

    query = query || {};
    if (!Array.isArray(type) && Document.Type.isPhoto(type)) {
      query = query || {};
      query = {
        ...query,
        types: ['panorama', 'video', 'photo'],
      };
    } else if (!_.isNil(type)) {
      query = {
        ...query,
        types: Array.isArray(type) ? type : [type],
      };
    }

    const parameters: string[] = [];
    _.forIn(query, (value, key) => {
      if (_.isUndefined(value) || _.isNull(value) || _.isNaN(value) || !key) {
      } else if (_.isArray(value)) {
        _.forEach(value, (v) => {
          parameters.push(`${key}=${v}`);
        });
      } else {
        parameters.push(`${key}=${value}`);
      }
    });

    if (parameters.length) {
      url += `?${parameters.join('&')}`;
    }

    return this.http.get<DocumentsQueryResult>(url).pipe(
      map((response) => ({
        ...response,
        documents: _.map(response.documents, (document) => ({
          ...Document.transform(document),
        })),
      }))
    );
  }

  getNewDownloadTokenForProjectAssetDocument(
    document: Document.ListItem,
    { version, action }: { version?: ImageSizeVersion; action?: DownloadTokenAction } = {}
  ): Observable<string> {
    const { project_id, asset_id, id } = document;
    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents/${id}/download_token`;

    let params = new HttpParams();
    if (version) {
      params = params.set('ver', version);
    }
    if (action) {
      params = params.set('action', action);
    }
    return this.http
      .post<Partial<Document.ListItem>>(`${url}${params.toString() ? `?${params.toString()}` : ''}`, null)
      .pipe(map(({ download_token }) => download_token));
  }

  publishDocument(document: Document.ListItem): Observable<void> {
    const { project_id, id, asset_id } = document;
    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents/${id}/publish`;

    return this.http.patch<void>(url, null);
  }

  publishDocumentsBatch(documents: Document.ListItem[]) {
    if (_.isEmpty(documents)) {
      return EMPTY;
    }

    const { project_id, asset_id } = _.head(documents);
    const ids = _.map(documents, ({ id }) => id);
    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents/publish`;

    return this.http.post<void>(url, { ids });
  }

  tagPhoto(document: Document.ListItem, tagAllPhotos = false): Observable<void> {
    const { project_id, id, asset_id, tags } = document;
    const urlPart = tagAllPhotos ? `tags` : `${id}/tags`;
    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents/${urlPart}`;

    return this.http.patch<void>(url, { tags });
  }

  appendTagsByDocumentName(params: {
    project_id: string;
    asset_id: string;
    names: string[];
    tags: string[];
  }): Observable<void> {
    const { project_id, asset_id, names, tags } = params;
    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents/append_tags_by_name`;

    return this.http.put<void>(url, { tags, names });
  }

  getListOfTags({ project_id, asset_id }: { project_id: string; asset_id: string }): Observable<string[]> {
    if (!project_id || !asset_id) {
      return of([]);
    }

    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents/tags`;
    return this.http.get<string[]>(url);
  }

  getDocument(project_id: string, asset_id: string, document_id: string): Observable<Document.ListItem> {
    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents/${document_id}`;
    return this.http.get<Document.ListItem>(url).pipe(map((doc) => Document.transform(doc)));
  }

  deleteAssetDocuments(
    { asset_id, project_id }: { asset_id: string; project_id: string },
    ids: string[]
  ): Observable<void> {
    const url = `${this.apiUrl}/${project_id}/assets/${asset_id}/documents`;
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: ids,
    };
    return this.http.delete<void>(url, options) as any; // eslint-disable-line @typescript-eslint/no-explicit-any
  }

  getDownloadToken(doc: Document.ListItem): Observable<string> {
    return this.getNewDownloadTokenForProjectAssetDocument(doc);
  }

  getDocumentTypes(filter?: 'reports' | 'media'): Observable<Document.Type[]> {
    const url = `${PORTAL_API_URL}/documents/types`;
    let params: HttpParams;
    if (filter) {
      params = <HttpParams>(<unknown>{
        filter,
      });
    }
    return this.http.get<Document.Type[]>(url, { params });
  }

  private requestUploadToken(
    file: File,
    url: string,
    types: Document.Type[]
  ): Observable<{ doc: Document; file: File }> {
    if (!file) {
      return throwError(422);
    }

    const newDoc: Document.NewDocument = {
      description: '',
      name: file.name,
      tags: [],
      types,
    };
    return this.http.post<Document>(url, newDoc).pipe(
      map((doc) => ({
        doc,
        file,
      }))
    );
  }

  private uploadFile(file: File, doc: Document): Observable<{ file: File; doc: Document; status: ApiErrorResponse }> {
    const { upload_token } = doc;
    return this.storageService.upload(upload_token, file).pipe(
      map(() => ({ file, doc, status: null })),
      catchError((status: ApiErrorResponse) => of({ file, doc, status }))
    );
  }
}
