import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router, RouterModule } from '@angular/router';
import { CallPipeModule, LocaleDatePipeModule, SafePipeModule } from '@app/common';
import { CardComponent } from '@app/common/components/card/card.component';
import { SvgIconModule } from '@app/common/components/svg-icon/svg-icon.module';
import { HeaderNotificationsStore } from '@app/common/page/header/header-notifications.store';
import { SSE } from '@app/core/models';
import { CustomerRoutingService } from '@app/portal/customer/services';
import { IndexPointsService } from '@core/services/index-points.service';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { StoreModule } from '@ngrx/store';
import { AdminRoutingService } from '@portal/admin/services';
import EventData = SSE.EventData;
import ReloadSubtreeUpload = SSE.EventData.ReloadSubtreeUpload;
import TicketActions = SSE.EventData.TicketActions;
import TicketListUpdated = SSE.EventData.TicketListUpdated;
import VersionUpdated = SSE.EventData.VersionUpdated;

interface NotificationState {
  read: boolean;
  event: SSE.EventData;
}

@Component({
  selector: 'x-notification-list',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoModule,
    RouterModule,
    CallPipeModule,
    StoreModule,
    CardComponent,
    SvgIconModule,
    LocaleDatePipeModule,
    MatTooltipModule,
    SafePipeModule,
  ],
  templateUrl: './notification-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationListComponent {
  @Input() notifications: NotificationState[];
  @Output() actionClicked = new EventEmitter();

  constructor(
    private readonly customerRouting: CustomerRoutingService,
    private readonly adminRouting: AdminRoutingService,
    private readonly router: Router,
    private readonly notificationsStore: HeaderNotificationsStore,
    private readonly transloco: TranslocoService,
    private readonly indexPointsService: IndexPointsService
  ) {}

  onNotificationActionBtnClick(notificationState: NotificationState) {
    if (!notificationState.read) {
      this.notificationsStore.markRead(notificationState.event);
    }
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigateByUrl(`${this.getNotificationLink(notificationState.event)}`));
    this.actionClicked.next();
  }

  onRemoveNotification(notificationState: NotificationState) {
    this.notificationsStore.remove(notificationState.event);
  }

  getNotificationText = (event: SSE.EventData): string => {
    switch (event.type) {
      case 'asset_status_changed':
        return this.transloco.translate('sse.asset_status_changed', {
          value: this.transloco.translate(`asset-statuses.${event.data.status}`),
          assetName: event.data.name,
        });

      case 'delete_user': {
        return this.transloco.translate('delete_user', {
          ...this.getUserNameTranslationParams(event.data),
          groupName: event.data.group_name,
        });
      }
      case 'file_ready':
        return this.transloco.translate('sse.file_ready', {
          value: event.data.node_label,
        });

      case 'finding_published': {
        return this.transloco.translate('sse.finding_published', {
          value: event.data.number,
        });
      }

      case 'media_files_published': {
        const moreThanOnePhoto = event.data.count > 1;
        const moreThanOnePhotoText = this.transloco.translate('sse.media_files_published-multiple-values', {
          value: event.data.count,
        });

        return moreThanOnePhoto
          ? moreThanOnePhotoText
          : `${this.transloco.translate('sse.media_files_published', {
              value: event.data.name || '',
            })}`;
      }

      case 'move_user': {
        return this.transloco.translate('sse.move_user', {
          ...this.getUserNameTranslationParams(event.data),
          groupName: event.data.group_name,
          oldGroupName: event.data.old_group_name,
        });
      }

      case 'new_asset':
        return this.transloco.translate('sse.new_asset', {
          value: event.data.name,
        });

      case 'new_project':
        return this.transloco.translate('sse.new_project', {
          value: event.data.name,
        });

      case 'new_user': {
        return this.transloco.translate('sse.new_user', {
          ...this.getUserNameTranslationParams(event.data),
          groupName: event.data.group_name,
        });
      }

      case 'qa_todo_list_updated':
        return this.transloco.translate('sse.qa_todo_list_updated', {
          value: event.data.qa_message_number,
        });

      case 'reload_subtree':
        event = event as ReloadSubtreeUpload;
        return this.transloco.translate('sse.reload_subtree', {
          path: `${this.indexPointsService.getFormattedPath(
            [...(event.data?.labels_path || []), event.data.node_label],
            event.data.index_point
          )}/`,
          count: event.data.files_count,
        });

      case 'report_published':
        return this.transloco.translate('sse.report_published', {
          value: event.data.name,
        });

      case 'undefined': {
        const isNewVersionAvailable = ['approve_version', 'switch_version'].includes(event.action);
        if (isNewVersionAvailable) {
          event = event as VersionUpdated;
          return this.transloco.translate('versioning.new-file-version-available', {
            path: `${this.indexPointsService.getFormattedPath(
              [...(event.data?.labels_path || []), event.data?.label],
              event.data?.index_point
            )}`,
          });
        }
      }
    }

    if (event?.['module'] === 'ticket') {
      switch ((event as TicketListUpdated).action) {
        case 'answer':
          return this.transloco.translate('sse.ticket.answer', { value: event?.data?.['qa_message_number'] });
        case 'reject':
          return this.transloco.translate('sse.ticket.reject', { value: event?.data?.['qa_message_number'] });
        case 'close':
          return this.transloco.translate('sse.ticket.close', { value: event?.data?.['qa_message_number'] });
        case 'create':
          return this.transloco.translate('sse.ticket.create', { value: event?.data?.['qa_message_number'] });
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (event.data as any)?.node_label || event.type;
  };

  getActionButtonText(event: SSE.EventData) {
    switch (event.type) {
      case 'report_published': {
        return this.transloco.translate('sse.actions.open-report');
      }
      case 'qa_todo_list_updated': {
        return this.transloco.translate('sse.actions.navigate-to-impacted-qa');
      }
      case 'reload_subtree':
      case 'file_ready': {
        if (event.action === 'upload' && event.data?.uploaded_node_ids?.length !== 1) {
          return this.transloco.translate('sse.actions.navigate-to-nodes');
        } else {
          return this.transloco.translate('sse.actions.navigate-to-node');
        }
      }
      case 'new_project': {
        const isInviteAction = event.action === 'invite';
        return isInviteAction
          ? this.transloco.translate('sse.actions.show-assets-overview')
          : this.transloco.translate('sse.actions.open-project-page');
      }
      case 'asset_status_changed': {
        return event.data.status !== 'closed' ? this.transloco.translate('sse.actions.open-asset') : undefined;
      }
      case 'new_asset': {
        return this.transloco.translate('sse.actions.open-asset');
      }
      case 'media_files_published': {
        return this.transloco.translate('sse.actions.open-photos-overview');
      }
      case 'finding_published': {
        return this.transloco.translate('sse.actions.navigate-to-the-published-finding');
      }
      case 'undefined': {
        const isNewVersionAvailable = ['approve_version', 'switch_version'].includes(event.action);
        if (isNewVersionAvailable) {
          return this.transloco.translate('sse.actions.navigate-to-version');
        }
      }
    }
    if (event?.['module'] === 'ticket' && event?.['action']) {
      switch (event?.['action'] as TicketActions) {
        case 'answer':
        case 'reject':
        case 'close':
          return this.transloco.translate('sse.actions.navigate-to-ticket');
        case 'create':
          return this.transloco.translate('sse.actions.navigate-to-new-ticket');
      }
    }
  }

  private getNotificationLink(event: SSE.EventData): string | null {
    switch (event.type) {
      case 'reload_subtree':
      case 'file_ready': {
        let nodeId = event.data.node_id;
        if (event.action === 'upload' && event.data.uploaded_node_ids?.length === 1) {
          nodeId = event.data.uploaded_node_ids[0];
        }
        const url = this.customerRouting.getDataRoomLink(event.asset_id);
        return `${url}/${nodeId}`;
      }

      case 'qa_todo_list_updated': {
        return this.customerRouting.getQADetailsLink(event.asset_id, event.data.qa_message_id);
      }

      case 'new_project': {
        const isInviteAction = event.action === 'invite';
        return isInviteAction
          ? this.customerRouting.getAssetsLink()
          : this.adminRouting.getProjectDetailsLink(event.data.id);
      }
      case 'asset_status_changed':
      case 'new_asset':
        return this.customerRouting.getAssetDetailsLink(event.data.id);
      case 'media_files_published': {
        const assetId = event.data.asset_id;
        return this.customerRouting.getMediaTypeDocumentLink(assetId, 'photo', event.data.id || '');
      }
      case 'report_published': {
        return this.customerRouting.getAssetReportTemplateViewerLink(event.data.asset_id, event.data.report_id);
      }
      case 'finding_published': {
        return this.customerRouting.getFindingDetailsLink(event.data.asset_id, event.data.id, event.data.class);
      }
      case 'undefined': {
        const isNewVersionAvailable = ['approve_version', 'switch_version'].includes(event.action);
        if (isNewVersionAvailable) {
          event = event as VersionUpdated;
          return this.customerRouting.getDataRoomVersioningNodeLink(event.asset_id, event.data.versioning_id);
        }
      }
    }
    return this.getNewNotificationLink(event);
  }

  private getNewNotificationLink(event: EventData): string | null {
    if (event?.['module'] === 'ticket') {
      const eventData = event as TicketListUpdated;
      return this.customerRouting.getTicketDetailsLink(eventData.asset_id, eventData.data.qa_message_id);
    }
    return null;
  }

  private getUserNameTranslationParams(userPayloadData: SSE.EventData.UserPayloadData) {
    const name = [userPayloadData.user_firstname, userPayloadData.user_lastname].filter((name) => !!name).join(' ');
    return {
      name,
      email: name ? `(${userPayloadData.user_email})` : userPayloadData.user_email,
    };
  }
}
