<ng-container [ngSwitch]="fieldName" *ngIf="!hasTooltip; else templateWithTooltip">
  <ng-container *ngSwitchCase="'name'">{{ 'name' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'status'">{{ 'status' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'user'">{{ 'user' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'created_at'">{{ 'created-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'updated_at'">{{ 'updated-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'deleted_at'">{{ 'deleted-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'published_at'">{{ 'published-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'type'">{{ 'type' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="'risk_max'">{{ 'risk' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'risk'">{{ 'risk' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'condition_max'">{{ 'condition' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'condition'">{{ 'condition' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'quality_max'">{{ 'quality' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'quality'">{{ 'quality' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'document_quality_max'">
    <div [xHyphenate]="true">{{ 'document-quality' | transloco }}</div>
  </ng-container>
  <ng-container *ngSwitchCase="'document_quality'">{{ 'document-quality' | transloco }}</ng-container>

  <ng-container *ngSwitchCase="'immediate_costs'">{{ 'sum-current-costs' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'sum_immediate_costs'">{{ 'sum-current-costs' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'short_term_costs'">{{ 'sum-of-costs-next-year' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'sum_short_term_costs'">{{ 'sum-of-costs-next-year' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'mid_term_costs'">{{ 'sum-of-costs-for-the-next-2-5-years' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'sum_mid_term_costs'">
    {{ 'sum-of-costs-for-the-next-2-5-years' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="'long_term_costs'">
    {{ 'sum-of-costs-for-the-next-6-10-years' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="'sum_long_term_costs'">
    {{ 'sum-of-costs-for-the-next-6-10-years' | transloco }}
  </ng-container>

  <ng-container *ngSwitchCase="'messages'">{{ 'messages' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>

<ng-template #templateWithTooltip>
  <ng-container [ngSwitch]="fieldName">
    <span *ngSwitchCase="'name'" title="{{ 'name' | transloco }}">{{ 'name' | transloco }}</span>
    <span *ngSwitchCase="'status'" title="{{ 'status' | transloco }}">{{ 'status' | transloco }}</span>
    <span *ngSwitchCase="'user'" title="{{ 'user' | transloco }}">{{ 'user' | transloco }}</span>
    <span *ngSwitchCase="'created_at'" title="{{ 'created-at' | transloco }}">{{ 'created-at' | transloco }}</span>
    <span *ngSwitchCase="'updated_at'" title="{{ 'updated-at' | transloco }}">{{ 'updated-at' | transloco }}</span>
    <span *ngSwitchCase="'deleted_at'" title="{{ 'deleted-at' | transloco }}">{{ 'deleted-at' | transloco }}</span>
    <span *ngSwitchCase="'published_at'" title="{{ 'published-at' | transloco }}">
      {{ 'published-at' | transloco }}
    </span>
    <span *ngSwitchCase="'type'" title="{{ 'type' | transloco }}">{{ 'type' | transloco }}</span>

    <span *ngSwitchCase="'risk_max'" title="{{ 'risk' | transloco }}">{{ 'risk' | transloco }}</span>
    <span *ngSwitchCase="'risk'" title="{{ 'risk' | transloco }}">{{ 'risk' | transloco }}</span>
    <span *ngSwitchCase="'condition_max'" title="{{ 'condition' | transloco }}">{{ 'condition' | transloco }}</span>
    <span *ngSwitchCase="'condition'" title="{{ 'condition' | transloco }}">{{ 'condition' | transloco }}</span>
    <span *ngSwitchCase="'quality_max'" title="{{ 'quality' | transloco }}">{{ 'quality' | transloco }}</span>
    <span *ngSwitchCase="'quality'" title="{{ 'quality' | transloco }}">{{ 'quality' | transloco }}</span>
    <span *ngSwitchCase="'document_quality_max'" title="{{ 'document-quality' | transloco }}">
      {{ 'document-quality' | transloco }}
    </span>
    <div *ngSwitchCase="'document_quality'" [xHyphenate]="true" title="{{ 'document-quality' | transloco }}">
      {{ 'document-quality' | transloco }}
    </div>

    <span *ngSwitchCase="'immediate_costs'" title="{{ 'sum-current-costs' | transloco }}">
      {{ 'sum-current-costs' | transloco }}
    </span>
    <span *ngSwitchCase="'sum_immediate_costs'" title="{{ 'sum-current-costs' | transloco }}">
      {{ 'sum-current-costs' | transloco }}
    </span>
    <span *ngSwitchCase="'short_term_costs'" title="{{ 'sum-of-costs-next-year' | transloco }}">
      {{ 'sum-of-costs-next-year' | transloco }}
    </span>
    <span *ngSwitchCase="'sum_short_term_costs'" title="{{ 'sum-of-costs-next-year' | transloco }}">
      {{ 'sum-of-costs-next-year' | transloco }}
    </span>
    <span *ngSwitchCase="'mid_term_costs'" title="{{ 'sum-of-costs-for-the-next-2-5-years' | transloco }}">
      {{ 'sum-of-costs-for-the-next-2-5-years' | transloco }}
    </span>
    <span *ngSwitchCase="'sum_mid_term_costs'" title="{{ 'sum-of-costs-for-the-next-2-5-years' | transloco }}">
      {{ 'sum-of-costs-for-the-next-2-5-years' | transloco }}
    </span>
    <span *ngSwitchCase="'long_term_costs'" title="{{ 'sum-of-costs-for-the-next-6-10-years' | transloco }}">
      {{ 'sum-of-costs-for-the-next-6-10-years' | transloco }}
    </span>
    <span *ngSwitchCase="'sum_long_term_costs'" title="{{ 'sum-of-costs-for-the-next-6-10-years' | transloco }}">
      {{ 'sum-of-costs-for-the-next-6-10-years' | transloco }}
    </span>

    <span *ngSwitchCase="'messages'" title="{{ 'messages' | transloco }}">{{ 'messages' | transloco }}</span>

    <span *ngSwitchDefault [title]="fieldName">{{ 'field-name' | transloco }}</span>
  </ng-container>
</ng-template>
