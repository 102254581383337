import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import {
  CallPipeModule,
  ColorPipesModule,
  IconComponentModule,
  LocaleDatePipeModule,
  NewLinePipeModule,
  NotPipeModule,
  UserNamePipeModule,
} from '@common';
import { SelectComponent } from '@common/components/select/select.component';
import { TranslocoModule } from '@ngneat/transloco';
import {
  MessagesContainerComponent,
  MessageCirclesComponent,
  MessageButtonComponent,
  AddMessageDialogComponent,
} from '@portal/components/comment';
import { MessageComponent } from '@portal/components/comment/message/message.component';
import { SpinnerModule } from '@common';
import { ConfirmationDialogModule } from '@portal/components';
import { MessagesColorsPipe, MessagesTreePipe, PageMessagesPipe } from '../pipes';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { TextareaComponent } from '@portal/components/textarea/textarea.component';
import { AutofocusDirectiveModule } from '@common/directives';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { TabPillComponent } from '@common/components/tab-pill/tab-pill.component';
import { ButtonComponent } from '@common/components/button/button.component';

const components = [
  MessageComponent,
  MessagesContainerComponent,
  MessageCirclesComponent,
  MessageButtonComponent,
  AddMessageDialogComponent,
];

const pipes = [MessagesColorsPipe, MessagesTreePipe, PageMessagesPipe];

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    IconComponentModule,
    ColorPipesModule,
    CallPipeModule,
    NotPipeModule,
    UserNamePipeModule,
    LocaleDatePipeModule,
    MatDialogModule,
    FormsModule,
    TranslocoModule,
    NewLinePipeModule,
    SpinnerModule,
    ConfirmationDialogModule,
    DialogComponent,
    TextareaComponent,
    AutofocusDirectiveModule,
    MatSnackBarModule,
    SvgIconModule,
    BadgeComponent,
    TabPillComponent,
    SelectComponent,
    ButtonComponent,
  ],
  declarations: [...components, ...pipes],
  exports: [...components, MessagesColorsPipe],
})
export class MessageModule {}
