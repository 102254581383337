<span class="ag-header-cell-text">
  <ng-content></ng-content>
  <ng-template *ngIf="labelPortal" [cdkPortalOutlet]="labelPortal"></ng-template>
</span>

<ng-container [ngSwitch]="activeSort">
  <span
    *ngSwitchCase="sortDirection.Asc"
    class="ag-header-icon ag-sort-ascending-icon icon-font icon-font-small-up"
  ></span>

  <span
    *ngSwitchCase="sortDirection.Desc"
    class="ag-header-icon ag-sort-descending-icon icon-font icon-font-small-down"
  ></span>

  <span
    *ngSwitchDefault
    class="ag-header-icon ag-sort-descending-icon icon-font icon-font-small-down hidden-icon"
  ></span>
</ng-container>
