import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { DepthSurvey } from '../../../../core/models';

@Component({
  selector: 'x-depth-survey-label',
  templateUrl: './depth-survey-label.component.html',
  styleUrls: ['./depth-survey-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DepthSurveyLabelComponent {
  @Input()
  depth: DepthSurvey;

  readonly DepthSurvey = DepthSurvey;
}
