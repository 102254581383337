import { Document } from '@core/models';
import { Observable, throwError } from 'rxjs';
import { ImageSizeVersion } from '@portal/customer/services';

export type DownloadTokenAction = 'view' | 'download';

export class DocumentsService {
  getDownloadToken(
    doc: Document.Short,
    { version, action }: { version?: ImageSizeVersion; action?: DownloadTokenAction } = {}
  ): Observable<string> {
    return throwError('NOT IMPLEMENTED');
  }

  getListOfTags(args: { asset_id: string }): Observable<string[]>;
  getListOfTags(args: { project_id: string; asset_id: string }): Observable<string[]> {
    return throwError('NOT IMPLEMENTED');
  }
}
