<div class="flex flex-row relative" *ngIf="params.data as data">
  <x-asset-overview-image-tile
    [assetId]="data['asset_id']"
    [imageClasses]="'min-w-[85px]'"
    class="w-20 mr-4 self-center block relative"
    [imageDownloadToken]="data['asset_photo_download_token']"
    [newDownloadTokenGetter]="params['new_download_token_getter']"
  ></x-asset-overview-image-tile>
  <div class="flex flex-col space-between text-gray-500">
    <span *ngIf="value['name']; else assetName" class="text-gray-600">{{ value['name'] }}</span>
    <span class="flex text-gray-500 font-medium space-x-1.5">
      <span>{{ 'token' | transloco }}:</span>
      <x-badge badgeType="secondary">{{ data['asset_token'] || '-' }}</x-badge>
    </span>
    <ng-template #assetName>
      <span class="flex">
        <x-svg-icon
          *ngIf="!!value['asset_is_frozen']"
          svgImageName="padlock"
          svgClass="w-4 h-4 pr-2 text-primary-600"
        ></x-svg-icon>
        <span class="text-[16px] font-medium">{{ data['asset_name'] }}</span>
      </span>
    </ng-template>
  </div>
</div>
