import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { UserNamePipeModule } from '../../pipes/user-name';
import { UserInfoDialogComponent } from './user-info-dialog.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

@NgModule({
  imports: [CommonModule, MatDialogModule, RouterModule, TranslocoModule, UserNamePipeModule, SvgIconModule],
  declarations: [UserInfoDialogComponent],
  exports: [UserInfoDialogComponent],
})
export class UserInfoDialogModule {}
