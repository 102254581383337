<ng-container *ngIf="finding || createNewFindingMode; else noFindingDetailsTemplate">
  <form #form="ngForm" novalidate autocomplete="off">
    <table [ngClass]="{ disabled: disabled }">
      <ng-container *ngFor="let field of fields; trackBy: trackFn">
        <tr class="w-full" *ngIf="!(disabled && (field === 'add_photo' || field === 'add_document'))">
          <td class="w-[224px]" *ngIf="disabled === true">
            <x-finding-label [fieldName]="field"></x-finding-label>
          </td>
          <ng-container *ngIf="disabled === true">
            <ng-container *ngTemplateOutlet="withDirective; context: { $implicit: field }"></ng-container>
          </ng-container>
          <ng-container *ngIf="disabled === false && model && form">
            <ng-container
              *ngTemplateOutlet="withoutDirective; context: { $implicit: field, form: form }"
            ></ng-container>
          </ng-container>
        </tr>
      </ng-container>
    </table>
  </form>
</ng-container>

<ng-template #noFindingDetailsTemplate>
  {{ 'finding-details.no-finding' | transloco }}
</ng-template>

<ng-template #noValueTemplate>
  {{ getEmptyValue() }}
</ng-template>

<ng-template #withDirective let-field>
  <td class="w-(calc(100%-224px))" [colSpan]="1" *ngIf="disabled && getFieldType | call : field as fieldType">
    <ng-container [ngSwitch]="fieldType">
      <ng-container *ngSwitchCase="'label'" [xHyphenate]="true">
        {{ (getFieldValue | call : field : model) || getEmptyValue() }}
      </ng-container>

      <div *ngSwitchCase="'text'" [xHyphenate]="true">
        <div class="relative" *ngIf="field === 'finding'; else regularText">
          <div (click)="onFindingProcessedClick($event)" [innerHtml]="getProcessedFindingValue() | safeHtml"></div>
          <div class="menu-trigger" #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></div>

          <mat-menu #menu="matMenu" [hasBackdrop]="true">
            <div class="norm-viewer" *ngIf="norm$ | async as norm; else spinner" (click)="$event.stopPropagation()">
              <div class="title">
                <b>{{ norm?.name }}</b>
                <mat-icon (click)="menuTrigger.closeMenu(); $event.stopPropagation(); $event.preventDefault()">
                  close
                </mat-icon>
              </div>
              <p>{{ norm.description }}</p>
              <a [href]="norm?.url" target="_blank">Link</a>
            </div>
          </mat-menu>

          <ng-template #spinner>
            <div class="relative">
              <span class="icon-progress"></span>
            </div>
          </ng-template>
        </div>
        <ng-template #regularText>
          {{ getFieldValue | call : field : model }}
        </ng-template>
      </div>

      <ng-container *ngSwitchCase="'date'">
        {{ (model | callFunction : getDateFieldValue : field | localeDate : true) || getEmptyValue() }}
      </ng-container>

      <ng-container *ngSwitchCase="'number'">
        {{ (getFieldValue | call : field : model | localeCurrency : undefined : getEmptyValue()) || getEmptyValue() }}
      </ng-container>

      <ng-container *ngSwitchCase="'trade'" [xHyphenate]="true">
        {{ getTradeLabel(model.trade_id) | async }}
      </ng-container>

      <ng-container *ngSwitchCase="'status'">
        {{ 'finding-monitoring-status.' + $any(model).status | transloco }}
      </ng-container>

      <ng-container *ngSwitchCase="'risk'">
        <x-risk-pill [value]="model?.risk_evaluation"></x-risk-pill>
      </ng-container>

      <div *ngSwitchCase="'references'" class="references">
        <div class="flex flex-wrap" *ngIf="model?.references?.length > 0; else noValueTemplate">
          <div
            *ngFor="let ref of getReferences | call : model; trackBy: trackByReference"
            class="flex border h-fit border-gray-200 rounded-xl mt-2.5 mr-2.5 hover:bg-gray-50"
          >
            <a class="inline-flex">
              <ng-container *ngIf="getIsMediaReference | call : ref; else notPhotoTemplate">
                <div
                  class="relative"
                  *ngIf="ref.doc.id | documentDetails | async as documentDetails; else notPhotoTemplate"
                  (click)="onOpenPhoto(documentDetails)"
                >
                  <section
                    *ngIf="
                      documentDetails.download_token | documentPhoto : false : documentDetails | async as imageData;
                      else notPhotoTemplate
                    "
                    class="asset-cover-container"
                  >
                    <img
                      class="rounded-lg bg-cover bg-center bg-no-repeat min-w-[150px] h-[100px] image-preview aspect-[16/10] cursor-pointer"
                      [src]="imageData"
                      [matTooltip]="ref?.name || ''"
                      [alt]="ref.name"
                    />
                  </section>
                </div>
              </ng-container>

              <ng-template #notPhotoTemplate>
                <div class="flex items-center justify-center" (click)="onOpenDocument(ref, $event)">
                  <ng-container *ngTemplateOutlet="refDocumentNameTemplate; context: { $implicit: ref }"></ng-container>
                </div>
              </ng-template>
            </a>
          </div>
        </div>
      </div>

      <ng-container *ngSwitchCase="'tags'">
        {{ (getFieldArrayValue | call : field : model).join(', ') || getEmptyValue() }}
      </ng-container>

      <ng-container *ngSwitchCase="'finding_type'">
        {{ model.finding_type ? (model.finding_type | transloco) : getEmptyValue() }}
      </ng-container>

      <ng-container *ngSwitchCase="'number_array'">
        {{ (getFieldValue | call : field : model) || getEmptyValue() }}
      </ng-container>

      <ng-container *ngSwitchDefault [xHyphenate]="true">
        {{ (getFieldValue | call : field : model) || getEmptyValue() }}
      </ng-container>
    </ng-container>
  </td>
</ng-template>

<ng-template #withoutDirective let-field let-form>
  <td
    class="w-(calc(100%-224px)) editable-row"
    [colSpan]="2"
    *ngIf="!disabled && getFieldType | call : field as fieldType"
  >
    <ng-container [ngSwitch]="fieldType">
      <ng-container *ngSwitchCase="'label'">
        {{ (getFieldValue | call : field : model) || getEmptyValue() }}
      </ng-container>

      <div *ngSwitchCase="'text'">
        <x-textarea
          label="{{ 'finding-label.' + field | transloco }}"
          [name]="field"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [disabled]="getIsFieldDisabled | call : disabledFields : field"
          [value]="getFieldValue | call : field : model"
          (valueChange)="onInputValueChanged($event, field, model)"
        ></x-textarea>
      </div>

      <ng-container *ngSwitchCase="'date'">
        <x-date-input
          *ngIf="!(getIsDateFieldDisabled | call : field); else textTemplate"
          label="{{ 'finding-label.' + field | transloco }}"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [includeTime]="field === 'published_at'"
          [value]="model | callFunction : getDateFieldValue : field"
          (valueChange)="onDateValueChanged($event, field, model)"
        ></x-date-input>
        <ng-template #textTemplate>
          {{ (model | callFunction : getDateFieldValue : field | localeDate : true) || getEmptyValue() }}
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'number'">
        <x-input
          *ngIf="existRestriction | call : field; else notRestriction"
          type="number"
          [name]="field"
          [disabled]="getIsFieldDisabled | call : disabledFields : field"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [value]="getFieldValue | call : field : model"
          [min]="1"
          [max]="5"
          (valueChange)="onNumericInputValueChanged($event, field, model)"
          label="{{ 'finding-label.' + field | transloco }}"
        ></x-input>
        <ng-template #notRestriction>
          <x-input
            *ngIf="canEditField | call : field; else canNotEdit"
            type="number"
            [name]="field"
            [required]="getIsFieldRequired | call : requiredFields : field"
            [value]="getFieldValue | call : field : model"
            (change)="onNumericInputValueChanged($event, field, model)"
            label="{{ 'finding-label.' + field | transloco }}"
          ></x-input>
        </ng-template>
        <ng-template #canNotEdit>
          <x-input
            *ngIf="!createNewFindingMode"
            type="number"
            [name]="field"
            [disabled]="true"
            [required]="getIsFieldRequired | call : requiredFields : field"
            [value]="getFieldValue | call : field : model : true"
            label="{{ 'finding-label.' + field | transloco }}"
          ></x-input>
        </ng-template>
      </ng-container>

      <ng-container *ngSwitchCase="'trade'">
        <x-select
          name="trade_id"
          [disabled]="getIsFieldDisabled | call : disabledFields : field"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [(value)]="model.trade_id"
          (valueChange)="onSelectValueChanged($event, field, model)"
          [emptyOptionValue]="0"
          [options]="tradeSelectOptions$ | async"
          label="{{ 'finding-label.' + field | transloco }}"
        ></x-select>
      </ng-container>

      <ng-container *ngSwitchCase="'status'">
        <x-select
          name="status"
          [disabled]="getIsFieldDisabled | call : disabledFields : field"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [(value)]="$any(model).status"
          (valueChange)="onSelectValueChanged($event, field, model)"
          [options]="statusSelectOptions"
          label="{{ 'finding-label.' + field | transloco }}"
        ></x-select>
      </ng-container>

      <ng-container *ngSwitchCase="'risk'">
        <x-select
          name="risk_evaluation"
          [disabled]="getIsFieldDisabled | call : disabledFields : field"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [(value)]="model.risk_evaluation"
          (valueChange)="onSelectValueChanged($event, field, model)"
          [options]="riskLevels"
          label="{{ 'finding-label.' + field | transloco }}"
        ></x-select>
      </ng-container>

      <div *ngSwitchCase="'references'" class="references">
        <label class="block text-sm font-medium text-gray-700">
          {{ 'finding-label.' + field | transloco }}
        </label>
        <div class="flex flex-wrap" *ngIf="model?.references?.length > 0; else noValueTemplate">
          <div
            *ngFor="let ref of getReferences | call : model; trackBy: trackByReference"
            class="flex h-fit border border-gray-200 rounded-xl mt-2.5 mr-2.5 hover:bg-gray-50"
          >
            <a class="inline-flex" [href]="ref.href" (click)="onOpenDocument(ref, $event)">
              <ng-container *ngIf="getIsMediaReference | call : ref; else notPhotoTemplate">
                <div
                  class="relative"
                  *ngIf="ref.doc.id | documentDetails | async as documentDetails; else notPhotoTemplate"
                >
                  <section
                    *ngIf="
                      documentDetails.download_token | documentPhoto : false : documentDetails | async as imageData;
                      else notPhotoTemplate
                    "
                    class="asset-cover-container"
                  >
                    <img
                      class="rounded-lg bg-cover bg-center bg-no-repeat min-w-[150px] h-[100px] image-preview aspect-[16/10] cursor-pointer"
                      [src]="imageData"
                      [alt]="ref.name"
                    />
                  </section>
                  <x-svg-icon
                    *ngIf="!(getIsFieldDisabled | call : disabledFields : field)"
                    class="flex absolute top-2 right-2 w-4 h-4 text-white hover:text-danger-600"
                    svgImageName="trash"
                    (click)="$event.preventDefault(); onDeleteLink($event, ref)"
                  ></x-svg-icon>
                </div>
              </ng-container>

              <ng-template #notPhotoTemplate>
                <div class="flex items-center justify-center">
                  <ng-container *ngTemplateOutlet="refDocumentNameTemplate; context: { $implicit: ref }"></ng-container>
                  <x-svg-icon
                    *ngIf="!(getIsFieldDisabled | call : disabledFields : field)"
                    class="text-gray-800 hover:text-danger-600 mr-2"
                    svgImageName="trash"
                    (click)="$event.preventDefault(); onDeleteLink($event, ref)"
                  ></x-svg-icon>
                </div>
              </ng-template>
            </a>
          </div>
        </div>
      </div>

      <ng-container *ngSwitchCase="'tags'">
        <label class="block text-sm font-medium text-gray-700">
          {{ 'finding-label.' + field | transloco }}
          <span class="inline-block text-danger-500" *ngIf="getIsFieldRequired | call : requiredFields : field">*</span>

          <x-tags-input
            [tags]="getFieldArrayValue | call : field : model"
            [allTags]="getFieldAvailableOptions | call : field : assetId : findingsService | async"
            [disabled]="getIsFieldDisabled | call : disabledFields : field"
            (tagsChanged)="onTagsChanged($event, field, model)"
          ></x-tags-input>
        </label>
      </ng-container>

      <ng-container *ngSwitchCase="'finding_type'">
        <x-select
          name="finding_type"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [(value)]="model.finding_type"
          (valueChange)="onSelectValueChanged($event, field, model)"
          [options]="findingTypes"
          label="{{ 'finding-label.' + field | transloco }}"
        ></x-select>
      </ng-container>

      <ng-container *ngSwitchCase="'number_array'">
        <x-select
          [name]="field"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [value]="getFieldValue | call : field : model"
          (valueChange)="onFailureValueChanged($event, field, model)"
          [options]="types"
          label="{{ 'finding-label.' + field | transloco }}"
        ></x-select>
      </ng-container>

      <ng-container *ngSwitchCase="'add_document'">
        <div>
          <x-button color="white" [block]="true" (handleClick)="onClickAddDocument()">
            <x-svg-icon svgImageName="plus" svgClass="w-4 h-4 text-black"></x-svg-icon>
            <span class="pl-2 capitalize">{{ 'documents' | transloco }}</span>
          </x-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'add_photo'">
        <div>
          <x-button color="white" [block]="true" (handleClick)="onClickAddPhoto()">
            <x-svg-icon svgImageName="plus" svgClass="w-4 h-4 text-black"></x-svg-icon>
            <span class="pl-2 capitalize">{{ 'add-photo' | transloco }}</span>
          </x-button>
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <x-input
          type="text"
          [name]="field"
          [disabled]="getIsFieldDisabled | call : disabledFields : field"
          [required]="getIsFieldRequired | call : requiredFields : field"
          [value]="getFieldValue | call : field : model"
          (valueChange)="onInputValueChanged($event, field, model)"
          label="{{ 'finding-label.' + field | transloco }}"
        ></x-input>
      </ng-container>
    </ng-container>

    <x-validation-error-label [error]="getErrors | call : field : form"></x-validation-error-label>
  </td>
</ng-template>

<ng-template #refDocumentNameTemplate let-ref>
  <span class="flex flex-row p-2">
    <x-svg-icon svgClass="h-6 w-6 text-gray-700" [svgImageName]="'folder'"></x-svg-icon>
    <span class="px-1.5">{{ ref.name }}</span>
    <span class="border-l border-gray-200 px-2 whitespace-nowrap" *ngIf="ref.page">
      <span class="whitespace-nowrap">{{ 'page-no' | transloco }}</span>
      <span class="whitespace-nowrap">&nbsp;{{ ref.page }}</span>
    </span>
  </span>
</ng-template>
