import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { AsyncPipe, DatePipe, NgClass, NgForOf, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';

import { DEBOUNCE_TIME } from '@app/utils/rxjs.utils';
import { SpinnerModule } from '@common';
import { ButtonComponent } from '@common/components/button/button.component';
import { CardComponent } from '@common/components/card/card.component';
import { CountryLabelModule } from '@common/components/labels/country-label/country-label.module';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { Asset, AssetOverview } from '@core/models';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DownloadTokenCallbackType, GridHelperService } from '@portal/components';
import { AssetOverviewImageTileComponent } from '@portal/components/assets-overview/asset-overview-image-tile/asset-overview-image-tile.component';
import { BadgeComponent, LabelType } from '@portal/components/badge/badge.component';
import _isEqual from 'lodash/isEqual';
import { debounceTime } from 'rxjs/operators';
import { ShopAdvertisementTileComponent } from '@portal/components/assets-overview/asset-overview-grid-view/shop-advertisement-tile/shop-advertisement-tile.component';

const VIEWED_ASSETS_BREAKPOINT = 30;

export const AD_TILE_CLOSED_STORAGE_KEY = 'ad-tile-closed';

@UntilDestroy()
@Component({
  selector: 'x-asset-overview-grid-view',
  templateUrl: './asset-overview-grid-view.component.html',
  styleUrls: ['./asset-overview-grid-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CardComponent,
    NgForOf,
    AsyncPipe,
    AssetOverviewImageTileComponent,
    CountryLabelModule,
    BadgeComponent,
    TranslocoModule,
    NgIf,
    DatePipe,
    ButtonComponent,
    SvgIconModule,
    NgClass,
    ScrollingModule,
    SpinnerModule,
    NgTemplateOutlet,
    ShopAdvertisementTileComponent,
  ],
})
export class AssetOverviewGridViewComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() assets: AssetOverview[] = [];
  @Input() isEditButtonShowed = false;
  @Input() set isLoading(value: boolean) {
    this.isLoadingAssets = value;
  }
  @Input() isClickable = true;
  @Input() newDownloadTokenGetter: DownloadTokenCallbackType;
  @Input() isAllFetched = false;

  @Output() assetClick = new EventEmitter<AssetOverview>();
  @Output() editAssetClick = new EventEmitter<AssetOverview>();
  @Output() lastAssetShown = new EventEmitter<void>();

  adFileVisible = this.getAdFileVisibleState();

  @ViewChild('scrollViewport', { static: false }) set content(elementRef: CdkVirtualScrollViewport) {
    if (elementRef && !this.scrollViewportRef) {
      this.scrollViewportRef = elementRef;
      this.scrollViewportRef.scrolledIndexChange
        .pipe(debounceTime(DEBOUNCE_TIME), untilDestroyed(this))
        .subscribe(() => {
          this.emitIfLastAssetShown();
        });
    }
  }

  private readonly gridHelperService = inject(GridHelperService);
  private readonly cdr = inject(ChangeDetectorRef);

  readonly VSItemSize = 20;
  isHoverOnEditAssetButton = false;
  isLoadingAssets = false;

  private resizeObserver: ResizeObserver;
  private lastAssetsLengthAfterNextPageFetchRequested!: number;
  private scrollViewportRef: CdkVirtualScrollViewport;

  ngAfterViewInit() {
    this.resizeObserver = new ResizeObserver(() => {
      this.cdr.detectChanges();
    });
    this.resizeObserver.observe(document.body);
  }

  ngOnChanges({ assets, isAllFetched }: SimpleChanges) {
    if (assets?.previousValue && assets?.currentValue && !_isEqual(assets.previousValue, assets.currentValue)) {
      this.cdr.markForCheck();
    }

    if (isAllFetched?.currentValue) {
      this.isLoadingAssets = false;
      this.cdr.markForCheck();
    }
  }

  getBadgeType(assetStatus: Asset.Status): LabelType {
    return this.gridHelperService.getBadgeType(assetStatus);
  }

  setHoverOnButton(value: boolean) {
    this.isHoverOnEditAssetButton = value;
  }

  trackByFn(index: number) {
    return index;
  }

  isNotInSameLine(separator: HTMLDivElement, statusBadges: HTMLDivElement): boolean {
    const separatorRect = separator.getBoundingClientRect();
    const statusBadgesRect = statusBadges.getBoundingClientRect();
    const maxPrecisionDeviation = 10; // px - for paddings or margins
    return Math.abs(separatorRect.top - statusBadgesRect.top) >= maxPrecisionDeviation;
  }

  ngOnDestroy() {
    this.resizeObserver.disconnect();
  }

  onAdTileClosed() {
    sessionStorage.setItem(AD_TILE_CLOSED_STORAGE_KEY, Date.now().toString());
    this.adFileVisible = false;
    this.cdr.markForCheck();
  }

  private getAdFileVisibleState() {
    return !sessionStorage.getItem(AD_TILE_CLOSED_STORAGE_KEY);
  }

  private emitIfLastAssetShown() {
    const percentOfViewedContent =
      (this.scrollViewportRef.measureScrollOffset('bottom') / this.getMaxScrollOffset()) * 100;
    if (
      percentOfViewedContent < VIEWED_ASSETS_BREAKPOINT &&
      this.lastAssetsLengthAfterNextPageFetchRequested !== this.assets.length
    ) {
      if (this.isAllFetched) {
        this.isLoadingAssets = false;
      } else {
        this.isLoadingAssets = true;
        this.lastAssetShown.emit();
        this.lastAssetsLengthAfterNextPageFetchRequested = this.assets.length;
      }
    } else {
      this.isLoadingAssets = false;
    }
  }

  private getMaxScrollOffset(): number {
    const totalContentSize = this.scrollViewportRef.getElementRef().nativeElement.scrollHeight;
    const viewportSize = this.scrollViewportRef.getViewportSize();
    return totalContentSize - viewportSize;
  }
}
