import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  inject,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IdleService } from '@core/services/idle.service';
import { catchError, debounceTime, filter, take, takeUntil, tap } from 'rxjs/operators';
import { ActivityStatisticsService, AuthService, UserService } from '@core/services';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { Activity, Document, Finding, Message } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { EMPTY, Observable, Subject } from 'rxjs';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoService } from '@ngneat/transloco';
import { isNotNil } from '@app/utils';
import { DataRoomUploadService } from '@portal/customer/components/asset-details/data-room/services';
import { PhotosService } from '@portal/customer/components/asset-details/asset-photo-viewer/photos.service';
import { LONG_DEBOUNCE_TIME } from '@app/utils/rxjs.utils';
import {
  MediaViewerPopupComponent,
  MediaViewerPopupData,
} from '@portal/customer/components/asset-details/asset-photo-viewer/media-viewer-popup/media-viewer-popup.component';
import { MatDialog } from '@angular/material/dialog';
import {
  ImageDialogData,
  ImageViewerDialogComponent,
} from '@portal/components/image-viewer-dialog/image-viewer-dialog.component';
import { isVideoExtensionsRegExp } from '@portal/components/qa-overview/qa-overview.constants';
import { StoredTemplatesService } from '@portal/customer/services/stored-templates.service';
import { TicketsService } from '@portal/customer/components/asset-details/asset-tickets-container/tickets.service';

export interface ModalDetailsData {
  dialogType: 'ticket' | 'qa' | 'finding';
  isLoading?: boolean;
  data?: Message | Finding | Document;
  data$?: Observable<Message | Finding>;
}

/* eslint-disable @angular-eslint/component-selector */
@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  providers: [IdleService],
})
export class AppComponent implements AfterViewInit {
  @ViewChild('templatePortalContent') templatePortalContent: TemplateRef<HTMLElement>;
  @ViewChild('qaDetailsDialogTemplate') qaDetailsDialogTemplate: TemplateRef<HTMLElement>;
  @ViewChild('ticketDetailsDialogTemplate') ticketDetailsDialogTemplate: TemplateRef<HTMLElement>;

  readonly authService = inject(AuthService);
  readonly overlay = inject(Overlay);
  readonly vcr = inject(ViewContainerRef);
  readonly cdr = inject(ChangeDetectorRef);
  readonly router = inject(Router);
  readonly toast = inject(ToastService);
  readonly transloco = inject(TranslocoService);
  readonly userService = inject(UserService);
  readonly dataroomUploadService = inject(DataRoomUploadService);
  readonly photosService = inject(PhotosService);
  readonly dialog = inject(MatDialog);
  readonly activityService = inject(ActivityStatisticsService);
  readonly storedTemplates = inject(StoredTemplatesService);
  readonly ticketsService = inject(TicketsService);

  overlayRef?: OverlayRef;
  modalDetailsData?: ModalDetailsData;
  modalDetailsLoading = false;
  overlayClosed$ = new Subject();

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        tap(() => {
          if (window.scrollY >= 105) {
            window.scrollTo({ top: 105 });
          }
        }),
        untilDestroyed(this)
      )
      .subscribe();

    if (this.authService.isUserAccessTokenValid()) {
      this.userService.fetchUser(this.authService.getCurrentAuthTokenDecodedData()).pipe(take(1)).subscribe();
    }
  }

  closeOverlayModalDetails() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    this.modalDetailsData = undefined;
    this.overlayClosed$.next();
    this.cdr.detectChanges();
  }

  ngAfterViewInit() {
    if (!window['openTemplateOverlay']) {
      this.initializeOpenTemplateOverlayMethod();
    }
    this.userService.user$.pipe(debounceTime(LONG_DEBOUNCE_TIME), filter(isNotNil)).subscribe(() => {
      this.dataroomUploadService.resumeDataroomUploadsIfExists();
      this.photosService.resumePhotosUploadsIfExists();
    });
    this.storedTemplates.setQaFieldsTemplate(this.qaDetailsDialogTemplate);
    this.storedTemplates.setTicketFieldsTemplate(this.ticketDetailsDialogTemplate);
  }

  getModalTitle(details: ModalDetailsData, transloco: TranslocoService) {
    switch (details.dialogType) {
      case 'finding':
        details.data = details.data as Finding;
        return `${transloco.translate('finding-no')} ${details.data.current_no}`;
      case 'qa':
        details.data = details.data as Message;
        return !['notes', 'note'].includes(details.data.category)
          ? transloco.translate('message-details.title', { value: details.data.current_no })
          : transloco.translate('note');
      case 'ticket':
        details.data = details.data as Message;
        return transloco.translate('tickets-system.ticket-details-title', {
          ticketNumber: details.data.current_no,
        });
    }
  }

  /**
   * Used from an app and iframe instances to attach overlay on top document (instead of using iframe's one)
   */
  private initializeOpenTemplateOverlayMethod() {
    window['openTemplateOverlay'] = window.frameElement
      ? window.parent.openTemplateOverlay
      : (modalDetailsData: ModalDetailsData) => {
          if (this.modalDetailsData) {
            this.closeOverlayModalDetails();
          }
          this.modalDetailsData = modalDetailsData;
          if (modalDetailsData.data$) {
            this.modalDetailsLoading = true;
            this.cdr.detectChanges();
            modalDetailsData.data$
              .pipe(
                take(1),
                catchError((error) => {
                  console.error(error);
                  this.toast.showToast(this.transloco.translate('unknown-error-please-try-again'), ToastType.ERROR);
                  this.closeOverlayModalDetails();
                  return EMPTY;
                }),
                takeUntil(this.overlayClosed$)
              )
              .subscribe({
                next: (data) => {
                  this.modalDetailsData = {
                    ...this.modalDetailsData,
                    data,
                  };
                },
                complete: () => {
                  this.modalDetailsLoading = false;
                  this.openOverlay();
                  this.cdr.detectChanges();
                },
              });
          } else {
            return this.openOverlay();
          }
        };

    window['openMediaDocument'] = window.frameElement
      ? window.parent.openMediaDocument
      : (document: Document, activity?: Partial<Activity> & Pick<Activity, 'scope'>) => {
          if (!document.types.includes('video') && !isVideoExtensionsRegExp.test(document.name)) {
            this.dialog.open(ImageViewerDialogComponent, {
              data: <ImageDialogData>{
                document,
              },
            });
          } else {
            this.dialog.open(MediaViewerPopupComponent, {
              data: <MediaViewerPopupData>{
                document,
                assetId: document.asset_id,
                hideDetails: true,
              },
            });
          }
          if (activity) {
            this.activityService
              .trackActivity(document.asset_id, {
                ...activity,
                action: 'view',
              })
              .pipe(take(1))
              .subscribe();
          }
        };

    window['openQaSidepanel'] = window.frameElement
      ? window.parent.openQaSidepanel
      : (qid: string) => {
          this.modalDetailsLoading = true;
          this.cdr.markForCheck();
          this.ticketsService
            .openQaDetailsDialog(qid)
            .pipe(
              take(1),
              catchError(() => {
                this.modalDetailsLoading = false;
                this.toast.showToast(this.transloco.translate('unknown-error-please-try-again'), ToastType.ERROR);
                this.cdr.markForCheck();
                return EMPTY;
              })
            )
            .subscribe(() => {
              this.modalDetailsLoading = false;
              this.cdr.markForCheck();
            });
        };

    window['openTicketSidepanel'] = window.frameElement
      ? window.parent.openTicketSidepanel
      : (id: string) => {
          this.modalDetailsLoading = true;
          this.cdr.markForCheck();
          this.ticketsService
            .openTicketDetailsDialog(id)
            .pipe(
              take(1),
              catchError(() => {
                this.modalDetailsLoading = false;
                this.toast.showToast(this.transloco.translate('unknown-error-please-try-again'), ToastType.ERROR);
                this.cdr.markForCheck();
                return EMPTY;
              })
            )
            .subscribe(() => {
              this.modalDetailsLoading = false;
              this.cdr.markForCheck();
            });
        };
  }

  private openOverlay() {
    this.overlayRef = this.overlay.create({
      hasBackdrop: true,
      positionStrategy: this.overlay.position().global().centerHorizontally().centerVertically(),
    });
    const portal = new TemplatePortal(this.templatePortalContent, this.vcr);
    this.overlayRef.attach(portal);
    return this.overlayRef;
  }
}
