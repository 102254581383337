import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Disclaimer, Transform } from '@core/models';
import { PORTAL_API_URL } from '@core/services';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DisclaimerService {
  readonly apiUrl = `${PORTAL_API_URL}/admin/disclaimers`;

  constructor(private http: HttpClient) {}

  fetch(): Observable<Disclaimer[]> {
    return this.http
      .get<Disclaimer[]>(this.apiUrl)
      .pipe(map((disclaimers) => _.map(disclaimers, (disclaimer) => Transform.disclaimer(disclaimer))));
  }

  get({ disclaimer_id }: { disclaimer_id: string }): Observable<Disclaimer> {
    const url = `${this.apiUrl}/${disclaimer_id}`;

    return this.http.get<Disclaimer>(url).pipe(map((disclaimer) => Transform.disclaimer(disclaimer)));
  }

  create(disclaimer: Disclaimer): Observable<Disclaimer> {
    return this.http.post<Disclaimer>(this.apiUrl, disclaimer).pipe(map((response) => Transform.disclaimer(response)));
  }

  update(disclaimer: Disclaimer): Observable<Disclaimer> {
    return this.http
      .put<Disclaimer>(`${this.apiUrl}/${disclaimer.id}`, disclaimer)
      .pipe(map((response) => Transform.disclaimer(response)));
  }

  delete(disclaimer: Disclaimer): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${disclaimer.id}`);
  }
}
