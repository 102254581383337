import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  ViewChild,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import {
  MatLegacyAutocompleteModule as MatAutocompleteModule,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import {
  MatLegacyChipInputEvent as MatChipInputEvent,
  MatLegacyChipsModule as MatChipsModule,
} from '@angular/material/legacy-chips';
import { SimpleChanges } from 'src/custom-typings/angular';
import { CommonModule } from '@angular/common';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { PreserveFocusModule } from '@common/directives';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'x-tags-input',
  templateUrl: './tags-input.component.html',
  styleUrls: ['./tags-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatChipsModule,
    MatAutocompleteModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    BadgeComponent,
    SvgIconModule,
    PreserveFocusModule,
    TranslocoModule,
  ],
})
export class TagsInputComponent implements OnChanges {
  @ViewChild('tagsInput') tagsInput?: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autoTrigger: MatAutocompleteTrigger;

  @Input() set tags(value: string[] | undefined) {
    this.tagsValue = [...(value || [])];
    this.filteredTags = this.getFilteredTags();
  }
  @Input() allTags?: string[];
  @Input() disabled?: boolean;
  @Input() placeholder = '';
  @Input() errorMessage = '';
  @Output() tagsChanged = new EventEmitter<string[]>();

  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE, TAB];

  filteredTags!: string[];
  tagsCtrl = new UntypedFormControl();
  tagsValue: string[] = [];
  isFocused: boolean;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges({ disabled, allTags }: SimpleChanges<TagsInputComponent>): void {
    if (allTags) {
      this.filteredTags = this.getFilteredTags();
    }

    if (disabled?.currentValue && disabled.currentValue !== disabled.previousValue) {
      this.tagsCtrl.disable();
    } else {
      this.tagsCtrl.enable();
    }
    this.cdr.markForCheck();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.tagsValue.push(value);
    }

    const input = this.tagsInput?.nativeElement;
    if (input) {
      input.value = '';
    }

    this.tagsCtrl.setValue(null);
    this.tagsChanged.emit(this.tagsValue);
  }

  onRemove(tag: string): void {
    const index = this.tagsValue.indexOf(tag);

    if (index >= 0) {
      this.tagsValue.splice(index, 1);
    }
    this.tagsChanged.emit(this.tagsValue);
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.tagsValue.push(event.option.viewValue);
    if (this.tagsInput) {
      this.tagsInput.nativeElement.value = '';
    }
    this.tagsCtrl.setValue(null);
    this.tagsChanged.emit(this.tagsValue);
    this.openPanel();
  }

  private openPanel(): void {
    window.requestAnimationFrame(() => {
      this.autoTrigger.openPanel();
    });
  }

  private getFilteredTags() {
    return (this.allTags || []).filter((tag) => !this.tagsValue.includes(tag));
  }
}
