import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
  OnChanges,
} from '@angular/core';
import { DestroyComponent } from '@common';
import * as _ from 'lodash';
import { fromEvent } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SimpleChanges } from 'src/custom-typings/angular';

const EMPTY_QUILL = '<p><br></p>';
@Component({
  selector: 'x-rich-editor',
  templateUrl: './rich-editor.component.html',
  styleUrls: ['./rich-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichEditorComponent extends DestroyComponent implements AfterViewInit, OnChanges, OnDestroy {
  @Input() set value(text: string | undefined) {
    this.textValue = text;
    this.setQuillContent();
  }
  @Input() disabled = false;
  @Input() placeholder?: string;
  @Input() emitOnInput = false;

  @Output() valueChanged = new EventEmitter<string>();

  @ViewChild('quillContainer') quillContainer?: ElementRef<HTMLElement>;

  private textValue: string | undefined;

  quill?: Quill;

  private get html(): string | undefined {
    return this.quill?.root?.innerHTML;
  }

  private textChangeHandler = _.debounce(
    () => {
      this.updateData(this.html);
    },
    500,
    { trailing: true, maxWait: 60e3 }
  );

  constructor() {
    super();
  }

  ngOnChanges({ disabled }: SimpleChanges<RichEditorComponent>) {
    if (disabled?.currentValue !== undefined) {
      if (disabled.currentValue) {
        this.quill?.disable();
      } else {
        this.quill?.enable();
      }
    }
  }

  ngAfterViewInit(): void {
    if (!this.quillContainer) {
      console.error('Quill container does not exist');
      return;
    }

    this.quill = new Quill(this.quillContainer?.nativeElement, {
      theme: 'snow',
      scrollingContainer: '.editor-container',
      readOnly: true,
      placeholder: this.placeholder,
      modules: {
        toolbar: [
          // [{ 'font': [] }],
          // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

          ['bold', 'italic', 'underline', 'strike'], // toggled buttons
          // ['blockquote', 'code-block'],

          // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          // [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
          // [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
          // [{ 'direction': 'rtl' }],                         // text direction

          // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ align: [] }],

          ['clean'],
        ],
      },
    });

    this.setQuillContent();
    // this.quill.on('selection-change', this.textChangeHandler);
    this.quill.once('text-change', () => this.addListeners());

    if (!this.disabled) {
      this.quill.enable();
    }
  }

  ngOnDestroy(): void {
    this.quill?.off('selection-change', this.textChangeHandler);
  }

  private setQuillContent(): void {
    if (this.quill && !_.isNil(this.textValue)) {
      if (this.textValue === this.html) {
        return;
      }

      const clipboardModule = this.quill.getModule('clipboard') as ClipboardModule;
      clipboardModule?.dangerouslyPasteHTML(this.textValue, 'api');
    }
  }

  private updateData(html?: string): void {
    const newValue = html === EMPTY_QUILL ? '' : html;
    if (newValue !== this.textValue) {
      this.textValue = newValue;
      this.valueChanged.emit(this.textValue);
    }
  }

  private emitEvents(eventName) {
    const editor = this.quillContainer?.nativeElement.querySelector('.ql-editor');
    if (!editor) {
      console.warn('Quill editor does not exist. Change detection may not work properly.');
      return;
    }
    fromEvent(editor, eventName)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => this.updateData(this.html),
      });
  }

  private addListeners(): void {
    this.emitEvents('blur');

    if (this.emitOnInput) {
      this.emitEvents('input');
    }
  }
}
