import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HyphenateDirective } from '@common/directives/hyphenate/hyphenate.directive';
import { TranslocoModule } from '@ngneat/transloco';
import { ReportLabelComponent } from './report-label.component';

@NgModule({
  imports: [CommonModule, TranslocoModule, HyphenateDirective],
  declarations: [ReportLabelComponent],
  exports: [ReportLabelComponent],
})
export class ReportLabelModule {}
