import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoModule } from '@ngneat/transloco';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

export type LabelType =
  | 'primary_dark'
  | 'primary'
  | 'primary_light'
  | 'primary_extralight'
  | 'secondary_dark'
  | 'secondary'
  | 'secondary_light'
  | 'success_dark'
  | 'success'
  | 'warning_dark'
  | 'warning'
  | 'danger_dark'
  | 'danger'
  | 'danger_light'
  | 'disabled';

@Component({
  selector: 'x-badge',
  standalone: true,
  imports: [CommonModule, TranslocoModule, SvgIconModule],
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BadgeComponent {
  @Input() label = '';
  @Input() text: string | number | undefined;
  @Input() badgeType: LabelType = 'primary';
  @Input() classes = '';
  @Input() withRemoveButton = false;
  @Output() removeBtnClick = new EventEmitter();

  getBadgeClass(labelType: LabelType): string {
    const colors: Record<LabelType, string> = {
      primary_dark: 'bg-blue-300 text-blue-700 primary-dark',
      primary: 'bg-blue-200 text-blue-600 primary',
      primary_light: 'bg-blue-100 text-blue-500 primary-light',
      primary_extralight: 'bg-blue-50 text-blue-500 primary-extralight',
      secondary_dark: 'bg-gray-300 text-black secondary-dark',
      secondary: 'bg-gray-200 text-black secondary',
      secondary_light: 'bg-gray-100 text-black secondary-light',
      success_dark: 'bg-green-300 text-green-700 success-dark',
      success: 'bg-green-200 text-green-600 success',
      warning_dark: 'bg-yellow-300 text-yellow-700 warning-dark',
      warning: 'bg-yellow-200 text-yellow-600 warning',
      danger_dark: 'bg-red-300 text-danger-700 danger-dark',
      danger: 'bg-red-200 text-danger-600 danger',
      danger_light: 'bg-red-100 text-danger-500 danger_light',
      disabled: 'bg-gray-100 text-gray-400 disabled',
    };

    return `${colors[labelType]} inline-block text-ellipsis overflow-hidden items-center rounded-xl px-2.5 py-0.5 text-xs font-medium`;
  }

  onRemove(event: MouseEvent) {
    this.removeBtnClick.emit(event);
  }
}
