import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Report } from '@core/models';
import { LabelComponent } from '../label-component';

@Component({
  selector: 'x-report-label',
  templateUrl: './report-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportLabelComponent extends LabelComponent<Report> {}
