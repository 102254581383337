import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { AssetTypeLabelComponent } from './asset-type-label.component';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [AssetTypeLabelComponent],
  exports: [AssetTypeLabelComponent],
})
export class AssetTypeLabelModule {}
