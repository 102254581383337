import { Address } from './address';
import { getAllEnumValues } from './enums/enum';

export interface User {
  id: string;
  address: Address;
  title: string;
  firstname: string;
  lastname: string;
  status: User.Status;
  email: string;
  phone: string;
  best_reachable: User.BestReachable;
  company: string;
  created_at: Date;
  is_seed2fa_confirmed: boolean;
}

export interface ShortUser {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
}

export namespace User {
  export interface ActiveUser extends User {
    // role: AdminRole | CustomerRole;
    withAdminAccess: boolean;
  }

  export interface Admin extends User {
    role: AdminRole;
  }

  export interface AssetUser extends User {
    member_id: string;
    invited_at: Date;
    role: 'customer' | 'user';
  }

  export class SignInUser {
    username: string;
    password: string;
  }

  export class SignUpUser implements Pick<User, 'firstname' | 'lastname' | 'company' | 'title' | 'email'> {
    firstname: string;
    lastname: string;
    company: string;
    title: string;
    email: string;
    password: string;
    confirm_password: string;
  }

  export class ConfirmInvitationUser implements Pick<User, 'firstname' | 'lastname' | 'company' | 'title' | 'email'> {
    firstname: string;
    lastname: string;
    company: string;
    title: string;
    email: string;
    phone: string;
    password: string;
    confirm_password: string;
  }

  export enum Status {
    Active = 'active',
    Blocked = 'blocked',
    Archived = 'archived',
    Pending = 'pending',
  }

  export namespace Status {
    export const all = getAllEnumValues<Status>(Status);
    export const available = getAllEnumValues<Status>(Status, (status) => status !== Status.Archived);
  }

  export enum AdminRole {
    Root = 'root',
    Manager = 'manager',
    Internal = 'internal',
    External = 'external',
  }

  export namespace AdminRole {
    export const all = getAllEnumValues<AdminRole>(AdminRole);
  }

  export enum CustomerRole {
    Customer = 'customer',
    User = 'user', // eslint-disable-line @typescript-eslint/no-shadow
    ProjectLead = 'project_lead',
    ProjectTeam = 'project_team',
    ProjectManager = 'project_manager',
  }

  export namespace CustomerRole {
    export const all = getAllEnumValues<CustomerRole>(CustomerRole);
  }

  export enum BestReachable {
    NineTwelve = '9-12',
    TwelveFifteen = '12-15',
    FifteenEighteen = '15-18',
  }

  export namespace BestReachable {
    export const all = getAllEnumValues<BestReachable>(BestReachable);
  }
}
