import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Norm } from '@core/models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PORTAL_API_URL } from './constants';

@Injectable({
  providedIn: 'root',
})
export class NormService {
  readonly apiUrl = `${PORTAL_API_URL}/norms`;

  constructor(private http: HttpClient) {}

  getNorms(): Observable<Norm[]> {
    return this.http.get<Norm[]>(this.apiUrl).pipe(map((norms) => _.map(norms, this.transform.bind(this))));
  }

  getNorm(norm_id: string): Observable<Norm> {
    return this.http.get<Norm>(`${this.apiUrl}/${norm_id}`).pipe(map(this.transform.bind(this)));
  }

  private transform(norm: Norm): Norm {
    return {
      ...norm,
      created_at: new Date(norm.created_at),
    };
  }
}
