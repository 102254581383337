import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { FormatNumberService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Pipe({
  name: 'localeCurrency',
  pure: false,
})
export class LocaleCurrencyPipe implements PipeTransform {
  private markForTransform = true;
  private value?: string | null;

  constructor(
    private readonly formatNumberService: FormatNumberService,
    private readonly transloco: TranslocoService,
    private cdr: ChangeDetectorRef
  ) {
    this.watchLanguageChangesToRerunTransform();
  }

  transform(value: number | string, currency?: string, noValueReplacement?: string | number): string | null {
    if (!this.markForTransform && this.value !== undefined) {
      return this.value;
    }
    if (typeof value === 'string') {
      this.value = value;
      return this.value;
    }
    const currencyValue = Number.parseFloat(`${value}`);

    this.value =
      !currencyValue && noValueReplacement !== undefined
        ? `${noValueReplacement}`
        : this.formatNumberService.formatCurrency(Number.parseFloat(`${value}`) || 0, currency);

    this.cdr.markForCheck();
    return this.value;
  }

  private watchLanguageChangesToRerunTransform(): void {
    this.transloco.langChanges$.pipe(untilDestroyed(this)).subscribe(() => {
      this.markForTransform = true;
    });
  }
}
