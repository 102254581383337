import { Pipe, PipeTransform } from '@angular/core';
import { Message } from '@core/models';
import * as _ from 'lodash';
import { Dictionary } from 'lodash';

interface MessagesTree {
  allChildren: MessagesTree[];
  children: MessagesTree[];
  message: Message.Short;
  parentId: string;
}

@Pipe({
  name: 'messagesTree',
  pure: true,
})
export class MessagesTreePipe implements PipeTransform {
  transform(messages?: Message.Short[]): MessagesTree[] {
    if (!messages || _.isEmpty(messages)) {
      return [];
    }

    const roots: MessagesTree[] = [];
    const map: Dictionary<number> = {};
    const tree: MessagesTree[] = [];

    const messagesMap = _.keyBy(messages, (message) => (message.id === message.parent_id ? undefined : message.id));

    for (let i = 0; i < messages.length; i += 1) {
      map[messages[i].id] = i;
      tree[i] = {
        message: messages[i],
        parentId: messages[i].parent_id,
        children: [],
        allChildren: [],
      };
    }

    for (let i = 0; i < tree.length; i += 1) {
      const node = tree[i];
      const parentExists = !!messagesMap[node.parentId];
      if (node.parentId && parentExists) {
        tree[map[node.parentId]].children.push(node);
      } else {
        roots.push(node);
      }
    }

    _.forEach(roots, (node) => {
      node.allChildren = this.getAllChildren(node);
    });

    return roots;
  }

  private getAllChildren({ children }: MessagesTree): MessagesTree[] {
    return [...children, ..._.flatMap(children, (child) => this.getAllChildren(child))];
  }
}
