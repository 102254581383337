import { Params } from '@angular/router';

function encodeParams(value: unknown): unknown {
  if (value instanceof Array) {
    return value.map(encodeParams);
  }

  if (value instanceof Date) {
    return value.toISOString();
  }

  if (value && typeof value === 'object') {
    const encodedValue: Params = {};
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        encodedValue[key] = encodeParams(value[key]);
      }
    }
    return encodedValue;
  }

  return value;
}

export function toQueryParams<T extends Object>(filter: T): Params {
  const params = encodeParams(filter) as Params;
  return params;
}

export function encodeQueryParam(str) {
  return encodeURIComponent(str).replace(/[!'()*]/g, function (c) {
    return '%' + c.charCodeAt(0).toString(16);
  });
}
