import * as _ from 'lodash';

export enum Risk {
  VeryLow = 'veryLow',
  Low = 'low',
  Medium = 'medium',
  AboveAverage = 'aboveAverage',
  High = 'high',
  Critical = 'critical',
}

export namespace Risk {
  export const order: Risk[] = [Risk.VeryLow, Risk.Low, Risk.Medium, Risk.AboveAverage, Risk.High, Risk.Critical];

  export const orderIndex = <{ [key in Risk]: number }>_(Risk.order)
    .keyBy((risk) => risk)
    .mapValues((risk) => Risk.order.indexOf(risk))
    .value();
}

export enum RiskScore {
  VeryLow = 1,
  Low = 2,
  Medium = 3,
  AboveAverage = 4,
  High = 5,
  Critical = 6,
}
