import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { Document, Message, Report } from '@core/models';
import { QaOverviewHelperService } from '@portal/components/qa-overview/qa-overview-helper.service';
import { CallPipeModule, LocaleDatePipeModule, NewLinePipeModule } from '@common';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { QaReferenceModule } from '@portal/components/qa-overview/qa-reference/qa-reference.module';
import { NgIf } from '@angular/common';
import { DocumentsPreviewModule } from '@portal/components/documents-preview/documents-preview.module';
import { DocumentUploadEvent } from '@portal/components/documents-preview/documents-preview.component';
import { ProcessedReference } from '@portal/customer/components/attach-reference-button/reference.model';
import { ReferencesPreviewComponent } from '@portal/customer/components/references-preview/references-preview.component';
import { DetailsFieldRowComponent } from '@portal/components/details-field-row/details-field-row.component';
import { UsernameCellRendererComponent } from '@portal/components/username-renderer/username-renderer.component';
import { PriorityIndicatorComponent } from '@common/components/priority-indicator/priority-indicator.component';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { GridHelperService } from '@portal/components';

@Component({
  selector: 'x-qa-details-dialog-fields',
  templateUrl: './qa-details-dialog-fields.component.html',
  styleUrls: ['./qa-details-dialog-fields.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NewLinePipeModule,
    TranslocoModule,
    LocaleDatePipeModule,
    CallPipeModule,
    QaReferenceModule,
    NgIf,
    DocumentsPreviewModule,
    ReferencesPreviewComponent,
    DetailsFieldRowComponent,
    UsernameCellRendererComponent,
    PriorityIndicatorComponent,
    BadgeComponent,
  ],
})
export class QaDetailsDialogFieldsComponent {
  @Input() message: Message;
  @Input() isQuestion = true;
  @Input() showUpload = false;

  @Output() documentUploadClick = new EventEmitter<DocumentUploadEvent>();

  readonly qaOverviewHelperService = inject(QaOverviewHelperService);
  readonly transloco = inject(TranslocoService);
  readonly gridHelper = inject(GridHelperService);

  get rejected_reason(): string | undefined {
    return this.message.rejected_reason;
  }

  getResponseReferences(message: Message): ProcessedReference[] | undefined {
    return Message.extractResponseReferences(message);
  }

  getDocumentList(data: Message): Document.Short[] | Document[] {
    return [...(data?.documents ?? []), ...(data?.photos ?? [])];
  }

  getStatusBadgeData(value) {
    return {
      text: this.transloco.translate(value),
      badgeType: this.gridHelper.getStatusBadgeType(value),
    };
  }

  getReferences(message: Message): { processedReferences: ProcessedReference[]; chapterReference: Report.Chapter } {
    return {
      processedReferences: Message.extractReference(message).map((ref) =>
        ref.type === 'finding'
          ? {
              ...ref,
              name: `${this.transloco.translate('finding-no')} ${ref.name}`,
            }
          : ref
      ),
      chapterReference: this.qaOverviewHelperService.getReferenceReportChapter(message),
    };
  }
}
