<div class="flex pr-[4px]">
  <ng-container *ngIf="!!photo; else loading">
    <x-image-viewer
      [openFullscreenOnClick]="true"
      (exitFullscreen)="onClose()"
      class="w-4 h-4"
      [src]="photo"
    ></x-image-viewer>
  </ng-container>
  <ng-template #loading>
    <div class="flex justify-center align-center p-2">
      <x-spinner></x-spinner>
    </div>
  </ng-template>
</div>
