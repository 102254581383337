import { ChangeDetectionStrategy, Component } from '@angular/core';

import { HeaderCellComponent } from '../../grid';

@Component({
  selector: 'x-portal-user-header-cell',
  templateUrl: './portal-user-header-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalUserHeaderCellComponent extends HeaderCellComponent {}
