<ng-container [ngSwitch]="error?.error">
  <ng-container *ngSwitchCase="'required'">{{ 'required-field' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'greater_than'">
    <span [title]="error.details">{{ 'value-is-invalid' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'inclusion'">
    <span [title]="error.details">{{ 'not-in-the-list' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'invalid'">
    <span [title]="error.details">{{ 'invalid' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'confirmation'">
    <span [title]="error.details">{{ 'password-doesnt-match' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchCase="'minimum'">
    <span [title]="error.details">{{ 'minimum-characters' | transloco }}</span>
  </ng-container>
  <ng-container *ngSwitchDefault>
    {{ error?.details }}
  </ng-container>
</ng-container>
