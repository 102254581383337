import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellClickedEvent,
  ColDef,
  GridOptions,
  GridReadyEvent,
  ICellRendererParams,
  Module,
  RowClickedEvent,
  RowNode,
  TextFilter,
  TextFilterModel,
  ViewportChangedEvent,
} from '@ag-grid-community/core';
import { ESCAPE, LEFT_ARROW, RIGHT_ARROW } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AlertType } from '@common';
import { Document } from '@core/models';
import { AdminDocumentsService, FileSizeService, StorageService } from '@core/services';
import { FormatDateService } from '@core/services/format-date.service';
import { DialogActions } from '@core/state';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AdminRoutingService } from '@portal/admin/services/admin-routing.service';
import { AdminFilterActions } from '@portal/admin/store';
import { AdminRouterSelectors } from '@portal/admin/store/admin-router.selector';
import { DocumentQueryParams } from '@portal/customer/services';
import * as _ from 'lodash';
import { combineLatest, of, Subject } from 'rxjs';
import { filter, map, mapTo, mergeMap, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  BaseGrid,
  BaseGridComponent,
  EmptyHeaderCellComponent,
  GridHelperService,
  HeaderCheckboxRendererComponent,
} from '../../grid';
import { GridHelper } from './grid.helper';
import { PortalDocumentHeaderCellComponent } from './portal-document-header-cell/portal-document-header-cell.component';
import { PortalDocumentTagComponent, TagsDialogData, TagsDialogResponse } from './portal-document-tag';

interface RowData extends Document.ListItem {
  photoDataUrl: string | ArrayBuffer;
}

@Component({
  selector: 'x-portal-document',
  templateUrl: './portal-document.component.html',
  styleUrls: ['./portal-document.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalDocumentComponent extends BaseGridComponent<Document.ListItem> implements OnChanges, OnDestroy {
  @Input() additionalColDefs: ColDef[] = [];
  @Input() documentTypeTemplate: TemplateRef<unknown>;
  @Input() getIsColumnVisible: (field: keyof Document.ListItem) => boolean;
  @Input() settingsEnabled = true;
  @Input() type: Document.Type;
  @Input() types: Document.Type[];
  @Output() documentsLoaded = new EventEmitter<number>();
  @ViewChild('documentDownloadFailTemplate') documentDownloadFailTemplate: TemplateRef<unknown>;
  @ViewChild('documentPublishFailTemplate') documentPublishFailTemplate: TemplateRef<unknown>;
  @ViewChild('photoTagFailTemplate') photoTagFailTemplate: TemplateRef<unknown>;

  cacheOverflowSize = 10;
  defaultColDef: ColDef = {
    ...BaseGrid.defaultColDef,
    tooltipValueGetter: ({ value }) => {
      if (value instanceof Date) {
        return this.getDateCellRenderer(value, true);
      }

      return value;
    },
  };
  gridOptions: GridOptions = {
    ...BaseGrid.defaultGridOptions,
    onGridReady: (event) => this.onGridReady(event),
    onRowClicked: (event: RowClickedEvent) => {
      if (event?.event?.defaultPrevented) {
        return;
      }
      this.onRowClicked(event.data, event);
    },
    onCellClicked: (event) => {
      if (event.colDef?.onCellClicked) {
        event.event?.preventDefault();
      }
    },
    onModelUpdated: () => this.onModelUpdated(),
    onViewportChanged: (viewportChangeEvent: ViewportChangedEvent) => this.onGridScroll(viewportChangeEvent.lastRow),
  };
  infiniteInitialRowCount = 1;
  maxBlocksInCache = 5;
  photoUrl: string = null;
  publishAction$ = createEffect(() => this.actions$.pipe(ofType(AdminFilterActions.publish)));
  resetAction$ = createEffect(() => this.actions$.pipe(ofType(AdminFilterActions.resetFilter)));
  rowData: Document.ListItem[] = [];
  rowHeight = BaseGrid.defaultGridOptions.rowHeight;
  rowNode: RowNode = null;
  setFilterAction$ = createEffect(() => this.actions$.pipe(ofType(AdminFilterActions.setFilter)));

  readonly assetId$ = this.store.select(AdminRouterSelectors.getProjectAssetId);
  readonly cancelDataSource$ = new Subject<void>();
  readonly destroy$ = new Subject<void>();
  readonly modules: Module[] = [ClientSideRowModelModule];
  readonly projectId$ = this.store.select(AdminRouterSelectors.getProjectId);

  constructor(
    protected componentRef: ElementRef<HTMLElement>,
    protected formatDateService: FormatDateService,
    private store: Store,
    private documentsService: AdminDocumentsService,
    private storageService: StorageService,
    private dialog: MatDialog,
    private adminRoutingService: AdminRoutingService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    public sanitizer: DomSanitizer,
    private fileSizeService: FileSizeService,
    private actions$: Actions,
    gridHelper: GridHelperService,
    transloco: TranslocoService
  ) {
    super(componentRef, formatDateService, gridHelper, transloco);

    setTimeout(() => {
      this.store.dispatch(AdminFilterActions.documentSelected({ data: 0 }));
    });

    this.setFilterAction$
      .pipe(
        takeUntil(this.destroy$),
        map((action) => {
          this.gridApi?.deselectAll();
          this.gridApi?.setFilterModel({
            tags: <TextFilterModel>{
              filterType: 'text',
              filter: action.data.tags[0],
              type: TextFilter.CONTAINS,
            },
          });
        })
      )
      .subscribe();

    this.resetAction$
      .pipe(
        takeUntil(this.destroy$),
        map(() => {
          this.gridApi?.deselectAll();
          this.gridApi?.setFilterModel(null);
        })
      )
      .subscribe();

    this.publishAction$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => {
          const documents: Document.ListItem[] = _.map(this.gridApi?.getSelectedNodes(), ({ data }) => data);

          return this.documentsService.publishDocumentsBatch(documents);
        })
      )
      .subscribe({
        next: () => this.fetchRowData(),
      });
  }

  get isPanorama(): boolean {
    return !!(<Document.ListItem>this.rowNode?.data)?.types.includes('panorama');
  }

  get paginationPageSize(): number {
    return this.type === 'photo' ? 200 : 100;
  }

  @HostListener('document:click')
  onDocumentClick() {
    if (this.photoUrl) {
      this.photoUrl = null;
      this.rowNode = null;
    }
  }

  @HostListener('document:keydown', ['$event'])
  onDocumentKeyPress(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE) {
      this.onDocumentClick();
      return;
    }

    if (this.photoUrl && this.rowNode && (event.keyCode === LEFT_ARROW || event.keyCode === RIGHT_ARROW)) {
      if (event.keyCode === LEFT_ARROW) {
        this.onClickPrevImage();
      } else {
        this.onClickNextImage();
      }
    }
  }

  fetchRowData(): void {
    if (!this.gridApi) {
      return;
    }

    this.gridApi.dispatchEvent({ type: 'refreshData' });
  }

  readonly newDownloadTokenGetter = (document_id: string) =>
    combineLatest([this.projectId$, this.assetId$]).pipe(
      switchMap(([project_id, asset_id]) =>
        this.documentsService.getNewDownloadTokenForProjectAssetDocument(<Document.ListItem>{
          asset_id,
          project_id,
          id: document_id,
        })
      )
    );

  ngOnChanges({ type, types, getIsColumnVisible, additionalColDefs }: SimpleChanges) {
    if (type || additionalColDefs || types) {
      const columnDefs = this.getColumnDefs();
      if (this.gridApi) {
        this.gridApi.setColumnDefs(columnDefs);
      } else {
        this.columnDefs = columnDefs;
      }

      this.setDatasource();
      this.rowHeight = this.type === 'photo' ? BaseGrid.previewHeight : BaseGrid.defaultGridOptions.rowHeight;

      setTimeout(() => {
        this.resizeGrid();
      }, 100);
    }

    if (getIsColumnVisible) {
      const isColumnVisible = (colDef: ColDef) =>
        this.getIsColumnVisible ? this.getIsColumnVisible(colDef.field as any) : !colDef.hide; // eslint-disable-line @typescript-eslint/no-explicit-any

      if (this.columnApi) {
        _.forEach(this.columnApi.getAllColumns(), (column) => {
          const visible = isColumnVisible(column.getColDef());
          column.setVisible(visible);
        });
      } else {
        _.forEach(this.columnDefs, (colDef) => {
          colDef.hide = !isColumnVisible(colDef);
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onClickNextImage(): void {
    const nextId = +this.rowNode?.id + 1;
    const rowNode = this.gridApi?.getRowNode(`${nextId}`);
    if (!rowNode) {
      return;
    }
    this.downloadDocument(rowNode.data, null, rowNode);
  }

  onClickPrevImage(): void {
    const nextId = +this.rowNode?.id - 1;
    const rowNode = this.gridApi?.getRowNode(`${nextId}`);
    if (!rowNode) {
      return;
    }
    this.downloadDocument(rowNode.data, null, rowNode);
  }

  onColumnsChanged(): void {
    setTimeout(() => {
      this.settingsColumns = this.getSettingsColumns();
      this.cdr.detectChanges();
    });
  }

  onGridReady(event: GridReadyEvent): void {
    super.onGridReady(event);

    this.setDatasource();
  }

  onGridScroll(lastRow: number): void {}

  onRowClicked(data, rowEvent: RowClickedEvent): void {
    this.downloadDocument(data, rowEvent.event as MouseEvent, rowEvent.node);
  }

  onSelectionChanged(): void {
    const selectedDocuments: Document.ListItem[] = this.gridApi?.getSelectedNodes().map((node) => node.data) || [];
    this.store.dispatch(AdminFilterActions.documentSelected({ data: selectedDocuments.length }));
  }

  setGridTags(selectedListItem: Document.ListItem[]): void {
    const itemDict = _.keyBy(selectedListItem, (item) => item.name);
    const update: Document.ListItem[] = [];

    this.gridOptions.api?.forEachNode((rowNode) => {
      const nodeData: Document.ListItem = rowNode.data;
      const item = itemDict[nodeData.name];

      if (item) {
        nodeData.tags = item.id ? item.tags : _.uniq([...nodeData.tags, ...item.tags]);
        update.push(nodeData);
      }
    });

    this.gridApi?.applyTransaction({ update });
    this.gridApi?.resetRowHeights();
  }

  protected getColDef(field: keyof Document.ListItem, colDef: Partial<ColDef> = {}): ColDef {
    return {
      ...super.getColDef(field, colDef),
      tooltipField: null,
      headerComponentFramework: PortalDocumentHeaderCellComponent,
      hide: this.getIsColumnVisible ? !this.getIsColumnVisible(field) : !!colDef.hide,
    };
  }

  protected getColumnDefs(): ColDef[] {
    const colDefs = [
      this.getColDef('is_published', {
        sortable: false,
        hide: this.type === 'photo',
        cellClass: ({ value, data }) => {
          if (!(data as Document.ListItem)?.id) {
            return '';
          }

          return `icon-cell ${!value && 'clickable-cell'}`;
        },
        cellRenderer: ({ value, data }) => {
          if (!(data as Document.ListItem)?.id) {
            return `<span class="icon-progress"></span>`;
          }

          return `<span class="icon-font icon-font-${value === true ? 'check' : 'cancel'}"></span>`;
        },
        onCellClicked: ({ data }) => this.publishDocument(data),
      }),
      this.getColDef('name', {
        sortable: true,
        tooltipField: 'name',
      }),
      this.getColDef('types', {
        sortable: true,
        hide: true,
      }),
      this.getColDef('upload_status', {
        sortable: false,
        hide: true,
        cellClass: 'capitalize',
      }),
      this.getColDef('user_email', {
        sortable: true,
      }),
      this.getColDef('created_at', {
        sortable: true,
        tooltipField: null,
        cellRenderer: ({ value }) => this.getDateCellRenderer(value, true),
      }),
      this.getColDef('published_at', {
        sortable: true,
        tooltipField: null,
        cellRenderer: ({ value }) => this.getDateCellRenderer(value, true),
      }),
      this.getColDef('file_size', {
        sortable: true,
        hide: true,
        cellRenderer: ({ value }) => this.fileSizeService.transform(value),
        tooltipValueGetter: undefined,
      }),
      this.getColDef('uploaded_at', {
        sortable: true,
        tooltipField: null,
        cellRenderer: ({ value }) => this.getDateCellRenderer(value, true),
        hide: true,
      }),
    ];

    if (Document.Type.isPhoto(this.type)) {
      return this.getPhotoColumnDefs(colDefs);
    }

    return [...colDefs, ...(this.additionalColDefs || [])];
  }

  protected getPhotoColumnDefs(colDefs: ColDef[]): ColDef[] {
    const hiddenByDefault: Array<keyof Document.ListItem> = ['description', 'is_published', 'types', 'upload_status'];

    const checkboxColumn = this.getColDef(null, {
      cellClass: 'flex-centered',
      headerClass: 'flex-centered',
      checkboxSelection: true,
      minWidth: 30,
      maxWidth: 30,
      width: 30,
      resizable: false,
      sortable: false,
      suppressMovable: true,
      suppressColumnsToolPanel: true,
      headerComponentFramework: HeaderCheckboxRendererComponent,
      tooltipValueGetter: undefined,
      headerCheckboxSelection: true,
    });

    const previewColumn = this.getColDef('id', {
      colId: 'image-preview-col',
      hide: false,
      sortable: false,
      width: 240,
      minWidth: 120,
      headerComponentFramework: EmptyHeaderCellComponent,
      cellClass: 'clickable-cell no-padding',
      onCellClicked: ({ data, event, node }) => this.downloadDocument(data, event as MouseEvent, node),
      cellRenderer: (params: ICellRendererParams) => {
        const data: RowData = params.data;
        if (!data?.types.includes('photo') && data?.types.includes('panorama')) {
          return '';
        }

        if (!data?.id) {
          return '<span class="icon-progress"></span>';
        }

        const span = document.createElement('div');
        span?.classList?.add('photo-preview');
        if (data?.photoDataUrl) {
          span.style.setProperty('background-image', `url('${data.photoDataUrl}')`);
          return span;
        }

        this.storageService
          .getImageWithDownloadToken(data)
          .pipe(take(1))
          .subscribe({
            next: (imageData) => {
              const reader = new FileReader();

              reader.addEventListener('load', () => {
                span.style.setProperty('background-image', `url('${reader.result}')`);
                data.photoDataUrl = reader.result;
              });

              reader.readAsDataURL(imageData);
            },
          });

        return span;
      },
      tooltipValueGetter: undefined,
      headerComponentParams: {
        displayName: null,
      },
    });

    const tagsColumn = this.getColDef('tags', {
      sortable: false,
      cellClass: 'clickable-cell tags-cell',
      minWidth: 100,
      width: 200,
      wrapText: true,
      autoHeight: true,
      cellStyle: () => ({
        'min-height': `${this.rowHeight}px`,
      }),
      cellRenderer: ({ value, data }) => {
        if (!(data as Document.ListItem)?.id) {
          return '';
        }

        if (_.isEmpty(value)) {
          return `<div style="opacity: .6;">click to add tags</div>`;
        }

        return _.map(value, (tag) => `<div class="chip" title="${tag}">${tag}</div>`).join('');
      },
      onCellClicked: (event: CellClickedEvent) => {
        const doc = event.data as Document.ListItem;
        this.dialog
          .open<PortalDocumentTagComponent, TagsDialogData, TagsDialogResponse>(PortalDocumentTagComponent, {
            data: <TagsDialogData>{
              tags: doc.tags,
              options: {
                asset_id: doc.asset_id,
                project_id: doc.project_id,
              },
            },
          })
          .afterClosed()
          .pipe(
            take(1),
            filter((response) => !!response),
            filter(({ tags, tagAllPhotos }) => {
              if (tagAllPhotos) {
                return true;
              }

              const diffLeft = _.difference(doc.tags, tags);
              const diffRight = _.difference(tags, doc.tags);
              return diffLeft.length > 0 || diffRight.length > 0;
            }),
            mergeMap(({ tags, tagAllPhotos }) => {
              const selectedListItem: Document.ListItem = {
                ...doc,
                tags,
              };
              return this.documentsService.tagPhoto(selectedListItem, tagAllPhotos).pipe(mapTo(selectedListItem));
            })
          )
          .subscribe({
            next: (selectedListItem) => this.setGridTags([selectedListItem]),
            error: () => this.showError(this.photoTagFailTemplate),
          });
      },
    });

    const originalDatetimeColumn = this.getColDef('original_datetime', {
      tooltipField: null,
      cellRenderer: ({ value }) => this.getDateCellRenderer(value, true),
    });

    colDefs.unshift(checkboxColumn, previewColumn);
    colDefs.splice(-1, 0, tagsColumn);
    colDefs.splice(-1, 0, originalDatetimeColumn);

    return colDefs.map((colDef) => ({
      hide: hiddenByDefault.indexOf(colDef.field as any) !== -1, // eslint-disable-line @typescript-eslint/no-explicit-any
      ...colDef,
    }));
  }

  protected publishDocument(doc: Document.ListItem): void {
    if (doc?.is_published) {
      return;
    }

    this.documentsService
      .publishDocument(doc)
      .pipe(take(1))
      .subscribe({
        next: () => this.fetchRowData(),
        error: () => this.showError(this.documentPublishFailTemplate),
      });
  }

  private downloadDocument(doc: Document.ListItem, event: MouseEvent, node?: RowNode): void {
    this.rowNode = node;

    if (!doc || !doc.download_token) {
      return;
    }

    const isCtrlPressed = event?.ctrlKey;
    const isImage = doc.types.some((type) => this.isImage(type));

    if (!isCtrlPressed && !isImage) {
      const { project_id, asset_id, types, id } = doc;
      const type = this.adminRoutingService.getMediaType(types);
      const url = this.adminRoutingService.getAssetDocumentViewerLink(project_id, asset_id, type, id);
      this.router.navigateByUrl(url);
      return;
    }

    this.store.dispatch(DialogActions.showProgress());

    const downloadToken$ = isImage
      ? this.documentsService.getNewDownloadTokenForProjectAssetDocument(doc, { version: 1600 })
      : of(doc.download_token);

    downloadToken$
      .pipe(
        take(1),
        switchMap((download_token) =>
          isImage && !isCtrlPressed
            ? this.storageService.getImageWithDownloadToken({
                download_token,
              })
            : this.storageService.downloadDocument({
                ...doc,
                download_token,
              })
        )
      )
      .subscribe({
        next: (data) => {
          this.store.dispatch(DialogActions.closeAll());
          const fileUri = window.URL.createObjectURL(data);
          if (isImage && !event?.ctrlKey) {
            this.photoUrl = `url('${fileUri}')`;
            this.cdr.markForCheck();
          } else {
            window.open(fileUri);
          }
        },
        error: () => {
          this.store.dispatch(DialogActions.closeAll());
          this.showError(this.documentDownloadFailTemplate);
        },
      });
  }

  private isImage(type: Document.Type): boolean {
    return Document.Type.isPanorama(type) || Document.Type.isPhoto(type);
  }

  private setDatasource(): void {
    if (!this.gridApi) {
      return;
    }

    this.cancelDataSource$.next(null);

    GridHelper.setClientSideDatasource({
      gridApi: this.gridApi,
      columnApi: this.columnApi,
      cancel$: this.cancelDataSource$,
      destroy$: this.destroy$,
      fetch: (params: DocumentQueryParams) =>
        combineLatest([this.projectId$, this.assetId$]).pipe(
          switchMap(([projectId, assetId]) =>
            this.documentsService.fetchDocumentsForTheAsset(projectId, assetId, this.types || this.type, {
              ...params,
            })
          )
        ),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private showError(template: TemplateRef<unknown>, context?: any): void {
    this.store.dispatch(
      DialogActions.showAlert({
        data: {
          type: AlertType.Error,
          template: () => template,
          context,
        },
      })
    );
  }
}
