import { NgModule } from '@angular/core';
import { LightenPipe, OpacityPipe } from './color.pipe';

const pipes = [LightenPipe, OpacityPipe];

@NgModule({
  declarations: [...pipes],
  exports: [...pipes],
})
export class ColorPipesModule {}
