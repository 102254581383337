import { Component, inject, Input, TemplateRef, ViewChild } from '@angular/core';

import { ToastService, ToastType } from '@app/core/toast';

@Component({
  selector: 'x-toast-template',
  templateUrl: './toast-template.component.html',
})
export class ToastTemplateComponent {
  @Input() type: ToastType = ToastType.SUCCESS;
  @Input() message = '';
  @Input() additionalTexts?: string[];
  @Input() id!: string;
  @Input() messageTemplate?: TemplateRef<unknown>;
  @Input() messageTemplateContext?: unknown;

  @ViewChild('customToastTemplate') templateRef!: TemplateRef<never>;

  readonly toastType: typeof ToastType = ToastType;
  readonly toastService = inject(ToastService);

  onHideToast(): void {
    this.toastService.hideToast(this.id);
  }

  trackBy(_: number, text: string): string {
    return text;
  }
}
