import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CellRendererComponent } from '@portal/components';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'x-grid-icon-action-renderer',
  standalone: true,
  imports: [CommonModule, SvgIconModule, MatTooltipModule, TranslocoModule],
  templateUrl: './grid-icon-action-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GridIconActionRendererComponent extends CellRendererComponent {
  handleClick(event: MouseEvent) {
    if (this.params.onClick) {
      event.stopPropagation();
      this.params.onClick();
    }
  }
}
