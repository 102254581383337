import { ColumnApi, GridApi, ViewportChangedEvent } from '@ag-grid-community/core';
import { Document } from '@core/models';
import { GridSortModel } from '@portal/components';
import { DocumentQueryParams, DocumentsQueryResult } from '@portal/customer/services';
import * as _ from 'lodash';
import { BehaviorSubject, fromEvent, Observable, Subject } from 'rxjs';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
import { take, takeUntil } from 'rxjs/operators';
import { debounce } from 'lodash';
import { SHORT_DEBOUNCE_TIME } from '@app/utils/rxjs.utils';

export interface GridHelperOptions {
  gridApi: GridApi;
  columnApi: ColumnApi;
  cancel$: Observable<void>;
  destroy$: Observable<void>;
  fetch: (params: DocumentQueryParams) => Observable<DocumentsQueryResult>;
  rows$?: BehaviorSubject<Document.ListItem[]>;
}

interface ExtendedOptions extends GridHelperOptions {
  cancelOngoingRequest$: Subject<void>;
  per_page: number;
  isLastPage?: boolean;
}

export namespace GridHelper {
  export function setClientSideDatasource(options: GridHelperOptions): void {
    const extendedOptions: ExtendedOptions = {
      ...options,
      cancel$: options.cancel$ ?? new Subject(),
      destroy$: options.destroy$ ?? new Subject(),
      cancelOngoingRequest$: new Subject(),
      per_page: 100,
    };

    fromEvent(extendedOptions.gridApi as FromEventTarget<unknown>, 'refreshData')
      .pipe(takeUntil(extendedOptions.cancel$), takeUntil(extendedOptions.destroy$))
      .subscribe({
        next: () => {
          extendedOptions.cancelOngoingRequest$.next(null);
          extendedOptions.gridApi.setRowData([]);
          options.rows$?.next([]);
          extendedOptions.isLastPage = false;
          fetchMoreRowsDebounced(0, extendedOptions);
        },
      });

    addViewportChangedEventListener(extendedOptions);
    addSortChangedEventListener(extendedOptions);
    addFilterChangedEventListener(extendedOptions);
  }

  function addViewportChangedEventListener(options: ExtendedOptions): void {
    const { gridApi, cancel$, destroy$ } = options;
    let fetch$: Observable<void> | undefined;
    fetchMoreRowsDebounced(0, options);

    fromEvent(gridApi as FromEventTarget<unknown>, 'viewportChanged')
      .pipe(takeUntil(cancel$), takeUntil(destroy$))
      .subscribe({
        next: (event) => {
          const totalRows = gridApi.getModel().getRowCount();
          const { lastRow } = <ViewportChangedEvent>event;

          if (totalRows !== lastRow && totalRows * 0.8 <= lastRow && !fetch$) {
            fetch$ = fetchMoreRows(lastRow + options.per_page, options);

            fetch$?.pipe(take(1), takeUntil(cancel$), takeUntil(destroy$)).subscribe({
              next: () => {
                fetch$ = undefined;
              },
            });
          }
        },
      });
  }

  function addSortChangedEventListener(options: ExtendedOptions): void {
    const { gridApi, cancel$, destroy$ } = options;
    fromEvent(gridApi as FromEventTarget<unknown>, 'sortChanged')
      .pipe(takeUntil(cancel$), takeUntil(destroy$))
      .subscribe({
        next: () => {
          options.cancelOngoingRequest$.next(null);
          gridApi.setRowData([]);
          options.rows$?.next([]);
          options.isLastPage = false;
          fetchMoreRowsDebounced(0, options);
        },
      });
  }

  function addFilterChangedEventListener(options: ExtendedOptions): void {
    const { gridApi, cancel$, destroy$ } = options;
    fromEvent(gridApi as FromEventTarget<unknown>, 'filterChanged')
      .pipe(takeUntil(cancel$), takeUntil(destroy$))
      .subscribe({
        next: () => {
          options.cancelOngoingRequest$.next(null);
          gridApi.setRowData([]);
          options.rows$?.next([]);
          options.isLastPage = false;
          fetchMoreRowsDebounced(0, options);
        },
      });
  }

  const fetchMoreRows = (lastRow: number, options: ExtendedOptions): Observable<void> | undefined => {
    const { gridApi, cancel$, destroy$, columnApi, fetch } = options;
    const ongoingRequestSubj = new Subject<void>();

    const sort = _.find(columnApi.getColumnState(), (state) => state.sort) as GridSortModel;
    const model = gridApi.getFilterModel();
    const tags = model?.tags?.filterChanges ? [model.tags.filterChanges] : [];
    const per_page = options.per_page;
    const page = Math.max(Math.floor(lastRow / per_page), 0);

    if (options.isLastPage) {
      return;
    }

    fetch({
      page,
      per_page,
      sort_column: sort?.colId,
      sort_direction: sort?.sort,
      tags,
    })
      .pipe(take(1), takeUntil(cancel$), takeUntil(destroy$), takeUntil(options.cancelOngoingRequest$.asObservable()))
      .subscribe({
        next: (result) => {
          options.isLastPage = (result.page + 1) * result.per_page >= result.total_count;
          if (result.page === 0) {
            gridApi.setRowData(result.documents);
            options.rows$?.next(result.documents);
          } else {
            gridApi.applyTransaction({
              add: result.documents.filter((doc) => gridApi.getRowNode(doc.id) === undefined),
            });
            options.rows$
              ?.pipe(
                take(1),
                takeUntil(cancel$),
                takeUntil(destroy$),
                takeUntil(options.cancelOngoingRequest$.asObservable())
              )
              .subscribe({
                next: (documents) => options.rows$?.next([...(documents || []), ...result.documents]),
              });
          }
        },
        complete: () => {
          ongoingRequestSubj.next();
          ongoingRequestSubj.complete();
        },
      });

    return ongoingRequestSubj.asObservable();
  };

  const fetchMoreRowsDebounced = debounce(fetchMoreRows, SHORT_DEBOUNCE_TIME);
}
