import { CommonModule } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, TemplateRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SpinnerModule } from '@common';
import { ButtonComponent } from '@common/components/button/button.component';
import {
  SIDE_PANEL_DIALOG_DATA,
  SidePanelDialogComponent,
} from '@common/components/side-panel-dialog/side-panel-dialog.component';
import { SidePanelDialogService } from '@portal/customer/services/side-panel-dialog.service';
import { RequiredInformationComponent } from '@common/required-information/required-information.component';
import { Group, Member, Message, Trade } from '@core/models';
import { UsersSelectors } from '@core/state';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  TicketFormComponent,
  TicketFormResult,
} from '@portal/customer/components/asset-details/asset-tickets-container/components/ticket-form/ticket-form.component';
import { Reference } from '@portal/customer/components/attach-reference-button/reference.model';
import { QAService } from '@portal/customer/services';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { isEmpty } from 'lodash';
import { EMPTY } from 'rxjs';
import { catchError, finalize, switchMap, take } from 'rxjs/operators';

export interface CreateTicketDialogData {
  scope?: Message.Scope;
  groups: Group[];
  members: Member[];
  trades: Trade[];
  initialValues?: {
    references?: Reference[];
    message?: string;
  };
}

@UntilDestroy()
@Component({
  selector: 'x-create-ticket-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TicketFormComponent,
    TranslocoModule,
    ButtonComponent,
    SpinnerModule,
    RequiredInformationComponent,
  ],
  templateUrl: './create-ticket-dialog.component.html',
  styleUrls: ['./create-ticket-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTicketDialogComponent implements AfterViewInit {
  @ViewChild('requiredFieldsInfo') requiredFieldsInfoTemplate: TemplateRef<never>;
  @ViewChild('primaryActionsTemplate') primaryActionsTemplate: TemplateRef<never>;

  private readonly dialogConfig = inject(SidePanelDialogService);
  private readonly dialogData = inject(SIDE_PANEL_DIALOG_DATA);
  private readonly store = inject(Store);
  private readonly toast = inject(ToastService);
  private readonly transloco = inject(TranslocoService);
  private readonly qaService = inject(QAService);
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);
  readonly user$ = this.store.select(UsersSelectors.getUser);
  readonly ref: MatDialogRef<SidePanelDialogComponent> = this.dialogData.dialogRef;

  readonly data = this.dialogData.dataSnapshot.data as CreateTicketDialogData;
  canSubmit = false;

  isLoading: boolean;
  newTicketData: Message.NewTicket;

  ngAfterViewInit() {
    this.dialogConfig.updateComponentData(this.dialogData.dialogRef, {
      ...this.dialogData.dataSnapshot,
      primaryActionsTemplate: this.primaryActionsTemplate,
      additionalFooterActionsTemplate: this.requiredFieldsInfoTemplate,
    });
  }

  onFormValueChange(ticketFormResult: TicketFormResult) {
    this.canSubmit = !ticketFormResult.hasErrors;
    this.newTicketData = ticketFormResult.data;
    if (!isEmpty(this.newTicketData)) {
      this.ref.componentInstance.setCanCloseDialog();
    }
  }

  onSubmit() {
    this.isLoading = true;
    this.assetId$
      .pipe(
        switchMap((assetId) =>
          this.qaService.createTicket(assetId, { ...this.newTicketData, scope: this.data.scope || 'general' })
        ),
        catchError(() => {
          this.toast.showToast(this.transloco.translate('tickets-system.ticket-create-error'), ToastType.ERROR);
          return EMPTY;
        }),
        take(1),
        finalize(() => {
          this.isLoading = false;
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        this.ref.close(true);
      });
  }

  onClose() {
    this.ref.componentInstance.close();
  }
}
