<mat-form-field
  class="block w-full rounded-md shadow-sm border border-gray-300 text-sm min-h-[42px] pt-[4px] px-2"
  [ngClass]="{ 'border-primary-500 ring-primary-500': isFocused && !errorMessage, 'border-danger-500': errorMessage }"
>
  <mat-chip-list
    #chipList
    class="mat-chip-list-stacked"
    [disabled]="tagsCtrl.disabled"
    aria-orientation="vertical"
  ></mat-chip-list>

  <x-badge
    class="m-1"
    badgeType="secondary_light"
    *ngFor="let tag of tagsValue"
    [text]="tag"
    [withRemoveButton]="tagsCtrl.enabled"
    (removeBtnClick)="onRemove(tag)"
  ></x-badge>

  <input
    #tagsInput
    *ngIf="!disabled"
    class="large"
    name="tags"
    [matChipInputFor]="chipList"
    [formControl]="tagsCtrl"
    [matAutocomplete]="auto"
    [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
    (matChipInputTokenEnd)="add($event)"
    [matChipInputAddOnBlur]="true"
    (focus)="isFocused = true"
    (blur)="isFocused = false"
  />

  <span
    *ngIf="placeholder && !isFocused && !tagsValue.length"
    class="text-base font-normal leading-6 absolute left-1.5 select-none cursor-text"
    [ngClass]="{ 'text-danger-500': errorMessage, 'text-gray-500': !errorMessage }"
  >
    {{ placeholder }}
  </span>

  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let tag of filteredTags" [value]="tag">
      {{ tag }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>

<span *ngIf="errorMessage" class="text-danger-500 text-sm mt-2.5">
  {{ errorMessage | transloco }}
</span>
