import { getAllEnumValues } from './enums';
import { ProjectPackage } from './project-package';
import { SearchInItem } from './search-in-item';
import { Transform } from './transform';
import { User } from './user';

export type ProjectProductType =
  | 'tdd'
  | 'monitoring'
  | 'data_room'
  | 'data_room_professional'
  | 'data_room_premium'
  | 'data_room_free_trial'
  | 'template';

export namespace TDD {
  export interface Module {
    id: string;
    name: string;
  }

  export interface ReportFormat {
    id: string;
    name: string;
  }

  export enum Type {
    Buyer = 'buyer',
    Seller = 'seller',
    Existing = 'existing',
  }

  export namespace Type {
    export const all = getAllEnumValues<Type>(Type);
  }
}

export interface ProjectForm {
  owner: User;
  name: string;
  product_type: Project['product_type'];
  language: Project['language'];
  tdd_finding_format: NumericRange<0, 6>;
  tdd_trade_set_id: string;
  monitoring_finding_format: 8 | 0;
  monitoring_trade_set_id: string;
  notes: string;
  modules: ProjectPackage.ProductType[];
}

export type Addon = 'qa' | 'photo_documentation';
export interface DataRoomProjectForm {
  owner: User;
  name: string;
  language: Project['language'];
  add_ons: Addon[];
  user_quota: number;
}

export interface DemoProjectForm {
  owner: User;
  name: string;
  notes?: string;
  template_id: string;
}

export const availableProjectLanguages = ['us', 'de'];

export type ProjectLanguage = 'de' | 'uk' | 'fr' | 'es' | 'it' | 'dk' | 'en' | 'us';

export interface Project {
  // country?: Countries; // [be, dk, fi, dr, de, gr, gb, ie, it, lu, li, nl, no, at, pl, se, ch, es]
  // state?: string;
  // city?: string;
  // year_of_construction_oldest?: number;
  // year_of_construction_newest?: number;
  // gross_area_sum?: number;
  // due_date?: Date;
  // tdd_type?: TDD.Type;
  // price?: number;
  // first_milestone?: string;
  // first_charge_pct?: number;
  // second_milestone?: string;
  // second_charge_pct?: number;
  // additional_costs_pct: number;
  // travel_expenses_included?: boolean;
  // notes?: string;
  // additional_modules_ids?: AdditionalModules[];
  // disclaimer_id?: string;
  // owner_id?: string; // *
  // modules: ProjectPackage.ProductType[];
  // user_quota?: number;

  // -----------
  id: string;
  tenant_id: string;
  name: string;
  token: string;
  logo_download_token: string;
  status: 'active' | 'pending';
  type: 'buyer' | 'seller' | 'existing';
  terms_conditions_id: string;
  tdd_finding_format: NumericRange<0, 6>;
  tdd_trade_set_id: string;
  monitoring_finding_format: 8 | 0;
  monitoring_trade_set_id: string;
  owner_id: string;
  owner: User;
  team_leads: User[];
  language: ProjectLanguage;
  with2fa: boolean;
  /**
   * @required
   */
  modules: ProjectPackage.ProductType[];
  user_quota: number;
  /**
   * @required
   */
  product_type: 'monitoring' | 'data_room' | 'tdd';
  notes: string;
  created_at: Date;
  time_zone: string;
}

export interface ProjectListItem {
  id: string;
  tenant_id: string;
  name: string;
  token: string;
  status: 'active' | 'pending' | 'archived';
  logo_download_token: string;
  tdd_finding_format: NumericRange<1, 6>;
  tdd_trade_set_id: string;
  monitoring_finding_format: 8;
  monitoring_trade_set_id: string;
  owner: User;
  team_leads: User[];
  product_type: 'monitoring' | 'data_room' | 'tdd';
  modules: ProjectPackage.ProductType[];
  user_quota: number;
  language: 'de' | 'uk' | 'fr' | 'es' | 'it' | 'dk';
  type: 'buyer' | 'seller' | 'existing';
  with2fa: boolean;
  started_at: Date;
  created_at: Date;
}

export namespace Project {
  export interface Admin {
    user_id: string;
    user: User;
    status: string;
    role: Admin.Role;
    project_id?: string;
    asset_id?: string;
    created_at?: Date;
    is_group_lead: boolean;
    first_access_at: Date;
    last_access_at: Date;
  }

  export namespace Admin {
    export enum Role {
      Lead = 'project_lead',
      Team = 'project_team',
    }
  }

  export const transform = function <T extends Project | ProjectListItem>(project: T): T {
    return {
      ...project,
      created_at: Transform.date(project.created_at),
      owner: Transform.user(project.owner),
      team_leads: (project.team_leads || []).map(Transform.user),
    };
  };

  export type ProjectFilter = Partial<{
    search_term: string;
    search_in: SearchInItem;
    time_frame: string;
    date_from: string;
    date_to: string;
    sort_column: string;
    sort_direction: string;
    with_progress: boolean;
    progress_from: number;
    progress_to: number;
    type: ProjectProductType;
  }>;
}
