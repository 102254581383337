import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Permission } from '@core/models';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResolvedDataActions } from '../store';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionResolver implements Resolve<Permission> {
  constructor(private permissionService: PermissionService, private store: Store<unknown>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Permission> {
    return this.permissionService.getPermissions().pipe(
      tap((permission) =>
        this.store.dispatch(
          ResolvedDataActions.setPermission({
            data: { permission },
          })
        )
      )
    );
  }
}
