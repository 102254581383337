import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Group, Transform } from '@core/models';
import { PORTAL_API_URL } from '@core/services';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { ResolvedDataSelectors } from '@portal/customer/state';
import { Store } from '@ngrx/store';
import { UsersAndGroupsCacheService } from '@portal/services/users-and-groups-cache.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  readonly apiUrl = `${PORTAL_API_URL}/assets`;
  readonly http = inject(HttpClient);
  readonly store = inject(Store);
  readonly usersAndGroupsCacheService = inject(UsersAndGroupsCacheService);

  private ongoingRequests: { [key: string]: Observable<Group[]> } = {};
  private currentProjectIdSnapshot: string;
  currentGroupSnapshot: Group;

  constructor() {
    this.store
      .select(ResolvedDataSelectors.getProjectId)
      .subscribe((projectId) => (this.currentProjectIdSnapshot = projectId));

    this.store.select(ResolvedDataSelectors.getGroup).subscribe((group) => (this.currentGroupSnapshot = group));
  }

  getAssetGroups({ asset_id }: { asset_id: string }, includeManagerGroups = true): Observable<Group[]> {
    const url = `${this.apiUrl}/${asset_id}/groups`;

    return this.store
      .select(ResolvedDataSelectors.getProjectId)
      .pipe(
        switchMap((projectId) => {
          const cacheKey = `${projectId}_${url}`;
          if (this.usersAndGroupsCacheService.getGroupsCache(projectId)) {
            return of(this.usersAndGroupsCacheService.getGroupsCache(projectId));
          } else if (this.ongoingRequests[cacheKey]) {
            return this.ongoingRequests[cacheKey];
          } else {
            this.ongoingRequests[cacheKey] = this.http.get<Group[]>(url).pipe(
              map((groups) => _.map(groups, (group) => Transform.group(group))),
              tap((groups) => {
                this.usersAndGroupsCacheService.setGroupsCache(projectId, groups);
                delete this.ongoingRequests[cacheKey];
              }),
              take(1),
              shareReplay(1)
            );
            return this.ongoingRequests[cacheKey];
          }
        })
      )
      .pipe(map((groups) => (includeManagerGroups ? groups : groups.filter((group) => group.type === 'custom'))));
  }

  createAssetGroup({ asset_id }: { asset_id: string }, group: Partial<Group>): Observable<Group> {
    this.usersAndGroupsCacheService.discardGroupsCache(this.currentProjectIdSnapshot);
    const url = `${this.apiUrl}/${asset_id}/groups`;
    return this.http.post<Group>(url, group);
  }

  updateAssetGroup({ asset_id }: { asset_id: string }, group: Partial<Group>): Observable<Group> {
    this.usersAndGroupsCacheService.discardGroupsCache(this.currentProjectIdSnapshot);
    const url = `${this.apiUrl}/${asset_id}/groups/${group.id}`;
    return this.http.post<Group>(url, group);
  }

  deleteAssetGroup({ asset_id, group_id }: { asset_id: string; group_id: string }): Observable<void> {
    this.usersAndGroupsCacheService.discardGroupsCache(this.currentProjectIdSnapshot);
    const url = `${this.apiUrl}/${asset_id}/groups/${group_id}`;
    return this.http.delete<void>(url);
  }
}
