import { ICellRendererParams } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Document, Link } from '@core/models';
import { CellRendererComponent } from '@portal/components/grid';
import { ReferenceCellRendererData } from './qa-reference-cell-renderer.model';
import Type = Document.Type;

@Component({
  selector: 'x-qa-reference-cell-renderer',
  templateUrl: './qa-reference-cell-renderer.component.html',
  styleUrls: ['./qa-reference-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QAReferenceCellRendererComponent extends CellRendererComponent<ReferenceCellRendererData | null> {
  @ViewChild('menuFindingTrigger') menuFindingTrigger: MatMenuTrigger;
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  refresh(params: ICellRendererParams): boolean {
    const result = super.refresh(params);
    this.value = this.getModel(this.value);
    return result;
  }

  agInit(params: ICellRendererParams): void {
    super.agInit(params);
    this.value = this.getModel(this.value);
  }

  private getModel(data: QAReferenceCellRendererComponent['value']): QAReferenceCellRendererComponent['value'] {
    if (!data) {
      return data;
    }

    const docs = data?.links?.map((link) => Link.toShortDocument(link, this.value?.asset_id as string)) || [];
    const photos: Document.Short[] = [...docs, ...(data.documents || []), ...(data.photos || [])].filter((doc) =>
      ['photo', 'video', 'panorama'].some((type: Type) => doc?.types?.includes(type))
    );

    const documents: Document.Short[] = [
      ...docs,
      ...(data.documents || []).filter((doc) => !photos.find((photo) => doc?.id === photo?.id)),
    ].filter((doc) => !['photo', 'video', 'panorama'].some((type: Type) => doc.types?.includes(type)));

    return {
      ...data,
      photos: photos || [],
      documents: documents || [],
      pages: [...(data.pages || [])],
    };
  }
}
