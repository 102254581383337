import { DIALOG_DATA } from '@angular/cdk/dialog';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { ChangeDetectorRef, Component, inject, InjectionToken, Injector, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '@portal/components';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, shareReplay, take } from 'rxjs/operators';

export const SIDE_PANEL_DIALOG_DATA = new InjectionToken<SidePanelDialogData>('SidePanelDialogData');
export interface SidePanelDialogData<DataType = never> {
  dataSnapshot: SidePanelDialogComponentData<DataType>;
  data: Observable<SidePanelDialogComponentData<DataType>>;
  dialogRef: MatDialogRef<SidePanelDialogComponent>;
}

export interface SidePanelDialogComponentData<DataType = unknown> {
  additionalFooterActionsTemplate?: TemplateRef<never>;
  cancelBtnText?: string;
  classes?: string;
  component?: ComponentType<unknown>;
  contentTemplate?: TemplateRef<never>;
  primaryActionsTemplate?: TemplateRef<never>;
  contentTemplateContext?: unknown;
  data?: DataType;
  hideHeadingLine?: boolean;
  hideSubmit?: boolean;
  noHeadingPadding?: boolean;
  submitBtnText?: string;
  submitDisabled?: boolean;
  title?: string;
  showFooter?: boolean;
  showFooter$?: Observable<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'x-side-panel-dialog',
  templateUrl: './side-panel-dialog.component.html',
  styleUrls: ['./side-panel-dialog.component.scss'],
})
export class SidePanelDialogComponent {
  @ViewChild('discardChangesDialogContent')
  discardChangesDialogContent?: TemplateRef<HTMLElement>;

  data?: SidePanelDialogComponentData = inject(DIALOG_DATA);
  submitDisabled? = this.data?.submitDisabled;
  pendingChanges = false;

  readonly dialogRef: MatDialogRef<SidePanelDialogComponent> = inject(MatDialogRef);
  readonly dialog = inject(MatDialog);
  readonly transloco = inject(TranslocoService);
  readonly dataChangesSubject = new BehaviorSubject(this?.data);

  private readonly dataChanges$ = this.dataChangesSubject.pipe(shareReplay(1), untilDestroyed(this));
  private readonly cdr = inject(ChangeDetectorRef);

  readonly portal = this.data?.component
    ? new ComponentPortal(
        this.data.component,
        null,
        Injector.create({
          providers: [
            {
              provide: SIDE_PANEL_DIALOG_DATA,
              useValue: {
                dataSnapshot: this.data,
                data: this.dataChanges$,
                dialogRef: this.dialogRef,
              },
            },
          ],
        })
      )
    : null;

  constructor() {
    this.dataChanges$.subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  setCanCloseDialog() {
    this.pendingChanges = true;
  }

  close() {
    if (!this.pendingChanges) {
      this.dialogRef.close();
    } else {
      this.showDiscardDialog()
        .pipe(take(1), untilDestroyed(this))
        .subscribe((closeDialog) => {
          if (closeDialog) {
            this.dialogRef.close();
          }
        });
    }
  }

  private showDiscardDialog(): Observable<boolean> {
    return this.dialog
      .open(ConfirmationDialogComponent, {
        data: <ConfirmationDialogData>{
          title: `${this.transloco.translate('discard')}?`,
          template: this.discardChangesDialogContent,
          acceptButtonText: this.transloco.translate('keep-editing'),
          cancelButtonText: this.transloco.translate('discard'),
        },
      })
      .afterClosed()
      .pipe(
        map((result: boolean) => !result),
        untilDestroyed(this)
      );
  }
}
