import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Message } from '@core/models';
import { Observable } from 'rxjs';
import { PORTAL_API_URL } from './constants';

interface CommentParams {
  asset_id: string;
  message_id?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  readonly apiUrl = `${PORTAL_API_URL}/assets`;

  constructor(private http: HttpClient) {}

  add({ asset_id }: CommentParams, message: Message.NewNote): Observable<Message> {
    const url = `${this.apiUrl}/${asset_id}/messages/notes`;
    return this.http.post<Message>(url, message);
  }

  response({ asset_id, message_id }: CommentParams, answer: string): Observable<Message> {
    const url = `${this.apiUrl}/${asset_id}/messages/notes/${message_id}/response`;
    return this.http.post<Message>(url, { answer });
  }

  delete({ asset_id, message_id }: CommentParams): Observable<void> {
    const url = `${this.apiUrl}/${asset_id}/messages/${message_id}`;
    return this.http.delete<void>(url);
  }

  get({ asset_id, message_id }: CommentParams): Observable<Message> {
    const url = `${this.apiUrl}/${asset_id}/messages/${message_id}`;
    return this.http.get<Message>(url);
  }
}
