import { getAllEnumValues } from './enum';

export enum RealEstateStatus {
  Existing = 'existing',
  Development = 'development',
}

export namespace RealEstateStatus {
  export const all = getAllEnumValues<RealEstateStatus>(RealEstateStatus);
}

export enum RealEstateDevelopmentStatus {
  Planning = 'planning',
  Realization = 'realization',
}

export namespace RealEstateDevelopmentStatus {
  export const all = getAllEnumValues<RealEstateDevelopmentStatus>(RealEstateDevelopmentStatus);
}
