import { api } from '@core/models/api';
import { Group } from '@core/models/group';
import { Message } from '@core/models/message';
import { User } from '@core/models/user';

export interface PaginationResponse<T> extends Omit<api.PaginationResponse<T>, 'data'> {
  records: T[];
}

export interface PaginationRequest extends Omit<api.PaginationRequest, 'per_page'> {
  per_page?: number;
}

export type ReportType = 'updates_and_changes' | 'permission' | 'uploads';
export type AvailableStatisticScopeValue =
  | 'finding'
  | 'qa'
  | 'ticket'
  | 'report'
  | 'report_chapter'
  | 'data_room'
  | 'photo';

export type StatisticReportType =
  | 'activity'
  | 'qaActivity'
  | 'ticketActivity'
  | 'group'
  | 'users'
  | 'permissions'
  | 'qa'
  | 'ticket'
  | 'photoActivity'
  | 'photoUpdates';

export type Actions = UserActions[] | GroupActions[] | PermissionActions[];
export type StatisticSearchFilter = Partial<{
  user_id: string;
  group_ids: string[];
  actions: Actions;
  from_date: Date;
  to_date: Date;
  affected_group_ids: string[];
  affected_user_ids: string;
  report_type?: ReportType | StatisticReportType;
  scopes?: AvailableStatisticScopeValue[];
}>;

export enum UserActions {
  block_user = 'block_user',
  create_user = 'create_user',
  delete_user = 'delete_user',
  move_user = 'move_user',
  invite_user = 'invite_user',
}

export enum GroupActions {
  asset_restriction = 'asset_restriction',
  block_group = 'block_group',
  create_group = 'create_group',
  delete_group = 'delete_group',
  download_as_pdf = 'download_as_pdf',
  rename_group = 'rename_group',
  set_qa_limit_daily = 'set_qa_limit_daily',
  set_qa_limit_total = 'set_qa_limit_total',
  set_tickets_limit_daily = 'set_tickets_limit_daily',
  set_tickets_limit_total = 'set_tickets_limit_total',
  set_save_pdf_without_watermark = 'set_save_pdf_without_watermark',
  set_view_without_watermark = 'set_view_without_watermark',
}

export enum PermissionActions {
  factsheet_permission = 'factsheet_permission',
  finding_permission = 'finding_permission',
  photo_permission = 'photo_permission',
  qa_permission = 'qa_permission',
  report_permission = 'report_permission',
  user_permission = 'user_permission',
}

export enum QaActions {
  answer = 'answer',
  approve_answer = 'approve_answer',
  approve_question = 'approve_question',
  ask = 'ask',
  close = 'close',
  edit_answer = 'edit_answer',
  forward = 'forward',
  reject_answer = 'reject_answer',
  reject_question = 'reject_question',
  release = 'release',
}

export enum TicketActions {
  ask = 'ask',
  create = 'create',
  answer = 'answer',
  approve_question = 'approve_question',
  approve_ticket = 'approve_ticket',
  approve_answer = 'approve_answer',
  reject_question = 'reject_question',
  reject_ticket = 'reject_ticket',
  reject_answer = 'reject_answer',
  close = 'close',
  forward = 'forward',
  release = 'release',
  edit_answer = 'edit_answer',
  extend_due_date = 'extend_due_date',
  set_grace_period_first = 'set_grace_period_first',
  set_grace_period_second = 'set_grace_period_second',
  set_grace_period_third = 'set_grace_period_third',
  first_opened = 'first_opened',
}

export enum PhotosActions {
  created = 'created',
  published = 'published',
  tagged = 'tagged',
  deleted = 'deleted',
  view = 'view',
  save = 'save',
}

export interface QaEntry {
  action: QaActions;
  created_at: string;
  group: Group;
  group_id: string;
  id: string;
  notes: string;
  qa_message: Message;
  qa_message_id: string;
  qa_workflow_step_id: string;
  user: User;
  user_id: string;
}

export interface TicketEntry {
  id: string;
  qa_message_id: string;
  qa_message: Message;
  user_id: string;
  user: User;
  group_id: string;
  group: Group;
  qa_workflow_step_id: string;
  action: TicketActions;
  notes: string;
  category: string;
  created_at: string;
  value: string;
}

export interface StatisticEntry {
  id: string;
  project_id: string;
  asset_id: string;
  user_id: string;
  user_email: string;
  user_firstname: string;
  user_lastname: string;
  group_id: string;
  group_name: string;
  action: UserActions | GroupActions | PermissionActions;
  action_payload: string;
  src_group: string;
  src_group_id: string;
  dest_group: string;
  dest_group_id: string;
  affected_user_id: string;
  affected_user_firstname: string;
  affected_user_lastname: string;
  affected_user_email: string;
  timestamp: string;
}

export type StatisticType =
  | 'login'
  | 'activity'
  | 'activity-heatmap'
  | 'qa'
  | 'dataroom-updates'
  | 'group'
  | 'tickets'
  | 'photos-updates'
  | 'photos-activity';
