<x-dialog
  classes="w-[500px]"
  [isHeaderShowed]="false"
  [submitDisabled]="codeForm.invalid"
  submitColor="danger"
  [submitBtnText]="data.submitBtnTranslationKey"
  [submitBtnIcon]="data.submitBtnIcon"
  cancelBtnText="cancel"
  (handleClose)="onCancel()"
  (handleSubmit)="onConfirm()"
>
  <span class="mt-8 mb-3 block">
    <p class="font-semibold text-lg mb-4 text-black">{{ data.title }}</p>
    <p class="font-normal text-gray-700">
      {{ data.subtitle }}
    </p>
  </span>

  <div class="mb-4 flex flex-col items-center" [formGroup]="codeForm">
    <p
      class="w-full mb-4 text-gray-800"
      [innerHTML]="('to-proceed-enter-the-code' | transloco : { code: code }) + ':'"
    ></p>

    <div formArrayName="digits" class="flex gap-4 items-center justify-center w-full">
      <input
        *ngFor="let control of digits.controls; let i = index; trackBy: trackByIndex"
        type="text"
        maxlength="1"
        [formControlName]="i"
        class="digit-input rounded-md border border-gray-300 px-3 shadow-gray-300 shadow-sm text-gray-600 text-xl w-[42px] h-[42px] text-center"
        [ngClass]="{
          'border-danger-600':
            ((codeForm.get('digits') | formArray).hasError('codeMismatch') && !isAnyDigitEmpty()) ||
            (isAnyDigitTouched() && isAnyDigitEmpty() && !isAnyDigitFocused())
        }"
        (keydown)="handleInputKeyDown(i, $event, control)"
        (focus)="onFocus(i)"
        (blur)="onBlur(i)"
      />
    </div>
  </div>

  <div
    *ngIf="
      ((codeForm.get('digits') | formArray).hasError('codeMismatch') && !isAnyDigitEmpty()) ||
      (isAnyDigitTouched() && isAnyDigitEmpty() && !isAnyDigitFocused())
    "
    class="mt-2 text-danger-700"
  >
    <p class="text-center">
      {{ 'enter-proper-4-digit-code' | transloco }}
    </p>
  </div>
</x-dialog>
