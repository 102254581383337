import { Injectable } from '@angular/core';
import { Member, User } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class FormatUserNameService {
  format(userOrMember: User | Member | undefined): string {
    if (!userOrMember) {
      return '';
    }

    if ('user' in userOrMember) {
      return this.formatName(userOrMember.user);
    }

    return this.formatName(userOrMember as User);
  }

  private formatName(user: User): string {
    return user?.firstname || user?.lastname ? `${user.firstname || ''} ${user.lastname || ''}` : user?.email || '';
  }
}
