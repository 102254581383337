import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerModule } from '@common';

@Component({
  selector: 'x-spinner-overlay',
  standalone: true,
  imports: [CommonModule, SpinnerModule],
  templateUrl: './spinner-overlay.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpinnerOverlayComponent {
  @Input() isLoading = false;
}
