import { getAllEnumValues } from './enum';

export enum WebshopEntityType {
  Asset = 'asset',
  Portfolio = 'portfolio',
  Product = 'product',
}

export namespace WebshopEntityType {
  export const all = getAllEnumValues<WebshopEntityType>(WebshopEntityType);
}
