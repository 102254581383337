import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newLine',
})
export class NewLinePipe implements PipeTransform {
  constructor() {}

  transform(text: string | undefined): string {
    return (text || '').replace(/\n/gi, '<br>');
  }
}
