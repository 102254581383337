import { ChangeDetectionStrategy, Component, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { Norm } from '@core/models';
import { NormService } from '@core/services';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CellRendererComponent } from '../grid';

@Component({
  templateUrl: './popup-cell-renderer.component.html',
  styleUrls: ['./popup-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupCellRendererComponent extends CellRendererComponent<string> {
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  left = 0;
  top = 0;
  readonly normId$ = new BehaviorSubject<string>(null);
  readonly norm$ = this.normId$.pipe(
    switchMap((normId) => (!normId ? of(null as Norm) : this.normService.getNorm(normId))),
    catchError(() => {
      this.normId$.next(null);
      return of(null as Norm);
    }),
    tap((norm) => (norm ? this.menuTrigger?.openMenu() : this.menuTrigger?.closeMenu()))
  );

  constructor(private normService: NormService) {
    super();
  }

  onClick(event: Event): void {
    const target = event.target as HTMLAnchorElement;

    if (target?.tagName?.toLowerCase() === 'a') {
      const normId = (target.href.match(/\/norms\/(.+)/i) || [])[1];
      if (normId) {
        event.preventDefault();
        event.stopPropagation();

        this.left = target.offsetLeft;
        this.top = target.offsetTop + target.getBoundingClientRect().height;

        this.normId$.next(normId);
      }
    }
  }
}
