import { Params } from '@angular/router';
import { ActivatedRouteData } from '@core/models';
import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { isNil } from 'lodash';
import { isNotNil } from 'src/app/utils';

export enum CustomerRouterParams {
  AssetId = 'asset-id',
  DocumentType = 'document-type',
  DocumentId = 'document-id',
  MediaType = 'media-type',
  MediaId = 'media-id',
  FindingId = 'finding-id',
  ReportTemplateId = 'report-template-id',
  ChapterId = 'chapter-id',
  QaId = 'qa-id',
  TicketId = 'ticket-id',
}

export namespace CustomerRouterSelectors {
  export const selectRouter = createFeatureSelector<RouterReducerState>('router');

  const { selectRouteParam, selectQueryParams, selectUrl, selectCurrentRoute, selectRouteData } =
    getSelectors(selectRouter);

  export const getAssetId = createSelector(selectUrl, (url) => {
    const paths = _.filter(url?.split('/'), (path) => !_.isEmpty(path));
    return paths.length >= 4 ? paths[2] : null;
  });

  export const getAssetIds = createSelector(selectRouteParam(CustomerRouterParams.AssetId), (rfp_id) => rfp_id);

  export const getProjectAssetDocumentId = createSelector(
    selectRouteParam(CustomerRouterParams.DocumentId),
    (rfp_id) => rfp_id
  );

  export const getProjectAssetReportTemplateId = createSelector(
    selectRouteParam(CustomerRouterParams.ReportTemplateId),
    (report_template_id) => report_template_id
  );

  export const getMediaType = createSelector(selectRouteParam(CustomerRouterParams.MediaType), (id) =>
    id !== 'undefined' ? <'photo' | 'panorama' | 'video'>id : undefined
  );

  export const getMediaId = createSelector(selectRouteParam(CustomerRouterParams.MediaId), (id) => id);

  export const getFindingId = createSelector(selectRouteParam(CustomerRouterParams.FindingId), (id) => id);

  export const getQaId = createSelector(selectRouteParam(CustomerRouterParams.QaId), (id) => id);

  export const getTicketId = createSelector(selectRouteParam(CustomerRouterParams.TicketId), (id) => id);

  export const getChapterId = createSelector(selectRouteParam(CustomerRouterParams.ChapterId), (id) => id);

  export const getCurrentPath = createSelector(
    selectCurrentRoute,
    getMediaType,
    selectRouteData,
    (route, mediaType, data: ActivatedRouteData) => {
      if (mediaType) {
        return 'photos'; // CustomerRoute.Photos;
      }

      if (data?.currentPath) {
        return data.currentPath;
      }
      return route?.routeConfig?.path;
    }
  );

  export const getSearchQueryParams = createSelector(
    selectQueryParams,
    (params: Params, transform?: (p: Params) => Params) => {
      if (isNil(params) || Object.keys(params).length === 0) {
        return undefined;
      }

      if (typeof transform === 'function') {
        return transform(params);
      }

      const searchParams: {
        query: string | undefined;
        page: number | undefined;
      } & Params = {
        ...params,
        query: params['query'],
        page: isNotNil(params['page']) ? +params['page'] : undefined,
      };

      return searchParams;
    }
  );

  export const getQueryParameter = createSelector(
    selectQueryParams,
    (params: Params, key: string, transform?: (p: unknown) => unknown) => {
      if (isNil(params) || Object.keys(params).length === 0) {
        return undefined;
      }

      const value = params[key];

      return typeof transform === 'function' ? transform(value) : value;
    }
  );

  export const getDataRoomNodeId = createSelector(selectRouteParam('node-id'), (id) => id);
  export const getDataRoomVersioningId = createSelector(selectRouteParam('versioning-id'), (id) => id);

  export const getUrl = createSelector(selectUrl, (url) => url);
}
