import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { DropdownComponent } from '@common/components/dropdown/dropdown.component';
import { ButtonComponent } from '@common/components/button/button.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { DropdownTriggerDirective } from '@common/components/dropdown/dropdown-trigger.directive';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'x-filters-dropdown',
  templateUrl: './filters-dropdown.component.html',
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    DropdownComponent,
    ButtonComponent,
    SvgIconModule,
    DropdownTriggerDirective,
    TranslocoModule,
  ],
})
export class FiltersDropdownComponent {
  @Input() customTitle: string;
  @Input() activeFiltersCount: number;
  @Input() isDisabled = false;
  @Input() hasChanges = false;

  @Output() resetFilters = new EventEmitter();

  resetFiltersClick(event?: Event) {
    event?.stopPropagation();
    this.resetFilters.emit();
  }
}
