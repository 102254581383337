import { Component, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DestroyComponent } from '@common';
import { Document } from '@core/models';
import { AdminDocumentsService, StorageService } from '@core/services';
import { DialogActions } from '@core/state';
import { environment } from '@environments';
import { Store } from '@ngrx/store';
import { AdminDocumentsActions, AdminRouterSelectors } from '@portal/admin/store';
import { PDFHelperService } from '@portal/services';
import { PdfJsViewerComponent } from 'ng2-pdfjs-viewer';
import { combineLatest, throwError } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { isNotNil } from 'src/app/utils';
import { TranslocoService } from '@ngneat/transloco';
import { ToastService, ToastType } from '@core/toast';

@Component({
  selector: 'x-portal-document-viewer',
  templateUrl: './portal-document-viewer.component.html',
  styleUrls: ['./portal-document-viewer.component.scss'],
})
export class PortalDocumentViewerComponent extends DestroyComponent {
  @ViewChild('documentLoadErrorTemplate', { static: false }) documentLoadErrorTemplate: TemplateRef<unknown>;
  @ViewChild(PdfJsViewerComponent, { static: false }) pdfViewer: PdfJsViewerComponent;
  @ViewChild('tooltip', { static: false }) tooltip: ElementRef<HTMLElement>;

  activePageNumber = 1;
  viewerFolder = environment.production ? './static/assets/pdfjs' : './assets/pdfjs';

  readonly assetId$ = this.store.select(AdminRouterSelectors.getProjectAssetId).pipe(filter(isNotNil));
  readonly projectId$ = this.store.select(AdminRouterSelectors.getProjectId).pipe(filter(isNotNil));
  readonly documentId$ = this.store.select(AdminRouterSelectors.getProjectAssetDocumentId).pipe(filter(isNotNil));
  readonly document$ = combineLatest([this.projectId$, this.assetId$, this.documentId$]).pipe(
    switchMap(([project_id, asset_id, document_id]) =>
      this.documentsService.getDocument(project_id, asset_id, document_id)
    )
  );

  readonly file$ = combineLatest([this.projectId$, this.assetId$, this.documentId$]).pipe(
    switchMap(([project_id, asset_id, id]) => {
      const doc = { project_id, asset_id, id } as Document.ListItem;
      return this.documentsService.getNewDownloadTokenForProjectAssetDocument(doc).pipe(
        switchMap((download_token) =>
          this.storageService.downloadDocument({
            ...doc,
            download_token,
          })
        ),
        map((data) => {
          this.store.dispatch(DialogActions.closeAll());
          return {
            url: window.URL.createObjectURL(data) + window.location.hash,
            blob: data,
            type: data.type,
            id,
          };
        })
      );
    }),
    catchError((error) => {
      this.toast.showToast(this.transloco.translate('document-load-failed'), ToastType.ERROR);
      return throwError(error);
    })
  );

  constructor(
    private store: Store,
    private documentsService: AdminDocumentsService,
    private storageService: StorageService,
    private transloco: TranslocoService,
    private toast: ToastService,
    public sanitizer: DomSanitizer,
    private pdfHelper: PDFHelperService
  ) {
    super();
    this.store.dispatch(AdminDocumentsActions.fetchDocument());
    this.store.dispatch(DialogActions.showProgress());
  }

  isImage(type: string): boolean {
    return type?.indexOf('image') !== -1;
  }

  onPDFLoaded(): void {
    this.store.dispatch(DialogActions.closeAll());

    const { pdfViewer } = this;

    this.pdfHelper.replaceDownloadButton(pdfViewer);
    this.pdfHelper.addClickListener(pdfViewer, this.destroy$);
    this.pdfHelper.addNormListeners(pdfViewer, this.tooltip.nativeElement, this.destroy$);
  }

  onPageChange(pageNumber: number): void {
    this.activePageNumber = pageNumber;
  }
}
