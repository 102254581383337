import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { isNotNil } from '@app/utils';
import { CallPipeModule } from '@common';
import { ButtonComponent } from '@common/components/button/button.component';
import { DropdownTriggerDirective } from '@common/components/dropdown/dropdown-trigger.directive';
import { DropdownComponent } from '@common/components/dropdown/dropdown.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { FindingClass, Permission } from '@core/models';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  RefDocumentData,
  Reference,
  References,
  RefFindingData,
  RefOption,
  RefPhotoData,
  RefQAData,
  RefReportData,
  refSelectionName,
  RefSelectionType,
  RefTicketData,
  RefType,
} from '@portal/customer/components/attach-reference-button/reference.model';
import { CustomerRoute, CustomerRoutingService } from '@portal/customer/services';
import { CustomerRouterSelectors, ResolvedDataSelectors } from '@portal/customer/state';
import * as _ from 'lodash';
import _includes from 'lodash/includes';
import { Subject } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import { NodeLinkSelectionData } from '@portal/customer/components/asset-details/data-room';

@UntilDestroy()
@Component({
  selector: 'x-attach-reference-button',
  standalone: true,
  imports: [
    CommonModule,
    DropdownComponent,
    ButtonComponent,
    DropdownTriggerDirective,
    SvgIconModule,
    TranslocoModule,
    CallPipeModule,
    MatMenuModule,
    MatIconModule,
  ],
  templateUrl: './attach-reference-button.component.html',
  styleUrls: ['./attach-reference-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachReferenceButtonComponent implements OnDestroy {
  @ViewChild(DropdownTriggerDirective, { read: ElementRef })
  triggerButton: ElementRef | undefined;
  @Input() label = 'reference';
  @Input() disabled = false;
  @Input() singleOnly = false;
  @Input() availableOptions: string[] = [
    References.FINDING,
    References.QA,
    References.PHOTO,
    References.DOCUMENT,
    References.TICKET,
    References.REPORT,
  ];
  @Output() referenceSelected: EventEmitter<Reference[]> = new EventEmitter<Reference[]>();

  readonly permissions$ = this.store.select(ResolvedDataSelectors.getPermission);

  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId).pipe(filter(isNotNil));

  private onDestroyActions: (() => void)[] = [];

  options: RefOption[] = [
    {
      iconName: 'exclamation-triangle',
      type: References.FINDING,
      permission: 'finding',
    },
    {
      iconName: 'chat-bubble-left-right',
      type: References.QA,
      permission: 'qa',
    },
    {
      iconName: 'ticket',
      type: References.TICKET,
      permission: 'qa',
    },
    {
      iconName: 'photo',
      type: References.PHOTO,
      permission: 'photo',
    },
    {
      iconName: 'folder',
      labelTranslationKey: 'dataroom',
      type: References.DOCUMENT,
      permission: 'file',
    },
    {
      iconName: 'document-text',
      type: References.REPORT,
      permission: 'report',
    },
  ];

  constructor(
    private readonly customerRouting: CustomerRoutingService,
    readonly zone: NgZone,
    private readonly store: Store
  ) {}

  ngOnDestroy() {
    this.onDestroyActions.forEach((action) => action());
  }

  openAttachmentSelection(type: RefType) {
    switch (type) {
      case 'finding':
        this.openSelector(References.FINDING, CustomerRoute.Findings);
        break;
      case 'qa':
        this.openSelector(References.QA, CustomerRoute.QA);
        break;
      case 'ticket':
        this.openSelector(References.TICKET, CustomerRoute.Tickets);
        break;
      case 'photo':
        this.openSelector(References.PHOTO, CustomerRoute.Photos);
        break;
      case 'report':
        this.openSelector(References.REPORT, CustomerRoute.Reports);
        break;
      default:
        this.openSelector(References.DOCUMENT, CustomerRoute.DataRoom);
        break;
    }
  }

  getSelectorUrl(name: RefSelectionType, assetId: string): string {
    let url: string;
    switch (name) {
      case 'FindingSelection':
        url = `${this.customerRouting.getFindingsLink(assetId)}`;
        break;
      case 'PhotoSelection':
        url = `${this.customerRouting.getPhotoSelectionWindowLink(assetId)}`;
        break;
      case 'TicketSelection':
        url = `${this.customerRouting.getTicketsLink(assetId)}`;
        break;
      case 'QASelection':
        url = `${this.customerRouting.getQALink(assetId)}`;
        break;
      case 'ReportSelection':
        url = `${this.customerRouting.getReportsLink(assetId)}`;
        break;
      default:
        const dataroomLink = this.customerRouting.getDataRoomSourceWindowLink(assetId);
        return this.singleOnly ? `${dataroomLink}/single-source` : `${dataroomLink}/source`;
    }
    return `${url}/source`;
  }

  openSelector(refType: RefType, route: CustomerRoute) {
    this.assetId$
      .pipe(
        switchMap((assetId) => this.openWindow(route, refSelectionName.get(refType), assetId)),
        take(1),
        untilDestroyed(this)
      )
      .subscribe((selectedValue) => {
        const referenceValue = this.parseLocalStorageValue(selectedValue, refType);
        if ('length' in referenceValue) {
          if (referenceValue.length) {
            this.referenceSelected.emit(referenceValue);
            this.triggerButton.nativeElement.click();
          }
        } else if (referenceValue.ref_id) {
          this.referenceSelected.emit([referenceValue]);
          this.triggerButton.nativeElement.click();
        }
      });
  }

  parseLocalStorageValue(value: string, refType: RefType): Reference | Reference[] {
    let result: Reference | Reference[];
    switch (refType) {
      case 'photo':
        const photoSource = (value || '').split('|');
        result = {
          ref_type: refType,
          ref_id: photoSource[0],
          ref_data: {
            id: photoSource[0],
            name: photoSource[1],
          } as RefPhotoData,
        };
        break;
      case 'qa':
        const qaSource = (value || '').split('|');
        const message_id = qaSource[2];
        result = {
          ref_type: refType,
          ref_id: message_id,
          ref_data: {
            message_id,
            current_no: Number(qaSource[0]),
            message: qaSource[1],
          } as RefQAData,
        };
        break;
      case 'ticket':
        const ticketSource = (value || '').split('|');
        result = {
          ref_type: refType,
          ref_id: ticketSource[0],
          ref_data: {
            current_no: Number(ticketSource[3]),
            type: ticketSource[1],
            task: ticketSource[2],
          } as RefTicketData,
        };
        break;
      case 'finding':
        const findingSource = (value || '').split('|');
        result = {
          ref_type: refType,
          ref_id: findingSource[0],
          ref_data: {
            id: findingSource[0],
            current_no: Number(findingSource[1]),
            finding: findingSource[2],
            class: findingSource[3] as FindingClass,
          } as RefFindingData,
        };
        break;
      case 'report':
        const data = (value || '').split('|');
        result = {
          ref_type: refType,
          ref_id: data[0],
          ref_data: {
            id: data[0],
            name: data[1],
          } as RefReportData,
        };
        break;
      case 'document':
        const dataRoomSources: NodeLinkSelectionData[] = JSON.parse(value) || [];
        const references = dataRoomSources.map((source) => ({
          ref_type: refType,
          ref_id: source.nodeId,
          ref_data: {
            id: source.nodeId,
            source_page: _.isFinite(source.page) ? source.page : undefined,
            name: source.name,
            nodeId: source.nodeId,
          } as RefDocumentData,
        }));

        result = references;
        break;
    }
    return result;
  }

  isOptionVisible(ref: string): boolean {
    return this.availableOptions.includes(ref);
  }

  canView(permission: Permission, key: keyof Permission): boolean {
    return _includes(permission?.[key], 'view');
  }

  trackBy(index: number) {
    return index;
  }

  private openWindow(route: CustomerRoute, name: RefSelectionType, assetId?: string) {
    const windowClosed$ = new Subject<string | null>();

    const key = `${assetId}|${route}`;
    localStorage.removeItem(key);

    const url = this.getSelectorUrl(name, assetId);

    const height = Math.round(screen?.height * 0.6);
    const width = Math.round(screen?.width * 0.6);
    const documentWindow = window.open(url, name, `width=${width},height=${height},menubar=0,toolbar=0,status=0`);

    documentWindow.addEventListener(
      'load',
      () => {
        const onStorageEventCallback = () => {
          const selectedValue = localStorage.getItem(key);
          if (selectedValue) {
            windowClosed$.next(selectedValue);
            localStorage.removeItem(key);
            window.removeEventListener('storage', onStorageEventCallback);
          }
        };
        window.addEventListener('storage', onStorageEventCallback);
        this.onDestroyActions.push(() => {
          window.removeEventListener('storage', onStorageEventCallback);
        });
      },
      { once: true }
    );

    return windowClosed$;
  }
}
