import { Injectable } from '@angular/core';

import { ISelectOption } from '@common/components/select/select.model';
import { TimeRange } from '@core/models/time-range';
import { TranslocoService } from '@ngneat/transloco';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DateHelperService {
  timeRangeOptions$: BehaviorSubject<ISelectOption<TimeRange>[]> = new BehaviorSubject<ISelectOption<TimeRange>[]>([]);
  futureTimeRangeOptions$: BehaviorSubject<ISelectOption<TimeRange>[]> = new BehaviorSubject<
    ISelectOption<TimeRange>[]
  >([]);

  constructor(private readonly transloco: TranslocoService) {
    this.generateTimeRangeOptions();
    this.generateFutureTimeRangeOptions();
    this.watchLanguageChanges();
  }

  private watchLanguageChanges(): void {
    this.transloco.langChanges$.subscribe(() => {
      this.generateTimeRangeOptions();
    });
  }

  private generateTimeRangeOptions(): void {
    this.timeRangeOptions$.next([
      { value: 'hour', translationKey: 'time-range.past-hour' },
      { value: 'day', translationKey: 'time-range.past-24' },
      { value: 'week', translationKey: 'time-range.past-week' },
      { value: 'month', translationKey: 'time-range.past-month' },
      {
        value: 'custom',
        translationKey: `time-range.custom-range`,
      },
    ]);
  }

  private generateFutureTimeRangeOptions(): void {
    this.futureTimeRangeOptions$.next([
      { value: 'day', translationKey: 'time-range.next-24' },
      { value: 'week', translationKey: 'time-range.next-week' },
      { value: 'month', translationKey: 'time-range.next-month' },
      {
        value: 'custom',
        translationKey: `time-range.custom-range`,
      },
    ]);
  }
}
