import { inject, Injectable } from '@angular/core';
import { Document } from '@core/models';
import { iif, Observable, of } from 'rxjs';
import { isVideoExtensionsRegExp } from '@portal/components/qa-overview/qa-overview.constants';
import { PreviewDetails } from '@portal/customer/components/asset-details/asset-photo-viewer/asset-photo-viewer-container/asset-photo-viewer-container.model';
import { catchError, filter, finalize, map, mergeMap, switchMap } from 'rxjs/operators';
import {
  CustomerDocumentsService,
  DocumentsUploadSavedUploadMetadata,
  ImageSizeVersion,
} from '@portal/customer/services';
import { DownloadTokenAction } from '@portal/services/documents.service';
import { getAssetUrl } from '@common/utils/getAssetUrl';
import { ActivityStatisticsService, StorageService } from '@core/services';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { isNotNil } from '@app/utils';
import { Store } from '@ngrx/store';
import { UploadResumingService } from '@portal/customer/services/upload-resuming.service';

@Injectable({
  providedIn: 'root',
})
export class PhotosService {
  readonly fallbackImagePath = getAssetUrl('images/asset-placeholder.png');
  readonly moviePlaceholderPath = getAssetUrl('images/movie-placeholder.png');

  readonly storageService = inject(StorageService);
  readonly documentsService = inject(CustomerDocumentsService);
  readonly uploadResumingService = inject(UploadResumingService);
  readonly activityService = inject(ActivityStatisticsService);
  readonly store = inject(Store);
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId).pipe(filter(isNotNil));

  assetId: string;
  previews = {};

  constructor() {
    this.assetId$.subscribe((assetId) => (this.assetId = assetId));
  }

  resumePhotosUploadsIfExists() {
    this.uploadResumingService.resumeUploadsIfExists('photos', (savedUpload) =>
      this.documentsService.startUpload(savedUpload as DocumentsUploadSavedUploadMetadata)
    );
  }

  extractUrl(input: string): string {
    const regex = /^url\(['"]?(.*?)['"]?\)$/;
    const match = input.match(regex);
    return match ? match[1] : input;
  }

  getAndCachePhoto = (
    photo: Document.ListItem,
    size: 'small' | 'large' = 'small',
    rawUrl = false,
    skipChecks = false,
    forceUsingExistingToken = false
  ) => {
    if (!photo) {
      return of(null);
    }

    const cacheKey = (photo.id || photo.download_token) + `-${size}`;

    if ((!skipChecks && photo.types?.includes('video')) || isVideoExtensionsRegExp.test(photo.name)) {
      return of(rawUrl ? this.moviePlaceholderPath : `url('${this.moviePlaceholderPath}')`);
    }

    if (!skipChecks && photo.conversion_status !== 'finished') {
      return of(rawUrl ? this.fallbackImagePath : `url('${this.fallbackImagePath}')`);
    }

    const preview = this.previews[cacheKey] || (this.previews[cacheKey] = {} as PreviewDetails);

    const image = preview?.preview ? this.extractUrl(preview.preview) : undefined;
    let loading$ = preview.loadingPreview;

    if (image) {
      return of(rawUrl ? image : `url(${image})`);
    } else if (loading$) {
      return loading$ as Observable<string>;
    }

    const token$ =
      (photo.download_token && size === 'small') || !photo.id || forceUsingExistingToken
        ? of(photo.download_token)
        : this.newDownloadTokenGetter(photo.id, { version: size === 'small' ? 360 : 1600 });

    loading$ = token$.pipe(
      mergeMap((download_token) =>
        this.storageService.getImageWithDownloadToken({ download_token }, false, cacheKey).pipe(
          catchError(() => {
            if (photo.download_token && size === 'small') {
              return this.newDownloadTokenGetter(photo.id, { version: 360 }).pipe(
                switchMap((download_token) =>
                  this.storageService.getImageWithDownloadToken({ download_token }, false, cacheKey)
                ),
                catchError(() => of(undefined))
              );
            } else {
              return of(undefined);
            }
          }),
          switchMap((photoBlob: Blob) =>
            iif(
              () => size === 'large',
              this.activityService
                .trackActivity(this.assetId, {
                  action: 'view',
                  scope: 'photo',
                  document_id: photo.id,
                })
                .pipe(
                  map(() => photoBlob),
                  catchError(() => of(photoBlob))
                ),
              of(photoBlob)
            )
          ),
          map((photoBlob) => {
            try {
              if (photoBlob) {
                preview.preview = rawUrl
                  ? window.URL.createObjectURL(photoBlob)
                  : `url('${window.URL.createObjectURL(photoBlob)}')`;
              } else {
                preview.preview = rawUrl ? this.fallbackImagePath : `url('${this.fallbackImagePath}')`;
              }
            } catch {
              preview.preview = rawUrl ? this.fallbackImagePath : `url('${this.fallbackImagePath}')`;
            }

            return preview.preview;
          }),
          finalize(() => {
            preview.loadingPreview = null;
          })
        )
      )
    );

    preview.loadingPreview = loading$;

    return loading$;
  };

  readonly newDownloadTokenGetter = (
    document_id: string,
    { version, action }: { version?: ImageSizeVersion; action?: DownloadTokenAction } = {}
  ) =>
    this.documentsService.getNewDownloadTokenForAssetDocument(
      {
        asset_id: this.assetId,
        id: document_id,
      },
      { version, action }
    );
}
