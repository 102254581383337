import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CellRendererComponent } from '../cell-renderer';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'x-non-published-cell-renderer',
  standalone: true,
  imports: [CommonModule, TranslocoModule],
  templateUrl: './non-published-cell-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NonPublishedCellRendererComponent extends CellRendererComponent {}
