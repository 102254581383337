<label *ngIf="labelPosition === 'above'" [attr.for]="id" class="block text-sm font-medium text-gray-700">
  {{ label }}
  <span *ngIf="required" class="text-danger-500 inline-block">*</span>
</label>
<div
  [ngClass]="{ 'mt-1': !!label && labelPosition === 'above' }"
  class="flex flex-col items-start relative"
  (clickOutside)="clickOutside.emit($event)"
>
  <label
    *ngIf="labelPosition === 'inline'"
    class="control-inline-label inline-block text-black font-medium text-base mr-2.5"
  >
    {{ label }}
    <span *ngIf="required" class="text-danger-500 inline-block">*</span>
  </label>
  <div class="flex align-center flex-1 w-full">
    <x-input *ngIf="prefixInputText" class="pr-2" [(value)]="prefixInputText" [disabled]="true" name="title"></x-input>
    <input
      class="block w-full appearance-none rounded-md px-3 py-2 placeholder-gray-400 shadow-sm
            focus:border-primary-500 focus:outline-none focus:ring-primary-500 text-sm {{ nativeInputClasses || '' }}"
      [attr.id]="id"
      [attr.name]="name"
      [attr.type]="isPasswordShowed ? 'text' : type"
      [attr.autocomplete]="autocomplete"
      [attr.placeholder]="placeholder"
      [attr.min]="min"
      [attr.max]="max"
      [attr.required]="required"
      [attr.maxlength]="maxlength"
      [formControl]="control"
      (click)="inputClick.emit($event)"
      (change)="onValueChange($event)"
      (input)="onInput($event)"
      (keydown)="onKeydown($event)"
      (blur)="onInputBlur()"
      [ngClass]="{
        'border-gray-300': !((control.invalid || (control.errors || [] | keyvalue).length > 0) && control.touched),
        'border-danger-400': (control.invalid || (control.errors || [] | keyvalue).length > 0) && control.touched,
        'text-gray-400 cursor-not-allowed bg-gray-100': control.disabled,
        'pr-10': getShowActionButtonsContainer(),
        'pl-10': leftSideIcon
      }"
    />
  </div>

  <div class="flex space-x-2 absolute top-2 right-2" *ngIf="getShowActionButtonsContainer">
    <div *ngIf="isTogglePasswordVisibilityButtonShowed" class="cursor-pointer" (click)="onPasswordShowToggle($event)">
      <x-svg-icon [svgImageName]="isPasswordShowed ? 'eye-slash' : 'eye'" svgClass="w-6 h-6 text-gray-400"></x-svg-icon>
    </div>
    <div
      class="cursor-pointer"
      (click)="onResetClick()"
      *ngIf="forceShowClearButton || (showClearButton && control.value?.length > 0)"
    >
      <x-svg-icon svgImageName="cross" svgClass="w-6 h-6 text-gray-400 rounded-xl hover:bg-gray-200"></x-svg-icon>
    </div>
    <x-svg-icon
      *ngIf="rightSideIcon"
      [svgImageName]="rightSideIcon"
      svgClass="w-5 h-5 text-gray-400"
      (click)="rightSideIconClick.emit($event)"
    ></x-svg-icon>
  </div>
  <div
    class="absolute flex items-center h-full top-0 left-3"
    *ngIf="leftSideIcon"
    (click)="leftSideIconClick.emit($event)"
  >
    <x-svg-icon [svgImageName]="leftSideIcon" svgClass="w-5 h-5 text-gray-400"></x-svg-icon>
  </div>
  <span
    *ngIf="control.invalid && control.touched && errorsKeysToDisplay"
    class="flex flex-column font-medium tracking-wide text-danger-400 text-xs mt-0.5 ml-0.5"
  >
    <span *ngFor="let error of errorsKeysToDisplay; trackBy: trackByErrorTranslationKey">
      {{ error.translationKey | transloco : error.errorDetails }}
    </span>
  </span>
</div>
