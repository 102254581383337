import { ProjectListItem } from '@core/models';
import { EntityState } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as _ from 'lodash';
import { adapter as projectsAdapter, PortalProjectsReducer, ProjectAdapterEntity } from './portal-projects.reducer';

const projectsState = createFeatureSelector<EntityState<ProjectAdapterEntity>>(PortalProjectsReducer.name);
const { selectAll, selectEntities } = projectsAdapter.getSelectors(projectsState);

export namespace ProjectsSelectors {
  export const getAll = createSelector(
    selectAll,
    (entities) => _.map(entities, ({ project }) => project) as ProjectListItem[]
  );

  export const getProjects = createSelector(selectEntities, (entities) =>
    _(entities)
      .mapValues((entity) => entity?.project)
      .value()
  );

  export const getLogoUris = createSelector(selectEntities, (entities) =>
    _(entities)
      .mapValues((entity) => entity?.logoUri)
      .value()
  );
}
