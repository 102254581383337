import { ChangeDetectionStrategy, Component, Inject, TemplateRef } from '@angular/core';
import {
  MatLegacySnackBarRef as MatSnackBarRef,
  MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA,
} from '@angular/material/legacy-snack-bar';
import * as _ from 'lodash';
import { AlertDialogData, AlertType } from '../alert-dialog';

export type SnackBarComponentData = Pick<AlertDialogData, 'type' | 'text' | 'template' | 'error' | 'context'>;

@Component({
  selector: 'x-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackBarComponent {
  readonly AlertType = AlertType;

  get type(): AlertType {
    if (this.data.type !== AlertType.Error) {
      setTimeout(() => this.snackBarRef.dismiss(), 3000);
    }
    return this.data && this.data.type;
  }

  get text(): string | undefined {
    return this.data?.text;
  }

  get messageTemplate(): TemplateRef<unknown> | undefined {
    if (_.isFunction(this.data?.template)) {
      return this.data.template();
    }

    return undefined;
  }
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarComponentData,
    public snackBarRef: MatSnackBarRef<SnackBarComponent>
  ) {}
}
