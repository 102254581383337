import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

const paddingClasses = 'pt-6 px-6 pb-3 rounded-lg lg:px-8 lg:pt-12';
export type CardVariant =
  | 'shadow'
  | 'primary'
  | 'alternative'
  | 'borderless'
  | 'thin'
  | 'extra_thin'
  | 'bordered'
  | 'report_block';

@Component({
  selector: 'x-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CardComponent {
  @Input() title = '';
  @Input() titleClasses = 'text-gray-900 text-xl';
  @Input() variant?: CardVariant = 'bordered';
  @Input() classes?: string;
  @Input() topContainerClass?: string;

  readonly variantClasses = {
    shadow: 'bg-white shadow rounded-lg',
    thin: 'bg-white rounded-lg border border-gray-200',
    extra_thin: 'bg-white rounded-lg border border-gray-200',
    primary: `bg-primary-700`,
    alternative: `bg-white ring-2 ring-primary-700`,
    borderless: 'bg-transparent',
    bordered: 'bg-white rounded-lg border border-gray-200',
    report_block: 'py-4',
  };

  readonly variantPaddings = {
    default: 'p-8',
    thin: 'p-5',
    extra_thin: 'px-4 py-3.5',
    primary: paddingClasses,
    alternative: paddingClasses,
    borderless: '',
    bordered: 'p-6 pb-2',
    report_block: 'pt-4 py-4',
  };
}
