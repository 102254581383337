<span class="icon-font icon-font-close" mat-dialog-close></span>

<div class="container">
  <ng-container *ngIf="!recoveryMode; else recoveryTemplate">
    <div class="title">{{ 'log-in' | transloco }}</div>

    <form #form="ngForm" class="signin" (ngSubmit)="doSignIn()">
      <div class="sigin-inputs">
        <input
          type="email"
          placeholder="{{ 'email' | transloco }}"
          required
          name="email"
          autocomplete="email"
          [(ngModel)]="model.username"
        />

        <input
          type="password"
          placeholder="{{ 'password' | transloco }}"
          required
          name="password"
          autocomplete="current-password"
          [(ngModel)]="model.password"
        />
      </div>

      <div class="link-container">
        <a class="link-recovery" [class.disabled]="inProgress" (click)="onPasswordRecovery()">
          {{ 'password-recovery' | transloco }}
        </a>
      </div>

      <button type="submit" class="accent next progress" [class.progress]="inProgress">
        <span>{{ 'sign-in' | transloco }}</span>
      </button>
    </form>

    <!-- Sign Up is not available now
    <div class="title">{{ 'no-account-yet' | transloco }}</div>
    <div>
      <button class="next register"
        mat-dialog-close

        [disabled]="inProgress"
        [routerLink]="'/auth/signup'">{{ 'register' | transloco }}</button>
    </div>
    -->
  </ng-container>
</div>

<ng-template #recoveryTemplate>
  <div class="title">{{ 'password-recovery' | transloco }}</div>
  <div class="description">
    {{ 'to-reset-your-password-submit-your-email-address-and-then-check-your-email-inbox' | transloco }}
  </div>
  <form #form="ngForm" (ngSubmit)="onSendRecoveryEmail()">
    <input type="email" placeholder="{{ 'email' | transloco }}" required name="email" [(ngModel)]="model.username" />
    <button class="next accent" type="suibmit" [class.progress]="inProgress">
      <span>{{ 'next' | transloco }}</span>
    </button>
  </form>
  <div class="link-container">
    <a class="link-recovery" [class.disabled]="inProgress" (click)="onPasswordRecovery(false)">
      {{ 'back' | transloco }}
    </a>
  </div>
</ng-template>

<ng-template #messageTemplatePasswordResetRequest>
  <span>{{ 'check-you-email-for-the-instructions' | transloco }}</span>
</ng-template>
