import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Permission } from '@core/models';
import { PORTAL_API_URL } from '@core/services';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  constructor(private readonly http: HttpClient) {}

  getPermissions(): Observable<Permission> {
    return this.http.get<Permission>(`${PORTAL_API_URL}/admin/permission`).pipe(
      map((permissions) => _.mapValues(permissions, (keys) => _.uniq(keys)) as Permission),
      catchError(() => of(<Permission>{}))
    );
  }
}
