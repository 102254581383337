import { ErrorHandler, inject } from '@angular/core';
import { Notifier } from '@airbrake/browser';
import { environment } from '@environments';
import { UserService } from '@core/services';

export class AirbrakeErrorHandler implements ErrorHandler {
  airbrake: Notifier;
  userService = inject(UserService);

  constructor() {
    this.airbrake = new Notifier({
      projectId: 573910,
      projectKey: '3eec0a1fc2429610344d248ba7c20e8f',
      environment: environment.production ? 'production' : 'local',
    });

    this.airbrake.addFilter((notice) => {
      notice.context.sourceMaps = {
        '**/main.%.js': 'main.js.map',
      };
      return notice;
    });
  }

  handleError(error: unknown): void {
    console.error(error);
    const user = this.userService.user$.value;
    const errorPayload = {
      error,
      context: {
        userAgent: navigator.userAgent,
        url: window.location.href,
        sourceMaps: {},
      },
      environment: {
        platform: navigator.platform,
        language: navigator.language,
      },
      session: {
        user: user
          ? {
              id: user.id,
              name: `${user.firstname} ${user.lastname}`,
              email: user.email,
            }
          : undefined,
      },
    };
    if (environment.production) {
      this.airbrake.notify(errorPayload);
    }
  }
}
