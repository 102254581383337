<div
  *ngIf="params?.['iconName'] as svgImageName"
  class="flex items-center justify-center rounded-lg w-9 h-9 hover:bg-gray-200 cursor-pointer"
  (click)="handleClick($event)"
>
  <x-svg-icon
    class="text-gray-700"
    svgClass="w-5 h-5 {{params?.['svgClass'] || '' }}"
    [svgImageName]="svgImageName"
    [matTooltip]="params?.['tooltipTranslationKey'] ? (params?.['tooltipTranslationKey'] | transloco) : undefined"
    [matTooltipShowDelay]="500"
    [matTooltipPositionAtOrigin]="true"
  ></x-svg-icon>
</div>
