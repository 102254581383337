import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CustomerTracker, Transform } from '@core/models';
import { PORTAL_API_URL } from '@core/services';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class TrackersService {
  readonly apiUrl = `${PORTAL_API_URL}/admin`;

  constructor(private http: HttpClient) {}

  fetchCustomerTrackers(assetId: string): Observable<CustomerTracker[]> {
    const url = `${PORTAL_API_URL}/assets/${assetId}/trackers`;
    return this.http.get<CustomerTracker[]>(url).pipe(
      map((trackers) => _.map(trackers, (tracker) => Transform.customerTracker(tracker))),
      catchError((error) => {
        console.error(error);
        return of([]);
      })
    );
  }
}
