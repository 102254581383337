import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { CallPipeModule, FileSizePipeModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { FileIconModule } from '@portal/customer/components/asset-details/data-room/file-icon/file-icon.module';
import { LocaleNumberPipeModule } from 'src/app/common/pipes/locale-number';
import { FilesTreeComponent } from './files-tree.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { MultiSelectComponent } from '@common/components/multi-select/multi-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ResizableColumnDirective } from '@common/directives/resizable-column/resizable-column.directive';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ScrollingModule,
    MatIconModule,
    FileSizePipeModule,
    CallPipeModule,
    TranslocoModule,
    LocaleNumberPipeModule,
    FileIconModule,
    SvgIconModule,
    MultiSelectComponent,
    MatTooltipModule,
    ResizableColumnDirective,
  ],
  declarations: [FilesTreeComponent],
  exports: [FilesTreeComponent],
})
export class FilesTreeModule {}
