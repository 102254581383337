import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, TemplateRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { api } from '@core/models';
import * as _ from 'lodash';
import { timer } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DestroyComponent } from '../destroy.component';

export enum AlertType {
  Info = 'info',
  Success = 'success',
  Error = 'error',
  Progress = 'progress',
  Default = 'default',
}

export interface AlertDialogData extends Partial<Omit<api.ErrorResponse<unknown>, 'type'>> {
  context?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  // error?: ;
  minWidth?: number;
  template?: () => TemplateRef<unknown> | undefined;
  text?: string;
  type: AlertType;
  updateTime?: number;
  width?: number;
}

@Component({
  selector: 'x-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertDialogComponent extends DestroyComponent {
  @HostBinding('style.min-width.px')
  minWidth: number;
  @HostBinding('style.width.px')
  width: number;

  readonly AlertType = AlertType;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogData,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<unknown, unknown>
  ) {
    super();

    if (this.data?.updateTime) {
      timer(0, this.data.updateTime)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: () => this.cdr.markForCheck(),
        });
    }

    this.minWidth = data?.minWidth;
    this.width = data?.width;
  }

  @HostBinding('class')
  get class(): string {
    const hasText = this.text || this.messageTemplate;
    return `${this.type} ${hasText ? '' : 'no-text'}`;
  }

  get messageTemplate(): TemplateRef<unknown> {
    if (!this.data) {
      return null;
    }

    if (_.isFunction(this.data.template)) {
      return this.data.template();
    }

    return null;
  }

  get text(): string {
    return this.data?.text;
  }

  get type(): AlertType {
    return this.data && this.data.type;
  }
}
