import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import {
  CallPipeModule,
  DocumentDetailsPipeModule,
  DocumentPhotoPipeModule,
  FindingLabelModule,
  LocaleCurrencyPipeModule,
  LocaleDatePipeModule,
  SafePipeModule,
  SnackBarModule,
  ValidationErrorLabelModule,
} from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { TradePipeModule } from '@common/pipes/trade';
import { FindingsService } from '@portal/customer/services';
import { FindingDetailsComponent } from './finding-details.component';
import { TagsInputComponent } from '@portal/components/tags-input/tags-input.component';
import { TextareaComponent } from '@portal/components/textarea/textarea.component';
import { InputComponent } from '@common/components/input/input.component';
import { SelectComponent } from '@common/components/select/select.component';
import { RiskPillComponent } from '@common/components/risk-pill/risk-pill.component';
import { ButtonComponent } from '@common/components/button/button.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { DateInputModule } from '@portal/components/date-input/date-input.module';
import { MatMenuModule } from '@angular/material/menu';
import { HyphenateDirective } from '@common/directives/hyphenate/hyphenate.directive';
import { FileIconModule } from '@portal/customer/components/asset-details/data-room/file-icon/file-icon.module';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    TranslocoModule,
    MatSelectModule,
    FormsModule,
    MatIconModule,
    MatButtonModule,
    CallPipeModule,
    FindingLabelModule,
    ValidationErrorLabelModule,
    TradePipeModule,
    DocumentDetailsPipeModule,
    DocumentPhotoPipeModule,
    DateInputModule,
    SnackBarModule,
    TagsInputComponent,
    TextareaComponent,
    InputComponent,
    SelectComponent,
    LocaleDatePipeModule,
    RiskPillComponent,
    LocaleCurrencyPipeModule,
    ButtonComponent,
    SvgIconModule,
    SafePipeModule,
    MatMenuModule,
    HyphenateDirective,
    FileIconModule,
    MatTooltipModule,
  ],
  declarations: [FindingDetailsComponent],
  providers: [FindingsService],
  exports: [FindingDetailsComponent],
})
export class FindingDetailsModule {}
