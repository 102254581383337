import * as _ from 'lodash';

export interface Permission {
  factsheet?: Permission.Rights[];
  user?: Permission.Rights[];
  qa?: Permission.Rights[];
  ticket?: Permission.Rights[];
  finding?: Permission.Rights[];
  report?: Permission.Rights[];
  file?: (Permission.Rights | Permission.MediaRights)[];
  photo?: (Permission.Rights | Permission.MediaRights)[];
  admin_project?: Permission.Rights[];
  admin_user?: Permission.Rights[];
  statistic?: Permission.Rights[];
  root_settings?: Permission.Rights[];
  location?: Permission.Rights[];
  setting?: Permission.Rights[];
  expose?: Permission.Rights[];

  // [key: string]: (Permission.Rights | Permission.MediaRights)[];
}

export namespace Permission {
  export type Rights = 'view' | 'write' | 'del' | 'approve';
  export type MediaRights = 'photo' | 'video' | 'panorama';

  export const hasPermission = (
    permissions: Permission,
    module: keyof Permission,
    right: Permission.Rights
  ): boolean => {
    if (!permissions) {
      return false;
    }
    return _.includes(permissions[module], right);
  };

  export const defaultPermissions = {
    factsheet: ['view'],
    qa: ['view', 'write'],
    file: ['view'],
    ticket: ['view'],
  };

  // These are always enabled and disabled from changing
  export const blockedPermissions: Permission = {
    ticket: ['view'],
  };

  export const disabledPermissions: Permission = {
    expose: ['approve', 'del'],
  };

  export const getDefault = (): Permission => ({
    factsheet: [],
    user: [],
    qa: [],
    ticket: [],
    finding: [],
    report: [],
    statistic: [],
    file: [],
    photo: [],
    expose: [],
  });
}
