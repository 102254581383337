import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { CellRendererComponent } from '@portal/components/grid/cell-renderer';
import { ShortUser } from '@core/models';
import { FormattedMemberNamePipe } from '@common/pipes/format-name/format-name.component';
import { UserAvatarComponent } from '@common/components/user-avatar/user-avatar.component';
import { UserInitialsPipe } from '@common/pipes/user-initials.pipe';

export interface UsernameRendererData {
  user: ShortUser;
  fallbackName: string;
}

@Component({
  selector: 'x-username-renderer',
  standalone: true,
  imports: [BadgeComponent, NgIf, FormattedMemberNamePipe, UserAvatarComponent, UserInitialsPipe],
  templateUrl: './username-renderer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsernameCellRendererComponent extends CellRendererComponent<UsernameRendererData> {
  @Input() value: UsernameRendererData;
}
