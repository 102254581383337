import { ChangeDetectionStrategy, Component, HostBinding, inject, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Document, Finding } from '@core/models';
import { Observable } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PhotosService } from '@portal/customer/components/asset-details/asset-photo-viewer/photos.service';

export interface MediaViewerPopupData {
  assetId: string;
  document: Document.ListItem;
  hideDetails?: boolean;
}

@UntilDestroy()
@Component({
  selector: 'x-media-viewer-popup',
  templateUrl: './media-viewer-popup.component.html',
  styleUrls: ['./media-viewer-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MediaViewerPopupComponent {
  readonly photoService = inject(PhotosService);
  readonly size: { width: number; height: number };

  selectedFinding$?: Observable<Finding>;

  @HostBinding('style.height.px')
  get height(): number {
    return this.size.height;
  }

  @HostBinding('style.width.px')
  get width(): number {
    return this.size.width;
  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: MediaViewerPopupData) {
    const height = Math.max(600, Math.round(screen?.height * 0.7));
    const width = Math.min(Math.round((height * 4) / 3), screen?.width);

    this.size = { height, width };
  }
}
