import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isoDate',
})
export class ISODatePipe implements PipeTransform {
  transform(date: Date): string {
    return date?.toISOString();
  }
}
