<ng-container [ngSwitch]="type">
  <ng-container *ngSwitchCase="AreaStandard.AEO">{{ 'aeo' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AreaStandard.Boma">{{ 'boma' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AreaStandard.Brutto">{{ 'brutto' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AreaStandard.Custom">{{ 'custom' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AreaStandard.DIN277">{{ 'din-277' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AreaStandard.GIF">{{ 'gif' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AreaStandard.IPMS">{{ 'ipms' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AreaStandard.NEN">{{ 'nen' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AreaStandard.Unknown">{{ 'unknown' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ type }}</ng-container>
</ng-container>
