import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipEditedEvent, MatChipInputEvent, MatChipsModule } from '@angular/material/chips';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslocoModule } from '@ngneat/transloco';
import { EMAIL_VALIDITY_PATTERN } from '@app/utils/email';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import { Member } from '@core/models';
import _isEqual from 'lodash/isEqual';

export interface MemberEmail {
  email: string;
}

export interface MemberFieldErrors {
  incorrect_address: boolean;
  has_already_added_members: boolean;
}

@Component({
  selector: 'x-add-member-field',
  standalone: true,
  imports: [CommonModule, MatFormFieldModule, MatChipsModule, SvgIconModule, MatTooltipModule, TranslocoModule],
  templateUrl: './add-member-field.component.html',
  styleUrls: ['./add-member-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddMemberFieldComponent {
  @Input() alreadyAddedMembers: Member[] = [];
  @Input() errorMessage = '';
  @Input() members: MemberEmail[] = [];
  @Input() placeholder = '';

  @Output() errors = new EventEmitter<MemberFieldErrors | undefined>();
  @Output() membersChange = new EventEmitter<MemberEmail[]>();

  private readonly cdr = inject(ChangeDetectorRef);
  private validityErrors: MemberFieldErrors | undefined;

  readonly separatorKeysCodes = [ENTER, COMMA, SPACE] as const;
  readonly entersCommasSpacesRegex = /([\n,\s])/g;

  add(event: MatChipInputEvent): void {
    const values = (event.value || '').split(this.entersCommasSpacesRegex);
    if (values.length) {
      values
        .map((value) => value.replace(this.entersCommasSpacesRegex, ''))
        .filter(Boolean)
        .forEach((value) => {
          this.members.push({ email: value });
        });
    }
    event.chipInput?.clear();
    this.validate();
    this.cdr.markForCheck();
  }

  remove(member: MemberEmail): void {
    const index = this.members.indexOf(member);
    if (index >= 0) {
      this.members.splice(index, 1);
    }
    this.validate();
    this.cdr.markForCheck();
  }

  edit(member: MemberEmail, event: MatChipEditedEvent) {
    const value = event.value.trim();
    if (!value) {
      this.remove(member);
      return;
    }
    const index = this.members.indexOf(member);
    if (index >= 0) {
      this.members[index].email = value;
    }
    this.validate();
  }

  isAlreadyAMemberOfTheAsset(invitedUserEmail: string) {
    return !!this.alreadyAddedMembers.find((member) => member.user?.email === invitedUserEmail);
  }

  isEmailValid(invitedUserEmail: string) {
    return EMAIL_VALIDITY_PATTERN.test(invitedUserEmail);
  }

  trackByFn(index: number) {
    return index;
  }

  private hasIncorrectAddresses() {
    return this.members.some(
      (member) => !this.isEmailValid(member.email) || this.isAlreadyAMemberOfTheAsset(member.email)
    );
  }

  private validate() {
    const memberFieldErrors: MemberFieldErrors = {
      has_already_added_members: this.members.some((member) => this.isAlreadyAMemberOfTheAsset(member.email)),
      incorrect_address: this.hasIncorrectAddresses(),
    };
    const hasErrors = Object.values(memberFieldErrors).some((val) => val);
    const validityErrorsUpdate = hasErrors ? memberFieldErrors : undefined;

    this.membersChange.emit(this.members);
    if (!_isEqual(validityErrorsUpdate, this.validityErrors)) {
      this.validityErrors = validityErrorsUpdate;
      this.errors.emit(validityErrorsUpdate);
    }
  }
}
