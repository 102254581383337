<div class="relative flex flex-row text-left">
  <button
    type="button"
    (click)="toggleNotifications()"
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    class="inline-flex relative items-center rounded-full border border-gray-100 bg-white-600 p-2 text-gray-500 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
  >
    <x-svg-icon [svgImageName]="'bell'" svgClass="text-gray-400 h-6 w-6"></x-svg-icon>
    <span
      *ngIf="(unreadNotifications$ | async)?.length; let unreadNotificationsNumber"
      class="flex items-center justify-center absolute w-5 h-5 text-[10px] rounded-full bg-danger-500 text-white top-0 right-0 border-2 border-white"
    >
      {{ unreadNotificationsNumber }}
    </span>
  </button>
</div>

<ng-template
  cdkConnectedOverlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayOrigin]="trigger"
  [cdkConnectedOverlayOpen]="isOpen"
  (detach)="isOpen = false"
  (backdropClick)="isOpen = false"
>
  <x-notification-list
    [notifications]="$any(notifications$ | async)"
    (actionClicked)="isOpen = false"
  ></x-notification-list>
</ng-template>
