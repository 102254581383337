import { Component, Input } from '@angular/core';

@Component({
  selector: 'x-side-panel-button',
  templateUrl: './side-panel-button.component.html',
  styleUrls: ['./side-panel-button.component.scss'],
})
export class SidePanelButtonComponent {
  @Input() icon?: string;
  @Input() matIcon?: string;
}
