import { Finding, FindingMonitoring, FindingTDD, Risk } from '@core/models';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FindingService {
  static splitFindings(findings: Finding[] = []): {
    high: Finding[];
    lowAndMedium: Finding[];
    guidance: Finding[];
    open: Finding[];
    discussion: Finding[];
    closed: Finding[];
  } {
    const tddFindings = findings.filter((finding) => finding.class === 'tdd') as FindingTDD[];
    const monitoringFindings = findings.filter((finding) => finding.class === 'monitoring') as FindingMonitoring[];
    const high = tddFindings.filter((finding) => finding.risk_evaluation === Risk.High);
    const lowAndMedium = tddFindings.filter(
      (finding) => finding.risk_evaluation === Risk.Medium || finding.risk_evaluation === Risk.Low
    );
    const guidance = findings.filter((finding) => !!finding.guidance);
    const open = monitoringFindings.filter((finding) => finding.status === 'open');
    const discussion = monitoringFindings.filter((finding) => finding.status === 'discussion');
    const closed = monitoringFindings.filter((finding) => finding.status === 'closed');

    return { high, lowAndMedium, guidance, open, discussion, closed };
  }

  getFindingFromShort(finding: Finding.Short): Observable<Finding> {
    throw new Error('Not Implemented');
  }
}
