<div class="flex flex-1 flex-row space-between py-1.5">
  <div class="flex w-8">
    <x-svg-icon
      class="w-4 h-4 text-gray-600"
      [svgImageName]="message.category === 'notes' || message.category === 'note' ? 'pencil-square' : 'annotation'"
    ></x-svg-icon>
  </div>
  <div class="flex flex-col flex-1">
    <span
      *ngIf="message?.message"
      class="text-sm font-semibold text-black break-words line-clamp-6"
      [title]="message?.message"
      [innerHtml]="message?.message | newLine"
    ></span>
    <div class="flex-1 text-xs text-gray-600 break-words">
      <ng-container *ngIf="messagesLabelTemplate">
        <ng-container *ngTemplateOutlet="messagesLabelTemplate; context: { $implicit: message }"></ng-container>
      </ng-container>
    </div>
    <div class="text-xs text-gray-600">
      {{ (message?.message_user | userName) + ' · ' + (message?.created_at | localeDate) }}
    </div>
  </div>
  <div class="flex flex-col items-end space-between">
    <div class="flex flex-row items-start space-x-2">
      <x-message-circles class="pr-1.5" *ngIf="message?.category !== 'qa'" [messages]="[message]"></x-message-circles>
      <x-badge
        *ngIf="message?.priority && message?.category === 'qa'"
        [badgeType]="getBadgeType | call : message"
        [text]="message?.priority | transloco"
      ></x-badge>
    </div>
    <div
      *ngIf="
        !frozen &&
        (message.category === 'notes' || message.category === 'note') &&
        message.message_user.id === currentUserId
      "
      class="rounded-xl p-2"
    >
      <x-spinner *ngIf="isDeleting; else deleteIcon" size="small"></x-spinner>
    </div>
    <ng-template #deleteIcon>
      <x-svg-icon
        class="cursor-pointer text-gray-500 hover:bg-gray-300"
        svgImageName="trash"
        svgClass="w-4 h-4"
        (click)="onDelete($event)"
      ></x-svg-icon>
    </ng-template>
  </div>
</div>
