import { LoadingOverlayComponent as BaseLoadingOverlayComponent } from '@ag-grid-community/core/dist/es6/rendering/overlays/loadingOverlayComponent';
import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';

@Component({
  selector: 'x-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingOverlayComponent extends BaseLoadingOverlayComponent {
  @HostBinding('class')
  readonly class = 'ag-overlay-loading-center';

  init(): void {}

  agInit(): void {}
}
