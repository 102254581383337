<span *ngIf="label" class="text-xs font-semibold leading-5 mr-2 text-gray-500">{{ label }}</span>
<span
  class="flex flex-row text-ellipsis select-none"
  [title]="text || ''"
  [ngClass]="getBadgeClass(badgeType)"
  [class]="classes"
>
  <ng-content></ng-content>
  <ng-container>{{ text }}</ng-container>
  <ng-container *ngIf="withRemoveButton">
    <div class="ml-0.5 cursor-pointer" (click)="onRemove($event)">
      <x-svg-icon svgImageName="cross" svgClass="w-4 h-4 text-gray-500 rounded-xl hover:bg-gray-300"></x-svg-icon>
    </div>
  </ng-container>
</span>
