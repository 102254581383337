import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NewLinePipeModule, SafePipeModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslocoModule,
    NewLinePipeModule,
    SafePipeModule,
    DialogComponent,
    SvgIconModule,
  ],
  declarations: [ConfirmationDialogComponent],
  exports: [MatDialogModule, ConfirmationDialogComponent],
})
export class ConfirmationDialogModule {}
