import { Document, Finding } from '@core/models';
import { Injectable } from '@angular/core';
import { RefType } from '@portal/customer/components/attach-reference-button/reference.model';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  getDocumentViewerLink(doc: Document.Short): string {
    return null;
  }

  getFindingLink(finding: Finding.Short): string {
    return null;
  }

  getDocumentFileLink(
    doc: Document.Short,
    page?: number,
    trackedActivityScope?: RefType,
    anchor?: string,
    messageId?: string
  ): string {
    return null;
  }

  getMediaType(mediaTypes: Document.Type[] | undefined): 'photo' | 'video' | 'panorama' | 'report' {
    if (mediaTypes?.includes('photo')) {
      return 'photo';
    }
    if (mediaTypes?.includes('video')) {
      return 'video';
    }
    if (mediaTypes?.includes('panorama')) {
      return 'panorama';
    }
    return 'report';
  }
}
