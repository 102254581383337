import { Component, ElementRef, HostBinding, HostListener, ViewChild } from '@angular/core';
import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import _isEmpty from 'lodash/isEmpty';

import { StorageService } from '@core/services';

export interface ImagePreviewCellRendererParams extends ICellRendererParams {
  getDownloadToken?: () => Observable<string>;
  isAdmin: boolean;
  onFileSelected: (file: File) => void;
  value: string;
}

@Component({
  templateUrl: './image-preview-cell-renderer.component.html',
  styleUrls: ['./image-preview-cell-renderer.component.scss'],
})
export class ImagePreviewCellRendererComponent implements ICellRendererAngularComp {
  @ViewChild('file') fileElement: ElementRef<HTMLInputElement>;

  readonly downloadToken$ = new BehaviorSubject<string>(null);
  readonly imageData$ = this.downloadToken$.pipe(
    switchMap((download_token) =>
      download_token ? this.storageService.getImageWithDownloadToken({ download_token }) : of(null)
    ),
    catchError((error) => {
      if (error?.status === 404 && this.params?.getDownloadToken) {
        return this.params
          .getDownloadToken()
          .pipe(switchMap((download_token) => this.storageService.getImageWithDownloadToken({ download_token })));
      }

      return throwError(error);
    }),
    map((blob) => (blob ? window.URL.createObjectURL(blob) : null))
  );

  private paramsValue: ImagePreviewCellRendererParams;

  constructor(private storageService: StorageService) {}

  @HostBinding('class.admin')
  get isAdmin(): boolean {
    return this.params?.isAdmin;
  }

  get params(): ImagePreviewCellRendererParams {
    return this.paramsValue;
  }

  set params(params: ImagePreviewCellRendererParams) {
    const paramsChanged = this.paramsValue?.value !== params?.value;
    this.paramsValue = params;

    if (paramsChanged) {
      this.downloadToken$.next(params?.value);
    }
  }

  @HostListener('click', ['$event'])
  onComponentClick(event: MouseEvent): void {
    if (this.params?.isAdmin) {
      if (event.target !== this.fileElement.nativeElement) {
        this.fileElement.nativeElement.click();
      }
      event.stopPropagation();
    }
  }

  agInit(params: ImagePreviewCellRendererParams): void {
    this.params = params;
  }

  onFileChanged(): void {
    const file = this.fileElement?.nativeElement.files[0];
    this.params?.onFileSelected(file);
  }

  refresh(params: ImagePreviewCellRendererParams): boolean {
    let valueChanged = false;

    valueChanged = valueChanged || _isEmpty(this.params) !== _isEmpty(params);
    valueChanged = valueChanged || this.params?.value === params?.value;

    return valueChanged;
  }
}
