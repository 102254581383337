import { Overlay, OverlayConfig, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoute } from '@app/app-routing.service';
import { SvgIconModule } from '@app/common/components/svg-icon/svg-icon.module';
import { HeaderNotificationsStore } from '@app/common/page/header/header-notifications.store';
import { SSE } from '@core/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CustomerRouterSelectors } from '@portal/customer/state';
import filter from 'lodash/filter';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { NotificationListComponent } from '../notification-list/notification-list.component';
import TicketActions = SSE.EventData.TicketActions;
import VersioningActions = SSE.EventData.VersioningActions;

export const NOT_DISPLAYABLE_ACTIONS_FOR_EVENT: Partial<Record<SSE.EventData['type'], string[]>> = {
  reload_subtree: ['cancel', 'update'],
};

export const ALLOWED_GENERAL_SSE_EVENTS: SSE.EventData['type'][] = ['asset_status_changed', 'new_asset'];

export const ALLOWED_ASSET_CONTEXT_SSE_EVENTS: SSE.EventData['type'][] = [
  ...ALLOWED_GENERAL_SSE_EVENTS,
  'qa_todo_list_updated',
  'finding_published',
  'file_ready',
  'media_files_published',
  'reload_subtree',
  'report_published',
];

export const ALLOWED_ADMIN_CONTEXT_SSE_EVENTS: SSE.EventData['type'][] = [...ALLOWED_GENERAL_SSE_EVENTS, 'new_project'];

export const ALLOWED_TICKET_SSE_EVENTS_ACTIONS: TicketActions[] = ['create', 'answer', 'reject', 'close'];
export const ALLOWED_VERSIONING_SSE_EVENTS_ACTIONS: VersioningActions[] = ['approve_version', 'switch_version'];

export const MAXIMUM_NUMBER_OF_SHOWN_EVENTS_PER_ASSET = 10;

@UntilDestroy()
@Component({
  selector: 'x-notifications',
  templateUrl: './notifications.component.html',
  standalone: true,
  imports: [CommonModule, SvgIconModule, NotificationListComponent, OverlayModule],
})
export class NotificationsComponent {
  isOpen = false;
  overlayRef!: OverlayRef;
  isAdminRouteActive = false;
  isCustomerRouteActive = false;
  private readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);

  readonly notifications$ = combineLatest([
    this.notificationsStore.notifications$,
    this.assetId$,
    this.activatedRoute.url,
  ]).pipe(
    map(([notifications, assetId]) =>
      filter(notifications, (n) => {
        const ALLOWED_SSE_EVENTS = this.isAdminRouteActive
          ? ALLOWED_ADMIN_CONTEXT_SSE_EVENTS
          : assetId
          ? ALLOWED_ASSET_CONTEXT_SSE_EVENTS
          : ALLOWED_GENERAL_SSE_EVENTS;

        // latest implemented events doesnt use type anymore undefined is returned instead
        const allowedActionEvents =
          n.event.type === 'undefined' &&
          ((n.event.module === 'ticket' && ALLOWED_TICKET_SSE_EVENTS_ACTIONS.includes(n.event.action)) ||
            (n.event.module === 'data_room' && ALLOWED_VERSIONING_SSE_EVENTS_ACTIONS.includes(n.event.action)));

        const isGeneralEvent = ALLOWED_GENERAL_SSE_EVENTS.includes(n.event.type);
        const isRelatedToTheAsset = n.event?.['asset_id'] === assetId;
        const isVersionUpload = n.event?.['action'] === 'upload' && n.event?.data?.['is_version_upload'];
        const isAllowed =
          ALLOWED_SSE_EVENTS.includes(n.event.type) && (this.isCustomerRouteActive ? isRelatedToTheAsset : true);
        const hasDisallowedActions = NOT_DISPLAYABLE_ACTIONS_FOR_EVENT[n.event.type]?.includes(n.event?.['action']);
        return (
          !hasDisallowedActions &&
          !isVersionUpload &&
          (isGeneralEvent || isAllowed || (allowedActionEvents && isRelatedToTheAsset))
        );
      }).slice(0, MAXIMUM_NUMBER_OF_SHOWN_EVENTS_PER_ASSET)
    ),
    untilDestroyed(this)
  );

  readonly unreadNotifications$ = this.notifications$.pipe(
    map((notifications) => notifications.filter((notification) => !notification.read)),
    untilDestroyed(this)
  );

  constructor(
    private overlay: Overlay,
    private readonly notificationsStore: HeaderNotificationsStore,
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly store: Store
  ) {
    const overlayConfig = new OverlayConfig({
      hasBackdrop: true,
    });
    this.overlayRef = this.overlay.create(overlayConfig);
    this.overlayRef
      .backdropClick()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.overlayRef.dispose();
      });
    this.updateAdminActiveState();
  }

  toggleNotifications() {
    this.isOpen = !this.isOpen;
  }

  private updateAdminActiveState(): void {
    const adminLink = `/${AppRoute.Admin}`;
    const customerLink = `/${AppRoute.Customer}`;
    const currentUrl: string = this.router.routerState.snapshot.url;
    this.isCustomerRouteActive = currentUrl.includes(customerLink);
    this.isAdminRouteActive = currentUrl.includes(adminLink) && !currentUrl.includes(customerLink);
  }
}
