import { Document } from '@core/models';

export const photoType: Document.Type = 'photo';
export const videoType: Document.Type = 'video';
export const dataroomType: Document.Type = 'dataroom';

export const isPhotoTypeRegExp = /\.(jpg|jpeg|png|gif|bmp|tif|tiff|heic)$/i;
export const isVideoExtensionsRegExp = /\.(mp4|mov|avi|mkv|wmv|flv|webm|m4v|mpeg|mpg)$/i;

export const isPdfTypeRegExp = /\.(pdf)$/i;
