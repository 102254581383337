import * as d3 from 'd3';
import * as _ from 'lodash';
// import { SpiderChart as SC } from './spider-chart.config';

/* eslint-disable */
export class SpiderChart {
  private config: SpiderChart.Config;

  constructor(config: Partial<SpiderChart.Config> = {}) {
    this.config = <SpiderChart.Config>{
      ...SpiderChart.defaultConfig,
      ...config,
    };
  }

  chart = (id: Element, data: SpiderChart.Data[][]) => {
    // default config
    const cfg: SpiderChart.Config = {
      ...this.config,
    };

    if (cfg.svgWidth && cfg.svgHeight) {
      cfg.TranslateX = (cfg.svgWidth - cfg.w) / 2;
      cfg.TranslateY = (cfg.svgHeight - cfg.h) / 2;
    } else {
      cfg.svgHeight = cfg.h;
      cfg.svgWidth = cfg.w;
    }

    data = _.map(data, (d) => {
      if (d.length) {
        // reverse data to be render clockwise
        const first = d[0];
        const rest = d.slice(1).reverse();
        return [first, ...rest];
      }

      return d;
    });

    var allAxis = data[0].map(function (i, j) {
      return i.area;
    });
    var total = allAxis.length;
    d3.select(id).select('svg').remove();

    var g = d3
      .select(id)
      .append('svg')
      .attr('width', cfg.svgWidth + cfg.ExtraWidthX)
      .attr('height', cfg.svgHeight + cfg.ExtraWidthY)
      .append('g')
      .attr('transform', 'translate(' + cfg.TranslateX + ',' + cfg.TranslateY + ')');

    this.addSegments(allAxis, g, cfg, total);
    this.addAxis(allAxis, g, cfg, total);
    this.addPolygon(data, g, cfg, total);
    this.addCircles(data, g, cfg, total);
  };

  private addSegments(
    allAxis: string[],
    g: d3.Selection<SVGGElement, unknown, null, undefined>,
    cfg: SpiderChart.Config,
    total: number
  ): SpiderChart {
    var radius = cfg.factor * Math.min(cfg.w / 2, cfg.h / 2);

    //Circular segments
    for (var j = 0; j < cfg.levels; j++) {
      const levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels);
      g.selectAll('.levels')
        .data(allAxis)
        .enter()
        .append('svg:line')
        .attr('x1', (d, i) => {
          // return levelFactor * (1 - cfg.factor * Math.sin(i * cfg.radians / total));
          return this.getHorizontalPosition(i, levelFactor, cfg, total);
        })
        .attr('y1', (d, i) => {
          // return levelFactor * (1 - cfg.factor * Math.cos(i * cfg.radians / total));
          return this.getVerticalPosition(i, levelFactor, cfg, total);
        })
        .attr('x2', (d, i) => {
          // return levelFactor * (1 - cfg.factor * Math.sin((i + 1) * cfg.radians / total));
          return this.getHorizontalPosition(i + 1, levelFactor, cfg, total);
        })
        .attr('y2', (d, i) => {
          // return levelFactor * (1 - cfg.factor * Math.cos((i + 1) * cfg.radians / total));
          return this.getVerticalPosition(i + 1, levelFactor, cfg, total);
        })
        .attr('class', 'line')
        .style('stroke', 'grey')
        .style('stroke-opacity', '0.75')
        .style('stroke-width', '0.3px')
        .attr('transform', 'translate(' + (cfg.w / 2 - levelFactor) + ', ' + (cfg.h / 2 - levelFactor) + ')');
    }

    //Text indicating at what % each level is
    for (var j = 0; j < cfg.levels; j++) {
      const levelFactor = cfg.factor * radius * ((j + 1) / cfg.levels);
      g.selectAll('.levels')
        .data([1]) //dummy data
        .enter()
        .append('svg:text')
        .attr('x', function (d) {
          return levelFactor * (1 - cfg.factor * Math.sin(0));
        })
        .attr('y', function (d) {
          return levelFactor * (1 - cfg.factor * Math.cos(0));
        })
        .attr('class', 'legend')
        .style('font-family', 'sans-serif')
        .style('font-size', '10px')
        .attr(
          'transform',
          'translate(' + (cfg.w / 2 - levelFactor + cfg.ToRight) + ', ' + (cfg.h / 2 - levelFactor) + ')'
        )
        .attr('fill', '#737373')
        .text(cfg.levelFormatter(j, cfg));
    }

    return this;
  }

  private addAxis(
    allAxis: string[],
    g: d3.Selection<SVGGElement, unknown, null, undefined>,
    cfg: SpiderChart.Config,
    total: number
  ): SpiderChart {
    // var radius = cfg.factor * Math.min(cfg.w / 2, cfg.h / 2);
    // var xFactor = radius; // cfg.w / 2;
    // var yFactor = radius; // cfg.h / 2;

    var xFactor = cfg.w / 2;
    var yFactor = cfg.h / 2;

    // const levelFactor = cfg.factor * radius;

    var axis = g.selectAll('.axis').data(allAxis).enter().append('g').attr('class', 'axis');

    if (cfg.showAxes) {
      axis
        .append('line')
        .attr('x1', cfg.w / 2)
        .attr('y1', cfg.h / 2)
        .attr('x2', (d, i) => {
          const x2 = this.getHorizontalPosition(i, xFactor, cfg, total);
          return x2;
        })
        .attr('y2', (d, i) => {
          const y2 = this.getVerticalPosition(i, yFactor, cfg, total);
          return y2;
        })
        .attr('class', 'line')
        .style('stroke', 'grey')
        .style('stroke-width', '1px');
    }

    axis
      .append('text')
      .attr('class', 'legend')
      .text(function (d) {
        return d;
      })
      .style('font-family', 'sans-serif')
      .style('font-size', '11px')
      .attr('text-anchor', 'middle')
      .attr('dy', '1.5em')
      .attr('transform', function (d, i) {
        return 'translate(0, -10)';
      })
      .attr('x', function (d, i) {
        return (
          xFactor * (1 - cfg.factorLegend * Math.sin((i * cfg.radians) / total)) -
          60 * Math.sin((i * cfg.radians) / total)
        );
      })
      .attr('y', function (d, i) {
        return yFactor * (1 - Math.cos((i * cfg.radians) / total)) - 20 * Math.cos((i * cfg.radians) / total);
      });

    return this;
  }

  private addPolygon(
    d: SpiderChart.Data[][],
    g: d3.Selection<SVGGElement, unknown, null, undefined>,
    cfg: SpiderChart.Config,
    total: number
  ): SpiderChart {
    let series = 0;

    d.forEach(function (y, x) {
      const dataValues: number[][] = [];
      g.selectAll('.nodes').data(y, <any>(<unknown>function (j: SpiderChart.Data, i: number) {
        dataValues.push([
          (cfg.w / 2) *
            (1 -
              (parseFloat(<string>(<unknown>Math.max(j.value, 0))) / cfg.maxValue) *
                cfg.factor *
                Math.sin((i * cfg.radians) / total)),
          (cfg.h / 2) *
            (1 -
              (parseFloat(<string>(<unknown>Math.max(j.value, 0))) / cfg.maxValue) *
                cfg.factor *
                Math.cos((i * cfg.radians) / total)),
        ]);
      }));

      dataValues.push(dataValues[0]);
      g.selectAll('.area')
        .data([dataValues])
        .enter()
        .append('polygon')
        .attr('class', 'radar-chart-serie' + series)
        .style('stroke-width', '2px')
        .style('stroke', cfg.color(series))
        .attr('points', function (d) {
          var str = '';
          for (var pti = 0; pti < d.length; pti++) {
            str = str + d[pti][0] + ',' + d[pti][1] + ' ';
          }
          return str;
        })
        .style('fill', <any>(<unknown>function (j: SpiderChart.Data, i: number) {
          return cfg.color(series);
        }))
        .style('fill-opacity', cfg.opacityArea);
      series++;
    });

    return this;
  }

  private addCircles(
    d: SpiderChart.Data[][],
    g: d3.Selection<SVGGElement, unknown, null, undefined>,
    cfg: SpiderChart.Config,
    total: number
  ): SpiderChart {
    let series = 0;

    d.forEach(function (y, x) {
      g.selectAll('.nodes')
        .data(y)
        .enter()
        .append('svg:circle')
        .attr('class', 'radar-chart-serie' + series)
        .attr('r', (d) => d.radius || cfg.radius)
        .attr('alt', function (j) {
          return Math.max(j.value, 0);
        })
        .attr('cx', function (j, i) {
          return (
            (cfg.w / 2) * (1 - cfg.factor * (Math.max(j.value, 0) / cfg.maxValue) * Math.sin((i * cfg.radians) / total))
          );
        })
        .attr('cy', function (j, i) {
          return (
            (cfg.h / 2) * (1 - cfg.factor * (Math.max(j.value, 0) / cfg.maxValue) * Math.cos((i * cfg.radians) / total))
          );
        })
        .attr('data-id', function (j) {
          return j.area;
        })
        .style('fill', '#fff')
        .style('stroke-width', '2px')
        .style('stroke', cfg.color(series))
        .style('fill-opacity', 0.9)
        .text((d) => d.value);

      series++;
    });

    return this;
  }

  private getPosition(
    i: number,
    range: number,
    factor: number | undefined,
    func: (x: number) => number,
    cfg: SpiderChart.Config,
    total: number
  ): number {
    factor = typeof factor !== 'undefined' ? factor : 1;
    return range * (1 - factor * func((i * cfg.radians) / total));
  }

  private getHorizontalPosition(
    i: number,
    range: number,
    cfg: SpiderChart.Config,
    total: number,
    factor?: number
  ): number {
    return this.getPosition(i, range, factor, Math.sin, cfg, total);
  }

  private getVerticalPosition(
    i: number,
    range: number,
    cfg: SpiderChart.Config,
    total: number,
    factor?: number
  ): number {
    return this.getPosition(i, range, factor, Math.cos, cfg, total);
  }

  draw = (container: string | Element, d: SpiderChart.Data[], options: Partial<SpiderChart.Config> = {}) => {
    this.config = {
      ...this.config,
      ...options,
    };

    this.chart(container as Element, [d]);
  };
}

export namespace SpiderChart {
  export interface Data {
    area: string;
    value: number;
    radius?: number;
    yOffset?: number;
    xOffset?: number;
    x?: number;
    y?: number;
  }

  export interface Config {
    radius: number;
    w: number;
    h: number;
    svgWidth: number;
    svgHeight: number;
    factor: number;
    factorLegend: number;
    levels: number;
    minValue: number;
    maxValue: number;
    radians: number;
    opacityArea: number;
    ToRight: number;
    TranslateX: number;
    TranslateY: number;
    ExtraWidthX: number;
    ExtraWidthY: number;
    color: d3.ScaleOrdinal<any, unknown> | any;
    levelFormatter(level: number, cfg: SpiderChart.Config): string;
    showAxes: boolean;
  }

  export const defaultConfig: Partial<SpiderChart.Config> = {
    radius: 5,
    w: 600,
    h: 600,
    factor: 1,
    factorLegend: 0.85,
    levels: 3,
    maxValue: 0,
    minValue: 0,
    radians: 2 * Math.PI,
    opacityArea: 0.5,
    ToRight: 5,
    TranslateX: 80,
    TranslateY: 30,
    ExtraWidthX: 100,
    ExtraWidthY: 100,
    color: d3.scaleOrdinal().range(['#00376a', '#8196a9', '#ae9250']),
    levelFormatter: (level: number, cfg: SpiderChart.Config) => `${((level + 1) * 100) / cfg.levels}`,
    showAxes: true,
  };
}
