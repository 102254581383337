import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, TemplateRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { Observable } from 'rxjs';
import { finalize, take } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface ConfirmationDialogData {
  title?: string;
  acceptButtonText?: string;
  cancelButtonText?: string;
  context?: any; // eslint-disable-line @typescript-eslint/no-explicit-any
  disclaimer?: boolean;
  minWidth?: number;
  minHeight?: number;
  template?: TemplateRef<unknown>;
  text?: string;
  textClasses?: string;
  textContainerClasses?: string;
  width?: number;
  disabled?: Observable<boolean>;
  variant?: 'primary' | 'danger';
  closeOnly?: boolean;
  isHeaderShowed?: boolean;
  hideCloseIcon?: boolean;
  onCancel?(): void;
  onConfirm?(): unknown | (() => Observable<never>);
}

@UntilDestroy()
@Component({
  selector: 'x-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent {
  @HostBinding('class.disclaimer-confirmation')
  isDisclaimer = false;
  @HostBinding('style.min-width.px')
  minWidth: number;
  @HostBinding('style.width.px')
  width: number;
  minHeight = '';

  isSubmitting = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private dialogRef: MatDialogRef<DialogComponent, boolean>,
    private readonly cdr: ChangeDetectorRef
  ) {
    this.isDisclaimer = data?.disclaimer;
    this.minWidth = data?.minWidth;
    this.width = data?.width;
    this.minHeight = data?.minHeight ? `min-h[${data.minHeight}]` : '';
  }

  get template(): TemplateRef<unknown> {
    return this.data?.template;
  }

  onCancel(): void {
    if (this.data.onCancel) {
      this.data.onCancel();
    }
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    if (!this.data?.onConfirm) {
      this.dialogRef.close(true);
      return;
    }
    const result = this.data?.onConfirm();
    if (result instanceof Observable) {
      this.isSubmitting = true;
      this.cdr.detectChanges();
      result
        .pipe(
          take(1),
          untilDestroyed(this),
          finalize(() => {
            this.isSubmitting = false;
            this.cdr.detectChanges();
          })
        )
        .subscribe(() => {
          this.dialogRef.close(true);
        });
    } else {
      this.dialogRef.close(true);
    }
  }
}
