import { createAction, props } from '@ngrx/store';

import { Asset, AssetOverview } from '@core/models';

export namespace AssetsActions {
  export const add = createAction('[Asset] Add', props<{ assetOverview: AssetOverview }>());

  export const update = createAction('[Asset] Update', props<{ assetOverview: AssetOverview }>());

  export const fetch = createAction('[Assets] Fetch');

  export const fetchSuccess = createAction('[Assets] Fetch Success', props<{ assetOverviews: AssetOverview[] }>());

  export const fetchDetailsSuccess = createAction('[Assets] Fetch Details Success', props<{ asset: Asset.Details }>());
}
