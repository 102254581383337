import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormBuilder, ValidationErrors, Validators } from '@angular/forms';
import { isNotNil } from '@app/utils';
import { CallPipeModule, DocumentDetailsPipeModule, DocumentPhotoPipeModule } from '@common';
import { ButtonComponent } from '@common/components/button/button.component';
import { SelectComponent } from '@common/components/select/select.component';
import { ISelectOption } from '@common/components/select/select.model';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { InfoPanelComponent } from '@common/info-panel/info-panel.component';
import { Group, Member, Message } from '@core/models';
import { Priority } from '@core/models/priority';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import {
  AddMemberFieldComponent,
  MemberFieldErrors,
} from '@portal/components/add-member-field/add-member-field.component';
import { DateInputModule } from '@portal/components/date-input/date-input.module';
import { QaDetailsDialogFieldsComponent } from '@portal/components/qa-overview/qa-details-dialog-fields/qa-details-dialog-fields.component';
import { QaReferenceModule } from '@portal/components/qa-overview/qa-reference/qa-reference.module';
import { TextareaComponent } from '@portal/components/textarea/textarea.component';
import { TicketDetailsDialogFieldsComponent } from '@portal/customer/components/asset-details/asset-tickets-container/components/ticket-details-dialog-fields/ticket-details-dialog-fields.component';
import { FindingDetailsModule } from '@portal/customer/components/asset-details/findings/finding-details';
import { AttachReferenceButtonComponent } from '@portal/customer/components/attach-reference-button/attach-reference-button.component';
import { Reference } from '@portal/customer/components/attach-reference-button/reference.model';
import { ReferencesPreviewComponent } from '@portal/customer/components/references-preview/references-preview.component';
import { CustomerRouterSelectors, PermissionsSelectors } from '@portal/customer/state';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import TicketType = Message.TicketType;

export interface TicketFormResult {
  data: Message.NewTicket;
  hasErrors: boolean;
}

@UntilDestroy()
@Component({
  selector: 'x-ticket-form',
  standalone: true,
  imports: [
    CommonModule,
    TextareaComponent,
    TranslocoModule,
    SelectComponent,
    QaReferenceModule,
    ButtonComponent,
    DateInputModule,
    AddMemberFieldComponent,
    InfoPanelComponent,
    AttachReferenceButtonComponent,
    CallPipeModule,
    DocumentDetailsPipeModule,
    DocumentPhotoPipeModule,
    SvgIconModule,
    ReferencesPreviewComponent,
    TicketDetailsDialogFieldsComponent,
    QaDetailsDialogFieldsComponent,
    FindingDetailsModule,
  ],
  templateUrl: './ticket-form.component.html',
  styleUrls: ['./ticket-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TicketFormComponent implements OnInit, OnChanges {
  @Input() assetId!: string;
  @Input() userId: string;
  @Input() groups: Group[];
  @Input() assetMembers: Member[];
  @Input() trades = [];
  @Input() initialValues!: { references?: Reference[]; message?: string };
  @Output() valueChanges = new EventEmitter<TicketFormResult>();

  private readonly store = inject(Store);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly fb = inject(FormBuilder);

  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId).pipe(filter(isNotNil));
  readonly groupOptions$: BehaviorSubject<ISelectOption<string>[]> = new BehaviorSubject([]);
  readonly hasTicketsWritePermission$ = this.store.select(PermissionsSelectors.canWrite, 'ticket');

  readonly typesOptions$: Observable<ISelectOption<string>[]> = this.hasTicketsWritePermission$.pipe(
    take(1),
    map((hasTicketWritePermission) => {
      const commonOptions = [
        {
          value: 'task',
          translationKey: 'tickets-system.ticket-type.task',
        },
      ];

      const additionalOptions = hasTicketWritePermission
        ? [
            {
              value: 'defect',
              translationKey: 'tickets-system.ticket-type.defect',
            },
            {
              value: 'complain_of_defect',
              translationKey: 'tickets-system.ticket-type.complain_of_defect',
            },
          ]
        : [];

      return [...commonOptions, ...additionalOptions];
    }),
    untilDestroyed(this)
  );

  readonly priorityOptions: ISelectOption<Priority>[] = [
    {
      value: 'high',
      translationKey: 'high',
    },
    {
      value: 'medium',
      translationKey: 'medium',
    },
    {
      value: 'low',
      translationKey: 'low',
    },
  ];
  tradesOptions = [];

  attachedReferences: Reference[] = [];
  externalMembers = [];

  private _hasExternalMembersErrors = false;
  get hasExternalMembersErrors() {
    return (
      this.form.controls.to_group_id.value === 'external' &&
      (this._hasExternalMembersErrors || this.externalMembers.length === 0)
    );
  }
  set hasExternalMembersErrors(val: boolean) {
    this._hasExternalMembersErrors = val;
  }

  form = this.fb.group({
    trade_id: [''],
    message: ['', Validators.required],
    type: ['', Validators.required],
    to_group_id: ['', Validators.required],
    to_user_id: [''],
    priority: ['', Validators.required],
    due_date: [undefined, Validators.required],
  });

  userOptions$: Observable<ISelectOption<string>[]> = this.form.controls.to_group_id.valueChanges.pipe(
    tap(() => {
      this.form.controls.to_user_id.reset();
    }),
    map((groupId: string) => this.assetMembers.filter((member) => member.group_id === groupId)),
    map((members: Member[]) =>
      members.map((member) => ({
        value: member.user_id,
        label: Member.getFormattedMemberName(member),
      }))
    ),
    untilDestroyed(this)
  );

  constructor() {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.emitChanges();
      this.cdr.detectChanges();
    });
    this.form.controls.type.valueChanges.pipe(untilDestroyed(this)).subscribe((type: TicketType) => {
      const groupId = this.assetMembers.find((member) => member.user_id === this.userId).group_id;
      this.form.controls.to_group_id.patchValue(type === 'task' ? groupId : undefined);
    });
    this.form.setValidators(this.customTicketTypeValidator.bind(this));
  }

  ngOnInit() {
    this.setGroupOptions(this.groups);
    this.initForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.trades && !this.tradesOptions.length) {
      this.setTradeOptions();
    }
  }

  initForm() {
    const references = this.initialValues?.references;
    const message = this.initialValues?.message;
    this.attachReference(references);
    if (message) {
      this.form.controls.message.patchValue(message);
    }
    this.cdr.markForCheck();
  }

  attachReference(refs: Reference[]) {
    this.attachedReferences = [...this.attachedReferences, ...(refs || [])];
    this.emitChanges();
    this.cdr.detectChanges();
  }

  removeReference(referenceId: string) {
    this.attachedReferences = this.attachedReferences.filter((ref) => ref.ref_id !== referenceId);
    this.emitChanges();
    this.cdr.detectChanges();
  }

  onDueDateChange(date) {
    this.form.controls.due_date.setValue(date);
  }

  getDateFieldValue(date: string | Date): Date | undefined {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    date?.setHours(23, 59, 59);
    return date;
  }

  handleExternalMemberErrorsChange(errors: MemberFieldErrors | undefined) {
    this.hasExternalMembersErrors = errors?.has_already_added_members || errors?.incorrect_address;
    this.emitChanges();
  }

  onExternalMembersChange() {
    this.emitChanges();
  }

  private emitChanges() {
    this.valueChanges.emit({
      data: this.getCreateTicketEntity(),
      hasErrors: this.form.invalid || this.hasExternalMembersErrors,
    });
  }

  private getCreateTicketEntity(): Message.NewTicket {
    const formValues = this.form.value;
    const reference = this.attachedReferences[0]; // only 1 reference is possible for ticket creation
    return {
      type: formValues.type as TicketType,
      priority: formValues.priority as Priority,
      to_group_id: formValues.to_group_id,
      to_user_id: formValues.type === 'task' ? formValues.to_user_id : undefined, // we do not support users for other types
      message: formValues.message,
      scope: 'general',
      trade_id: Number(formValues.trade_id),
      due_date: this.getDateFieldValue(formValues.due_date)?.toISOString(),
      ...Message.getMessageReferenceIdField(reference),
    };
  }

  private customTicketTypeValidator(control: AbstractControl): ValidationErrors | null {
    const type: TicketType = control.get('type').value;
    const toUserIdControl = control.get('to_user_id');

    if (type === 'task' && !toUserIdControl.value) {
      toUserIdControl.setErrors({ requiredForTask: true });
      return { requiredForTask: true };
    } else {
      toUserIdControl.setErrors(null);
      return null;
    }
  }

  private setGroupOptions(groups: Group[]) {
    const options = groups?.map((group) => ({
      value: group.id,
      label: group.name,
      disabled: false,
    }));
    // const externalUserOption: ISelectOption<string> = {
    //   value: 'external',
    //   translationKey: 'external',
    //   pinned: true,
    // };
    // this.groupOptions$.next([...options, externalUserOption]);
    this.groupOptions$.next([...options]);
  }

  private setTradeOptions() {
    this.tradesOptions = this.trades?.map((trade) => ({
      value: trade.id,
      label: `${trade.register} ${trade.description}`,
      tooltip: trade.tooltip,
    }));
    this.cdr.markForCheck();
  }
}
