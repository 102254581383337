import { Permission } from '@core/models';
import { UserActions } from '@core/state';
import { Action, createReducer, on } from '@ngrx/store';
import { ResolvedDataActions } from './resolver.actions';

export interface ResolvedData {
  permission: Permission | null;
  globalTermsConditionsId: string;
}

const resolvedDataInitialState: ResolvedData = {
  permission: null,
  globalTermsConditionsId: '',
};

const resolvedReducer = createReducer<ResolvedData>(
  resolvedDataInitialState,
  on(ResolvedDataActions.setPermission, (state, action) => ({
    ...state,
    permission: action.data.permission,
  })),
  on(ResolvedDataActions.setGlobalTermsConditionsId, (state, action) => ({
    ...state,
    globalTermsConditionsId: action.termsConditionsId,
  })),
  on(UserActions.clearUser, () => ({
    ...resolvedDataInitialState,
  }))
);

export namespace ResolvedDataReducer {
  export const name = 'admin-resolved-data';

  export const reducer = (state: ResolvedData, action: Action) => resolvedReducer(state, action);
}
