import { Group } from './group';
import { Permission } from './permission';
import { ShortUser, User } from './user';

export interface Member {
  id: string;
  user_id: string;
  user: User;
  group_id: string;
  group: Group;
  is_group_lead: boolean;
  permission: Permission;
  status: string;
  tenant_id: string;
  project_id: string;
  limited_to_asset_ids: string[];
  invited_at: Date;
  created_at: Date;
  blocked_at: Date;
  deleted_at: Date;
  first_access_at: Date;
  last_access_at: Date;
}

export namespace Member {
  export const getFormattedMemberName = (member: ShortUser | Member, showEmail = true) => {
    const user = 'email' in member ? member : member.user;
    return `${user?.firstname || ''} ${user?.lastname || ''} ${showEmail ? '<' + user?.email + '>' : ''}`.trim();
  };
}
