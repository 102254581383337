import { getAllEnumValues } from './enum';

export enum AdditionalModules {
  QAPprocess = 'qa_process',
  EnvironmentalDD = 'environmental_dd',
  AreaPlausibilisation = 'area_plausibilisation',
  AreaCertification = 'area_certification',
  ContractNegotiations = 'contract_negotiations',
  CreatingDataroom = 'creating_dataroom',
  RelianceLetter = 'reliance_letter',
  MarketValueIndication = 'market_value_indication',
  MarketValueAssessment = 'market_value_assessment',
  RetailImpactAnalysis = 'retail_impact_analysis',
  Nd2OpinionForExistingValueExpertise = 'nd2_opinion_for_existing_value_expertise',
}

export namespace AdditionalModules {
  export const all = getAllEnumValues<AdditionalModules>(AdditionalModules);
}
