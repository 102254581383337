<ng-container *transloco="let t; read: 'tickets-system.ticket-details'">
  <ng-container *ngIf="(dialogData.data | async).data; let data">
    <ng-container *ngIf="!previewOnly && user$ | async; let user">
      <div class="flex flex-row space-between children:mr-1.5">
        <div class="flex flex-1 justify-end children:py-2.5 items-center space-x-2.5 self-end">
          <x-button
            *ngIf="messageUtils.getCanForward(data?.message)"
            (handleClick)="forwardMessage(data?.message)"
            color="white"
          >
            <x-svg-icon class="pr-1" svgImageName="forward" svgClass="w-5 h-5"></x-svg-icon>
            <span>{{ 'tickets-system.actions.forward' | transloco }}</span>
          </x-button>
          <x-button
            *ngIf="isSetGracePeriodVisible(data?.message, user.id)"
            (handleClick)="onSetGracePeriod(data?.message)"
            color="white"
          >
            <x-svg-icon class="pr-1" svgImageName="calendar" svgClass="w-5 h-5"></x-svg-icon>
            <span>{{ t('set-grace-period') }}</span>
          </x-button>
          <x-button
            *ngIf="isExtendDueDateVisible(data?.message, user.id)"
            (handleClick)="onExtendDueDate(data?.message)"
            color="white"
          >
            <x-svg-icon class="pr-1" svgImageName="edit" svgClass="w-5 h-5"></x-svg-icon>
            <span>{{ t('extend-due-date') }}</span>
          </x-button>
          <x-button
            *ngIf="data.canWriteResponse"
            (handleClick)="onWriteResponseClick()"
            color="white"
            [disabled]="isWritingResponse"
          >
            <x-svg-icon class="pr-1" svgImageName="pencil" svgClass="w-5 h-5"></x-svg-icon>
            <span>{{ t('write-response') }}</span>
          </x-button>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="isWritingResponse">
      <h4 class="subtitle">
        {{ t('write-response') }}
      </h4>
      <x-write-response-form
        *ngIf="isWritingResponse"
        [allowReferenceSelect]="true"
        [initialResponseText]="data?.message?.response"
        [initialReferences]="getResponseReferences(data?.message)"
        [messageId]="data?.message?.id"
        [messageCategory]="data?.category"
        [documents]="data?.message?.documents"
        [isSaving]="isSaving"
        [multipleReferences]="true"
        (modelChange)="onChanges($event)"
      ></x-write-response-form>
      <div class="flex flex-row space-x-2 items-center justify-end">
        <x-button (handleClick)="cancelChanges()" color="white" [disabled]="isSaving">
          <span>{{ 'cancel' | transloco }}</span>
        </x-button>
        <x-button
          [disabled]="isSaving || !(writeResponseModelOutput?.response || data?.message?.response)"
          (handleClick)="onSaveResponse(writeResponseModelOutput?.response || data?.message?.response)"
          color="primary"
        >
          <span>{{ 'save' | transloco }}</span>
        </x-button>
        <x-button [disabled]="isSaving || !hasChangesToSave()" (handleClick)="onSaveResponseAsDraft()" color="primary">
          <span>{{ 'save-as-draft' | transloco }}</span>
        </x-button>
      </div>
    </ng-container>
    <h4 class="subtitle">{{ t('ticket-details-subtitle') }}</h4>
    <ng-container
      [ngTemplateOutlet]="data?.fieldsTemplate"
      [ngTemplateOutletContext]="{ $implicit: data.message }"
    ></ng-container>
    <ng-container *ngIf="!isWritingResponse">
      <h4 class="mt-4 subtitle">
        {{ messageUtils.getCanAnswer(data?.message) && data?.message?.response ? t('response-draft') : t('response') }}
      </h4>
      <div class="flex flex-col text-sm justify-center py-4">
        <div class="flex flex-col divide-y" *ngIf="data?.message?.response; else noResponseAvailable">
          <x-details-field-row [label]="t('response')">
            {{ data?.message?.response }}
          </x-details-field-row>
          <x-details-field-row [label]="t('attachments')">
            <ng-container *ngIf="data?.message?.documents || data?.message?.photos; else dash; let docs">
              <x-documents-preview
                *ngIf="docs?.length; else dash"
                [documents]="getDocumentList | call : data?.message"
                [messageId]="data?.message?.id"
                [messageCategory]="data?.category"
                [groupNotUploadedDocuments]="data?.message?.status === 'closed'"
                [isDownloadDocumentPossible]="true"
                [showUploadAction]="
                  (data?.message?.status === 'closed' && data?.message?.message_user?.id === (user$ | async)?.id) ||
                  data?.category === 'qa'
                "
                (documentUploadClick)="onDocumentUploadClick($event, data.message)"
              ></x-documents-preview>
            </ng-container>
            <ng-template #dash>-</ng-template>
          </x-details-field-row>
          <x-details-field-row [label]="t('references')">
            <ng-container *ngIf="getResponseReferences(data?.message); else dash; let references">
              <x-references-preview
                [assetId]="data?.message.asset_id"
                [attachedReferencesList]="references"
              ></x-references-preview>
            </ng-container>
            <ng-template #dash>-</ng-template>
          </x-details-field-row>
          <x-details-field-row [label]="t('responded-by')">
            <x-username-renderer
              [value]="{ user: data?.message?.response_user, fallbackName: '-' }"
            ></x-username-renderer>
          </x-details-field-row>
          <x-details-field-row [label]="t('responded-at')">
            {{ (data?.message?.response_created_at | localeDate : true) || '-' }}
          </x-details-field-row>
        </div>

        <ng-template #noResponseAvailable>
          <div class="flex flex-row !text-gray-400">
            <x-svg-icon svgImageName="info" class="mr-2.5"></x-svg-icon>
            <span>{{ t('no-response-text') }}</span>
          </div>
        </ng-template>
      </div>
    </ng-container>

    <ng-container *ngIf="data?.message?.rejected_reason; let rejectedReason">
      <h4 class="mt-4 subtitle">
        {{ t('rejection') }}
      </h4>
      <div class="flex flex-col divide-y">
        <x-details-field-row [label]="t('rejected-reason')">
          {{ rejectedReason }}
        </x-details-field-row>
      </div>
    </ng-container>

    <ng-template #footerTemplate>
      <div class="flex space-x-2">
        <ng-container *ngIf="messageUtils.getCanApprove(data?.message)">
          <x-button (handleClick)="acceptMessage(data?.message)" color="success" [disabled]="isSaving">
            <x-svg-icon svgImageName="success-check" class="mr-1.5"></x-svg-icon>
            <span>{{ 'accept' | transloco }}</span>
          </x-button>
          <x-button (handleClick)="rejectMessage(data?.message)" color="danger" [disabled]="isSaving">
            <x-svg-icon svgImageName="reject-cross" class="mr-1.5"></x-svg-icon>
            <span>{{ 'reject' | transloco }}</span>
          </x-button>
        </ng-container>
      </div>
    </ng-template>
  </ng-container>
</ng-container>
