import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface TermsConfirmationDialogData {
  content: string;
  description: string;
  assetId?: string;
  projectId?: string;
  tenantId?: string;
  createAt?: string;
  deletedAt?: string;
  updatedAt?: string;
  onConfirm?(): void;
  onCancel?(): void;
}

@Component({
  selector: 'x-terms-confirmation-dialog',
  templateUrl: './terms-confirmation-dialog.component.html',
  styleUrls: ['./terms-confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsConfirmationDialogComponent {
  get description(): string {
    return this.data.description;
  }

  get theHtmlString(): string {
    return this.data.content;
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: TermsConfirmationDialogData,
    private dialogRef: MatDialogRef<TermsConfirmationDialogData, boolean>
  ) {}

  onCancel(): void {
    if (this.data.onCancel) {
      this.data.onCancel();
    }
    this.dialogRef.close(false);
  }

  onConfirm(): void {
    if (this.data.onConfirm) {
      this.data.onConfirm();
    }
    this.dialogRef.close(true);
  }
}
