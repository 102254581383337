import { Pipe, PipeTransform } from '@angular/core';

/* eslint-disable */
// use only with pure functions!
// https://github.com/angular/angular/issues/25976#issuecomment-520229969
@Pipe({ name: 'call' })
export class CallPipe implements PipeTransform {
  // Utilized variadic tuple types for types
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-0.html#variadic-tuple-types
  transform<Args extends unknown[], Result>(func: (...funcargs: Args) => Result, ...args: Args): Result {
    return func(...args);
  }
}
/* eslint-enable */

export type Mapper<T, G> = (item: T, ...args: unknown[]) => G;

@Pipe({ name: 'callFunction' })
export class CallFunctionPipe implements PipeTransform {
  transform<T, G>(value: T, mapper: Mapper<T, G>, ...args: unknown[]): G {
    return mapper(value, ...args);
  }
}
