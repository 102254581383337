<mat-form-field>
  <input
    matInput
    class="hidden"
    (dateChange)="onValueSelected($event, picker)"
    (focus)="picker.open()"
    [matDatepicker]="picker"
    [min]="minDate"
  />
  <mat-datepicker #picker></mat-datepicker>
</mat-form-field>
<div>
  <span>{{ params?.value | localeDate }}</span>
</div>
