<ng-container *ngIf="!(isLoading$ | async)">
  <x-dialog
    *ngIf="!(dailyLimitExceeded || totalLimitExceeded); else qaLimitExceededTemplate"
    [submitDisabled]="!model.priority || !model.to_group_id || !model.message"
    (handleClose)="onClose()"
    (handleSubmit)="onSubmit()"
    title="{{ 'asset_menu.add_question' | transloco }}"
    cdkOverlayOrigin
    #dialogRef="cdkOverlayOrigin"
  >
    <form novalidate autocomplete="off">
      <div class="form-section">
        <section>
          <x-select
            [(value)]="model.priority"
            labelPosition="inline"
            name="priority"
            label="{{ 'priority' | transloco }}"
            [options]="priorityOptions"
            [withEmptyOption]="true"
            [required]="true"
          ></x-select>
        </section>

        <section class="mt-4">
          <x-select
            labelPosition="inline"
            (valueChange)="selectGroup($event)"
            [(value)]="model.to_group_id"
            name="to_group_id"
            [options]="groupsOptions"
            [withEmptyOption]="true"
            label="{{ 'group' | transloco }}"
            [required]="true"
          ></x-select>
        </section>

        <section class="mt-4">
          <span class="flex flex-row space-between">
            <p class="label">{{ 'message' | transloco }}:</p>
            <ng-container *ngIf="isProjectManager$ | async">
              <span class="interactive-text" (click)="onShowPredefined()">
                {{ 'predefined-questions' | transloco }}
              </span>
              <ng-template
                cdkConnectedOverlay
                [cdkConnectedOverlayOrigin]="dialogRef"
                [cdkConnectedOverlayOpen]="isShowPredefined"
                [cdkConnectedOverlayPositions]="cdkConnectedOverlayPositions"
                [cdkConnectedOverlayViewportMargin]="100"
                cdkConnectedOverlayPanelClass="predefined-questions-panel"
              >
                <div
                  *ngIf="isShowPredefined"
                  class="relative p-3 mx-2 w-[400px] form-section predefined-questions-form bg-white rounded-xl shadow-2xl"
                >
                  <header class="text-gray-900 text-lg font-semibold">
                    {{ 'template' | transloco }}
                  </header>
                  <x-svg-icon
                    class="absolute right-3.5 top-3.5 cursor-pointer"
                    (click)="onCancelPredefinedMessage()"
                    svgImageName="cross"
                    svgClass="w-6 h-6 text-gray-400 rounded-xl hover:bg-gray-200"
                  ></x-svg-icon>
                  <div class="py-2.5">
                    <section class="py-1">
                      <p class="text-sm text-gray-700">{{ 'filter-by-tags' | transloco }}</p>
                      <x-tags-input
                        [allTags]="predefinedTags$ | async"
                        (tagsChanged)="onTagsChanged($event)"
                      ></x-tags-input>
                    </section>
                    <section class="py-1">
                      <p class="text-sm text-gray-700">{{ 'predefined-messages' | transloco }}</p>
                      <ul class="pb-2 divide-y divide-gray-200 max-h-[400px] overflow-auto">
                        <li
                          class="my-1"
                          *ngFor="let message of predefinedMessagesOptions$ | async"
                          (click)="onSelectMessage(message.value)"
                        >
                          <span
                            class="flex text-sm p-1 my-1.5 cursor-pointer hover:bg-gray-100 text-sm text-gray-700 rounded"
                          >
                            {{ message.label }}
                          </span>
                        </li>
                      </ul>
                    </section>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </span>
          <x-textarea [(value)]="model.message" name="message"></x-textarea>
        </section>

        <section class="flex flex-row mt-2 justify-end items-center" *ngIf="data.showReferenceSelector">
          <x-attach-reference-button
            [availableOptions]="availableRefOptions"
            *ngIf="!model?.reference"
            (referenceSelected)="onReferenceAdded($event)"
            [singleOnly]="true"
          ></x-attach-reference-button>
          <x-references-preview
            *ngIf="model?.reference"
            [assetId]="assetId$ | async"
            [attachedReferencesList]="[model.reference]"
            [canRemoveReference]="true"
            (referenceRemoved)="onReferenceRemoved()"
          ></x-references-preview>
        </section>

        <section class="flex flex-row mt-2" *ngIf="(isProjectManager$ | async) && selectedGroupType === typeCustom">
          <x-checkbox name="bcc" [(value)]="model.bcc"></x-checkbox>
          <p class="label">{{ 'send-copy-to-manager-group' | transloco }}</p>
        </section>

        <ng-container *ngIf="data.fields">
          <section class="mt-4">
            <p class="label">{{ 'field' | transloco }}</p>
            <x-select
              [(value)]="model.report_chapter_field"
              name="report_chapter_field"
              [options]="fieldsOptions"
            ></x-select>
          </section>
        </ng-container>
      </div>
    </form>
  </x-dialog>

  <ng-template #qaLimitExceededTemplate>
    <x-dialog [closeOnly]="true" (handleClose)="dialogRef.close()" [title]="'qa-limit-reached' | transloco">
      <p class="text-base text-gray-900">
        <ng-container *ngIf="dailyLimitExceeded && !totalLimitExceeded">
          {{ 'qa-limit-reached-daily' | transloco }}
        </ng-container>
        <ng-container *ngIf="totalLimitExceeded">
          {{ 'qa-limit-reached-total' | transloco }}
        </ng-container>
      </p>
    </x-dialog>
  </ng-template>
</ng-container>
<x-spinner-overlay class="fixed" [isLoading]="isLoading$ | async"></x-spinner-overlay>
