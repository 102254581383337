import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Transform } from '@core/models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Project } from '../models';
import { PORTAL_API_URL } from './constants';

@Injectable({
  providedIn: 'root',
})
export class AdminsService {
  readonly apiUrl = `${PORTAL_API_URL}/admin`;

  constructor(private http: HttpClient) {}

  fetchProjectAdmins(): Observable<Project.Admin[]> {
    return this.http.get<Project.Admin[]>(`${this.apiUrl}/admins`).pipe(
      map((admins) =>
        _.map(admins, (admin) => ({
          ...admin,
          user: Transform.user(admin.user),
        }))
      )
    );
  }

  fetchProjectAdminRoles(): Observable<Project.Admin.Role[]> {
    return this.http.get<Project.Admin.Role[]>(`${this.apiUrl}/admins/roles`);
  }
}
