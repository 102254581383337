import { OverlayRef } from '@angular/cdk/overlay';
import { PortalModule } from '@angular/cdk/portal';
import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { CallPipeModule, DocumentDetailsPipeModule, DocumentPhotoPipeModule, SpinnerModule } from '@common';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { Document, Link } from '@core/models';
import { TranslocoModule } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QaDetailsDialogFieldsComponent } from '@portal/components/qa-overview/qa-details-dialog-fields/qa-details-dialog-fields.component';
import { TicketDetailsDialogFieldsComponent } from '@portal/customer/components/asset-details/asset-tickets-container/components/ticket-details-dialog-fields/ticket-details-dialog-fields.component';
import { FindingDetailsModule } from '@portal/customer/components/asset-details/findings/finding-details';
import {
  ProcessedReference,
  Reference,
  RefType,
} from '@portal/customer/components/attach-reference-button/reference.model';
import { CustomerRoutingService, FindingsService } from '@portal/customer/services';
import { LinkHelperService } from '@portal/services/link-helper.service';
import _isEqual from 'lodash/isEqual';
import { QAService } from '../../services/qa.service';
import { ModalDetailsData } from '@app/app.component';

@UntilDestroy()
@Component({
  selector: 'x-references-preview',
  standalone: true,
  imports: [
    CommonModule,
    CallPipeModule,
    DocumentDetailsPipeModule,
    DocumentPhotoPipeModule,
    TranslocoModule,
    SvgIconModule,
    DialogComponent,
    QaDetailsDialogFieldsComponent,
    TicketDetailsDialogFieldsComponent,
    SpinnerModule,
    FindingDetailsModule,
    PortalModule,
  ],
  providers: [QAService, FindingsService],
  templateUrl: './references-preview.component.html',
  styleUrls: ['./references-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReferencesPreviewComponent implements OnInit, OnChanges {
  @Input() assetId!: string;
  @Input() canRemoveReference: boolean;
  @Input() attachedReferencesList: Reference[] | ProcessedReference[] = [];
  @Input() showLabels = true;
  @Input() smallThumbnail = false;

  @Output() referenceRemoved = new EventEmitter<string>();

  @Input() ticketDetailsDialogTemplate?: TemplateRef<HTMLElement>;
  @Input() qaDetailsDialogTemplate?: TemplateRef<HTMLElement>;
  @Input() findingDetailsDialogTemplate?: TemplateRef<HTMLElement>;

  overlayRef: OverlayRef;

  parsedReferences: ProcessedReference[];

  private readonly customerRouting = inject(CustomerRoutingService);
  private readonly linkHelperService = inject(LinkHelperService);
  private readonly findingsService = inject(FindingsService);
  private readonly cdr = inject(ChangeDetectorRef);

  ngOnInit() {
    this.parsedReferences = this.attachedReferencesList.map((ref) => this.parseReference(ref));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!_isEqual(changes?.attachedReferencesList?.currentValue, changes?.attachedReferencesList?.previousValue)) {
      this.parsedReferences = this.attachedReferencesList.map((ref) => this.parseReference(ref));
      this.cdr.detectChanges();
    }
  }

  openModal(detailsModalData: ModalDetailsData) {
    this.overlayRef = window.openTemplateOverlay(detailsModalData);
  }

  parseReference = (ref: Reference | ProcessedReference): ProcessedReference =>
    this.isProcessed(ref)
      ? {
          ...ref,
          icon: !ref.icon ? this.getPreviewIcon(ref.type) : ref.icon,
          document: !ref.document && ref.type === 'photo' ? this.getPhotoDocument(ref) : ref.document,
        }
      : {
          id: ref.ref_id,
          type: ref.ref_type,
          icon: this.getPreviewIcon(ref.ref_type),
          current_no: ref.ref_data?.['current_no'],
          name:
            ref.ref_data?.['name'] || ref.ref_data?.['message'] || ref.ref_data?.['finding'] || ref.ref_data?.['task'],
          page: ref.ref_data?.['source_page'],
          document: ref.ref_type === 'photo' ? this.getPhotoDocument(ref) : undefined,
        };

  openPreview(ref: ProcessedReference) {
    switch (ref.type) {
      case 'qa':
        this.openQuestion(ref.id);
        break;
      case 'document':
        this.linkHelperService.open(
          this.customerRouting.getDataroomSourceLink(ref.id, this.assetId, ref.page),
          'document'
        );
        break;
      case 'finding':
        this.openFinding(ref);
        break;
      case 'ticket':
        this.openTicket(ref.id);
        break;
      case 'report':
        this.linkHelperService.open(
          this.customerRouting.getAssetReportTemplateViewerLink(this.assetId, ref.id, true),
          'report'
        );
    }
  }

  onRemoveReference(processedReference: ProcessedReference, $event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.referenceRemoved.emit(processedReference.id);
  }

  onOpenPhoto(document: Document): void {
    window.openMediaDocument(document);
  }

  trackByFn(index: number) {
    return index;
  }

  private isProcessed(ref: Reference | ProcessedReference): ref is ProcessedReference {
    const missingProperties = ['id', 'type', 'name'].filter((key) => ref[key] === undefined);
    return missingProperties.length === 0;
  }

  private getPhotoDocument(ref: Reference | ProcessedReference) {
    let link: Link;
    if (this.isProcessed(ref)) {
      link = {
        target_id: ref.id,
        target_description: ref.name,
        target_type: 'photo',
        target_anchor: ref.id,
      } as Link;
    } else {
      link = {
        target_description: ref.ref_data['name'],
        target_id: ref.ref_data['id'],
        target_type: ref.ref_type,
        target_anchor: ref.ref_data['id'],
      } as Link;
    }

    return Link.toShortDocument(link, this.assetId);
  }

  private openFinding(ref: ProcessedReference): void {
    const detailsModalData: ModalDetailsData = {
      dialogType: 'finding',
      data$: this.findingsService.getFinding({ finding_id: ref.id, asset_id: this.assetId }).pipe(untilDestroyed(this)),
    };
    this.openModal(detailsModalData);
  }

  private openQuestion(questionId: string) {
    window.openQaSidepanel(questionId);
  }

  private openTicket(ticketId: string) {
    window.openTicketSidepanel(ticketId);
  }

  private getPreviewIcon(refType: RefType): AvailableIcons {
    switch (refType) {
      case 'qa':
        return 'chat-bubble-left-right';
      case 'photo':
        return 'photo';
      case 'finding':
        return 'exclamation-triangle';
      case 'ticket':
        return 'ticket';
      case 'report':
        return 'document-text';
      case 'document':
        return 'folder';
      default:
        return 'document';
    }
  }
}
