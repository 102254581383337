<div class="relative w-6 h-6">
  <x-svg-icon [svgImageName]="$any(icon)" [svgClass]="multipleIcon ? '' : 'w-6 h-6 !m-0'"></x-svg-icon>
  <div
    *ngIf="hasDotIndicator"
    class="absolute box-content rounded-full bg-primary-400 h-1.5 w-1.5 -top-1 right-0 border-2 border-white"
  ></div>
  <div
    *ngIf="statusIcon"
    class="absolute status-icon box-content -right-[3.5px] -bottom-[3.5px]"
    [ngClass]="{ '-right-[4.5px] -bottom-[4.5px]': multipleIcon }"
  >
    <x-svg-icon [svgImageName]="statusIcon" [svgClass]="'h-4 w-4'"></x-svg-icon>
  </div>
</div>
