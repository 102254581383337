<ng-container *transloco="let t; read: 'history-dialog'">
  <x-dialog
    title="{{ t('title', { value: data.message.current_no }) }}"
    (handleClose)="dialogRef.close()"
    [closeOnly]="true"
  >
    <ag-grid-angular
      #grid
      class="ag-theme-xportal"
      [gridOptions]="gridOptions"
      [modules]="modules"
      [columnDefs]="columnDefs"
      [rowData]="history$ | async"
    ></ag-grid-angular>
  </x-dialog>
</ng-container>
