import { createAction, props } from '@ngrx/store';

import { AssetOverview } from '../../../../core/models';

export namespace PortalProjectAssetsActions {
  export const fetch = createAction('[Project Assets] Fetch', props<{ project_id: string }>());
  export const fetchForCurrentProject = createAction('[Project Assets] Fetch For Current Project');
  export const fetchSuccess = createAction('[Project Asset] Fetch Success', props<{ assets: AssetOverview[] }>());
  export const fetchFail = createAction('[Project Asset] Fail');
  export const removeAssetForProject = createAction(
    '[Project Assets] Remove For Project',
    props<{
      asset_id: string;
      project_id: string;
    }>()
  );
}
