import { AfterViewInit, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { User } from '@core/models';
import { AdminsService, UserService } from '@core/services';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, switchMap, takeUntil } from 'rxjs/operators';
import { SHORT_DEBOUNCE_TIME } from '@app/utils/rxjs.utils';

export interface CreateAdminData {
  role_id: User.AdminRole;
  user: User;
}

@Component({
  selector: 'x-add-admin-dialog',
  templateUrl: './add-admin-dialog.component.html',
  styleUrls: ['./add-admin-dialog.component.scss'],
})
export class AddAdminDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('form') form: NgForm;
  @ViewChild('userSelect') userSelectCtrl: ElementRef<HTMLSelectElement>;

  filteredOptions$: Observable<User[]>;
  model = {} as CreateAdminData;

  readonly destroy$ = new Subject<void>();
  readonly roles$ = this.adminsService.fetchProjectAdminRoles();

  constructor(
    private dialogRef: MatDialogRef<AddAdminDialogComponent, CreateAdminData>,
    private userService: UserService,
    private adminsService: AdminsService
  ) {}

  get isModelFilled(): boolean {
    return _.isObject(this.model?.user) && _.isString(this.model?.role_id);
  }

  displayFn(user: User): string {
    return user ? `${user.firstname} ${user.lastname}` : '';
  }

  ngAfterViewInit(): void {
    this.filteredOptions$ = this.form.valueChanges.pipe(
      takeUntil(this.destroy$),
      debounceTime(SHORT_DEBOUNCE_TIME),
      filter((model) => {
        if (!model || !model.user || !_.isString(model.user)) {
          return;
        }
        const { user } = model;
        return user.length > 2;
      }),
      map(({ user }) => user),
      switchMap((user) =>
        this.userService.fetchListOfUsers(user).pipe(map((members) => _.map(members, (member) => member.user)))
      )
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onSubmit(): void {
    this.dialogRef.close(this.model);
  }
}
