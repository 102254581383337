<ng-container *ngIf="finding as finding">
  <a
    class="text-gray-700 inline-flex flex-row cursor-pointer rounded-xl hover:bg-gray-200 items-center p-1.5"
    href="#{{ finding.id }}"
    title="{{ 'finding-no' | transloco }}&nbsp;{{ finding.current_no }}"
    (click)="selectFinding(finding, $event)"
  >
    <x-svg-icon class="h-6 w-6" svgClass="text-gray-700" svgImageName="exclamation-triangle"></x-svg-icon>
    <span class="ml-2.5" *ngIf="!useIconsOnly">{{ 'finding-no' | transloco }}{{ finding.current_no }}</span>
  </a>
</ng-container>

<ng-container *ngIf="photos">
  <mat-menu #menu="matMenu" [hasBackdrop]="true" (close)="selectPhoto()" class="finding-photo-preview">
    <mat-icon
      class="close-preview"
      (click)="menuTrigger?.closeMenu(); $event.stopPropagation(); $event.preventDefault()"
    >
      close
    </mat-icon>
  </mat-menu>

  <mat-menu #menuPlaceholder="matMenu" class="menu-placeholder"></mat-menu>

  <ng-container *ngFor="let photo of photos; trackBy: trackByFn">
    <div
      *ngIf="photosAndDocsIds.includes(photo.id)"
      class="link photo mr-1 cursor-pointer text-primary-600"
      [title]="photo.name"
      (click)="openPhotoPreview(photo, $event)"
    >
      <span *ngIf="!useIconsOnly">
        {{ photo.name }}
      </span>
      <div class="reference-photo-thumbnail-container">
        <ng-container *ngIf="getCachedImage(photo.id) | async; else newImageTemplate">
          <ng-container *ngIf="photo.id | documentPhoto : false : photo | async as imageSrc">
            <ng-container *ngTemplateOutlet="photoThumbnail; context: { $implicit: imageSrc }"></ng-container>
          </ng-container>
        </ng-container>
        <ng-template #newImageTemplate>
          <ng-container
            *ngIf="
              (photo.id | documentDetails | async)?.download_token | documentPhoto : false : photo | async as imageSrc
            "
          >
            <ng-container *ngTemplateOutlet="photoThumbnail; context: { $implicit: imageSrc }"></ng-container>
          </ng-container>
        </ng-template>
        <ng-template #photoThumbnail let-imageSrc>
          <img
            class="reference-photo-thumbnail"
            [alt]="photo.name"
            [src]="imageSrc"
            [ngClass]="{ small: useIconsOnly }"
          />
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-container *ngIf="report">
  <a
    *ngIf="getReportLink(report); let href"
    class="link doc"
    (click)="onOpenReport(href); $event.preventDefault()"
    [target]="null"
    [title]="report.name"
  >
    {{ report.name }}
  </a>
</ng-container>

<ng-container *ngIf="reportChapter">
  <a
    *ngIf="getReportChapterLink(assetId$ | async, reportChapter); let href"
    class="link doc"
    (click)="onOpenReport(href); $event.preventDefault()"
    [target]="null"
    [title]="reportChapter.title"
  >
    {{ reportChapter.title }}
  </a>
</ng-container>

<x-documents-preview
  *ngIf="isReferenceThumbnailShowed && documents?.length > 0"
  [documents]="documents"
  [messageCategory]="'qa'"
  [messageId]=""
  [isDownloadDocumentPossible]="true"
></x-documents-preview>

<ng-container *ngIf="!isReferenceThumbnailShowed">
  <ng-container *ngIf="documents?.[0]; let doc">
    <ng-container
      *ngIf="photosAndDocsIds.includes(doc.id)"
      [ngTemplateOutlet]="documentTemplate"
      [ngTemplateOutletContext]="{ doc }"
    ></ng-container>
  </ng-container>

  <ng-container *ngIf="documents?.[1]; let doc">
    <ng-container
      *ngIf="photosAndDocsIds.includes(doc.id)"
      [ngTemplateOutlet]="documentTemplate"
      [ngTemplateOutletContext]="{ doc }"
    ></ng-container>
  </ng-container>

  <x-badge
    class="ml-0.5"
    *ngIf="photos?.length + documents?.length > maxPreviewThumbnails"
    badgeType="secondary"
    text="+{{ (photos?.length || 0) + (documents?.length || 0) - (maxPreviewThumbnails || 0) }}"
  ></x-badge>
</ng-container>

<ng-template #documentTemplate let-doc="doc">
  <a
    class="text-primary-600 inline-flex flex-row rounded-xl items-center p-1.5"
    [ngClass]="{
      'cursor-not-allowed': !getIsViewableOnline(doc),
      'cursor-pointer hover:bg-gray-200': getIsViewableOnline(doc)
    }"
    [matTooltip]="!getIsViewableOnline(doc) ? ('document-cannot-be-viewed-online' | transloco) : ''"
    [href]="getDocumentLink(doc)"
    [target]="targetBlank ? '_blank' : null"
    [title]="doc.name"
    (click)="onOpenDocument(doc, $event)"
  >
    <x-svg-icon class="h-6 w-6" svgClass="text-gray-700" svgImageName="folder"></x-svg-icon>
    <ng-container *ngIf="!useIconsOnly">
      {{ doc.name }}
    </ng-container>
    <x-spinner *ngIf="isOpeningWithinTokenPreview" class="spinner" size="small"></x-spinner>
  </a>
</ng-template>

<ng-template #spinner>
  <x-spinner></x-spinner>
</ng-template>
