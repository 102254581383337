import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpSentEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { NetworkIdleService } from '@core/services/network-idle.service';

const TIME_TO_WAIT_FOR_NEXT_REQUESTS = 500;

@Injectable()
export class IdleInterceptor implements HttpInterceptor {
  private readonly networkIdleService = inject(NetworkIdleService);
  private pendingRequests = 0;

  intercept(request: HttpRequest<never>, next: HttpHandler): Observable<HttpEvent<HttpSentEvent>> {
    this.pendingRequests++;
    return next.handle(request).pipe(
      finalize(() => {
        this.pendingRequests--;
        this.updateIdleStatusIfNeeded();
      })
    );
  }

  private updateIdleStatusIfNeeded() {
    setTimeout(() => {
      if (this.pendingRequests === 0) {
        this.networkIdleService.setIdleStatus(true);
      } else {
        this.networkIdleService.setIdleStatus(false);
      }
    }, TIME_TO_WAIT_FOR_NEXT_REQUESTS);
  }
}
