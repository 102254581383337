import { Pipe, PipeTransform } from '@angular/core';
import { Member, User } from '@core/models';
import { FormatUserNameService } from '@core/services';

@Pipe({
  name: 'userName',
})
export class UserNamePipe implements PipeTransform {
  constructor(private readonly formatUserNameService: FormatUserNameService) {}

  transform(userOrMember: User | Member | undefined): string {
    return this.formatUserNameService.format(userOrMember);
  }
}
