import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NumberOfObjects } from '@core/models';

@Component({
  selector: 'x-number-of-objects-label',
  templateUrl: './number-of-objects-label.component.html',
  styleUrls: ['./number-of-objects-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NumberOfObjectsLabelComponent {
  // eslint-disable-next-line id-blacklist
  @Input() number: NumberOfObjects;

  readonly NumberOfObjects = NumberOfObjects;
}
