<x-ticket-form
  [assetId]="assetId$ | async"
  (valueChanges)="onFormValueChange($event)"
  [groups]="data.groups"
  [trades]="data.trades"
  [userId]="(user$ | async).id"
  [assetMembers]="data.members"
  [initialValues]="data?.initialValues"
></x-ticket-form>

<ng-template #primaryActionsTemplate>
  <div class="flex space-x-2">
    <x-button color="white" (handleClick)="onClose()">
      {{ 'cancel' | transloco }}
    </x-button>
    <x-button color="primary" (handleClick)="onSubmit()" [disabled]="!canSubmit || isLoading">
      {{ 'tickets-system.create-ticket-btn-text' | transloco }}
      <x-spinner color="accent" [size]="'small'" *ngIf="isLoading"></x-spinner>
    </x-button>
  </div>
</ng-template>

<ng-template #requiredFieldsInfo>
  <x-required-information></x-required-information>
</ng-template>
