<div
  class="image-container"
  *ngIf="photos && photos.length; else noPhotosTemplate"
  [ngClass]="{ 'has-image': (isLoading$ | async) === false || isPanorama }"
>
  <div class="image" *ngIf="isPhoto">
    <x-image-viewer
      [zoomAtInit]="zoomAtInit"
      [src]="getPhotoSource | call : currentPhoto : photoService : isLoading$ : useExistingTokens | async"
    ></x-image-viewer>
  </div>

  <ng-container *ngIf="isPanorama && currentPhoto">
    <x-panorama-viewer
      [active]="true"
      [photos]="[currentPhoto]"
      [selectedDocument]="currentPhoto"
      [newDownloadTokenGetter]="newDownloadTokenGetter"
      (click)="$event.stopPropagation()"
    ></x-panorama-viewer>
  </ng-container>

  <ng-container *ngIf="isVideo && currentPhoto">
    <x-video-viewer
      [source]="getVideoSource | call : currentPhoto | async"
      [ngClass]="{ hidden: (isLoading$ | async) === true }"
    ></x-video-viewer>
  </ng-container>

  <ng-container *ngIf="photos.length">
    <div class="image-prev" title="{{ 'previous' | transloco }}" (click)="onClickPrevImage(); $event.stopPropagation()">
      <span class="icon-font icon-font-small-down"></span>
    </div>
    <div class="image-next" title="{{ 'next' | transloco }}" (click)="onClickNextImage(); $event.stopPropagation()">
      <span class="icon-font icon-font-small-up"></span>
    </div>
  </ng-container>
</div>

<ng-template #noPhotosTemplate>
  <div class="no-photos-container">
    <span *ngIf="!hasFilter">{{ 'no-photos' | transloco }}</span>
    <span *ngIf="hasFilter">{{ 'no-photos-try-to-change-the-filter' | transloco }}</span>
  </div>
</ng-template>

<ng-content></ng-content>
