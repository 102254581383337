import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  ColDef,
  ColumnApi,
  GridApi,
  GridOptions,
  GridReadyEvent,
  ITooltipParams,
  Module,
  RowClickedEvent,
  RowDataChangedEvent,
  RowNode,
  ValueGetterParams,
} from '@ag-grid-community/core';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DestroyComponent, LabelComponent, LabelComponentInjectors } from '@common';
import { AvailableLanguages } from '@core/models';
import { FormatDateService } from '@core/services/format-date.service';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { Observable, ReplaySubject } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { ColumnsPanelSetting } from './columns-panel/columns-panel.component';
import { BaseGrid } from './constants';
import { GridHelperService } from './grid-helper.service';

export interface GridSortModel {
  colId: string;
  sort: 'desc' | 'asc';
}

@UntilDestroy()
@Component({
  template: '',
})
export class BaseGridComponent<T> extends DestroyComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() preselectedId: string;
  @Output() gridReady = new EventEmitter<void>();

  columnDefs: ColDef[];
  defaultColDef: ColDef = BaseGrid.defaultColDef;
  settingsColumns: ColumnsPanelSetting<T>[] = [];
  reloadOnLanguageSwitch = true;

  readonly gridOptions: GridOptions = {
    ...BaseGrid.defaultGridOptions,
    onGridReady: (event) => this.onGridReady(event),
    onRowClicked: (event: RowClickedEvent) => {
      if (event?.event?.defaultPrevented || event.event?.['shiftKey']) {
        return;
      }
      this.onRowClicked(event.data, event);
    },
    onCellClicked: (event) => {
      if (event.colDef?.onCellClicked) {
        event.event?.preventDefault();
      }
    },
    onModelUpdated: () => this.onModelUpdated(),
    onRowDataChanged: (event: RowDataChangedEvent) => this.onRowDataChanged(event),
    suppressRowClickSelection: false,
    columnHoverHighlight: true,
    suppressColumnVirtualisation: true,
    debounceVerticalScrollbar: true, // 'false' setting causes artifacts when scrolling up and down with high amount of data (i.e. when selecting photo reference)
    rowBuffer: 15,
  };
  readonly modules: Module[] = [ClientSideRowModelModule];
  readonly resize: 'fit' | 'auto' | 'none' = 'fit';
  readonly fitSizeCorrectionPx: number = 0;
  readonly rowData$: Observable<T[]> | ReplaySubject<T[]>;

  protected gridReadyEvent?: GridReadyEvent;

  constructor(
    protected componentRef: ElementRef<HTMLElement>,
    protected formatDateService: FormatDateService,
    protected readonly gridHelper: GridHelperService,
    protected readonly transloco: TranslocoService
  ) {
    super();
  }

  get columnApi(): ColumnApi | undefined {
    return this.gridApi ? this.gridReadyEvent?.columnApi : undefined;
  }

  get gridApi(): GridApi | undefined {
    return this.gridReadyEvent?.api?.['destroyCalled'] ? undefined : this.gridReadyEvent?.api;
  }

  @HostListener('window:resize')
  onWindowResize() {
    this.resizeGrid();
  }

  hideLoadingOverlay(): void {
    this.gridApi?.hideOverlay();
  }

  ngAfterViewInit(): void {
    this.resizeGrid();
  }

  ngOnDestroy(): void {
    this.gridReadyEvent = undefined;
  }

  ngOnInit(): void {
    this.columnDefs = this.getColumnDefs();

    if (this.reloadOnLanguageSwitch) {
      this.transloco.langChanges$
        .pipe(
          map((lang) => lang as AvailableLanguages),
          mergeMap((lang) => this.transloco.selectTranslation(lang).pipe(map(() => lang))),
          untilDestroyed(this)
        )
        .subscribe({
          next: () =>
            setTimeout(() => {
              this.gridApi?.redrawRows();
            }, 0),
        });
    }
  }

  onModelUpdated(): void {
    const selectedNodes = this.gridApi?.getSelectedNodes() || [];
    const selectedRowId = selectedNodes.length === 1 ? selectedNodes[0].id : undefined;
    if (selectedRowId) {
      this.highlightRow(selectedRowId);
    }
  }

  onRowDataChanged(event: RowDataChangedEvent) {
    if (this.preselectedId) {
      const node = this.gridApi.getRowNode(this.preselectedId);
      if (node) {
        this.highlightRow(node.id);
        this.onRowClicked(node.data);
        this.preselectedId = undefined;
      }
    }
  }

  highlightRow(rowId: string): void {
    const node = this.gridApi?.getRowNode(rowId);
    node?.setSelected(true, true);
    this.gridApi?.ensureNodeVisible(node);
  }

  onPanelOpened(panelOpened?: boolean): void {
    this.resizeGrid();
  }

  onRowClicked(data: T, event?: RowClickedEvent): void {}

  onToggleColumnVisibility({ columnId, hide }: ColumnsPanelSetting<T>): void {
    this.columnApi?.setColumnVisible(columnId as string, !hide);
    this.settingsColumns = this.getSettingsColumns();
    this.resizeGrid();
  }

  setColumnVisible(colId: keyof T | 'checkbox', visible: boolean): void {
    if (this.columnApi) {
      this.columnApi?.getColumn(colId)?.setVisible(visible);
    } else {
      const colDef = this.columnDefs?.find((columnDef) => columnDef.colId === colId);
      if (colDef) {
        colDef.hide = !visible;
      }
    }
  }

  showLoadingOverlay(): void {
    this.gridApi?.showLoadingOverlay();
  }

  protected createInjector(colDef: ColDef): Injector {
    const displayName = colDef.headerComponentParams?.displayName;
    return Injector.create({
      providers: [
        { provide: LabelComponentInjectors.Field, useValue: _.isUndefined(displayName) ? colDef.field : displayName },
        { provide: LabelComponentInjectors.Tooltip, useValue: true },
      ],
    });
  }

  protected getCheckboxColDef(showMasterCheckbox = true, colDef?: ColDef): ColDef {
    return this.gridHelper.getCheckboxColDef(showMasterCheckbox, colDef);
  }

  protected getColDef(field: keyof T | null, colDef: Partial<ColDef> = {}): ColDef {
    const componentPortal = this.getLabelPortal({ field: field as string, ...colDef });
    return this.gridHelper.getColDef({
      colDef: {
        ...colDef,
        field: field as string,
      },
      defaultColDef: this.defaultColDef,
      componentPortal,
    });
  }

  protected getColumnDefs(): ColDef[] {
    return [];
  }

  protected getDateCellRenderer(date: Date | string, includeTime = false): string {
    return this.gridHelper.getDateCellRenderer(date, includeTime);
  }

  protected getDateColDef(field: keyof T, colDef: Partial<ColDef> = {}, includeTime = false): ColDef {
    return this.getColDef(
      field,
      _.defaultsDeep(colDef, <ColDef>{
        cellRenderer: ({ value }) => this.getDateCellRenderer(value, includeTime),
        tooltipValueGetter: ({ value }) => this.getDateCellRenderer(value, includeTime),
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected getLabelPortal(colDef: ColDef): ComponentPortal<LabelComponent<any>> | null {
    return null;
  }

  protected getSettingsColumns(): ColumnsPanelSetting<T>[] {
    const ignoredColumns = _(this.columnApi?.getAllColumns())
      .filter((column) => column.getColDef().suppressColumnsToolPanel === true)
      .map((columnd) => columnd.getColId())
      .value();

    const colDefs = _(this.getColumnDefs())
      .keyBy(({ field }) => field)
      .mapValues()
      .value();

    return _(this.columnApi?.getColumnState())
      .filter((state) => ignoredColumns.indexOf(state.colId) === -1)
      .map(
        (columnState) =>
          <ColumnsPanelSetting<T>>{
            columnId: columnState.colId,
            displayName:
              colDefs[columnState.colId]?.headerComponentParams?.displayName ||
              colDefs[columnState.colId]?.field ||
              columnState.colId,
            hide: columnState.hide,
          }
      )
      .value();
  }

  protected getUserCellValueGetter(): (params: ValueGetterParams) => string {
    return this.gridHelper.getUserCellValueGetter();
  }

  protected getUserColDef(field: keyof T, colDef: Partial<ColDef> = {}): ColDef {
    return this.getColDef(
      field,
      _.defaultsDeep(colDef, <ColDef>{
        valueGetter: this.getUserCellValueGetter(),
        tooltipValueGetter: (params: ITooltipParams) => params.value,
      })
    );
  }

  protected onGridReady(event: GridReadyEvent): void {
    this.gridReadyEvent = event;
    this.resizeGrid();
    this.settingsColumns = this.getSettingsColumns();
    this.gridReady.emit();
  }

  protected resizeGrid(): void {
    if (!this.columnApi) {
      return;
    }

    if (this.resize === 'none') {
      return;
    }

    if (this.resize === 'auto') {
      this.columnApi.autoSizeAllColumns();
      return;
    }

    const nativeElement = this.componentRef.nativeElement;
    let { width } = nativeElement.getBoundingClientRect();
    const nativeElementStyles = document.defaultView.getComputedStyle(nativeElement);
    const paddingsWidth =
      Number.parseInt(nativeElementStyles.paddingLeft) + Number.parseInt(nativeElementStyles.paddingRight);

    const sidePanel = this.componentRef.nativeElement.querySelector('x-columns-panel');

    if (sidePanel) {
      const sidePanelWidth = sidePanel.getBoundingClientRect().width;
      const sidePanelMargin = Number.parseInt(document.defaultView.getComputedStyle(sidePanel).marginLeft);
      width -= sidePanelWidth + sidePanelMargin;
    }

    if (width) {
      this.columnApi.sizeColumnsToFit(width - paddingsWidth - this.fitSizeCorrectionPx - 17); // extract extra 17px for the vertical scrollbar
    }
  }

  protected overrideAfterSortForExpandedRowsFeature(
    gridOptions: GridOptions,
    isParent: (node: RowNode) => boolean,
    groupingId: string
  ): void {
    gridOptions.postSort = (allNodes: RowNode[]): void => {
      const childNodes: RowNode[] = [];
      const sortedNodes: RowNode[] = [];

      allNodes.forEach((node) => {
        if (isParent(node)) {
          sortedNodes.push(node);
        } else {
          childNodes.push(node);
        }
      });

      childNodes.reverse().forEach((childNode) => {
        const parentIndex = sortedNodes.findIndex((node) => node.data[groupingId] === childNode.data[groupingId]);
        sortedNodes.splice(parentIndex + 1, 0, childNode);
      });

      allNodes.length = 0;
      allNodes.push(...sortedNodes);
    };
  }
}
