import { inject, Injectable } from '@angular/core';
import {
  AddReferencesToSystemDialogComponent,
  AddReferencesToSystemDialogComponentData,
} from '@portal/customer/components/asset-details/asset-tickets-container/components/add-references-to-system-dialog/add-references-to-system-dialog.component';
import { DocumentLocation, MessageCategory, QAService } from '@portal/customer/services';
import { filter, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Document } from '@core/models';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { isNotNil } from '@app/utils';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';

export interface OpenAddRefsDialogData {
  action$: Observable<unknown>;
  documents: Document.Short[];
  preselectedDocumentId?: string;
  showSkipAndContinue?: boolean;
  messageCategory: MessageCategory;
}

@Injectable({
  providedIn: 'root',
})
export class CollaborationService {
  private readonly dialog = inject(MatDialog);
  private readonly qaService = inject(QAService);
  private readonly store = inject(Store);
  private readonly assetId$: Observable<string> = this.store
    .select(CustomerRouterSelectors.getAssetId)
    .pipe(filter(isNotNil));

  readonly openAddReferencesToSystemDialog = ({
    action$,
    documents,
    preselectedDocumentId,
    showSkipAndContinue = true,
    messageCategory,
  }: OpenAddRefsDialogData) =>
    this.dialog.open(AddReferencesToSystemDialogComponent, {
      data: <AddReferencesToSystemDialogComponentData>{
        documents,
        preselectedDocumentId,
        onSkipAndContinueClick: showSkipAndContinue ? () => action$ : undefined,
        onAddSelected: (documentLocations: DocumentLocation[]) =>
          this.handleAddSelectedLocationsToModules(documentLocations, messageCategory).pipe(switchMap(() => action$)),
      },
    });

  handleAddSelectedLocationsToModules(documentLocations: DocumentLocation[], messageCategory: MessageCategory) {
    return this.assetId$.pipe(
      take(1),
      switchMap((assetId: string) => this.qaService.moveAttachments(assetId, documentLocations, messageCategory))
    );
  }
}
