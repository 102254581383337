import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { CommonModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { PortalDocumentTagComponent } from './portal-document-tag.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { TagsInputComponent } from '@portal/components/tags-input/tags-input.component';

@NgModule({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatChipsModule,
    ReactiveFormsModule,
    FormsModule,
    MatAutocompleteModule,
    TranslocoModule,
    DialogComponent,
    BadgeComponent,
    TagsInputComponent,
  ],
  declarations: [PortalDocumentTagComponent],
  exports: [PortalDocumentTagComponent],
})
export class PortalDocumentTagModule {}
