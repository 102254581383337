<header
  class="flex justify-between align-center py-6 mx-6"
  [ngClass]="{ 'border-b border-gray-200': !data?.hideHeadingLine, 'pb-0': data?.noHeadingPadding }"
>
  <span class="text-xl font-semibold leading-2 text-gray-700">
    {{ data?.title || '' }}
  </span>
  <x-svg-icon
    class="text-gray-400 cursor-pointer hover:bg-gray-200 rounded-2xl"
    svgClass="w-7 h-7"
    svgImageName="cross"
    (click)="close()"
  ></x-svg-icon>
</header>
<section class="flex-1 pt-2 overflow-auto" [ngClass]="data?.classes">
  <div class="flex relative h-full" *ngIf="data?.contentTemplate; let contentTemplate">
    <ng-container
      *ngTemplateOutlet="contentTemplate; context: { $implicit: data.contentTemplateContext }"
    ></ng-container>
  </div>
  <div class="flex relative h-full" *ngIf="portal">
    <ng-container *cdkPortalOutlet="portal"></ng-container>
  </div>
</section>
<footer
  *ngIf="data?.showFooter !== false && (data?.showFooter$ | async) !== false"
  class="flex justify-between bg-gray-100 items-center h-16 px-6 py-2 border-t border-gray-200"
>
  <div class="flex">
    <ng-container *ngIf="data?.additionalFooterActionsTemplate; let actionsTemplate">
      <ng-container *ngTemplateOutlet="actionsTemplate; context: { $implicit: data?.data }"></ng-container>
    </ng-container>
  </div>
  <ng-container *ngIf="data?.primaryActionsTemplate; else defaultPrimaryActionsTemplate; let primaryActionsTemplate">
    <ng-container *ngTemplateOutlet="primaryActionsTemplate"></ng-container>
  </ng-container>
  <ng-template #defaultPrimaryActionsTemplate>
    <div class="flex space-x-2">
      <x-button color="white" (handleClick)="dialogRef.close()">
        {{ data?.cancelBtnText || ('dialog.close' | transloco) }}
      </x-button>
      <x-button
        *ngIf="!data?.hideSubmit"
        color="primary"
        (handleClick)="dialogRef.close(true)"
        [disabled]="submitDisabled"
      >
        {{ data?.submitBtnText || ('dialog.submit' | transloco) }}
      </x-button>
    </div>
  </ng-template>
</footer>

<ng-template #discardChangesDialogContent>
  <span class="pb-4">{{ 'confirmation-discard-changes' | transloco }}</span>
</ng-template>
