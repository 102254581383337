import { HttpClient, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, last, map, tap } from 'rxjs/operators';
import { ApiErrorResponse } from '../models';
import { API_URL, PORTAL_API_URL } from './constants';
import { dateTimeOptions } from './format-date.service';
import { LanguageService } from './language-service';
import { UploadTrackerService } from './upload-tracker.service';

export enum UploadStatus {
  Success = 201,
  NoMultipart = 400,
  NoToken = 404,
  MaxSize = 413,
}

export interface BatchParams {
  asset_id: string;
  node_ids: string[];
  omit_labels_truncation?: boolean;
}

export interface BatchParamsCheckResult {
  max_node_path_length: number;
}

export const MAX_WINDOWS_STORAGE_PATH_LENGTH = 210;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  readonly apiUrl = `${API_URL}/storage/v1`;
  readonly downloadUrl = `${this.apiUrl}/download`;
  readonly imagesCache = new Map<string, Observable<Blob>>();

  constructor(
    private readonly http: HttpClient,
    private readonly uploadTrackerService: UploadTrackerService,
    private readonly languageService: LanguageService
  ) {}

  upload(
    upload_token: string,
    file: File,
    suppress404?: boolean,
    completeCallback?: () => Promise<unknown>
  ): Observable<void> {
    const url = `${this.apiUrl}/upload`;
    const formData = new FormData();
    formData.append('token', upload_token);
    formData.append('file', file);

    return this.http
      .post(url, formData, {
        responseType: 'text',
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        tap(async (event) => {
          this.showProgress(event, file);
          if (event.type === HttpEventType.Response) {
            await completeCallback?.();
          }
        }),
        last(), // return last (completed) message to caller
        map(() => undefined),
        catchError((error: ApiErrorResponse) => {
          if (suppress404 && error.status === 404) {
            completeCallback?.();
            return of(undefined);
          }
          this.uploadTrackerService.handleUploadError(error, file);
          return throwError(error);
        })
      );
  }

  /**
   * Downloads image in one of three size versions: 1600 | 360 | 120.
   *
   * @param download_token
   * @param version - It allows us to specify the "ver" query parameter to download the thumbnail version of a photo uploaded to the photos section.
   * @param useCache - If true, gets image from the cache when available, otherwise caches the result
   * @param cacheId - If defined uses id to get value from cache instead of download_token
   * Currently, we support 1600px, 360px and 120px thumbnails, if "ver" is not specified then the original version of the file is returned
   */
  getImageWithDownloadToken(
    { download_token }: { download_token: string },
    useCache = false,
    cacheId?: string
  ): Observable<Blob> {
    cacheId = cacheId || download_token;
    const getImageFn = (download_token: string) =>
      this.http
        .get(`${this.downloadUrl}/${download_token}`, {
          responseType: 'blob',
          headers: {
            'Cache-Control': 'public',
          },
        })
        .pipe(
          tap((imageBlob) => {
            this.imagesCache.set(cacheId, of(imageBlob));
          })
        );
    if (useCache && this.imagesCache.has(cacheId)) {
      return this.imagesCache.get(cacheId);
    } else {
      return getImageFn(download_token);
    }
  }

  downloadDocument(
    { download_token, name }: { download_token: string; name?: string },
    saveLocally = false
  ): Observable<Blob> {
    const url = `${this.downloadUrl}/${download_token}`;
    return this.http.get(url, { responseType: 'blob' }).pipe(
      map((blob) => {
        if (saveLocally) {
          name = name || 'Downloaded at ' + new Date().toLocaleString(this.languageService.locale, dateTimeOptions);
          this.openDownloadDialog(name, blob);
        }

        return blob;
      })
    );
  }

  downloadDocumentWithProperExtension({ download_token, name }: { download_token: string; name?: string }) {
    const url = `${this.downloadUrl}/${download_token}`;
    const link = document.createElement('a');
    link.download = name;
    link.href = url;
    link.click();
  }

  batchDownloadDocuments({ asset_id, node_ids, omit_labels_truncation = false }: BatchParams): Observable<string> {
    const url = `${PORTAL_API_URL}/assets/${asset_id}/batch_downloads`;
    return this.http.post<string>(url, { node_ids, omit_labels_truncation });
  }

  batchDownloadDocumentsCheck({ asset_id, node_ids }: BatchParams) {
    const url = `${PORTAL_API_URL}/assets/${asset_id}/batch_downloads/check-max-node-path-length`;
    return this.http.post<BatchParamsCheckResult>(url, { node_ids });
  }

  openDownloadDialog(name: string, blob: Blob | string): void {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.setProperty('display', 'none');

    const url = typeof blob === 'string' ? blob : window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);

    document.body.removeChild(a);
  }

  removeItemFromCache(cacheId: string) {
    if (this.imagesCache.has(cacheId)) {
      this.imagesCache.delete(cacheId);
    }
  }

  getFilenameFromContentDispositionHeader(headers: HttpHeaders): string | null {
    const contentDispositionHeader = headers.get('Content-Disposition');
    if (contentDispositionHeader) {
      const filenameRegex = /filename="([^"]+)/;
      const matches = contentDispositionHeader.match(filenameRegex);
      if (matches && matches.length > 1) {
        return decodeURIComponent(matches[1]).replace(/['"]/g, '').trim();
      }
    }
    return null;
  }

  private showProgress(event: HttpEvent<string>, file: File): void {
    this.uploadTrackerService.handleUploadEvent(event, file);
  }
}
