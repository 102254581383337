import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

@Component({
  selector: 'x-user-avatar',
  templateUrl: './user-avatar.component.html',
  imports: [CommonModule, SvgIconModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class UserAvatarComponent {
  @Input() initials = '';
  @Input() src = '';
  @Input() classes = 'h-10 w-10';
}
