<ng-container [ngSwitch]="module">
  <ng-container *ngSwitchCase="AdditionalModules.AreaCertification">
    {{ 'area-certification' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.AreaPlausibilisation">
    {{ 'area-plausibilisation' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.ContractNegotiations">
    {{ 'contract-negotiations' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.CreatingDataroom">{{ 'creating-dataroom' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.EnvironmentalDD">
    {{ 'environmental-dd' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.MarketValueAssessment">
    {{ 'market-value-assessment' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.MarketValueIndication">
    {{ 'market-value-indication' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.Nd2OpinionForExistingValueExpertise">
    {{ 'nd-2-opinion-for-existing-value-expertise' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.QAPprocess">{{ 'qa-process' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.RelianceLetter">{{ 'reliance-letter' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="AdditionalModules.RetailImpactAnalysis">
    {{ 'retail-impact-analysis' | transloco }}
  </ng-container>

  <ng-container *ngSwitchDefault>{{ module }}</ng-container>
</ng-container>
