<div
  [xHyphenate]="true"
  [innerHtml]="value | safeHtml"
  (click)="onClick($event)"
  class="text-md font-normal line-clamp-4 text-ellipsis overflow-hidden break-words"
></div>

<div
  class="menu-trigger"
  #menuTrigger="matMenuTrigger"
  [style.left.px]="left"
  [style.top.px]="top"
  [matMenuTriggerFor]="menu"
></div>

<mat-menu #menu="matMenu" [hasBackdrop]="true">
  <div class="norm-viewer" *ngIf="norm$ | async as norm; else spinner" (click)="$event.stopPropagation()">
    <div class="title">
      <b>{{ norm?.name }}</b>
      <mat-icon (click)="menuTrigger.closeMenu(); $event.stopPropagation(); $event.preventDefault()">close</mat-icon>
    </div>
    <p>{{ norm.description }}</p>
    <a [href]="norm?.url" target="_blank">Link</a>
  </div>
</mat-menu>

<ng-template #spinner>
  <div class="relative">
    <span class="icon-progress"></span>
  </div>
</ng-template>
