<ng-container [ngSwitch]="frequency">
  <ng-container *ngSwitchCase="Frequency.Biweekly">{{ 'biweekly' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Frequency.DependConstructionProgress">
    {{ 'depend-construction-progress' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="Frequency.Monthly">{{ 'monthly' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Frequency.Weekly">{{ 'weekly' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ frequency }}</ng-container>
</ng-container>
