import { Project, ProjectListItem } from '@core/models';
import { UserActions } from '@core/state';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as _ from 'lodash';
import { ProjectActions } from './portal-projects.actions';

export interface ProjectAdapterEntity {
  project: ProjectListItem | Project;
  logoUri?: string;
}

export const adapter: EntityAdapter<ProjectAdapterEntity> = createEntityAdapter<ProjectAdapterEntity>({
  selectId: ({ project }) => project?.id,
});

const projectsInitialState = adapter.getInitialState({
  entities: {},
  ids: [],
});

const reducer = createReducer<EntityState<ProjectAdapterEntity>>(
  projectsInitialState,
  on(ProjectActions.fetchSuccess, (state, action) =>
    adapter.setAll(
      _.map(action.projects, (project) => ({ project })),
      state
    )
  ),
  on(ProjectActions.fetchFail, (state) => adapter.setAll([], state)),
  on(ProjectActions.fetchDetailsSuccess, (state, { project }) => adapter.upsertOne({ project }, state)),
  on(ProjectActions.setProjectLogoUri, (state, { id, logoUri }) =>
    adapter.updateOne({ id, changes: { logoUri } }, state)
  ),
  on(UserActions.clearUser, (state) => adapter.removeAll(state))
);

export namespace PortalProjectsReducer {
  export const name = 'portal-projects';

  export const portalProjectsReducer = (state: EntityState<ProjectAdapterEntity> | undefined, action: Action) =>
    reducer(state, action);
}
