import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PORTAL_API_URL } from './constants';

@Injectable({
  providedIn: 'root',
})
export class UnitsService {
  readonly url = `${PORTAL_API_URL}/units`;

  constructor(private readonly http: HttpClient) {}

  fetch(): Observable<string[]> {
    return this.http.get<string[]>(this.url);
  }
}
