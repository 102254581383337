import { HttpClient } from '@angular/common/http';
import { inject, Injectable, TemplateRef } from '@angular/core';
import { isNotNil, isTruthy } from '@app/utils';
import { SidePanelDialogService } from '@portal/customer/services/side-panel-dialog.service';
import { Message } from '@core/models';
import { PORTAL_API_URL, TradeService, UserService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import {
  CreateTicketDialogComponent,
  CreateTicketDialogData,
} from '@portal/customer/components/asset-details/asset-tickets-container/components/create-ticket-dialog/create-ticket-dialog.component';
import { MessageDetailsDialogComponent } from '@portal/customer/components/asset-details/asset-collaboration/collaboration/message-details-dialog';
import { Reference } from '@portal/customer/components/attach-reference-button/reference.model';
import { GetTicketsFilters, QAService } from '@portal/customer/services';
import { CustomerRouterSelectors, PermissionsSelectors } from '@portal/customer/state';
import { combineLatest, Observable } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import { StoredTemplatesService } from '@portal/customer/services/stored-templates.service';

export interface TicketsOverviewByPriority {
  high: number;
  medium: number;
  low: number;
}

export interface TicketsOverviewByStatus {
  answered: number;
  closed: number;
  open: number;
}

export interface TicketsOverviewResponseData {
  by_priority: TicketsOverviewByPriority;
  by_status: TicketsOverviewByStatus;
}

@Injectable({
  providedIn: 'root',
})
export class TicketsService {
  readonly store = inject(Store);
  readonly http = inject(HttpClient);
  readonly qaService = inject(QAService);
  readonly sidePanel = inject(SidePanelDialogService);
  readonly transloco = inject(TranslocoService);
  readonly userService = inject(UserService);
  readonly tradeService = inject(TradeService);
  readonly storedTemplates = inject(StoredTemplatesService);

  readonly assetId$: Observable<string> = this.store.select(CustomerRouterSelectors.getAssetId).pipe(filter(isNotNil));
  readonly groups$ = this.assetId$.pipe(
    switchMap((asset_id: string) => this.qaService.getGroups({ asset_id }, 'tickets'))
  );
  readonly members$ = this.assetId$.pipe(switchMap((assetId) => this.userService.getAssetMembers(assetId)));
  protected readonly apiUrl = `${PORTAL_API_URL}/assets`;

  // Requirement: User can still create tickets of Task type if only "View" permission is set.
  readonly canCreateTicket$ = this.store.select(PermissionsSelectors.canView, 'ticket');

  openCreateTicketDialog(
    reference: Reference,
    scope: Message.Scope = 'general',
    message?: string
  ): Observable<boolean> {
    return combineLatest([this.groups$, this.members$, this.tradeService.trades$]).pipe(
      take(1),
      switchMap(([groups, members, trades]) => {
        const dialogData: CreateTicketDialogData = {
          scope,
          groups,
          members,
          trades,
          initialValues: {
            references: [reference],
            message,
          },
        };
        return this.sidePanel
          .open<CreateTicketDialogData>({
            title: this.transloco.translate('tickets-system.add-ticket-modal-title'),
            component: CreateTicketDialogComponent,
            submitBtnText: this.transloco.translate('tickets-system.add-ticket-modal-submit-btn-text'),
            data: dialogData,
          })
          .ref.afterClosed()
          .pipe(take(1), filter(isTruthy));
      })
    );
  }

  openTicketDetailsDialog(id: string) {
    return this.assetId$.pipe(
      take(1),
      switchMap((asset_id) => this.qaService.getTicket({ asset_id, id })),
      tap((message) => {
        this.sidePanel.open({
          title: this.transloco.translate('tickets-system.ticket-details-title', {
            ticketNumber: message.current_no,
          }),
          showFooter: false,
          data: this.getTicketDetailsDialogData(message, this.storedTemplates.getTicketFieldsTemplate()),
          component: MessageDetailsDialogComponent,
          hideSubmit: true,
        });
      })
    );
  }

  openQaDetailsDialog(qid: string) {
    return this.assetId$.pipe(
      take(1),
      switchMap((assetId) => this.qaService.getMessage({ asset_id: assetId, qid })),
      tap((message) => {
        this.sidePanel.open({
          title: this.transloco.translate('message-details.title', {
            value: message.current_no,
          }),
          showFooter: false,
          data: this.getQaDetailsDialogData(message),
          component: MessageDetailsDialogComponent,
          hideSubmit: true,
        });
      })
    );
  }

  getTicketsOverviewData(
    assetId: string,
    filters: GetTicketsFilters,
    until?: Date
  ): Observable<TicketsOverviewResponseData> {
    const url = `${this.apiUrl}/${assetId}/messages/tickets/overview`;
    return this.http.get<TicketsOverviewResponseData>(url, {
      params: this.qaService.prepareMessagesFilterParams(filters, until),
    });
  }

  private getQaDetailsDialogData(message: Message) {
    return {
      message,
      fieldsTemplate: this.storedTemplates.getQaFieldsTemplate(),
      submit: undefined,
      submitFiles: undefined,
      removeFiles: undefined,
      saveDraft: undefined,
      canWriteResponse: false,
      previewOnly: true,
      category: 'qa',
      approveMessage: () => undefined,
      rejectMessage: () => undefined,
      forwardMessage: () => undefined,
    };
  }

  private getTicketDetailsDialogData(message: Message, fieldsTemplate: TemplateRef<HTMLElement>) {
    return {
      message,
      fieldsTemplate,
      submit: undefined,
      submitFiles: undefined,
      removeFiles: undefined,
      saveDraft: undefined,
      acceptResponse: () => undefined,
      rejectResponse: () => undefined,
      forwardTicket: () => undefined,
      extendDueDate: () => undefined,
      setGracePeriod: () => undefined,
      canWriteResponse: false,
      previewOnly: true,
    };
  }
}
