import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'x-icon',
  template: '',
  styleUrls: ['./icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconComponent {
  @Input() iconFont?: string;

  @HostBinding('class')
  get cssClass(): string {
    return this.iconFont ? `icon-font icon-font-${this.iconFont}` : '';
  }
}
