<div class="add-container" *ngIf="isAllowedToCreateTracker$ | async">
  <ng-container *ngIf="assetId$ | async as assetIdValue">
    <x-button *ngIf="project$ | async as project" class="m-1" (click)="onAddTracker(project, assetIdValue)">
      <x-svg-icon svgImageName="plus" svgClass="w-4 h-4 text-white mr-2"></x-svg-icon>
      <span>{{ 'schedule' | transloco }}</span>
    </x-button>
  </ng-container>
</div>
<div class="grid-container" [ngClass]="{ 'min-h-[480px]': !!assetId }">
  <ag-grid-angular
    #grid
    class="ag-theme-xportal"
    [defaultColDef]="defaultColDef"
    [gridOptions]="gridOptions"
    [rowData]="rowData$ | async"
    [columnDefs]="columnDefs"
    [modules]="modules"
  ></ag-grid-angular>

  <x-columns-panel
    [columns]="settingsColumns"
    [fieldTemplate]="trackerLabelTemplate"
    (panelOpened)="onPanelOpened($event)"
    (toggleVisibility)="onToggleColumnVisibility($event)"
  ></x-columns-panel>
</div>

<ng-template #trackerLabelTemplate let-field>
  <x-portal-tracker-label [fieldName]="field"></x-portal-tracker-label>
</ng-template>

<ng-template #trackerDeleteFailedTemplate>
  <span>{{ 'asset-tracker-delete-failed' | transloco }}</span>
</ng-template>

<ng-template #trackerUpdateFailedTemplate>
  <span>{{ 'asset-tracker-update-failed' | transloco }}</span>
</ng-template>
