import { ConnectionPositionPair } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MAT_DIALOG_DEFAULT_OPTIONS, MatDialogRef } from '@angular/material/dialog';
import { ISelectOption } from '@common/components/select/select.model';
import { Group, Message, Report } from '@core/models';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { QASearch, QAService } from '@portal/customer/services';
import { CustomerRouterSelectors, ResolvedDataSelectors } from '@portal/customer/state';
import * as _ from 'lodash';
import { BehaviorSubject, combineLatest, EMPTY } from 'rxjs';
import { catchError, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { isNotNil } from '../../../../utils';
import { Priority } from '@core/models/priority';
import { Reference, References } from '@portal/customer/components/attach-reference-button/reference.model';
import { ToastService, ToastType } from '@core/toast';

export interface QAAskDialogParams {
  fields?: Report.Field[];
  finding_id?: string;
  groups: Group[];
  showReferenceSelector?: boolean;
}

@UntilDestroy()
@Component({
  templateUrl: './qa-ask-dialog.component.html',
  styleUrls: ['./qa-ask-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        overlayPanelClass: 'qa-ask-dialog-overlay',
      },
    },
  ],
})
export class QAAskDialogComponent {
  isShowPredefined = false;
  selectedGroupType?: string;
  selectedMessage?: string;
  dailyLimitExceeded = false;
  totalLimitExceeded = false;
  isLoading$ = new BehaviorSubject(true);

  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId).pipe(filter(isNotNil));
  readonly isProjectManager$ = this.store.select(ResolvedDataSelectors.getGroup).pipe(
    filter(isNotNil),
    map(({ type }) => type === 'project_manager')
  );
  readonly model: Message.NewQuestion = <Message.NewQuestion>{
    scope: 'general',
  };
  readonly predefinedMessages$ = this.isProjectManager$.pipe(
    filter((isProjectManager) => isProjectManager),
    switchMap(() => combineLatest([this.assetId$, this.tagsQuestionsFetch$])),
    switchMap(([asset_id, filterSearch]) => {
      if (filterSearch) {
        this.tagsChanges$.next(true);
      }
      return this.qaService.getPredefinedMessages(asset_id, filterSearch);
    }),
    shareReplay(1),
    untilDestroyed(this)
  );

  readonly predefinedTags$ = this.predefinedMessages$.pipe(
    map((messages) => _.flatMap((messages || []).map(({ tags }) => tags)))
  );
  readonly priorityOptions: ISelectOption<Priority>[] = [
    {
      value: 'high',
      label: this.transloco.translate('high'),
    },
    {
      value: 'medium',
      label: this.transloco.translate('medium'),
    },
    {
      value: 'low',
      label: this.transloco.translate('low'),
    },
  ];

  groupsOptions: ISelectOption<string>[] = [];
  fieldsOptions: ISelectOption<string>[] = [];
  availableRefOptions: string[] = [References.DOCUMENT, References.REPORT, References.PHOTO, References.FINDING];

  readonly predefinedMessagesOptions$ = this.predefinedMessages$.pipe(
    map((messages) =>
      (messages || []).map(({ message }) => ({
        value: message,
        label: message,
      }))
    ),
    untilDestroyed(this)
  );

  readonly tagsChanges$ = new BehaviorSubject<boolean>(false);
  readonly tagsQuestionsFetch$ = new BehaviorSubject<QASearch | undefined>(undefined);
  readonly typeCustom: Group.Type = 'custom';

  readonly cdkConnectedOverlayPositions: ConnectionPositionPair[] = [
    new ConnectionPositionPair({ originX: 'end', originY: 'top' }, { overlayX: 'start', overlayY: 'top' }),
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QAAskDialogParams,
    public dialogRef: MatDialogRef<QAAskDialogParams, Message.NewQuestion>,
    private readonly qaService: QAService,
    private readonly store: Store,
    private readonly transloco: TranslocoService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toast: ToastService
  ) {
    this.groupsOptions = this.data?.groups?.map((group) => ({
      value: group.id,
      label: group.name,
    }));
    this.fieldsOptions = this.data?.fields?.map((field) => ({
      value: field.title,
      label: field.title,
    }));

    this.model.finding_id = data?.finding_id;

    if (data?.groups?.length === 1) {
      this.model.to_group_id = _.head(data?.groups)?.id as string;
    }

    this.assetId$
      .pipe(
        switchMap((assetId) => this.qaService.getCurrentQaLimitStatistics(assetId)),
        catchError(() => {
          this.toast.showToast(this.transloco.translate('unknown-error-please-try-again'), ToastType.ERROR);
          this.isLoading$.next(false);
          this.dialogRef.close();
          return EMPTY;
        }),
        take(1),
        untilDestroyed(this)
      )
      .subscribe((limits) => {
        this.dailyLimitExceeded = limits.daily_limit && limits.daily_use >= limits.daily_limit;
        this.totalLimitExceeded = limits.total_limit && limits.total_use >= limits.total_limit;
        this.isLoading$.next(false);
        this.cdr.markForCheck();
      });
  }

  onCancelPredefinedMessage(): void {
    this.isShowPredefined = false;
    this.selectedMessage = undefined;
  }

  onSelectMessage(message: string): void {
    this.selectedMessage = message;
    this.model.message = this.selectedMessage;
    this.isShowPredefined = false;
    this.selectedMessage = undefined;
    this.tagsQuestionsFetch$.next(undefined);
  }

  onShowPredefined(): void {
    this.isShowPredefined = true;
  }

  onSubmit(): void {
    this.dialogRef.close(this.model);
  }

  onClose(): void {
    this.dialogRef.close(null);
  }

  onTagsChanged(tags: string[]): void {
    this.tagsQuestionsFetch$.next({ tags });
    this.selectedMessage = undefined;
  }

  selectGroup(groupId): void {
    this.selectedGroupType = this.data.groups.find((group) => group.id === groupId)?.type;
    if (this.selectedGroupType !== this.typeCustom) {
      this.model.bcc = undefined;
    }
  }

  onReferenceAdded([ref]: Reference[]) {
    if (ref) {
      this.model.reference = ref;
    }
    this.cdr.detectChanges();
  }

  onReferenceRemoved() {
    this.model.reference = undefined;
    this.cdr.detectChanges();
  }
}
