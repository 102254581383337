import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Member, Transform, User } from '@core/models';
import { environment } from '@environments';
import { Store } from '@ngrx/store';
import { UserFilters } from '@portal/customer/components/user-search/user-search.component';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { UserActions } from '@core/state';
import { DecodedTokenData } from './auth-data';
import { API_URL, PORTAL_API_URL } from './constants';
import { ResolvedDataSelectors } from '@portal/customer/state';
import { UsersAndGroupsCacheService } from '@portal/services/users-and-groups-cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  readonly apiUrl = API_URL;
  readonly portalApiUrl = PORTAL_API_URL;
  readonly user$ = new BehaviorSubject<User.ActiveUser | undefined>(undefined);
  readonly http = inject(HttpClient);
  readonly store = inject(Store);
  readonly usersAndGroupsCacheService = inject(UsersAndGroupsCacheService);

  private currentProjectIdSnapshot: string;

  constructor() {
    this.store
      .select(ResolvedDataSelectors.getProjectId)
      .subscribe((projectId) => (this.currentProjectIdSnapshot = projectId));
  }

  getActiveUserData(): User.ActiveUser | undefined {
    return this.user$.value;
  }

  fetchUser(appToken: DecodedTokenData, authToken?: string): Observable<User.ActiveUser> {
    return this.http
      .get<User.ActiveUser>(
        `${API_URL}/${environment.portalApiUrl}/users/me`,
        authToken
          ? {
              headers: {
                Authorization: `Bearer ${authToken}`,
              },
            }
          : {}
      )
      .pipe(
        switchMap((user) => {
          const usr = <User.ActiveUser>{
            ...(Transform.user(user) as User.ActiveUser),
            withAdminAccess: appToken?.with_admin_access,
          };

          this.user$.next(usr);
          this.store.dispatch(UserActions.fetchUserSuccess({ user: usr }));

          return of(user);
        }),
        catchError((error) => {
          this.store.dispatch(UserActions.fetchUserFail());
          return throwError(error);
        })
      );
  }

  clearUser(): void {
    this.store.dispatch(UserActions.clearUser());
    this.user$.next(undefined);
  }

  recoverPassword(email: string): Observable<unknown> {
    return this.http
      .post<void>(`${API_URL}/${environment.portalApiUrl}/password/forgot`, { email })
      .pipe(switchMap(() => of()));
  }

  changePassword(password: string, confirm_password: string, token: string): Observable<unknown> {
    return this.http
      .post<void>(`${API_URL}/${environment.portalApiUrl}/password/reset/${token}`, {
        password,
        confirm_password,
      })
      .pipe(
        switchMap(() => of(null)),
        catchError((error: HttpErrorResponse) => throwError(error))
      );
  }

  fetchListOfUsers(queryParams?: string): Observable<Member[]> {
    let query = '';
    if (queryParams) {
      query = `?query=${queryParams}`;
    }
    const url = `${this.apiUrl}/${environment.portalApiUrl}/admin/users${query}`;
    return this.http.get<Member[]>(url).pipe(map((members) => _.map(members, (member) => Transform.member(member))));
  }

  searchUser(email: string) {
    const url = `${this.apiUrl}/${environment.portalApiUrl}/admin/users/search`;
    return this.http.post(url, { email });
  }

  fetchListOfAdmins(): Observable<Member[]> {
    return this.http
      .get<Member[]>(`${this.apiUrl}/${environment.portalApiUrl}/admin/admins`)
      .pipe(map((members) => _.map(members, (member) => Transform.member(member))));
  }

  createAdmin(data: { user_id: string; role_id: User.AdminRole }): Observable<void> {
    if (!data || !data.user_id) {
      return of();
    }

    return this.http.post<void>(`${this.apiUrl}/${environment.portalApiUrl}/admin/admins`, data);
  }

  updateAdminGroup(data: { user_id: string; role_id: User.AdminRole }): Observable<Member> {
    if (!data || !data.user_id) {
      return of();
    }

    return this.http
      .put<Member>(`${this.apiUrl}/${environment.portalApiUrl}/admin/admins`, data)
      .pipe(map((member) => Transform.member(member)));
  }

  deleteAdmin({ id }: User.Admin): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/${environment.portalApiUrl}/admin/admins/${id}`);
  }

  inviteUser(email: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${environment.portalApiUrl}/admin/users`, { email });
  }

  setUserStatus({ id }: User, status: User.Status): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${environment.portalApiUrl}/admin/users/${id}/status`, { status });
  }

  getAssetMembers(asset_id: string): Observable<Member[]> {
    const url = `${this.apiUrl}/${environment.portalApiUrl}/assets/${asset_id}/users`;

    const cachedAssetMembers = this.usersAndGroupsCacheService.getMembersFromCache(this.currentProjectIdSnapshot);
    if (cachedAssetMembers) {
      return of(cachedAssetMembers);
    }

    return this.http.get<Member[]>(url).pipe(
      map((members) =>
        _.map(
          members.filter((member) => !!member.user),
          (member) => Transform.member(member)
        )
      ),
      tap((members) => {
        this.usersAndGroupsCacheService.setMembersCache(this.currentProjectIdSnapshot, members);
      })
    );
  }

  getFilteredAssetMembers(asset_id: string, userFilters: UserFilters): Observable<Member[]> {
    const url = `${this.apiUrl}/${environment.portalApiUrl}/assets/${asset_id}/users/search`;
    return this.http.get<Member[]>(url, {
      params: <HttpParams>_(userFilters).omitBy(_.isUndefined).value(),
    });
  }

  addAssetUser(
    asset_id: string,
    data: { users: string[]; group: string; limit_access_to_asset: boolean }
  ): Observable<Member[]> {
    this.usersAndGroupsCacheService.discardMembersCache(this.currentProjectIdSnapshot);
    const url = `${this.apiUrl}/${environment.portalApiUrl}/assets/${asset_id}/users`;
    return this.http.post<Member[]>(url, data);
  }

  changeUserGroup({
    asset_id,
    group_id,
    member_id,
  }: {
    asset_id: string;
    group_id: string;
    member_id: string;
  }): Observable<void> {
    this.usersAndGroupsCacheService.discardMembersCache(this.currentProjectIdSnapshot);
    const url = `${this.portalApiUrl}/assets/${asset_id}/users/${member_id}/groups/${group_id}`;
    return this.http.post<void>(url, null);
  }

  removeAssetUser(asset_id: string, member_id: string): Observable<void> {
    this.usersAndGroupsCacheService.discardMembersCache(this.currentProjectIdSnapshot);
    const url = `${this.apiUrl}/${environment.portalApiUrl}/assets/${asset_id}/users/${member_id}`;
    return this.http.delete<void>(url);
  }

  resendAssetUserInvitation(asset_id: string, member_id: string): Observable<Member> {
    const url = `${this.apiUrl}/${environment.portalApiUrl}/assets/${asset_id}/users/${member_id}/resendInvitation`;
    return this.http.post<Member>(url, null);
  }
}
