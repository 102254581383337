import { Injectable } from '@angular/core';
import { CanLoad, Route, UrlSegment } from '@angular/router';
import { AppRoute } from '../../app-routing.service';

const portalPaths: string[] = [
  AppRoute.Admin,
  AppRoute.Customer,
  AppRoute.Auth,
  AppRoute.Reset,
  AppRoute.EmailConfirm,
  AppRoute.ConfirmInvitation,
  AppRoute.Imprint,
  AppRoute.DataProtection,
  AppRoute.User,
];

@Injectable()
export class RoutingPortalGuard implements CanLoad {
  constructor() {} // private navigationService: NavigationService

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    // const host = window.location.host.toLowerCase();
    // const isPortal = host.indexOf(AppRoute.Portal.toLowerCase()) !== -1 || TenantHelper.IS_TENANT || environment.production;

    // if (!environment.production || !isPortal) {
    //   // console.log(RoutingPortalGuard.name, 'not a portal or production');
    //   return true;
    // }

    // const { paths } = this.navigationService;
    // console.log(RoutingPortalGuard.name, { isPortal, paths: paths.join(', '), route: route.path });
    // if (portalPaths.indexOf(paths[0]) === -1 || (paths[0] === AppRoute.Auth && !route.path)) {
    //   this.navigationService.navigateAccordingToLocale(AppRoute.Customer);
    //   return false;
    // }

    return portalPaths.indexOf(route.path) !== -1;
  }
}
