<x-dialog
  [title]="'2fa.two-factor-authorization-required' | transloco"
  [submitDisabled]="
    (useRecoveryCodeForm ? !recoveryCodeControl.valid || !recoveryCodeControl.value : enteredDigits.length !== 6) ||
    hasError
  "
  [submitLoading]="isLoading"
  [hideCloseIcon]="true"
  (handleSubmit)="handleSubmit()"
  [cancelBtnText]="(useRecoveryCodeForm ? 'go-back' : 'cancel') | transloco"
  [submitBtnText]="'sign-in' | transloco"
  (handleClose)="handleClose()"
>
  <x-svg-icon svgImageName="2fa-security-icon" svgClass="h-20"></x-svg-icon>
  <ng-container *ngIf="!useRecoveryCodeForm; else recoveryCodeTemplate">
    <p class="mt-4 mb-2.5 text-gray-800">{{ '2fa.enter-six-digits-code-to-login' | transloco }}</p>
    <x-digits-code
      [digitsNumber]="TWO_FACTOR_AUTH_CODE_LENGTH"
      (codeChange)="onCodeChange($event)"
      (codeSubmit)="handleSubmit()"
    ></x-digits-code>
    <p *ngIf="hasError" class="text-danger-700 mt-2.5">
      {{ '2fa.provided-verification-code-is-invalid' | transloco }}
    </p>
    <p
      class="text-sm text-right text-primary-600 cursor-pointer select-none mt-8 self-end"
      (click)="onLostAccessClick()"
    >
      {{ '2fa.i-lost-access-to-my-authenticator-device' | transloco }}
    </p>
  </ng-container>
</x-dialog>

<ng-template #recoveryCodeTemplate>
  <p class="mt-4 mb-2.5 text-gray-800">{{ '2fa.enter-your-single-use-recovery-code' | transloco }}</p>
  <x-input [control]="recoveryCodeControl" [maxlength]="10" type="text"></x-input>
  <p class="text-danger-600 mt-2.5" *ngIf="hasError">{{ '2fa.recovery-code-invalid' | transloco }}</p>

  <p class="mt-4 text-xs text-gray-700">{{ '2fa.recovery-code-login-notice' | transloco }}</p>
</ng-template>
