import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { AdditionalModuleLabelComponent } from './additional-module-label';
import { AreaStandardLabelComponent } from './area-standard-label';
import { AreaTypeLabelComponent } from './area-type-label';
import { AssetOverviewLabelModule } from './asset-overview-label';
import { AssetTypeLabelModule } from './asset-type-label';
import { CountryLabelModule } from './country-label/country-label.module';
import { DeploymentLabelModule } from './deployment-label';
import { DepthSurveyLabelComponent } from './depth-survey-label';
import { DocumentLabelModule } from './document-label';
import { FileLabelComponent } from './file-label';
import { FindingLabelModule } from './finding-label';
import { FrequencyLabelComponent } from './frequency-label';
import { LabelComponentInjectors } from './label-component';
import { LanguageLabelModule } from './language-label';
import { MonitoringLabelModule } from './monitoring-label';
import { NumberOfObjectsLabelComponent } from './number-of-objects-label';
import { PermissionLabelModule } from './permission-label';
import { PortalProjectsLabelModule } from './portal-projects-label';
import { PortalTrackerLabelModule } from './portal-tracker-label';
import { QALabelModule } from './qa-label';
import { QuantityLabelComponent } from './quantity-label';
import { ReportLabelModule } from './report-label';
import { RiskLabelModule } from './risk-label';
import { RoleLabelModule } from './role-label';
import { TimeframeLabelComponent } from './timeframe-label';
import { UserLabelModule } from './user-label';
import { ValidationErrorLabelModule } from './validation-error-label';

const components = [
  AdditionalModuleLabelComponent,
  AreaStandardLabelComponent,
  AreaTypeLabelComponent,
  DepthSurveyLabelComponent,
  FileLabelComponent,
  FrequencyLabelComponent,
  NumberOfObjectsLabelComponent,
  QuantityLabelComponent,
  TimeframeLabelComponent,
];

const labelModules = [
  ValidationErrorLabelModule,
  FindingLabelModule,
  QALabelModule,
  AssetOverviewLabelModule,
  PermissionLabelModule,
  MonitoringLabelModule,
  RiskLabelModule,
  CountryLabelModule,
  ReportLabelModule,
  DocumentLabelModule,
  AssetTypeLabelModule,
  UserLabelModule,
  RoleLabelModule,
  PortalTrackerLabelModule,
  PortalProjectsLabelModule,
  LanguageLabelModule,
  DeploymentLabelModule,
];

/**
 * Import only required modules instead of this module
 *
 * @deprecated
 */
@NgModule({
  imports: [TranslocoModule, CommonModule, ...labelModules],
  declarations: components,
  exports: [...components, ...labelModules],
  providers: [
    { provide: LabelComponentInjectors.Field, useValue: undefined },
    { provide: LabelComponentInjectors.Tooltip, useValue: false },
  ],
})
export class LabelsModule {}
