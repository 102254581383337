<ng-container [ngSwitch]="timeframe">
  <ng-container *ngSwitchCase="Timeframe.Week1">{{ 'week-1' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Timeframe.Week2">{{ 'week-2' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Timeframe.Week3">{{ 'week-3' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Timeframe.Week6">{{ 'week-6' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Timeframe.Month2">{{ 'month-2' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="Timeframe.More">{{ 'more-than-2' | transloco }}</ng-container>

  <ng-container *ngSwitchDefault>{{ timeframe }}</ng-container>
</ng-container>
