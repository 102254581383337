import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { CallPipeModule, FindingLabelModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { SpinnerModule } from 'src/app/common/components/spinner';
import { FindingPreviewComponent } from './finding-preview.component';
import { FindingPreviewDirective } from './finding-preview.directive';

@NgModule({
  imports: [CommonModule, CallPipeModule, SpinnerModule, FindingLabelModule, MatDialogModule, TranslocoModule],
  declarations: [FindingPreviewComponent, FindingPreviewDirective],
  exports: [FindingPreviewDirective],
})
export class FindingPreviewModule {}
