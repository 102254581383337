import { Injectable } from '@angular/core';
import { Document } from '@core/models';

@Injectable({
  providedIn: 'root',
})
export class LinkHelperService {
  open(href: string, type?: 'report' | 'photo' | Document.Type, windowSize?: { width: number; height?: number }): void {
    const width = windowSize?.width
      ? windowSize.width
      : Math.min(Math.max(1280, Math.round(screen?.width * 0.4)), screen?.width * 0.8);
    let height = windowSize?.height ? windowSize.height : Math.min(Math.round(width * 1.5), screen?.height);
    let windowName = 'unknown-format';

    const isReport = type === 'report' || href.indexOf('.pdf') !== -1;
    const isPhoto =
      type === 'photo' ||
      href.endsWith('.png') ||
      href.endsWith('.jpg') ||
      href.endsWith('.jpeg') ||
      href.endsWith('.gif');

    if (isReport) {
      windowName = 'report-window';
    } else if (isPhoto) {
      windowName = 'photo-window';
      height = Math.max(720, Math.round(screen?.height * 0.75));
    }

    window.open(href, windowName, `width=${width},height=${height},menubar=0,toolbar=0,status=0`);
  }
}
