import { api } from './api';
import { Disclaimer } from './disclaimer';
import { Group } from './group';
import { Member } from './member';
import { CustomerTracker, Tracker } from './tracker';
import { User } from './user';

export enum Transform {}

export namespace Transform {
  export function date(value: Date | string): Date {
    if (!value) {
      return null;
    }
    value = new Date(value);
    return value.getFullYear() === 1 ? null : value;
  }

  export const group = (value: Group): Group =>
    value && {
      ...value,
      created_at: date(value.created_at),
      deleted_at: date(value.deleted_at),
    };

  export const user = (value: User): User =>
    value && {
      ...value,
      created_at: date(value.created_at),
    };

  export const member = (value: Member): Member =>
    value && {
      ...value,
      user: user(value.user),
      invited_at: date(value.invited_at),
      created_at: date(value.created_at),
      first_access_at: date(value.first_access_at),
      last_access_at: date(value.last_access_at),
      blocked_at: date(value.blocked_at),
      deleted_at: date(value.deleted_at),
    };

  export const tracker = (value: Tracker): Tracker =>
    value && {
      ...value,
      planned_date: date(value.planned_date),
    };

  export const customerTracker = (value: CustomerTracker): CustomerTracker =>
    value && {
      ...value,
      planned_date: date(value.planned_date),
      done_at: date(value.done_at),
    };

  export const disclaimer = (value: Disclaimer): Disclaimer =>
    value && {
      ...value,
      created_at: date(value.created_at),
      deleted_at: date(value.deleted_at),
      updated_at: date(value.updated_at),
    };

  export const searchResult = (value: api.SearchResult): api.SearchResult =>
    value && {
      ...value,
      created_at: date(value.created_at),
      updated_at: date(value.updated_at),
    };
}
