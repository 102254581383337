<ag-grid-angular
  #grid
  class="ag-theme-xportal"
  [defaultColDef]="defaultColDef"
  [gridOptions]="gridOptions"
  [columnDefs]="columnDefs"
  [modules]="modules"
  [rowClass]="'clickable-row'"
  [rowHeight]="rowHeight"
  [rowMultiSelectWithClick]="false"
  [rowData]="rowData"
  (selectionChanged)="onSelectionChanged()"
  (gridColumnsChanged)="onColumnsChanged()"
></ag-grid-angular>

<x-columns-panel
  *ngIf="settingsEnabled"
  [columns]="settingsColumns"
  [fieldTemplate]="documentLabelTemplate"
  (panelOpened)="onPanelOpened($event)"
  (toggleVisibility)="onToggleColumnVisibility($event)"
></x-columns-panel>

<div class="image-preview" [class.visible]="!!photoUrl" title="{{ 'click-to-close-preview' | transloco }}">
  <ng-container *ngIf="!isPanorama">
    <div class="image" [style.backgroundImage]="sanitizer.bypassSecurityTrustStyle(photoUrl)"></div>
    <div class="image-title-container">
      <span class="image-title">{{ rowNode?.data.name }}</span>
      <button class="icon">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="image-navigation-container">
      <div
        class="image-prev"
        title="{{ 'previous' | transloco }}"
        (click)="onClickPrevImage(); $event.stopPropagation()"
      ></div>
      <div
        class="image-next"
        title="{{ 'next' | transloco }}"
        (click)="onClickNextImage(); $event.stopPropagation()"
      ></div>
    </div>
  </ng-container>
  <ng-container *ngIf="isPanorama">
    <x-panorama-viewer
      [active]="true"
      [selectedDocument]="rowNode.data"
      [newDownloadTokenGetter]="newDownloadTokenGetter"
      (click)="$event.stopPropagation()"
      (selectedDocumentChange)="onDocumentClick()"
    ></x-panorama-viewer>
  </ng-container>
</div>

<ng-template #documentLabelTemplate let-field>
  <x-document-label [fieldName]="field"></x-document-label>
</ng-template>

<ng-template #documentDownloadFailTemplate>
  <span>{{ 'cant-download-file' | transloco }}</span>
</ng-template>

<ng-template #documentPublishFailTemplate>
  <span>{{ 'cant-publish-file' | transloco }}</span>
</ng-template>

<ng-template #photoTagFailTemplate>
  <span>{{ 'cant-tag-photo' | transloco }}</span>
</ng-template>
