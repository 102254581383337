import { getAllEnumValues } from './enum';

export enum Frequency {
  Weekly = 'weekly',
  Biweekly = 'biweekly',
  Monthly = 'monthly',
  DependConstructionProgress = 'depend_construction_progress',
}

export namespace Frequency {
  export const all = getAllEnumValues<Frequency>(Frequency);
}
