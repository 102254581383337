import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, forkJoin, from, Observable, of } from 'rxjs';
import { catchError, concatMap, filter, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import {
  ConfirmationDialogComponent,
  ConfirmationDialogData,
} from '@portal/components/confirmation-dialog/confirmation-dialog.component';
import { PORTAL_API_URL, UploadTrackerService, UserService } from '@core/services';
import { FileUploadStatus } from '@portal/customer/components/asset-details/data-room/services';
import { DataRoom, Document } from '@core/models';
import { UPLOAD_FILES_STORE_NAME } from '@app/app.module';

export type MediaFileType = 'photo' | 'panorama' | 'video';

export interface BatchUploadForm {
  target_node_id?: string;
  parent_node_id?: string;
  folders: string[];
  files: string[];
  files_count: number;
  files_type?: MediaFileType; // for photo batch upload
  permission_option?: DataRoom.PermissionOption;
}

export interface BatchUploadFile {
  id?: string;
  batch_upload_id?: string;
  parent_node_id: string;
  target_node_id?: string;
  filename: string;
  upload_token?: string;
  upload_status?: string;
  target_versioning_id?: string;
  replace_based_on_index_point?: boolean;
  replace_hidden?: boolean;
}

export interface FileStatus {
  id: string;
  status: 'pending' | 'finished';
}

export interface BatchUpload {
  id: string;
  user_id: string;
  creator_group_id: string;
  project_id: string;
  asset_id: string;
  target_node_id: string;
  files_count: number;
  permission_option: DataRoom.PermissionOption;
  folders_map: Map<string, string>;
  uploaded_files: BatchUploadFile[];
  files_status: FileStatus[];
  files_map?: {
    [path: string]: string; // id
  };
}

export type UploadMetadataSection = 'dataroom' | 'photos';

export interface UploadMetadata<T = unknown> {
  userId: string;
  assetId: string;
  fileStatuses: FileUploadStatus[];
  uploadSection: UploadMetadataSection;
  folders?: string[];
  batchUpload?: BatchUpload;
  data: T;
}

export interface SavedUploadMetadata<T = unknown> extends UploadMetadata<T> {
  id: string;
}

export interface StoredRawFile {
  key: string;
  id: string;
  rawFile: File;
}

@Injectable({
  providedIn: 'root',
})
export class UploadResumingService {
  private readonly apiUrl = `${PORTAL_API_URL}/assets`;

  constructor(
    private readonly http: HttpClient,
    private readonly dbService: NgxIndexedDBService,
    private readonly dialog: MatDialog,
    private readonly transloco: TranslocoService,
    private readonly uploadTrackerService: UploadTrackerService,
    private readonly userService: UserService
  ) {
    this.uploadTrackerService.batchUploadActionTrigger$
      .pipe(
        switchMap((upload) => {
          if (upload.batchProgress.action === 'finish') {
            return this.finishBatchUpload(upload.asset_id, upload.upload_id);
          } else if (upload.batchProgress.action === 'cancel') {
            return this.cancelBatchUpload(upload.asset_id, upload.upload_id);
          } else {
            return EMPTY;
          }
        })
      )
      .subscribe();
  }

  resumeUploadsIfExists(
    uploadSection: UploadMetadataSection,
    uploadFilesCallback: (upload: SavedUploadMetadata) => Observable<unknown>
  ) {
    this.shouldResumeUploads(uploadSection)
      .pipe(
        take(1),
        filter((result) => result === true)
      )
      .subscribe(() => {
        this.resumeUploads(uploadSection, uploadFilesCallback);
      });
  }

  resumeUploads(
    uploadSection: UploadMetadataSection,
    uploadFilesCallback: (upload: SavedUploadMetadata) => Observable<unknown>
  ) {
    return of(this.getUploadMetadata(uploadSection))
      .pipe(
        concatMap((uploads) =>
          from(uploads).pipe(
            mergeMap((upload) => this.prepareSavedUploadEntity(upload)),
            concatMap((savedUploadMetadata: SavedUploadMetadata) => uploadFilesCallback(savedUploadMetadata))
          )
        )
      )
      .subscribe();
  }

  stopBatchUpload(asset_id: string, upload_id: string): Observable<void> {
    return this.uploadTrackerService.stopUpload(upload_id).pipe(
      concatMap(() => this.cancelBatchUpload(asset_id, upload_id)),
      tap(() => {
        const metadata = this.getUploadMetadata();
        this.setUploadMetadata(metadata.filter((data) => data.batchUpload.id !== upload_id));
      })
    );
  }

  getFilePath(file: File): string {
    let pathWithFilename = file?.['path'] || file?.webkitRelativePath; // is empty when no directories are used

    // Remove slash at the beginning if exists (on some old browsers implementation differs a bit)
    if (pathWithFilename.startsWith('/')) {
      pathWithFilename = pathWithFilename.substring(1);
    }
    return pathWithFilename ? pathWithFilename : file.name;
  }

  async addUploadData<T>(uploadMetadata: UploadMetadata<T>): Promise<UploadMetadata<T> & { id: string }> {
    try {
      const uploadId = this.uploadTrackerService.generateRandomUUID();
      await this.dbService
        .bulkAdd(
          UPLOAD_FILES_STORE_NAME,
          uploadMetadata.fileStatuses.map(
            (fileStatus): StoredRawFile => ({
              key: fileStatus.id,
              id: fileStatus.id,
              rawFile: fileStatus.rawFile,
            })
          )
        )
        .toPromise();
      const uploadFilesStoreData = this.getUploadMetadata();
      uploadFilesStoreData.push({
        id: uploadId,
        ...{
          ...uploadMetadata,
          fileStatuses: uploadMetadata.fileStatuses.map((fileStatus) => ({ ...fileStatus, rawFile: undefined })),
        },
      });
      this.setUploadMetadata(uploadFilesStoreData);
      return {
        id: uploadId,
        ...uploadMetadata,
      };
    } catch (error) {
      console.error(error);
      console.warn('Could not cache upload data for a upload resuming feature.');
    }
  }

  markFileAsCompleted(uploadId: string, fileStatus: FileUploadStatus) {
    let metadata = this.getUploadMetadata();
    const index = metadata.findIndex((data) => data.id === uploadId);
    const queriedMetadata = metadata[index];
    const updatedMetadata = {
      ...queriedMetadata,
      fileStatuses:
        queriedMetadata?.fileStatuses?.map((status) => {
          if (status.id === fileStatus.id) {
            status.status = 'completed';
          }
          return status;
        }) || [],
    };

    this.dbService
      .delete(UPLOAD_FILES_STORE_NAME, fileStatus.id)
      .pipe(
        take(1),
        catchError((e) => {
          console.error(e);
          return EMPTY;
        })
      )
      .subscribe();

    const isEverythingCompleted =
      !updatedMetadata?.fileStatuses?.length ||
      updatedMetadata.fileStatuses.every((status) => status.status === 'completed');

    if (isEverythingCompleted) {
      this.clearUploadMetadata(updatedMetadata.uploadSection);
    } else {
      metadata = [...metadata.slice(0, index), updatedMetadata, ...metadata.slice(index + 1)];
      this.setUploadMetadata(metadata);
    }
  }

  setUploadTokenForSavedUpload(uploadId: string, fileStatusId: string, uploadToken: string) {
    let metadata = this.getUploadMetadata();
    const index = metadata.findIndex((data) => data.id === uploadId);
    const queriedMetadata = metadata[index];
    const updatedMetadata = {
      ...queriedMetadata,
      fileStatuses:
        queriedMetadata?.fileStatuses?.map((fileUploadStatus) => {
          if (fileUploadStatus.id === fileStatusId) {
            fileUploadStatus.document = {
              ...(fileUploadStatus.document || ({} as Document)),
              upload_token: uploadToken,
            } as Document;
          }
          return fileUploadStatus;
        }) || [],
    };
    metadata = [...metadata.slice(0, index), updatedMetadata, ...metadata.slice(index + 1)];
    this.setUploadMetadata(metadata);
  }

  clearUploadMetadata(scope: 'full' | UploadMetadataSection = 'full'): Observable<boolean> {
    const metadata = this.getUploadMetadata();
    switch (scope) {
      case 'full':
        localStorage.removeItem(UPLOAD_FILES_STORE_NAME);
        return this.dbService.clear(UPLOAD_FILES_STORE_NAME);
      default:
        const filteredMetadata = metadata
          .filter((data) => data.uploadSection === scope)
          .map((savedUpload) => savedUpload.fileStatuses)
          .flat();
        const ids = filteredMetadata.map((data) => data.id);
        this.setUploadMetadata(metadata.filter((data) => data.uploadSection !== scope));
        return this.dbService.bulkDelete(UPLOAD_FILES_STORE_NAME, ids).pipe(
          map(() => true),
          catchError((e) => {
            console.error(e);
            return of(false);
          })
        );
    }
  }

  getBatchUploadStatus(assetId: string, batchUploadId: string): Observable<BatchUpload> {
    const url = `${this.apiUrl}/${assetId}/batch_uploads/${batchUploadId}`;
    return this.http.get<BatchUpload>(url);
  }

  private setUploadMetadata(savedUploadMetadata?: SavedUploadMetadata[]) {
    if (savedUploadMetadata?.length) {
      localStorage.setItem(UPLOAD_FILES_STORE_NAME, JSON.stringify(savedUploadMetadata));
    } else {
      localStorage.removeItem(UPLOAD_FILES_STORE_NAME);
    }
  }

  private getUploadMetadata(scope: 'full' | UploadMetadataSection = 'full'): SavedUploadMetadata[] {
    let uploadStoreMetaData = localStorage.getItem(UPLOAD_FILES_STORE_NAME);
    try {
      uploadStoreMetaData = JSON.parse(uploadStoreMetaData);
    } catch {
      console.warn('Could not parse upload metadata due to invalid structure.');
    }
    const metadata = (uploadStoreMetaData || []) as SavedUploadMetadata[];
    switch (scope) {
      case 'full':
        return metadata;
      default:
        return metadata.filter((data) => data.uploadSection === scope);
    }
  }

  private checkIfHasUploadsToResume(uploadSection: UploadMetadataSection): Observable<boolean> {
    const sectionRelatedUploadMetadata = this.getUploadMetadata(uploadSection);
    return of(sectionRelatedUploadMetadata).pipe(
      switchMap((metadatas) =>
        forkJoin(metadatas.map((metadata) => this.getBatchUploadStatus(metadata.assetId, metadata.batchUpload.id)))
      ),
      map((batchUploadStatuses: BatchUpload[]) =>
        sectionRelatedUploadMetadata.map((metadata) => ({
          ...metadata,
          fileStatuses: [
            ...metadata.fileStatuses.filter((fileStatus) => fileStatus.status === 'completed'),
            ...metadata.fileStatuses
              .filter((fileStatus) => fileStatus.status === 'pending')
              .map((fileStatus) => {
                const currentRecordUploadStatus: BatchUpload = batchUploadStatuses?.find(
                  (batchUploadStatus) => batchUploadStatus.id === metadata.batchUpload.id
                );
                let fileId = '';
                // TODO: Backend should keep consistency for the data. Currently in photo section it's flipped.
                if (uploadSection === 'dataroom') {
                  fileId = currentRecordUploadStatus?.files_map[`/${fileStatus.filePath}`];
                } else {
                  fileId = this.reverseObjectKeysAndValues(currentRecordUploadStatus?.files_map)[fileStatus.filePath];
                }
                const isAlreadyUploaded =
                  currentRecordUploadStatus?.files_status?.find((file) => file.id === fileId)?.status === 'finished';
                if (isAlreadyUploaded) {
                  this.markFileAsCompleted(metadata.id, fileStatus);
                  return {
                    ...fileStatus,
                    status: 'completed',
                  };
                } else {
                  return fileStatus;
                }
              }),
          ],
        }))
      ),
      map((metadata) => metadata.length > 0)
    );
  }

  private reverseObjectKeysAndValues(obj) {
    const reversedObj = {};

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        reversedObj[value] = key;
      }
    }

    return reversedObj;
  }

  private shouldResumeUploads(uploadSection: UploadMetadataSection) {
    return this.checkIfHasUploadsToResume(uploadSection).pipe(
      switchMap((hasUploadsToResume) => {
        if (hasUploadsToResume) {
          return this.dialog
            .open<ConfirmationDialogComponent, ConfirmationDialogData>(ConfirmationDialogComponent, {
              data: {
                title:
                  uploadSection === 'dataroom'
                    ? this.transloco.translate('stopped-dataroom-upload')
                    : this.transloco.translate('stopped-photos-upload'),
                text: this.transloco.translate('shall-we-continue'),
                cancelButtonText:
                  uploadSection === 'dataroom'
                    ? this.transloco.translate('upload-tracker.error.cancel')
                    : this.transloco.translate('no'),
                acceptButtonText: this.transloco.translate('continue'),
                hideCloseIcon: true,
                onCancel: async () => {
                  const savedUploads = this.getUploadMetadata().filter(
                    (upload) => upload.userId === this.userService.user$?.value?.id
                  );
                  for (const savedUpload of savedUploads) {
                    if (savedUpload.batchUpload) {
                      try {
                        await this.cancelBatchUpload(savedUpload.assetId, savedUpload.batchUpload.id).toPromise();
                      } catch {
                        console.warn('Batch upload already cancelled. Proceeding to clear internal metadata.');
                      }
                    }
                    await this.clearUploadMetadata(uploadSection).toPromise();
                  }
                  return false;
                },
                onConfirm() {
                  return true;
                },
              },
            })
            .afterClosed();
        } else {
          return this.clearUploadMetadata(uploadSection);
        }
      }),
      take(1)
    );
  }

  private async prepareSavedUploadEntity(savedUpload: SavedUploadMetadata): Promise<SavedUploadMetadata> {
    const storedRawFiles: StoredRawFile[] = await this.getRawFilesFromIndexedDb(
      savedUpload.fileStatuses.filter((status) => status.status === 'pending').map((status) => status.id)
    ).toPromise();
    return {
      ...savedUpload,
      fileStatuses: savedUpload.fileStatuses.map((fileStatus) => {
        // path needs to be rewritten because it's getting automatically cleared after putting the file into indexedDb
        // it's then used to create a proper folder structure in the upload summary
        if (fileStatus.status === 'pending') {
          fileStatus.rawFile = storedRawFiles.find((storedRawFile) => storedRawFile.id === fileStatus.id).rawFile;
          fileStatus.rawFile['path'] = fileStatus.filePath;
        }
        return fileStatus;
      }),
    };
  }

  private getRawFilesFromIndexedDb(ids: string[]): Observable<StoredRawFile[]> {
    return from(this.dbService.bulkGet(UPLOAD_FILES_STORE_NAME, ids)) as Observable<StoredRawFile[]>;
  }

  private finishBatchUpload(asset_id: string, upload_id: string | undefined): Observable<void> {
    if (!upload_id) {
      return of();
    }

    const url = `${this.apiUrl}/${asset_id}/batch_uploads/${upload_id}/finish`;

    return this.http.post<void>(url, null);
  }

  private cancelBatchUpload(asset_id: string, upload_id: string | undefined): Observable<void> {
    if (!upload_id) {
      return of();
    }

    const url = `${this.apiUrl}/${asset_id}/batch_uploads/${upload_id}/cancel`;

    return this.http.post<void>(url, null);
  }
}
