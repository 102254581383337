import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ResolvedData, ResolvedDataReducer } from './resolver.reducer';

const resolvedDataSelector = createFeatureSelector<ResolvedData>(ResolvedDataReducer.name);

export namespace ResolvedDataSelectors {
  export const getPermission = createSelector(resolvedDataSelector, (data) => data?.permission);

  export const getGlobalTermsConditionsId = createSelector(
    resolvedDataSelector,
    (data) => data?.globalTermsConditionsId
  );
}
