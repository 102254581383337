import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'opacity',
})
export class OpacityPipe implements PipeTransform {
  transform(color: string, opacity = 80): string {
    if (!color) {
      return color;
    }

    const isHex = color.indexOf('#') === 0;
    const isRGB = color.toLowerCase().indexOf('rgb') === 0;

    if (isHex) {
      return rgbToRGBA(hexToRGB(color), opacity);
    } else if (isRGB) {
      return rgbToRGBA(color, opacity);
    } else {
      const hexColor = webColorToHex(color);
      if (hexColor) {
        return rgbToRGBA(hexToRGB(hexColor), opacity);
      }
    }

    return color;
  }
}

@Pipe({
  name: 'lighten',
})
export class LightenPipe implements PipeTransform {
  transform(color: string, lightenScale = 0.4): string {
    if (!color) {
      return color;
    }

    const isHex = color.indexOf('#') === 0;
    const isRGB = color.toLowerCase().indexOf('rgb') === 0;

    if (isHex) {
      return lighten(hexToRGB(color), lightenScale);
    } else if (isRGB) {
      return lighten(color, lightenScale);
    } else {
      const hexColor = webColorToHex(color);
      if (hexColor) {
        return lighten(hexToRGB(hexColor), lightenScale);
      }
    }

    return color;
  }
}

function webColorToHex(color: string) {
  switch (color) {
    case 'grey':
      return '#acacac';
    case 'red':
      return '#ff0000';
    case 'green':
      return '#0b6623';
    case 'blue':
      return '#002366';
    case 'yellow':
      return '#fcd12a';
  }
}

function hexToRGB(hex: string): string {
  hex = hex.replace('#', '');
  if (hex.length === 3) {
    hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return `rgb(${r},${g}),${b}`;
}

function rgbToRGBA(rgb: string, opacity: number): string {
  const color = rgbToArray(rgb);
  return `rgba(${color.join(',')},${opacity / 100})`;
}

function lighten(color: string, ratio = 1): string {
  const rgb = rgbToArray(color);
  for (let i = 0; i < rgb.length; i++) {
    rgb[i] = Math.ceil(rgb[i] + ratio * (255 - rgb[i]));
  }

  return `rgb(${rgb.join(',')})`;
}

const rgbToArray = (color: string): number[] =>
  color
    .replace('rgb(', '')
    .replace('rgba(', '')
    .replace(')', '')
    .split(',')
    .map((channel) => parseInt(channel, 10))
    .slice(0, 3);
