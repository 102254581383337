<div class="relative font-bold" *ngIf="params?.node?.rowPinned && params?.value?.label; else commonRowTemplate">
  <span class="absolute -translate-x-full -left-[2rem]">{{ params?.value?.label | transloco }}:</span>
  <span>{{ params?.value?.sum | localeCurrency : undefined : '-' }}</span>
</div>
<ng-template #commonRowTemplate>
  <span
    [ngClass]="{
      'font-bold': params.node?.rowPinned
    }"
  >
    {{
      params?.value | localeCurrency : undefined : (params?.node?.rowPinned && params?.node?.rowIndex > 0 ? '' : '-')
    }}
  </span>
</ng-template>
