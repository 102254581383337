import { Pipe, PipeTransform } from '@angular/core';
import { Report } from '@core/models';
import { FormatReportChapterTitleService } from '@core/services/format-reprot-chapter-title.service';

@Pipe({
  name: 'reportChapterTitle',
})
export class ReportChapterTitlePipe implements PipeTransform {
  constructor(private readonly chapterTitleService: FormatReportChapterTitleService) {}

  transform(chapter?: Report.Chapter): string {
    return chapter ? this.chapterTitleService.format(chapter) : '';
  }
}
