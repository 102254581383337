import { ChangeDetectionStrategy, Component } from '@angular/core';
import { NgForOf, NgIf } from '@angular/common';

import { CellRendererComponent } from '@portal/components';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { ButtonComponent } from '@common/components/button/button.component';

@Component({
  selector: 'x-collapsed-list-cell-renderer',
  standalone: true,
  imports: [BadgeComponent, NgIf, ButtonComponent, NgForOf],
  templateUrl: './collapsed-list-cell-renderer.component.html',
  styleUrls: ['./collapsed-list-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsedListCellRendererComponent extends CellRendererComponent<string[]> {
  readonly expandedByDefaultLimit = 2;

  isExpanded = false;

  trackByIndex(index: number): number {
    return index;
  }
}
