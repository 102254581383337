import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertType } from '@common';
import { ApiError, ApiErrorResponse, ApiErrorType, Document } from '@core/models';
import { AdminDocumentsService } from '@core/services';
import { DialogActions } from '@core/state';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { AdminRoute } from '@portal/admin/services/admin-routing.service';
import { AdminRouterSelectors } from '@portal/admin/store/admin-router.selector';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { concatMap, filter, first, map, mapTo, mergeMap, take } from 'rxjs/operators';
import { isNotNil } from 'src/app/utils';
import { UploadOutput } from '../upload';
import { PortalDocumentTagComponent, TagsDialogData, TagsDialogResponse } from './portal-document/portal-document-tag';
import { PortalDocumentComponent } from './portal-document/portal-document.component';
import { UploadPreviewComponent, UploadPreviewDialogData, UploadPreviewOutput } from './upload-preview';

@Component({
  selector: 'x-portal-document-wrapper',
  templateUrl: './portal-document-wrapper.component.html',
  styleUrls: ['./portal-document-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalDocumentWrapperComponent {
  @Input() documentTypes: Document.Type[];
  @ViewChild('fileInput') file: ElementRef<HTMLInputElement>;
  @ViewChild('portalDocument') portalDocument: PortalDocumentComponent;
  @ViewChild('uploadFailTemplate') uploadFailTemplate: TemplateRef<unknown>;
  @ViewChild('uploadingTemplate') uploadingTemplate: TemplateRef<unknown>;

  readonly assetId$ = this.store.select(AdminRouterSelectors.getProjectAssetId);
  readonly documentType$: Observable<Document.Type> = this.store
    .select(AdminRouterSelectors.getProjectAssetDocumentType)
    .pipe(map((type) => (type === AdminRoute.Photo ? 'photo' : (type as Document.Type))));
  readonly isImage$ = this.store
    .select(AdminRouterSelectors.getProjectAssetDocumentType)
    .pipe(map((type) => type === AdminRoute.Photo));
  readonly multiple$ = this.store
    .select(AdminRouterSelectors.getProjectAssetDocumentType)
    .pipe(map((type) => type === AdminRoute.Photo));
  readonly projectId$ = this.store.select(AdminRouterSelectors.getProjectId);
  readonly type$ = this.store.select(AdminRouterSelectors.getProjectAssetDocumentType);
  readonly typeLabel$ = this.type$.pipe(
    filter(isNotNil),
    map((type) => {
      /**
       * t(cost-report)
       * t(photo)
       * t(report)
       * t(unknown)
       */

      // eslint-disable-next-line
      switch (type as any) {
        case 'capex':
          return this.transloco.translate('cost-report').toLowerCase();
        case 'photo':
          return this.transloco.translate('photo').toLowerCase();
        case 'report':
          return this.transloco.translate('report').toLowerCase();
        default:
          return this.transloco.translate('unknown').toLowerCase();
      }
    })
  );

  constructor(
    private store: Store<unknown>,
    private componentRef: ElementRef<HTMLElement>,
    private documentsService: AdminDocumentsService,
    private cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly location: Location,
    private readonly transloco: TranslocoService
  ) {}

  get hasSelectedRows(): boolean {
    return !!this.portalDocument?.gridApi?.getSelectedNodes().length;
  }

  @HostListener('dragenter', ['$event'])
  @HostListener('dragover', ['$event'])
  onHighlight(event?: MouseEvent): void {
    event?.preventDefault();
    event?.stopPropagation();

    this.componentRef.nativeElement?.classList.add('drop-highlight');
  }

  getApiErrors(response: ApiErrorResponse): ApiError[] {
    if (response?.status === 500) {
      return [
        {
          source: null,
          details: _.isString(response.error) ? <any>response.error : 'Internal server error', // eslint-disable-line @typescript-eslint/no-explicit-any
          error: ApiErrorType.Internal,
        },
      ];
    }
    return response?.error?.errors;
  }

  goBack() {
    this.location.back();
  }

  onCancelUpload(): void {
    this.hideDropArea();
  }

  onDropFile(event: DragEvent): void {
    event.preventDefault();
    event.stopPropagation();

    this.openUpload(event.dataTransfer);
  }

  onTagSelectedPhotos(): void {
    const names = this.portalDocument?.gridApi
      ?.getSelectedNodes()
      .map((rowNode) => (rowNode.data as Document.ListItem).name);

    combineLatest([this.projectId$, this.assetId$])
      .pipe(
        take(1),
        mergeMap(([project_id, asset_id]) =>
          this.dialog
            .open<PortalDocumentTagComponent, TagsDialogData, TagsDialogResponse>(PortalDocumentTagComponent, {
              data: {
                tags: [],
                disableAllPhotos: true,
                options: {
                  asset_id,
                  project_id,
                },
                editOption: 'ADD_ONLY',
              },
            })
            .afterClosed()
        ),
        filter((response) => !!response),
        map(({ tags }) => tags)
      )
      .subscribe({
        next: (tags) => this.tagDocumentsByNames(names, tags, false),
      });
  }

  onUnhighlight(event?: MouseEvent): void {
    event?.preventDefault();
    event?.stopPropagation();

    this.componentRef.nativeElement?.classList.remove('drop-highlight');
  }

  onUploadDocuments(result: UploadPreviewOutput): void {
    const uploadedFiles: { file?: File; errors?: ApiError[] }[] = [];

    combineLatest([this.projectId$, this.assetId$])
      .pipe(
        first(),
        concatMap(([projectId, assetId]) =>
          this.documentsService.createProjectDocument(
            result.files,
            projectId,
            assetId,
            (file: File) => result.fileTypeMap?.get(file) || []
          )
        )
      )
      .subscribe({
        next: ({ file, status }) => {
          uploadedFiles.push({
            file,
            errors: this.getApiErrors(status),
          });
          this.cdr.markForCheck();
        },
        error: (status) => {
          this.portalDocument.fetchRowData();
          this.showError(this.uploadFailTemplate, [{ errors: this.getApiErrors(status) }]);
        },
        complete: () => {
          const uploadErrors = _.filter(uploadedFiles, ({ errors }) => !!errors);
          if (!_.isEmpty(uploadErrors)) {
            this.showError(this.uploadFailTemplate, uploadErrors);
          }

          this.portalDocument.fetchRowData();

          if (result.tags?.length) {
            const names = result.files.map((file) => file.name);
            this.tagDocumentsByNames(names, result.tags, true);
          }
        },
      });
  }

  openUpload(dataTransfer?: DataTransfer): void {
    this.isImage$
      .pipe(
        take(1),
        mergeMap((isImage) => {
          this.hideDropArea();

          return this.dialog
            .open<UploadPreviewComponent, UploadPreviewDialogData, UploadOutput>(UploadPreviewComponent, {
              data: {
                dataTransfer,
                allowedTypes: !isImage ? this.documentTypes : ['photo', 'panorama', 'video'],
                selectTags: isImage,
              },
            })
            .afterClosed();
        })
      )
      .subscribe({
        next: (result) => {
          if (!result) {
            this.onCancelUpload();
          } else {
            this.onUploadDocuments(result);
          }
        },
      });
  }

  resizeGrid(): void {
    if (!this.portalDocument) {
      return;
    }

    this.portalDocument.onWindowResize();
  }

  private hideDropArea(): void {
    this.onUnhighlight();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private showError(template: TemplateRef<unknown>, context: { file?: File; errors?: ApiError[] }[]): void {
    this.store.dispatch(
      DialogActions.showAlert({
        data: {
          type: AlertType.Error,
          template: () => template,
          context,
        },
      })
    );
  }

  private tagDocumentsByNames(names: string[], tags: string[], doRefresh: boolean): void {
    combineLatest([this.projectId$, this.assetId$])
      .pipe(
        take(1),
        mergeMap(([project_id, asset_id]) => {
          const listItems: Document.ListItem[] = names.map((name) => ({
            project_id,
            asset_id,
            tags,
            name,
          })) as Document.ListItem[];
          return this.documentsService
            .appendTagsByDocumentName({
              project_id,
              asset_id,
              tags,
              names,
            })
            .pipe(mapTo(listItems));
        })
      )
      .subscribe({
        next: (listItems) => {
          if (doRefresh) {
            this.portalDocument.fetchRowData();
          } else {
            this.portalDocument.setGridTags(listItems);
          }
        },
      });
  }
}
