import { Document } from './document';
import { Finding, FindingClass } from './finding';
import { Transform } from './transform';
import { User } from './user';
import { Tile } from '@portal/customer/services/dashboards.service';

/* eslint-disable no-shadow, @typescript-eslint/no-shadow */
export interface Report {
  tenant_id: string;
  id: string;
  name: string;
  status: 'active' | 'inactive';
  is_template: boolean;
  user_id: string;
  user: User;
  trade_set_id: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
  published_at: Date;
  /**
   * Type is defined on a BE and is used here
   * to as example for the customer's report templates table.
   */
  type: Document.Type | 'pdf' | FindingClass;
  asset_id?: string;
  template_id: string;

  // Calculated properties
  isActive?: boolean;

  risk_max: number;
  risk_avg: number; // float
  condition_max: number;
  condition_avg: number; // float
  quality_max: number;
  document_quality_max: number;
  document_quality_avg: number; // float
  sum_immediate_costs: number;
  sum_short_term_costs: number;
  sum_mid_term_costs: number;
  sum_long_term_costs: number;
  document_id: string;
  logo_download_token?: string;
  total_versions_count: number;
  version_status?: 'active' | 'draft' | 'outdated';
  versioning_id?: string;
}

export interface ExternalEntityProperty {
  name: string;
  type: string;
  value: string;
}

export interface ExternalEntity {
  name: string;
  properties: ExternalEntityProperty[];
}

export namespace Report {
  export interface RenderCondition {
    field_id: string;
    match_type: 'equals' | 'equals_not';
    match_value?: string;
    match_operator?: 'and'; // "and" is the only option and it's the default behavior
    next_condition?: RenderCondition;
  }

  export const doReportFieldsMeetCondition = (
    renderCondition: Report.RenderCondition,
    allFields: Report.Field[]
  ): boolean => {
    if (!renderCondition) {
      return true;
    }

    const stripHtml = (html) => {
      const tmp = document.createElement('div');
      tmp.innerHTML = html;
      return tmp.textContent || tmp.innerText || '';
    };

    const evaluateCondition = (condition: RenderCondition) => {
      const targetField = allFields.find((f) => f.id === condition.field_id);
      if (!targetField) {
        return false;
      }

      let targetValue = stripHtml(targetField.data);
      if (targetField.type === 'checkbox') {
        const correspodingValue = targetField?.answer_options?.find(
          (option) => option?.display_name === targetField?.data
        )?.name;
        if (correspodingValue) {
          targetValue = correspodingValue;
        }
      }
      const matchValue = condition.match_value;
      let conditionMet = false;

      switch (condition.match_type) {
        case 'equals':
          conditionMet = matchValue !== undefined ? targetValue === matchValue : targetValue !== undefined;
          break;
        case 'equals_not':
          conditionMet = matchValue !== undefined ? targetValue !== matchValue : targetValue === undefined;
          break;
        default:
          return false;
      }

      if (condition.match_operator === 'and' && condition.next_condition) {
        return conditionMet && evaluateCondition(condition.next_condition);
      }

      return conditionMet;
    };

    return evaluateCondition(renderCondition);
  };

  export const transform = (report: Report): Report => ({
    ...report,
    created_at: Transform.date(report.created_at),
    deleted_at: Transform.date(report.deleted_at),
    updated_at: Transform.date(report.updated_at),
    published_at: Transform.date(report.published_at),
    isActive: report.status === 'active',
  });
  export interface Chapter {
    id: string;
    sub_id: string;
    report_id: string;
    report?: Report;
    parent_id: string;
    title: string;
    title_prefix: string;
    without_prefix: boolean;
    intro_text: string;
    intro_text_tooltip: string;
    risk: number;
    condition: number;
    document_quality: number;
    order_number: number;
    version: number;
    fields: Field[];
    updated_by: string;
    user: User;
    trade_ids: number[];
    created_at: Date;
    updated_at: Date;
    published_at: Date;
    with_score: boolean;
    type: string;
    is_not_applicable: boolean;
    executive_summary?: string[];
    quality: number;
    /** Mandatory */
    paper_format: 'A4' | 'A3' | 'Letter';
    /** Mandatory */
    orientation: 'portrait' | 'landscape';
    /**
     * If a chapter designed to be blank
     */
    is_intentionally_blank: boolean;
    risk_max: number;
    risk_avg: number; // float
    condition_max: number;
    condition_avg: number; // float
    quality_max: number;
    document_quality_max: number;
    document_quality_avg: number; // float
    immediate_costs: number;
    sum_immediate_costs: number;
    short_term_costs: number;
    sum_short_term_costs: number;
    mid_term_costs: number;
    sum_mid_term_costs: number;
    long_term_costs: number;
    sum_long_term_costs: number;
    // If given and not empty, show them before the regular headline
    additional_headlines: string[] | null;
    styling?: {
      answer_column?: {
        cols: number;
      };
      question_column?: {
        cols: number;
      };
    };
  }

  export interface ReportExternalEntity {
    name: string;
    property: string;
  }

  export type AggerationFunction = 'min' | 'max' | 'mean' | 'sum' | 'median';
  export type FormulaOperation = 'addition' | 'subtraction' | 'multiplication' | 'division';

  export interface FormulaExpression {
    field_id?: string; // or
    report_data_id?: string; // or
    external_entity?: ReportExternalEntity;
    result_type?: Field.Type;
    operation?: FormulaOperation;
    next_expression?: FormulaExpression;
  }

  export interface AggregationExpression {
    field_id?: string; // or
    report_data_id?: string;
    result_type: Field.Type;
    function?: AggerationFunction;
  }

  export namespace Chapter {
    export const transform = (chapter?: Chapter) =>
      chapter && {
        ...chapter,
        fields: (chapter.fields || []).map((field) => Field.transform(field)),
        user: Transform.user(chapter.user),
        created_at: Transform.date(chapter.created_at),
        updated_at: Transform.date(chapter.updated_at),
        published_at: Transform.date(chapter.published_at),
      };
  }

  export interface Field {
    id: string; // given by the user!
    title: string;
    blocks: Block[];
    type: Field.Type | string;
    tiles: Tile[];
    structure: Field.Structure | string;
    answer_options: Field.AnswerOption[];
    factor: number;
    is_required: boolean;
    is_block_repeatable: boolean;
    is_block_horizontal: boolean;
    drop_if_empty: boolean;
    is_source_required: boolean;
    hide_title: boolean;
    hidden: boolean;
    with_multiplechoice: boolean;
    data: string | number | Date | boolean | string[] | null | undefined;
    sources: ReportFieldSource[];
    unit: string;
    tooltip: string;
    tooltip_example?: string;
    repetitions_limit?: number;
    length_limit?: number;
    render_condition: RenderCondition;
    sources_required_condition: RenderCondition;
    sources_tooltip: string;
    formula_expression?: FormulaExpression;
    aggregation_expression?: AggregationExpression;
  }

  export interface ReportFieldSource {
    source_scope: 'qa' | 'dataroom' | 'text' | 'external' | 'document' | 'ticket';
    source: string;
    source_description: string;
    source_page?: number;
    src_type?: string;
  }

  export namespace Field {
    export interface AnswerOption {
      name: string;
      display_name?: string;
      is_negative: boolean;
    }

    export namespace AnswerOption {
      export const transform = (ao: AnswerOption) => ao;
    }

    export type Type =
      | 'text'
      | 'text_static'
      | 'string'
      | 'number'
      | 'float'
      | 'year'
      | 'country'
      | 'date'
      | 'date & time'
      | 'checkbox'
      | 'no input'
      | 'block'
      | 'photo'
      | 'document'
      | 'link'
      | 'crrem_chart'
      | 'chart_bar'
      | 'chart_pie'
      | 'chart_line'
      | 'chart_spider'
      | 'table'
      | 'aggregation'
      | 'formula';

    export type Structure = 'crrem_chart' | 'chart_bar' | 'chart_pie' | 'chart_line' | 'chart_spider' | 'table';

    export const transform = (field: Field): Field => ({
      ...field,
      blocks: (field.blocks || []).map((block) => Block.transform(block)),
      answer_options: (field.answer_options || []).map((ao) => AnswerOption.transform(ao)),
    });
  }

  export interface Block {
    counter: number;
    block: Field[];
    sources?: ReportFieldSource[];
  }

  export namespace Block {
    export const transform = (block: Block): Block => ({
      ...block,
      block: (block.block || []).map((b) => Field.transform(b)),
    });
  }

  export namespace Search {
    export interface ResultRecord {
      id: string;
      type: 'node' | 'finding' | 'qa';
      abstract: 'text' | 'with' | 'html' | 'tags';
      created_at: Date;
      updated_at: Date;
    }
  }

  /**
   * A published report has an executive summary
   * and there section about key findings
   */
  export interface KeyFinding {
    id: string;
    findings: Report.ChapterKeyFinding[];
    risk_avg: number; // float
    condition_avg: number; // float
    quality_avg: number; // float
    document_quality_avg: number; // float
    tiles: Tile[];
  }

  export interface ChapterKeyFinding {
    id: string;
    findings: string[];
    title: string;
  }

  export interface CostStatement {
    id: string;
    sum_total: number; // int
    sum_istau: number; // int
    percent_sum_istau: number; // float64
    sum_capex: number; // int
    percent_sum_capex: number; // float64
    sum_now_and_next: number; // int
    percent_sum_now_and_next: number; // float64
    sum_2nd_until_5th_year: number; // int
    percent_sum_2nd_until_5th_year: number; // float64
    sum_6th_until_10th_year: number; // int
    percent_sum_6th_until_10th_year: number; // float64
    sum_risk_high: number; // int
    percent_sum_risk_high: number; // float64
    sum_risk_medium: number; // int
    percent_sum_risk_medium: number; // float64
    sum_risk_low: number; // int,
    percent_sum_risk_low: number; // float64
    tiles: Tile[];
  }

  export interface ExecutiveStatement {
    id: string;
    summary_type: string;
    count: number;
    statements: {
      id: string;
      title: string;
      /** float64 */
      rating: number;
    }[];
  }

  export interface PhotoDocumentation {
    finding?: Finding.Short;
    chapter?: Chapter;
    document: Document;
  }

  export namespace PhotoDocumentation {
    export const transform = (photo: PhotoDocumentation): PhotoDocumentation =>
      photo && {
        ...photo,
        finding: Finding.Short.transform(photo.finding),
        chapter: Chapter.transform(photo.chapter),
        document: Document.transform(photo.document),
      };
  }
}
