<x-upload
  [dataTransfer]="data?.dataTransfer"
  [types]="allowedTypes"
  [allowedExtensions]="data?.allowedExtensions"
  [title]="'upload' | transloco"
  [showTypes]="false"
  [multiple]="true"
  (handleClose)="onCancel()"
  (upload)="onUpload($event)"
>
  <div *ngIf="data?.selectTags" class="flex flex-row content-center w-full space-x-4">
    <section class="flex-[2]">
      <p class="mt-4 text-sm text-gray-700">{{ 'tags' | transloco }}</p>
      <x-tags-input [tags]="tags" [allTags]="data.predefinedTags" (tagsChanged)="onTagsChanged($event)"></x-tags-input>
    </section>

    <section class="flex-1" *ngIf="data?.allowedTypes?.length">
      <p class="mt-4 text-sm text-gray-700">{{ 'type' | transloco }}</p>
      <x-select
        [value]="allowedTypes[0]"
        (valueChange)="onTypeChanged($event)"
        [options]="allowedTypesOptions"
      ></x-select>
    </section>
  </div>
</x-upload>
