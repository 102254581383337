<ng-container [ngSwitch]="fieldName">
  <ng-container *ngSwitchCase="'asset_name'">{{ 'asset' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_street1'">{{ 'address' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_due_date'">{{ 'due-date' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_status'">{{ 'asset-status' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'project_name'">{{ 'project' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'project_token'">{{ 'project-token' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'project_role'">{{ 'role' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'project_status'">{{ 'status' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'project_due_date'">{{ 'project-due-date' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'tracker_all'">{{ 'progress' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_photo_download_token'">{{ 'photo' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'created_at'">{{ 'created-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'started_at'">{{ 'started-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'type'">{{ 'type' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'project_created_at'">{{ 'project-created-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'project_started_at'">{{ 'project-started-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'project_product_type'">{{ 'type' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_token'">{{ 'asset-token' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_is_frozen'">{{ 'is-frozen' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'tracker_done'">{{ 'progress' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_is_initial_setup_done'">
    {{ 'asset-is-initial-setup-done' | transloco }}
  </ng-container>
  <ng-container *ngSwitchCase="'asset_started_at'">{{ 'asset-started-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_created_at'">{{ 'asset-created-at' | transloco }}</ng-container>
  <ng-container *ngSwitchCase="'asset_termination_notice_at'">
    {{ 'asset-termination-notice-at' | transloco }}
  </ng-container>
  <ng-container *ngSwitchDefault>{{ fieldName }}</ng-container>
</ng-container>
