import { ChangeDetectionStrategy, Component } from '@angular/core';

import { HeaderCellComponent } from '../../../grid';

@Component({
  selector: 'x-portal-document-header-cell',
  templateUrl: './portal-document-header-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalDocumentHeaderCellComponent extends HeaderCellComponent {}
