import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';
import { isNotNil } from '@app/utils';
import { Document, Finding, Report } from '@core/models';
import { StorageService } from '@core/services';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { CustomerDocumentsService, CustomerRoutingService } from '@portal/customer/services';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { FindingService } from '@portal/services/finding.service';
import { LinkHelperService } from '@portal/services/link-helper.service';
import { RoutingService } from '@portal/services/routing.service';
import { of } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';
import {
  dataroomType,
  isPdfTypeRegExp,
  isPhotoTypeRegExp,
  isVideoExtensionsRegExp,
  photoType,
} from '../qa-overview.constants';

@UntilDestroy()
@Component({
  selector: 'x-qa-reference',
  templateUrl: './qa-reference.component.html',
  styleUrls: ['./qa-reference.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QaReferenceComponent implements OnInit {
  @Input() messageId?: string;
  @Input() finding?: Finding.Short;
  @Input() photos?: Document.Short[];
  @Input() documents?: Document.Short[];
  @Input() pages?: number[];
  @Input() targetBlank?: boolean;
  @Input() isReferenceThumbnailShowed = false;
  @Input() isExtendedFindingMode = false;
  @Input() useIconsOnly = false;
  @Input() report?: Report;
  @Input() reportChapter?: Report.Chapter;
  @Input() maxPreviewThumbnails?: number;
  @Input() scope: 'qa' | 'finding' = 'qa';

  @ViewChild('menuTrigger') menuTrigger?: MatMenuTrigger;
  @ViewChild('menuPlaceholder') menuPlaceholder?: MatMenuTrigger;

  assetId$ = this.store.select(CustomerRouterSelectors.getAssetId).pipe(filter(isNotNil));

  isOpeningWithinTokenPreview = false;

  photosAndDocsIds = [];

  private storedUrlsToPhotosDownloadedWithToken: {
    docId: string;
    photoUrl: string;
  }[] = [];

  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private storageService: StorageService,
    private routingService: RoutingService,
    private findingService: FindingService,
    private linkHelperService: LinkHelperService,
    private readonly customerDocumentsService: CustomerDocumentsService,
    private readonly customerRoutingService: CustomerRoutingService
  ) {}

  ngOnInit() {
    this.photosAndDocsIds = [
      ...(this.photos || [])?.map((photo) => photo?.id),
      ...(this.documents || [])?.map((doc) => doc?.id),
    ].slice(0, 2);
  }

  selectPhoto(photo: Document.Short | null = null, event?: MouseEvent) {
    event?.preventDefault();
    if (this.isExtendedFindingMode) {
      if (photo) {
        this.linkHelperService.open(this.getDocumentLink(photo), photoType);
      }
      setTimeout(() => {
        this.menuPlaceholder?.closeMenu?.();
      });
    }
  }

  selectFinding(finding: Finding.Short, event: MouseEvent) {
    window.openTemplateOverlay({
      dialogType: 'finding',
      data$: this.findingService.getFindingFromShort(finding).pipe(take(1), untilDestroyed(this)),
    });

    event.preventDefault();
  }

  getDocumentLink(doc: Document.Short): string {
    if (doc) {
      let page: number | undefined;
      if (Array.isArray(this.pages) && Array.isArray(this.documents)) {
        page = this.pages[this.documents.indexOf(doc)];
        page = page >= 1 ? page : undefined;
      }
      return this.routingService.getDocumentFileLink(
        doc,
        page || doc.anchor ? Number(doc.anchor.split('Page=').pop()) : page,
        this.scope,
        undefined,
        this.messageId
      );
    } else {
      return '';
    }
  }

  openPhotoPreview(photo, event) {
    event.stopPropagation();
    window.openMediaDocument(photo);
  }

  getReportLink(report: Report): string {
    return this.customerRoutingService.getAssetReportTemplateViewerLink(report.asset_id, report.id);
  }

  getReportChapterLink(assetId: string, chapter: Report.Chapter): string {
    return this.customerRoutingService.getAssetReportTemplateChapterViewerLink(assetId, chapter.report_id, chapter.id);
  }

  onOpenReport(href: string) {
    this.linkHelperService.open(href, 'report');
  }

  getIsViewableOnline = (doc: Document.Short) =>
    isPhotoTypeRegExp.test(doc.name) ||
    isVideoExtensionsRegExp.test(doc.name) ||
    isPdfTypeRegExp.test(doc.name) ||
    doc.conversion_status === 'finished' ||
    doc.types.includes('document'); // for findings list qa renderer check;

  onOpenDocument(doc: Document.Short, event: MouseEvent): void {
    if (this.targetBlank) {
      return;
    }

    if (!event?.ctrlKey) {
      event?.preventDefault();
    }

    if (!this.getIsViewableOnline(doc)) {
      return;
    }

    const url = this.getDocumentLink(doc);

    if (isPhotoTypeRegExp.test(doc.name) && (!doc.types || doc.types.includes(dataroomType))) {
      if (!this.isOpeningWithinTokenPreview) {
        const storedFileUrl = this.storedUrlsToPhotosDownloadedWithToken.find(
          (storedUrl) => storedUrl.docId === doc.id
        )?.photoUrl;

        if (storedFileUrl) {
          this.linkHelperService.open(storedFileUrl, photoType);
        } else {
          this.isOpeningWithinTokenPreview = true;
          this.customerDocumentsService
            .getNewDownloadTokenForAssetDocument(doc, { version: 360 })
            .pipe(
              switchMap((downloadToken) =>
                this.storageService.getImageWithDownloadToken({ download_token: downloadToken })
              ),
              untilDestroyed(this)
            )
            .subscribe((photoFile: Blob) => {
              const photoUrl = window.URL.createObjectURL(photoFile);
              this.linkHelperService.open(photoUrl, photoType);
              this.isOpeningWithinTokenPreview = false;
              this.cdr.markForCheck();
              this.storedUrlsToPhotosDownloadedWithToken.push({
                docId: doc.id,
                photoUrl,
              });
            });
        }
      }
    } else {
      this.linkHelperService.open(url, doc.types?.includes(photoType) ? photoType : undefined);
    }
  }

  getCachedImage(id: string) {
    if (id) {
      return this.storageService.imagesCache.get(id) || of(null);
    } else {
      return of(null);
    }
  }

  trackByFn(index: number) {
    return index;
  }
}
