<ng-container *ngIf="notifications">
  <div *transloco="let t; read: 'sse'" class="w-[538px] mt-2 bg-white border border-gray-200 rounded-md">
    <div class="flex flex-col w-full max-h-[694px] text-gray-500 rounded-xl">
      <div class="flex justify-center items-center text-black font-semibold p-2 border-b border-gray-200 h-[59px]">
        {{ t('events') }}
      </div>
      <ng-container *ngIf="!notifications.length; else notificationListTemplate">
        <div class="p-6 text-center">
          {{ 'no-events' | transloco }}
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #notificationListTemplate>
    <div class="flex flex-col overflow-auto">
      <div class="mx-5 [&:not(:last-child)]:border-b border-gray-200" *ngFor="let notification of notifications">
        <ng-container *ngTemplateOutlet="notificationListItem; context: { $implicit: notification }"></ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>

<ng-template #notificationListItem let-notification>
  <div class="flex flex-row py-4" [class.read]="notification.read">
    <div
      [ngClass]="{ 'opacity-0': notification.read }"
      class="inline-flex rounded-full bg-primary-600 h-[10px] w-[10px] mt-[0.5rem] mx-2.5"
    >
      <!-- a blue dot -->
    </div>
    <div class="flex flex-col flex-1 space-y-2.5" *transloco="let t; read: 'sse'">
      <span class="text-gray-900 mr-3" [innerHTML]="getNotificationText(notification.event) | safeHtml"></span>
      <span
        class="text-primary-600 cursor-pointer select-none w-fit whitespace-nowrap text-base"
        (click)="onNotificationActionBtnClick(notification)"
      >
        {{ getActionButtonText(notification.event) }}
      </span>
      <span class="text-gray-500 font-medium text-sm align-center">
        {{ notification.timestamp | localeDate : true }}
      </span>
    </div>
    <div class="inline-flex w-10">
      <button
        class="text-gray-400 hover:text-gray-600 hover:bg-gray-100 w-9 h-9 rounded-xl focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
        [matTooltip]="'remove-from-the-list' | transloco"
        (click)="onRemoveNotification(notification)"
        matTooltipPosition="above"
      >
        <x-svg-icon [svgImageName]="'trash'" svgClass="h-5 w-5"></x-svg-icon>
      </button>
    </div>
  </div>
</ng-template>
