<div *ngIf="value" class="flex flex-col">
  <ng-container *ngFor="let item of value; let i = index; trackBy: trackByIndex">
    <span *ngIf="isExpanded || i < expandedByDefaultLimit" class="h-[20px] leading-[20px]">
      {{ item }}
    </span>
  </ng-container>
  <x-button
    *ngIf="!isExpanded && value.length > expandedByDefaultLimit"
    size="xs"
    color="white"
    classes="!bg-gray-200 !rounded-[15px] !h-[20px] !text-[12px] m-1"
    (click)="isExpanded = true"
  >
    + {{ value.length - expandedByDefaultLimit }}
  </x-button>
</div>
