import { ColDef } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component, ElementRef, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { api, Group, Message } from '@core/models';
import { FormatDateService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseGridComponent, GridHelperService } from '@portal/components/grid';
import { QAService } from '@portal/customer/services';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ToastService, ToastType } from '@core/toast';

export interface QaHistoryDialogData {
  message: Message;
  hasUserViewPermission: boolean;
}

@UntilDestroy()
@Component({
  selector: 'x-qa-history-dialog',
  templateUrl: './qa-history-dialog.component.html',
  styleUrls: ['./qa-history-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QaHistoryDialogComponent extends BaseGridComponent<Message.History> {
  readonly history$ = this.qaService
    .getHistory(
      {
        asset_id: this.data.message.asset_id,
        qid: this.data.message.id,
      },
      'qa'
    )
    .pipe(
      tap(() => this.resizeGrid()),
      catchError((error: api.ErrorResponse<unknown>) => {
        this.showError(error);
        return of([]);
      })
    );

  readonly fitSizeCorrectionPx = 48; // Horizontal padding set by x-dialog

  constructor(
    componentRef: ElementRef<HTMLElement>,
    formatDateService: FormatDateService,
    gridHelper: GridHelperService,
    transloco: TranslocoService,
    @Inject(MAT_DIALOG_DATA) readonly data: QaHistoryDialogData,
    private readonly qaService: QAService,
    private readonly toast: ToastService,
    public dialogRef: MatDialogRef<never>
  ) {
    super(componentRef, formatDateService, gridHelper, transloco);
  }

  showError(error: api.ErrorResponse<unknown>): void {
    this.toast.showToast(this.transloco.translate('history-dialog.error'), ToastType.ERROR);
  }

  protected getColumnDefs(): ColDef[] {
    return [
      this.getDateColDef(
        'created_at',
        {
          headerComponentParams: {
            displayName: this.transloco.translate('history-dialog.created-at'),
          },
        },
        true
      ),
      this.getColDef('action', {
        headerComponentParams: {
          displayName: this.transloco.translate('history-dialog.action'),
        },
        valueGetter: (params) => {
          const actionValue = params.data[params.colDef.field as string];
          return actionValue ? this.transloco.translate(`history-dialog.action-value.${actionValue}`) : '';
        },
        tooltipValueGetter: ({ value }) => value,
      }),
      this.getColDef('group', {
        headerComponentParams: {
          displayName: this.transloco.translate('history-dialog.group'),
        },
        valueGetter: (params) => {
          const value = params.data[params.colDef.field as string] as Group;
          return value?.name;
        },
        tooltipValueGetter: ({ value }) => value,
      }),
      this.getColDef('value', {
        tooltipValueGetter: ({ value }) => value,
        headerComponentParams: {
          displayName: this.transloco.translate('qa-history-dialog.value'),
        },
      }),
      // TODO: Restore when BE returns valid history of affected groups
      // ...(this.data.hasUserViewPermission
      //   ? [
      //       this.getColDef('qa_message', {
      //         headerComponentParams: {
      //           displayName: this.transloco.translate('qa-history-dialog.affected-group'),
      //         },
      //         valueGetter: (params) => {
      //           const toGroup = params.data.qa_message?.to_group as Group;
      //           return toGroup?.name;
      //         },
      //       }),
      //     ]
      //   : []),
      this.getUserColDef('user', {
        tooltipValueGetter: ({ value }) => value,
        headerComponentParams: {
          displayName: this.transloco.translate('history-dialog.user'),
        },
      }),
      this.getColDef('notes', {
        headerComponentParams: {
          displayName: this.transloco.translate('history-dialog.comment'),
        },
        tooltipValueGetter: ({ value }) => value,
      }),
    ];
  }

  protected getColDef(field: keyof Message.History, colDef: Partial<ColDef> = {}): ColDef {
    const columnDefinition = super.getColDef(field, colDef);

    return {
      ...columnDefinition,
      headerComponentFramework: undefined,
      headerComponentParams: {
        ...colDef.headerComponentParams,
      },
    };
  }
}
