import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  CallPipeModule,
  DocumentDetailsPipeModule,
  DocumentPhotoPipeModule,
  IconComponentModule,
  LocaleDatePipeModule,
  NewLinePipeModule,
  SnackBarModule,
} from '@common';
import { MatDialogModule } from '@angular/material/dialog';
import { TranslocoModule } from '@ngneat/transloco';
import { DocumentsPreviewModule } from '@portal/components/documents-preview/documents-preview.module';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { QaDetailsDialogFieldsComponent } from '@portal/components/qa-overview/qa-details-dialog-fields/qa-details-dialog-fields.component';
import { QaReferenceModule } from '../qa-reference/qa-reference.module';
import { QAAnswerDialogComponent } from './qa-answer-dialog.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { ButtonComponent } from '@common/components/button/button.component';
import { TextareaComponent } from '@portal/components/textarea/textarea.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import { WriteResponseFormComponent } from '@portal/customer/components/asset-details/asset-tickets-container/components/write-response-form/write-response-form.component';

@NgModule({
  imports: [
    CommonModule,
    NewLinePipeModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslocoModule,
    IconComponentModule,
    FormsModule,
    QaReferenceModule,
    SnackBarModule,
    CallPipeModule,
    LocaleDatePipeModule,
    DocumentDetailsPipeModule,
    DocumentPhotoPipeModule,
    DocumentsPreviewModule,
    QaDetailsDialogFieldsComponent,
    DialogComponent,
    ButtonComponent,
    TextareaComponent,
    SvgIconModule,
    BadgeComponent,
    WriteResponseFormComponent,
  ],
  declarations: [QAAnswerDialogComponent],
  exports: [QAAnswerDialogComponent, MatDialogModule],
})
export class QaAnswerDialogModule {}
