export namespace QA {
  export interface Workflow {
    id: string;
    project_id: string;
    name: string;
    is_system_workflow: boolean;
    created_at: Date;
    category: string;
    status: string;
  }

  export interface WorkflowStep {
    id: string;
    qa_workflow_id: string;
    qa_workflow: QA.Workflow;
    name: string;
    action: 'ask' | 'answer' | 'approve_question' | 'approve_answer' | 'close' | 'approve_ticket';
    group_types: string[];
    group_ids: string[];
    dest_group_types: string[];
    dest_group_ids: string[];
    required_permission: string;
    forwardable: boolean;
    forward_group_ids: string[];
    forward_group_types: string[];
    prev_qa_step_ids?: string[];
    next_qa_step_ids: string[];
    created_at: Date;
  }

  export interface Approval {
    is_approved: boolean;
    notes?: string;
  }

  export interface Config {
    id: string;
    project_id: string;
    status: string;
    config: string;
    workflow_arr: string[];
    workflows: QA.Workflow[];
    created_at: Date;
  }

  export type Status = 'open' | 'prending_approval' | 'answered' | 'closed';

  export namespace Status {
    export const all: Status[] = ['open', 'prending_approval', 'answered', 'closed'];
  }
}
