import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { api, FetchStatus, Finding, FindingMonitoring, Message, Project, Risk, Transform } from '@core/models';
import { DATE_TIME_FORMAT, FormatDateService, PORTAL_API_URL } from '@core/services';
import { Store } from '@ngrx/store';
import { FindingDetailsDialogData } from '@portal/customer/components/asset-details/findings/finding-details-dialog';
import { CustomerRouterSelectors, PermissionsSelectors, ResolvedDataSelectors } from '@portal/customer/state';
import { FindingService } from '@portal/services/finding.service';
import * as _ from 'lodash';
import { combineLatest, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { FindingsFilter } from '../components/asset-details/findings/findings-search-breadcrumbs/findings-filter';

interface RequiredParams {
  asset_id: string;
  trade_id?: number[];
}

interface FindingParams extends RequiredParams {
  finding_id: string;
}

interface BatchFindingParams extends RequiredParams {
  finding_ids: string[];
}

export interface CreateFindingData {
  finding: undefined;
  tradeId: string;
  assetId: string;
  findingClass: string;
  canEdit: boolean;
}

export interface PublishedReportFindingsParams {
  assetId: string;
  reportId: string;
  chapterId: string;
}

@Injectable({
  providedIn: 'root',
})
export class FindingsService extends FindingService {
  protected readonly apiUrl = PORTAL_API_URL;
  readonly project$ = this.store.select(ResolvedDataSelectors.getProject);
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);
  readonly canWriteFinding$ = this.store.select(PermissionsSelectors.canWrite, 'finding');
  readonly hasBothFormats$ = this.project$.pipe(
    map((project) =>
      !!project?.tdd_finding_format && !!project?.monitoring_finding_format
        ? 'both'
        : !!project?.monitoring_finding_format
        ? 'monitoring'
        : 'tdd'
    )
  );
  constructor(
    private readonly http: HttpClient,
    private readonly formatDateService: FormatDateService,
    private store: Store
  ) {
    super();
  }

  getFindings(
    params: RequiredParams,
    {
      published,
      until,
      findingClass,
      findingStatus,
      risk,
    }: Partial<{
      published: Date;
      until: Date;
      findingStatus?: FindingMonitoring['status'][];
    }> & {
      findingClass: Finding['class'];
      risk?: Risk;
    }
  ): Observable<Finding[]> {
    const url = this.getFindingsUrl(params);
    const contractAcceptedRiskParams: Risk[] = [Risk.High, Risk.Medium, Risk.Low];
    const requestParams = _.omitBy(
      {
        published: published ? this.formatDateService.format(published) : null,
        timestamp_until: until ? this.formatDateService.format(until, DATE_TIME_FORMAT, 'UTC') : null,
        trade_id: params.trade_id,
        class: findingClass,
        status:
          findingClass === 'monitoring' ? (findingStatus?.every((status) => !status) ? null : findingStatus) : null,
        risk_evaluation: findingClass === 'tdd' ? (contractAcceptedRiskParams.includes(risk) ? risk : null) : null,
      },
      _.isNil
    );
    return this.http
      .get<Finding[]>(url, {
        params: new HttpParams({ fromObject: requestParams }),
      })
      .pipe(
        catchError((error) => {
          console.error(error);
          return throwError(error);
        }),
        map((findings) => findings?.map(this.transform))
      );
  }

  getPublishedReportFindings({ assetId, reportId, chapterId }: PublishedReportFindingsParams): Observable<Finding[]> {
    const url = this.getPublishedReportFindingsUrl({ assetId, reportId, chapterId });
    return this.http.get<Finding[]>(url).pipe(
      catchError((error) => {
        console.error(error);
        return throwError(error);
      }),
      map((findings) => {
        return findings?.map(this.transform);
      })
    );
  }

  getCreateFindingData(): Observable<FindingDetailsDialogData> {
    return combineLatest([this.project$, this.assetId$, this.canWriteFinding$]).pipe(
      mergeMap(([project, assetId, canWrite]) =>
        of({
          finding: undefined,
          tradeId: this.getTradeId(project, 'tdd'),
          assetId,
          findingClass: 'tdd' as Finding['class'],
          canEdit: canWrite,
        })
      )
    );
  }

  getTradeId(project: Project | undefined, selectedClass: Finding['class']): string | undefined {
    return selectedClass === 'tdd' ? project?.tdd_trade_set_id : project?.monitoring_trade_set_id;
  }

  searchFindings(
    params: RequiredParams,
    filter: FindingsFilter & {
      findingClass: Finding['class'];
    }
  ): Observable<FetchStatus<api.SearchResult[]>> {
    const url = this.getFindingsUrl(params) + (filter ? '/search' : '');
    const requestParams = _.omitBy(
      <api.Search>{
        query: filter?.query,
        class: filter?.findingClass,
        scope: 'finding',
      },
      _.isNil
    );

    return this.http
      .get<api.PaginationResponse<api.SearchResult>>(url, {
        params: requestParams,
      })
      .pipe(
        map(
          (response) =>
            <FetchStatus<api.SearchResult[]>>{
              entity: _.map(response.data, Transform.searchResult),
            }
        ),
        catchError((error) =>
          of(<FetchStatus<api.SearchResult[]>>{
            error,
          })
        )
      );
  }

  getFinding(params: FindingParams): Observable<Finding> {
    const url = this.getFindingsUrl(params);
    return this.http.get<Finding>(`${url}/${params.finding_id}`).pipe(map(this.transform));
  }

  getHistory(params: FindingParams): Observable<Finding[]> {
    const url = `${this.getFindingsUrl(params)}/${params.finding_id}/history`;

    return this.http.get<Finding[]>(url).pipe(
      map((findings) => findings?.map(this.transform) || []),
      catchError(() => of(null))
    );
  }

  getPublishDates(params: RequiredParams, findingClass: Finding['class']): Observable<(Date | null)[]> {
    const url = this.getFindingsUrl(params);

    return this.http
      .get<Date[]>(`${url}/history/dates?class=${findingClass}`)
      .pipe(map((dates) => _.map(dates, Transform.date).sort().reverse()));
  }

  getFindingFromShort({ asset_id, id }: Finding.Short): Observable<Finding> {
    return this.getFinding({
      asset_id,
      finding_id: id,
    });
  }

  export(findings: Finding[]): Observable<void> {
    if (_.isEmpty(findings)) {
      return EMPTY;
    }

    const finding_ids = _.map(findings, ({ id }) => id);
    const { asset_id } = _.head(findings);

    const url = `${this.getFindingsUrl({ asset_id })}/export`;
    return this.http.post<void>(url, { finding_ids });
  }

  getMessages(params: RequiredParams): Observable<Message.Short[]> {
    const url = `${this.getFindingsUrl(params)}/findings/messages`;
    return this.http.get<Message.Short[]>(url).pipe(map((messages) => _.map(messages, Message.Short.transform)));
  }

  create(finding: Partial<Finding>): Observable<Finding> {
    // eslint-disable-next-line
    const url = `${this.getFindingsUrl({ asset_id: finding.asset_id! })}/${finding.class}`;
    return this.http.post<Finding>(url, finding).pipe(map((f) => this.transform(f)));
  }

  save(finding: Partial<Finding>): Observable<Finding> {
    // eslint-disable-next-line
    const url = `${this.getFindingsUrl({ asset_id: finding.asset_id! })}/${finding.class}`;
    const data: Partial<Finding> = {
      ...finding,
      src_id: finding.src_id || finding.id,
    };

    return this.http.post<Finding>(url, data).pipe(map((f) => this.transform(f)));
  }

  delete(params: FindingParams): Observable<void> {
    const url = `${this.getFindingsUrl(params)}/${params.finding_id}`;
    return this.http.delete<void>(url);
  }

  publish({ finding_ids, asset_id }: BatchFindingParams): Observable<void> {
    const url = `${this.getFindingsUrl({ asset_id })}/publish`;
    return this.http.post<void>(url, { finding_ids });
  }

  getBuildings({ asset_id }: { asset_id: string }): Observable<string[]> {
    const url = `${PORTAL_API_URL}/assets/${asset_id}/findings/buildings`;
    return this.http.get<string[]>(url);
  }

  getRentalAreas({ asset_id }: { asset_id: string }): Observable<string[]> {
    const url = `${PORTAL_API_URL}/assets/${asset_id}/findings/rental-areas`;
    return this.http.get<string[]>(url);
  }

  private getPublishedReportFindingsUrl = (reportFindingsParams: PublishedReportFindingsParams) =>
    `${this.apiUrl}/assets/${reportFindingsParams.assetId}/reports/${reportFindingsParams.reportId}/chapters/${reportFindingsParams.chapterId}/findings`;

  private getFindingsUrl({ asset_id }: RequiredParams): string {
    return `${this.apiUrl}/assets/${asset_id}/findings`;
  }

  private transform(finding: Finding): Finding {
    if (!finding) {
      return finding;
    }
    return {
      ...finding,
      created_at: finding.created_at && new Date(finding.created_at),
      deleted_at: finding.created_at && new Date(finding.created_at),
      published_at: finding.published_at && new Date(finding.published_at),
    };
  }
}
