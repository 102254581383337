import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { environment } from '@environments';

export type AvailableIcons =
  | '2fa-security-icon'
  | 'accept-reject'
  | 'add'
  | 'annotation'
  | 'answer'
  | 'arrow'
  | 'arrow-left'
  | 'arrow-left-simple'
  | 'arrow-right'
  | 'assignment'
  | 'bell'
  | 'bubbles'
  | 'big-success-mark'
  | 'calendar'
  | 'calendar-cross'
  | 'campaign'
  | 'chat-bubble-left-right'
  | 'check'
  | 'chevron-arrow-down'
  | 'chevron-arrow-right'
  | 'chevron-left'
  | 'chevron-right'
  | 'circle-gray'
  | 'circle-green-check'
  | 'circle-orange'
  | 'circle-red'
  | 'clipboard-list'
  | 'cloud-upload'
  | 'cog-2'
  | 'cog-8-tooth'
  | 'collaboration'
  | 'copy-from-group'
  | 'cross'
  | 'cube'
  | 'document'
  | 'document-text'
  | 'download'
  | 'drag-drop'
  | 'edit'
  | 'error'
  | 'exclamation'
  | 'exclamation-triangle'
  | 'eye'
  | 'eye-add'
  | 'eye-remove'
  | 'eye-slash'
  | 'file-or-folder-placeholder'
  | 'file-write'
  | 'file-write-add'
  | 'file-write-remove'
  | 'filter'
  | 'folder'
  | 'folder-alt'
  | 'folder-closed'
  | 'folder-opened'
  | 'folder-upload'
  | 'forward'
  | 'fullscreen'
  | 'grid'
  | 'history'
  | 'home-modern'
  | 'horizontal-menu'
  | 'info'
  | 'info-alt'
  | 'key'
  | 'list'
  | 'list-bullet'
  | 'logout'
  | 'magnifying-glass'
  | 'minus'
  | 'missing-file'
  | 'move-y'
  | 'multi-file-checked'
  | 'multi-file-checked-add'
  | 'multi-file-checked-remove'
  | 'multi-file-plus'
  | 'multi-file-plus-add'
  | 'multi-file-plus-remove'
  | 'multi-file-view'
  | 'multi-file-view-add'
  | 'multi-file-view-remove'
  | 'no-results'
  | 'padlock'
  | 'padlock-bordered'
  | 'pencil'
  | 'pencil-square'
  | 'photo'
  | 'photo-activity'
  | 'placeholder-tile-dashed'
  | 'plus'
  | 'presentation-chart-bar'
  | 'presentation-chart-line'
  | 'print'
  | 'print-add'
  | 'print-remove'
  | 'projects'
  | 'qa-activity'
  | 'question-mark-circle'
  | 'quote-source'
  | 'reject-cross'
  | 'reset'
  | 'scale'
  | 'sparkles'
  | 'split-screen'
  | 'star'
  | 'success'
  | 'success-check'
  | 'thick-check'
  | 'ticket'
  | 'ticket-activity'
  | 'trash'
  | 'trash-add'
  | 'trash-bin'
  | 'trash-can'
  | 'trash-remove'
  | 'upload'
  | 'user'
  | 'user-add'
  | 'user-circle'
  | 'users-2'
  | 'users-3'
  | 'version-info';

@Component({
  selector: 'x-svg-icon',
  templateUrl: './svg-icon.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent implements OnChanges {
  @Input() svgImageName?: AvailableIcons;
  @Input() svgClass = 'h-5 w-5';

  assetsPath = environment.production ? '/static/assets' : 'assets';
  svgSrc = '';

  ngOnChanges({ svgImageName }: SimpleChanges): void {
    if (svgImageName) {
      this.svgSrc = `${this.assetsPath}/icons/svg/${svgImageName.currentValue}.svg`;
    }
  }
}
