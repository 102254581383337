import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FileSizePipeModule, SafePipeModule } from '@common';
import { TranslocoModule } from '@ngneat/transloco';
import { FilesTreeModule } from '../files-tree/files-tree.component.module';
import { UploadComponent } from './upload.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    TranslocoModule,
    FilesTreeModule,
    SafePipeModule,
    FileSizePipeModule,
    DialogComponent,
    SvgIconModule,
  ],
  declarations: [UploadComponent],
  exports: [UploadComponent],
})
export class UploadComponentModule {}
