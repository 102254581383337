import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyMenu } from '@angular/material/legacy-menu';
import { MatMenu } from '@angular/material/menu';
import { CellRendererComponent } from '../cell-renderer';

export interface ContextMenuCellRendererParams extends ICellRendererParams {
  menu?: MatLegacyMenu | MatMenu;
  menuProps?: Record<string, unknown>;
}

@Component({
  templateUrl: './context-menu-cell-renderer.component.html',
  styleUrls: ['./context-menu-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContextMenuCellRendererComponent extends CellRendererComponent {
  params?: ContextMenuCellRendererParams;

  get menu(): MatLegacyMenu | MatMenu | undefined {
    return this.params?.menu;
  }

  get node(): RowNode | undefined {
    return this.params?.node;
  }

  get triggerData(): { node: RowNode; data: unknown } {
    return {
      node: this.node as RowNode,
      data: [this.node?.data],
      ...(this.params?.menuProps || {}),
    };
  }

  onClick(event: MouseEvent): void {
    event.stopPropagation();
  }
}
