import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextareaComponent } from '@portal/components/textarea/textarea.component';
import { ButtonComponent } from '@common/components/button/button.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import _isEqual from 'lodash/isEqual';
import { FileWithImageSource } from '@portal/components';
import { TranslocoModule } from '@ngneat/transloco';
import { BadgeComponent } from '@portal/components/badge/badge.component';
import map from 'lodash/map';
import { isNotNil } from '@app/utils';
import { DomSanitizer } from '@angular/platform-browser';
import { AttachReferenceButtonComponent } from '@portal/customer/components/attach-reference-button/attach-reference-button.component';
import { ProcessedReference, Reference } from '@portal/customer/components/attach-reference-button/reference.model';
import { ReferencesPreviewComponent } from '@portal/customer/components/references-preview/references-preview.component';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { Store } from '@ngrx/store';
import { DocumentsPreviewModule } from '@portal/components/documents-preview/documents-preview.module';
import { Document } from '@core/models';
import { DeleteAssetLinkReqBody } from '@core/services';
import { FileIconModule } from '@portal/customer/components/asset-details/data-room/file-icon/file-icon.module';
import { FileExtensionPipe } from '@common/pipes/file-extension/file-extension.pipe';
import { getFileExtension } from '@portal/customer/components/asset-details/data-room/file-icon/file-icon.helper';
import { isPhotoTypeRegExp } from '@portal/components/qa-overview/qa-overview.constants';
import { MessageCategory } from '@portal/customer/services';

export interface WriteResponseModelOutput {
  response: string;
  files: FileWithImageSource[];
  references?: Reference[];
  filesToRemove?: DeleteAssetLinkReqBody[];
}

export interface QaResponseData {
  response: string;
  references: Reference[];
}

@Component({
  selector: 'x-write-response-form',
  standalone: true,
  imports: [
    CommonModule,
    TextareaComponent,
    ButtonComponent,
    SvgIconModule,
    TranslocoModule,
    BadgeComponent,
    AttachReferenceButtonComponent,
    ReferencesPreviewComponent,
    DocumentsPreviewModule,
    FileIconModule,
    FileExtensionPipe,
  ],
  templateUrl: './write-response-form.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WriteResponseFormComponent implements OnInit {
  @Input() messageId: string;
  @Input() messageCategory: MessageCategory;
  @Input() allowReferenceSelect = false;
  @Input() multipleReferences = false;
  @Input() isSaving = false;
  @Input() initialReferences: ProcessedReference[] = [];
  @Input() documents: Document.Short[];
  @Input() initialResponseText: string;
  @Input() isDeleteDocumentPossible = true;
  @Output() modelChange = new EventEmitter<WriteResponseModelOutput>();

  model: WriteResponseModelOutput = { response: '', files: [], references: [] };

  readonly domSanitizer = inject(DomSanitizer);
  readonly cdr = inject(ChangeDetectorRef);
  readonly store = inject(Store);
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);

  ngOnInit() {
    if (this.initialResponseText) {
      this.model.response = this.initialResponseText;
    }
    if (this.initialReferences) {
      this.model.references = this.initialReferences.map((ref) => this.toReference(ref));
    }
    if (this.initialReferences || this.initialResponseText) {
      this.modelChange.emit(this.model);
    }
  }

  onReferenceAdded(refs: Reference[]) {
    if (refs.length) {
      this.model.references = [...this.model.references, ...refs];
    }
    this.modelChange.emit(this.model);
    this.cdr.detectChanges();
  }

  onReferenceRemoved(id: string) {
    this.model.references = this.model.references.filter((ref) => ref.ref_id !== id);
    this.modelChange.emit(this.model);
    this.cdr.detectChanges();
  }

  onFileChanged(input: HTMLInputElement): void {
    const files = input.files;

    const newFiles = (map(new Array(files?.length), (dummy, index) => files?.item(index)) as FileWithImageSource[])
      .filter(isNotNil)
      .map((file) => {
        const extension = getFileExtension(file.name);
        const isPhotoType = isPhotoTypeRegExp.test(extension);
        if (isPhotoType && file.type.includes('image/')) {
          file.imageSrc = this.domSanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(file));
        }
        return file;
      });

    this.model.files = this.model.files?.length > 0 ? [...this.model.files, ...newFiles] : newFiles;
    input.value = '';
    this.modelChange.emit(this.model);
  }

  onResponseChanged(value: string) {
    this.model = {
      ...this.model,
      response: value,
    };
    this.modelChange.emit(this.model);
  }

  handleDocumentsDelete(docs: DeleteAssetLinkReqBody[]) {
    this.model.filesToRemove = docs;
    this.modelChange.emit(this.model);
  }

  onDeleteFileClick(file: FileWithImageSource) {
    this.model.files = this.model.files.filter((modelFile) => !_isEqual(modelFile, file));
  }

  trackByFileIndex(index: number, _: FileWithImageSource): number {
    return index;
  }

  private toReference(processedRef: ProcessedReference): Reference {
    return {
      ref_id: processedRef.id,
      ref_data: {
        name: processedRef.name,
        id: processedRef.id,
        current_no: processedRef.current_no,
      },
      ref_type: processedRef.type,
    };
  }
}
