import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { Component } from '@angular/core';
import * as _ from 'lodash';

import { User } from '@core/models';

export interface PortalUserStatusRendererParams extends ICellRendererParams {
  value: User.Status;
  onChange(status: User.Status): void;
}

@Component({
  selector: 'x-portal-user-status-renderer',
  templateUrl: './portal-user-status-renderer.component.html',
  styleUrls: ['./portal-user-status-renderer.component.scss'],
})
export class PortalUserStatusRendererComponent implements ICellRendererAngularComp {
  readonly Status = User.Status;
  readonly availableStatuses = User.Status.available;

  get value(): User.Status {
    return this.params.value;
  }

  params: PortalUserStatusRendererParams;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  refresh(params: PortalUserStatusRendererParams): boolean {
    let valueChanged = false;

    valueChanged = valueChanged || _.isEmpty(this.params) !== _.isEmpty(params);
    valueChanged = valueChanged || +this.params?.value === +params?.value;

    return valueChanged;
  }

  agInit(params: PortalUserStatusRendererParams): void {
    this.params = params;
  }

  onStatusSelected(status: User.Status): void {
    if (this.params?.onChange) {
      this.params.onChange(status);
    }
  }
}
