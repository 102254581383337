import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { AreaStandard } from '../../../../core/models';

@Component({
  selector: 'x-area-standard-label',
  templateUrl: './area-standard-label.component.html',
  styleUrls: ['./area-standard-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AreaStandardLabelComponent {
  @Input() type: AreaStandard;

  readonly AreaStandard = AreaStandard;
}
