import { CellClassParams, ColDef, ICellRendererParams, ValueGetterParams } from '@ag-grid-community/core';
import { Injectable } from '@angular/core';
import { Finding, FindingField, FindingMonitoringStatus, Risk, VALUE_TO_REPLACE_ZERO } from '@core/models';
import { FormatDateService, FormatNumberService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import {
  BadgeCellRendererComponent,
  BadgeCellRendererData,
} from '@portal/components/badge-cell-renderer/badge-cell-renderer.component';
import { LabelType } from '@portal/components/badge/badge.component';
import { NonPublishedCellRendererComponent } from '@portal/components/grid/non-published-cell-renderer/non-published-cell-renderer.component';
import { RiskCellRendererComponent } from '@portal/components/grid/risk-cell-renderer/risk-cell-renderer.component';
import { TotalCostsRendererComponent } from '@portal/components/grid/total-costs-renderer/total-costs-renderer.component';
import { WrappedTextCellRendererComponent } from '@portal/components/grid/wrapped-text-cell-renderer/wrapped-text-cell-renderer.component';
import { PopupCellRendererComponent } from '@portal/components/popup-cell-renderer/popup-cell-renderer.component';
import { ProbabilityLabelComponent } from '@portal/components/probability-label/probability-label.component';
import { ReferenceCellRendererData } from '@portal/components/qa-overview/qa-reference-cell-renderer/qa-reference-cell-renderer.model';
import * as _ from 'lodash';
import { QAReferenceCellRendererComponent } from '../components/qa-overview/qa-reference-cell-renderer/qa-reference-cell-renderer.component';

const costColumnsWidth = 95;
@Injectable({
  providedIn: 'root',
})
export class FindingHelperService {
  constructor(
    readonly formatNumberService: FormatNumberService,
    readonly formatDateService: FormatDateService,
    readonly transloco: TranslocoService
  ) {}

  getCostsCellClass(cellClassParams: CellClassParams): string {
    const classes = ['!px-0 align-left'];
    const rowPinned = !!cellClassParams?.node?.rowPinned;
    const fieldName = cellClassParams?.colDef?.field as keyof Finding;
    const hasValue = cellClassParams.value || cellClassParams.value?.sum;

    const isCapexSum = cellClassParams?.value?.label === 'sum-capex';

    if (rowPinned) {
      classes.push('!border-b-2', '!overflow-visible');

      if (hasValue && Finding.investmentCostFields.includes(fieldName) && !isCapexSum) {
        classes.push('!border-b-primary-600');
      }

      if ((hasValue && Finding.capexCostFields.includes(fieldName)) || isCapexSum) {
        classes.push('!border-b-secondary-500');
      }
    }

    return classes.join(' ');
  }

  getColumnDefs(): ColDef[] {
    const statusTranslationsAndKeysMap = {
      [this.transloco.translate('finding-monitoring-status.closed')]: 'closed',
      [this.transloco.translate('finding-monitoring-status.discussion')]: 'discussion',
      [this.transloco.translate('finding-monitoring-status.done')]: 'done',
      [this.transloco.translate('finding-monitoring-status.followup')]: 'followup',
      [this.transloco.translate('finding-monitoring-status.open')]: 'open',
      [this.transloco.translate('finding-monitoring-status.regulation_necessary')]: 'regulation_necessary',
    };

    return [
      this.getColDef('current_no', {
        suppressColumnsToolPanel: true,
        minWidth: 30,
        width: 60,
        cellClass: 'py-4 !px-2.5',
        headerClass: '!px-2.5',
      }),
      this.getColDef('buildings', {
        valueGetter: ({ data }: ValueGetterParams) => {
          const { buildings } = data as Finding;
          return buildings || [];
        },
        cellRenderer: ({ value }: ICellRendererParams) =>
          _.map(value as string[], (building) => `<div>${building}</div>`).join(''),
        wrapText: true,
        minWidth: 75,
      }),
      this.getColDef('trade', {
        comparator: ({ text: textA }, { text: textB }) => (textA > textB ? 1 : textA < textB ? -1 : 0),
        valueGetter: ({ data }) => {
          const { trade, published_at } = data as Finding;
          return {
            text: `${trade?.register || ''} ${trade?.description || ''}`.trim(),
            notPublished: !published_at,
          };
        },
        minWidth: 50,
        cellRendererFramework: NonPublishedCellRendererComponent,
        wrapText: true,
      }),
      this.getColDef('location', {
        minWidth: 200,
        cellRendererFramework: PopupCellRendererComponent,
        flex: 1,
      }),
      this.getColDef('level', {
        wrapText: true,
      }),
      this.getColDef('component', { wrapText: true }),
      this.getColDef('rental_areas', {
        valueGetter: ({ data }: ValueGetterParams) => {
          const { rental_areas } = data as Finding;
          return rental_areas || [];
        },
        cellRenderer: ({ value }: ICellRendererParams) =>
          _.map(value as string[], (rental) => `<div>${rental}</div>`).join(''),
        wrapText: true,
        minWidth: 75,
      }),
      this.getColDef('finding_processed', {
        minWidth: 200,
        wrapText: true,
        cellRendererFramework: PopupCellRendererComponent,
        flex: 1,
      }),
      this.getColDef('task', {
        minWidth: 200,
        wrapText: true,
        cellRendererFramework: PopupCellRendererComponent,
        flex: 1,
      }),
      this.getColDef('status', {
        cellClass: ({ value }: CellClassParams) =>
          `status-value status-value-${statusTranslationsAndKeysMap[value]} align-center`,
        minWidth: 200,
        wrapText: true,
        cellRendererFramework: BadgeCellRendererComponent,
        flex: 1,
        cellRendererParams: ({ data }) =>
          <{ value: BadgeCellRendererData }>{
            value: data.status
              ? {
                  /**
                   * t(finding-monitoring-status.discussion)
                   * t(finding-monitoring-status.closed)
                   * t(finding-monitoring-status.open)
                   */
                  text: data.status ? this.transloco.translate(`finding-monitoring-status.${data.status}`) : '',
                  badgeType: this.getBadgeType(data.status),
                }
              : undefined,
          },
      }),
      this.getColDef('guidance', {
        minWidth: 220,
        wrapText: true,
        flex: 1,
        cellRendererFramework: WrappedTextCellRendererComponent,
      }),
      this.getColDef('risk_evaluation', {
        cellClass: ({ value }: CellClassParams) => `risk-value risk-value-${value} align-center`,
        comparator: (riskA: Risk, riskB: Risk) => Risk.orderIndex[riskA] - Risk.orderIndex[riskB],
        minWidth: 40,
        maxWidth: 100,
        cellRendererFramework: RiskCellRendererComponent,
      }),
      this.getColDef('photos', {
        minWidth: 170,
        width: 170,
        cellRendererFramework: QAReferenceCellRendererComponent,
        onCellClicked: () => void 0,
        valueGetter: ({ data }: ValueGetterParams) => {
          const photos = (data as Finding)?.photos;
          if (!photos) {
            return null;
          }

          return <ReferenceCellRendererData>{
            scope: 'finding',
            photos,
          };
        },
      }),
      this.getColDef('extra_notes', {
        wrapText: true,
      }),
      this.getColDef('created_at', {
        cellRenderer: ({ value }: ICellRendererParams) => this.formatDateService.toLocaleString(value, false),
        sortable: true,
        cellClass: 'align-center',
      }),
      this.getColDef('failure_effect', {
        cellRendererFramework: ProbabilityLabelComponent,
        wrapText: true,
      }),
      this.getColDef('failure_probability', {
        cellRendererFramework: ProbabilityLabelComponent,
        wrapText: true,
      }),
      this.getColDef('finding_type', {
        valueFormatter: ({ value }) => (value || '')[0]?.toUpperCase(),
        tooltipValueGetter: ({ value }) => _.upperFirst(value),
        cellClass: 'align-center',
        width: 100,
      }),
      this.getColDef('follow_up_costs', {
        cellRendererFramework: ProbabilityLabelComponent,
        wrapText: true,
      }),
      this.getColDef('lifetime_age', {
        cellClass: 'align-right',
        valueFormatter: ({ value }) => this.formatValueToReplaceZero(value),
      }),
      this.getColDef('lifetime_default', {
        cellClass: 'align-right',
        valueFormatter: ({ value }) => this.formatValueToReplaceZero(value),
      }),
      this.getColDef('lifetime_remaining', {
        cellClass: 'align-right',
        valueFormatter: ({ value }) => this.formatValueToReplaceZero(value),
      }),
      this.getColDef('references', {
        minWidth: 210,
        width: 210,
        cellRendererFramework: QAReferenceCellRendererComponent,
        sortable: false,
        valueGetter: ({ data }: ValueGetterParams) => {
          const links = (data as Finding)?.references;

          return <ReferenceCellRendererData>{
            links,
            asset_id: (data as Finding)?.asset_id,
          };
        },
      }),
      this.getColDef('overhaul_backlog_now', {
        colId: 'overhaul_backlog_now',
        cellClass: this.getCostsCellClass,
        width: costColumnsWidth,
        minWidth: costColumnsWidth,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('overhaul_backlog_now_plan', {
        cellClass: this.getCostsCellClass,
        width: costColumnsWidth,
        minWidth: costColumnsWidth,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('overhaul_backlog_next_year', {
        cellClass: this.getCostsCellClass,
        width: costColumnsWidth,
        minWidth: costColumnsWidth,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('overhaul_backlog_next_year_plan', {
        cellClass: this.getCostsCellClass,
        width: costColumnsWidth,
        minWidth: costColumnsWidth,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('plan_invest_next_years', {
        cellClass: this.getCostsCellClass,
        width: costColumnsWidth,
        minWidth: costColumnsWidth,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('plan_invest_next_years_plan', {
        cellClass: this.getCostsCellClass,
        width: costColumnsWidth,
        minWidth: costColumnsWidth,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('plan_invest_following_years', {
        cellClass: this.getCostsCellClass,
        width: costColumnsWidth,
        minWidth: costColumnsWidth,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('plan_invest_following_years_plan', {
        cellClass: this.getCostsCellClass,
        width: costColumnsWidth,
        minWidth: costColumnsWidth,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('risk_indication', { wrapText: true }),
      this.getColDef('risk_value_eur', {
        cellClass: this.getCostsCellClass,
        valueFormatter: ({ value }) => this.formatCostField(value),
        cellRendererFramework: TotalCostsRendererComponent,
      }),
      this.getColDef('risk_value_result', {
        cellClass: 'align-right',
        valueFormatter: ({ value }) => this.formatNumberService.format(value),
      }),
      this.getColDef('documents', {
        wrapText: true,
        sortable: false,
        cellRendererFramework: QAReferenceCellRendererComponent,
        onCellClicked: () => void 0,
        valueGetter: ({ data }: ValueGetterParams) => {
          const documents = (data as Finding)?.documents;
          if (!documents) {
            return null;
          }

          return <ReferenceCellRendererData>{
            documents,
          };
        },
      }),
      this.getColDef('on_site_visit_at', {
        cellRenderer: ({ value }: ICellRendererParams) =>
          value ? this.formatDateService.toLocaleString(new Date(value), false) : '',
        sortable: true,
        cellClass: 'align-center',
      }),
      this.getColDef('published_at', {
        cellRenderer: ({ value }: ICellRendererParams) =>
          value ? this.formatDateService.toLocaleString(new Date(value), false) : '',
        sortable: true,
        cellClass: 'align-center',
      }),
      this.getColDef('deleted_at', {
        cellRenderer: ({ value }: ICellRendererParams) =>
          value ? this.formatDateService.toLocaleString(new Date(value), false) : '',
        sortable: true,
        cellClass: 'align-center',
      }),
    ];
  }

  formatCostField(value: number | undefined, minimumFractionDigits = 0): string {
    return typeof value === 'number'
      ? value === 0
        ? VALUE_TO_REPLACE_ZERO
        : this.formatNumberService.formatCurrency(value, undefined, minimumFractionDigits)
      : '';
  }

  getAdminColumns(): ColDef[] {
    return [];
  }

  getCustomerColumns(format: Finding.Format = Finding.defaultFormat): ColDef[] {
    const fields = Finding.getFieldsByFormat(format).filter(
      (f) => f !== 'finding_processed' && f !== 'published_at' && f !== 'finding'
    );

    fields.splice(3, 0, 'finding_processed');

    console.log(fields);

    return this.getColumnDefs().map((colDef) => {
      if (!colDef.field) {
        return {
          ...colDef,
          hide: false,
        };
      }

      return {
        ...colDef,
        hide: !fields.includes(colDef.field as unknown as keyof Finding),
      };
    });
  }

  getBadgeType(monitoringStatus: FindingMonitoringStatus): LabelType {
    switch (monitoringStatus) {
      case 'closed':
        return 'success';
      case 'discussion':
        return 'warning';
      case 'open':
        return 'danger';
      default:
        return 'secondary';
    }
  }

  getCustomerFindingOverviewColumns(format: Finding.Format = Finding.defaultFormat): ColDef[] {
    const fields = Finding.getOverviewFieldsByFormat(format);
    const hiddenFields = ['finding_type', 'location', 'level', 'component', 'published_at'];

    return this.getColumnDefs().map((colDef) => {
      if (!colDef.field) {
        return {
          ...colDef,
          hide: false,
        };
      }

      return {
        ...colDef,
        hide: !fields.includes(colDef.field as unknown as keyof Finding) || hiddenFields.includes(colDef.field),
      };
    });
  }

  private formatValueToReplaceZero(value): string {
    return value ? this.formatNumberService.format(value) : VALUE_TO_REPLACE_ZERO;
  }

  private getColDef(field: FindingField, colDef: ColDef): ColDef {
    return {
      field: field as string,
      tooltipField: null,
      headerComponentParams: { displayName: field },
      ...colDef,
    };
  }
}
