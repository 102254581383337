import { createAction, props } from '@ngrx/store';

export namespace AdminFilterActions {
  export const setFilter = createAction(
    '[Admin Photos] Set filter',
    props<{
      data: {
        tags: string[];
      };
    }>()
  );

  export const resetFilter = createAction('[Admin Photos] Reset filter');
  export const publish = createAction('[Admin Photos] Publish');
  export const documentSelected = createAction('[Admin Photos] Document selected', props<{ data: number }>());
}
