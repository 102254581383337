<header *ngIf="isHeaderShowed" class="flex text-gray-900 text-lg font-semibold p-8 space-between">
  <div class="flex flex-col mr-8">
    <span [class]="titleClasses">{{ title || ('are-you-sure' | transloco) }}</span>
    <span class="text-gray-400 text-sm mt-1.5 font-normal" *ngIf="subtitle">{{ subtitle }}</span>
  </div>
</header>
<div
  *ngIf="!hideCloseIcon && !closeDisabled && !submitLoading"
  class="absolute right-8 top-8 cursor-pointer"
  (click)="handleClose.emit()"
>
  <x-svg-icon svgImageName="cross" svgClass="w-7 h-7 text-gray-400 rounded-xl hover:bg-gray-200"></x-svg-icon>
</div>
<section class="flex flex-col flex-1 p-8 pt-0.5 max-h-[65vh] overflow-auto" [ngClass]="classes">
  <ng-content></ng-content>
</section>
<footer class="flex bg-gray-100 items-center justify-end h-16 space-x-2 px-6 py-2" *ngIf="withFooter">
  <ng-content select="[additionalActionsLeft]"></ng-content>
  <x-button color="white" (handleClick)="handleClose.emit()" [disabled]="closeDisabled || submitLoading">
    <x-svg-icon *ngIf="cancelBtnIcon" [svgImageName]="cancelBtnIcon" class="mr-2"></x-svg-icon>
    {{ cancelBtnText || ('dialog.close' | transloco) }}
  </x-button>
  <x-button
    *ngIf="!closeOnly"
    [color]="submitColor || 'primary'"
    (handleClick)="handleSubmit.emit()"
    [disabled]="submitDisabled || submitLoading"
  >
    <x-svg-icon *ngIf="submitBtnIcon" [svgImageName]="submitBtnIcon" class="mr-2"></x-svg-icon>
    {{ submitBtnText || ('dialog.submit' | transloco) }}
    <x-spinner *ngIf="submitLoading" class="ml-1.5" color="accent" size="small"></x-spinner>
  </x-button>
  <ng-content select="[additionalActionsRight]"></ng-content>
</footer>
