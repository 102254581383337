import { createAction, props } from '@ngrx/store';

import { AlertDialogData } from '../../../common/components/alert-dialog/alert-dialog.component';
import { ConfirmationDialogData } from '../../../portal/components/confirmation-dialog/confirmation-dialog.component';

export namespace DialogActions {
  export const closeAll = createAction('[Dialog] Close All');

  export const showAlert = createAction('[Dialog] Show Alert', props<{ data?: AlertDialogData }>());

  export const showProgress = createAction('[Dialog] Show Progress');

  export const showConfirmation = createAction(
    '[Dialog] Show Confirmation',
    props<{ data?: ConfirmationDialogData }>()
  );
}
