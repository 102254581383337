<div class="flex flex-col w-full mx-auto">
  <div class="lg:flex lg:items-center lg:justify-between top-0 sticky bg-gray-100 py-6">
    <div class="min-w-0 flex-1">
      <p class="text-3xl font-bold text-black mr-8">{{ title || ('My Assets' | transloco) }}</p>
    </div>
    <div class="mt-5 lg:flex lg:mt-0 lg:ml-4 space-x-3 flex-2">
      <x-assets-overview-search
        class="min-w-[420px]"
        [viewTypes]="viewTypeToggleData"
        [availableAssets]="assets"
        (viewChange)="selectedViewType = $event"
        [isDisabled]="isNoResultsBeShowedOnInit"
      ></x-assets-overview-search>

      <ng-content></ng-content>
    </div>
  </div>
  <ng-container *ngIf="!assets?.length && isLoading; else gridView">
    <div class="w-full h-full flex flex-col gap-2 align-center justify-center">
      <p class="text-lg text-gray-500">{{ 'loading-assets' | transloco }}</p>
      <x-spinner></x-spinner>
    </div>
  </ng-container>
  <ng-template #gridView>
    <ng-container *ngIf="filteredAssets?.length === 0; else results">
      <x-not-found-alert
        [customPrimaryText]="isNoResultsBeShowedOnInit && noAssetsCustomPrimaryText"
        [customSecondaryText]="isNoResultsBeShowedOnInit && noAssetsCustomSecondaryText"
      ></x-not-found-alert>
    </ng-container>
    <ng-template #results>
      <ng-container *ngIf="selectedViewType === 'grid'; else listView">
        <x-asset-overview-grid-view
          class="min-w-[420px]"
          [assets]="filteredAssets"
          [isEditButtonShowed]="isCardEditButtonShowed"
          [isLoading]="isLoading"
          [isAllFetched]="isAllFetched"
          [newDownloadTokenGetter]="newDownloadTokenGetter"
          (assetClick)="assetClick.emit($event)"
          (editAssetClick)="editAssetClick.emit($event)"
          (lastAssetShown)="lastAssetShown.emit()"
        ></x-asset-overview-grid-view>
      </ng-container>
    </ng-template>
  </ng-template>
</div>
<ng-template #listView>
  <x-asset-overview-list-view
    class="min-w-[420px]"
    [assetData]="filteredAssets"
    (assetClick)="assetClick.emit($event)"
    [newDownloadTokenGetter]="newDownloadTokenGetter"
    (lastAssetShown)="lastAssetShown.emit()"
  ></x-asset-overview-list-view>
</ng-template>
