import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Trade, TradeSet, Transform } from '@core/models';
import * as _ from 'lodash';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { PORTAL_API_URL } from './constants';
import { ResolvedDataSelectors } from '@portal/customer/state';
import { Store } from '@ngrx/store';
import { LanguageService } from '@core/services/language-service';

@Injectable({
  providedIn: 'root',
})
export class TradeService {
  readonly store = inject(Store);
  readonly http = inject(HttpClient);
  readonly lang = inject(LanguageService);

  readonly url = `${PORTAL_API_URL}/tradesets`;
  readonly project$ = this.store.select(ResolvedDataSelectors.getProject);
  readonly trades$ = combineLatest([this.project$, this.lang.language$]).pipe(
    distinctUntilChanged(),
    switchMap(([project, language]) => {
      if (this.tradesCache[language]) {
        return of(this.tradesCache[language]);
      } else {
        return this.getTrades(project?.tdd_trade_set_id).pipe(
          tap((trades) => {
            this.tradesCache[language] = trades;
          })
        );
      }
    }),
    shareReplay(1)
  );

  readonly tradesCache: { [language: string]: Trade[] } = {};

  getTrades(tradeset_id: string): Observable<Trade[]> {
    if (!tradeset_id) {
      return of([]);
    }
    return this.http.get<Trade[]>(`${this.url}/${tradeset_id}/trades`);
  }

  getTradeLabel(tradeId: number, trades: Trade[]): string {
    const trade = trades?.find((trade) => trade.id === tradeId);
    return trade ? `${trade.register} ${trade.description}` : '-';
  }

  getTradeLabelObs(tradeId: number): Observable<string> {
    return this.trades$.pipe(
      take(1),
      map((trades) => this.getTradeLabel(tradeId, trades))
    );
  }

  getTradeSets(): Observable<TradeSet[]> {
    return this.http.get<TradeSet[]>(this.url).pipe(
      map((tradeSets) =>
        _.map(tradeSets, (tset) => ({
          ...tset,
          created_at: Transform.date(tset.created_at),
          updated_at: Transform.date(tset.updated_at),
        }))
      )
    );
  }
}
