import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeUrl } from '@angular/platform-browser';

@Pipe({
  name: 'safeStyle',
})
export class SafeStylePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustStyle(data);
  }
}

@Pipe({
  name: 'safeResource',
})
export class SafeResourcePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(data: string): SafeStyle {
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }
}

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(data: string): SafeStyle {
    return data ? this.sanitizer.bypassSecurityTrustHtml(data) : '';
  }
}

@Pipe({
  name: 'safeUrl',
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(data: string | null | undefined | SafeUrl): SafeUrl | undefined {
    if (!data) {
      return;
    }
    if (typeof data === 'object') {
      return data;
    }
    return this.sanitizer.bypassSecurityTrustUrl(data);
  }
}
