import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@environments';
import { User } from '../models';
import { API_URL } from './constants';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationService {
  readonly apiUrl = API_URL;
  constructor(private readonly http: HttpClient) {}

  confirmEmail(token: string): Observable<boolean> {
    return this.http.post(`${API_URL}/${environment.portalApiUrl}/register/email-confirmation/${token}`, null).pipe(
      map(() => true),
      catchError(() => of(false))
    );
  }

  confirmInvitation(user: User.ConfirmInvitationUser, token: string): Observable<void> {
    return this.http.post<void>(`${API_URL}/${environment.portalApiUrl}/register/invitation/${token}`, user);
  }
}
