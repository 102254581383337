<x-dialog
  classes="w-[70vw] max-w-[600px]"
  [title]="(isEdit ? 'edit-asset' : 'create-asset') | transloco"
  [submitDisabled]="assetFormGroup.invalid"
  (handleSubmit)="onSubmit()"
  (handleClose)="onClose()"
>
  <form class="grid grid-cols-3 gap-4" autocomplete="off">
    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px] required">
      {{ 'asset-name' | transloco }}:
    </span>
    <x-input class="col-span-2" [control]="assetFormGroup.get('name') | formControl"></x-input>

    <div class="col-span-3 grid grid-cols-2 gap-4">
      <span class="flex items-center space-x-4 text-gray-900 text-md col-span-2 min-w-[240px] max-h-[38px] required">
        {{ 'asset-types' | transloco }}:
      </span>
      <x-checkbox
        *ngFor="let assetType of AssetType.all"
        class="col-span-1"
        [control]="assetFormGroup.get('asset_types').get(assetType) | formControl"
        [name]="assetType"
      >
        <x-asset-type-label [type]="assetType"></x-asset-type-label>
      </x-checkbox>
    </div>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px]">
      {{ 'year-of-construction' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      type="number"
      [control]="assetFormGroup.get('year_of_construction') | formControl"
    ></x-input>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px]">
      {{ 'plot-size' | transloco }}:
    </span>
    <x-input class="col-span-2" type="number" [control]="assetFormGroup.get('plot_size') | formControl"></x-input>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px]">
      {{ 'gross-area' | transloco }}:
    </span>
    <x-input class="col-span-2" type="number" [control]="assetFormGroup.get('gross_area') | formControl"></x-input>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px]">
      {{ 'parking-places' | transloco }}:
    </span>
    <x-input
      class="col-span-2"
      type="number"
      [control]="assetFormGroup.get('parking_garage_spaces') | formControl"
    ></x-input>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px] required">
      {{ 'country' | transloco }}:
    </span>
    <x-select
      class="col-span-2"
      [options]="sortedCountries"
      [control]="assetFormGroup.get('address').get('country') | formControl"
    ></x-select>

    <ng-container *ngIf="assetFormGroup.get('address').get('country').value === Countries.Germany">
      <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px] required">
        {{ 'state' | transloco }}:
      </span>
      <x-select
        class="col-span-2"
        [options]="states"
        [control]="assetFormGroup.get('address').get('state') | formControl"
      ></x-select>
    </ng-container>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px] required">
      {{ 'city' | transloco }}:
    </span>
    <x-input class="col-span-2" [control]="assetFormGroup.get('address').get('city') | formControl"></x-input>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px] required">
      {{ 'street-s' | transloco }}:
    </span>
    <x-input class="col-span-2" [control]="assetFormGroup.get('address').get('line1') | formControl"></x-input>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px] required">
      {{ 'street-on-map' | transloco }}:
    </span>
    <x-input class="col-span-2" [control]="assetFormGroup.get('address').get('line2') | formControl"></x-input>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px] required">
      {{ 'zip' | transloco }}:
    </span>
    <x-input class="col-span-2" [control]="assetFormGroup.get('address').get('zip') | formControl"></x-input>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px]">
      {{ 'termination-notice-at' | transloco }}:
    </span>
    <!--    TODO : use better input with datepicker -->
    <div class="col-span-2">
      <input
        class="block w-full rounded-md px-3 py-2 placeholder-gray-400 shadow-sm text-sm border-gray-300 focus:border-primary-500 focus:outline-none focus:ring-primary-500"
        name="termination_notice_at"
        [style.border-width.px]="1"
        [matDatepicker]="picker"
        [formControl]="assetFormGroup.get('termination_notice_at') | formControl"
        [min]="minDate"
        (click)="picker.open()"
      />
      <mat-datepicker #picker></mat-datepicker>
    </div>

    <span class="flex items-center space-x-4 text-gray-900 text-md col-span-1 min-w-[240px] max-h-[38px] required">
      {{ 'decimal-separator' | transloco }}:
    </span>
    <x-select
      class="col-span-2"
      [options]="separators"
      [control]="assetFormGroup.get('decimal_separator') | formControl"
    ></x-select>
  </form>
</x-dialog>
