import { EntityState } from '@ngrx/entity';
import { createFeatureSelector } from '@ngrx/store';

import { Document } from '../../../../core/models';
import { AssetDetailsReducer } from './asset-details.reducer';

const assetDetailsDocumentsState = createFeatureSelector<EntityState<Document.ListItem>>(
  AssetDetailsReducer.DocumentsReducer.name
);
const { selectAll, selectEntities } =
  AssetDetailsReducer.DocumentsReducer.adapter.getSelectors(assetDetailsDocumentsState);

export namespace AssetDetailsDocumentsSelectors {
  export const getAll = selectAll;

  export const getEntities = selectEntities;
}
