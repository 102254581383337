import { getAllEnumValues } from './enum';

export enum Quantity {
  All = 'all',
  Some = 'some',
  None = 'none',
}

export namespace Quantity {
  export const all = getAllEnumValues<Quantity>(Quantity);
}
