import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AlertType } from '@common';
import { Document } from '@core/models';
import { StorageService } from '@core/services';
import { DialogActions } from '@core/state';
import { Store } from '@ngrx/store';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { DocumentsService } from '../../services/documents.service';
import { RoutingService } from '../../services/routing.service';
import { CellRendererComponent } from '../grid';

@Component({
  templateUrl: './photos-cell-renderer.component.html',
  styleUrls: ['./photos-cell-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhotosCellRendererComponent extends CellRendererComponent<Document.ListItem[]> {
  readonly selectedPhoto$ = new BehaviorSubject<Document.ListItem>(null);
  readonly imageData$ = this.selectedPhoto$.pipe(
    switchMap((photo) => (!photo ? of(null) : this.documentsService.getDownloadToken(photo, { version: 360 }))),
    switchMap((download_token) => {
      if (!download_token) {
        return of(null);
      }

      return this.storageService.getImageWithDownloadToken({ download_token }).pipe(
        map((blob) => window.URL.createObjectURL(blob)),
        tap(() =>
          setTimeout(() => {
            this.cdr.detectChanges();
          }, 10)
        ),
        catchError((httpError: HttpErrorResponse) => {
          this.store.dispatch(
            DialogActions.showAlert({
              data: {
                type: AlertType.Error,
                error: httpError.error,
              },
            })
          );

          return of(null);
        })
      );
    })
  );
  readonly imageLink$ = this.selectedPhoto$.pipe(
    map((photo) => (!photo ? of('') : `.${this.routingService.getDocumentViewerLink(photo)}`))
  );

  constructor(
    private store: Store<unknown>,
    private storageService: StorageService,
    private documentsService: DocumentsService,
    private routingService: RoutingService
  ) {
    super();
  }

  selectImage(photo: Document.ListItem) {
    this.selectedPhoto$.next(photo);
  }
}
