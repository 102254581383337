import { Pipe, PipeTransform } from '@angular/core';
import { FormatNumberService } from '@core/services';

@Pipe({
  name: 'localeNumber',
})
export class LocaleNumberPipe implements PipeTransform {
  constructor(private readonly formatNumberService: FormatNumberService) {}

  transform(value: number | unknown, currency?: string): string {
    return this.formatNumberService.format(value as number, currency);
  }
}
