import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { PortalProjectAssetsEffects } from './portal-project-assets.effects';
import { ProjectAssetsReducer } from './portal-project-assets.reducer';

@NgModule({
  imports: [
    EffectsModule.forFeature([PortalProjectAssetsEffects]),
    StoreModule.forFeature(ProjectAssetsReducer.name, ProjectAssetsReducer.portalProjectAssetsReducer),
  ],
})
export class PortalProjectAssetsStateModule {}
