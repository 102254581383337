import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Data, NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments';
import { filter, map } from 'rxjs/operators';

export interface RouteDataTitle extends Data {
  title: string;
}

@Injectable()
export class TitleService {
  constructor(router: Router, activatedRoute: ActivatedRoute, title: Title) {
    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          const pageTitle = [environment.documentTitle, ...this.getTitles(activatedRoute)].join(' - ');
          title.setTitle(pageTitle);
        })
      )
      .subscribe();
  }

  getTitles(route: ActivatedRoute, titles: string[] = []): string[] {
    const title = route.routeConfig && route.routeConfig.data ? route.routeConfig.data.title : null;

    const newTitles = title ? [...titles, title] : titles;
    if (route.firstChild) {
      return this.getTitles(route.firstChild, newTitles);
    }
    return newTitles;
  }
}
