import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslocoModule } from '@ngneat/transloco';
import { RiskLabelComponent } from './risk-label.component';

@NgModule({
  imports: [CommonModule, TranslocoModule],
  declarations: [RiskLabelComponent],
  exports: [RiskLabelComponent],
})
export class RiskLabelModule {}
