import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map, switchMap, withLatestFrom } from 'rxjs/operators';
import { CustomerDocumentsService } from '../../services/customer-documents.service';
import { CustomerRouterSelectors } from '../customer-router.selectors';
import { AssetDetailsActions } from './asset-details.actions';

@Injectable()
export class AssetDetailsEffects {
  readonly loadDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssetDetailsActions.fetchDocument),
      withLatestFrom(
        this.store.select(CustomerRouterSelectors.getAssetId),
        this.store.select(CustomerRouterSelectors.getProjectAssetDocumentId)
      ),
      switchMap(([, asset_id, document_id]) => this.documentsService.getDocument(asset_id, document_id)),
      map((document) => AssetDetailsActions.fetchDocumentSuccess({ data: document }))
    )
  );

  constructor(
    private actions$: Actions,
    private store: Store<never>,
    private documentsService: CustomerDocumentsService
  ) {}
}
