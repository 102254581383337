import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { inject, Injectable } from '@angular/core';
import {
  SidePanelDialogComponent,
  SidePanelDialogComponentData,
} from '@common/components/side-panel-dialog/side-panel-dialog.component';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { Message } from '@core/models';
import { SidePanelMessagesComponent } from '@common/components/side-panel-messages/side-panel-messages.component';
import { TranslocoService } from '@ngneat/transloco';
import { NavigationStart, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export interface SidePanelDialogRef {
  ref: MatDialogRef<SidePanelDialogComponent>;
  setDisabledState: (disabled: boolean) => void;
  updateComponentData: (data: SidePanelDialogComponentData) => void;
}

@Injectable({
  providedIn: 'root',
})
export class SidePanelDialogService {
  private sidePanelDialogRef?: SidePanelDialogRef;
  private readonly dialog = inject(MatDialog);
  private readonly sidePanelDialogConfig: MatDialogConfig = {
    height: '100vh',
    closeOnNavigation: true,
    disableClose: true,
    position: {
      top: '0',
      right: '0',
    },
    autoFocus: false,
    scrollStrategy: new NoopScrollStrategy(),
    panelClass: 'x-side-panel-dialog',
  };
  private readonly transloco = inject(TranslocoService);
  private readonly router = inject(Router);

  constructor() {
    this.router.events.pipe(filter((event) => event instanceof NavigationStart)).subscribe(() => {
      this.closeAll();
    });
  }

  openSingle<T>(data: SidePanelDialogComponentData<T>) {
    this.sidePanelDialogRef?.ref.close(false);
    this.sidePanelDialogRef = this.open(data);
    return this.sidePanelDialogRef;
  }

  open<T>(data: SidePanelDialogComponentData<T>) {
    return this.createSidePanelDialogRef(
      this.dialog.open(SidePanelDialogComponent, {
        ...this.sidePanelDialogConfig,
        data,
      })
    );
  }

  openMessagesPanel(messages: Message.Short[], allMessages: Message.Short[]) {
    return this.open({
      title: this.transloco.translate('messages'),
      hideSubmit: true,
      hideHeadingLine: true,
      noHeadingPadding: true,
      component: SidePanelMessagesComponent,
      classes: 'pt-0',
      data: {
        messages,
        allMessages,
      },
    });
  }

  closeAll() {
    this.dialog.closeAll();
  }

  updateComponentData(ref: MatDialogRef<SidePanelDialogComponent>, data: SidePanelDialogComponentData) {
    const instance = ref.componentInstance;
    if (instance) {
      instance.data = data;
      instance.dataChangesSubject.next(data);
    }
  }

  setDisabledState(ref: MatDialogRef<SidePanelDialogComponent>, disabled: boolean) {
    const instance = ref.componentInstance;
    if (instance) {
      instance.submitDisabled = disabled;
    }
  }

  private createSidePanelDialogRef(ref: MatDialogRef<SidePanelDialogComponent>): SidePanelDialogRef {
    return {
      ref,
      setDisabledState: (disabled) => this.setDisabledState(ref, disabled),
      updateComponentData: (data) => this.updateComponentData(ref, data),
    };
  }
}
