<div class="w-full h-full">
  <cdk-virtual-scroll-viewport
    *ngIf="assets?.length; else loadingTemplate"
    #scrollViewport
    [appendOnly]="true"
    orientation="vertical"
    class="viewport h-full w-full"
    [itemSize]="VSItemSize"
  >
    <x-shop-advertisement-tile
      class="col-span-2 xl:col-span-1"
      (closed)="onAdTileClosed()"
      *ngIf="adFileVisible"
    ></x-shop-advertisement-tile>
    <x-card
      class="col-span-2 xl:col-span-1"
      [classes]="
        !isClickable || isHoverOnEditAssetButton
          ? 'min-h-[190px] min-w-full !p-4'
          : 'min-h-[190px] min-w-full !p-4 hover:bg-gray-50 cursor-pointer'
      "
      *cdkVirtualFor="let asset of assets; trackBy: trackByFn"
      variant="thin"
      (click)="assetClick.emit(asset)"
    >
      <div class="flex flex-row h-full text-gray-500">
        <div class="flex-1 flex-shrink-0 min-w-[150px] h-full relative">
          <x-asset-overview-image-tile
            imageClasses="h-full"
            [assetId]="asset.asset_id"
            [imageDownloadToken]="asset.asset_photo_download_token"
            [newDownloadTokenGetter]="newDownloadTokenGetter"
          ></x-asset-overview-image-tile>
        </div>
        <div class="flex flex-2 flex-column w-[66%] pl-6 px-2">
          <span class="text-xs font-semibold flex" [title]="asset.asset_name">
            <p class="text-xs font-semibold" [title]="asset.project_name">{{ asset.project_name }}</p>
            <p
              title="{{ terminationDate | date }}"
              *ngIf="asset.asset_termination_notice_at as terminationDate"
              class="text-danger-400 min-w-fit text-end ml-auto"
            >
              {{ terminationDate | date }}
            </p>
          </span>
          <div class="flex justify-between items-center">
            <p class="text-black text-lg font-bold" [title]="asset.asset_name">{{ asset.asset_name }}</p>
            <x-button
              *ngIf="isEditButtonShowed"
              class="translate-y-0.5"
              iconButtonIconName="pencil-square"
              (click)="$event.stopPropagation(); editAssetClick.emit(asset)"
              (mouseenter)="setHoverOnButton(true)"
              (mouseleave)="setHoverOnButton(false)"
            ></x-button>
          </div>
          <div class="font-sm font-normal leading-6 mt-0.5 text-gray-500">
            <p>{{ asset.asset_street1 }}</p>
            <p>{{ asset.asset_zip }} {{ asset.asset_city }}</p>
            <p>
              <x-country-label [country]="asset.asset_country"></x-country-label>
            </p>
          </div>
          <div class="flex flex-wrap flex-row mt-auto items-center">
            <x-badge
              label="{{ 'asset-type.' + asset.project_product_type | transloco }}"
              class="py-2"
              classes="min-w-[2rem]"
              badgeType="secondary_light"
              [text]="asset.asset_token"
            ></x-badge>
            <div
              class="mx-2 border-l border-gray-300 w-[1px] h-[1rem] block"
              #separatorRef
              [ngClass]="{ 'opacity-0': isNotInSameLine(separatorRef, statusBadgesRef) }"
            ></div>
            <div class="flex flex-row flex-wrap" #statusBadgesRef>
              <x-badge
                class="mr-1"
                [badgeType]="getBadgeType(asset.asset_status)"
                [text]="'asset-statuses.' + asset.asset_status | transloco"
              ></x-badge>
              <x-badge
                *ngIf="asset.asset_is_frozen"
                [badgeType]="'primary_light'"
                [text]="'is-frozen' | transloco"
              ></x-badge>
            </div>
          </div>
        </div>
      </div>
    </x-card>
    <ng-container *ngIf="isLoadingAssets">
      <ng-container *ngTemplateOutlet="loadingTemplate"></ng-container>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</div>

<ng-template #loadingTemplate>
  <div *ngIf="!isAllFetched" class="w-full col-span-2 h-full flex flex-col gap-2 align-center justify-center">
    <p class="text-lg text-gray-500">{{ 'loading-assets' | transloco }}</p>
    <x-spinner></x-spinner>
  </div>
</ng-template>
