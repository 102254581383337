import { Pipe, PipeTransform } from '@angular/core';
import { getFileExtension } from '@portal/customer/components/asset-details/data-room/file-icon/file-icon.helper';

@Pipe({
  name: 'fileExtension',
  standalone: true,
})
export class FileExtensionPipe implements PipeTransform {
  transform(filename: string): string | null {
    return getFileExtension(filename);
  }
}
