import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Risk, RiskScore } from '@core/models';

@Component({
  selector: 'x-risk-label',
  templateUrl: './risk-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskLabelComponent {
  @Input() hasTooltip = false;
  @Input() risk: Risk;

  readonly Risk = Risk;
  readonly RiskScore = RiskScore;
}
