import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { Document, FindingClass, Message, Permission } from '@core/models';

export interface Reference {
  src_id?: string;
  ref_type: RefType;
  ref_id: string;
  ref_data: RefPhotoData | RefQAData | RefTicketData | RefFindingData | RefDocumentData | RefReportData;
  href?: string;
}

export interface ProcessedReference {
  id: string;
  type: RefType;
  name: string;
  page?: number;
  current_no?: number;
  class?: FindingClass;
  icon?: AvailableIcons;
  document?: Document.Short;
}

export interface RefPhotoData {
  id: string;
  name: string;
}

export interface RefQAData {
  message_id: string;
  current_no: number;
  message: string;
}

export interface RefTicketData {
  current_no: number;
  type: string;
  task: string;
}

export interface RefFindingData {
  id: string;
  current_no: number;
  finding: string;
  class: 'monitoring' | 'tdd';
}

export interface RefDocumentData {
  nodeId: string;
  source_page: number;
  name: string;
}

export interface RefReportData {
  id: string;
  name: string;
}

export type RefType = 'document' | 'finding' | 'photo' | 'report' | Message.Category | 'video';
export type RefSelectionType =
  | 'DataRoomFileSelection'
  | 'FindingSelection'
  | 'PhotoSelection'
  | 'TicketSelection'
  | 'QASelection'
  | 'ReportSelection';

export interface RefOption {
  iconName: AvailableIcons;
  type: RefType;
  labelTranslationKey?: string;
  permission: keyof Permission;
}

export enum References {
  DOCUMENT = 'document',
  FINDING = 'finding',
  PHOTO = 'photo',
  TICKET = 'ticket',
  QA = 'qa',
  REPORT = 'report',
}

export const refSelectionName = new Map<string, RefSelectionType>([
  [References.DOCUMENT, 'DataRoomFileSelection'],
  [References.FINDING, 'FindingSelection'],
  [References.PHOTO, 'PhotoSelection'],
  [References.TICKET, 'TicketSelection'],
  [References.QA, 'QASelection'],
  [References.REPORT, 'ReportSelection'],
]);
