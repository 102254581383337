<x-asset-photo-viewer
  *ngIf="data?.document; let document"
  [mediaType]="document.types[0]"
  [getPhoto]="photoService.getAndCachePhoto"
  [currentPhoto]="data.document"
  [borderlessContainer]="true"
></x-asset-photo-viewer>

<x-svg-icon
  class="w-7 h-7 text-gray-400 cursor-pointer hover:bg-gray-200 rounded-2xl"
  svgClass="w-7 h-7"
  [svgImageName]="'cross'"
  mat-dialog-close=""
></x-svg-icon>
