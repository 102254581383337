<span class="flex flex-row relative text-center items-center">
  {{ data?.current_no }}
  <x-svg-icon
    *ngIf="data?.rejected_reason"
    svgImageName="exclamation"
    class="cursor-help ml-1.5"
    svgClass="text-danger-600 w-4 h-5"
    title="{{ 'qa-overview.tooltip.rejected' | transloco }}"
  ></x-svg-icon>
</span>
