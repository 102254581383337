import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Document } from '@core/models';
import { Store } from '@ngrx/store';
import { CustomerDocumentsService } from '@portal/customer/services';
import { CustomerRouterSelectors } from '@portal/customer/state';
import * as _ from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';

@Pipe({
  name: 'documentDetails',
})
export class DocumentDetailsPipe implements PipeTransform {
  constructor(
    private readonly store: Store,
    private readonly documentsService: CustomerDocumentsService,
    private cdr: ChangeDetectorRef
  ) {}

  transform(document_id: string): Observable<Document> {
    if (_.isEmpty(document_id) || document_id.includes(' ')) {
      return EMPTY;
    }

    return this.store.select(CustomerRouterSelectors.getAssetId).pipe(
      mergeMap((asset_id) =>
        this.documentsService.getDocument(asset_id, document_id).pipe(
          tap(() => setTimeout(() => this.cdr.detectChanges())),
          catchError(() => EMPTY)
        )
      )
    );
  }
}
