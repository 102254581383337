import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateTrackerModel, Tracker, Transform } from '@core/models';
import { PORTAL_API_URL } from '@core/services';
import * as _ from 'lodash';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TrackersService {
  readonly apiUrl = `${PORTAL_API_URL}/admin`;

  constructor(private http: HttpClient) {}

  fetch(projectId: string, assetId: string): Observable<Tracker[]> {
    return this.http
      .get<Tracker[]>(`${this.apiUrl}/projects/${projectId}/assets/${assetId}/trackers`)
      .pipe(map((trackers) => _.map(trackers, (tracker) => Transform.tracker(tracker))));
  }

  delete(projectId: string, assetId: string, trackerId: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/projects/${projectId}/assets/${assetId}/trackers/${trackerId}`);
  }

  update(projectId: string, assetId: string, tracker: Tracker): Observable<void> {
    if (_.isEmpty(projectId) || _.isEmpty(assetId) || !tracker) {
      return EMPTY;
    }

    return this.http.put<void>(
      `${this.apiUrl}/projects/${projectId}/assets/${assetId}/trackers/${tracker.id}`,
      tracker
    );
  }

  create(
    { project_id, asset_id }: { project_id: string; asset_id: string },
    trackers: CreateTrackerModel[]
  ): Observable<void> {
    if (_.isEmpty(project_id) || _.isEmpty(asset_id) || !trackers) {
      return EMPTY;
    }

    return this.http.post<void>(`${this.apiUrl}/projects/${project_id}/assets/${asset_id}/trackers`, trackers);
  }
}
