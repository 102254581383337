import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { TextareaComponent } from '@portal/components/textarea/textarea.component';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  templateUrl: './message-reject-dialog.component.html',
  styleUrls: ['./message-reject-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [DialogComponent, TextareaComponent, TranslocoModule],
})
export class MessageRejectDialogComponent {
  reason = '';

  constructor(
    public dialogRef: MatDialogRef<void, string>,
    @Inject(MAT_DIALOG_DATA) readonly data: { type: 'answer' | 'message'; title: string }
  ) {}

  onCancel(): void {
    this.dialogRef.close(undefined);
  }

  onSubmit(): void {
    this.dialogRef.close(this.reason);
  }
}
