import { Injectable } from '@angular/core';
import { AssetOverview } from '@core/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AssetsOverviewSearchService {
  private rowData$ = new Subject<AssetOverview[]>();

  getSearchFilter(): Observable<AssetOverview[]> {
    return this.rowData$.asObservable();
  }

  setSearchFilter(rowData: AssetOverview[]): void {
    this.rowData$.next(rowData);
  }
}
