import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SHORT_DEBOUNCE_TIME } from '@app/utils/rxjs.utils';
import { AvailableIcons } from '@common/components/svg-icon/svg-icon.component';
import { api, DataRoom, Message, Transform } from '@core/models';
import { FormatDateService, PORTAL_API_URL, UserService } from '@core/services';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { ResolvedDataSelectors } from '@portal/customer/state';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { isNotNil } from '@app/utils';

export interface PageResponse<T> extends api.PaginationResponse<T> {
  nodes?: T[];
}

export interface NodeSetParams {
  asset_id: string;
  node_id: string;
  node_set: 'documents' | 'folders' | 'childs' | 'ancestors';
  group?: string;
}

export type MoveNodeNextToPosition = 'above' | 'below';

const IS_NODES_TREE_SORTED_ALPHABETICALLY_LOCAL_STORAGE_KEY = 'is_nodes_tree_sorted_alphabetically';
const SORTED_ALPHABETICALLY_DEFAULT_VALUE = true;

@Injectable({
  providedIn: 'root',
})
export class DataRoomService {
  isNodesTreeSortedWithFoldersFirst$ = new BehaviorSubject<boolean>(SORTED_ALPHABETICALLY_DEFAULT_VALUE);

  nodeSetMap = new Map<string, Observable<DataRoom.Node[]>>();

  private readonly apiUrl = `${PORTAL_API_URL}/assets`;
  private readonly indexPointsEnabled$ = this.store
    .select(ResolvedDataSelectors.getIndexPointsEnabled)
    .pipe(shareReplay(1));
  private readonly settings$ = this.store.select(ResolvedDataSelectors.getAssetSettings).pipe(shareReplay(1));

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store,
    private readonly transloco: TranslocoService,
    private readonly formatDateService: FormatDateService,
    private readonly userService: UserService
  ) {
    this.indexPointsEnabled$.subscribe(async (indexPointsEnabled) => {
      if (indexPointsEnabled) {
        this.isNodesTreeSortedWithFoldersFirst$.next(false);
      } else {
        const isSortedAlphabetically = await this.getAreNodesTreeSorted().toPromise();
        this.isNodesTreeSortedWithFoldersFirst$.next(isSortedAlphabetically);
      }
    });
    this.setIsNodesTreeSortedAlphabeticallyLocalStorageValueOnChanges();
  }

  getRoots({ asset_id }: { asset_id: string }) {
    const url = `${this.apiUrl}/${asset_id}/nodes/roots`;
    return this.http.get<DataRoom.Node[]>(url).pipe(
      map((roots) => _.map(roots, DataRoom.Node.transform)),
      map((roots) => _.orderBy(roots, ({ root_type }) => root_type, 'asc'))
    );
  }

  getNodeSet(nodeSetParams: NodeSetParams) {
    const paramsKey = JSON.stringify(nodeSetParams);
    if (!this.nodeSetMap.has(paramsKey)) {
      this.nodeSetMap.set(
        paramsKey,
        new BehaviorSubject(nodeSetParams).pipe(
          switchMap((nodeSetParams) => this.fetchNodeSetData(nodeSetParams)),
          shareReplay()
        )
      );
    }

    return this.nodeSetMap.get(paramsKey).pipe(
      debounceTime(SHORT_DEBOUNCE_TIME),
      take(1),
      tap(() => {
        this.nodeSetMap.delete(paramsKey);
      })
    );
  }

  getNode({
    asset_id,
    node_id,
    group,
  }: {
    asset_id: string;
    node_id: string;
    group?: string;
  }): Observable<DataRoom.Node> {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}`;
    const params = <HttpParams>{};
    if (group) {
      params['permission_group_1'] = group;
    }

    return node_id
      ? this.http.get<DataRoom.Node>(url, { params }).pipe(map(DataRoom.Node.transform))
      : of(null).pipe(tap(() => console.warn('Could not get node, because node_id is missing in request parameters')));
  }

  indexExport(assetId: string): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/${assetId}/nodes/export`, {});
  }

  getNodeTree({
    asset_id,
    node_id,
    node_set,
    page,
    per_page,
    sort_column,
    sort_direction,
  }: { asset_id: string; node_id: string; node_set: 'documents' | 'folders' } & api.PaginationRequest) {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}/tree/${node_set}`;
    const params = _.omitBy(
      <api.PaginationRequest>{
        page,
        per_page,
        sort_column,
        sort_direction,
      },
      _.isUndefined
    );
    return this.http.get<PageResponse<DataRoom.Node>>(url, { params }).pipe(
      map((response) => ({
        ...response,
        nodes: _.map(response.nodes, DataRoom.Node.transform),
      }))
    );
  }

  deleteNode({ asset_id, node_id }: { asset_id: string; node_id: string }): Observable<void> {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}`;
    return this.http.delete<void>(url);
  }

  deleteNodes({ asset_id, node_ids }: { asset_id: string; node_ids: string[] }): Observable<void> {
    const url = `${this.apiUrl}/${asset_id}/nodes`;
    return this.http.delete<void>(url, { body: { node_ids } });
  }

  renameNode({
    asset_id,
    node_id,
    label,
  }: {
    asset_id: string;
    node_id: string;
    label: string;
  }): Observable<DataRoom.Node> {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}`;
    return this.http.put<DataRoom.Node>(url, { label });
  }

  createNode({
    asset_id,
    node_id,
    label,
    option,
    group,
    index_point,
  }: {
    asset_id: string;
    node_id: string;
    label: string;
    option: DataRoom.PermissionOption;
    group?: string;
    index_point?: string;
  }): Observable<DataRoom.Node> {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}`;

    const params = <HttpParams>{};
    if (group) {
      params['permission_group_1'] = group;
    }

    return this.http.post<DataRoom.Node>(
      url,
      {
        label,
        index_point,
        permission_option: option,
      },
      { params }
    );
  }

  renumerate(asset_id: string, node_id: string) {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}/renumerate`;
    return this.http.post<DataRoom.Node>(url, {});
  }

  moveNode({
    asset_id,
    node_id,
    target_node_id,
    option,
  }: {
    asset_id: string;
    node_id: string;
    target_node_id: string;
    option: DataRoom.PermissionOption;
  }): Observable<DataRoom.Node> {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}/moveto/${target_node_id}`;
    return this.http.post<DataRoom.Node>(url, {
      permission_option: option,
    });
  }
  moveNodes({
    asset_id,
    node_ids,
    target_node_id,
    option,
    next_to_node_id,
    next_to_position,
  }: {
    asset_id: string;
    node_ids: string[];
    target_node_id: string;
    option: DataRoom.PermissionOption;
    next_to_node_id?: string;
    next_to_position?: MoveNodeNextToPosition;
  }): Observable<DataRoom.Node> {
    const url = `${this.apiUrl}/${asset_id}/nodes/moveto/${target_node_id}`;
    return this.http.post<DataRoom.Node>(
      url,
      this.prepareCopyOrMoveParams(option, node_ids, next_to_node_id, next_to_position)
    );
  }

  copyNodes({
    asset_id,
    node_ids,
    target_node_id,
    option,
    next_to_node_id,
    next_to_position,
  }: {
    asset_id: string;
    node_ids: string[];
    target_node_id: string;
    option: DataRoom.PermissionOption;
    next_to_node_id?: string;
    next_to_position?: MoveNodeNextToPosition;
  }): Observable<DataRoom.Node> {
    const url = `${this.apiUrl}/${asset_id}/nodes/copy/${target_node_id}`;
    return this.http.post<DataRoom.Node>(
      url,
      this.prepareCopyOrMoveParams(option, node_ids, next_to_node_id, next_to_position)
    );
  }

  getNodePermissionsForAllGroups({ asset_id, node_id }: { asset_id: string; node_id: string }) {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}/permission`;
    return this.http.get<_.Dictionary<DataRoom.NodeGroupPermission>>(url);
  }

  setNodePermissionsForGroup({
    asset_id,
    node_id,
    update,
  }: {
    asset_id: string;
    node_id: string;
    update: DataRoom.PermissionGroupUpdate;
  }) {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}/permission`;
    return this.http.post<void>(url, update);
  }

  copyNodePermissionForGroup({
    asset_id,
    node_id,
    update,
  }: {
    asset_id: string;
    node_id: string;
    update: DataRoom.PermissionGroupCopyPermissionsUpdate;
  }) {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}/permission/copy`;
    return this.http.post<void>(url, update);
  }

  getPermissionForNodes({
    asset_id,
    group_ids,
    node_ids,
  }: {
    asset_id: string;
    group_ids: string[];
    node_ids: string[];
  }) {
    const url = `${this.apiUrl}/${asset_id}/nodes/permission`;
    return this.http.post<DataRoom.Node[]>(url, {
      group_ids,
      node_ids,
    });
  }

  /**
   *
   * @param asset_id string
   * @param params
   * @param page
   * @param per_page
   * @param params.query search term
   * @param params.last_days new nodes of the last x days
   * @param params.only_docs only search through documents
   * @param params.only_folders only search through folder names
   * @param params.only_placeholders only search through placeholders
   * @returns
   */
  search(asset_id: string, params: DataRoom.Search.Params, page = 0, per_page = 100) {
    const url = `${this.apiUrl}/${asset_id}/nodes/search`;

    return this.http
      .get<PageResponse<DataRoom.Search.Result>>(url, {
        params: <HttpParams>(<unknown>{
          ..._.omitBy(params, (el) => _.isNil(el) || el === false),
          page,
          per_page,
        }),
      })
      .pipe(
        map((result) => ({
          ...result,
          data: _.map(result.data, (record) => ({
            ...record,
            created_at: Transform.date(record.created_at),
            updated_at: Transform.date(record.updated_at),
          })),
        }))
      );
  }

  /**
   * Load specific node messages.
   *
   * Use it for updates only!
   */
  getNodeMessages({ asset_id, node_id }: { asset_id: string; node_id: string }): Observable<Message.Short[]> {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}/messages`;
    return this.http.get<Message.Short[]>(url).pipe(map((messages) => _.map(messages, Message.Short.transform)));
  }

  /**
   * Load all dataroom messages
   */
  getNodesMessages({ asset_id }: { asset_id: string }): Observable<Message.Short[]> {
    const url = `${this.apiUrl}/${asset_id}/nodes/nodes/messages`;
    return this.http.get<Message.Short[]>(url).pipe(map((messages) => _.map(messages, Message.Short.transform)));
  }

  emptyTrash({ asset_id }: { asset_id: string }): Observable<void> {
    const url = `${this.apiUrl}/${asset_id}/nodes/empty-trash`;
    return this.http.delete<void>(url);
  }

  getFileExtension(node: DataRoom.Node): string | undefined {
    if (node.type === 'placeholders') {
      return node.type;
    }
    return node.document?.file_extension?.toLowerCase();
  }

  getFileVersionStatusIcon(node: DataRoom.Node, activeUserId: string): AvailableIcons {
    if (!node?.is_locked) {
      return null;
    }

    if (node?.is_locked) {
      return activeUserId === node?.locked_by && !node?.contains_pending_version && this.getCanEditNode(node)
        ? 'cloud-upload'
        : this.getCanApproveNode(node) && node?.contains_pending_version
        ? 'accept-reject'
        : 'padlock-bordered';
    }
  }

  getCanDownloadNode(nodes: DataRoom.Node[]): boolean {
    const canDownload = (node: DataRoom.Node) =>
      node?.type !== 'placeholders' && DataRoom.PermissionSet.canDownload(node?.permission_set);
    return nodes.every(canDownload);
  }

  getCanWriteNode(node: DataRoom.Node | undefined): boolean {
    return DataRoom.PermissionSet.canWrite(node?.permission_set);
  }

  getCanNeedApproveNode(node: DataRoom.Node | undefined): boolean {
    return DataRoom.PermissionSet.needApprove(node?.permission_set);
  }

  getCanApproveNode(node: DataRoom.Node | undefined): boolean {
    return DataRoom.PermissionSet.canApprove(node?.permission_set);
  }

  getCanUnlockNode(node: DataRoom.Node | undefined): boolean {
    const activeUser = this.userService.getActiveUserData();
    return (this.getCanApproveNode(node) || node.locked_by === activeUser?.id) && this.getCanWriteNode(node);
  }

  getCanEditNode(node: DataRoom.Node | undefined): boolean {
    return this.getCanApproveNode(node) || this.getCanWriteNode(node);
  }

  getCanViewVersions(node: DataRoom.Node | undefined): boolean {
    return DataRoom.PermissionSet.canViewVersions(node?.permission_set);
  }

  prepareCopyOrMoveParams(option, node_ids, next_to_node_id, next_to_position) {
    return {
      permission_option: option,
      node_ids,
      ...(next_to_node_id ? { next_to_node_id } : {}),
      ...(next_to_position ? { next_to_position } : {}),
    };
  }

  getNodeVersions(assetId: string, nodeId: string): Observable<DataRoom.Node[]> {
    const url = `${this.apiUrl}/${assetId}/nodes/${nodeId}/versions`;
    return this.http.get<DataRoom.Node[]>(url);
  }

  approveVersion(nodeId: string, versionNode: DataRoom.Node) {
    const url = `${this.apiUrl}/${versionNode.asset_id}/nodes/${nodeId}/versions/${versionNode.id}/approve`;
    return this.http.post<void>(url, {});
  }

  rejectVersion(nodeId: string, versionNode: DataRoom.Node, comment: string) {
    const url = `${this.apiUrl}/${versionNode.asset_id}/nodes/${nodeId}/versions/${versionNode.id}/reject`;
    return this.http.post<void>(url, { comment });
  }

  switchVersion(nodeId: string, versionNode: DataRoom.Node) {
    const url = `${this.apiUrl}/${versionNode.asset_id}/nodes/${nodeId}/versions/${versionNode.id}/switch`;
    return this.http.post<void>(url, {});
  }

  getVersioningActiveNode(assetId: string, versioningId: string): Observable<DataRoom.Node> {
    const url = `${this.apiUrl}/${assetId}/nodes/versionings/${versioningId}/active_node`;
    return this.http.get<DataRoom.Node>(url);
  }

  deleteVersion(nodeId, versionNode: DataRoom.Node) {
    const url = `${this.apiUrl}/${versionNode.asset_id}/nodes/${nodeId}/versions/${versionNode.id}`;
    return this.http.delete<void>(url);
  }

  getVersionNameLabel(versionNode: DataRoom.Node) {
    return `${this.transloco.translate('versioning.version')} ${
      versionNode.version
    } ${this.formatDateService.toLocaleString(
      new Date(versionNode.version_created_at || versionNode.created_at),
      true
    )}`;
  }

  isNotActiveVersionNode(node: DataRoom.Node) {
    return !node?.parent_id && !node?.is_root_node && node?.type === 'documents';
  }

  lockNode(assetId: string, nodeId: string): Observable<Object> {
    return this.http.post(`${this.apiUrl}/${assetId}/nodes/${nodeId}/lock`, null);
  }

  unlockNode(assetId: string, nodeId: string): Observable<Object> {
    return this.http.post(`${this.apiUrl}/${assetId}/nodes/${nodeId}/unlock`, null);
  }

  isNodeOriginFileDownloadable(node: DataRoom.Node): Observable<boolean> {
    return this.settings$.pipe(
      filter(isNotNil),
      take(1),
      map((settings) => {
        if (node?.document?.file_extension === 'pdf') {
          return settings.group.save_pdf_without_watermark;
        } else {
          return (
            node?.type === 'documents' &&
            !settings.group.save_pdf_instead_these_file_formats.includes(node?.document?.file_extension)
          );
        }
      })
    );
  }

  private fetchNodeSetData({ asset_id, node_id, node_set, group }: NodeSetParams): Observable<DataRoom.Node[]> {
    const url = `${this.apiUrl}/${asset_id}/nodes/${node_id}/${node_set}`;
    const params = <HttpParams>{};
    if (group) {
      params['permission_group_1'] = group;
    }

    return asset_id && node_id && node_set
      ? this.http.get<DataRoom.Node[]>(url, { params }).pipe(map((nodes) => _.map(nodes, DataRoom.Node.transform)))
      : of([]).pipe(
          tap(() => console.warn(`Could not request ${node_set || 'data'}, because of missing query parameters`))
        );
  }

  private setIsNodesTreeSortedAlphabeticallyLocalStorageValueOnChanges() {
    this.isNodesTreeSortedWithFoldersFirst$.subscribe(async (isNodesTreeSortedAlphabetically) => {
      const isNodesTreeSortedAlphabeticallyLocalStorageValue = await this.getAreNodesTreeSorted().toPromise();
      if (isNodesTreeSortedAlphabetically !== isNodesTreeSortedAlphabeticallyLocalStorageValue) {
        localStorage.setItem(
          IS_NODES_TREE_SORTED_ALPHABETICALLY_LOCAL_STORAGE_KEY,
          JSON.stringify(isNodesTreeSortedAlphabetically)
        );
      }
    });
  }

  private getAreNodesTreeSorted(): Observable<boolean> {
    return this.indexPointsEnabled$.pipe(
      take(1),
      map((indexPointsEnabled) => {
        if (indexPointsEnabled) {
          return false;
        }

        const isNodesTreeSortedAlphabeticallyLocalStorageValue = localStorage.getItem(
          IS_NODES_TREE_SORTED_ALPHABETICALLY_LOCAL_STORAGE_KEY
        );
        if (isNodesTreeSortedAlphabeticallyLocalStorageValue) {
          return JSON.parse(isNodesTreeSortedAlphabeticallyLocalStorageValue);
        } else {
          return SORTED_ALPHABETICALLY_DEFAULT_VALUE;
        }
      })
    );
  }
}
