import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { User } from '@core/models';

@Component({
  selector: 'x-user-label',
  templateUrl: './user-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserLabelComponent {
  @Input() fieldName: keyof User | keyof User.Admin;

  getKey(key: keyof User | keyof User.Admin): string {
    return key;
  }
}
