import { ICellRendererAngularComp } from '@ag-grid-community/angular';
import { ICellRendererParams } from '@ag-grid-community/core';
import { CommonModule, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
  selector: 'x-qa-current-no-renderer',
  standalone: true,
  imports: [CommonModule, SvgIconModule, TranslocoModule, NgIf],
  templateUrl: './qa-current-no-renderer.component.html',
  styleUrls: ['./qa-current-no-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QaCurrentNoRendererComponent implements ICellRendererAngularComp {
  params?: ICellRendererParams;
  data?: {
    current_no?: number;
    rejected_reason?: string;
  } = {};

  refresh(params: ICellRendererParams) {
    this.params = params;
    this.data = params?.data;
    return true;
  }

  agInit(params: ICellRendererParams): void {
    this.params = params;
    this.data = params?.data || params?.value;
  }
}
