<x-dialog
  [submitBtnText]="'reject' | transloco"
  (handleSubmit)="onSubmit()"
  (handleClose)="onCancel()"
  [title]="data.title"
  [submitDisabled]="!reason"
  submitColor="danger"
>
  <section>
    <x-textarea
      label="{{ 'rejection-reason' | transloco }}"
      [required]="true"
      [(value)]="reason"
      name="reason"
    ></x-textarea>
  </section>
</x-dialog>
