<div class="tailwind mt-5 space-x-3 lg:flex lg:mt-0 lg:ml-4 items-center">
  <x-toggle
    class="block h-[42px]"
    [options]="viewTypes"
    (optionClick)="onViewTypeChange($event)"
    [selectedId]="selectedViewType"
  ></x-toggle>
  <x-input
    [autofocus]="true"
    [value]="searchTerm"
    [showClearButton]="true"
    [disabled]="isDisabled"
    nativeInputClasses="h-[42px] min-w-[320px]"
    placeholder="{{ 'search' | transloco }}"
    autocomplete="off"
    leftSideIcon="magnifying-glass"
    (handleInput)="onSearchInputChange($event)"
  ></x-input>

  <x-filters-dropdown
    [activeFiltersCount]="getActiveFiltersCount()"
    (resetFilters)="filters.reset()"
    [isDisabled]="isDisabled"
  >
    <x-filter-group [title]="'asset-created-at' | transloco" [expanded]="true">
      <x-select
        [options]="dateHelperService.timeRangeOptions$ | async"
        [withEmptyOption]="true"
        [control]="filters.controls.createdAtFormControl"
      ></x-select>
      <ng-container *ngIf="filters.value.createdAtFormControl === 'custom'">
        <ng-container *ngTemplateOutlet="dateRangeTemplate"></ng-container>
      </ng-container>
    </x-filter-group>
    <hr />
    <x-filter-group [title]="'type' | transloco">
      <x-select [options]="typeItems" [withEmptyOption]="true" [control]="filters.controls.typeFormControl"></x-select>
    </x-filter-group>
    <hr />
    <x-filter-group [title]="'status' | transloco">
      <x-select [options]="statuses" [withEmptyOption]="true" [control]="filters.controls.statusFormControl"></x-select>
    </x-filter-group>
  </x-filters-dropdown>
</div>

<ng-template #dateRangeTemplate>
  <div class="date-range">
    <mat-date-range-input [rangePicker]="picker">
      <input
        matInput
        matStartDate
        placeholder="{{ 'start-date' | transloco }}"
        [(ngModel)]="from_date"
        (dateChange)="onStartDateChange($event.value)"
        (click)="picker.open()"
      />
      <input
        matInput
        matEndDate
        placeholder="{{ 'end-date' | transloco }}"
        [(ngModel)]="to_date"
        (dateChange)="onEndDateChange($event.value)"
        (click)="picker.open()"
      />
    </mat-date-range-input>
    <mat-date-range-picker #picker></mat-date-range-picker>
  </div>
</ng-template>
