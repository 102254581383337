import { ChangeDetectionStrategy, Component, EventEmitter, inject, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseGridComponent } from '@portal/components';
import { Message, QA } from '@core/models';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { catchError, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ToastService, ToastType } from '@core/toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidePanelDialogService } from '@portal/customer/services/side-panel-dialog.service';
import { MessageRejectDialogComponent } from '@portal/customer/components/asset-details/asset-tickets-container/components/ticket-reject-dialog/message-reject-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [CommonModule],
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export abstract class CollaborationComponent extends BaseGridComponent<Message> {
  @Output() fetchMessages = new EventEmitter<void>();

  protected readonly toast: ToastService = inject(ToastService);
  protected readonly sidePanel = inject(SidePanelDialogService);
  protected readonly dialog = inject(MatDialog);

  readonly isLoading$ = new BehaviorSubject(false);
  readonly actionTriggered$ = new Subject();

  protected updateMessageIfConcealed(message: Message) {
    const row = this.gridApi.getRowNode(message.id);
    const isMessageConcealed = !row.data?.message;
    if (isMessageConcealed) {
      row.updateData(message);
      this.gridApi.redrawRows(); // option to update specific row result with removing of some items from DOM - TBI
    }
  }

  protected openDetails(getDetailsFn: () => Observable<Message>, callback: (message: Message) => Observable<unknown>) {
    this.actionTriggered$.next();
    this.isLoading$.next(true);
    getDetailsFn()
      .pipe(
        take(1),
        tap(() => {
          this.isLoading$.next(false);
        }),
        takeUntil(this.actionTriggered$),
        switchMap((message) => {
          this.updateMessageIfConcealed(message);
          return callback(message);
        }),
        catchError(() => {
          this.isLoading$.next(false);
          this.toast.showToast(this.transloco.translate('unknown-error-please-try-again'), ToastType.ERROR);
          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe();
  }

  protected showError(): void {
    this.toast.showToast(this.transloco.translate('unknown-error-please-try-again'), ToastType.ERROR);
  }

  protected rejectMessage(
    title: string,
    message: Message,
    approveFn: (approval: QA.Approval, type: 'answer' | 'message', message: Message) => Observable<unknown>
  ): Observable<unknown> | undefined {
    if (!message?.with_to_do) {
      return;
    }

    const workflowStep = message.qa_workflow_step?.action;
    const type = workflowStep === 'approve_answer' ? 'answer' : 'message';

    return this.dialog
      .open(MessageRejectDialogComponent, { data: { type, title } })
      .afterClosed()
      .pipe(
        take(1),
        switchMap((notes) => {
          if (notes) {
            return approveFn?.(
              {
                is_approved: false,
                notes,
              },
              type,
              message
            );
          } else {
            this.isLoading$.next(false);
            return EMPTY;
          }
        }),
        tap(() => {
          this.fetchMessages.emit();
        }),
        catchError(() => {
          this.showError();
          this.isLoading$.next(false);
          return EMPTY;
        }),
        untilDestroyed(this)
      );
  }

  protected approveMessage(
    message: Message,
    approveFn: (approval: QA.Approval, type: 'answer' | 'message', message: Message) => Observable<unknown>
  ): Observable<unknown> {
    if (!message?.with_to_do) {
      return;
    }

    const workflowStep = message.qa_workflow_step?.action;

    const request$ =
      approveFn?.(
        {
          is_approved: true,
        },
        workflowStep === 'approve_answer' ? 'answer' : 'message',
        message
      ) || EMPTY;

    return request$.pipe(
      take(1),
      tap((wasSubmitted) => {
        wasSubmitted && this.fetchMessages.emit();
      }),
      catchError(() => {
        this.showError();
        return EMPTY;
      }),
      untilDestroyed(this)
    );
  }

  protected onRejectCellClicked(
    message: Message,
    rejectFn: (message: Message) => Observable<unknown>,
    forwardFn: (message: Message) => unknown
  ) {
    if (!message?.with_to_do) {
      return;
    }

    if (this.getCanApprove(message)) {
      this.isLoading$.next(true);
      return rejectFn(message)
        ?.pipe(
          catchError(() => {
            this.showError();
            this.isLoading$.next(false);
            return EMPTY;
          })
        )
        .subscribe(() => {
          this.isLoading$.next(false);
        });
    }

    if (this.getCanForward(message)) {
      forwardFn(message);
    }
  }

  protected getCanApprove(message: Message): boolean {
    return Message.getCanApprove(message);
  }

  protected getCanAnswer(message: Message): boolean {
    return Message.getCanAnswer(message);
  }

  protected getCanForward(message: Message): boolean {
    return Message.getCanForward(message);
  }
}
