import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitsCodeComponent } from '@portal/components/digits-code/digits-code.component';
import { AuthService, TokenResponse, TWO_FACTOR_AUTH_CODE_LENGTH } from '../../core/services/auth.service';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { TranslocoModule } from '@ngneat/transloco';
import { DIALOG_DATA } from '@angular/cdk/dialog';
import { of } from 'rxjs';
import { MatDialogRef } from '@angular/material/dialog';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { InputComponent } from '@common/components/input/input.component';
import { FormControl, Validators } from '@angular/forms';
import { catchError, finalize } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'x-enter-two-factor-auth-code-dialog',
  standalone: true,
  imports: [CommonModule, DigitsCodeComponent, DialogComponent, TranslocoModule, SvgIconModule, InputComponent],
  templateUrl: './enter-two-factor-auth-code-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnterTwoFactorAuthCodeDialogComponent {
  readonly TWO_FACTOR_AUTH_CODE_LENGTH = TWO_FACTOR_AUTH_CODE_LENGTH;
  readonly cdr = inject(ChangeDetectorRef);
  readonly dialogRef = inject(MatDialogRef<EnterTwoFactorAuthCodeDialogComponent>);
  readonly dialogData = inject<TokenResponse>(DIALOG_DATA);
  readonly authService = inject(AuthService);

  enteredDigits = '';
  hasError = false;
  isLoading = false;
  useRecoveryCodeForm = false;
  recoveryCodeControl = new FormControl('', Validators.minLength(10));

  constructor() {
    this.recoveryCodeControl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.hasError = false;
      this.cdr.markForCheck();
    });
  }

  @HostListener('window:keydown.enter', ['$event'])
  onEnterPress(event: KeyboardEvent): void {
    event.preventDefault();
    this.handleSubmit();
  }

  onCodeChange(code: string): void {
    this.enteredDigits = code;
    this.hasError = false;
    this.cdr.markForCheck();
  }

  handleSubmit(): void {
    if (this.isLoading) return;

    this.isLoading = true;
    this.hasError = false;
    this.cdr.markForCheck();

    const action$ = this.useRecoveryCodeForm
      ? this.authService.signInWith2FARecoveryCode(this.recoveryCodeControl.value, this.dialogData)
      : this.authService.signInWith2FA(this.enteredDigits, this.dialogData);

    action$
      .pipe(
        catchError(() => {
          this.hasError = true;
          return of(null);
        }),
        finalize(() => {
          this.isLoading = false;
          this.cdr.markForCheck();
        })
      )
      .subscribe((result) => {
        if (result) {
          this.dialogRef.close(result);
        }
      });
  }

  onLostAccessClick(): void {
    this.useRecoveryCodeForm = true;
    this.resetForm();
  }

  handleClose(): void {
    if (this.useRecoveryCodeForm) {
      this.useRecoveryCodeForm = false;
      this.resetForm();
    } else {
      this.dialogRef.close(false);
    }
  }

  private resetForm(): void {
    this.enteredDigits = '';
    this.hasError = false;
    this.cdr.markForCheck();
  }
}
