import { HttpErrorResponse } from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  SIDE_PANEL_DIALOG_DATA,
  SidePanelDialogData,
} from '@common/components/side-panel-dialog/side-panel-dialog.component';
import { SidePanelDialogService } from '@portal/customer/services/side-panel-dialog.service';
import { api, Document, Message } from '@core/models';
import { DeleteAssetLinkReqBody } from '@core/services';
import { UsersSelectors } from '@core/state';
import { ToastService, ToastType } from '@core/toast';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { DocumentUploadEvent } from '@portal/components/documents-preview/documents-preview.component';
import { WriteResponseModelOutput } from '@portal/customer/components/asset-details/asset-tickets-container/components/write-response-form/write-response-form.component';
import { ProcessedReference, Reference } from '@portal/customer/components/attach-reference-button/reference.model';
import { DocumentLocation, MessageCategory } from '@portal/customer/services';
import { forkJoin, Observable, of } from 'rxjs';
import { concatMap, filter, finalize, switchMap, take } from 'rxjs/operators';
import { CollaborationService } from '@portal/customer/components/asset-details/asset-collaboration/collaboration/collaboration.service';

export interface MessageDetailsDialogComponentData {
  fieldsTemplate: TemplateRef<HTMLElement>;
  message: Message;
  category: MessageCategory;
  getDetails: (id: string) => Observable<Message>;
  removeFiles: (files: DeleteAssetLinkReqBody[]) => Observable<unknown>;
  saveDraft: (data: { response: string; references: Reference[] }) => Observable<unknown>;
  submit: (data: { response: string; references: Reference[] }) => Observable<unknown>;
  submitFiles: (files: File[]) => Observable<unknown>;
  writeResponseInitiallyEnabled?: boolean;
  approveMessage?: (message: Message) => Observable<unknown> | undefined;
  canWriteResponse?: boolean;
  extendDueDate?: (message: Message) => Observable<unknown> | undefined;
  forwardMessage?: (message: Message) => Observable<unknown> | undefined;
  previewOnly?: boolean;
  rejectMessage?: (message: Message) => Observable<unknown> | undefined;
  setGracePeriod?: (message: Message) => Observable<unknown> | undefined;
}

@UntilDestroy()
@Component({
  selector: 'x-message-details-dialog',
  templateUrl: './message-details-dialog.component.html',
  styleUrls: ['./message-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageDetailsDialogComponent implements AfterViewInit {
  @ViewChild('footerTemplate') footerTemplate: TemplateRef<never>;
  @ViewChild('requiredFieldsInfoTemplate') requiredFieldsInfoTemplate: TemplateRef<never>;
  @ViewChild('ticketFieldsTemplate', { static: false })
  ticketFieldsTemplate?: TemplateRef<HTMLElement>;

  private readonly store = inject(Store);
  private readonly dialog = inject(SidePanelDialogService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly toast = inject(ToastService);
  private readonly collaborationService = inject(CollaborationService);

  readonly dialogData: SidePanelDialogData<MessageDetailsDialogComponentData> = inject(SIDE_PANEL_DIALOG_DATA);
  readonly user$ = this.store.select(UsersSelectors.getUser);
  readonly messageUtils = Message;

  isSaving = false;
  isWritingResponse = this.dialogData?.dataSnapshot?.data?.writeResponseInitiallyEnabled || false;
  writeResponseModelOutput: WriteResponseModelOutput;
  previewOnly = this.dialogData.dataSnapshot.data.previewOnly || false;

  ngAfterViewInit() {
    this.dialog.updateComponentData(this.dialogData.dialogRef, {
      ...this.dialogData.dataSnapshot,
      primaryActionsTemplate: this.footerTemplate,
    });
  }

  hasChangesToSave() {
    return this.writeResponseModelOutput?.response?.length > 0;
  }

  close() {
    this.dialog.closeAll();
  }

  cancelChanges() {
    this.isWritingResponse = false;
    this.writeResponseModelOutput = undefined;
    this.cdr.detectChanges();
  }

  onWriteResponseClick() {
    this.isWritingResponse = true;
    this.cdr.detectChanges();
  }

  onChanges(model: WriteResponseModelOutput) {
    this.writeResponseModelOutput = model;
    this.cdr.detectChanges();
  }

  onDocumentUploadClick({ document, allDocuments }: DocumentUploadEvent, message: Message) {
    this.collaborationService
      .openAddReferencesToSystemDialog({
        preselectedDocumentId: document.id,
        showSkipAndContinue: false,
        documents: allDocuments,
        action$: of(null),
        messageCategory: this.dialogData.dataSnapshot.data.category,
      })
      .afterClosed()
      .pipe(
        filter((result) => !!result),
        untilDestroyed(this)
      )
      .subscribe((result: DocumentLocation[]) => {
        if (result) {
          this.executeMessageAction(message, of(null));
        }
      });
  }

  onSaveResponse(response: string): void {
    this.isSaving = true;
    this.dialogData.dialogRef.disableClose = true;
    this.cdr.markForCheck();

    forkJoin([this.saveFiles(), this.removeFiles()])
      .pipe(
        concatMap(() =>
          response
            ? this.dialogData.dataSnapshot.data.submit({
                response,
                references: this.writeResponseModelOutput?.references || [],
              })
            : of(false)
        ),
        take(1)
      )
      .subscribe({
        next: (message?: Message) => {
          this.isSaving = false;
          this.isWritingResponse = false;
          message && this.updateMessage(message, false);
          this.writeResponseModelOutput = undefined;
          this.cdr.markForCheck();
        },
        error: (error: HttpErrorResponse) => this.showError(error),
      });
  }

  onSaveResponseAsDraft() {
    this.isSaving = true;
    this.dialogData.dialogRef.disableClose = false;
    this.cdr.markForCheck();

    forkJoin([this.saveFiles(), this.removeFiles()])
      .pipe(
        concatMap(() =>
          this.writeResponseModelOutput?.response
            ? this.dialogData.dataSnapshot.data.saveDraft({
                response: this.writeResponseModelOutput.response,
                references: this.writeResponseModelOutput.references,
              })
            : of(false)
        ),
        take(1)
      )
      .subscribe({
        next: (message?: Message) => {
          this.isSaving = false;
          this.isWritingResponse = false;
          message && this.updateMessage(message, true);
          this.writeResponseModelOutput = undefined;
          this.cdr.markForCheck();
        },
        error: (error: HttpErrorResponse) => this.showError(error),
      });
  }

  acceptMessage(message: Message) {
    this.executeMessageAction(
      message,
      this.dialogData.dataSnapshot?.data?.approveMessage?.(message).pipe(filter((wasSubmitted) => !!wasSubmitted))
    );
  }

  rejectMessage(message: Message) {
    this.executeMessageAction(message, this.dialogData.dataSnapshot?.data?.rejectMessage?.(message));
  }

  forwardMessage(message: Message) {
    this.executeMessageAction(message, this.dialogData.dataSnapshot?.data?.forwardMessage?.(message));
  }

  onExtendDueDate(message: Message) {
    this.executeMessageAction(message, this.dialogData.dataSnapshot?.data?.extendDueDate?.(message));
  }

  onSetGracePeriod(message: Message) {
    this.executeMessageAction(message, this.dialogData.dataSnapshot?.data?.setGracePeriod?.(message));
  }

  isExtendDueDateVisible(message: Message, userId: string) {
    return Message.getCanExtendDueDate(message, userId) && this.dialogData.dataSnapshot.data.category === 'tickets';
  }

  isSetGracePeriodVisible(message: Message, userId: string) {
    return Message.getCanSetGracePeriod(message, userId) && this.dialogData.dataSnapshot.data.category === 'tickets';
  }

  getDocumentList(data: Message): Document.Short[] | Document[] {
    return [...(data?.documents ?? []), ...(data?.photos ?? [])];
  }

  getResponseReferences(message: Message): ProcessedReference[] | undefined {
    return Message.extractResponseReferences(message);
  }

  private executeMessageAction(message: Message, actionObs$: Observable<unknown>) {
    this.isSaving = true;
    actionObs$
      ?.pipe(
        take(1),
        switchMap(() => this.dialogData.dataSnapshot.data.getDetails(message.id)),
        finalize(() => {
          this.isSaving = false;
        }),
        untilDestroyed(this)
      )
      .subscribe((message: Message) => {
        this.updateMessage(message);
      });
  }

  private removeFiles() {
    const { filesToRemove } = this.writeResponseModelOutput || {};
    if (!filesToRemove?.length) {
      return of(true) as Observable<never>;
    }
    return this.dialogData.dataSnapshot.data.removeFiles(filesToRemove).pipe(take(1));
  }

  private saveFiles() {
    const { files } = this.writeResponseModelOutput || {};
    if (!files?.length) {
      return of(true) as Observable<never>;
    }
    return this.dialogData.dataSnapshot.data.submitFiles(files).pipe(take(1));
  }

  private showError(error: api.ErrorResponse<unknown>) {
    this.isSaving = false;
    this.dialogData.dialogRef.disableClose = false;
    const text = error.error?.errors?.map((e) => e.details).join('\n') || error.message;
    this.toast.showToast(text, ToastType.ERROR);
  }

  private updateMessage(message: Message, canWriteResponse?: boolean) {
    this.dialogData.data.pipe(take(1), untilDestroyed(this)).subscribe((data) => {
      this.dialog.updateComponentData(this.dialogData.dialogRef, {
        ...data,
        showFooter: Message.getCanApprove(message),
        data: {
          ...data.data,
          canWriteResponse: canWriteResponse ?? this.messageUtils.getCanAnswer(message),
          message,
        } as MessageDetailsDialogComponentData,
      });
      this.cdr.detectChanges();
    });
  }
}
