import { Project, ProjectListItem } from '@core/models';
import { createAction, props } from '@ngrx/store';

export namespace ProjectActions {
  export const fetch = createAction('[Projects] Fetch');
  export const fetchSuccess = createAction('[Projects] Fetch Success', props<{ projects: ProjectListItem[] }>());
  export const fetchFail = createAction('[Projects] Fetch Fail');

  export const fetchDetailsSuccess = createAction('[Projects] Fetch Details Success', props<{ project: Project }>());

  export const setProjectLogoUri = createAction('[Projects] Set Logo URI', props<{ id: string; logoUri: string }>());
}
