import { inject, Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, filter, first, map, mergeMap, withLatestFrom } from 'rxjs/operators';

import {
  AlertDialogComponent,
  AlertDialogData,
  AlertType,
} from '../../../common/components/alert-dialog/alert-dialog.component';
import { LoginDialogComponent } from '../../../common/components/login-dialog/login-dialog.component';
import { AuthService } from '../../services/auth.service';
import { UserActions } from './users.actions';
import { UsersSelectors } from './users.selectors';

@Injectable()
export class UsersEffects {
  readonly authService = inject(AuthService);
  readonly fetchUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.fetchUser),
      mergeMap(() => of(null)),
      catchError(() => of(UserActions.fetchUserFail))
    )
  );

  readonly signOut$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.signOut),
        map(() => {
          this.dialog.closeAll();
          this.authService.signOut();
        })
      ),
    { dispatch: false }
  );

  readonly showLoginDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.showLoginDialog),
        withLatestFrom(this.store.select(UsersSelectors.getIsLoggedIn)),
        filter(([, isLoggedIn]) => !isLoggedIn),
        map(([{ nextAction }]) => {
          this.scrollToTop();
          const rect = this.getUserButtonRect();

          const dialogRef = this.dialog.open(LoginDialogComponent, {
            data: {
              top: rect ? rect.top + rect.height : 0,
              right: rect && rect.right,
            },
          });

          if (nextAction) {
            dialogRef
              .afterClosed()
              .pipe(first())
              .subscribe((success) => {
                if (success) {
                  this.store.dispatch(nextAction());
                }
              });
          }
        })
      ),
    { dispatch: false }
  );

  readonly showEmailConfirmationDialog$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(UserActions.showEmailConfirmationResult),
        map(({ confirmed }) => {
          this.dialog.open(AlertDialogComponent, {
            data: <AlertDialogData>{
              type: confirmed ? AlertType.Success : AlertType.Error,
              text: confirmed
                ? this.transloco.translate(`Email has been successfully confirmed`)
                : this.transloco.translate(`Email confirmation failed`),
            },
          });
        })
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<unknown>,
    private dialog: MatDialog,
    private readonly transloco: TranslocoService
  ) {}

  private getUserButtonRect(): ClientRect {
    const button = document.querySelector('button.user');
    return button && button.getBoundingClientRect();
  }

  private scrollToTop(): void {
    window.scrollTo(0, 0);
  }
}
