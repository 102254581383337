<ng-template #messagesContainer>
  <div class="flex flex-col h-full">
    <div class="controls py-3.5 mx-6 border-b border-gray-200" *ngIf="showToolbar">
      <ul class="flex flex-wrap text-sm font-medium text-center text-gray-500">
        <li class="mr-2" (click)="showAllMessages = false">
          <ng-container
            [ngTemplateOutlet]="tabPill"
            [ngTemplateOutletContext]="{
              name: (currentTabTitle ? currentTabTitle : 'current') | transloco,
              isActive: !showAllMessages
            }"
          ></ng-container>
        </li>
        <span class="mr-2" *ngIf="hasAllTab" (click)="showAllMessages = true">
          <ng-container
            [ngTemplateOutlet]="tabPill"
            [ngTemplateOutletContext]="{
              name: 'all' | transloco,
              nameTemplate: allMessagesLabelTemplate,
              isActive: showAllMessages
            }"
          ></ng-container>
        </span>
      </ul>
    </div>
    <div class="overflow-auto flex flex-column flex-1 mt-2">
      <ng-container *ngIf="!showAllMessages">
        <ng-container *ngIf="currentMessages">
          <ng-container *ngIf="currentMessages?.length; else noCurrentMessagesTemplate">
            <div *ngFor="let message of currentMessages">
              <div class="message">
                <x-message
                  [frozen]="isFrozen$ | async"
                  [message]="message"
                  [showReply]="false"
                  [showTimestampAndPage]="true"
                  [icon]="(isQuestion | call : message) ? 'question_answer' : ''"
                  [iconTooltip]="getIconTooltip | call : (isQuestion | call : message)"
                  [messagesLabelTemplate]="messagesLabelTemplate"
                  (click)="onShowMessageDetails(message)"
                  (hasBeenRemoved)="onMessageHasBeenRemoved(message)"
                ></x-message>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="showAllMessages">
        <ng-template #noMessagesTemplate>
          <div *ngIf="currentEmptyText" class="no-messages">
            {{ allEmptyText }}
          </div>
        </ng-template>
        <ng-container *ngIf="allMessages?.length; else noMessagesTemplate">
          <div class="message" *ngFor="let message of allMessages">
            <x-message
              [frozen]="isFrozen$ | async"
              [message]="message"
              [showReply]="false"
              [showTimestampAndPage]="true"
              [icon]="(isQuestion | call : message) ? 'question_answer' : ''"
              [iconTooltip]="getIconTooltip | call : (isQuestion | call : message)"
              [messagesLabelTemplate]="messagesLabelTemplate"
              (click)="onShowMessageDetails(message)"
              (hasBeenRemoved)="onMessageHasBeenRemoved(message)"
            ></x-message>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #tabPill let-isActive="isActive" let-name="name" let-nameTemplate="nameTemplate">
  <x-tab-pill [isActive]="isActive">
    <ng-container *ngIf="nameTemplate; else nameStringTemplate">
      <ng-container *ngTemplateOutlet="nameTemplate"></ng-container>
    </ng-container>
    <ng-template #nameStringTemplate>
      {{ name }}
    </ng-template>
  </x-tab-pill>
</ng-template>

<ng-template #noCurrentMessagesTemplate>
  <div *ngIf="currentEmptyText" class="no-messages">
    {{ currentEmptyText }}
  </div>
</ng-template>

<ng-container *ngIf="withFrame; else messagesContainer">
  <div class="flex flex-col bg-white h-full rounded-xl overflow-hidden">
    <div
      class="flex flex-row bg-gray-50 border-b border-gray-300 items-center px-4 space-between min-h-[60px]"
      [ngClass]="headerClasses"
    >
      <label class="text-sm text-gray-900 font-semibold">
        {{ 'messages' | transloco }}
      </label>
      <x-svg-icon
        (click)="handleOnClose.emit()"
        svgImageName="cross"
        class="text-gray-600 rounded-xl hover:bg-gray-300 cursor-pointer"
        svgClass="w-6 h-6"
      ></x-svg-icon>
    </div>
    <ng-container [ngTemplateOutlet]="messagesContainer"></ng-container>
  </div>
</ng-container>
