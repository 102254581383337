import * as _ from 'lodash';
import { getAllEnumValues } from './enum';

export enum AreaStandard {
  DIN277 = 'din277',
  GIF = 'gif',
  IPMS = 'ipms',
  Boma = 'boma',
  Brutto = 'brutto',
  AEO = 'aeo',
  NEN = 'nen',
  Unknown = 'unknown',
  Custom = 'custom',
}

export namespace AreaStandard {
  export const all = getAllEnumValues<AreaStandard>(AreaStandard);

  export const calculation = _.filter(AreaStandard.all, (std) => std !== AreaStandard.Unknown);

  export const asset = _.filter(AreaStandard.all, (std) => std !== AreaStandard.Custom);
}
