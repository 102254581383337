import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NewLinePipeModule, SafePipeModule } from '@common';
import { ButtonComponent } from '@common/components/button/button.component';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { TranslocoModule } from '@ngneat/transloco';
import { MatDialogModule } from '@angular/material/dialog';
import { RichEditorModule } from '../rich-editor';
import { TermsConfirmationDialogComponent } from './terms-confirmation-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule,
    TranslocoModule,
    NewLinePipeModule,
    SafePipeModule,
    RichEditorModule,
    ButtonComponent,
    DialogComponent,
  ],
  declarations: [TermsConfirmationDialogComponent],
  exports: [TermsConfirmationDialogComponent],
})
export class TermsConfirmationDialogModule {}
