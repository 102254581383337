<x-dropdown origin="TOP_RIGHT" width="lg">
  <x-button xDropdownTrigger color="white" size="lg" [disabled]="isDisabled" [isTriggerDisabled]="isDisabled">
    <x-svg-icon svgImageName="filter"></x-svg-icon>
    <span class="ml-1">{{ customTitle || ('filters' | transloco) }}</span>
    <span
      class="inline-flex items-center rounded-full bg-blue-100 px-2.5 py-0.5 text-xs font-medium text-blue-800 ml-2"
    >
      {{ activeFiltersCount || 0 }}
    </span>
    <x-svg-icon
      *ngIf="activeFiltersCount && (hasChanges !== undefined ? hasChanges : true)"
      svgImageName="cross"
      class="ml-1 hover:bg-gray-200 rounded-xl"
      svgClass="w-6 h-6 text-gray-400"
      (click)="resetFiltersClick($event)"
    ></x-svg-icon>
    <x-svg-icon class="pl-1.5" svgImageName="chevron-arrow-down"></x-svg-icon>
  </x-button>

  <div class="max-h-[560px] overflow-y-auto relative">
    <div class="px-4 py-2 space-y-2 divide-y divide-gray-200">
      <ng-content></ng-content>
    </div>
    <div class="py-3 px-4 sticky bottom-0 left-0 right-0 bg-gray-50 border-t border-gray-200">
      <x-button size="full" color="white" [block]="true" (handleClick)="resetFiltersClick()">
        {{ 'reset-filters-label' | transloco }}
      </x-button>
    </div>
  </div>
</x-dropdown>
