import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { PHOTO_ACTIONS, PhotoActionType } from '@portal/components/image-viewer/image-viever.model';
import { SimpleChanges } from 'src/custom-typings/angular';
import Viewer from 'viewerjs';
import Options = Viewer.Options;
import { SHORT_DEBOUNCE_TIME } from '@app/utils/rxjs.utils';

const ZOOM_RATIO = 0.625;

@Component({
  selector: 'x-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
})
export class ImageViewerComponent implements AfterViewInit, OnDestroy, OnChanges {
  @Input() src?: string | SafeUrl;
  @Input() openFullscreenOnClick = false;
  @Input() zoomAtInit = false;
  @ViewChild('viewer') viewerRef: ElementRef<HTMLElement> | undefined;
  @Output() exitFullscreen: EventEmitter<null> = new EventEmitter();

  viewer: Viewer | undefined;
  viewerOptions: Options = {
    inline: true,
    title: false,
    navbar: true,
    button: false,
    transition: false,
    loop: false,
    viewed: () => {
      if (this.zoomAtInit && window.innerWidth >= 1600) {
        this.viewer.zoom(ZOOM_RATIO);
      }
    },
  };
  private resizeTimeout;
  photoActions = PHOTO_ACTIONS;

  ngAfterViewInit() {
    this.initViewer();
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize() {
    if (this.viewer && window.innerWidth >= 1600 && this.zoomAtInit) {
      clearTimeout(this.resizeTimeout);

      this.resizeTimeout = setTimeout(() => {
        if (this.viewer) {
          this.viewer.zoom(ZOOM_RATIO);
        }
      }, SHORT_DEBOUNCE_TIME);
    }
  }

  @HostListener('document:transitionend', ['$event'])
  onTransitionEnd(event: TransitionEvent) {
    if (event.target && event.propertyName === 'padding-left' && (event.target as Element).id === 'doree-content') {
      this.viewer.destroy();
      this.initViewer();
    }
  }

  ngOnDestroy() {
    this.viewer?.destroy();
  }

  ngOnChanges({ src }: SimpleChanges<ImageViewerComponent>): void {
    if (src) {
      setTimeout(() => {
        this.viewer?.update();
        if (this.openFullscreenOnClick) {
          const exitNative = this.viewer.exit;
          this.viewer.exit = () => {
            const result = exitNative.call(this.viewer);
            this.exitFullscreen.emit(null);
            Viewer.setDefaults({ ...this.viewerOptions });
            return result;
          };
          this.triggerAction('fullscreen');
        }
      }, 0);
    }
  }

  initViewer() {
    const container = this.viewerRef?.nativeElement;
    if (!container) {
      return;
    }
    this.viewer = new Viewer(container, { ...this.viewerOptions });
  }

  triggerAction(name: PhotoActionType): void {
    switch (name) {
      case 'zoomIn':
        this.viewer.zoom(1);
        break;
      case 'zoomOut':
        this.viewer.zoom(-1);
        break;
      case 'rotateLeft':
        this.viewer.rotate(-90);
        break;
      case 'rotateRight':
        this.viewer.rotate(+90);
        break;
      case 'reset':
        this.viewer.reset();
        if (window.innerWidth >= 1600 && this.zoomAtInit) {
          this.viewer.zoom(ZOOM_RATIO);
        }
        break;
      case 'fullscreen':
        const exitNative = this.viewer.exit;
        this.viewer.exit = () => {
          const result = exitNative.call(this.viewer);
          Viewer.setDefaults({ ...this.viewerOptions });
          return result;
        };
        Viewer.setDefaults({
          ...this.viewerOptions,
          backdrop: true,
          movable: false,
          button: true,
        });
        this.viewer.full();
        break;
      default:
        break;
    }
  }
}
