import { Permission } from '@core/models';
import { createSelector } from '@ngrx/store';
import { ResolvedDataSelectors } from './resolver/resolver.selector';

export namespace PermissionsSelectors {
  const getPermissions = ResolvedDataSelectors.getPermission;

  export const canDelete = createSelector(
    getPermissions,
    (permissions: Permission | undefined, key: keyof Permission) => (permissions?.[key]?.indexOf('del') ?? -1) !== -1
  );

  export const canView = createSelector(
    getPermissions,
    (permissions: Permission | undefined, key: keyof Permission) => (permissions?.[key]?.indexOf('view') ?? -1) !== -1
  );

  export const canApprove = createSelector(
    getPermissions,
    (permissions: Permission | undefined, key: keyof Permission) =>
      (permissions?.[key]?.indexOf('approve') ?? -1) !== -1
  );

  export const canWrite = createSelector(
    getPermissions,
    (permissions: Permission | undefined, key: keyof Permission) => (permissions?.[key]?.indexOf('write') ?? -1) !== -1
  );
}
