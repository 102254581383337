import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeValue } from '@angular/platform-browser';
import { EMPTY, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, startWith, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { SSEService, StorageService } from '@core/services';
import { isNotEmpty, isNotNil } from 'src/app/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { Store } from '@ngrx/store';
import { Document, SSE } from '@core/models';
import ConversionFinished = SSE.EventData.ConversionFinished;
import { CustomerDocumentsService } from '@portal/customer/services';
import { getAssetUrl } from '@common/utils/getAssetUrl';
import { isVideoExtensionsRegExp } from '@portal/components/qa-overview/qa-overview.constants';

@UntilDestroy()
@Pipe({
  name: 'documentPhoto',
})
export class DocumentPhotoPipe implements PipeTransform {
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);
  readonly newDownloadToken$ = new Subject();
  id: string;
  readonly moviePlaceholderPath = getAssetUrl('images/movie-placeholder.png');
  readonly fallbackImagePath = getAssetUrl('images/asset-placeholder.png');

  constructor(
    private readonly storageService: StorageService,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private sseService: SSEService,
    private store: Store,
    private customerDocumentsService: CustomerDocumentsService
  ) {
    this.sseService.sseEvent$
      .pipe(
        filter(isNotNil),
        filter((event: ConversionFinished) => event.action === 'convert'),
        withLatestFrom(this.assetId$),
        filter(([event, asset_id]) => (event as ConversionFinished).data.asset_id === asset_id),
        filter(([event]) => (event as ConversionFinished).data.id === this.id),
        switchMap(([, asset_id]) =>
          this.customerDocumentsService.getNewDownloadTokenForAssetDocument({ asset_id, id: this.id }, { version: 360 })
        ),
        untilDestroyed(this)
      )
      .subscribe((downloadToken) => {
        this.storageService.removeItemFromCache(this.id);
        this.newDownloadToken$.next(downloadToken);
      });
  }

  transform(
    download_token: string,
    cssUrl = true,
    document?: Document | Document.Short,
    sanitize = true,
    cacheId?: string
  ): Observable<SafeValue> {
    if (!isNotEmpty(download_token)) {
      return EMPTY;
    }

    if (document) {
      this.id = document.id;
      if (document.types.includes('video') || isVideoExtensionsRegExp.test(document.name)) {
        return of(this.moviePlaceholderPath);
      }
    }

    return this.newDownloadToken$.pipe(
      startWith([download_token]),
      switchMap((download_token: string) =>
        this.storageService.getImageWithDownloadToken(
          { download_token },
          !!document && document?.conversion_status === 'finished',
          cacheId || download_token
        )
      ),
      map((file) => {
        const uri = window.URL.createObjectURL(file);
        return cssUrl ? `url('${uri}')` : uri;
      }),
      map((imageData) =>
        sanitize === false
          ? imageData
          : cssUrl
          ? this.sanitizer.bypassSecurityTrustStyle(imageData)
          : this.sanitizer.bypassSecurityTrustUrl(imageData)
      ),
      tap(() => {
        setTimeout(() => this.cdr.detectChanges());
      }),
      catchError(() => (cssUrl ? `url('${this.fallbackImagePath}')` : of(this.fallbackImagePath)))
    );
  }
}
