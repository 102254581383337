import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { EntityState, FetchStatus } from '@core/models';

export class ViewContainerContext<T> {
  $implicit: FetchStatus<T> | undefined;
}

@Directive({
  selector: '[viewContainer]', // eslint-disable-line
})
export class ViewContainerDirective<T> implements OnInit {
  @Input() set viewContainer(state: FetchStatus<T> | undefined) {
    this.context.$implicit = state;

    this.renderView();
  }

  @Input() set viewContainerFailed(templateRef: TemplateRef<ViewContainerContext<T>>) {
    this.errorTemplateRef = templateRef;

    this.renderView();
  }

  @Input() set viewContainerLoading(templateRef: TemplateRef<ViewContainerContext<T>>) {
    this.loaderTemplateRef = templateRef;

    this.renderView();
  }

  private readonly context: ViewContainerContext<T> = new ViewContainerContext<T>();

  private errorTemplateRef?: TemplateRef<ViewContainerContext<T>>;
  private loaderTemplateRef?: TemplateRef<ViewContainerContext<T>>;

  private get state(): EntityState | undefined {
    return this.context.$implicit?.state;
  }

  constructor(
    private readonly viewContainerValue: ViewContainerRef,
    private readonly mainTemplateRef: TemplateRef<ViewContainerContext<T>>
  ) {}

  ngOnInit(): void {
    this.renderView();
  }

  private renderView(): void {
    this.viewContainerValue.clear();

    if (this.state === 'loading') {
      this.renderTemplateRef(this.loaderTemplateRef, this.context?.$implicit?.state);
    }

    if (this.state === 'error') {
      this.renderTemplateRef(this.errorTemplateRef, this.context?.$implicit?.error);
    }

    if (this.state === 'ready') {
      this.renderTemplateRef(this.mainTemplateRef, this.context?.$implicit?.entity);
    }
  }

  private renderTemplateRef(templateRef: TemplateRef<unknown> | undefined, context: unknown): void {
    if (!templateRef) {
      return;
    }

    this.viewContainerValue.createEmbeddedView(templateRef, {
      $implicit: context,
    });
  }
}
