import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationPlan, Transform } from '@core/models';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PORTAL_API_URL } from './constants';

@Injectable({
  providedIn: 'root',
})
export class NotificationPlanService {
  readonly apiUrl = `${PORTAL_API_URL}/users/notification-plans`;

  constructor(private readonly http: HttpClient) {}

  getNotificationPlans(): Observable<NotificationPlan[]> {
    return this.http
      .get<NotificationPlan[]>(this.apiUrl)
      .pipe(map((plans) => _.map(plans, (np) => this.transform(np))));
  }

  setNotification({
    asset_id,
    plan,
  }: {
    asset_id: string;
    plan: NotificationPlan.PlanType;
  }): Observable<NotificationPlan> {
    const url = `${this.apiUrl}/${asset_id}/${plan}`;
    return this.http.post<NotificationPlan>(url, null).pipe(map((np) => this.transform(np)));
  }

  private transform(np: NotificationPlan): NotificationPlan {
    return {
      ...np,
      created_at: Transform.date(np.created_at),
      updated_at: Transform.date(np.updated_at),
      user: Transform.user(np.user),
    };
  }
}
