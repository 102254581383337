import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { RouterModule } from '@angular/router';
import { DialogComponent } from '@common/components/dialog/dialog.component';
import { TranslocoModule } from '@ngneat/transloco';
import { AlertDialogComponent } from './alert-dialog';
import { DestroyComponent } from './destroy.component';
import { IconComponentModule } from './icon';
import { SpinnerModule } from './spinner';
import { SvgIconModule } from './svg-icon/svg-icon.module';

const components = [DestroyComponent, AlertDialogComponent];

/**
 * Import only required components instead of this module.
 *
 * Do not add new components to this module.
 *
 * @deprecated
 */
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    IconComponentModule,
    SpinnerModule,
    MatButtonModule,
    TranslocoModule,
    SvgIconModule,
    DialogComponent,
  ],
  declarations: components,
  exports: [...components, CommonModule, FormsModule, RouterModule, IconComponentModule, SpinnerModule],
})
export class CommonComponentsModule {}
