<x-spinner *ngIf="isLoadingReferences"></x-spinner>
<ol *ngIf="references$ | async; let references; else: noReferenceTemplate" class="space-y-2.5">
  <ng-container *ngIf="!groupNotUploadedDocuments; else groupedLists">
    <ng-container
      [ngTemplateOutlet]="referencesList"
      [ngTemplateOutletContext]="{ $implicit: references }"
    ></ng-container>
  </ng-container>
  <ng-template #groupedLists>
    <div class="flex flex-col">
      <ng-container
        [ngTemplateOutlet]="referencesList"
        [ngTemplateOutletContext]="{ $implicit: references | callFunction : filterUploadedDocuments }"
      ></ng-container>
      <ng-container *ngIf="references | callFunction : filterNotUploadedDocuments; let notUploadedDocuments">
        <div class="mt-2.5" *ngIf="notUploadedDocuments?.length > 0">
          <p class="w-full text-xs text-gray-500 border-b mb-2.5 pb-1.5" *ngIf="showNotUploadedToSystemLabel">
            {{ 'documents-not-uploaded-to-the-system-label' | transloco }}
          </p>
          <ng-container
            [ngTemplateOutlet]="referencesList"
            [ngTemplateOutletContext]="{ $implicit: notUploadedDocuments }"
          ></ng-container>
        </div>
      </ng-container>
    </div>
  </ng-template>
</ol>

<ng-template #noReferenceTemplate>
  <ng-container *ngIf="!isLoadingReferences">-</ng-container>
</ng-template>

<ng-template #referencesList let-references>
  <div class="flex flex-col space-y-1.5" *ngIf="asReferencesType(references); let references">
    <li
      *ngFor="let reference of references; let i = index; trackBy: trackByReferenceHref"
      class="inline-flex cursor-pointer mr-1.5 w-[320px] break-all line-clamp-2 text-ellipsis"
      [ngClass]="{
        '!bg-white': isDownloadDocumentPossible,
        'cursor-pointer hover:bg-gray-50': getIsViewableOnline(reference) && !isDeleteDocumentPossible,
        'cursor-not-allowed': !getIsViewableOnline(reference) && !isDeleteDocumentPossible
      }"
      (click)="onOpenDocument(reference, $event)"
    >
      <ng-container *ngIf="getIsMediaDocumentReference(reference.doc); else referenceNameTemplate">
        <ng-container *ngIf="reference.doc.download_token | documentPhoto : false : reference.doc | async as imageData">
          <div
            class="inline-flex flex-row items-center w-full"
            [ngClass]="{
              'p-2 rounded-lg border border-gray-200':
                isDeleteDocumentPossible || showUploadAction || isDownloadDocumentPossible
            }"
          >
            <div class="flex flex-col w-full">
              <x-svg-icon
                *ngIf="isUploadedIntoPhotos(reference.doc)"
                (click)="openInPhotosOverview($event, reference)"
                svgImageName="photo"
                svgClass="w-4 h-4 text-gray-600 hover:text-primary-600"
                class="action-icon !ml-[-2px] mb-1"
                [matTooltip]="'open-in-photos-overview' | transloco"
              ></x-svg-icon>
              <img
                class="rounded-lg"
                [style.height]="'auto'"
                [style.width.px]="240"
                [alt]="reference.name"
                [src]="imageData"
              />
              <ng-container
                [ngTemplateOutlet]="documentDataroomPathElementTemplate"
                [ngTemplateOutletContext]="{ $implicit: reference.doc }"
              ></ng-container>
            </div>
            <ng-container
              [ngTemplateOutlet]="actionsContainer"
              [ngTemplateOutletContext]="{ $implicit: reference, references: references }"
            ></ng-container>
          </div>
        </ng-container>
      </ng-container>
      <ng-template #referenceNameTemplate>
        <div class="flex flex-col p-2 border border-gray-200 rounded-lg w-full">
          <div class="inline-flex flex-row items-center">
            <span
              class="inline-flex flex-row items-center"
              [matTooltip]="
                !getIsViewableOnline(reference) && !isDeleteDocumentPossible
                  ? ('unsupported-file-preview-info' | transloco)
                  : undefined
              "
              [matTooltipPosition]="'left'"
            >
              <x-file-icon [hasDotIndicator]="false" [extension]="reference.doc.file_extension"></x-file-icon>
              <span
                [title]="getIsViewableOnline(reference) ? reference.name : ''"
                class="ml-1.5 text-sm text-primary-600 break-all line-clamp-2 text-ellipsis select-none"
              >
                {{ reference.name }}
              </span>
              <span
                class="text-gray-400 text-xs"
                *ngIf="
                  reference.doc.conversion_status === 'processing' || reference.doc.conversion_status === 'pending'
                "
              >
                &nbsp; ({{ 'conversion-pending' | transloco }})
              </span>
            </span>
            <ng-container
              [ngTemplateOutlet]="actionsContainer"
              [ngTemplateOutletContext]="{ $implicit: reference, references: references }"
            ></ng-container>
          </div>
          <ng-container
            [ngTemplateOutlet]="documentDataroomPathElementTemplate"
            [ngTemplateOutletContext]="{ $implicit: reference.doc }"
          ></ng-container>
        </div>
      </ng-template>
    </li>
  </div>
</ng-template>

<ng-template let-doc #documentDataroomPathElementTemplate>
  <span
    class="flex flex-row text-xs text-gray-600 whitespace-nowrap items-center mt-1.5 hover:text-primary-600 !cursor-pointer"
    (click)="navigateToDataroomPath($event, doc)"
    *ngIf="getDataroomPathFromDocument(doc); let path"
  >
    <x-svg-icon
      svgImageName="folder"
      svgClass="w-4 h-4"
      class="action-icon !ml-[-2px]"
      [matTooltip]="'open-in-dataroom' | transloco"
    ></x-svg-icon>
    <span [title]="path" class="overflow-hidden text-ellipsis">
      {{ path }}
    </span>
  </span>
</ng-template>

<ng-template #removeRefBtn let-ref>
  <x-svg-icon
    *ngIf="isDeleteDocumentPossible"
    class="action-icon"
    svgClass="w-5 h-5 text-gray-500"
    svgImageName="cross"
    (click)="$event.stopPropagation(); onDeleteReferenceClick(ref)"
  ></x-svg-icon>
</ng-template>

<ng-template #downloadRefBtn let-ref>
  <div
    *ngIf="isDownloadDocumentPossible"
    class="action-icon"
    (click)="$event.stopPropagation(); onDownloadReferenceClick(ref)"
  >
    <x-svg-icon svgClass="w-4 h-4 text-gray-500" svgImageName="download"></x-svg-icon>
  </div>
</ng-template>

<ng-template #uploadRefBtn let-ref let-references="references">
  <div
    *ngIf="showUploadAction && !isUploadedIntoSystem(ref.doc)"
    class="action-icon"
    matTooltip="{{ 'upload-to-the-system' | transloco }}"
    (click)="$event.stopPropagation(); onUploadIntoSystemClick(ref, references)"
    [matTooltipShowDelay]="500"
  >
    <x-svg-icon svgClass="w-4 h-4 text-gray-500" svgImageName="cloud-upload"></x-svg-icon>
  </div>
</ng-template>

<ng-template #actionsContainer let-reference let-references="references">
  <span class="flex h-full items-center ml-auto">
    <ng-container *ngTemplateOutlet="removeRefBtn; context: { $implicit: reference }"></ng-container>
    <ng-container
      *ngTemplateOutlet="uploadRefBtn; context: { $implicit: reference, references: references }"
    ></ng-container>
    <ng-container *ngTemplateOutlet="downloadRefBtn; context: { $implicit: reference }"></ng-container>
  </span>
</ng-template>
