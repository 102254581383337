import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InputComponent } from '@common/components/input/input.component';
import { AutofocusDirectiveModule } from '@common/directives';
import { TranslocoModule } from '@ngneat/transloco';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'x-search-input',
  standalone: true,
  imports: [CommonModule, InputComponent, AutofocusDirectiveModule, TranslocoModule],
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchInputComponent {
  @Input() value: string;
  @Input() control = new FormControl();
  @Output() handleKeydown = new EventEmitter();
  @Output() valueChange = new EventEmitter();
  @Output() resetClick = new EventEmitter();

  searchFieldFocused = false;

  reset() {
    this.searchFieldFocused = false;
    this.resetClick.emit();
  }
}
