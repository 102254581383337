import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { api } from '@core/models';
import {
  TermsConfirmationDialogComponent,
  TermsConfirmationDialogData,
} from '@portal/components/terms-confirmation-dialog';
import { isNotNil } from 'src/app/utils';
import { PORTAL_API_URL } from '@core/services';
import { Store } from '@ngrx/store';
import { find } from 'lodash';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { TranslocoService } from '@ngneat/transloco';
import { TermsConditions } from '@core/models/terms-conditions';
import { CustomerAssetsService } from '../services';
import { CustomerRouterSelectors } from '../state/customer-router.selectors';
import { ToastService, ToastType } from '@core/toast';

@Injectable()
export class TermsConditionsInterceptor implements HttpInterceptor {
  readonly DIALOG_ID = 'terms-conditions-dialog';
  readonly TERMS_CONDITIONS = 'terms_conditions';

  constructor(
    private readonly dialog: MatDialog,
    private readonly assetsService: CustomerAssetsService,
    private readonly store: Store,
    private readonly router: Router,
    private readonly toast: ToastService,
    private readonly transloco: TranslocoService
  ) {}

  readonly ASSET_URL = (asset_id: string | null) => `${PORTAL_API_URL}/assets/${asset_id}`;

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: api.ErrorResponse<unknown>) => {
        if (error && error.status === 403) {
          if (error.error?.errors?.[0]?.source !== this.TERMS_CONDITIONS) {
            return throwError(error);
          }

          const disclaimerDialog = find(this.dialog.openDialogs, ({ id }) => id === this.DIALOG_ID);
          if (disclaimerDialog) {
            return throwError(error);
          }

          this.store
            .select(CustomerRouterSelectors.getAssetId)
            .pipe(
              take(1),
              filter((asset_id) => isNotNil(asset_id)),
              filter((asset_id) => !!error?.url?.toLowerCase().startsWith(this.ASSET_URL(asset_id))),
              switchMap((asset_id) =>
                this.assetsService
                  .getAssetTermsAndConditions({ asset_id: asset_id as string })
                  .pipe(map((result) => ({ result, asset_id })))
              )
            )
            .subscribe({
              next: ({ result, asset_id }) => this.showTermsConditionsDialog(result, asset_id),
            });
        }
        return throwError(error);
      })
    );
  }

  private onConfirm(asset_id: string | null): void {
    if (!!asset_id) {
      this.assetsService.confirmTermsAndConditions(asset_id).subscribe({
        next: async () => {
          await this.router.navigate(['customer', 'assets', asset_id]);
        },
        error: () => {
          this.toast.showToast(this.transloco.translate('terms-conditions-confirm-error'), ToastType.ERROR);
        },
      });
    }
  }

  private onReject() {
    this.router.navigate(['customer', 'assets']);
    this.dialog.closeAll();
  }

  private showTermsConditionsDialog(response: TermsConditions, asset_id: string | null) {
    if (!this.dialog.getDialogById(this.DIALOG_ID)) {
      this.dialog.open(TermsConfirmationDialogComponent, {
        backdropClass: 'terms-backdrop',
        id: this.DIALOG_ID,
        closeOnNavigation: false,
        disableClose: true,
        data: <TermsConfirmationDialogData>{
          assetId: response.project_id,
          description: response.description,
          content: response.content,
          projectId: response.project_id,
          tenantId: response.project_id,
          onConfirm: () => this.onConfirm(asset_id),
          onCancel: () => this.onReject(),
        },
      });
    }
  }
}
