import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';

import { AuthService } from '../services/auth.service';
import map from 'lodash/map';
import { isEmpty } from 'lodash';

@Injectable()
export class AuthGuard implements CanLoad, CanActivateChild, CanActivate {
  constructor(private router: Router, private authService: AuthService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const loggedIn = this.isAuthorized();
    if (!loggedIn && state.url.indexOf('/auth') === -1) {
      const hasQueryParams = route.queryParams && !!route.queryParams.path;
      if (hasQueryParams) {
        this.router.navigate(['/auth']);
      } else {
        this.router.navigate(['/auth'], { queryParams: { path: state.url } });
      }
      return false;
    } else if (state.url.indexOf('/auth') === 0) {
      if (loggedIn) {
        this.router.navigateByUrl('/');
        return false;
      }
      return true;
    }

    return loggedIn;
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const loggedIn = this.isAuthorized();
    if (!loggedIn && state.url.indexOf('/auth') === -1) {
      const hasQueryParams = childRoute.queryParams && !!childRoute.queryParams.path;
      if (hasQueryParams) {
        this.router.navigate(['/auth']);
      } else {
        this.router.navigate(['/auth'], { queryParams: { path: state.url } });
      }
      return false;
    } else if (state.url.indexOf('/auth') === 0) {
      if (loggedIn) {
        this.router.navigateByUrl('/');
        return false;
      }
      return true;
    }

    return loggedIn;
  }

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const loggedIn = this.isAuthorized();
    if (!loggedIn && route.path !== 'auth') {
      const segmentsPath = isEmpty(segments) ? null : map(segments, ({ path }) => path).join('/');
      this.router.navigate(['/auth'], { queryParams: { path: segmentsPath || route.path } });
      return false;
    } else if (route.path === 'auth') {
      if (loggedIn) {
        this.router.navigateByUrl('/');
        return false;
      }
      return true;
    }

    return loggedIn;
  }

  private isAuthorized(): boolean {
    return this.authService.isUserAccessTokenValid();
  }
}
