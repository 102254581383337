import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { IconComponentModule } from '../icon';
import { LanguageSwitcherComponent } from './language-switcher.component';
import { AssetUrlPipe } from '@common/pipes/asset-url/asset-url.pipe';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';

@NgModule({
  imports: [
    CommonModule,
    IconComponentModule,
    MatIconModule,
    RouterModule,
    TranslocoModule,
    AssetUrlPipe,
    CdkMenu,
    CdkMenuItem,
    CdkMenuTrigger,
    SvgIconModule,
  ],
  declarations: [LanguageSwitcherComponent],
  exports: [LanguageSwitcherComponent],
})
export class LanguageSwitcherModule {}
