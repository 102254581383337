import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  inject,
  Inject,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as _ from 'lodash';
import { first } from 'rxjs/operators';
import { AuthService, UserService } from '../../../core/services';
import { AlertDialogComponent, AlertDialogData, AlertType } from '../alert-dialog';

@Component({
  selector: 'x-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginDialogComponent implements OnInit {
  @HostBinding('class.recovery')
  recoveryMode = false;
  @HostBinding('style.left.px')
  right = 0;
  @ViewChild('form') form: NgForm;
  @ViewChild('messageTemplatePasswordResetRequest')
  messageTemplatePasswordResetRequest: TemplateRef<unknown>;

  inProgress = false;
  model = {
    username: null,
    password: null,
  };

  readonly userService = inject(UserService);
  readonly authService = inject(AuthService);
  readonly user$ = this.userService.user$;

  constructor(
    public dialogRef: MatDialogRef<void>,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: { top: number; right: number }
  ) {}

  @HostBinding('style.top.px')
  get top(): number {
    return this.data && this.data.top + 15;
  }

  doSignIn(): void {
    this.form.form.markAllAsTouched();

    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    this.authService
      .signIn(this.model)
      .pipe(first())
      .subscribe({
        next: () => {
          this.inProgress = false;
          this.dialogRef.close(true);
        },
        error: () => {
          this.inProgress = false;
          this.resetPassword();
          _.forOwn(this.form.controls, (control) => {
            control.setErrors({ error: 'required' });
            control.markAsDirty();
            control.markAsTouched();
          });
          this.cdr.markForCheck();
        },
      });
  }

  ngOnInit(): void {
    const componentWidth =
      this.elementRef && (<HTMLElement>this.elementRef.nativeElement).getBoundingClientRect().width;
    this.right = this.data.right - componentWidth + 20;
  }

  onPasswordRecovery(isRecovery = true): void {
    this.recoveryMode = isRecovery;
    this.resetPassword();
    this.cdr.markForCheck();
  }

  onSendRecoveryEmail(): void {
    if (this.inProgress) {
      return;
    }

    this.inProgress = true;
    this.userService
      .recoverPassword(this.model.username)
      .pipe(first())
      .subscribe(() => {
        this.dialogRef.close();
        this.showSuccessAlert();
      });
  }

  private resetPassword(): void {
    this.model.password = null;
    this.form.form.markAllAsTouched();
  }

  private showSuccessAlert(): void {
    this.dialog.open(AlertDialogComponent, {
      data: <AlertDialogData>{
        type: AlertType.Success,
        template: () => this.messageTemplatePasswordResetRequest,
      },
    });
  }
}
