import { Injectable } from '@angular/core';
import { HeaderActions } from '@common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AdminRouterSelectors } from '@portal/admin/store';
import { of } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { isNotNil } from 'src/app/utils';
import { ProjectsService, StorageService } from '../../../../core/services';
import { ProjectActions } from './portal-projects.actions';

@Injectable()
export class PortalProjectsEffects {
  readonly loadProjects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.fetch),
      switchMap(() => this.projectsService.fetch()),
      map((projects) => ProjectActions.fetchSuccess({ projects })),
      catchError(() => of(ProjectActions.fetchFail()))
    )
  );

  readonly fetchProjectDetails$ = createEffect(() =>
    this.store.select(AdminRouterSelectors.getProjectId).pipe(
      filter(isNotNil),
      switchMap((projectId) => this.projectsService.fetchDetails(projectId)),
      map((project) => ProjectActions.fetchDetailsSuccess({ project }))
    )
  );

  readonly fetchProjectLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.fetchDetailsSuccess),
      distinctUntilChanged(
        (a, b) => a !== b,
        (details) => details.project?.logo_download_token
      ),
      switchMap((details) =>
        this.storageService.downloadDocument({ download_token: details.project?.logo_download_token }).pipe(
          map((blob) => ({
            url: window.URL.createObjectURL(blob),
            project_id: details.project.id,
          }))
        )
      ),
      map(({ url, project_id }) => ProjectActions.setProjectLogoUri({ id: project_id, logoUri: url }))
    )
  );

  readonly setHeaderLogo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProjectActions.setProjectLogoUri),
      map((state) => HeaderActions.setLogo({ url: state.logoUri }))
    )
  );

  readonly removeHeaderLogo$ = createEffect(() =>
    this.store.select(AdminRouterSelectors.getProjectId).pipe(
      filter((id) => !id),
      map(() => HeaderActions.setLogo({}))
    )
  );

  constructor(
    private readonly actions$: Actions,
    private readonly projectsService: ProjectsService,
    private readonly store: Store,
    private readonly storageService: StorageService
  ) {}
}
