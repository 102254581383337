import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonComponentsModule } from './components';
import { MaterialModule } from './material.module';
import { PageModule } from './page';
import { PipesModule } from './pipes';

const modules = [AngularCommonModule, ScrollingModule, MaterialModule, PageModule, PipesModule, CommonComponentsModule];

/**
 * Import only required modules instead of this module
 *
 * @deprecated
 */
@NgModule({
  imports: modules,
  exports: modules,
})
export class CommonModule {}
