import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Monitoring } from '@core/models';
import { LabelComponent } from '../label-component';

@Component({
  selector: 'x-monitoring-label',
  templateUrl: './monitoring-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MonitoringLabelComponent extends LabelComponent<Monitoring.Job & Monitoring.Log> {
  translationMapping: { [key: string]: string } = {
    asset_id: 'asset-id',
    asset_name: 'asset',
    asset_token: 'asset-token',
    avg_conversion_time: 'avg-conversion-time',
    conversion_status: 'conversion-status',
    conversion_time: 'conversion-time',
    conversion_type: 'conversion-type',
    created_at: 'date-time',
    document_id: 'document-id',
    file_format: 'file-format',
    file_size: 'file_upload_size',
    finished_at: 'finished-at',
    folder_src_node: 'folder',
    group_id: 'group-id',
    group_name: 'group',
    job: 'job',
    num_files: 'num-files',
    num_images_to_jpg: 'num-images-to-jpg',
    num_images_to_pdf: 'num-images-to-pdf',
    num_office_files_to_pdf: 'num-office-files-to-pdf',
    num_pdfs_to_pdf: 'num_pdfs_to_pdf',
    page: 'page',
    path: 'path',
    project_id: 'project-id',
    project_name: 'project',
    response_status: 'status',
    src_path: 'path',
    status: 'status',
    tenant_id: 'tenant-id',
    tenant_name: 'tenant',
    tenant_token: 'tenant-token',
    timestamp: 'date-time',
    time_range: 'timerange',
    user_email: 'email',
    user_firstname: 'first-name',
    user_id: 'user-id',
    user_lastname: 'last-name',
    action: 'action',
    scope: 'scope',
    label: 'filename',
    original_filename: 'filename',
    duration: 'duration',
    'ticket-number': 'ticket-number',
    'qa-number': 'qa-number',
    error_msg: 'generic-error',
    data: 'data',
    answer: 'answer',
    url_path: 'url-path',
    url_host: 'url-host',
    operation_id: 'operation-id',
    id: 'id',
    affected_group_id: 'affected-group-id',
    affected_group_name: 'affected-group-name',
    permissions: 'permissions',
    dest_node: 'target-node',
    dest_path: 'target-path',
    file_extension: 'extension',
    affected_user_id: 'affected-user-id',
    affected_user_email: 'affected-user-email',
    src_group: 'src_group',
    dest_group: 'dest_group',
    action_payload: 'action_payload',
    value: 'value',
    user: 'user',
    group: 'group',
    qa_message: 'qa_message',
    version: 'version',
  };
}
