export type Priority = 'high' | 'medium' | 'low';

export namespace Priority {
  export const order: Priority[] = ['high', 'medium', 'low'];

  export const compare = (a: Priority, b: Priority) => {
    if (a === b) {
      return 0;
    }
    const aIndex = Priority.order.indexOf(a);
    const bIndex = Priority.order.indexOf(b);
    return aIndex < bIndex ? -1 : 1;
  };
}
