import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslocoResolver } from '@core/services';
import { environment } from '@environments';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { CustomerRoute } from '@portal/customer/services';
import { CustomerRouterParams } from '@portal/customer/state';
import { AuthGuard, RoutingPortalGuard } from '@core/guards';
import { AppRoute } from './app-routing.service';

const routes: Routes = [
  {
    pathMatch: 'full',
    path: `${AppRoute.Customer}/${CustomerRoute.Assets}/:${CustomerRouterParams.AssetId}/${CustomerRoute.Reports}/${CustomerRoute.ReportTemplate}/:${CustomerRouterParams.ReportTemplateId}/print`,
    loadChildren: () =>
      import('./portal/customer/components/report-preview/all-chapters').then(
        (importedModule) => importedModule.ReportPreviewAllChaptersModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: [TranslocoResolver],
  },
  {
    pathMatch: 'full',
    path: `${AppRoute.Customer}/${CustomerRoute.Assets}/:${CustomerRouterParams.AssetId}/${CustomerRoute.Reports}/${CustomerRoute.ReportTemplate}/:${CustomerRouterParams.ReportTemplateId}/:${CustomerRouterParams.ChapterId}/preview`,
    loadChildren: () =>
      import('./portal/customer/components/report-preview/preview-container').then(
        (importedModule) => importedModule.ReportPreviewContainerModule
      ),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: [TranslocoResolver],
  },
  {
    path: AppRoute.Customer,
    loadChildren: () =>
      import('./portal/customer/customer-portal.module').then((importedModule) => importedModule.CustomerPortalModule),
    canLoad: [RoutingPortalGuard, AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: [TranslocoResolver],
  },
  {
    path: AppRoute.Admin,
    loadChildren: () =>
      import('./portal/admin/admin-portal.module').then((importedModule) => importedModule.AdminPortalModule),
    canLoad: [RoutingPortalGuard, AuthGuard],
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: [TranslocoResolver],
  },
  {
    path: AppRoute.Auth,
    loadChildren: () => import('./auth/auth.module').then((importedModule) => importedModule.AuthModule),
    canLoad: [AuthGuard],
    canActivateChild: [AuthGuard],
    resolve: [TranslocoResolver],
  },
  {
    path: AppRoute.External,
    loadChildren: () => import('./external/external.module').then((importedModule) => importedModule.ExternalModule),
    resolve: [TranslocoResolver],
  },
  ...(environment.notificationPlanEnabled
    ? [
        {
          path: AppRoute.User,
          loadChildren: () => import('./user/user.module').then((importedModule) => importedModule.UserModule),
          canLoad: [AuthGuard],
          canActivate: [AuthGuard],
          canActivateChild: [AuthGuard],
          resolve: [TranslocoResolver],
        },
      ]
    : []),
  {
    path: `${AppRoute.EmailConfirm}/:token`,
    loadChildren: () => import('./confirm-email').then((importedModule) => importedModule.ConfirmEmailModule),
    resolve: [TranslocoResolver],
  },
  {
    path: `${AppRoute.ConfirmInvitation}/:token`,
    loadChildren: () => import('./confirm-invitation').then((importedModule) => importedModule.ConfirmInvitationModule),
    resolve: [TranslocoResolver],
  },
  {
    path: AppRoute.Imprint,
    loadChildren: () => import('./imprint').then((importedModule) => importedModule.ImprintModule),
    resolve: [TranslocoResolver],
  },
  {
    path: AppRoute.DataProtection,
    loadChildren: () => import('./data-protection').then((importedModule) => importedModule.DataProtectionModule),
    resolve: [TranslocoResolver],
  },
  {
    path: AppRoute.Terms,
    loadChildren: () => import('./terms').then((importedModule) => importedModule.TermsModule),
    resolve: [TranslocoResolver],
  },
  {
    path: '',
    redirectTo: AppRoute.Customer,
    pathMatch: 'full',
    canLoad: [RoutingPortalGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    StoreModule.forRoot({
      router: routerReducer,
    }),
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      // , enableTracing: true
      // , enableTracing: !environment.production
      // , useHash: !environment.production
      // , urlUpdateStrategy: 'eager',
      // , relativeLinkResolution: 'corrected'
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
