import { ESCAPE, LEFT_ARROW, RIGHT_ARROW } from '@angular/cdk/keycodes';
import { Component, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Document } from '@core/models';
import { ActivityStatisticsService, StorageService } from '@core/services';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _head from 'lodash/head';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { isNotNil } from 'src/app/utils';
import { TokenGetter } from '@portal/customer/components/asset-details/asset-photo-viewer/asset-photo-viewer-container/asset-photo-viewer-container.model';
import { isVideoExtensionsRegExp } from '@portal/components/qa-overview/qa-overview.constants';
import { PhotosService } from '@portal/customer/components/asset-details/asset-photo-viewer/photos.service';

interface Photo extends Document.ListItem {
  large: string;
  loadingLarge: Observable<string>;
  loadingPreview: Observable<string>;
  preview: string;
}

@Component({
  selector: 'x-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrls: ['./photo-viewer.component.scss'],
})
export class PhotoViewerComponent implements OnChanges {
  @Input() hasFilter?: boolean;
  @Input() borderless?: boolean;
  @Input() newDownloadTokenGetter?: TokenGetter;
  @Input() photos?: Photo[];
  @Input() selectedImageId?: string;
  @Input() useExistingTokens: boolean;
  @Input() zoomAtInit: boolean;
  @Output() lastShown = new EventEmitter<void>();
  @Output() selectedImageIdChange = new EventEmitter<string>();
  @Output() shown = new EventEmitter<Photo>();

  currentPhotoValue?: Photo;
  currentPhotoZoomed = false;
  isLoading$ = new BehaviorSubject(true);

  constructor(
    public sanitizer: DomSanitizer,
    public storageService: StorageService,
    public photoService: PhotosService,
    private activityService: ActivityStatisticsService
  ) {}

  get currentPhoto(): Photo | undefined {
    return this.currentPhotoValue;
  }

  set currentPhoto(value: Photo | undefined) {
    this.currentPhotoValue = value;
    this.shown.emit(value);
  }

  get isPanorama(): boolean {
    return !!this.currentPhoto?.types?.includes('panorama');
  }

  get isPhoto(): boolean {
    return !this.isVideo && !this.isPanorama;
  }

  get isVideo(): boolean {
    return isVideoExtensionsRegExp.test(this.currentPhoto.name);
  }

  @HostListener('document:keydown', ['$event'])
  onDocumentKeyPress(event: KeyboardEvent) {
    if (event.keyCode === LEFT_ARROW || event.keyCode === RIGHT_ARROW) {
      if (event.keyCode === LEFT_ARROW) {
        this.onClickPrevImage();
      } else {
        this.onClickNextImage();
      }
    } else if (event.keyCode === ESCAPE) {
      this.onToggleZoom(false);
    }
  }

  getPhotoSource(
    photo: Document.ListItem,
    photoService: PhotosService,
    loading$: BehaviorSubject<boolean>,
    useExistingTokens: boolean
  ) {
    loading$.next(true);
    return photoService.getAndCachePhoto(photo, 'large', true, useExistingTokens).pipe(
      tap(() => {
        loading$.next(false);
      }),
      catchError((err) => {
        loading$.next(false);
        return throwError(err);
      })
    );
  }

  getVideoSource = (doc: Document.ListItem) => {
    this.isLoading$.next(true);
    return this.newDownloadTokenGetter?.(doc.id, doc.asset_id).pipe(
      take(1),
      filter(isNotNil),
      switchMap((download_token) =>
        this.storageService.downloadDocument({
          ...doc,
          download_token,
        })
      ),
      switchMap((blob: Blob) => {
        this.isLoading$.next(false);
        return this.activityService
          .trackActivity(doc.asset_id, { document_id: doc.id, scope: 'photo', action: 'view' })
          .pipe(
            map(() => blob),
            catchError(() => of(blob))
          );
      }),
      catchError((error) => {
        this.isLoading$.next(false);
        return throwError(error);
      }),
      map((blob: Blob) => ({
        src: window.URL.createObjectURL(blob),
        type: blob.type,
      }))
    );
  };

  ngOnChanges({ photos, selectedImageId }: SimpleChanges): void {
    if (photos) {
      const firstPhoto = _head(this.photos);
      const currentId = this.selectedImageId || this.currentPhoto?.id;
      const currentPhoto = _find(this.photos, ({ id }) => id === currentId);
      this.selectImage(currentPhoto || firstPhoto);
    }

    if (selectedImageId && selectedImageId.currentValue) {
      this.showImageById(selectedImageId.currentValue);
    }
  }

  onClickNextImage(): void {
    const index = _findIndex(this.photos, this.currentPhoto) + 1;
    this.selectImage(this.photos[index] || this.currentPhoto);
  }

  onClickPrevImage(): void {
    const index = _findIndex(this.photos, this.currentPhoto) - 1;
    this.selectImage(this.photos[index] || this.currentPhoto);
  }

  onSelectImage(photo: Photo): void {
    this.selectImage(photo);
  }

  onToggleZoom(zoomed = !this.currentPhotoZoomed): void {
    this.currentPhotoZoomed = zoomed;

    if (zoomed) {
      document.getElementsByTagName('x-header').item(0)?.classList.add('z-index-0');
    } else {
      document.getElementsByTagName('x-header').item(0)?.classList.remove('z-index-0');
    }
  }

  selectImage(photo: Photo): void {
    this.currentPhoto = photo;
    if (photo) {
      this.selectedImageIdChange.emit(photo.id);
    }
  }

  showImageById(selectedImageId: string): void {
    if (this.currentPhoto?.id === selectedImageId) {
      return;
    }

    const photo = _find(this.photos, ({ id }) => id === selectedImageId);
    if (!photo) {
      return;
    }

    this.selectImage(photo);
    this.selectedImageIdChange.emit(photo.id);
  }
}
