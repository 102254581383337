import { Pipe, PipeTransform } from '@angular/core';

export const decodeHtml = (value: string): string => {
  const txt = document.createElement('textarea');
  txt.innerHTML = value;
  return txt.value;
};

@Pipe({
  name: 'decodeHtml',
  standalone: true,
})
export class DecodeHtmlPipe implements PipeTransform {
  transform(value: string): string {
    return decodeHtml(value);
  }
}
