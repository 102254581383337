import { ChangeDetectorRef, Component } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ISelectOption } from '@common/components/select/select.model';

import {
  Addon,
  Countries,
  DataRoomProjectForm,
  DemoProjectForm,
  Finding,
  GermanLands,
  getCountryTranslationKeyName,
  ProjectForm,
  ProjectPackage,
  User,
} from '@core/models';
import { LanguageService, ProjectsService, TradeService, UserService } from '@core/services';
import { ToastService, ToastType } from '@core/toast';
import { TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as _ from 'lodash';
import { BehaviorSubject, of, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, map, switchMap, take, tap } from 'rxjs/operators';

const USER_QUOTA_DEFAULT_VALUE = 10;

@UntilDestroy()
@Component({
  templateUrl: './create-project-dialog.component.html',
  styleUrls: ['./create-project-dialog.component.scss'],
})
export class CreateProjectDialogComponent {
  commonFormGroup: FormGroup;
  demoFormGroup: FormGroup;
  tddFormGroup: FormGroup;
  dataRoomFormGroup: FormGroup;

  projectTypeControl = this.fb.control('');
  inProgress = false;
  packagesOptions: ISelectOption<ProjectPackage.ProductType>[];

  readonly projectTypeOptions = [
    {
      value: 'demo',
      label: this.transloco.translate('demo'),
    },
    {
      value: 'tdd',
      label: this.transloco.translate('tdd'),
    },
    {
      value: 'data_room',
      label: this.transloco.translate('data-room'),
    },
  ];
  readonly BestReachable = User.BestReachable;
  readonly countries = Countries;
  readonly languages = this.sortCountries(Countries.reportLanguages)
    .filter((country) => country === Countries.Germany || country === Countries.UnitedStates)
    .map((language) => ({
      value: language,
      translationKey: this.projectsService.getLanguageTranslationKey(language),
    }));
  readonly monitoringFindingFormats = Finding.monitoringFormats;
  readonly productTypes$ = this.projectsService.getProductTypes();
  readonly projectPackages$ = new BehaviorSubject<ProjectPackage>({} as ProjectPackage);
  readonly projectTemplates$ = this.projectsService.fetch({ type: 'template' }).pipe(
    map((projectTemplates) =>
      projectTemplates.map((projectTemplate) => ({ value: projectTemplate.id, label: projectTemplate.name }))
    ),
    untilDestroyed(this)
  );
  readonly sortedCountries = this.sortCountries(Countries.all).map((country) => ({
    value: country,
    label: this.transloco.translate(getCountryTranslationKeyName(country)),
  }));
  readonly states = GermanLands;
  readonly tddFindingFormats = Finding.tddFormats;
  readonly tradeSets$ = new BehaviorSubject<ISelectOption<string>[]>([]);

  constructor(
    private readonly dialogRef: MatDialogRef<CreateProjectDialogComponent, boolean>,
    private readonly projectsService: ProjectsService,
    private readonly userService: UserService,
    private readonly tradeService: TradeService,
    private readonly cdr: ChangeDetectorRef,
    private readonly transloco: TranslocoService,
    private readonly fb: FormBuilder,
    private readonly toastService: ToastService,
    private readonly languageService: LanguageService
  ) {
    this.buildFormGroups();
    this.getTradeSets();
    this.getProjectPackages();
    this.watchEmailChange();
  }

  get demoProject(): DemoProjectForm {
    return { ...this.commonFormGroup.value, ...this.demoFormGroup.value } as DemoProjectForm;
  }

  get tddProject(): ProjectForm {
    const formValue = { ...this.commonFormGroup.getRawValue(), ...this.tddFormGroup.getRawValue() };
    if (formValue.monitoring_finding_format === '') {
      delete formValue.monitoring_finding_format;
    }
    if (!formValue.monitoring_trade_set_id) {
      delete formValue.monitoring_trade_set_id;
    }
    if (formValue.monitoring_finding_format !== '') {
      formValue.monitoring_finding_format = formValue.monitoring_finding_format
        ? Number.parseInt(formValue.monitoring_finding_format, 10)
        : 0;
    }
    if (formValue.tdd_finding_format) {
      formValue.tdd_finding_format = Number.parseInt(formValue.tdd_finding_format, 10);
    }

    return this.removeTDDFields(formValue) as ProjectForm;
  }

  removeTDDFields(formValue: object): unknown {
    const productType = this.tddFormGroup.value['product_type'];
    if (productType) {
      const tddRemove = ['monitoring_finding_format', 'monitoring_trade_set_id'];
      const notTdd = ['tdd_finding_format', 'tdd_trade_set_id'];
      const omit = productType === 'tdd' ? tddRemove : notTdd;
      Object.keys(formValue).forEach((key) => {
        if (omit.includes(key)) {
          delete formValue[key];
        }
      });
    }
    return formValue;
  }

  isEmpty(value): boolean {
    return value === undefined || value === null || value === '';
  }

  get dataroomProject(): DataRoomProjectForm {
    const formValue = {
      ...this.commonFormGroup.value,
      ...this.dataRoomFormGroup.value,
    } as DataRoomProjectForm;
    formValue.add_ons = Object.entries(formValue.add_ons)
      .map(([key, value]) => (value ? key : null))
      .filter(Boolean) as Addon[];
    formValue.user_quota = Number.parseInt(`${formValue.user_quota}`, 10) ?? USER_QUOTA_DEFAULT_VALUE;
    return formValue;
  }

  get currentFormGroup(): FormGroup {
    return {
      demo: this.demoFormGroup,
      tdd: this.tddFormGroup,
      data_room: this.dataRoomFormGroup,
    }[this.projectTypeControl.value];
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    const projectType = this.projectTypeControl.value;
    this.inProgress = true;
    if (projectType === 'demo') {
      this.createDemoProject();
    } else if (projectType === 'tdd') {
      this.createTddProject();
    } else if (projectType === 'data_room') {
      this.createDataRoomProject();
    }
  }

  isRequired(formControl: AbstractControl) {
    return formControl.hasValidator(Validators.required);
  }

  private watchEmailChange() {
    this.commonFormGroup
      .get('owner.email')
      .valueChanges.pipe(
        distinctUntilChanged(),
        switchMap((emailValue) => this.userService.searchUser(emailValue).pipe(catchError(() => of(undefined)))),
        untilDestroyed(this)
      )
      .subscribe(
        (owner) => owner && this.commonFormGroup.get('owner').patchValue(owner, { emitEvent: true, onlySelf: false })
      );
  }

  private watchProductTypeChange(): void {
    this.projectTypeControl.valueChanges
      .pipe(
        tap((value) => {
          if (value === 'data_room') {
            this.dataRoomFormGroup.get('user_quota').patchValue(USER_QUOTA_DEFAULT_VALUE);
          }
        }),
        switchMap(() => this.projectPackages$),
        untilDestroyed(this)
      )
      .subscribe((packages) => {
        this.setAvailablePackagesOptions(packages);
      });
  }

  private setAvailablePackagesOptions(packages: ProjectPackage): void {
    this.tddFormGroup.get('modules').patchValue(packages[this.projectTypeControl.value]);
  }

  private preparePackagesOptions(
    productTypes: ProjectPackage.ProductType[]
  ): ISelectOption<ProjectPackage.ProductType>[] {
    return productTypes.map((productType) => ({
      value: productType,
      translationKey: this.projectsService.getProductTypeFullName(productType),
    }));
  }

  private createDataRoomProject() {
    this.projectsService
      .createDataRoom(this.dataroomProject)
      .pipe(catchError(this.errorHandler), untilDestroyed(this))
      .subscribe(() => {
        this.inProgress = false;
        this.cdr.markForCheck();
        this.dialogRef.close(true);
      });
  }

  private createDemoProject(): void {
    this.projectsService
      .createDemoProject(this.demoProject)
      .pipe(catchError(this.errorHandler), untilDestroyed(this))
      .subscribe(() => {
        this.inProgress = false;
        this.cdr.markForCheck();
        this.dialogRef.close(true);
      });
  }

  private createTddProject(): void {
    this.projectsService
      .createProject(this.tddProject)
      .pipe(catchError(this.errorHandler), untilDestroyed(this))
      .subscribe(() => {
        this.inProgress = false;
        this.cdr.markForCheck();
        this.dialogRef.close(true);
      });
  }

  private errorHandler = ({ status, error }) => {
    this.inProgress = false;

    if (status === 422) {
      _.forEach(error.errors, (questionnaireError) => {
        const { source } = questionnaireError;
        const control = this.commonFormGroup?.get(source) || this.currentFormGroup?.get(source);
        if (control) {
          control.setErrors({ [questionnaireError.error]: true });
          control.markAsDirty();
          control.markAsTouched();
        }
      });
      this.toastService.showToast(
        this.transloco.translate(''),
        ToastType.ERROR,
        error.errors,
        undefined,
        undefined,
        undefined,
        true
      );
    } else {
      this.toastService.showToast(
        this.transloco.translate('notification.unknown-error'),
        ToastType.ERROR,
        error.errors
      );
    }

    this.cdr.markForCheck();

    return throwError(error);
  };

  private sortCountries(countries: Countries[]): Countries[] {
    return this.languageService.getCountriesSortedByCurrentLanguage(countries);
  }

  private buildFormGroups(): void {
    this.buildCommonFormGroup();
    this.buildDemoFormGroup();
    this.buildTddFormGroup();
    this.buildDataRoomFormGroup();
  }

  private buildCommonFormGroup(): void {
    this.commonFormGroup = this.fb.group({
      name: ['', [Validators.required]],
      owner: this.fb.group({
        email: ['', { validators: [Validators.required], updateOn: 'blur' }],
        firstname: [''],
        lastname: ['', [Validators.required]],
        title: [''],
        company: [''],
        phone: [''],
        best_reachable: [''],
        address: this.fb.group({
          country: ['', [Validators.required]],
          city: ['', [Validators.required]],
          state: [''],
          line1: ['', [Validators.required]],
          line2: [''],
          zip: ['', [Validators.required]],
        }),
      }),
    });
    this.commonFormGroup
      .get('owner.address.state')
      .addValidators(this.requiredStateValidator(this.commonFormGroup.get('owner.address.country')));
  }

  private buildDemoFormGroup(): void {
    this.demoFormGroup = this.fb.group({
      template_id: ['', [Validators.required]],
    });
  }

  private buildTddFormGroup(): void {
    this.tddFormGroup = this.fb.group({
      product_type: ['', [Validators.required]],
      modules: [[], [Validators.required]],
      language: ['', [Validators.required]],
      tdd_trade_set_id: [''],
      tdd_finding_format: ['', [Validators.required]],
      monitoring_trade_set_id: [''],
      monitoring_finding_format: ['', [Validators.required]],
    });
  }

  private buildDataRoomFormGroup(): void {
    this.dataRoomFormGroup = this.fb.group({
      language: ['', [Validators.required]],
      add_ons: this.fb.group({
        qa: [false, [Validators.required]],
        photo_documentation: [false, [Validators.required]],
        ticket: [false, [Validators.required]],
      }),
      user_quota: [USER_QUOTA_DEFAULT_VALUE, [Validators.required]],
    });
  }

  private getTradeSets(): void {
    this.tradeService
      .getTradeSets()
      .pipe(
        take(1),
        map((tradeSets) => tradeSets.map((tradeSet) => ({ value: tradeSet.id, label: tradeSet.name }))),
        untilDestroyed(this)
      )
      .subscribe({
        next: (tradeSets) => {
          this.tradeSets$.next(tradeSets);
        },
      });
  }

  private getProjectPackages(): void {
    this.projectsService
      .getProjectPackages()
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (packages) => {
          this.projectPackages$.next(packages);
          this.packagesOptions = this.preparePackagesOptions(packages.modules);
          this.setAvailablePackagesOptions(packages);
          this.watchProductTypeChange();
        },
        error: () => this.projectPackages$.next({} as ProjectPackage),
      });
  }

  private requiredStateValidator(countryControl: AbstractControl): ValidatorFn {
    return (stateControl: AbstractControl): { [key: string]: boolean } | null => {
      const countryValue = countryControl.value;
      const stateValue = stateControl.value;

      if (countryValue === Countries.Germany && !stateValue) {
        return { requiredState: true };
      }

      return null;
    };
  }
}
