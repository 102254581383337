import { RowNode } from '@ag-grid-community/core';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { ButtonComponent } from '@common/components/button/button.component';
import { SvgIconModule } from '@common/components/svg-icon/svg-icon.module';
import { DataRoom, Document } from '@core/models';
import { TranslocoModule } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { CellRendererComponent, CellRendererParams } from '@portal/components';
import { isPhotoTypeRegExp, isVideoExtensionsRegExp } from '@portal/components/qa-overview/qa-overview.constants';
import { CustomerRoute, CustomerRoutingService, DocumentLocation } from '@portal/customer/services';
import { CustomerRouterSelectors } from '@portal/customer/state';
import { LinkHelperService } from '@portal/services/link-helper.service';
import { take } from 'rxjs/operators';

export interface DocumentMetadata {
  name: string;
  id: string;
  types?: Document.Type[];
}

export interface SelectLocationRendererComponentData {
  document: DocumentMetadata;
  onLocationChanged: (docLocation: DocumentLocation, rowNode: RowNode) => void;
}

@Component({
  selector: 'x-select-location-renderer',
  standalone: true,
  imports: [CommonModule, ButtonComponent, MatMenuModule, TranslocoModule, SvgIconModule],
  templateUrl: './select-location-renderer.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectLocationRendererComponent
  extends CellRendererComponent<SelectLocationRendererComponentData>
  implements OnDestroy
{
  documentLocation: DocumentLocation;

  readonly linkHelper = inject(LinkHelperService);
  readonly routingService = inject(CustomerRoutingService);
  readonly store = inject(Store);
  readonly cdr = inject(ChangeDetectorRef);
  readonly assetId$ = this.store.select(CustomerRouterSelectors.getAssetId);

  private onDestroyActions: (() => void)[] = [];

  ngOnDestroy() {
    this.onDestroyActions.forEach((action) => action());
  }

  refresh(params: CellRendererParams<unknown>): boolean {
    this.params = params;
    this.value = params?.value;
    if (params.data?.location) {
      this.documentLocation = params.data.location;
      this.cdr.markForCheck();
    }
    return true;
  }

  onPhotosDirectorySelected() {
    this.setLocationAndEmitChanges({
      documentId: this.value.document?.id,
      documentName: this.value.document?.name,
      location: 'photos',
    });
  }

  onDataroomDirectorySelected() {
    this.assetId$.pipe(take(1)).subscribe((assetId) => {
      const url = this.routingService.getDataRoomDirectorySelectorLink(assetId);

      const key = `${assetId}|${CustomerRoute.DataRoom}`;
      localStorage.removeItem(key);

      const height = Math.round(screen?.height * 0.6);
      const width = Math.round(screen?.width * 0.6);
      const documentWindow = window.open(
        url,
        undefined,
        `width=${width},height=${height},menubar=0,toolbar=0,status=0`
      );

      documentWindow.addEventListener(
        'load',
        () => {
          const onStorageEventCallback = () => {
            const selectedValue = localStorage.getItem(key);
            if (selectedValue) {
              const [id, folderNodeName, permission] = selectedValue?.split('|') as [
                string,
                string,
                DataRoom.PermissionOption
              ];

              this.setLocationAndEmitChanges({
                documentId: this.value.document?.id,
                documentName: this.value.document?.name,
                location: 'dataroom',
                permission,
                dirName: folderNodeName,
                dirId: id,
              });
              localStorage.removeItem(key);
            }
            window.removeEventListener('storage', onStorageEventCallback);
          };
          window.addEventListener('storage', onStorageEventCallback);
          this.onDestroyActions.push(() => {
            window.removeEventListener('storage', onStorageEventCallback);
          });
        },
        { once: true }
      );
    });
  }

  onSelectLocationClick(document: DocumentMetadata) {
    if (!this.isMediaFile(document)) {
      this.onDataroomDirectorySelected();
    }
  }

  isMediaFile(document: DocumentMetadata) {
    return isPhotoTypeRegExp.test(document.name) || isVideoExtensionsRegExp.test(document.name);
  }

  clearLocation() {
    this.documentLocation = undefined;
    this.cdr.markForCheck();
  }

  private setLocationAndEmitChanges(docLocation: DocumentLocation) {
    this.documentLocation = docLocation;
    this.cdr.markForCheck();
    this.value.onLocationChanged(this.documentLocation, this.params.node);
  }
}
