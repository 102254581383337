import { ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { api } from '@core/models';
import * as _ from 'lodash';

export function setFormErrors<T>(error: api.ErrorResponse<T>, form: NgForm, cdr?: ChangeDetectorRef): void {
  const { status } = error;

  if (status === 422) {
    _.forOwn(form.controls, (control) => {
      if (!control) {
        return;
      }

      control.setErrors(null);
    });

    _.forEach(error.error.errors, (serverError) => {
      const { source } = serverError;
      const control = form.controls[source as string];
      if (!control) {
        return;
      }

      control.setErrors({ serverError });
      control.markAsDirty();
      control.markAsTouched();
    });
  }

  cdr?.markForCheck();
}
