import { Document } from '@core/models';
import { createAction, props } from '@ngrx/store';

export namespace AdminDocumentsActions {
  export const fetchDocument = createAction('[Admin Document] Fetch Document');
  export const fetchDocumentSuccess = createAction(
    '[Admin Document] Fetch Document Success',
    props<{ data: Document.ListItem }>()
  );
  export const fetchDocumentError = createAction('[Admin Document] Fetch Document Error');
}
