import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Inject, TemplateRef, ViewChild } from '@angular/core';
import { NgForm, ValidationErrors } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertType } from '@common';
import { Countries, CreateTrackerModel } from '@core/models';
import { LanguageService } from '@core/services';
import { DialogActions } from '@core/state';
import { Store } from '@ngrx/store';
import { TrackersService } from '@portal/admin/services';
import * as _ from 'lodash';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export interface CreateTrackerDialogData {
  asset_id: string;
  project_id: string;
}

@UntilDestroy()
@Component({
  selector: 'x-create-tracker-dialog',
  templateUrl: './create-tracker-dialog.component.html',
  styleUrls: ['./create-tracker-dialog.component.scss'],
})
export class CreateTrackerDialogComponent {
  @ViewChild('form') form: NgForm;
  @ViewChild('unknownErrorTemplate', { static: true })
  unknownErrorTemplate: TemplateRef<HTMLElement>;

  inProgress = false;
  trackers: CreateTrackerModel[] = [];

  readonly languages = [
    { lang: Countries.UnitedStates, id: Countries.UnitedStates },
    { lang: Countries.Germany, id: Countries.Germany },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: CreateTrackerDialogData,
    readonly dialogRef: MatDialogRef<CreateTrackerDialogComponent, boolean>,
    private readonly languageService: LanguageService,
    private readonly trackersService: TrackersService,
    private readonly cdr: ChangeDetectorRef,
    private readonly store: Store<unknown>
  ) {
    this.onAddTracker();
  }

  getControlName(name: string, index: number, descrIndex?: number): string {
    return `${name}-${index}` + (_.isNumber(descrIndex) ? `-${descrIndex}` : '');
  }

  getDefaultTrackerLanguage(): Countries {
    return (
      _.find(this.languages, ({ id }) => id === (this.languageService.lang as string))?.lang || Countries.UnitedStates
    );
  }

  getErrors(fieldName: string): ValidationErrors {
    const errors = this.form?.controls[fieldName]?.errors;
    return errors && errors.serverError;
  }

  onAddDescriptionLanguage(tracker: CreateTrackerModel): void {
    tracker.description.push({
      description: '',
      lang: this.getDefaultTrackerLanguage() === Countries.UnitedStates ? Countries.Germany : Countries.UnitedStates,
    });
  }

  onAddTracker(): void {
    const tracker: CreateTrackerModel = {
      description: [
        {
          description: '',
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          lang: <any>this.getDefaultTrackerLanguage(),
        },
      ],
      planned_date: null,
    };
    this.onAddDescriptionLanguage(tracker);
    this.trackers.push(tracker);
  }

  onRemoveTracker(tracker): void {
    this.trackers = _.without(this.trackers, tracker);
  }

  onSubmit(): void {
    this.inProgress = true;

    const data: CreateTrackerModel[] = _.map(this.trackers, (tracker) => {
      const planned_date = !tracker?.planned_date
        ? null
        : new Date(
            Date.UTC(
              tracker.planned_date.getFullYear(),
              tracker.planned_date.getMonth(),
              tracker.planned_date.getDate(),
              12
            )
          );

      return <CreateTrackerModel>{
        description: _.filter(tracker.description, ({ description, lang }) => description || lang),
        planned_date,
      };
    });

    this.trackersService
      .create(this.data, data)
      .pipe(
        catchError((response: HttpErrorResponse) => {
          const { status } = response;
          const errors = response.error as {
            line: number;
            errors: {
              errors: {
                details: string;
                error: string;
                source: string;
              }[];
            };
          }[];

          this.inProgress = false;

          if (status === 422) {
            _.forOwn(this.form.controls, (control) => {
              if (!control) {
                return;
              }

              control.setErrors(null);
            });

            _.forEach(errors, (error) => {
              _.forEach(error.errors.errors, (serverError) => {
                const { source } = serverError;
                const controlName =
                  source === 'planned_date'
                    ? `${source}-${error.line - 1}`
                    : `${source.split('.')[1]}-${error.line - 1}-${source.match(/\[(.*?)\]/)[1]}`;

                const control = this.form.controls[controlName];
                if (!control) {
                  return;
                }

                control.setErrors({ serverError });
                control.markAsDirty();
                control.markAsTouched();
              });
            });
          } else {
            this.store.dispatch(
              DialogActions.showAlert({
                data: {
                  type: AlertType.Error,
                  template: () => this.unknownErrorTemplate,
                },
              })
            );
          }

          this.cdr.markForCheck();

          return throwError(errors);
        }),
        untilDestroyed(this)
      )
      .subscribe({
        next: () => {
          this.inProgress = false;
          this.cdr.markForCheck();
          this.dialogRef.close(true);
        },
      });
  }
}
