import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectListItem } from '@core/models';

@Component({
  selector: 'x-portal-projects-label',
  templateUrl: './portal-projects-label.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortalProjectsLabelComponent {
  @Input() fieldName: keyof ProjectListItem;
}
