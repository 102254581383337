import { getSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdminRoute } from '../services/admin-routing.service';

export enum AdminRouterParams {
  ProjectId = 'project-id',
  ProjectAssetId = 'project-asset-id',
  ProjectAssetDocumentType = 'project-asset-document-type',
  ProjectAssetDocumentId = 'project-asset-document-id',
  ReportTab = 'project-asset-report-tab',
  SettingsTab = 'settings-tab',
  DisclaimerId = 'disclaimer-id',
  ReportTemplateId = 'report-template-id',
  FindingId = 'finding-id',
}

export namespace AdminRouterSelectors {
  const selectRouter = createFeatureSelector<RouterReducerState>('router');

  const { selectRouteParam, selectUrl } = getSelectors(selectRouter);

  export const getProjectId = createSelector(selectRouteParam(AdminRouterParams.ProjectId), (id) => id);

  export const getProjectAssetId = createSelector(selectRouteParam(AdminRouterParams.ProjectAssetId), (id) => id);

  export const getProjectAssetDocumentType = createSelector(
    selectRouteParam(AdminRouterParams.ProjectAssetDocumentType),
    selectUrl,
    (id, url) => (id ? (id as AdminRoute) : url.match(/report$/gi) && AdminRoute.Report)
  );

  export const getProjectAssetDocumentId = createSelector(
    selectRouteParam(AdminRouterParams.ProjectAssetDocumentId),
    (id) => id
  );

  export const getProjectAssetReportTab = createSelector(selectRouteParam(AdminRouterParams.ReportTab), (id) => id);

  export const getSettingsTab = createSelector(selectRouteParam(AdminRouterParams.SettingsTab), (id) => id);

  export const getDisclaimerId = createSelector(selectRouteParam(AdminRouterParams.DisclaimerId), (id) => id);

  export const getReportTemplateId = createSelector(selectRouteParam(AdminRouterParams.ReportTemplateId), (id) => id);

  export const getFindingId = createSelector(selectRouteParam(AdminRouterParams.FindingId), (id) => id);
}
