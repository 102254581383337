import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslocoService } from '@ngneat/transloco';
import { EMPTY } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';

import { Message } from '@core/models/message';
import { CommentsService } from '@core/services/comments.service';
import { UserService } from '@core/services';
import { ConfirmationDialogComponent, ConfirmationDialogData } from '@portal/components';
import { LabelType } from '@portal/components/badge/badge.component';
import { ToastService, ToastType } from '@core/toast';

@UntilDestroy()
@Component({
  selector: 'x-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnInit {
  @Input() message?: Message.Short;
  @Input() hasSubMessages = false;
  @Input() isSubMessage = false;
  @Input() showReply = true;
  @Input() showTimestampAndPage = false;
  @Input() icon?: string;
  @Input() iconTooltip?: string;
  @Input() messagesLabelTemplate?: TemplateRef<unknown>;
  @Input() frozen = false;

  @Output() reply = new EventEmitter<void>();
  @Output() showMore = new EventEmitter<void>();
  @Output() hasBeenRemoved = new EventEmitter<void>();

  currentUserId: string;
  isDeleting = false;

  constructor(
    private readonly commentsService: CommentsService,
    private readonly userService: UserService,
    private readonly toast: ToastService,
    private readonly transloco: TranslocoService,
    private readonly dialog: MatDialog,
    private readonly cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.watchCurrentUserId();
  }

  getBadgeType(message: Message.Short): LabelType {
    switch (message.priority) {
      case 'low':
        return 'success';
      case 'medium':
        return 'warning';
      case 'high':
        return 'danger';
    }
  }

  onDelete(event: Event): void {
    const { asset_id, id } = this.message;
    event.preventDefault();
    event.stopPropagation();

    this.dialog
      .open<ConfirmationDialogComponent, ConfirmationDialogData, boolean>(ConfirmationDialogComponent, {
        data: {
          text: this.transloco.translate('message-details.delete.confirm-delete-note'),
          variant: 'danger',
          title: this.transloco.translate('delete-note'),
          acceptButtonText: this.transloco.translate('delete'),
          onConfirm: () => {
            this.isDeleting = true;
            return this.commentsService.delete({ asset_id, message_id: id }).pipe(
              tap(() => {
                this.isDeleting = false;
                this.cdr.detectChanges();
              }),
              catchError((error) => {
                console.error(error);
                this.toast.showToast(
                  this.transloco.translate('message-details.delete.delete-note-error'),
                  ToastType.ERROR
                );
                return EMPTY;
              })
            );
          },
        },
      })
      .afterClosed()
      .pipe(filter(Boolean))
      .subscribe(() => {
        this.hasBeenRemoved.emit();
      });
  }

  private watchCurrentUserId(): void {
    this.userService.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.currentUserId = user.id;
    });
  }
}
